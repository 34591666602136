import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import get from 'lodash/get';
import { openCoreHelpModal } from 'combine-modals/lib/actions/actionCreator';
import { wizard, LazyComponent, dispatchAction } from '@pdffiller/jsf-lazyload';
import { getModeIdFromState } from 'ws-editor-lib/store/helpers';
import { DEFAULT_EDITOR_MODE } from 'ws-editor-lib/constants';
import { editType } from 'jsfcore/helpers/const';

import { getIsReadOnlyOverlayEnabled, getIsWizardOverlayEnabled } from '../../../helpers/utils';
import { mainHeaderId, messageButtonHeaderId } from '../../../helpers/const';
import { toggleThumbnailsVisibility } from '../../../store/modules/viewport';
import * as selectors from '../../../store/selectors';

import HeaderDoneActionCreator from '../HeaderDone/HeaderDoneActionCreator';
import HeaderDoneVisibilityProvider from '../HeaderDone/HeaderDoneVisibilityProvider';
import HeaderPhoneOverlayView from './HeaderPhoneOverlayView';
import * as Ui from '../../../ui';
import MobileTools from '../../Toolbar/MobileTools';
import MessageButtonProvider from '../../Toolbar/MessageButtonProvider';

@connect(
  (state) => {
    return {
      isThumbnailsOpen: selectors.base.getIsThumbnailsOpen(state),
      havePdfDocument: state.pdf.hasPdfDocument,
      isWizardLoaded: state.lazyLoad.wizard,
      isWizardStartButtonVisible: get(state, 'wizard.isWizardStartButtonVisible', false),
      toolbarOverlays: state.viewport.toolbarOverlays,
      doneButtonLocale: selectors.locale.getDoneButtonLocale(state),
      editorInfoLocale: selectors.locale.getEditorLocale(state),
      helpModalTitle: selectors.locale.getHelpModalLocale(state).title,
      editorModeId: getModeIdFromState(state),
      isAirSlate: state.query.editType === editType.as,
    };
  }, {
    toggleThumbnailsVisibility,
    openCoreHelpModal,
  },
)
export default class HeaderPhone extends Component {
  static propTypes = {
    appStarted: PropTypes.bool.isRequired,
    isThumbnailsOpen: PropTypes.bool.isRequired,
    havePdfDocument: PropTypes.bool.isRequired,
    isThumbnailsMounted: PropTypes.bool.isRequired,
    isWizardLoaded: PropTypes.bool.isRequired,
    isWizardStartButtonVisible: PropTypes.bool.isRequired,
    toolbarOverlays: PropTypes.arrayOf(PropTypes.string).isRequired,
    doneButtonLocale: PropTypes.string.isRequired,
    editorInfoLocale: PropTypes.string.isRequired,
    helpModalTitle: PropTypes.string.isRequired,
    editorModeId: PropTypes.string.isRequired,
    isAirSlate: PropTypes.bool.isRequired,

    openCoreHelpModal: PropTypes.func.isRequired,
    toggleThumbnailsVisibility: PropTypes.func.isRequired,
  };

  onClickHelp = () => {
    const { helpModalTitle, editorModeId } = this.props;

    this.props.openCoreHelpModal({
      welcome: helpModalTitle,
      editorModeId,
      mode: DEFAULT_EDITOR_MODE,
      isResponsive: true,
    });
  };

  onToDoListClick = () => {
    dispatchAction(wizard.actions.setMobileTodoIsActive, true);
  };

  renderReadOnlyOverlay = () => {
    return (
      <HeaderPhoneOverlayView
        iconType={Ui.icon.types.eye}
        text={this.props.editorInfoLocale.readOnlyAccess}
      />
    );
  };

  renderWizardOverlay = () => {
    return (
      <HeaderPhoneOverlayView
        iconType={Ui.icon.types.magicWand}
        text={this.props.editorInfoLocale.fillingWizardMobile}
      />
    );
  };

  render = () => {
    const {
      appStarted,
      havePdfDocument,
      isThumbnailsOpen,
      isThumbnailsMounted,
      isWizardLoaded,
      isWizardStartButtonVisible,
      toolbarOverlays,
      doneButtonLocale,
      isAirSlate,
    } = this.props;

    const { wizardStartButton } = wizard.components;

    const hasReadOnlyAccessOverlay = getIsReadOnlyOverlayEnabled(toolbarOverlays);
    const hasFillingWizardOverlay = getIsWizardOverlayEnabled(toolbarOverlays);
    const isFillableMode = !hasReadOnlyAccessOverlay && !hasFillingWizardOverlay;

    const showToDoListButton =
      havePdfDocument &&
      isThumbnailsMounted &&
      isWizardLoaded &&
      !hasReadOnlyAccessOverlay;

    const showStartButton =
      isWizardLoaded &&
      showToDoListButton &&
      isWizardStartButtonVisible &&
      isFillableMode;

    const showMobileTools =
      !showStartButton &&
      isFillableMode;

    return (
      <Ui.Grid.CellGroup
        id={mainHeaderId}
        style={{ pointerEvents: appStarted
          ? 'auto'
          : 'none',
        }}
      >
        { hasReadOnlyAccessOverlay && this.renderReadOnlyOverlay() }
        { hasFillingWizardOverlay && this.renderWizardOverlay() }

        <Ui.Grid.Cell
          attributes={
            isFillableMode
              ? Ui.attributes.grow
              : null
          }
        >
          <Ui.ToolBar.Body
            size={Ui.toolBar.sizes.medium}
            theme={Ui.toolBar.themes.solidLightgray}
          >
            <Ui.ToolBar.Main>
              {
                showStartButton &&
                <Ui.ToolBar.Group attributes={Ui.attributes.grow}>
                  <LazyComponent
                    literal={wizardStartButton}
                  />
                </Ui.ToolBar.Group>
              }

              { showMobileTools && <MobileTools /> }

              <Ui.ToolBar.Group>
                {
                  showToDoListButton &&
                  <Ui.Container.SettingButton
                    icon={Ui.icon.types.checklist}
                    size={Ui.icon.sizes.medium}
                    offset={Ui.control.offsets.medium}
                    onClick={this.onToDoListClick}
                    inheritRadius
                  />
                }
                <MessageButtonProvider>
                  {({ onClickMessageButton, isMessageButtonVisible }) => {
                    if (isMessageButtonVisible) {
                      return (
                        <Ui.Container.SettingButton
                          icon={Ui.icon.types.search}
                          size={Ui.icon.sizes.medium}
                          offset={Ui.control.offsets.medium}
                          id={messageButtonHeaderId}
                          onClick={onClickMessageButton}
                          inheritRadius
                        />
                      );
                    }

                    return null;
                  }}
                </MessageButtonProvider>
                <Ui.Container.SettingButton
                  icon={Ui.icon.types.pages}
                  size={Ui.icon.sizes.medium}
                  offset={Ui.control.offsets.medium}
                  isPressed={isThumbnailsOpen}
                  onClick={this.props.toggleThumbnailsVisibility}
                  inheritRadius
                />
                {!isAirSlate && (
                  <Ui.Container.SettingButton
                    icon={Ui.icon.types.circledQuestion}
                    size={Ui.icon.sizes.medium}
                    offset={Ui.control.offsets.medium}
                    onClick={this.onClickHelp}
                    inheritRadius
                  />
                )}
              </Ui.ToolBar.Group>
              <Ui.ToolBar.Group>
                <HeaderDoneVisibilityProvider>
                  <HeaderDoneActionCreator>
                    {({ onDoneClick }) => {
                      return (
                        <Ui.Container.SettingButton
                          textSize={15}
                          theme={Ui.control.themes.solidPrimary}
                          label={doneButtonLocale}
                          offset={Ui.control.offsets.medium}
                          onClick={onDoneClick}
                          inheritRadius
                        />
                      );
                    }}
                  </HeaderDoneActionCreator>
                </HeaderDoneVisibilityProvider>
              </Ui.ToolBar.Group>
            </Ui.ToolBar.Main>
          </Ui.ToolBar.Body>
        </Ui.Grid.Cell>
      </Ui.Grid.CellGroup>
    );
  };
}
