import PropTypes from 'prop-types';
import React, { Component } from 'react';
import requiredIf from 'react-required-if';
import classnames from 'classnames';
import { svgStyle, wrapperDivStyle, getValidColor } from '../../../helpers/graphicUtils';
import { mouseFocusClassName } from '../../../helpers/const';
import { getElementCursor } from '../../../helpers/utils';

export default class ArrowToolView extends Component {
  static propTypes = {
    fillColor: PropTypes.string.isRequired,
    viewBox: PropTypes.string.isRequired, // SVG-viewbox
    path: PropTypes.string.isRequired, // SVG-path
    isDisabled: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    isGhost: PropTypes.bool.isRequired,

    onFocus: requiredIf(PropTypes.func, (props) => {
      return !props.isGhost;
    }),
    onBlur: requiredIf(PropTypes.func, (props) => {
      return !props.isGhost;
    }),
    storeRef: requiredIf(PropTypes.func, (props) => {
      return !props.isGhost;
    }),
  };

  static defaultProps = {
    onFocus: null,
    onBlur: null,
    storeRef: null,
  };

  getWrapperStyles = () => {
    const cursor = getElementCursor(this.props.isDisabled);

    return { cursor, ...wrapperDivStyle };
  };

  render() {
    const {
      fillColor,
      viewBox,
      path,
      isDisabled,

      onFocus,
      onBlur,
      storeRef,
    } = this.props;

    const wrapperStyles = this.getWrapperStyles();

    return (
      <div
        className="ArrowToolView-tabindex"
        style={wrapperStyles}

        onFocus={onFocus}
        onBlur={onBlur}
        ref={storeRef}
        tabIndex={0}
      >
        <svg
          preserveAspectRatio="none"
          viewBox={viewBox}
          style={svgStyle}
        >
          <path
            className={classnames({
              [mouseFocusClassName]: !isDisabled,
              'elementHover-Content': !isDisabled,
            })}
            d={path}
            fill={getValidColor(fillColor)}
          />
        </svg>
      </div>
    );
  }
}
