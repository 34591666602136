import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { thisDevice } from '@pdffiller/jsf-useragent';

import { popupMenu, fontsMap, fontsList } from '../../../../../helpers/const';
import * as Ui from '../../../../../ui';

import { toolbar } from '../../../../../store/selectors';
import setFontFamily from '../../../../../store/thunks/setFontFamily';
import { setActivePopupMenu } from '../../../../../store/modules/viewport';


@connect(
  (state) => {
    const { fontFamily } = toolbar.getElementPropsForToolbar(state);
    return {
      fontFamily,
    };
  },
  {
    setFontFamily,
    setActivePopupMenu,
  },
)
export default class FontFamilyPopupSettings extends Component {
  static propTypes = {
    fontFamily: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    setActivePopupMenu: PropTypes.func.isRequired,
    setFontFamily: PropTypes.func.isRequired,
  }

  backToMain = () => {
    this.props.setActivePopupMenu(popupMenu.main);
  }

  onFontFamilyChange = (value) => {
    return () => {
      this.props.setFontFamily(value);
    };
  }

  renderFontFamilyDiv = (item) => {
    const { fontFamily } = this.props;
    const font = fontsMap[item];

    return (
      <Ui.Menu.ListItem key={font.value}>
        <Ui.Menu.Item
          onClick={this.onFontFamilyChange(font.value)}
        >
          <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.body}>
            <Ui.Menu.ItemByType attributes={Ui.attributes.grow}>
              <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.text}>
                <Ui.Text size={16} align={Ui.text.aligns.left}>
                  {font.label}
                </Ui.Text>
              </Ui.Menu.ItemByType>
            </Ui.Menu.ItemByType>

            {font.value === fontFamily &&
              <Ui.Menu.ItemByType>
                <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.checkMark}>
                  <Ui.Icon
                    type={Ui.icon.types.checkMark}
                    size={Ui.icon.sizes.small}
                  />
                </Ui.Menu.ItemByType>
              </Ui.Menu.ItemByType>
            }
          </Ui.Menu.ItemByType>
        </Ui.Menu.Item>
      </Ui.Menu.ListItem>
    );
  };

  render() {
    const textWeight = thisDevice.isDesktop
      ? Ui.text.weights.regular
      : null;
    const offset = thisDevice.isDesktop
      ? Ui.control.offsets.medium
      : null;

    return (
      <Ui.Slider.ContentCell>

        <Ui.ToolBar.Body
          size={Ui.toolBar.sizes.medium}
          theme={Ui.toolBar.themes.solidLightgray}
          offset={Ui.toolBar.offsets.extraSmallHorizontal}
        >
          <Ui.ToolBar.Main>
            <Ui.ToolBar.Group>
              <Ui.Container.SettingButton
                icon={Ui.icon.types.arrowLeft}
                theme={Ui.control.themes.transparentBlue}
                behavior={Ui.control.behaviors.null}
                size={Ui.icon.sizes.small}
                label={this.props.locale}
                textSize={15}
                onClick={this.backToMain}
                textWeight={textWeight}
                offset={offset}
              />
            </Ui.ToolBar.Group>
          </Ui.ToolBar.Main>
        </Ui.ToolBar.Body>

        <Ui.ScrollBox>
          <Ui.Menu.Body>
            <Ui.Menu.List
              onTouchStart={(event) => {
                event.stopPropagation();
              }}
            >
              {fontsList.map(this.renderFontFamilyDiv)}
            </Ui.Menu.List>
          </Ui.Menu.Body>
        </Ui.ScrollBox>

      </Ui.Slider.ContentCell>
    );
  }
}
