export const snf = {
  width: '100%',
  height: '100%',
  background: 'rgba(255, 255, 255, 0.5)',
  position: 'absolute',
  top: 0,
  left: 0,
};

export const jsf = {
  width: '100%',
  height: '100%',
  background: 'rgba(255, 255, 255, 0.5)',
  position: 'absolute',
  top: 0,
  left: 0,
};
