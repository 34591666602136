import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { popupMenu } from '../../../../helpers/const';
import * as Ui from '../../../../ui';

export default class PopupMenuItem extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string, PropTypes.number,
    ]).isRequired,
    showPopupMenuItem: PropTypes.func.isRequired,
  }

  render() {
    return (
      <Ui.Menu.ListItem key={popupMenu.fontFamily}>
        <Ui.Menu.Item
          type={Ui.menu.itemsTypes.dropdown}
          dataPointer={Ui.control.dataPointers.arrowRight}
          onClick={this.props.showPopupMenuItem}
        >
          <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.body}>

            <Ui.Menu.ItemByType attributes={Ui.attributes.grow}>
              <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.text}>
                <Ui.Text size={16} align={Ui.text.aligns.left}>
                  {this.props.label}
                </Ui.Text>
              </Ui.Menu.ItemByType>
            </Ui.Menu.ItemByType>

            <Ui.Menu.ItemByType attributes={Ui.attributes.shrinkOff}>
              <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.text}>
                <Ui.Text size={16} align={Ui.text.aligns.right}>
                  {this.props.value}
                </Ui.Text>
              </Ui.Menu.ItemByType>
            </Ui.Menu.ItemByType>

          </Ui.Menu.ItemByType>
        </Ui.Menu.Item>
      </Ui.Menu.ListItem>
    );
  }
}
