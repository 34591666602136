import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { thisDevice } from '@pdffiller/jsf-useragent';
import ResizeBox from '../../ResizeBox/ResizeBox';
import GhostButton from '../../Ghost/GhostButton';

import {
  getValidColor,
  svgStyle,
  wrapperDivStyle,
} from '../../../helpers/graphicUtils';
import {
  defaultZoomScale,
  longTapClassName,
  mouseFocusClassName,
  positions,
} from '../../../helpers/const';

export default class RectToolView extends Component {
  static propTypes = {
    fillColor: PropTypes.string.isRequired,
    fillAlpha: PropTypes.number, // not required, for 'highlight' only
    viewBox: PropTypes.string.isRequired, // SVG-viewbox
    path: PropTypes.string.isRequired, // SVG-path
    element: PropTypes.shape({
      type: PropTypes.string,
      content: PropTypes.shape({
        linkedTextId: PropTypes.string,
      }),
    }),
    isGhost: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isEraseElement: PropTypes.bool.isRequired,

    needResizeBox: PropTypes.bool.isRequired,

    // All props below not required for ghost or new element that is being drawn now
    scale: PropTypes.number,

    // from elementDraggableDecorator
    onResizeDragStart: PropTypes.func,
    onResizeDragMove: PropTypes.func,
    onResizeDragStop: PropTypes.func,
    isDragging: PropTypes.bool,
    resizeIndex: PropTypes.number,

    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    storeRef: PropTypes.func.isRequired,
  };

  static defaultProps = {
    element: null,
    fillAlpha: 1,
    isGhost: false,
    onResizeDragStart: () => {
      return false;
    },
    onResizeDragMove: () => {
      return false;
    },
    onResizeDragStop: () => {
      return false;
    },
    scale: defaultZoomScale,
    isDragging: false,
    resizeIndex: positions.none,
    isDisabled: false,
  };

  render() {
    const {
      fillColor,
      fillAlpha,
      viewBox,
      path,
      isGhost,
      element,
      isDisabled,
      isDragging,
      isEraseElement,
      resizeIndex,
      scale,
      needResizeBox,

      onFocus,
      onBlur,
      storeRef,
    } = this.props;

    const type = element
      ? element.type
      : false;

    return (
      <div
        className="RectToolView-tabindex"
        style={wrapperDivStyle}
        onBlur={onBlur}
        onFocus={onFocus}
        ref={storeRef}
        tabIndex={
          isDisabled
            ? null
            : 0
        }
      >
        {needResizeBox && <ResizeBox
          onResizeDragStart={this.props.onResizeDragStart}
          onResizeDragMove={this.props.onResizeDragMove}
          onResizeDragStop={this.props.onResizeDragStop}
          isDragging={isDragging}
          resizeIndex={resizeIndex}
          scale={scale}
          isForGraphicTool
        />}
        <svg
          preserveAspectRatio="none"
          viewBox={viewBox}
          style={svgStyle}
        >
          <g>
            <path
              focusable={!isDisabled}
              className={classnames({
                'elementHover-Content': !isDisabled,
                [mouseFocusClassName]:
                  !isDisabled && !isGhost && (!type || !isEraseElement),
                [longTapClassName]:
                  !isDisabled && thisDevice.isMobile && isEraseElement,
              })}
              d={path}
              style={{
                stroke: getValidColor(fillColor),
                strokeWidth: 1,
                fill: getValidColor(fillColor),
                opacity: fillAlpha,
              }}
            />
          </g>
        </svg>
        {isEraseElement && (
        // JSF-7755: this is done in order to be able to add free tools over erase element
          <GhostButton />
        )}
      </div>
    );
  }
}
