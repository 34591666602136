import PropTypes from 'prop-types';
import React from 'react';

import {
  FocusControllerDisableBlur,
} from '@pdffiller/jsf-focuscontroller';

import * as Ui from '../../../ui';
import Dropdown from '../../Dropdown/Dropdown';
import { possibleZoomRange } from '../../../helpers/const';
import { SCALE_FIT_PAGE, SCALE_FIT_WIDTH } from '../../../store/modules/navigationActionTypes';

const getValueString = (scale, locale) => {
  if (scale === SCALE_FIT_PAGE) {
    return locale.fitPage;
  }
  if (scale === SCALE_FIT_WIDTH) {
    return locale.fitWidth;
  }
  if (Number.isNaN(scale) || !scale) {
    return locale.zoom;
  }
  return `${Math.round(scale * 100)}%`;
};

const values = (locale) => {
  return [
    {
      value: SCALE_FIT_PAGE,
      label: getValueString(SCALE_FIT_PAGE, locale),
    },
    {
      value: SCALE_FIT_WIDTH,
      label: getValueString(SCALE_FIT_WIDTH, locale),
    },
    ...possibleZoomRange,
  ];
};

const filterOnlyPossibleZoomRange = (el) => {
  return (
    el.value !== SCALE_FIT_PAGE &&
    el.value !== SCALE_FIT_WIDTH
  );
};

const filterFitPageFitWidth = (el) => {
  return (
    el.value === SCALE_FIT_PAGE ||
    el.value === SCALE_FIT_WIDTH
  );
};

const renderOption = (opt, scale, onChange) => {
  const onClick = () => {
    onChange(opt);
  };

  return (
    <Ui.Menu.ListItem key={opt.value}>
      <Ui.Control
        size={Ui.control.sizes.smallStretched}
        offset={Ui.control.offsets.medium}
        justify={Ui.control.justifies.flexStart}
        onClick={onClick}
        isPressed={scale === opt.value}
        inheritRadius
      >
        <Ui.Text
          size={14}
          weight={Ui.text.weights.regular}
        >
          {opt.label}
        </Ui.Text>
      </Ui.Control>
    </Ui.Menu.ListItem>
  );
};

const renderDisabled = (scale, locale, scaleValue) => {
  return (
    <div
      className="select-zoom jsf-header-nav-item__title"
      style={{ height: '50px' }}
      data-scale={scaleValue}
    >
      {getValueString(scale, locale)}
    </div>
  );
};

const renderOptions = (scale, locale, onChange) => {
  const options = values(locale);

  return (
    <Ui.Menu.Body>
      <Ui.Menu.List>
        {options
          .filter(filterFitPageFitWidth)
          .map((el) => {
            return renderOption(el, scale, onChange);
          })
        }
        <Ui.Menu.Separator />
      </Ui.Menu.List>

      <Ui.Menu.List>
        {options
          .filter(filterOnlyPossibleZoomRange)
          .map((el) => {
            return renderOption(el, scale, onChange);
          })
        }
      </Ui.Menu.List>
    </Ui.Menu.Body>
  );
};

const headerZoomView =
  ({ scale, onChange, locale, isDisabled, scaleValue, onClickHandlerCreator }) => {
    return (
      <Dropdown
        dropdownComponent={() => {
          return isDisabled
            ? renderDisabled(scale, locale, scaleValue)
            : renderOptions(scale, locale, onChange);
        }}
        clickCloses
      >
        {({ togglePortal, isPortalOpen, storeRef }) => {
          return (
            <Ui.ToolBar.Item>
              <Ui.Control
                behavior={Ui.control.behaviors.column}
                title={Ui.locales.zoom}
                icon={
                  <Ui.Icon
                    type={Ui.icon.types.pageZoom}
                    size={Ui.icon.sizes.large}
                  />
                }
                offset={Ui.control.offsets.extraSmall}
                onClick={onClickHandlerCreator(isPortalOpen, togglePortal)}
                isPressed={isPortalOpen}
                storeRef={storeRef}
              >
                <Ui.Text
                  weight={Ui.text.weights.bold}
                  offset={Ui.text.offsets.topExtraSmall}
                  size={11}
                >
                  {getValueString(scale, locale)}
                </Ui.Text>
              </Ui.Control>
              <FocusControllerDisableBlur
                disabled={isPortalOpen}
              />
            </Ui.ToolBar.Item>
          );
        }}
      </Dropdown>
    );
  };


headerZoomView.propTypes = {
  scale: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  scaleValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  locale: PropTypes.shape({
    fitPage: PropTypes.string.isRequired,
    fitWidth: PropTypes.string.isRequired,
    zoom: PropTypes.string.isRequired,
  }).isRequired,
  onClickHandlerCreator: PropTypes.func.isRequired,
};

export default headerZoomView;
