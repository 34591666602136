import updateElement from './updateElement';
import { toolbar } from '../selectors';

const toggleElementFontProperty = (fontProperty) => {
  return (dispatch, getState) => {
    const { id, [fontProperty]: property } = toolbar.getElementPropsForToolbar(getState());
    return dispatch(updateElement(id, { [fontProperty]: !property }));
  };
};

export default toggleElementFontProperty;
