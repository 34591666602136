import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { selectors } from '../..';
import { updateDraggablePanelPosition } from '../../store/modules/viewport';

@connect(
  (state, { entity }) => {
    const workspace = selectors.base.getNavigationBody(state);
    const defaultWorkspace = selectors.base.getWorkspace(state);
    const draggablePanelPosition = selectors.base.getDraggablePanelPosition(state, entity);

    return {
      workspace,
      defaultWorkspace,
      draggablePanelPosition,
    };
  }, {
    updateDraggablePanelPosition,
  },
)

export default class DraggablePanelProvider extends Component {
  static propTypes = {
    entity: PropTypes.string.isRequired,
    workspace: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      top: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
    }).isRequired,
    defaultWorkspace: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      top: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
    }).isRequired,
    draggablePanelPosition: PropTypes.shape({}).isRequired,
    updateDraggablePanelPosition: PropTypes.func.isRequired,

    children: PropTypes.func.isRequired,
  };

  onChangePosition = (position) => {
    this.props.updateDraggablePanelPosition(this.props.entity, position);
  };

  render() {
    const { workspace, defaultWorkspace, draggablePanelPosition } = this.props;

    return this.props.children({
      workspace,
      defaultWorkspace,
      draggablePanelPosition,
      onChangePosition: this.onChangePosition,
    });
  }
}
