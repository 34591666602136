import PropTypes from 'prop-types';
import React, { Component } from 'react';

import PageContextProvider from '../Page/PageContextProvider/PageContextProvider';

import GhostWrapperView from './GhostWrapperView';
import GhostLayerView from './GhostLayerView';

export default class GhostRenderer extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    workspace: PropTypes.shape({
      framePadding: PropTypes.shape({
        top: PropTypes.number.isRequired,
        bottom: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        right: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    frameOffset: PropTypes.shape({
      scrollTop: PropTypes.number.isRequired,
      top: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
    }).isRequired,
    isGhostHidden: PropTypes.bool.isRequired,
    getPagesViewport: PropTypes.func.isRequired,
    scale: PropTypes.number.isRequired,
    ghostElementForRender: PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    ghostPageId: PropTypes.number.isRequired,
    getGhostPosition: PropTypes.func.isRequired,
  };

  getGhostPositionForDom = () => {
    const { workspace: { framePadding } } = this.props;
    const { top: marginTop, left: marginLeft } = this.props.frameOffset;
    const { x, y } = this.props.getGhostPosition();

    return {
      x: x + marginLeft + framePadding.left,
      y: y + marginTop + framePadding.top,
    };
  };

  render = () => {
    const {
      ghostElementForRender,
      ghostPageId,
      scale,
    } = this.props;

    const { width, height, x, y } = {
      width: ghostElementForRender.content.width * scale,
      height: ghostElementForRender.content.height * scale,
      ...this.getGhostPositionForDom(),
    };

    return (
      <GhostLayerView>
        <PageContextProvider
          getPagesViewport={this.props.getPagesViewport}
          pageId={ghostPageId}
        >
          <GhostWrapperView
            x={x}
            y={y}
            width={width}
            height={height}
            isHidden={this.props.isGhostHidden}
          >
            {this.props.children}
          </GhostWrapperView>
        </PageContextProvider>
      </GhostLayerView>

    );
  };
}
