import clamp from 'lodash/clamp';

import { isTop, isBottom, isLeft, isRight } from './resizeUtils';

// when cursor reaches 'whenScrollStarts' pixels from the edge - do scroll
const whenScrollStarts = 30; // px

// how much scroll do we perform in one step
const scrollDelta = 30; // px

// interval given in throttle()
export const scrollInterval = 10; // ms

/**
 * Get directions, where we do scroll on element drag or resize
 *
 * @param {object} state - elementDraggableDecorator's state
 * @returns {object} { up, down, left, right } - booleans, directions where we will scroll
 */
export const getScrollDirection = (state, viewport) => {
  const { frameOffset, workspace, frameSize } = viewport;
  const { dragEndPoint, resizeIndex } = state;

  // Step 1: get where we can scroll (accroding to current frameOffset)
  const canScroll = {
    up: frameOffset.scrollTop > 0,
    down: frameOffset.scrollTop + workspace.height <
      frameSize.height + (workspace.framePadding.top + workspace.framePadding.bottom),
    left: frameOffset.scrollLeft > 0,
    right: frameOffset.scrollLeft + workspace.width <
      frameSize.width + (workspace.framePadding.left + workspace.framePadding.right),
  };

  // Step 2: get where we want to scroll (according to current mouse pos)
  const canScrollVert = resizeIndex
    ? isTop(resizeIndex) || isBottom(resizeIndex)
    : true;

  const canScrollHor = resizeIndex
    ? isLeft(resizeIndex) || isRight(resizeIndex)
    : true;
  const wantScroll = {
    up: (dragEndPoint.y - workspace.top < whenScrollStarts) && canScrollVert,
    down: (dragEndPoint.y - workspace.top > workspace.height - whenScrollStarts) && canScrollVert,
    left: (dragEndPoint.x - workspace.left < whenScrollStarts) && canScrollHor,
    right: (dragEndPoint.x - workspace.left > workspace.width - whenScrollStarts) && canScrollHor,
  };

  // Step 3: we will scroll when our desires coincide with our opportunities
  const up = canScroll.up && wantScroll.up;
  const down = canScroll.down && wantScroll.down;
  const left = canScroll.left && wantScroll.left;
  const right = canScroll.right && wantScroll.right;

  if (!up && !down && !left && !right) {
    return false;
  }
  return { up, down, left, right };
};

/**
 * Get object { scrollTop, scrollLeft } to pass into PageScroll.js
 *
 * @param {object} scrollDirection - { up, down, left, right } - result of getScrollDirection() work
 * @returns {object} - new { scrollTop, scrollLeft }
 */
export const getScrollObject = (scrollDirection, viewport) => {
  const { frameOffset, frameSize, workspace } = viewport;

  const dOffset = {
    top: (scrollDirection.up
      ? -scrollDelta
      : 0
    ) + (scrollDirection.down
      ? scrollDelta
      : 0
    ),

    left: (
      scrollDirection.left
        ? -scrollDelta
        : 0
    ) + (
      scrollDirection.right
        ? scrollDelta
        : 0
    ),
  };

  const paddingsHeight = workspace.framePadding.top + workspace.framePadding.bottom;
  const paddingsWidth = workspace.framePadding.left + workspace.framePadding.right;

  const maxOffset = {
    top: (frameSize.height + paddingsHeight) - workspace.height,
    left: (frameSize.width + paddingsWidth) - workspace.width,
  };

  return {
    scrollTop: clamp(frameOffset.scrollTop + dOffset.top, 0, maxOffset.top),
    scrollLeft: clamp(frameOffset.scrollLeft + dOffset.left, 0, maxOffset.left),
  };
};
