import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DraggableCore } from 'react-draggable';

import LineToolView from '../../../Tools/LineTool/LineToolView';
import ArrowToolView from '../../../Tools/ArrowTool/ArrowToolView';
import RectToolView from '../../../Tools/RectTool/RectToolView';
import { pathFromPoints, drawArrow, directions } from '../../../../helpers/graphicUtils';
import { elemTypes, isDrawRectType, dragPointIndexes } from '../../../../helpers/elemTypes';
import { stopEvent } from '../../../../helpers/utils';
import SimpleToolEndPoint from '../../../Tools/LineTool/EndPoint/SimpleToolEndpoint';

export const getViewboxFromFrameSize = (frameSize) => {
  return `0 0 ${frameSize.width} ${frameSize.height}`;
};

export default class DrawingLayerPointsView extends Component {
  static propTypes = {
    frameSize: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }).isRequired,
    controlPoints: PropTypes.oneOfType([
      PropTypes.array.isRequired,
      PropTypes.bool.isRequired,
    ]),
    onDragStart: PropTypes.func.isRequired,
    onDragMove: PropTypes.func.isRequired,
    onDragStop: PropTypes.func.isRequired,
    onEndPointDragStart: PropTypes.func.isRequired,
    onEndPointDragMove: PropTypes.func.isRequired,
    onEndPointDragStop: PropTypes.func.isRequired,
    drawExisting: PropTypes.bool, // true if we focused some line element, need draw EndPoints
    isDraggingExistingObjectNow: PropTypes.bool, // true if we are dragging EndPoints now
    canCreateElements: PropTypes.bool.isRequired,
    scale: PropTypes.number.isRequired,
    element: PropTypes.shape({
      content: PropTypes.shape({
        fillColor: PropTypes.string.isRequired,
        fillAlpha: PropTypes.number, // not required, only for 'highlight' type
        lineWidth: PropTypes.number.isRequired,
        direction: PropTypes.oneOf(Object.values(directions)), // not required, arrow only
      }).isRequired,
      type: PropTypes.oneOf(Object.values(elemTypes)).isRequired,
    }).isRequired,
  };

  static defaultProps = {
    isDraggingExistingObjectNow: false,
    drawExisting: false,
    controlPoints: false,
  };

  renderComponent = () => {
    const {
      drawExisting,
      isDraggingExistingObjectNow,
      element,
      scale,
      controlPoints,
      frameSize,
    } = this.props;
    const { fillColor, fillAlpha, lineWidth, direction } = element.content;
    if (!controlPoints || (drawExisting && !isDraggingExistingObjectNow)) {
      return null;
    }

    if (element.type === elemTypes.arrow) {
      const arrow = drawArrow(controlPoints, lineWidth * scale, direction);
      const mappedArrow = {
        points: arrow.points.map((value, index) => {
          return value + controlPoints[index % 2];
        }),
      };
      const viewBox = getViewboxFromFrameSize(frameSize);
      const path = pathFromPoints(mappedArrow.points, true);

      return (
        <ArrowToolView
          fillColor={fillColor}
          path={path}
          viewBox={viewBox}
          isDisabled={false}
          isGhost
        />
      );
    }

    if (isDrawRectType(element.type)) {
      return (
        <RectToolView
          fillColor={fillColor}
          fillAlpha={fillAlpha}
          viewBox={getViewboxFromFrameSize(frameSize)}
          path={pathFromPoints(controlPoints)}
          needResizeBox={false}
          isDisabled={false}
        />
      );
    }

    return (
      <LineToolView
        fillColor={fillColor}
        lineWidth={lineWidth * scale}
        path={pathFromPoints(controlPoints)}
        viewBox={getViewboxFromFrameSize(frameSize)}
        isDisabled={false}
      />
    );
  };

  renderEndPoint = (index) => {
    const { controlPoints, scale, element } = this.props;
    const { lineWidth, direction } = element.content;

    const endPoints = element.type === elemTypes.arrow
      ? drawArrow(controlPoints, lineWidth * scale, direction)
        .endPoints
        .map((value, jndex) => {
          return value + controlPoints[jndex % 2];
        })
      : controlPoints;

    const left = endPoints[index * 2];
    const top = endPoints[(index * 2) + 1];
    return (
      <SimpleToolEndPoint
        key={index}
        onDragStart={this.props.onEndPointDragStart}
        onDragMove={this.props.onEndPointDragMove}
        onDragStop={this.props.onEndPointDragStop}
        top={top}
        left={left}
        zIndex={9999}
        scale={scale}
        resizeIndex={index}
      />
    );
  };

  render() {
    const { drawExisting, element } = this.props;
    const needRenderEndPoints = !isDrawRectType(element.type) && drawExisting;

    return (
      <div
        className="drawingLayerPointsView-DrawingLayerPoints"
        style={{
          pointerEvents: this.props.canCreateElements
            ? 'auto'
            : 'none',
        }}

        /**
         * DrawingLayerView вынесен из ClickController'a в процессе имплементцаии Zoom by CSS
         * поэтому сейчас клики стали подниматься выше, нужно остановить всплытие
         */
        onMouseUp={stopEvent}
        onClick={stopEvent}
      >
        <div className="drawingElement-DrawingLayerPoints">
          {this.renderComponent()}
        </div>
        <DraggableCore
          onStart={this.props.onDragStart}
          onDrag={this.props.onDragMove}
          onStop={this.props.onDragStop}
          enableUserSelectHack={false}
        >
          <div
            className="dragHandler-DrawingLayerPoints"
            data-autotest="drawingLayerPoints"
          />
        </DraggableCore>
        {needRenderEndPoints && this.renderEndPoint(dragPointIndexes.start)}
        {needRenderEndPoints && this.renderEndPoint(dragPointIndexes.end)}
      </div>
    );
  }
}
