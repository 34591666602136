import PropTypes from 'prop-types';
import React, { Component } from 'react';

import IfContentShown from '../../Logic/IfContentShown';
import Content from '../../Content/Content';

export default class PageCanvas extends Component {
  static propTypes = {
    pageId: PropTypes.number.isRequired,
    storeRef: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className="layersWrapper-PageCanvas">
        <div className="pageCanvas-PageCanvas" ref={this.props.storeRef} />
        <IfContentShown pageId={this.props.pageId}>
          <Content pageId={this.props.pageId} />
        </IfContentShown>
      </div>
    );
  }
}
