import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { DONE_BUTTON_DESTROY_TYPE } from 'ws-editor-lib/actions/constants';
import { thunks } from '../../..';

@connect(null, {
  onDoneButtonOptionClickFactory: thunks.onDoneButtonOptionClickFactory,
})
export default class HeaderDoneActionCreator extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    onDoneButtonOptionClickFactory: PropTypes.func.isRequired,
  };

  onDoneClick =
    this.props.onDoneButtonOptionClickFactory({ destroyType: DONE_BUTTON_DESTROY_TYPE });

  render() {
    return this.props.children({
      onDoneClick: this.onDoneClick,
      onOptionClick: this.props.onDoneButtonOptionClickFactory,
    });
  }
}
