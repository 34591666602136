import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as JsfUi from 'jsfcore/ui';
import DatePickerView from './DatePickerView';
import DatePickerContext from './DatePickerContext';
import { currentDate } from '../datePickerUtils.js';

const getDateToShow = ({ isCalendarEnabled, isTimeEnabled, shownDate, selectedDate }) => {
  const propsDate = shownDate || selectedDate;

  if (isCalendarEnabled || isTimeEnabled) {
    return propsDate || currentDate.clone();
  }
  return propsDate || selectedDate;
};

export default class DatePicker extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    selectedDate: PropTypes.instanceOf(moment),
    shownDate: PropTypes.instanceOf(moment),
    isCalendarEnabled: PropTypes.bool,
    isTimeEnabled: PropTypes.bool,
    isMonthYearSelectEnabled: PropTypes.bool,
    is24Format: PropTypes.bool,
    timeAmPmTheme: PropTypes.oneOf(Object.values(JsfUi.control.themes)),
    yearsOffset: PropTypes.number,
    setSecondsOnCurrentDateClick: PropTypes.bool,
    needShowCurrentTimeButton: PropTypes.bool,
    toggleWizardNext: PropTypes.func,
  };

  static defaultProps = {
    selectedDate: null,
    shownDate: null,
    isCalendarEnabled: true,
    isTimeEnabled: false,
    isMonthYearSelectEnabled: true,
    is24Format: false,
    timeAmPmTheme: JsfUi.control.themes.toggleSecondary,
    yearsOffset: undefined,
    setSecondsOnCurrentDateClick: false,
    needShowCurrentTimeButton: false,
    toggleWizardNext: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      shownDate: getDateToShow(props),
      tempMonth: null,
      tempYear: null,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.shownDate !== this.props.shownDate ||
      nextProps.selectedDate !== this.props.selectedDate
    ) {
      this.setState((prevState) => {
        return {
          shownDate: getDateToShow(nextProps),
          tempYear: nextProps.shownDate !== null || nextProps.selectedDate !== null
            ? null
            : prevState.tempYear,
          tempMonth: nextProps.shownDate !== null || nextProps.selectedDate !== null
            ? null
            : prevState.tempMonth,
        };
      });
    }
  }

  // call onChange when datepicker only has isMonthYearSelectEnabled
  componentDidUpdate(_, prevState) {
    const { isCalendarEnabled, isTimeEnabled, isMonthYearSelectEnabled } = this.props;

    const onlyMonthYearSelectEnabled = (
      !isCalendarEnabled &&
      !isTimeEnabled &&
      isMonthYearSelectEnabled
    );

    const isShownDateUpdated = (
      this.state.shownDate !== null &&
      prevState.shownDate !== this.state.shownDate
    );

    if (onlyMonthYearSelectEnabled && isShownDateUpdated) {
      this.props.onChange(this.state.shownDate.clone());
    }
  }

  createDateIfTempVariablesAreSet = () => {
    if (
      this.state.tempMonth !== null &&
      this.state.tempYear !== null
    ) {
      this.props.onChange(moment({
        year: this.state.tempYear,
        month: this.state.tempMonth,
      }));
      return;
    }

    if (this.state.shownDate) {
      this.props.onChange(this.state.shownDate);
    }
  };

  toggleNext = () => {
    if (!this.props.isTimeEnabled) {
      this.props.toggleWizardNext();
    }
  };

  onChangeYear = (year) => {
    this.setState((prevState) => {
      if (prevState.shownDate !== null) {
        return {
          shownDate: prevState.shownDate
            .clone()
            .year(year),
        };
      }

      return { tempYear: year };
    }, this.createDateIfTempVariablesAreSet);
  };

  onChangeMonth = (month) => {
    this.setState((prevState) => {
      if (prevState.shownDate !== null) {
        return {
          shownDate: prevState.shownDate
            .clone()
            .month(month),
        };
      }

      return { tempMonth: month };
    }, this.createDateIfTempVariablesAreSet);
  };

  onChangeDay = (day) => {
    this.props.onChange(
      this.state.shownDate
        .clone()
        .date(day),
    );
    this.toggleNext();
  };

  onCurrentDateClick = () => {
    this.props.onChange(
      this.props.setSecondsOnCurrentDateClick
        ? moment()
        : moment().seconds(0),
    );

    // set selected month and year as current
    //
    // we should manually call setState as if
    // selectedDate is already as current, then
    // there won't be any rerender down to datepicker
    this.setState({
      shownDate: getDateToShow(this.props),
    });
    this.toggleNext();
  };

  render() {
    const shownMonth = this.state.shownDate
      ? this.state.shownDate.month()
      : this.state.tempMonth;

    const shownYear = this.state.shownDate
      ? this.state.shownDate.year()
      : this.state.tempYear;

    const contextValues = {
      needShowCurrentTimeButton: this.props.needShowCurrentTimeButton,
      timeAmPmTheme: this.props.timeAmPmTheme,
    };

    return (
      <DatePickerContext.Provider value={contextValues}>
        <DatePickerView
          selectedDate={this.props.selectedDate}
          shownDate={this.state.shownDate}
          shownMonth={shownMonth}
          shownYear={shownYear}
          isCalendarEnabled={this.props.isCalendarEnabled}
          isTimeEnabled={this.props.isTimeEnabled}
          isMonthYearSelectEnabled={this.props.isMonthYearSelectEnabled}
          is24Format={this.props.is24Format}
          onChangeYear={this.onChangeYear}
          onChangeMonth={this.onChangeMonth}
          onChangeDay={this.onChangeDay}
          onChange={this.props.onChange}
          onCurrentDateClick={this.onCurrentDateClick}
          yearsOffset={this.props.yearsOffset}
          timeAmPmTheme={this.props.timeAmPmTheme}
        />
      </DatePickerContext.Provider>
    );
  }
}
