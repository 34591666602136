import PropTypes from 'prop-types';
import React from 'react';
import { FocusControllerRestoreFocusArea } from '@pdffiller/jsf-focuscontroller';
import { thisDevice } from '@pdffiller/jsf-useragent';

import ToolbarThumbnailsButton from './ToolbarThumbnailsButton/ToolbarThumbnailsButton';
import ToolbarRearrangeButton from './ToolbarRearrangeButton/ToolbarRearrangeButton';
import ToolbarUndoRedoButton from './ToolbarUndoRedoButton/ToolbarUndoRedoButton';
import * as Ui from '../../../ui';

const undoRedoNavigationView = ({
  onClickPages,
  onClickRearrange,
  onClickUndo,
  onClickRedo,
  isThumbnailsOpen,
  isUndoAvailable,
  isRedoAvailable,
  locale,
}) => {
  return (
    <Ui.Grid.Cell size={Ui.grid.sizes.small}>
      <FocusControllerRestoreFocusArea
        isSafariDesktop={thisDevice.isSafariDesktop}
        style={{ flexGrow: 1 }}
      >
        <Ui.ToolBar.Body
          theme={Ui.toolBar.themes.bordered}
          size={Ui.toolBar.sizes.mediumStretched}
        >
          <Ui.ToolBar.Main>

            <ToolbarThumbnailsButton
              isThumbnailsOpen={isThumbnailsOpen}
              onClickPages={onClickPages}
            />

            <ToolbarRearrangeButton
              onClickRearrange={onClickRearrange}
            />

            <ToolbarUndoRedoButton
              icon={Ui.icon.types.undo}
              isDisabled={!isUndoAvailable}
              onClick={onClickUndo}
              hintHtml={locale.undo}
            />

            <ToolbarUndoRedoButton
              icon={Ui.icon.types.redo}
              isDisabled={!isRedoAvailable}
              onClick={onClickRedo}
              hintHtml={locale.redo}
            />

          </Ui.ToolBar.Main>
        </Ui.ToolBar.Body>
      </FocusControllerRestoreFocusArea>
    </Ui.Grid.Cell>
  );
};

undoRedoNavigationView.defaultProps = {
  isUndoAvailable: false,
  isRedoAvailable: false,
};

undoRedoNavigationView.propTypes = {
  onClickPages: PropTypes.func.isRequired,
  onClickRearrange: PropTypes.func.isRequired,
  onClickUndo: PropTypes.func.isRequired,
  onClickRedo: PropTypes.func.isRequired,
  isUndoAvailable: PropTypes.bool,
  isRedoAvailable: PropTypes.bool,
  isThumbnailsOpen: PropTypes.bool.isRequired,
  locale: PropTypes.shape({
    undo: PropTypes.string,
    redo: PropTypes.string,
  }).isRequired,
};

export default undoRedoNavigationView;
