import PropTypes from 'prop-types';
import React from 'react';
import GhostMobileDataProvider from './GhostDataProvider';
import GhostMobileElementCreator from './GhostElementCreator';
import GhostLimiter from '../GhostLimiter';
import GhostCreateTrigger from '../GhostCreateTrigger';
import { haveDataForRenderGhost } from '../ghostUtils';

const renderGhost = ({ frameSizes, workspace, scales, pdfPages }) => {
  return (
    <GhostMobileDataProvider
      frameSizes={frameSizes}
      workspace={workspace}
      scales={scales}
    >
      {({
        scale,
        frameSize,
        ghostElementForRender,
        getScaledGhostPosition,
        getPropsForElementCreating,
        activePageId,
      }) => {
        if (haveDataForRenderGhost({
          ghostElementForRender, pdfPages, pageId: activePageId,
        })) {
          return (
            <div>
              <GhostLimiter
                frameSize={frameSize}
                getScaledGhostPosition={getScaledGhostPosition}
                ghostElementForRender={ghostElementForRender}
                scale={scale}
                workspace={workspace}
              >
                {({ getLimitedScaledGhostPosition }) => {
                  return (
                    <div>
                      <GhostMobileElementCreator
                        getGhostPosition={getLimitedScaledGhostPosition}
                        scale={scale}
                        activePageId={activePageId}
                        getPropsForElementCreating={getPropsForElementCreating}
                      >
                        {({ createElement }) => {
                          return (
                            <GhostCreateTrigger
                              createElement={createElement}
                            />
                          );
                        }}
                      </GhostMobileElementCreator>
                    </div>
                  );
                }}
              </GhostLimiter>
            </div>
          );
        }

        return null;
      }}
    </GhostMobileDataProvider>
  );
};

renderGhost.propTypes = {
  frameSizes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        height: PropTypes.number.isRequired,
      }),
      PropTypes.bool,
    ]).isRequired,
  ).isRequired,
  workspace: PropTypes.shape({
    height: PropTypes.number.isRequired,
    framePadding: PropTypes.shape({
      top: PropTypes.number.isRequired,
      bottom: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
      right: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  scales: PropTypes.arrayOf(
    PropTypes.number,
  ).isRequired,
  pdfPages: PropTypes.arrayOf(
    PropTypes.number,
  ).isRequired,
};

export default renderGhost;
