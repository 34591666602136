import PropTypes from 'prop-types';
import { Component } from 'react';

export default class ContinuousScrollContextProvider extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
  };

  static childContextTypes = {
    subscribeToScroll: PropTypes.func,
    unsubscribeToScroll: PropTypes.func,
    onScroll: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.scrollListeners = [];
  }

  getChildContext = () => {
    return {
      subscribeToScroll: this.addScrollListener,
      unsubscribeToScroll: this.removeScrollListener,
      onScroll: this.onScroll,
    };
  };

  addScrollListener = (handler) => {
    this.scrollListeners = [
      ...this.scrollListeners,
      handler,
    ];
  };

  removeScrollListener = (handler) => {
    this.scrollListeners = this.scrollListeners.filter((h) => {
      return h !== handler;
    });
  };

  // call all subscribers on scroll
  onScroll = ({ scrollTop, scrollLeft }) => {
    this.scrollListeners.forEach((handler) => {
      handler({ scrollTop, scrollLeft });
    });
  };

  render() {
    return this.props.children;
  }
}
