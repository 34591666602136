import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { FocusController } from '@pdffiller/jsf-focuscontroller';
import { thisDevice } from '@pdffiller/jsf-useragent';

import { selectors, thunks } from '../..';
import { setKeyboardShown, setKeyboardSize } from '../../store/modules/viewport';

@connect(
  (state) => {
    return {
      activeElementId: selectors.base.getActiveElementId(state),
      keyboardSize: selectors.base.getKeyboardSize(state),
      isKeyboardShown: selectors.base.getKeyboardShown(state),
    };
  }, {
    setActiveElement: thunks.setActiveElement,
    setKeyboardShown,
    setKeyboardSize,
  },
)
export default class WrapperForFocusController extends Component {
  static propTypes = {
    activeElementId: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.bool.isRequired,
    ]).isRequired,
    setActiveElement: PropTypes.func.isRequired,

    keyboardSize: PropTypes.shape({
      portrait: PropTypes.number,
      landscape: PropTypes.number,
    }).isRequired,
    setKeyboardSize: PropTypes.func.isRequired,

    isKeyboardShown: PropTypes.bool.isRequired,
    setKeyboardShown: PropTypes.func.isRequired,

    children: PropTypes.element.isRequired,
  };

  setActiveElement = (id) => {
    const { activeElementId } = this.props;

    if (id) {
      if (activeElementId !== id) {
        this.props.setActiveElement(id, true);
      }
    } else if (activeElementId) {
      this.props.setActiveElement(activeElementId, false);
    }
  };

  setKeyboardSize = (size) => {
    if (!isEqual(size, this.props.keyboardSize)) {
      this.props.setKeyboardSize(size);
    }
  };

  setKeyboardShown = (shown) => {
    if (shown !== this.props.isKeyboardShown) {
      this.props.setKeyboardShown(shown);
    }
  };

  render() {
    return (
      <FocusController
        activeElementId={this.props.activeElementId || null}

        thisDevice={thisDevice}

        setActiveElementId={this.setActiveElement}
        setKeyboardShown={this.setKeyboardShown}
        setKeyboardSize={this.setKeyboardSize}
      >
        {this.props.children}
      </FocusController>
    );
  }
}
