import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { webPageId } from 'jsfcore/helpers/const';
import { selectors, thunks } from 'jsfcore';
import * as Ui from '../../../ui';
import { miscIds } from '../../../helpers/const';

@connect(
  (state) => {
    return {
      label: selectors.locale.getMiscLabel(state, miscIds.feedback.id),
      isFeedbackButtonVisible: selectors.getMiscFeatureVisible(state, miscIds.feedback.id),
    };
  }, {
    openFeedbackModal: thunks.openFeedbackModal,
  },
)
export default class HeaderFeedback extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    isFeedbackButtonVisible: PropTypes.bool.isRequired,

    openFeedbackModal: PropTypes.func.isRequired,
  };

  static contextTypes = {
    store: PropTypes.shape({
      dispatch: PropTypes.func.isRequired,
      getState: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    if (__CLIENT__) {
      window.webPageId = webPageId;
    }
  }

  onClick = () => {
    this.props.openFeedbackModal();
  };

  render() {
    if (!this.props.isFeedbackButtonVisible) {
      return null;
    }

    return (
      <Ui.Container.SettingButton
        icon={Ui.icon.types.feedback}
        label={this.props.label}
        theme={Ui.control.themes.transparentGray}
        title={Ui.locales.titles.feedback}
        size={Ui.icon.sizes.large}
        offset={Ui.control.offsets.extraSmall}
        textOffset={Ui.text.offsets.topExtraSmall}
        onClick={this.onClick}
      />
    );
  }
}
