import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default class ListItem extends Component {
  static propTypes = {
    props: PropTypes.shape({}),
    className: PropTypes.string,
    children: PropTypes.element.isRequired,
  };

  static defaultProps = {
    className: null,
    props: null,
  };

  render() {
    const { children, props, className, ...otherProps } = this.props; // eslint-disable-line

    return (
      <li
        className={cx(
          'list__item', {
            [className]: className,
          },
        )}
        {...otherProps}
      >
        {children}
      </li>
    );
  }
}
