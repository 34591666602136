import floor from 'lodash/floor';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as Ui from '../../../ui';
import { getCellQuantity } from '../../../store/helpers/functions';

const cutTextIfRequired = (text, maxChars) => {
  const { length: textLength } = text;
  if (textLength <= maxChars) {
    return text;
  }

  const cuttedText = text.substr(0, maxChars);
  if (cuttedText.length > 2) {
    // todo improve
    return `${cuttedText.substr(0, maxChars - 2)}..`;
  }

  return cuttedText;
};

const FillablePlaceholderCells = ({ element, text }) => {
  const { width } = element.template;
  const maxChars = element.template.maxChars === ''
    ? getCellQuantity(width)
    : element.template.maxChars;

  const cellWidth = floor(width / maxChars, 1);
  const cuttedText = cutTextIfRequired(text, maxChars);
  const style = {
    width: cellWidth,
  };

  return (
    <Fragment>
      {Array.prototype.map.call(cuttedText, (letter, index) => {
        const normalizedLetter = letter.replace(/\s/, '&nbsp;');
        return (
          <Ui.FillableField.LabelCell
            key={cuttedText.substr(0, index + 1)}
            style={style}
            html={normalizedLetter}
          />
        );
      })}
    </Fragment>
  );
};

FillablePlaceholderCells.propTypes = {
  element: PropTypes.shape({
    template: PropTypes.shape({
      maxChars: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf(['']),
      ]),
      maxLines: PropTypes.number,
      width: PropTypes.number,
    }).isRequired,
  }).isRequired,
  text: PropTypes.string.isRequired,
};

export default FillablePlaceholderCells;
