import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as selectors from '../../store/selectors';
import {
  triggerMessageButtonClick,
} from '../../store/modules/events';

@connect(
  (state) => {
    return {
      locale: selectors.locale.getEditorLocale(state).toolbarHelpView,
      isMessageButtonVisible: state.viewport.isMessageButtonVisible,
    };
  },
  {
    triggerMessageButtonClick,
  },
)
class MessageButtonProvider extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    locale: PropTypes.objectOf(
      PropTypes.string,
    ).isRequired,
    triggerMessageButtonClick: PropTypes.func.isRequired,
    isMessageButtonVisible: PropTypes.bool.isRequired,
  };

  onClickMessageButton = () => {
    this.props.triggerMessageButtonClick();
  };

  render() {
    return this.props.children({
      locale: this.props.locale,
      onClickMessageButton: this.onClickMessageButton,
      isMessageButtonVisible: this.props.isMessageButtonVisible,
    });
  }
}

export default MessageButtonProvider;
