import PropTypes from 'prop-types';
import { Component } from 'react';
import { types, constructorType } from '../../helpers/tools';

export default class ElementTypesMapper extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,

    type: PropTypes.string.isRequired,
    subType: PropTypes.string.isRequired,
    isFConstuctorMode: PropTypes.bool,
  };

  static defaultProps = {
    isFConstuctorMode: false,
  };

  getComponent = () => {
    const { isFConstuctorMode, type, subType } = this.props;

    if (isFConstuctorMode) {
      return constructorType;
    }

    return types[type][subType || 'none'];
  };

  render() {
    return this.props.children({
      ComponentElement: this.getComponent(),
    });
  }
}
