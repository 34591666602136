import React from 'react';
import PropTypes from 'prop-types';
import HighlightedText from './HighlightedText';

const OptionRenderer = ({ label, inputValue }) => {
  return <HighlightedText searchedValue={inputValue} label={label} />;
};

OptionRenderer.propTypes = {
  label: PropTypes.string.isRequired,
  inputValue: PropTypes.string.isRequired,
};

export default OptionRenderer;
