import { zoomTick as zoomTickDefault } from './const';

export const isWheelRotatingUp = (event) => {
  return event.deltaY < 0;
};

export default class WheelConverter {
  constructor(props = { zoomTick: zoomTickDefault }) {
    this.zoomTick = props.zoomTick;
  }

  onStartToOnPinchStart = ({ workspace }) => {
    return {
      preventDefault: () => {
        return false;
      },
      center: {
        x: workspace.left + (workspace.width / 2),
        y: workspace.top + (workspace.height / 2),
      },
      notSetPagePinching: true,
    };
  }

  onMoveToOnPinchMove = (event, prevScale) => {
    const scale = isWheelRotatingUp(event)
      ? prevScale + this.zoomTick
      : prevScale - (this.zoomTick / 2);

    return {
      preventDefault: () => {
        return false;
      },
      scale: scale >= 0
        ? scale
        : 0.1,

      notSetPagePinching: true,
    };
  }

  onEndToOnPinchEnd = (scale) => {
    return {
      preventDefault: () => {
        return false;
      },
      scale,
      notSetPagePinching: true,
    };
  };
}
