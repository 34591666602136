import createInvisibleSpan from './helpers/createInvisibleSpan';
import getIsFontAvailable from './getIsFontAvailable';
import { getFontIdentifier } from './helpers/utils';

const fontCheckInterval = 500;
const fontWaitTimeout = 3000;

const addFontLoadCallback = (fontId, content, callback) => {
  // Логика с document.font load events не используется, т.к. может прилететь
  // не совсем тот фонт, который был запрошен (к примеру, normal вместо bold)
  // И эта логика по-хорошему поддерживается только в хроме.
  const interval = setInterval(() => {
    if (getIsFontAvailable(content)) {
      callback();
      clearInterval(interval);
    }
  }, fontCheckInterval);

  setTimeout(() => {
    callback();
    clearInterval(interval);
  }, fontWaitTimeout);
};

const loadFont = (content) => {
  // content: { fontFamily: string, bold: bool, italic: bool }

  return new Promise((resolve) => {
    const isFontAvailable = getIsFontAvailable(content);

    if (!isFontAvailable) {
      const fontId = getFontIdentifier(content);
      createInvisibleSpan({
        ...content,
        text: fontId,
      });
      addFontLoadCallback(fontId, content, resolve);
    } else {
      resolve();
    }
  });
};

export const isFontLoaded = (content) => {
  return getIsFontAvailable(content);
};

export default loadFont;
