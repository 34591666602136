import memoize from 'lodash/memoize';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DraggableCore } from 'react-draggable';
import { focusControllerDraggableDecorator } from '@pdffiller/jsf-focuscontroller';
import { thisDevice } from '@pdffiller/jsf-useragent';

import { getDependency, dependenciesMap } from '../di';

const paletteStyles = { position: 'absolute' };
const handlerHeight = 25;

const getDecoratedDraggableCore = memoize(() => {
  return focusControllerDraggableDecorator(thisDevice)(DraggableCore);
});

@connect(
  (state) => {
    const jsfcoreSelectors = getDependency(dependenciesMap.jsfcoreSelectors);
    return {
      body: jsfcoreSelectors.base.getBody(state),
    };
  },
)
export default class Palette extends Component {
  static propTypes = {
    storeRef: PropTypes.func,
    children: PropTypes.element.isRequired,
    body: PropTypes.shape({
      height: PropTypes.number.isRequired,
    }),

    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    style: PropTypes.shape({}),

    onDragStart: PropTypes.func.isRequired,
    onDragMove: PropTypes.func.isRequired,
    onDragStop: PropTypes.func.isRequired,
    useFocusControllerDecorator: PropTypes.bool,
  };

  static defaultProps = {
    body: null,
    storeRef: () => {},
    style: {},
    useFocusControllerDecorator: false,
  };

  render() {
    const {
      top,
      left,
      body,
      style,
      useFocusControllerDecorator,
    } = this.props;

    const DraggableComponent = useFocusControllerDecorator
      ? getDecoratedDraggableCore()
      : DraggableCore;

    return (
      <div
        className="palette palette--theme--primary palette--size--medium"
        tabIndex={-1}
        style={{ ...paletteStyles, ...style, top, left }}
        ref={this.props.storeRef}
      >
        <DraggableComponent
          onStart={this.props.onDragStart}
          onDrag={this.props.onDragMove}
          onStop={this.props.onDragStop}
        >
          <div className="palette__drag-handle" />
        </DraggableComponent>
        <div
          className="palette__body"
          style={body
            ? {
              maxHeight: body.height - top - handlerHeight,
              overflowY: 'auto',
            }
            : {}
          }
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
