import PropTypes from 'prop-types';

const canPrev = (activePageId, count) => {
  return (count !== false && activePageId !== 0);
};
const canNext = (activePageId, count) => {
  return (count !== false && activePageId !== count - 1);
};

const thumbnailsNavigationProvider =
  ({ activePageId, count, children }, context) => {
    const index = context.getIndexByPageId(activePageId);

    return (
      children({
        index,
        canPrev: canPrev(index, count),
        canNext: canNext(index, count),
      })
    );
  };

thumbnailsNavigationProvider.propTypes = {
  activePageId: PropTypes.number.isRequired,
  count: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,

  children: PropTypes.func.isRequired,
};

thumbnailsNavigationProvider.contextTypes = {
  getIndexByPageId: PropTypes.func,
};

export default thumbnailsNavigationProvider;
