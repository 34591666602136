import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import clamp from 'lodash/clamp';
import scroll from 'scroll';
import * as lazySelectors from '@pdffiller/jsf-lazyload/store/selectors';
import { thisDevice } from '@pdffiller/jsf-useragent';

import { selectors } from '../../..';
import { getIndexByPageId } from '../../../helpers/utils';
import {
  changePageScrollPosition,
} from '../../../helpers/const';
import { setFrameScroll } from '../../../store/modules/navigation';
import PageFrame from '../PageFrame/PageFrame';

const defaultValue = 1;

export const workspaceAndFrameSizeIsOk = (workspace, frameSize) => {
  if (
    workspace &&
    frameSize &&
    workspace.height > defaultValue &&
    frameSize.height > defaultValue
  ) {
    return true;
  }
  return false;
};

@connect(
  (__, { pageId }) => {
    return (state) => {
      const activePageId = selectors.base.getActivePageId(state);
      const prevPageId = selectors.base.getPrevPageId(state);
      const nextPageId = selectors.base.getNextPageId(state);
      const isActivePage = selectors.getIsActivePage(state, pageId);

      const pageSettings = selectors.navigation.getPagesSettings(state);
      const prevIndex = getIndexByPageId(prevPageId, pageSettings);
      const nextIndex = getIndexByPageId(nextPageId, pageSettings);

      const isNextPage = pageId === nextPageId;

      // If this is new active page - need to update scroll of this page.
      const isNextActive = isNextPage &&
        activePageId !== nextPageId;

      // Pagination to next page (scroll to top)
      const isPaginationNext = isNextPage &&
        nextIndex > prevIndex;
      const isPaginationPrev = isNextPage &&
        nextIndex < prevIndex;

      const workspace = selectors.base.getWorkspace(state);
      const height = workspace && workspace.height
        ? workspace.height
        : 0;

      const frameHeight = selectors.getFrameSizePadded(state, pageId).height;

      const isScrollDisabledBySize = height >= frameHeight;
      const isScrollDisabled = (isActivePage || isNextActive) &&
        (
          state.events.pagePanning || state.events.pagePinching || state.events.pageScaling ||
          state.events.pageChanging || state.events.dragElementId !== false ||
          state.events.isDrawingNewGraphicElement || state.events.isScrollDisabled ||
          isScrollDisabledBySize
        );


      return {
        isScrollDisabled,
        isScrollDisabledBySize,
        isActivePage,
        isPaginationNext,
        isPaginationPrev,
        isNextActive,
        workspace,
        frameHeight,
        frameOffset: selectors.getFrameOffset(state, pageId),
        frameSize: selectors.getFrameSize(state, pageId),
        isCommentsLoaded: lazySelectors.jsf.getIsCommentsLoaded(state),
        isCommentsShown: selectors.base.getIsCommentsShown(state),
      };
    };
  }, {
    setFrameScroll,
  },
)
export default class PageScroll extends Component {
  static propTypes = {
    pageId: PropTypes.number.isRequired,

    // from global.state
    isActivePage: PropTypes.bool.isRequired,
    // TODO: remove eslint-disable-next-line after remove UNSAFE_componentWillReceiveProps
    // eslint-disable-next-line react/no-unused-prop-types
    isPaginationNext: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    isPaginationPrev: PropTypes.bool.isRequired,
    isNextActive: PropTypes.bool.isRequired,
    frameSize: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
      }),
    ]).isRequired,
    frameOffset: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        left: PropTypes.number.isRequired,
        top: PropTypes.number.isRequired,
        scrollLeft: PropTypes.number.isRequired,
        scrollTop: PropTypes.number.isRequired,
      }),
    ]).isRequired,

    workspace: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      framePadding: PropTypes.shape({
        right: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        top: PropTypes.number.isRequired,
        bottom: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    frameHeight: PropTypes.number.isRequired,
    // is compilation of props from global.state, see stateToProps func in
    // @connect decorator
    isScrollDisabled: PropTypes.bool.isRequired,
    isScrollDisabledBySize: PropTypes.bool.isRequired,
    isCommentsLoaded: PropTypes.bool.isRequired,
    isCommentsShown: PropTypes.bool.isRequired,

    // actions
    setFrameScroll: PropTypes.func.isRequired,
  };

  static childContextTypes = {
    changePageScroll: PropTypes.func,
  };

  static contextTypes = {
    onScroll: PropTypes.func,
    paginationHelpers: PropTypes.shape({
      updateCanGoImmediately: PropTypes.func.isRequired,
      updateCanGoWithTimeout: PropTypes.func.isRequired,
      onPageChange: PropTypes.func.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);
    this.scroll = { scrollTop: 0, scrollLeft: 0 };
  }

  getChildContext() {
    return {
      changePageScroll: this.forceScroll,
    };
  }

  componentDidMount() {
    const { scrollTop, scrollLeft } = this.props.frameOffset;
    if (scrollTop || scrollLeft) {
      this.setDOMScroll({
        scrollTop,
        scrollLeft,
        animation: false,
      });
    }

    this.listenScroll();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      isActivePage,
      isPaginationNext,
      isPaginationPrev,
      isNextActive,
      frameSize,
      frameOffset,
      workspace,
      isScrollDisabledBySize,
    } = nextProps;

    // Если отскролить страницу вниз в IE и затем изменить масштаб чтобы
    // скроллбары пропали - она будет сдвинутой.
    // Принудительно скроллим ее вверх
    if (
      isScrollDisabledBySize && !this.props.isScrollDisabledBySize && (
        thisDevice.isInternetExplorer11 || thisDevice.isEdgeDesktop
      )
    ) {
      setTimeout(() => {
        this.forceScroll({ scrollTop: 0, scrollLeft: 0 }, { forceUpdateCanGo: true });
      }, 200);
    }

    // Prevent execution this func for unactive pages
    if (isActivePage && !this.props.isActivePage) {
      this.context.paginationHelpers.onPageChange();
    }

    if (frameSize && this.props.frameSize === false) {
      this.props.setFrameScroll(this.scroll, this.props.pageId);
      if (thisDevice.isMobile) {
        this.context.paginationHelpers.updateCanGoImmediately(this.getDOMScroll());
      }
    }

    if (frameOffset && thisDevice.isDesktop &&
      frameOffset.scrollLeft === 0 && frameOffset.scrollTop === 0 &&
      (this.scroll.scrollLeft !== 0 || this.scroll.scrollTop !== 0)) {
      this.forceScroll({ scrollLeft: 0, scrollTop: 0 });
    }

    // If this is new active page - need to update scroll of this page.
    if (isNextActive && !this.props.isNextActive) {
      // Pagination to next page (scroll to top)
      if (isPaginationNext) {
        this.forceScroll({ scrollLeft: 0, scrollTop: changePageScrollPosition });
      }

      // Pagination to prev page (scroll to bottom)
      if (isPaginationPrev && workspaceAndFrameSizeIsOk(workspace, frameSize)) {
        this.forceScroll({
          scrollLeft: 0,
          scrollTop:
            (frameSize.height + workspace.framePadding.top + workspace.framePadding.bottom) -
            workspace.height - changePageScrollPosition,
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isCommentsShown,
      isCommentsLoaded,
      isActivePage,
    } = prevProps;

    if (this.props.isActivePage) {
      if (this.props.isCommentsLoaded && (
        this.props.isCommentsLoaded !== isCommentsLoaded ||
        this.props.isCommentsShown !== isCommentsShown ||
        this.props.isActivePage !== isActivePage
      )) {
        if (this.props.isCommentsShown) {
          this.scrollCommentsIntoView();
        } else {
          this.scrollOutOfComments();
        }
      }
    }
  }

  setDOMScroll = ({ scrollTop, scrollLeft, animation }) => {
    const scrollDomElement = this.wrapperRef;

    // появилась проблема в https://pdffiller.atlassian.net/browse/JSF-3677:
    // эта функция вызывается в TextToolView до метода componentDidMount в
    // данном компоненте, а поэтому ref еще не определен
    if (!scrollDomElement) {
      return;
    }

    if (animation) {
      if (scrollLeft !== undefined) {
        scrollDomElement.scrollLeft = scrollLeft;
      }

      scroll.top(scrollDomElement, scrollTop, { duration: 150 });
      return;
    }

    if (scrollLeft !== undefined) {
      scrollDomElement.scrollLeft = scrollLeft;
    }

    const sizeDiff = this.props.frameHeight - this.props.workspace.height;
    const scrollTopResult = sizeDiff < 0
      ? 0
      : sizeDiff;
    // calculate offset from top
    scrollDomElement.scrollTop = clamp(scrollTop, 0, scrollTopResult);
  };

  getDOMScroll = () => {
    if (!this.getRoot()) {
      return {
        scrollTop: 0,
        scrollLeft: 0,
      };
    }

    return {
      scrollTop: this.getRoot().scrollTop,
      scrollLeft: this.getRoot().scrollLeft,
    };
  };

  getRoot = () => {
    return this.wrapperRef;
  };

  // AB: form perfect-scrollbar
  getDeltaFromEvent = (event) => {
    let { deltaX } = event;
    let deltaY = -1 * event.deltaY;

    if (typeof deltaX === 'undefined' || typeof deltaY === 'undefined') {
      // OS X Safari
      deltaX = (-1 * event.wheelDeltaX) / 6;
      deltaY = event.wheelDeltaY / 6;
    }

    if (event.deltaMode && event.deltaMode === 1) {
      // Firefox in deltaMode 1: Line scrolling
      deltaX *= 10;
      deltaY *= 10;
    }

    if (Number.isNaN(deltaX) && Number.isNaN(deltaY)) {
      // I  E in some mouse drivers
      deltaX = 0;
      deltaY = event.wheelDelta;
    }

    if (event.shiftKey) {
      // reverse axis with shift key
      return [-deltaY, -deltaX];
    }
    return { deltaX, deltaY };
  };

  listenScroll = () => {
    this.getRoot().addEventListener('scroll', () => {
      this.scroll = this.getDOMScroll();

      this.context.onScroll({
        scroll: this.scroll, pageId: this.props.pageId,
      });
      this.onChangeScrollDebounced();

    // we dont use preventDefault so make event passive
    }, { passive: true });

    this.getRoot().addEventListener('wheel', (event) => {
      const node = this.getRoot();
      const { deltaY } = this.getDeltaFromEvent(event);

      // scrollHeight and clientHeight are integer values that rounded to top bound
      // but scrollTop could sometimes be fractional value, so we round it to top value to get
      // everything to work
      const scrollTop = Math.ceil(node.scrollTop);

      if (!(
        (scrollTop === 0 && deltaY > 0) ||
        (scrollTop >= node.scrollHeight - node.clientHeight && deltaY < 0)
      )) {
        event.stopPropagation();
      }
      // we dont use preventDefault so make event passive too
    }, { passive: true });
  };

  storeWrapperRef = (ref) => {
    this.wrapperRef = ref;
  };

  forceScroll = (forceScroll, opts) => {
    this.setDOMScroll(forceScroll);
    this.onChangeScroll(opts);
  };

  // TODO: FIX
  scaleUpdated = (offset) => {
    if (!offset) {
      return;
    }
    const { top, left } = offset;
    const scrollTop = top < 0
      ? -top
      : 0;
    const scrollLeft = left < 0
      ? -left
      : 0;

    if (this.props.isCommentsShown && this.props.isCommentsLoaded) {
      this.scrollCommentsIntoView(scrollTop);
    } else {
      this.setDOMScroll({ scrollTop, scrollLeft });
    }
    this.onChangeScroll();
  };

  scrollCommentsIntoView(scrollTop = this.getDOMScroll().scrollTop) {
    const { workspace, frameSize } = this.props;
    const { framePadding, width: wsWidth } = workspace;
    const { width } = frameSize;

    const scrollLeftRL = width - (wsWidth - framePadding.left - framePadding.right);
    this.setDOMScroll({ scrollTop, scrollLeft: Math.max(0, scrollLeftRL) });
  }

  scrollOutOfComments(scrollTop = this.getDOMScroll().scrollTop) {
    const { workspace, frameSize } = this.props;
    const { width: workspaceWidth, framePadding } = workspace;
    const { width: frameWidth } = frameSize;
    const pageFrameWidth = frameWidth + framePadding.left + framePadding.right;

    const { scrollLeft } = this.getDOMScroll();

    const scrollDiff = (scrollLeft + workspaceWidth) - pageFrameWidth;
    if (scrollDiff > 0) {
      this.setDOMScroll({ scrollTop, scrollLeft: scrollLeft - scrollDiff });
    }
  }

  onChangeScroll = (opts = {}) => {
    this.scroll = this.getDOMScroll();

    // shit
    const { scrollTop, scrollLeft } = this.props.frameOffset;
    if (
      this.scroll.scrollTop === scrollTop &&
      this.scroll.scrollLeft === scrollLeft &&

      /*
       * Хоть скролл и не изменился canGo нужно пересчитать,
       * т.к. скролл вообще пропал
       */
      !opts.forceUpdateCanGo
    ) {
      return;
    }

    this.props.setFrameScroll(this.scroll, this.props.pageId);
    if (thisDevice.isMobile) {
      this.context.paginationHelpers.updateCanGoImmediately(this.getDOMScroll());
    } else {
      this.context.paginationHelpers.updateCanGoWithTimeout();
    }
  };

  onChangeScrollDebounced = debounce(this.onChangeScroll, 200);

  render() {
    const { pageId, workspace, isScrollDisabled } = this.props;
    const width = workspace && workspace.width
      ? workspace.width
      : 0;

    const height = workspace && workspace.height
      ? workspace.height
      : 0;

    return (
      <div
        className="wrapper-PageScroll"
        ref={this.storeWrapperRef}
        data-autotest="wrapperPageScroll"
        style={{
          width,
          height,
          // В ие11 получается мигание экрана при смене overflow.
          // Было решено не вырубать скролл в ie11.
          // https://pdffiller.atlassian.net/browse/JSF-7454
          overflow: (isScrollDisabled && !thisDevice.isInternetExplorer11)
            ? 'hidden'
            : 'auto',
        }}
      >
        <PageFrame pageId={pageId} updateScale={this.scaleUpdated} />
      </div>
    );
  }
}
