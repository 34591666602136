import { mocks } from '@pdffiller/jsf-lazyload/const';
import { isSignNow } from 'ws-editor-lib';
import { focusControllerElementDecorator } from '@pdffiller/jsf-focuscontroller';

import { elemTypes, elemSubTypes, dateTypes } from './elemTypes';

import TextTool from '../components/Tools/TextTool/TextTool';
import DropdownTool from '../components/Tools/DropdownTool/DropdownTool';
import SVGTool from '../components/Tools/SVGTool/SVGTool';
import LineTool from '../components/Tools/LineTool/LineTool';
import ArrowTool from '../components/Tools/ArrowTool/ArrowTool';
import CurveTool from '../components/Tools/CurveTool/CurveTool';
import RectTool from '../components/Tools/RectTool/RectTool';
import PictureTool from '../components/Tools/PictureTool/PictureTool';
import * as SignTool from '../components/Tools/SignTool';
import ConstructorTool from '../components/Element/ConstructorTool/ConstructorTool';

const TextToolWithFocusController = focusControllerElementDecorator(TextTool);
const DropdownToolWithFocusController = focusControllerElementDecorator(DropdownTool);
const SVGToolWithFocusController = focusControllerElementDecorator(SVGTool);
const LineToolWithFocusController = focusControllerElementDecorator(LineTool);
const ArrowToolWithFocusController = focusControllerElementDecorator(ArrowTool);
const CurveToolWithFocusController = focusControllerElementDecorator(CurveTool);
const RectToolWithFocusController = focusControllerElementDecorator(RectTool);
const PictureToolWithFocusController = focusControllerElementDecorator(PictureTool);
const SignToolWithFocusController = focusControllerElementDecorator(SignTool.render);

const ConstructorToolWithFocusController = focusControllerElementDecorator(
  ConstructorTool,
);

export const constructorType = ConstructorToolWithFocusController;

// eslint-disable-next-line import/prefer-default-export
export const types = {
  [elemTypes.text]: {
    none: TextToolWithFocusController,
    [elemSubTypes.text.number]: TextToolWithFocusController,
    [elemSubTypes.text.formula]: TextToolWithFocusController,
    [elemSubTypes.text.dropdown]:
      isSignNow()
        ? TextToolWithFocusController
        : DropdownToolWithFocusController,
    ...(dateTypes.reduce((state, type) => {
      return {
        ...state,
        [type]: TextToolWithFocusController,
      };
    }, {})),
    [elemSubTypes.text.overlying]: TextToolWithFocusController,
  },
  [elemTypes.checkmark]: {
    x: SVGToolWithFocusController,
    v: SVGToolWithFocusController,
    o: SVGToolWithFocusController,
  },
  [elemTypes.line]: {
    none: LineToolWithFocusController,
  },
  [elemTypes.arrow]: {
    none: ArrowToolWithFocusController,
  },
  [elemTypes.blackout]: {
    none: RectToolWithFocusController,
  },
  [elemTypes.highlight]: {
    none: RectToolWithFocusController,
  },
  [elemTypes.erase]: {
    none: RectTool,
  },
  [elemTypes.pen]: {
    none: CurveToolWithFocusController,
  },
  [elemTypes.sticky]: {
    none: TextToolWithFocusController,
  },
  [elemTypes.textbox]: {
    none: TextToolWithFocusController,
  },
  [elemTypes.image]: {
    none: PictureToolWithFocusController,
  },
  [elemTypes.signature]: {
    [elemSubTypes.signature.initials]: SignToolWithFocusController,
    [elemSubTypes.signature.curve]: SignToolWithFocusController,
    [elemSubTypes.signature.image]: SignToolWithFocusController,
    [elemSubTypes.signature.text]: SignToolWithFocusController,
    [elemSubTypes.none]: SignToolWithFocusController,
  },
  [elemTypes.selection]: {
    [elemSubTypes.none]: mocks.component,
  },
  [elemTypes.radio]: {
    [elemSubTypes.none]: SVGToolWithFocusController,
    x: SVGToolWithFocusController,
    v: SVGToolWithFocusController,
    o: SVGToolWithFocusController,
  },
  [elemTypes.smart]: {
    [elemSubTypes.none]: mocks.component,
  },
  [elemTypes.attachment]: {
    [elemSubTypes.none]: mocks.component,
  },
};
