import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { popupMenu, fontsMap } from '../../../../../helpers/const';
import { toolbar } from '../../../../../store/selectors';
import { setActivePopupMenu } from '../../../../../store/modules/viewport';
import PopupMenuItem from '../../../../Toolbar/ToolbarAttributes/Popup/PopupMenuItem';

@connect(
  (state) => {
    const { fontFamily } = toolbar.getElementPropsForToolbar(state);
    return {
      fontFamily,
    };
  },
  {
    setActivePopupMenu,
  },
)
export default class FontFamilyPopupMenuSettings extends Component {
  static propTypes = {
    fontFamily: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    setActivePopupMenu: PropTypes.func.isRequired,
  }

  getFontLabel = () => {
    const font = fontsMap[this.props.fontFamily];
    return font && font.label;
  }

  handleShowFontFamilyMenu = () => {
    this.props.setActivePopupMenu(popupMenu.fontFamily);
  };

  render() {
    return (
      <PopupMenuItem
        label={this.props.locale}
        showPopupMenuItem={this.handleShowFontFamilyMenu}
        value={this.getFontLabel()}
      />
    );
  }
}
