import { setActiveElement } from 'ws-editor-lib/actions';
import { setIsSearchModeActive } from '../modules/search';
import { getActiveElementId, getIsSearchModeActive } from '../selectors/baseSelectors';

export default function toggleSearchMode() {
  return (dispatch, getState) => {
    const state = getState();
    const activeElementId = getActiveElementId(state);
    const isSearchModeActive = getIsSearchModeActive(state);

    if (activeElementId) {
      dispatch(setActiveElement(activeElementId, false));
    }

    dispatch(setIsSearchModeActive(!isSearchModeActive));
  };
}
