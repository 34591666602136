import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import find from 'lodash/find';
import { getNumberLengthWithoutDotsOrCommas } from '../../helpers/numbers';
import isSignNow from '../../helpers/isSignNow';

import { selectors as jsfValidationsSelectors } from '..';
import { MAX_LENGTH_FOR_FORMULA_FIELD } from '../const';
import { format, unformat } from '../utils/formatUnformat';

@connect(
  (state) => {
    return {
      validators: jsfValidationsSelectors.validators.getMergedValidators(state),
    };
  },
)
export default class ToolTypeValidation extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    validators: PropTypes.arrayOf(
      PropTypes.shape({
        errorMessage: PropTypes.string.isRequired,
        regexExpression: PropTypes.string.isRequired,
      }),
    ).isRequired,
    validatorId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOf([null]),
    ]).isRequired,
    isUsedByFormula: PropTypes.bool,
    isFreeFormMode: PropTypes.bool,
  };

  static defaultProps = {
    isUsedByFormula: false,
    isFreeFormMode: false,
  };

  static contextTypes = {
    subscribeToScroll: PropTypes.func,
    unsubscribeToScroll: PropTypes.func,
  };

  componentDidMount() {
    // forceUpdate on scroll decreases performance(too many tools updates)
    // it necessary for SNF only
    if (isSignNow()) {
      // TODO (sshutov): research where seted context
      this.context.subscribeToScroll(this.callForceUpdate);
    }
  }

  componentWillUnmount() {
    if (isSignNow()) {
      this.context.unsubscribeToScroll(this.callForceUpdate);
    }
  }

  getIsValid = () => {
    const { value } = this.props;
    if (!this.validator) {
      return true;
    }

    const fakeEl = { template: { validator: this.validator } };

    // when we get text from CellsTool - we have '&nbsp' symbols instead of regular spaces
    const nbspChar = String.fromCharCode(160);
    const regularSpacesValue = value.replace(new RegExp(nbspChar, 'g'), ' ');

    const formattedValue = format(unformat(regularSpacesValue, fakeEl), fakeEl, regularSpacesValue);

    const needValidateLength = (
      this.props.isUsedByFormula ||
      (!this.props.isFreeFormMode && jsfValidationsSelectors
        .validators
        .needNumberLengthValidation(this.validator))
    );
    if (
      needValidateLength &&
      getNumberLengthWithoutDotsOrCommas(formattedValue) > MAX_LENGTH_FOR_FORMULA_FIELD
    ) {
      return false;
    }

    const regexp = new RegExp(this.validator.regexExpression);
    return formattedValue === '' || regexp.test(formattedValue);
  };

  get validator() {
    return find(this.props.validators, { id: this.props.validatorId });
  }

  callForceUpdate = () => {
    this.forceUpdate();
  }

  render() {
    const isInvalid = !this.getIsValid();

    return this.props.children({
      isInvalid,
      validator: this.validator,
    });
  }
}
