import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { setGhostPageId } from '../../store/modules/navigation';
import { analyzeTarget } from './ghostUtils';
import { selectors } from '../..';

export const defaultMouseListenerState = {
  mousePosition: {
    contentX: 0,
    contentY: 0,
  },
  isMouseOverElement: false,
  isMouseOverClickBlocker: false,
  isMouseOverPage: false,
  isMouseOverVisibleBox: false,
  isMouseOverComment: false,
  isMouseOverFakeEdit: false,
};

@connect(
  (state) => {
    return {
      ghostPageId: selectors.base.getGhostPageId(state),
      activePageId: selectors.base.getActivePageId(state),
      isToolDragging: selectors.base.getIsToolDragging(state),
      ghostPos: selectors.base.getGhostPos(state),
      workspace: selectors.base.getWorkspace(state),
    };
  }, {
    setGhostPageId,
  },
)
export default class GhostMouseListener extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    ghostPageId: PropTypes.number.isRequired,
    setGhostPageId: PropTypes.func.isRequired,
    isToolDragging: PropTypes.bool.isRequired,
    activePageId: PropTypes.number.isRequired,
    ghostPos: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
      }),
    ]).isRequired,
    workspace: PropTypes.shape({
      left: PropTypes.number,
      right: PropTypes.number,
      top: PropTypes.number,
      bottom: PropTypes.number,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...defaultMouseListenerState,
      ghostPageId: props.activePageId,
    };
  }

  componentDidMount() {
    document.addEventListener('mousemove', this.onMouseMove);
  }

  componentDidUpdate = (prevProps) => {
    const { ghostPos, workspace } = this.props;
    if (prevProps.ghostPos === ghostPos) {
      return;
    }
    // после того как в модальном окне выбираем подпись, нужно проверить,
    // находится ли курсор в рабочей области и переписать локальный стэйт
    // (componentDidUpdate используем вместо getDerivedStateFromProps
    // из-за использования requestAnimationFrame)
    const isMouseOverWorkspace = this.calcMouseOverVisibleBox({ ...workspace, ...ghostPos });
    if (isMouseOverWorkspace) {
      this.animationFrame(() => {
        this.setState({
          mousePosition: {
            contentX: ghostPos.x,
            contentY: ghostPos.y,
          },
          isMouseOverVisibleBox: isMouseOverWorkspace,
          isMouseOverPage: isMouseOverWorkspace,
        });
      });
    }
  }


  componentWillUnmount() {
    document.removeEventListener('mousemove', this.onMouseMove);
  }

  hideGhost = () => {
    this.setState({ isMouseOverElement: true });
  };

  calcMouseOverVisibleBox = ({ left, right, top, bottom, x, y }) => {
    return (x > left) && (x < right) && (y > top) && (y < bottom);
  }

  animationFrame = (cb) => {
    if (this.mouseMoveRequestAnimationFrame) {
      cancelAnimationFrame(this.mouseMoveRequestAnimationFrame);
    }

    this.mouseMoveRequestAnimationFrame = requestAnimationFrame(() => {
      this.mouseMoveRequestAnimationFrame = null;

      cb();
    });
  }

  onMouseMove = (event) => {
    // Не делаем ничего пока происходит drag-подобные события
    // Мы можем, например, зажать 'size up' у элемента, и тулбар из-под него
    // уедет а по mouseUp сюда прилетит onClick - нежелательное поведение
    if (event.buttons && !this.props.isToolDragging) {
      return;
    }

    const { clientX, clientY, target } = event;

    this.animationFrame(() => {
      this.mouseMoveRequestAnimationFrame = null;

      const {
        isMouseOverElement,
        isMouseOverClickBlocker,
        isMouseOverVisibleBox,
        isMouseOverComment,
        isMouseOverFakeEdit,
        pageIdAboveWhichMouse,
      } = analyzeTarget(target);

      const isMouseOverPage = pageIdAboveWhichMouse !== -1;

      this.setState({
        mousePosition: {
          contentX: clientX,
          contentY: clientY,
        },
        isMouseOverElement,
        isMouseOverClickBlocker,
        isMouseOverPage,
        isMouseOverVisibleBox,
        isMouseOverComment,
        isMouseOverFakeEdit,
        // For FreeGhost if ghost above active page, for correct render ghost
        // in all parts of application need return activePage (document after rearrange)
        ghostPageId: isMouseOverPage
          ? pageIdAboveWhichMouse
          : this.props.activePageId,
      });
      if (isMouseOverPage && pageIdAboveWhichMouse !== this.props.ghostPageId) {
        this.props.setGhostPageId(pageIdAboveWhichMouse);
      }
    });
  };

  render() {
    const {
      mousePosition,
      isMouseOverElement,
      isMouseOverClickBlocker,
      isMouseOverPage,
      isMouseOverVisibleBox,
      isMouseOverComment,
      isMouseOverFakeEdit,
      ghostPageId,
    } = this.state;

    return this.props.children({
      mousePosition,
      isMouseOverElement,
      isMouseOverClickBlocker,
      isMouseOverPage,
      isMouseOverVisibleBox,
      isMouseOverComment,
      isMouseOverFakeEdit,
      ghostPageId,
      hideGhost: this.hideGhost,
    });
  }
}
