import PropTypes from 'prop-types';
import React from 'react';
import { thisDevice } from '@pdffiller/jsf-useragent';

import ElementDataProvider from './ElementDataProvider';
import ElementDrag from './Drag/ElementDrag';
import ElementGeometryUpdater from './Drag/ElementGeometryUpdater';

import MagneticLinesView from '../MagneticLines/MagneticLinesView';
import MagneticLinesProvider from './MagneticLines/MagneticLinesProvider';
import ElementHrefProvider from './ElementHrefProvider';
import RenderElementWrapperView from './renderElementWrapperView';
import RenderSimpleElementView from './renderSimpleElementView';
import RenderConstructorElementView from './renderConstructorElementView';

import { getExpandableElementProps } from '../../store/helpers/functions';

import config from '../../helpers/clientConfig';

const renderElement = ({ element, textFieldViewMode }) => {
  if (!element) {
    return null;
  }
  return (
    <ElementDataProvider
      element={element}
      key={element.id}
    >
      {({
        /**
         * TODO:
         * Нужно удалить использованите scale во всех низлежащих компонентах
         * сейчас везде прокидывается "1"
         */
        scale,
        fConstructorLoadedAndShown,
        isActiveElement,
        isDraggingInRedux,
        isHighlighted,
        isStretching,
        isConstructorMode,
        isDateElement,
        isDropdownElement,
        isPictureElement,
        isSignatureElement,
        isFillableElement,
        isEnabledElement,
        isElementDisabledByDependencies,
        isConditionalElement,
      }) => {
        // When we resize line/arrow by endPoints -
        // element's view is being rendered at DrawingLayerView,
        // so we don't need render it here
        if (isStretching) {
          return null;
        }

        // NOTE: пока что сделано однообразно как в snfiller
        // потом будем это рефакторить, и здесь и в SN
        if (isConditionalElement && isElementDisabledByDependencies) {
          return null;
        }

        return (
          <ElementDrag
            element={element}
            isActiveElement={isActiveElement}
            isDraggingInRedux={isDraggingInRedux}
            fConstructorLoadedAndShown={fConstructorLoadedAndShown}
            isFillableElement={isFillableElement}
          >
            {({
              onDrag,
              onResize,
              draggableProps,
              element: elementFromProvider,
            }) => {
              return (
                <MagneticLinesProvider
                  isDragging={draggableProps.isDragging}
                  resizeIndex={draggableProps.resizeIndex}
                  element={elementFromProvider}
                  isFillableElement={isFillableElement}
                  scale={scale}
                  isActiveElement={isActiveElement}
                >
                  {({ magneticLines, magneticElementFromProvider }) => {
                    const magneticElementProps = getExpandableElementProps({
                      element: magneticElementFromProvider,
                      isConstructorMode,
                      isActiveElement,
                      textFieldViewMode,
                    });

                    return (
                      <ElementGeometryUpdater
                        element={magneticElementFromProvider}
                        elementProps={magneticElementProps}
                        isDragging={draggableProps.isDragging}
                        resizeIndex={draggableProps.resizeIndex}
                        isFillableElement={isFillableElement}
                        isActiveElement={isActiveElement}
                        fConstructorLoadedAndShown={fConstructorLoadedAndShown}
                      >
                        <>
                          {config.app.dragMagneticLinesOn && (
                            draggableProps.isDragging || draggableProps.resizeIndex !== 0
                          ) &&
                            <MagneticLinesView
                              magneticLines={magneticLines}
                            />
                          }
                          <ElementHrefProvider
                            hyperlink={magneticElementProps.hyperlink}
                            isEnabledElement={isEnabledElement}
                            isDragging={draggableProps.isDragging}
                            resizeIndex={draggableProps.resizeIndex}
                          >
                            {({
                              onClickWrapperElement,
                              hover,
                              onMouseEnter,
                              onMouseLeave,
                            }) => {
                              const needRenderConstructorElementView = (
                                thisDevice.isDesktop &&
                                isConstructorMode &&
                                isFillableElement
                              );

                              return (
                                <RenderElementWrapperView
                                  element={magneticElementFromProvider}
                                  onDrag={onDrag}
                                  draggableProps={draggableProps}
                                  scale={scale}
                                  elementProps={magneticElementProps}
                                  isFillableElement={isFillableElement}
                                  isConstructorMode={isConstructorMode}
                                  isStretching={isStretching}
                                  isActiveElement={isActiveElement}
                                  onClickWrapperElement={onClickWrapperElement}
                                  hover={hover}
                                  onMouseEnter={onMouseEnter}
                                  onMouseLeave={onMouseLeave}
                                >
                                  {needRenderConstructorElementView
                                    ? (
                                      <RenderConstructorElementView
                                        elementProps={magneticElementProps}
                                        element={elementFromProvider}
                                        onDrag={onDrag}
                                        onResize={onResize}
                                        draggableProps={draggableProps}
                                        scale={scale}
                                        isActiveElement={isActiveElement}
                                        fConstructorLoadedAndShown={fConstructorLoadedAndShown}
                                      />
                                    )
                                    : (
                                      <RenderSimpleElementView
                                        isDateElement={isDateElement}
                                        isDropdownElement={isDropdownElement}
                                        isPictureElement={isPictureElement}
                                        isSignatureElement={isSignatureElement}
                                        isFillableElement={isFillableElement}
                                        elementProps={magneticElementProps}
                                        isConstructorMode={isConstructorMode}
                                        element={elementFromProvider}
                                        onDrag={onDrag}
                                        onResize={onResize}
                                        draggableProps={draggableProps}
                                        isHighlighted={isHighlighted}
                                        isStretching={isStretching}
                                        scale={scale}
                                        isActiveElement={isActiveElement}
                                      />
                                    )
                                  }
                                </RenderElementWrapperView>
                              );
                            }}
                          </ElementHrefProvider>
                        </>
                      </ElementGeometryUpdater>
                    );
                  }}
                </MagneticLinesProvider>
              );
            }}
          </ElementDrag>
        );
      }}
    </ElementDataProvider>
  );
};

renderElement.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    PropTypes.bool,
  ]).isRequired,
  textFieldViewMode: PropTypes.string.isRequired,
};

export default renderElement;
