import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ResizeBox from '../../ResizeBox/ResizeBox';
import { positions } from '../../../helpers/const';
import ElementIconWrapper, { styleForIconWrapper } from '../ElementIcon/ElementIconWrapper';
import { getElementCursor } from '../../../helpers/utils';

const style = {
  width: '100%',
  height: '100%',
  pointerEvents: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  // when we have scale - icon's body is bigger than ConstructorToolView body because of scale
  // so we have bug e.g. JSF-4094. Set overflow: hidden to avoid it
  overflow: 'hidden',
};

export default class ConstructorToolView extends Component {
  static propTypes = {
    icon: PropTypes.element,
    resizeIndex: PropTypes.number,
    isDragging: PropTypes.bool.isRequired,
    isActiveElement: PropTypes.bool.isRequired,
    isPicture: PropTypes.bool.isRequired,
    isSignature: PropTypes.bool.isRequired,
    checkMarkIcon: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.element,
    ]),

    style: PropTypes.shape({}),
    isChoiseEnabled: PropTypes.bool.isRequired,

    onResizeDragStart: PropTypes.func,
    onResizeDragMove: PropTypes.func,
    onResizeDragStop: PropTypes.func,

    onClick: PropTypes.func,
    onMouseDown: PropTypes.func,

    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    storeRef: PropTypes.func.isRequired,
    interceptFocusDataset: PropTypes.shape({}),
  };

  static defaultProps = {
    icon: null,
    checkMarkIcon: null,
    resizeIndex: positions.none,

    style: null,

    onResizeDragStart: () => {},
    onResizeDragMove: () => {},
    onResizeDragStop: () => {},

    onClick: () => {},
    onMouseDown: () => {},

    interceptFocusDataset: undefined,
  };

  getWrapperStyles = () => {
    const cursor = getElementCursor(this.props.isActiveElement);

    return {
      cursor,
      ...this.props.style,
      ...style,
    };
  }

  renderActiveElement = () => {
    const { isDragging, resizeIndex } = this.props;

    return (
      <ResizeBox
        onResizeDragStart={this.props.onResizeDragStart}
        onResizeDragMove={this.props.onResizeDragMove}
        onResizeDragStop={this.props.onResizeDragStop}
        isDragging={isDragging}
        resizeIndex={resizeIndex}
        isFConstructorShown
      />
    );
  };

  renderIcon = () => {
    const { icon, isSignature, isPicture, checkMarkIcon } = this.props;

    if (icon) {
      return isSignature || isPicture || checkMarkIcon
        ? icon
        : (
          <ElementIconWrapper style={styleForIconWrapper}>
            {icon}
          </ElementIconWrapper>
        );
    }

    return null;
  };

  render() {
    const {
      isActiveElement,
      checkMarkIcon,
      isChoiseEnabled,
    } = this.props;

    const wrapperStyles = this.getWrapperStyles();

    return (
      <div
        style={wrapperStyles}
        className="ConstructorToolView-tabindex"
        onClick={this.props.onClick}
        tabIndex={0}

        onMouseDown={this.props.onMouseDown}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        ref={this.props.storeRef}
        {...this.props.interceptFocusDataset}
      >
        {(isActiveElement && isChoiseEnabled && this.renderActiveElement())}
        {this.renderIcon()}
        {checkMarkIcon &&
          <div className="svgTool-SVGTool">
            {checkMarkIcon}
          </div>
        }
      </div>
    );
  }
}
