import PropTypes from 'prop-types';
import React from 'react';
import * as Ui from '../../../ui';

const thumbnailRender = ({
  isActivePage,
  storeRef,
  onClick,
  children,
  thumbnail,
  pageId,
}) => {
  return (
    <Ui.PageThumbnail.BodyMain
      pageId={pageId}
      thumbnail={thumbnail}
      isActivePage={isActivePage}
      onClick={onClick}
      storeRef={storeRef}
    >
      <Ui.PageThumbnail.Counter
        thumbnail={thumbnail}
        isActivePage={isActivePage}
      />
      <Ui.PageThumbnail.ContentMain
        storeRef={storeRef}
      />
      {children}
    </Ui.PageThumbnail.BodyMain>
  );
};

thumbnailRender.propTypes = {
  isActivePage: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  thumbnail: PropTypes.number,
  pageId: PropTypes.number.isRequired,

  onClick: PropTypes.func.isRequired,
  storeRef: PropTypes.func.isRequired,
};

thumbnailRender.defaultProps = {
  thumbnail: PropTypes.number,
};

export default thumbnailRender;
