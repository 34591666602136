import React from 'react';
import PropTypes from 'prop-types';

const SidebarRow = ({ children, ...props }) => {
  return (
    <div
      className="datepicker__sidebar-row"
      {...props}
    >
      {children}
    </div>
  );
};

SidebarRow.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SidebarRow;
