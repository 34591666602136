import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as conditionsSelectors from '../selectors';

const style = {
  display: 'none',
};

@connect(
  (state) => {
    return {
      choiseByClickingModeIsActive:
        conditionsSelectors.getChoiseByClickingModeIsActive(state),
    };
  },
)
export default class ChoiseByClickingPopoverVisibilityController extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    choiseByClickingModeIsActive: PropTypes.bool.isRequired,
  };

  render() {
    if (this.props.choiseByClickingModeIsActive) {
      return (
        <div style={style}>{this.props.children}</div>
      );
    }

    return this.props.children;
  }
}
