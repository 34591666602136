import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import {
  getMouseEventHandler,
  stopEvent,
} from '../../../helpers/utils';

export default class SignStamp extends Component {
  static propTypes = {
    toggleStamp: PropTypes.func,
    dateStamp: PropTypes.string.isRequired,
    isFillable: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,

    sigDateStampChangeable: PropTypes.bool.isRequired,
    updateDefaultSettings: PropTypes.func.isRequired,
  };

  static defaultProps = {
    toggleStamp: null,
  };

  onClickClose = (event) => {
    stopEvent(event);
    this.props.toggleStamp({ id: this.props.id });
  };

  render() {
    // TODO: to static css
    const removeBtnStyle = { transition: 'none' };

    return (
      <div
        className={classnames('jsf-stamp-document', {
          'jsf-document-attr--sign': this.props.isFillable,
        })}
      >
        <div className="jsf-stamp-document__cell">
          <div className="jsf-stamp-document__icon">
            <svg width="41" height="31" viewBox="0 0 41 31" xmlns="http://www.w3.org/2000/svg">
              <g fill="none" fillRule="evenodd">
                <path
                  d="M34.273 0L16.857 31h12.75l11.04-19.653L34.274 0z"
                  fill="#CF4436"
                />
                <path
                  d="M22.612 18.63l-2.58-4.924H7.285l8.98 16.223 6.348-11.3z"
                  fill="#E95E50"
                />
                <path
                  d="M33.08 0H0l6.357 11.347h20.347L33.08 0z"
                  fill="#666"
                />
              </g>
            </svg>
          </div>
        </div>
        <div className="jsf-stamp-document__cell">
          <div className="jsf-stamp-document__title">Verified by PDFfiller</div>
          <div className="jsf-stamp-document__date">{this.props.dateStamp}</div>
        </div>
        {this.props.sigDateStampChangeable &&
          <div
            className="jsf-stamp-document__remove"
            style={removeBtnStyle}
            {...getMouseEventHandler(this.onClickClose)}
          />
        }
      </div>
    );
  }
}
