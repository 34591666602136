import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import {
  activateTool,
} from 'ws-editor-lib/actions';
import { selectors, thunks } from '../../..';

import { setIsSearchModeActive } from '../../../store/modules/search';

@connect(
  (state) => {
    return {
      activeTool: selectors.base.getActiveTool(state),
      activeElement: selectors.elements.getActiveElement(state),
      wizardActive: get(state, 'wizard.wizardActive', false),
    };
  },
  {
    activateTool,
    setActiveElement: thunks.setActiveElement,
    setIsSearchModeActive,
  },
)
export default class ActivateToolProviderPhone extends Component {
  static propTypes = {
    activeElement: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool,
    ]).isRequired,
    activeTool: PropTypes.shape({
      subType: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
    wizardActive: PropTypes.bool.isRequired,
    setIsSearchModeActive: PropTypes.func.isRequired,

    children: PropTypes.func.isRequired,

    activateTool: PropTypes.func.isRequired,
    setActiveElement: PropTypes.func.isRequired,
  };

  static defaultProps = {
    activeTool: null,
  };

  render() {
    return this.props.children({
      activeTool: this.props.activeTool,
      activeElement: this.props.activeElement,
      wizardActive: this.props.wizardActive,
      setActiveElement: this.props.setActiveElement,
      activateTool: this.props.activateTool,
      setIsSearchModeActive: this.props.setIsSearchModeActive,
    });
  }
}
