import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import FocusControlledSelect from '../../../Select';
import * as Ui from '../../../../ui';
import { toolbar } from '../../../../store/selectors';
import setFontSize from '../../../../store/thunks/setFontSize';
import forceFocusTextElement from '../../../../store/thunks/forceFocusTextElement';
import { sizes, keyCodesMap } from '../../../../helpers/const';

const MIN_FONT_SIZE = 1;
const MAX_FONT_SIZE = 400;

const preventFilterOptionsFunc = (options) => {
  return options;
};

const fontSizes = sizes.map((item) => {
  return { value: item, label: item };
});

@connect(
  (state) => {
    const { fontSize } = toolbar.getElementPropsForToolbar(state);
    return {
      fontSize,
    };
  },
  {
    forceFocusTextElement,
    setFontSize,
  },
)
export default class FontSizeToolbarSettings extends Component {
  static propTypes = {
    fontSize: PropTypes.number.isRequired,

    // actions
    setFontSize: PropTypes.func.isRequired,
    forceFocusTextElement: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
    };
  }

  getIsValidInputValue = (value) => {
    const validIntegerRegEx = new RegExp('^\\d+\\.?\\d*$');
    return validIntegerRegEx.test(value) && parseInt(value, 10) >= MIN_FONT_SIZE;
  };

  getCustomFontSize = (valueFromInput) => {
    const isValid = this.getIsValidInputValue(valueFromInput);

    if (!isValid) {
      return this.props.fontSize;
    }

    const value = parseInt(valueFromInput * 100, 10) / 100;

    if (value > MAX_FONT_SIZE) {
      return MAX_FONT_SIZE;
    }

    return value;
  };

  setFontSize = (value) => {
    this.props.setFontSize(value);
    this.props.forceFocusTextElement();
  };

  storeSelectRef = (ref) => {
    this.selectRef = ref;
  };


  onChange = (keyValue) => {
    if (!keyValue) {
      return;
    }

    const { value } = keyValue;
    this.setFontSize(value);
    this.selectRef.setState({ inputValue: value.toString() });
  };

  onFocus = () => {
    this.setState({ isFocused: true });
    this.selectRef.control.addEventListener('keypress', this.onInputKeyPress);
  };

  onBlur = (event) => {
    this.setState({ isFocused: false });
    const value = this.getCustomFontSize(event.target.value);

    this.setFontSize(value);
    this.selectRef.control.removeEventListener('keypress', this.onInputKeyPress);
  };

  onDropDownClose = () => {
    this.selectRef.input.blur();
  };

  onInputKeyDown = (event) => {
    const { keyCode, metaKey, ctrlKey } = event;
    const isValid = this.getIsValidInputValue(event.target.value);

    // prevent ctrl/cmd + V
    if (keyCode === keyCodesMap.v && (metaKey || ctrlKey)) {
      event.preventDefault();
    }

    if (keyCode === keyCodesMap.enter) {
      if (isValid) {
        this.onBlur(event);
        event.target.blur();
      }

      event.preventDefault();
    }
  };

  onInputKeyPress = (event) => {
    // prevent any key press except 0-9
    if (
      (event.charCode < keyCodesMap[0] || event.charCode > keyCodesMap[9]) &&
      // 46 - for dot symbol in onKeyPress event
      event.charCode !== 46
    ) {
      event.preventDefault();
    }
  };

  onMenuOpen = () => {
    this.selectRef.setState({ inputValue: this.props.fontSize.toString() });
  };

  render() {
    // hack to prevent placeholder showing on empty focused input
    const placeholder = this.state.isFocused
      ? ''
      : this.props.fontSize;

    return (
      <Ui.ToolBar.Item type={Ui.toolBar.itemTypes.select}>
        <FocusControlledSelect
          width={100}
          placeholder={placeholder}
          options={fontSizes}
          storeRef={this.storeSelectRef}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onOpen={this.onMenuOpen}
          onClose={this.onDropDownClose}
          onInputKeyDown={this.onInputKeyDown}
          filterOptions={preventFilterOptionsFunc}
          onCloseResetsInput={false}
          onBlurResetsInput={false}
          onSelectResetsInput={false}
          clearable={false}
          noResultsText={false}
          searchable
        />
      </Ui.ToolBar.Item>
    );
  }
}
