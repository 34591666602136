import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';
import clone from 'lodash/clone';
import { addElement, updateElement, trackPoint } from 'ws-editor-lib/actions';

import { selectors, thunks } from '../../../..';
import { cancellableOpts } from '../../../../store/modules/undoRedo';
import { setIsDrawingNewGraphicElement,
  setStretchingElementId } from '../../../../store/modules/events';
import { screenToFrame, stopEvent } from '../../../../helpers/utils';
import { toolsTrackPoints, getIsPressedMetaKey } from '../../../../helpers/const';
import { drawArrow, directions, getPureViewbox,
  getRectProps } from '../../../../helpers/graphicUtils';

import { getEventPos, getRoundedEventPos } from '../../../../helpers/dragUtils';
import { getScrollDirection, scrollInterval,
  getScrollObject } from '../../../../helpers/scrollOnDragUtils';
import { elemTypes, dragPointIndexes, isLineType,
  isDrawType, isDrawRectType } from '../../../../helpers/elemTypes';

import { fixXY, isViewboxValid,
  normalizeControlPoints } from '../drawingLayerUtils';
import DrawingLayerPointsView from './DrawingLayerPointsView';

const initialState = {
  isDrawing: false,
  dragStartPoint: { x: -1, y: -1 },
  dragEndPoint: { x: -1, y: -1 },
  dragIndex: dragPointIndexes.none,
  dragDiff: { x: -1, y: -1 },
};

@connect(
  (_, { pageId }) => {
    return (state) => {
      const activeElement = selectors.elements.getActiveElement(state);
      return {
        scale: selectors.getScale(state, pageId),
        frameSize: selectors.getFrameSize(state, pageId),
        ghost: selectors.base.getGhostElement(state),
        element: (
          activeElement.pageId === pageId
            ? normalizeControlPoints(activeElement)
            : false
        ),
        activeTool: selectors.base.getActiveTool(state),
        dragElementId: selectors.base.getDragElementId(state),
        stretchingElementId: selectors.base.getStretchingElementId(state),
        isDrawingNewGraphicElement: selectors.base.getIsDrawingNewGraphicElement(state),
      };
    };
  },
  {
    addElement,
    setActiveElement: thunks.setActiveElement,
    updateElement,
    setIsDrawingNewGraphicElement,
    setStretchingElementId,
    trackPoint,
  },
)
export default class DrawingLayerPoints extends Component {
  static propTypes = {
    // from global state
    pageId: PropTypes.number.isRequired,
    scale: PropTypes.number.isRequired,
    frameSize: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }).isRequired,
    ghost: PropTypes.shape({ // not required, either ghost or element
      type: PropTypes.string,
      id: PropTypes.string,
      content: PropTypes.object,
    }),
    element: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool,
    ]).isRequired,
    activeTool: PropTypes.shape({
      type: PropTypes.oneOf(
        Object.values(elemTypes),
      ).isRequired,
      subType: PropTypes.string.isRequired,
    }).isRequired,
    dragElementId: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]).isRequired,
    stretchingElementId: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]).isRequired,
    isDrawingNewGraphicElement: PropTypes.bool.isRequired,

    // actions
    updateElement: PropTypes.func.isRequired,
    addElement: PropTypes.func.isRequired,
    setActiveElement: PropTypes.func.isRequired,
    setIsDrawingNewGraphicElement: PropTypes.func.isRequired,
    setStretchingElementId: PropTypes.func.isRequired,
    trackPoint: PropTypes.func.isRequired,
  };

  static contextTypes = {
    changePageScroll: PropTypes.func,
    getPageViewport: PropTypes.func,
    getEvents: PropTypes.func,
  };

  static defaultProps = {
    ghost: null,
  };

  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidUpdate() {
    if (this.state.isDrawing || this.state.dragIndex !== dragPointIndexes.none) {
      this.maybeScroll();
    }
  }

  // New line drawing end
  //

  // get new element props { x, y, width, height, controlPoints } for add or update
  getNewProps = (element) => {
    const { scale } = this.props;
    const { lineWidth, direction } = element.content;

    const controlPoints = this.getControlPoints()
      .map((value) => {
        return value / scale;
      });

    if (element.type === elemTypes.arrow) {
      const arrowPoints = drawArrow(controlPoints, lineWidth, direction).points;
      const arrowViewBox = getPureViewbox(arrowPoints, 0);
      arrowViewBox.x += controlPoints[0];
      arrowViewBox.y += controlPoints[1];

      return {
        ...arrowViewBox,
        controlPoints,
      };
    }

    const viewboxLineWidth = isDrawRectType(element.type)
      ? 0
      : lineWidth;

    const { x, y, width, height } = getPureViewbox(controlPoints, viewboxLineWidth);
    return {
      x,
      y,
      width,
      height,
      controlPoints,
      ...(isDrawRectType(element.type)
        ? getRectProps({ x, y, width, height })
        : {}
      ),
    };
  };

  // TODO: comment
  getControlPoints = () => {
    const { element, scale } = this.props;
    const { dragIndex } = this.state;
    if (!this.state.isDrawing && dragIndex === dragPointIndexes.none) {
      return element
        ? element.content.controlPoints.map((value) => {
          return value * scale;
        })
        : false;
    }

    // Step 0: prepare data
    const { dragStartPoint, dragEndPoint, initialOffset } = this.state;
    const { ghost } = this.props;
    const { frameSize, frameOffset, workspace } = this.context.getPageViewport();
    const type = element
      ? element.type
      : ghost.type;

    const dScroll = {
      left: frameOffset.scrollLeft - initialOffset.scrollLeft,
      top: frameOffset.scrollTop - initialOffset.scrollTop,
    };

    // warning: these values will be mutated during this method
    let resultStartPoint = { x: 0, y: 0 };
    let resultEndPoint = { x: 0, y: 0 };

    const makeControlPoints = () => {
      return [
        resultStartPoint.x,
        resultStartPoint.y,
        resultEndPoint.x,
        resultEndPoint.y,
      ];
    };

    // Step 1: get unbounded pos
    if (this.state.isDrawing) {
      const scrolledStartPoint = {
        x: dragStartPoint.x - dScroll.left,
        y: dragStartPoint.y - dScroll.top,
      };
      resultStartPoint = screenToFrame(scrolledStartPoint, frameOffset, workspace);
      resultEndPoint = screenToFrame(dragEndPoint, frameOffset, workspace);
    } else if (dragIndex !== dragPointIndexes.none) {
      const dx = (dragEndPoint.x - dragStartPoint.x) + dScroll.left;
      const dy = (dragEndPoint.y - dragStartPoint.y) + dScroll.top;

      const controlPoints =
        element.content.controlPoints.map((value) => {
          return value * scale;
        });

      resultStartPoint = {
        x: controlPoints[0] + (
          dragIndex === dragPointIndexes.start
            ? dx
            : 0
        ),
        y: controlPoints[1] + (
          dragIndex === dragPointIndexes.start
            ? dy
            : 0
        ),
      };
      resultEndPoint = {
        x: controlPoints[2] + (
          dragIndex === dragPointIndexes.end
            ? dx
            : 0
        ),
        y: controlPoints[3] + (
          dragIndex === dragPointIndexes.end
            ? dy
            : 0
        ),
      };
    }

    // Step 2: bound end points
    const { direction, lineWidth } = element
      ? element.content
      : ghost.content;

    // PAPA TODO: do something with lineWidth's
    const lineWidthForStartBound = (
      (type === elemTypes.arrow && direction !== directions.forward) || isDrawRectType(type)
    )
      ? 0
      : lineWidth;

    resultStartPoint = fixXY(resultStartPoint, frameSize, lineWidthForStartBound, scale);

    const lineWidthForEndBound = (
      (type === elemTypes.arrow && direction !== directions.backward) || isDrawRectType(type)
    )
      ? 0
      : lineWidth;

    resultEndPoint = fixXY(resultEndPoint, frameSize, lineWidthForEndBound, scale);

    // Step 3 case 1: bound arrow
    if (type === elemTypes.arrow) {
      const diffingPoint = (
        direction === directions.forward ||
        (direction === directions.double &&
          (dragIndex === dragPointIndexes.end || this.state.isDrawing)
        )
      )
        ? resultEndPoint
        : resultStartPoint;

      // not valid viewbox, to start a cycle
      let viewBox = { x: NaN, y: NaN, width: NaN, height: NaN };

      // Make 'while' body to process maximum of 1000 times
      let preventInfinite = 0;
      while (!isViewboxValid(viewBox, frameSize) && preventInfinite < 1000) {
        preventInfinite += 1;
        if (viewBox.x < 0) {
          diffingPoint.x += 1;
        }
        if (viewBox.y < 0) {
          diffingPoint.y += 1;
        }
        if (viewBox.x + viewBox.width > frameSize.width) {
          diffingPoint.x -= 1;
        }
        if (viewBox.y + viewBox.height > frameSize.height) {
          diffingPoint.y -= 1;
        }
        const controlPoints = makeControlPoints();
        const arrow = drawArrow(controlPoints, lineWidth * scale, direction);
        viewBox = getPureViewbox(arrow.points);
        viewBox.x += controlPoints[0];
        viewBox.y += controlPoints[1];
      }

      return makeControlPoints();
    }

    // Step 3 case 2: make rect points from current result(Start|End)Point
    if (isDrawRectType(type)) {
      const left = Math.min(resultStartPoint.x, resultEndPoint.x);
      const right = Math.max(resultStartPoint.x, resultEndPoint.x);
      const top = Math.min(resultStartPoint.y, resultEndPoint.y);
      const bottom = Math.max(resultStartPoint.y, resultEndPoint.y);
      return [
        left, top,
        right, top,
        right, bottom,
        left, bottom,
      ];
    }

    return makeControlPoints();
  };

  // 0 - start point, 1 - end point
  getControlPointsBySide = (index) => {
    const [startPointX, startPointY, endPointX, endPointY] = this.getControlPoints();

    if (index === dragPointIndexes.start) {
      return [startPointX, startPointY];
    }

    // index === dragPointIndexes.end
    return [endPointX, endPointY];
  }

  addElement = () => {
    const { ghost, pageId } = this.props;

    this.props.trackPoint(toolsTrackPoints.ADDED_SIMPLE_TOOL);
    this.props.addElement({
      ...ghost,
      pageId,
      content: {
        ...ghost.content,
        ...this.getNewProps(ghost),
      },
    }, cancellableOpts);
    this.props.setActiveElement(ghost.id, true);
  };

  maybeScroll = throttle(() => {
    const { isDrawing, dragIndex } = this.state;

    if (!this.context.changePageScroll || (!isDrawing && dragIndex === dragPointIndexes.none)) {
      return;
    }

    const viewport = this.context.getPageViewport();
    const scrollDirection = getScrollDirection(this.state, viewport);
    if (!scrollDirection) {
      return;
    }

    const scrollObject = getScrollObject(scrollDirection, viewport);
    this.context.changePageScroll(scrollObject);
  }, scrollInterval);

  updateElement = () => {
    const { element } = this.props;
    this.props.updateElement(
      element.id,
      this.getNewProps(this.props.element),
      cancellableOpts,
    );
  };

  onEndPointDragMove = (index) => {
    return (event) => {
      stopEvent(event);

      // set drag only when drag is already started and some move appeared.
      // other way (dragstart->dragstop without move) - is click, with handler at Content.js
      if (!this.props.stretchingElementId) {
        this.props.setStretchingElementId(this.props.element.id);
      }

      const eventPos = getEventPos(event, this.context.getEvents());

      if (!eventPos) {
        return;
      }

      const isPressedMetaKey = getIsPressedMetaKey(event);

      if (isPressedMetaKey) {
        // берем обратную выбранной точке, как стартовую
        const inversedIndex = index === 0
          ? 1
          : 0;
        const [controlPointX, controlPointY] = this.getControlPointsBySide(inversedIndex);
        const startPoint = {
          x: controlPointX + this.state.dragDiff.x,
          y: controlPointY + this.state.dragDiff.y,
        };

        const dragEndPoint = getRoundedEventPos(startPoint, eventPos);

        this.setState({
          dragEndPoint,
          dragIndex: index,
        });
      } else {
        this.setState({
          dragEndPoint: eventPos,
          dragIndex: index,
        });
      }
    };
  };

  // Existing line dragging begin
  onEndPointDragStart = (index) => {
    return (event) => {
      const eventPos = getEventPos(event, this.context.getEvents());
      if (!eventPos) {
        return;
      }

      const [controlPointX, controlPointY] = this.getControlPointsBySide(index);

      // нам нужен diff для расчета начальной точки при move тула
      const dragDiff = {
        x: eventPos.x - controlPointX,
        y: eventPos.y - controlPointY,
      };

      this.setState({
        dragDiff,
        dragStartPoint: eventPos,
        dragEndPoint: eventPos, // that's ok, we start to process at the very beginning of drag
        initialOffset: clone(this.context.getPageViewport().frameOffset),
      });
    };
  };

  onEndPointDragStop = () => {
    return () => {
    // when dragStop shoot, Content::onClick shoots too.
    // to prevent Content's onClick, we reset drag after a small delay
      if (this.state.dragIndex > dragPointIndexes.none) {
        this.updateElement();
      }
      this.props.setStretchingElementId(false);
      this.setState(initialState);
    };
  };

  // Existing line dragging end
  //

  //
  // New line drawing begin
  onDragStart = (event) => {
    const eventPos = getEventPos(event, this.context.getEvents());
    if (!eventPos) {
      return;
    }

    this.setState({
      dragStartPoint: eventPos,
      dragEndPoint: eventPos, // that's ok, we start to process at the very beginning of drag
      initialOffset: clone(this.context.getPageViewport().frameOffset),
    });
  };

  onDragMove = (event) => {
    stopEvent(event);

    // set drag only when drag is already started and some move appeared.
    // other way (dragstart->dragstop without move) - is click, with handler at Content.js
    if (!this.props.isDrawingNewGraphicElement) {
      this.props.setIsDrawingNewGraphicElement(true);
    }

    const eventPos = getEventPos(event, this.context.getEvents());

    if (!eventPos) {
      return;
    }

    const isPressedMetaKey = getIsPressedMetaKey(event);

    this.setState((prevState) => {
      const dragEndPoint = isPressedMetaKey
        ? getRoundedEventPos(prevState.dragStartPoint, eventPos)
        : eventPos;

      return {
        dragEndPoint,
        isDrawing: true,
      };
    });
  };

  onDragStop = () => {
    // when dragStop shoot, Content::onClick shoots too.
    // to prevent Content's onClick, we reset drag after a small delay
    setTimeout(() => {
      return this.props.setIsDrawingNewGraphicElement(false);
    }, 100);

    if (this.state.isDrawing) {
      this.addElement();
    }
    this.setState(initialState);
  };

  render() {
    const { scale, element, ghost, dragElementId, activeTool } = this.props;
    const viewElement = element || ghost;

    // В ходе выполнения JSF-2027 выяснилось, что иногда нам может прилететь
    // неправильный элемент в connect
    // т.к. connect этого компонента отрабатывает раньше чем connect Content.js
    // Поэтому необходима проверка isDrawType.
    // На следующем апдейте Content.js этот DrawingLayer уже не отрендерится
    if (dragElementId || !isDrawType(viewElement.type)) {
      return null;
    }

    const canCreateElements =
      activeTool &&
      (isLineType(activeTool.type) || isDrawRectType(activeTool.type));

    return (
      <DrawingLayerPointsView
        element={viewElement}
        controlPoints={this.getControlPoints()}
        onDragStart={this.onDragStart}
        onDragMove={this.onDragMove}
        onDragStop={this.onDragStop}
        onEndPointDragStart={this.onEndPointDragStart}
        onEndPointDragMove={this.onEndPointDragMove}
        onEndPointDragStop={this.onEndPointDragStop}
        frameSize={this.props.frameSize}
        canCreateElements={canCreateElements}
        drawExisting={!!element}
        isDraggingExistingObjectNow={this.state.dragIndex !== dragPointIndexes.none}
        scale={scale}
      />
    );
  }
}
