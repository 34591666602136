import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import SearchHighlighter from '../../SearchHighlighter/SearchHighlighter';
import { occurenceTypes } from '../../../helpers/const';
import { selectors } from '../../..';

import { getSearchDivFlatList } from '../../../helpers/searchUtils';

// TODO: to static css
const style = {
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

@connect(
  (__, { pageId }) => {
    const getPageOccurences = selectors.makeGetPageOccurences(pageId);
    return (state) => {
      return {
        occurences: getPageOccurences(state, pageId),
      };
    };
  },
)
export default class SearchLayer extends Component {
  static propTypes = {
    pageId: PropTypes.number.isRequired,
    occurences: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.bool,
    ]).isRequired,
  };

  renderSearchHighlighter = (item, index) => {
    return (
      <SearchHighlighter
        key={index}
        occurence={item}
        type={occurenceTypes.pdfText}
        pageId={this.props.pageId}
      />
    );
  };

  render() {
    const { occurences } = this.props;

    return (
      <div style={style}>
        {occurences && occurences.length > 0 &&
          getSearchDivFlatList(occurences)
            .map(this.renderSearchHighlighter)
        }
      </div>
    );
  }
}
