import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Component } from 'react';
import isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';
import { updateElement } from 'ws-editor-lib/actions';
import { cancellableOpts } from '../../store/modules/undoRedo';
import updateFillableElementThunk from '../../store/thunks/updateFillableElement';
import { limitByFrameSize } from '../../helpers/utils';
import { selectors } from '../..';

@connect(
  (__, { elementId }) => {
    return (state) => {
      const element = selectors.base.getElement(state, elementId);
      return {
        isConstructor: selectors.mode.isConstructor(state),
        originalSize: selectors.getOriginalSize(state, element.pageId),
        element,
      };
    };
  }, {
    updateElement,
    updateFillableElementThunk,
  },
)
export default class ElementBoundOnChangeSize extends Component {
  static propTypes = {
    // TODO: remove eslint-disable-next-line after remove UNSAFE_componentWillReceiveProps
    // eslint-disable-next-line react/no-unused-prop-types
    isActiveElement: PropTypes.bool.isRequired,
    element: PropTypes.shape({
      content: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
      }),
      template: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
      }),
    }).isRequired,
    isConstructor: PropTypes.bool.isRequired,
    elementId: PropTypes.string.isRequired,
    originalSize: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }).isRequired,
    updateElement: PropTypes.func.isRequired,
    updateFillableElementThunk: PropTypes.func.isRequired,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isActiveElement, isConstructor } = nextProps;

    if (!isActiveElement) {
      return;
    }

    const prevGeomteryObject = isConstructor
      ? get(this.props, 'element.template', {})
      : get(this.props, 'element.content', {});

    const nextGeometryObject = isConstructor
      ? get(nextProps, 'element.template', {})
      : get(nextProps, 'element.content', {});

    if (nextProps.element !== this.props.element) {
      if (this.isElementSizeChanged(nextGeometryObject, prevGeomteryObject)) {
        this.boundElement(nextGeometryObject, prevGeomteryObject);
      }
    }
  }

  isElementSizeChanged = (nextElem, prevElem) => {
    return (
      (
        prevElem.width && nextElem.width !== prevElem.width
      ) ||
      (
        prevElem.height && nextElem.height !== prevElem.height
      )
    );
  };

  boundElement = (elementNextProps, prevGeomteryObject) => {
    /**
     * After clear element - skip bound
     */
    if (Object.keys(elementNextProps).length === 0) {
      return;
    }

    const { isConstructor } = this.props;
    const { y, x, width } = elementNextProps;
    const height = elementNextProps.height !== undefined
      ? elementNextProps.height
      : prevGeomteryObject.height;

    const resultPosition = limitByFrameSize(
      { x, y },
      this.props.originalSize,
      { width, height },
    );
    if (!isEqual({ x, y }, resultPosition)) {
      if (isConstructor) {
        const newProps = { id: this.props.elementId, template: { ...resultPosition } };
        this.props.updateFillableElementThunk({ ...newProps }, cancellableOpts);
      } else {
        this.props.updateElement(this.props.elementId, resultPosition);
      }
    }
  };

  render() {
    return null;
  }
}
