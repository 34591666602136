import PropTypes from 'prop-types';
import React, { Component } from 'react';

import SimpleElementControls from '../SimpleElementControls';
import * as Ui from '../../../../ui';

const attributes = { ...Ui.attributes.grow, ...Ui.attributes.shrink };

export default class ToolbarAttributesTabletView extends Component {
  static propTypes = {
    storeRef: PropTypes.func.isRequired,

    // props for SimpleElementControls
    hasSize: PropTypes.bool.isRequired,
    hasLineWidth: PropTypes.bool.isRequired,
    hasStamp: PropTypes.bool.isRequired,
    sigDateStampChangeable: PropTypes.bool.isRequired,
    hasPopup: PropTypes.bool.isRequired,
    handleSizeUp: PropTypes.func.isRequired,
    handleSizeDown: PropTypes.func.isRequired,
    onSizeButtonFocus: PropTypes.func,
    handleLineWidthUp: PropTypes.func.isRequired,
    handleLineWidthDown: PropTypes.func.isRequired,
    handleToggleStampClick: PropTypes.func.isRequired,
    onRemovePressed: PropTypes.func.isRequired,
    onTogglePopupPressed: PropTypes.func.isRequired,
    isTextToolBasedElement: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    onSizeButtonFocus: null,
  };

  render() {
    const {
      hasSize,
      hasLineWidth,
      hasStamp,
      sigDateStampChangeable,
      hasPopup,
      handleSizeUp,
      handleSizeDown,
      onSizeButtonFocus,
      handleLineWidthUp,
      handleLineWidthDown,
      handleToggleStampClick,
      onRemovePressed,
      onTogglePopupPressed,
      isTextToolBasedElement,
    } = this.props;

    return (
      <Ui.Grid.Cell
        attributes={attributes}
        storeRef={this.props.storeRef}
      >
        <Ui.Grid.Wrapper>
          <Ui.ToolBar.Body
            size={Ui.toolBar.sizes.extraLarge}
            type={Ui.toolBar.types.common}
          >
            <Ui.ToolBar.Main>
              <SimpleElementControls
                hasSize={hasSize}
                hasLineWidth={hasLineWidth}
                hasStamp={hasStamp}
                sigDateStampChangeable={sigDateStampChangeable}
                hasPopup={hasPopup}
                handleSizeUp={handleSizeUp}
                handleSizeDown={handleSizeDown}
                onSizeButtonFocus={onSizeButtonFocus}
                handleLineWidthUp={handleLineWidthUp}
                handleLineWidthDown={handleLineWidthDown}
                handleToggleStampClick={handleToggleStampClick}
                onRemovePressed={onRemovePressed}
                onTogglePopupPressed={onTogglePopupPressed}
                isTextToolBasedElement={isTextToolBasedElement}
              />
            </Ui.ToolBar.Main>
          </Ui.ToolBar.Body>
        </Ui.Grid.Wrapper>
      </Ui.Grid.Cell>
    );
  }
}
