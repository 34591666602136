import * as Ghost from './Ghost';
import * as RightPanel from './RightPanel';
import * as Element from './Element';
import * as DateMinWidth from './DateMinWidth';
import * as Thumbnails from './Thumbnails';
import * as Portal from './Portal';
import * as Content from './Content';

import ScrollContextProvider, {
  ImmediateFrameOffsetProvider,
  FrameOffsetProvider,
} from './ScrollContextProvider';

import Header from './Header/Header';
import HeaderTools from './Header/HeaderTools/HeaderTools';
import HeaderZoom from './Header/HeaderZoom/HeaderZoom';
import HeaderLogo from './Header/HeaderLogo/HeaderLogo';
import HeaderHelp from './Header/HeaderHelp/HeaderHelp';
import HeaderDone from './Header/HeaderDone/HeaderDone';
import HeaderDeclineButton from './Header/HeaderDeclineButton/HeaderDeclineButton';
import HeaderDraftButton from './Header/HeaderDraftButton/HeaderDraftButton';
import HeaderSearch from './Header/HeaderSearch/HeaderSearch';
import HeaderComments from './Header/HeaderComments/HeaderComments';
import HeaderFakeEdit from './Header/HeaderFakeEdit/HeaderFakeEdit';
import HeaderDoneActionCreator from './Header/HeaderDone/HeaderDoneActionCreator';
import HeaderDoneVisibilityProvider from './Header/HeaderDone/HeaderDoneVisibilityProvider';

import Toolbar from './Toolbar/Toolbar';
import ToolbarNavigation from './Toolbar/ToolbarNavigation/ToolbarNavigation';
import ToolbarAttributes from './Toolbar/ToolbarAttributes/ToolbarAttributes';
import ToolbarAdvice from './Toolbar/ToolbarAdvice/ToolbarAdvice';
import ToolbarHelp from './Toolbar/ToolbarHelp/ToolbarHelp';
import ToolbarSearch from './Toolbar/ToolbarSearch/ToolbarSearch';

import PopupWrapper, { PopupWrapperSimple } from './Toolbar/ToolbarAttributes/Popup/PopupWrapper';
import PopupMainMenu from './Toolbar/ToolbarAttributes/Popup/PopupMainMenu';

import ThumbnailsSmall from './Thumbnails/ThumbnailsSmall/ThumbnailsSmall';

import Workspace from './Workspace/Workspace';
import WorkspaceContinious from './Workspace/WorkspaceContinious';

import PdfDocumentController from './Pdf/PdfDocumentController/PdfDocumentController';
import PdfGetController from './Pdf/PdfGetController/PdfGetController';
import PdfRunner from './Pdf/PdfRunner/PdfRunner';

import Pagination from './Pagination/Pagination';
import PaginationContinious from './Pagination/PaginationContinious/PaginationContinious';
import PaginationContiniousView from './Pagination/PaginationContinious/PaginationContiniousView';
import PaginationContiniousPinch from './Pagination/PaginationContiniousPinch/PaginationContiniousPinch';
import PaginationLabel from './Pagination/PaginationLabel/PaginationLabel';

import Page from './Page/Page';
import PageScroll from './Page/PageScroll/PageScroll';
import PageFrame from './Page/PageFrame/PageFrame';
import PagePinch from './Page/PagePinch/PagePinch';
import PageCanvas from './Page/PageCanvas/PageCanvas';
// eslint-disable-next-line import/no-named-as-default
import PageCanvasInserter, {
  PageThumbnailsInserter,
} from './Page/PageCanvas/PageCanvasInserter';
import PagePrint from './Page/PagePrint/PagePrint';
import PageContextProvider from './Page/PageContextProvider/PageContextProvider';
import PageFrameContinious from './Page/PageFrameContinious/PageFrameContinious';

import StoreRefProvider from './StoreRef/StoreRefProvider';

import PageAttributesLayer from './Content/PageAttributesLayer/PageAttributesLayer';

import SVGTool from './Tools/SVGTool/SVGTool';
import { SvgCheck, SvgCircle, SvgCross } from './Tools/SVGTool/Svgs';

import PictureTool from './Tools/PictureTool/PictureTool';

import SignStamp from './Tools/SignTool/SignStamp';

import CurveToolView from './Tools/CurveTool/CurveToolView';

import ColorToolbarSettings from './Tools/ToolbarSettings/Color/Color';
import ColorPopupSettings, { ColorPopupSettingsSimple } from './Tools/ToolbarSettings/Color/Popup/ColorPopup';
import ColorPopupEntry from './Tools/ToolbarSettings/Color/Popup/ColorPopupEntry';

import FontFamilyToolbarSettings from './Tools/ToolbarSettings/Font/FontFamily';
import FontFamilyPopupSettings from './Tools/ToolbarSettings/Font/Popup/FontFamilyPopup';
import FontFamilyPopupMenuSettings from './Tools/ToolbarSettings/Font/Popup/FontFamilyPopupMenuSettings';

import FontSizeToolbarSettings from './Tools/ToolbarSettings/FontSize/FontSize';
import FontSizePopupSettings from './Tools/ToolbarSettings/FontSize/Popup/FontSizePopup';
import FontSizePopupMenuSettings from './Tools/ToolbarSettings/FontSize/Popup/FontSizePopupMenu';

import LineWidthToolbarSettings from './Tools/ToolbarSettings/LineWidth/LineWidth';
import LineWidthPopupSettings from './Tools/ToolbarSettings/LineWidth/Popup/LineWidthPopup';

import StampToolbarSettings from './Tools/ToolbarSettings/Stamp/Stamp';

import {
  BToolbarSettings,
  UToolbarSettings,
  IToolbarSettings,
} from './Tools/ToolbarSettings/Style/Style';

import HrefToolbarSettings from './Tools/ToolbarSettings/Href/Href';
import HrefPopupSettings, { HrefPopupSettingsSimple } from './Tools/ToolbarSettings/Href/Popup/HrefPopup';

import AlignToolbarSettings, { AlignToolbarSettingsSimple } from './Tools/ToolbarSettings/Alignments/Align';
import AlignPopupSettings, { AlignPopupSettingsSimple } from './Tools/ToolbarSettings/Alignments/Popup/AlignPopup';

import VAlignToolbarSettings, { VAlignToolbarSettingsSimple } from './Tools/ToolbarSettings/Alignments/VAlign';
import VAlignPopupSettings, { VAlignPopupSettingsSimple } from './Tools/ToolbarSettings/Alignments/Popup/VAlignPopup';

import FontStylePopupSettings from './Tools/ToolbarSettings/Style/Popup/StylePopup';
import DirectionPopupSettings from './Tools/ToolbarSettings/Direction/Popup/DirectionPopup';
import DirectionToolbarSettings from './Tools/ToolbarSettings/Direction/Direction';


import OkBtn from './Toolbar/ToolbarAttributes/Mobile/OkBtn';

import Dropdown from './Dropdown/Dropdown';

import ResizeBox from './ResizeBox/ResizeBox';

import ContentEditable from './Controls/ContentEditable';
import MonthDayYearControl from './Controls/MonthDayYearControl';

import Hint from './Hint/Hint';
import InterfaceLockedModalWindow from './InterfaceLockedModalWindow/InterfaceLockedModalWindow';

import PageIdIndexContextProvider from './PageIdIndexContextProvider';
import IfContentShown from './Logic/IfContentShown';

// eslint-disable-next-line import/no-named-as-default
import Select from './Select';
import Slider from './Slider/Slider';

import MagneticLinesView from './MagneticLines/MagneticLinesView';

import ToolsDropDown from './Toolbar/MobileToolsDropDown/ToolsDropDown';
import TabletOkBtn from './Toolbar/ToolbarAttributes/Tablet/TabletOkBtn';

import OriginalSizeProvider from './OriginalSizeProvider';

import DraggablePanel from './DraggablePanel/DraggablePanel';
import DraggablePanelProvider from './DraggablePanel/DraggablePanelProvider';
import DraggablePanelProviderSn from './DraggablePanel/DraggablePanelProviderSn';

import WrapperForFocusController from './FocusController/WrapperForFocusController';

export {
  Ghost,
  Element,
  DateMinWidth,
  Thumbnails,

  ScrollContextProvider,
  ImmediateFrameOffsetProvider,
  FrameOffsetProvider,

  Portal,
  Header,
  HeaderTools,
  HeaderZoom,
  HeaderLogo,
  HeaderHelp,
  HeaderDone,
  HeaderDeclineButton,
  HeaderDraftButton,
  HeaderSearch,
  HeaderComments,
  HeaderFakeEdit,
  HeaderDoneActionCreator,
  HeaderDoneVisibilityProvider,
  Toolbar,
  ToolbarNavigation,
  ToolbarAttributes,
  ToolbarAdvice,
  ToolbarHelp,
  ToolbarSearch,
  PopupWrapper,
  PopupWrapperSimple,
  PopupMainMenu,

  ThumbnailsSmall,
  Workspace,
  WorkspaceContinious,
  PdfDocumentController,
  PdfGetController,
  PdfRunner,
  Pagination,
  PaginationContinious,
  PaginationContiniousView,
  PaginationContiniousPinch,
  PaginationLabel,
  Page,
  PageScroll,
  PageFrame,
  PagePinch,
  PageCanvas,
  PageCanvasInserter,
  PageThumbnailsInserter,
  PagePrint,
  PageContextProvider,
  PageFrameContinious,
  StoreRefProvider,
  Content,
  PageAttributesLayer,
  SVGTool,
  SvgCheck,
  SvgCircle,
  SvgCross,
  PictureTool,
  SignStamp,
  CurveToolView,
  ColorToolbarSettings,
  ColorPopupSettings,
  ColorPopupSettingsSimple,
  ColorPopupEntry,
  FontFamilyToolbarSettings,
  FontFamilyPopupSettings,
  FontFamilyPopupMenuSettings,
  FontSizeToolbarSettings,
  FontSizePopupSettings,
  FontSizePopupMenuSettings,
  LineWidthToolbarSettings,
  LineWidthPopupSettings,
  StampToolbarSettings,
  BToolbarSettings,
  UToolbarSettings,
  IToolbarSettings,
  HrefToolbarSettings,
  HrefPopupSettings,
  HrefPopupSettingsSimple,
  AlignToolbarSettings,
  AlignToolbarSettingsSimple,
  AlignPopupSettings,
  AlignPopupSettingsSimple,
  VAlignToolbarSettings,
  VAlignToolbarSettingsSimple,
  VAlignPopupSettings,
  VAlignPopupSettingsSimple,

  FontStylePopupSettings,
  DirectionPopupSettings,
  DirectionToolbarSettings,
  OkBtn,
  Dropdown,
  ResizeBox,
  RightPanel,
  ContentEditable,
  MonthDayYearControl,
  Hint,
  InterfaceLockedModalWindow,
  PageIdIndexContextProvider,
  IfContentShown,

  Select,
  Slider,
  MagneticLinesView,
  ToolsDropDown,
  TabletOkBtn,

  OriginalSizeProvider,
  DraggablePanel,
  DraggablePanelProvider,
  DraggablePanelProviderSn,

  WrapperForFocusController,
};
