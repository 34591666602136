import PropTypes from 'prop-types';
import { Component } from 'react';

import {
  limitByFrameSizeFreeGhost,
} from '../../helpers/utils';

export default class GhostLimiter extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    frameSize: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }).isRequired,
    getScaledGhostPosition: PropTypes.func.isRequired,
    ghostElementForRender: PropTypes.shape({
      content: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    scale: PropTypes.number.isRequired,
  };

  getScaledGhostPosition = (...args) => {
    const unlimitedPosition = this.props.getScaledGhostPosition(...args);
    const {
      frameSize, scale, ghostElementForRender,
    } = this.props;

    return limitByFrameSizeFreeGhost(
      unlimitedPosition,
      frameSize, {
        width: ghostElementForRender.content.width * scale,
        height: ghostElementForRender.content.height * scale,
      },
    );
  };

  render() {
    return this.props.children({
      getLimitedScaledGhostPosition: this.getScaledGhostPosition,
    });
  }
}
