import React from 'react';
import PropTypes from 'prop-types';
import * as JsfUi from 'jsfcore/ui';

const Inputs = (props) => {
  return (
    <JsfUi.CustomField.Index
      theme={JsfUi.customField.themes.lightgray}
      size={JsfUi.customField.sizes.small}
      type={JsfUi.customField.types.compact}
    >
      <JsfUi.CustomField.Body>
        <JsfUi.CustomField.Cell>
          <JsfUi.InputField
            data-is-date-input="true"
            theme={JsfUi.inputField.themes.default}
            size={JsfUi.inputField.sizes.parent}
            align={JsfUi.inputField.aligns.center}
            offset={JsfUi.inputField.offsets.smallHorizontal}
            value={props.hours}
            onFocus={props.onFocusHours}
            onBlur={props.onBlurHours}
            onChange={props.onChangeHours}
            type="number"
          />
        </JsfUi.CustomField.Cell>
        <JsfUi.CustomField.Cell>
          <JsfUi.Text
            size={14}
            theme={JsfUi.text.themes.dovegray}
            family={JsfUi.text.families.openSans}
          >
            :
          </JsfUi.Text>
        </JsfUi.CustomField.Cell>
        <JsfUi.CustomField.Cell>
          <JsfUi.InputField
            data-is-date-input="true"
            theme={JsfUi.inputField.themes.default}
            size={JsfUi.inputField.sizes.parent}
            align={JsfUi.inputField.aligns.center}
            offset={JsfUi.inputField.offsets.smallHorizontal}
            value={props.minutes}
            onFocus={props.onFocusMinutes}
            onBlur={props.onBlurMinutes}
            onChange={props.onChangeMinutes}
            type="number"
          />
        </JsfUi.CustomField.Cell>
      </JsfUi.CustomField.Body>
    </JsfUi.CustomField.Index>
  );
};

Inputs.propTypes = {
  minutes: PropTypes.string.isRequired,
  hours: PropTypes.string.isRequired,
  onChangeHours: PropTypes.func.isRequired,
  onChangeMinutes: PropTypes.func.isRequired,
  onFocusHours: PropTypes.func.isRequired,
  onFocusMinutes: PropTypes.func.isRequired,
  onBlurHours: PropTypes.func.isRequired,
  onBlurMinutes: PropTypes.func.isRequired,
};

export default Inputs;
