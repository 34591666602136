import hash from 'object-hash';

const cache = {};

if (__CLIENT__) {
  window.JSFillerStores = { ...window.JSFillerStores, rulesCache: cache };
}

const optsToString = (opts) => {
  return hash(opts);
};

const rulerCache = {
  put: (opts, result) => {
    cache[optsToString(opts)] = result;
  },

  get: (opts) => {
    return cache[optsToString(opts)] || undefined;
  },

  delete: (opts) => {
    delete cache[optsToString(opts)];
  },

  list: () => {
    return cache;
  },
};

export default rulerCache;
