import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cls from 'classnames';

export const iconTypes = {
  settings: 'settings',
  save: 'save',
  user: 'user',
  addUser: 'add-user',
  fountainPen: 'fountain-pen',
  pencil: 'pencil',
  clock: 'clock',
  check: 'check',
  record: 'record',
  navigateDown: 'navigate-down',
  paperclip: 'paperclip',
  help: 'help',
  move: 'move',
  delete: 'delete',
  checkWide: 'check-wide',
  upload: 'upload',
  coloredPointerLeft: 'colored--pointer-left',
  coloredPointerUp: 'colored--pointer-up',
  menu: 'menu',
  plus: 'plus',
  trash: 'trash',
  trashBin: 'trash-bin',
  downRight: 'down-right',
  arrowDownright: 'arrow-downright',
  cross: 'cross',
  calculator: 'calculator',
  dropdown: 'dropdown',
  ban: 'ban',
  chat: 'chat',
  lock: 'lock',
  undo: 'undo',
  redo: 'redo',
  zoomOut: 'zoom-out',
  zoomIn: 'zoom-in',
  download: 'download',
  arrowRight: 'arrow-right',
  doubleArrowRight: 'double-arrow-right',
  doubleArrowLeft: 'double-arrow-left',
  arrowLeft: 'arrow-left',
  fieldProperties: 'field-properties',
  thumbnails: 'thumbnails',
  close: 'close',
};

export const iconSizes = {
  medium: 'medium',
  small: 'small',
  extraSmall: 'extra-small',
  parent: 'parent',
};

export default class Icon extends PureComponent {
  static propTypes = {
    type: PropTypes.oneOf(
      Object.values(iconTypes),
    ),
    size: PropTypes.oneOf(
      Object.values(iconSizes),
    ),
  };

  static defaultProps = {
    type: iconTypes.settings,
    size: iconSizes.medium,
  }

  render() {
    const { type, size } = this.props;
    return (
      <span
        className={
          cls('icon', {
            'icon--size--medium': size === iconSizes.medium,
            'icon--size--small': size === iconSizes.small,
            'icon--size--extra-small': size === iconSizes.extraSmall,
            'icon--size--parent': size === iconSizes.parent,
          })
        }
        aria-hidden="true"
      >
        <svg className="icon__pic">
          <use xlinkHref={`#${type}`} />
        </svg>
      </span>
    );
  }
}
