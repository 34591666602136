import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import HeaderSearchView from './HeaderSearchView';
import toggleSearchMode from '../../../store/thunks/toggleSearchMode';
import { nullComponent } from '../../../helpers/utilsForReact15';
import { miscIds } from '../../../helpers/const';
import {
  getMiscFeatureVisible,
  locale as localeSelectors,
} from '../../../store/selectors';

@connect(
  (state) => {
    return {
      label: localeSelectors.getMiscLabel(state, miscIds.search.id),
      isSearchModeAvailable: getMiscFeatureVisible(state, miscIds.search.id),
      isSearchModeActive: state.search.isSearchModeActive,
    };
  }, {
    toggleSearchMode,
  },
)
export default class HeaderSearch extends Component {
  static propTypes = {
    isSearchModeActive: PropTypes.bool.isRequired,
    isSearchModeAvailable: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    toggleSearchMode: PropTypes.func.isRequired,
  }

  render() {
    if (!this.props.isSearchModeAvailable) {
      return nullComponent();
    }

    return (
      <HeaderSearchView
        onClick={this.props.toggleSearchMode}
        label={this.props.label}
        isSearchModeActive={this.props.isSearchModeActive}
      />
    );
  }
}
