import React from 'react';
import classnames from 'classnames';

const currentYear = new Date().getFullYear();

const menuRendererYearDropDown = ({
  instancePrefix,
  onFocus,
  onSelect,
  optionClassName,
  optionComponent: Option,
  optionRenderer,
  options,
  valueKey,
  valueArray,
  onOptionRef,
}) => {
  return (
    options.map((option, i) => {
      const isSelected = !valueArray.length
        ? false
        : valueArray[0].value === option.value;
      // Shift focus on two years higher
      const isFocused = !valueArray.length
        ? option.value === currentYear - 2
        : valueArray[0].value === option.value + 2;

      return (
        <Option
          className={classnames(optionClassName, {
            'Select-option': true,
            'is-selected': isSelected,
            'is-focused': isFocused,
            'is-disabled': option.disabled,
            'is-selected-DropDown__Center': isFocused,
          })}
          instancePrefix={instancePrefix}
          isDisabled={option.disabled}
          isFocused={isFocused}
          isSelected={isSelected}
          key={`option-${option.value}-${option[valueKey]}`}
          onFocus={onFocus}
          onSelect={onSelect}
          option={option}
          optionIndex={i}
          ref={(ref) => {
            onOptionRef(ref, isFocused);
          }}
        >
          {optionRenderer(option, i)}
        </Option>
      );
    })
  );
};

export default menuRendererYearDropDown;
