import PropTypes from 'prop-types';
import React, { Component } from 'react';
import escape from 'lodash/escape';
import assignIn from 'lodash/assignIn';

import {
  getRulerStyle,
  fixRulerText,
  getLinesCount,
} from './utils/textToolUtils';
import rulerCache from './utils/rulerCache';
import { unformat, format } from '../../../jsf-validations/utils/formatUnformat';
import { defaultLineHeight } from '../../../helpers/const';

export default class RulerView extends Component {
  static propTypes = {
    element: PropTypes.shape({}).isRequired,
    fontSize: PropTypes.number,
    lineHeight: PropTypes.number,
    padding: PropTypes.number.isRequired,
    rulerText: PropTypes.string.isRequired,
    onRulerChange: PropTypes.func.isRequired,

    // eslint-disable-next-line react/no-unused-prop-types
    minWidth: PropTypes.number,
    // eslint-disable-next-line react/no-unused-prop-types
    minHeight: PropTypes.number,
  };

  static defaultProps = {
    fontSize: 10,
    lineHeight: defaultLineHeight,
    minWidth: null,
    minHeight: null,
  };

  componentDidMount() {
    // After render dom send real size of text
    // to statefull component
    this.props.onRulerChange(this.getRulerSize());
  }

  shouldComponentUpdate(nextProps) {
    const cachedResultForNextProps = rulerCache.get(
      this.getOpts(false, true, nextProps),
    );

    if (cachedResultForNextProps) {
      this.props.onRulerChange(cachedResultForNextProps);
      return false;
    }

    rulerCache.delete(this.getOpts(false, true));

    return true;
  }

  componentDidUpdate() {
    // After re-render dom send real size of text
    // to statefull component
    this.props.onRulerChange(this.getRulerSize());
  }

  getRulerBounding = () => {
    const formattedRulerBB = this.rulerRef.getBoundingClientRect();
    const nonFormattedRulerBB = this.nonFormattedRulerRef.getBoundingClientRect();
    if (nonFormattedRulerBB.height > formattedRulerBB.height) {
      return nonFormattedRulerBB;
    }

    if (nonFormattedRulerBB.height < formattedRulerBB.height) {
      return formattedRulerBB;
    }

    if (nonFormattedRulerBB.width > formattedRulerBB.width) {
      return nonFormattedRulerBB;
    }

    return formattedRulerBB;
  }

  getRulerSize = () => {
    if (!this.rulerRef) {
      return { width: 0, height: 0 };
    }
    const { width, height } = this.getRulerBounding();
    const result = {
      height,
      width,
      linesCount: getLinesCount({
        fontSize: this.props.fontSize,
        height,
        lineHeight: this.props.lineHeight,
        padding: this.props.padding,
      }),
      // Sometimes isEqual works incorrect with DOMRect object
      realSize: assignIn({}, this.rulerRealSizeRef.getBoundingClientRect()),
    };

    rulerCache.put(
      this.getOpts(false, true),
      result,
    );

    return result;
  };

  getOpts = (withoutMinHeight = false, withText = false, props = this.props) => {
    return {
      fontSize: props.fontSize,
      lineHeight: props.lineHeight,
      bold: props.bold,
      italic: props.italic,
      underline: props.underline,
      fontFamily: props.fontFamily,
      maxWidth: props.maxWidth,
      minWidth: props.minWidth,
      ...(
        withoutMinHeight
          ? {}
          : { minHeight: props.minHeight }
      ),
      ...(
        withText
          ? { rulerText: props.rulerText }
          : {}
      ),
      padding: props.padding,
      resizingGeometry: props.resizingGeometry,
    };
  }

  getEscapedText = () => {
    return escape(fixRulerText(this.props.rulerText));
  };

  getNonFormattedText = () => {
    const escapedText = this.getEscapedText();
    return unformat(escapedText, this.props.element);
  };

  getFormattedText = () => {
    return format(this.getNonFormattedText(), this.props.element, this.getEscapedText());
  };

  storeRulerRef = (ref) => {
    this.rulerRef = ref;
  };

  storeNonFormattedRulerRef = (ref) => {
    this.nonFormattedRulerRef = ref;
  }

  storeRulerRealSizeRef = (ref) => {
    this.rulerRealSizeRef = ref;
  };

  render() {
    const rulerStyle = getRulerStyle(this.getOpts());
    const rulerRealSizeStyle = getRulerStyle(this.getOpts(true));

    const formattedText = this.getFormattedText();

    return (
      <div className="rulerWrap-TextTool">
        <div className="rulerDiv-TextTool">
          <span
            ref={this.storeRulerRef}
            style={rulerStyle}
            // Content passed to dangerouslySetInnerHTML is sanitized HTML
            //
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: formattedText }}
          />
          <span
            ref={this.storeNonFormattedRulerRef}
            style={rulerStyle}
            // Content passed to dangerouslySetInnerHTML is sanitized HTML
            //
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: this.getEscapedText() }}
          />
          <span
            ref={this.storeRulerRealSizeRef}
            style={rulerRealSizeStyle}
            // Content passed to dangerouslySetInnerHTML is sanitized HTML
            //
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: formattedText }}
          />
        </div>
      </div>
    );
  }
}
