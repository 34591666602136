import PropTypes from 'prop-types';
import React, { Component } from 'react';

const style = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1,
  touchAction: 'none',
};

const backgroundTypes = {
  none: { background: 'none' },
  white: { background: 'rgba(255, 255, 255, .80)' },
};

export default class LockLayerView extends Component {
  static propTypes = {
    backgroundType: PropTypes.oneOf(
      Object.keys(backgroundTypes),
    ).isRequired,
  };

  render() {
    return (
      <div
        style={{
          ...style,
          ...backgroundTypes[this.props.backgroundType],
        }}
      />
    );
  }
}
