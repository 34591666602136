import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { selectors } from 'jsfcore';
import {
  undo,
  redo,
} from 'jsfcore/store/actions/undoRedo/undoRedo';
import { rearrange, dispatchAction } from '@pdffiller/jsf-lazyload';

import { toggleThumbnailsVisibility } from '../../../store/modules/viewport';
import ToolbarNavigationView from './ToolbarNavigationView';
import RearrangeNavigationView from './RearrangeNavigationView';
import UndoRedoNavigationView from './UndoRedoNavigationView';

import { getIsReadOnlyOverlayEnabled } from '../../../helpers/utils';
import { miscIds } from '../../../helpers/const';

const getIsRearrangeVisible = (state) => {
  if (
    state.viewport.isPAConstructorShown ||
    state.viewport.isFConstructorShown ||
    state.viewport.isVersionsShown
  ) {
    return false;
  }
  if (selectors.getMiscFeatures(state)) {
    return selectors.getMiscFeatureVisible(state, miscIds.rearrange.id);
  }
  return false;
};

export const getIsThumbnailsVisible = (state) => {
  if (selectors.getMiscFeatures(state)) {
    return selectors.getMiscFeatureVisible(state, miscIds.thumbnails.id);
  }
  return false;
};

export const isNeedToggleThumbnailsVisibility = ({
  isThumbnailsVisibleOld,
  isThumbnailsVisible,
  isThumbnailsOpen,
  pagesSettingsOld,
  pagesSettings,
}) => {
  if (isThumbnailsVisibleOld !== isThumbnailsVisible && !isThumbnailsVisible) {
    return true;
  }

  return (
    pagesSettingsOld !== pagesSettings && pagesSettings &&
    (
      (isThumbnailsOpen && pagesSettings.length === 1) ||
      (
        !isThumbnailsOpen && pagesSettingsOld &&
        pagesSettingsOld.length === 1 && pagesSettings.length > 1
      )
    )
  );
};

@connect(
  (state) => {
    return {
      isThumbnailsOpen: selectors.base.getIsThumbnailsOpen(state),
      locale: selectors.locale.getEditorLocale(state).toolbarNavigation,
      pagesSettings: selectors.navigation.getPagesSettings(state),
      isRearrangeVisible: getIsRearrangeVisible(state),
      isUndoAvailable: selectors.isUndoAvailable(state),
      isRedoAvailable: selectors.isRedoAvailable(state),
      isThumbnailsVisible: getIsThumbnailsVisible(state),
      toolbarOverlays: state.viewport.toolbarOverlays,
    };
  }, { undo, redo, toggleThumbnailsVisibility },
)

export default class ToolbarNavigation extends Component {
  static propTypes = {

    // from global.state
    isThumbnailsOpen: PropTypes.bool.isRequired,
    pagesSettings: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.array,
    ]),
    locale: PropTypes.shape({
      showPages: PropTypes.string.isRequired,
      hidePages: PropTypes.string.isRequired,
    }).isRequired,
    isRearrangeVisible: PropTypes.bool.isRequired,
    isUndoRedoVisible: PropTypes.bool,
    isThumbnailsVisible: PropTypes.bool,
    isUndoAvailable: PropTypes.bool.isRequired,
    isRedoAvailable: PropTypes.bool.isRequired,
    toolbarOverlays: PropTypes.arrayOf(PropTypes.string).isRequired,

    // actions
    toggleThumbnailsVisibility: PropTypes.func.isRequired,
    undo: PropTypes.func.isRequired,
    redo: PropTypes.func.isRequired,
  };

  static defaultProps = {
    pagesSettings: false,
    isThumbnailsVisible: false,
  };

  // на данный момент undoRedo захардкожено всегда в true
  // вероятно, нужен динамический параметр с бекенда, определяющий видимость кнопок
  static defaultProps = {
    isUndoRedoVisible: true,
  };

  componentDidMount() {
    if (window && window.innerWidth < 1024 && this.props.isThumbnailsOpen) {
      this.props.toggleThumbnailsVisibility();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ pagesSettings, isThumbnailsVisible, isThumbnailsOpen }) {
    if (
      isNeedToggleThumbnailsVisibility({
        isThumbnailsVisibleOld: this.props.isThumbnailsVisible,
        isThumbnailsVisible,
        isThumbnailsOpen,
        pagesSettingsOld: this.props.pagesSettings,
        pagesSettings,
      })
    ) {
      this.props.toggleThumbnailsVisibility();

      // disable animation
      const barNode = document.querySelector('.jsf-left-sb');
      if (barNode) {
        barNode.style.transition = 'none';
      }

      setTimeout(() => {
        if (barNode) {
          barNode.style.transition = null;
        }

        // scroll page to top
        const pageNode = document.querySelector('.wrapper-PageScroll');
        if (pageNode) {
          pageNode.scrollTop = 0;
        }
      }, 1000);
    }
  }

  onClickUndo = () => {
    if (!this.props.isUndoAvailable) {
      return;
    }

    this.props.undo();
  };

  onClickRedo = () => {
    if (!this.props.isRedoAvailable) {
      return;
    }

    this.props.redo();
  };

  onClickPages = (event) => {
    event.stopPropagation();
    event.preventDefault();

    this.props.toggleThumbnailsVisibility();
  };

  onClickRearrange = () => {
    dispatchAction(rearrange.actions.openPageRearrange);
  };

  // существует три версии углового туллбара:
  // ToolbarNavigationView - одна широкая кнопка с локалью "show pages/hide pages"
  // RearrangeNavigationView - две раздвижные кнопки: Pages и Rearrange
  // UndoRedoNavigationView - 4 маленьких кнопки: Pages, Rearrange, Undo, Redo
  // TODO: нужно спроектировать универсальную верстку для всех случаев
  render() {
    const isReadOnly = getIsReadOnlyOverlayEnabled(this.props.toolbarOverlays);

    if (!this.props.isRearrangeVisible) {
      return (
        <ToolbarNavigationView
          onClickPages={this.onClickPages}
          onClickUndo={this.onClickUndo}
          onClickRedo={this.onClickRedo}
          isUndoAvailable={this.props.isUndoAvailable}
          isRedoAvailable={this.props.isRedoAvailable}
          isThumbnailsOpen={this.props.isThumbnailsOpen}
          isReadOnly={isReadOnly}
          locale={this.props.locale}
        />
      );
    }

    if (this.props.isRearrangeVisible && !this.props.isUndoRedoVisible) {
      return (
        <RearrangeNavigationView
          onClickPages={this.onClickPages}
          onClickRearrange={this.onClickRearrange}
          isThumbnailsOpen={this.props.isThumbnailsOpen}
          locale={this.props.locale}
        />
      );
    }

    if (this.props.isRearrangeVisible && this.props.isUndoRedoVisible) {
      return (
        <UndoRedoNavigationView
          onClickPages={this.onClickPages}
          onClickRearrange={this.onClickRearrange}
          onClickUndo={this.onClickUndo}
          onClickRedo={this.onClickRedo}
          isUndoAvailable={this.props.isUndoAvailable}
          isRedoAvailable={this.props.isRedoAvailable}
          isThumbnailsOpen={this.props.isThumbnailsOpen}
          locale={this.props.locale}
        />
      );
    }

    return null;
  }
}
