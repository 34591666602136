import getSvg from './getGvg';

const checkPath = 'M20.2168069,2.37826071 C20.5602057,1.94571509 21.1892329,1.87344731 21.6217785,2.2168461 C22.0543241,2.56024489 22.1265919,3.18927212 21.7831931,3.62181775 C20.7952592,4.86622071 18.797349,6.92913859 16.5,11.0000392 C14.202651,15.0709399 12.4277987,19.8062102 11.9531933,21.3024009 C11.7264927,22.0170731 10.823058,22.2373108 10.2928932,21.707146 L2.29289322,13.707146 C1.90236893,13.3166217 1.90236893,12.6834567 2.29289322,12.2929324 C2.68341751,11.9024082 3.31658249,11.9024082 3.70710678,12.2929324 L10.5,18.0000392 C10.9535071,16.7547651 12.3884718,13.3524942 14.5,10.0000392 C16.4825292,6.8523948 19.1249316,3.7535884 20.2168069,2.37826071 Z';
const circlePath = 'M10.5193018,20 C14.7281473,20 18.9019811,16.3287896 19.5803352,11.7474776 C20.2330385,7.3393996 17.4253721,4 13.4806982,4 C9.27185268,4 5.09801889,7.67121039 4.41966483,12.2525224 C3.76696151,16.6606004 6.57462786,20 10.5193018,20 Z M10.5193018,22 C5.05809131,22 1.29383667,17.5228475 2.11160372,12 C2.92937077,6.4771525 8.01948776,2 13.4806982,2 C18.9419087,2 22.7061633,6.4771525 21.8883963,12 C21.0706292,17.5228475 15.9805122,22 10.5193018,22 Z';
const crossPath = 'M8.73116402,13.8641249 L2.30190244,7.56937054 C1.89936585,7.16683401 1.89936585,6.21229096 2.30190244,5.80975444 C2.70443903,5.40721791 3.65898224,5.40721791 4.06151883,5.80975444 L10.312804,12.194616 L19.5511348,2.44304468 C20.0900572,1.87418213 20.9880944,1.84991086 21.5569569,2.38883328 C21.5662343,2.39762231 21.5753924,2.40653629 21.5844289,2.41557276 C22.1385236,2.9696675 22.1385236,3.86803259 21.5844289,4.42212733 C21.5753924,4.4311638 21.5662343,4.44007778 21.5569569,4.44886681 L11.7891858,13.7025448 L18.1902482,20.2403839 C18.5927848,20.6429204 18.5927848,21.2955611 18.1902482,21.6980976 C17.7877116,22.1006341 17.1350708,22.1006341 16.7325342,21.6980976 L10.1511319,15.2543854 L3.55987492,21.4987341 C3.26536816,21.7777406 2.802241,21.7714832 2.51537971,21.4846219 C2.22851841,21.1977606 2.22226108,20.7346335 2.50126749,20.4401267 L8.73116402,13.8641249 Z';

const SvgCheck = getSvg(checkPath);
const SvgCross = getSvg(crossPath);
const SvgCircle = getSvg(circlePath);

export {
  SvgCheck,
  SvgCross,
  SvgCircle,
};
