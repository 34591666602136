import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';


import { selectors } from '../../..';
import { paSections } from '../../../helpers/const';
import { PageAttributesItem, PageAttributesWatermark } from '.';
import { getIndexByPageId } from '../../../helpers/utils';

@connect(
  (__, { pageId }) => {
    return (state) => {
      const pageIndex = getIndexByPageId(pageId, selectors.navigation.getPagesSettings(state));
      return {
        activePaConstructorSection: selectors.getIsPACShownWithSection(state),
        isWatermarkBarVisible:
          selectors.getIsAttrVisibleOnPage(state, paSections.watermark, pageIndex),
        isDateVisible: selectors.getIsAttrVisibleOnPage(state, paSections.date, pageIndex),
        isNumberingVisible:
          selectors.getIsAttrVisibleOnPage(state, paSections.numbering, pageIndex),
        isBatesVisible: selectors.getIsAttrVisibleOnPage(state, paSections.bates, pageIndex),

        pageId, // current page
        isPAConstructorShown: state.viewport.isPAConstructorShown,
      };
    };
  },
)
export default class PageAttributesLayer extends Component {
  static propTypes = {
    activePaConstructorSection: PropTypes.oneOfType([
      PropTypes.oneOf(Object.values(paSections)),
      PropTypes.bool,
    ]).isRequired,
    isWatermarkBarVisible: PropTypes.bool.isRequired,
    isDateVisible: PropTypes.bool.isRequired,
    isNumberingVisible: PropTypes.bool.isRequired,
    isBatesVisible: PropTypes.bool.isRequired,

    pageId: PropTypes.number.isRequired,
    isPAConstructorShown: PropTypes.bool.isRequired,
  };

  static contextTypes = {
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
    }).isRequired,
    getEvents: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = { draggingSection: false };
  }

  setDraggingSection = (attrName) => {
    this.setState({ draggingSection: attrName });
  };

  getShownLimiterName = () => {
    const { activePaConstructorSection, isPAConstructorShown } = this.props;
    if (!isPAConstructorShown) {
      return false;
    }
    return this.state.draggingSection || activePaConstructorSection;
  };

  resetDraggingSection = () => {
    this.setState({ draggingSection: false });
  };

  render() {
    const { pageId,
      isPAConstructorShown,
      isWatermarkBarVisible, isDateVisible, isBatesVisible, isNumberingVisible,
    } = this.props;

    const shownLimiterName = this.getShownLimiterName();

    return (
      <div
        className={classnames('pageAttributesLayer-PageAttributesLayer', {
          'constructor-active': isPAConstructorShown,
        })}
      >
        {isNumberingVisible && (
          <PageAttributesItem
            attrName={paSections.numbering}
            shownLimiterName={shownLimiterName}
            setDraggingSection={this.setDraggingSection}
            resetDraggingSection={this.resetDraggingSection}
            pageId={pageId}
          />
        )}
        {isDateVisible && (
          <PageAttributesItem
            attrName={paSections.date}
            shownLimiterName={shownLimiterName}
            setDraggingSection={this.setDraggingSection}
            resetDraggingSection={this.resetDraggingSection}
            pageId={pageId}
          />
        )}
        {isBatesVisible && (
          <PageAttributesItem
            attrName={paSections.bates}
            shownLimiterName={shownLimiterName}
            setDraggingSection={this.setDraggingSection}
            resetDraggingSection={this.resetDraggingSection}
            pageId={pageId}
          />
        )}
        {isWatermarkBarVisible && (
          <PageAttributesWatermark pageId={pageId} />
        )}
      </div>
    );
  }
}
