import get from 'lodash/get';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { isSignNow } from 'ws-editor-lib';
import RulerView from '../Tools/TextTool/RulerView';
import { isDate, isFillable } from '../../store/helpers/functions';
import { mode } from '../../store/selectors';
import * as jsfValidationsSelectors from '../../jsf-validations/selectors/validatorsSelectors';
import getRenderTextForDateFormat from './getRenderTextForDateFormat';

import DateMinWidthBounds from './DateMinWidthBounds';
import DateMinWidthUpdater from './DateMinWidthUpdater';
import Portal from '../Portal/Portal';

const DATE_MIN_WIDTH_RULER_PADDING = 3;

const mapStateToProps = (state) => {
  return {
    isConstructor: mode.isConstructor(state),
    validators: jsfValidationsSelectors.getDateValidators(state),
  };
};

export class DateMinWidthProvider extends Component {
  static propTypes = {
    children: PropTypes.func,
    isConstructor: PropTypes.bool.isRequired,
    draggableProps: PropTypes.shape({
      resizeIndex: PropTypes.number,
    }),
    element: PropTypes.shape({
      template: PropTypes.shape({
        bold: PropTypes.bool,
        italic: PropTypes.bool,
        underline: PropTypes.bool,
        fontFamily: PropTypes.string,
        fontSize: PropTypes.number,
        validator: PropTypes.shape({
          momentFormat: PropTypes.string,
        }),
      }),
    }).isRequired,
    isGhost: PropTypes.bool,
    validators: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })).isRequired,
  };

  static defaultProps = {
    children: null,
    isGhost: false,
    draggableProps: {
      resizeIndex: 0,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      elementMinWidth: 0,
    };
  }

  getRulerText() {
    // если это элемент, то сразу получаем значение momentFormat и возвращаем его
    const momentFormat = get(this.props, 'element.template.validator.momentFormat');

    if (momentFormat) {
      return getRenderTextForDateFormat(momentFormat);
    }

    // для ghost по validatorId находим значение валидатора
    const validatorId = get(this.props, 'element.template.validatorId');
    const validator = find(this.props.validators, (v) => {
      return v.id === validatorId;
    });

    if (validator) {
      const rulerText = getRenderTextForDateFormat(validator.momentFormat);
      return rulerText;
    }

    return false;
  }

  onRulerChange(size) {
    const { width } = size;
    if (width && this.state.elementMinWidth !== width) {
      this.setState({ elementMinWidth: width });
    }
  }

  renderRuler() {
    const rulerText = this.getRulerText();

    if (!rulerText) {
      return null;
    }

    const { element } = this.props;
    const {
      fontSize,
      fontFamily,
      bold,
      italic,
      underline,
    } = element.template;

    return (
      <RulerView
        fontSize={fontSize}
        fontFamily={fontFamily}
        element={element}
        bold={bold}
        italic={italic}
        underline={underline}
        rulerText={rulerText}
        padding={DATE_MIN_WIDTH_RULER_PADDING}
        onRulerChange={(size) => {
          this.onRulerChange(size);
        }}
      />
    );
  }

  renderUpdater() {
    return (
      <DateMinWidthUpdater
        element={this.props.element}
        elementMinWidth={this.state.elementMinWidth}
      />
    );
  }

  renderBounder() {
    return (
      <DateMinWidthBounds
        element={this.props.element}
        elementMinWidth={this.state.elementMinWidth}
        draggableProps={this.props.draggableProps}
      >
        {({ boundedElement }) => {
          return (
            this.props.children({
              ...this.props,
              boundedElement,
            })
          );
        }}
      </DateMinWidthBounds>
    );
  }

  renderChildrenWithoutBound() {
    return this.props.children({
      ...this.props,
      boundedElement: this.props.element,
    });
  }

  render() {
    const { isConstructor, element, isGhost } = this.props;
    const isResizing = this.props.draggableProps.resizeIndex !== 0;
    const isGhostElementInSignNow = isGhost && isSignNow();
    const needRenderUpdater = !isResizing && !isGhostElementInSignNow;

    if (!isConstructor || !isDate(element) || !isFillable(element)) {
      // для ghost просто возвращаем null, а для элемента renderProps дальше по дереву
      return isGhost
        ? null
        : this.renderChildrenWithoutBound();
    }

    // renderUpdater активируется только если не происходит resize,
    // чтобы во время resize не вызывался action обновления

    // renderBounder активируется только для элемента, но не для ghost
    return (
      <Fragment>
        <Portal>
          {this.renderRuler()}
        </Portal>

        {!isGhost && this.renderBounder()}
        {needRenderUpdater && this.renderUpdater()}
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, null)(DateMinWidthProvider);
