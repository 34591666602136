import PropTypes from 'prop-types';
import { Children, cloneElement } from 'react';

import { elemTypes } from '../../../helpers/elemTypes';


const RectToolDisabledProvider = ({ isDisabled, element, children }) => {
  const isDisabledByFakeEditLinks = (
    element.type === elemTypes.erase &&
    element.content.linkedTextId !== undefined
  );

  return cloneElement(Children.only(children), {
    isDisabled: isDisabled || isDisabledByFakeEditLinks,
  });
};

RectToolDisabledProvider.propTypes = {
  children: PropTypes.element.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  element: PropTypes.shape({
    type: PropTypes.string.isRequired,
    content: PropTypes.shape({
      linkedTextId: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default RectToolDisabledProvider;
