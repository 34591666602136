import omit from 'lodash/omit';
import React from 'react';
import PropTypes from 'prop-types';

import { defaultMemoize } from 'reselect';
import Dropdown from '../Dropdown/Dropdown';
import { Wrapper, wrapperPositions, wrapperPresets } from '../Portal';
import HintIcon from './HintIcon';
import * as Ui from '../../ui';

const mergeWrapperProps = defaultMemoize((wrapperProps) => {
  return {
    locatorArgs: {
      position: wrapperPositions.bottomCenterOuter,
      preset: wrapperPresets.hint,
    },
    offset: Ui.popover.offsets.small,
    ...wrapperProps,
  };
});

/**
 * Создаем фабрику компонентов, которые будем передавать
 * в Dropdown, т.к Dropdown должен принимать компонент (функцию),
 * в который затем отдаст closePortal.
 */
const dropdownWrapper = defaultMemoize((CustomHint, html) => {
  // eslint-disable-next-line react/prop-types
  return ({ closePortal }) => {
    return (
      <Ui.TextBox.Body>
        <Ui.Text size={14} html={html}>
          {CustomHint !== null
            ? <CustomHint closePortal={closePortal} />
            : null}
        </Ui.Text>
      </Ui.TextBox.Body>
    );
  };
});

const Hint = ({
  wrapperProps,
  timeout,
  children,
  customHintComponent,
  html,
  openPortalOnMount,
}) => {
  return (
    <Dropdown
      timeout={timeout}
      dropdownComponent={dropdownWrapper(customHintComponent, html)}
      wrapperProps={mergeWrapperProps(wrapperProps)}
      openPortalOnMount={openPortalOnMount}
    >
      {({ closePortal, openPortal, storeRef, isPortalOpen }) => {
        return children({
          onMouseEnter: openPortal,
          onMouseLeave: closePortal,
          isMouseOn: isPortalOpen,
          storeRef,
        });
      }}
    </Dropdown>
  );
};

Hint.propTypes = {
  children: PropTypes.func,
  html: PropTypes.string,
  customHintComponent: PropTypes.func,

  timeout: PropTypes.number,
  wrapperProps: PropTypes.shape(
    omit(Wrapper.propTypes, ['getRef', 'children']),
  ),
  openPortalOnMount: PropTypes.bool,
};

Hint.defaultProps = {
  children: HintIcon,
  html: '',
  customHintComponent: null,

  timeout: null,
  wrapperProps: {},
  openPortalOnMount: false,
};

export default Hint;
