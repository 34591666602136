import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { thunks } from '../../..';
import * as selectors from '../../../store/selectors';
import ThumbnailsSmallView from './ThumbnailsSmallView';

@connect(
  (state) => {
    return {
      activePageId: selectors.base.getNextPageId(state) || selectors.base.getActivePageId(state),
      count: state.pdf.count,
      locale: selectors.locale.getEditorLocale(state).thumbnails,
    };
  }, {
    changePage: thunks.changePage,
  },
)
export default class ThumbnailsSmall extends Component {
  static propTypes = {

    // from global.state
    activePageId: PropTypes.number.isRequired,
    count: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool,
    ]).isRequired,
    locale: PropTypes.shape({
      label: PropTypes.string.isRequired,
      labelInit: PropTypes.string.isRequired,
    }).isRequired,

    // actions
    changePage: PropTypes.func.isRequired,
  };

  static contextTypes = {
    getAdjacentPageId: PropTypes.func,
    getIndexByPageId: PropTypes.func,
  };

  canPrev = () => {
    return (
      this.props.count !== false &&
      this.context.getIndexByPageId(this.props.activePageId) !== 0
    );
  };

  canNext = () => {
    return (
      this.props.count !== false &&
      this.context.getIndexByPageId(this.props.activePageId) !== this.props.count - 1
    );
  };

  toPrev = () => {
    if (this.canPrev()) {
      this.props.changePage(this.context.getAdjacentPageId(-1));
    }
  };

  toNext = () => {
    if (this.canNext()) {
      this.props.changePage(this.context.getAdjacentPageId(1));
    }
  };

  render() {
    return (
      <ThumbnailsSmallView
        canPrev={this.canPrev()}
        canNext={this.canNext()}
        activePageId={this.props.activePageId}
        count={this.props.count}
        toPrev={this.toPrev}
        toNext={this.toNext}
        locale={this.props.locale}
      />
    );
  }
}
