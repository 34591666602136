import React, { Component } from 'react';

import LayerSimplePaginationWrapper from './GhostLayerSimplePaginationWrapper';
import renderGhost from './renderGhost';

const Ghost = {
  LayerSimplePaginationWrapper,
  renderGhost,
};

export default class FreeGhost extends Component {
  render() {
    return (
      <Ghost.LayerSimplePaginationWrapper>
        {({
          frameSizes,
          workspace,
          scales,
          pdfPages,
          getPagesViewport,
        }) => {
          return Ghost.renderGhost({
            frameSizes,
            workspace,
            scales,
            pdfPages,
            getPagesViewport,
          });
        }}
      </Ghost.LayerSimplePaginationWrapper>
    );
  }
}
