import { Component } from 'react';
import PropTypes from 'prop-types';
import clamp from 'lodash/clamp';
import once from 'lodash/once';
import isEmpty from 'lodash/isEmpty';
import { defaultSidesOfDraggablePanel } from '../../helpers/const';

const WORKSPACE_VERTICAL_SCROLLBAR_WIDTH = 12;
const WORKSPACE_HEADER_PADDING = 50;
const PANEL_LEFT_PADDING = 20;
const PANEL_VERTICAL_OFFSET = 5;

export default class DraggablePanel extends Component {
  static propTypes = {
    workspace: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      top: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
    }),
    defaultWorkspace: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      top: PropTypes.number.isRequired,
      bottom: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
      right: PropTypes.number.isRequired,
    }),
    headerSize: PropTypes.number.isRequired,
    defaultSide: PropTypes.oneOf(
      Object.values(defaultSidesOfDraggablePanel),
    ),
    draggablePanelPosition: PropTypes.shape({
      left: PropTypes.number,
      top: PropTypes.number,
    }),
    onChangePosition: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
  };

  static defaultProps = {
    defaultSide: defaultSidesOfDraggablePanel.left,
    workspace: null,
    defaultWorkspace: null,
    draggablePanelPosition: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      // TODO
      // eslint-disable-next-line
      defaultPosition: {
        x: 0,
        y: 0,
      },
      left: 0,
      top: 0,
      // eslint-disable-next-line
      xLimits: 0,
      // eslint-disable-next-line
      yLimits: 0,
      isDragging: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    const { width, height } = this.props.workspace;
    const { width: prevWith, height: prevHeigh } = prevProps.workspace;

    if ((width !== prevWith) || (height !== prevHeigh)) {
      this.calcPosition();
    }
  }

  setPosition = once(() => {
    const savedPosition = this.props.draggablePanelPosition;
    if (isEmpty(savedPosition)) {
      this.setDefaultPosition();
    } else {
      this.calcPosition();
    }
  });

  getDraggablePanelSizes = () => {
    const { width, height } = this.ref.getBoundingClientRect();

    return {
      width: width + WORKSPACE_VERTICAL_SCROLLBAR_WIDTH,
      height,
    };
  };

  getTopLeft = () => {
    if (this.state.initialPos) {
      const { initialPos, finalPosition, top, left } = this.state;
      return {
        top: top + (finalPosition.clientY - initialPos.clientY),
        left: left + (finalPosition.clientX - initialPos.clientX),
      };
    }

    return {
      top: this.state.top,
      left: this.state.left,
    };
  }

  getDefaultPositionByLeftSide = () => {
    if (this.props.defaultWorkspace) {
      return {
        x: PANEL_LEFT_PADDING,
        y: this.props.defaultWorkspace.top + PANEL_VERTICAL_OFFSET,
      };
    }

    return {
      x: PANEL_LEFT_PADDING,
      y: this.props.headerSize + WORKSPACE_HEADER_PADDING,
    };
  };

  getDefaultPositionByRightSide = () => {
    const { width } = this.getDraggablePanelSizes();

    if (this.props.defaultWorkspace) {
      return {
        x: this.props.defaultWorkspace.right - width,
        y: this.props.defaultWorkspace.top + PANEL_VERTICAL_OFFSET,
      };
    }

    return {
      x: this.props.workspace.width - width - PANEL_LEFT_PADDING,
      y: this.props.headerSize + WORKSPACE_HEADER_PADDING,
    };
  };

  getDefaultPositionByBottomRightSide = () => {
    const { width, height } = this.getDraggablePanelSizes();

    return {
      x: this.props.defaultWorkspace.right - width,
      y: this.props.defaultWorkspace.bottom - height - PANEL_VERTICAL_OFFSET,
    };
  };

  setDefaultPosition = once(() => {
    const defaultPosition = this.getDefaultPositionBySide();

    this.setState({
      // eslint-disable-next-line
      defaultPosition,
      left: defaultPosition.x,
      top: defaultPosition.y,
    });
    this.props.onChangePosition({
      left: defaultPosition.x,
      top: defaultPosition.y,
    });
  });

  getDefaultPositionBySide = (side = this.props.defaultSide) => {
    return this.DEFAULT_POSITION_HANDLERS[side]();
  };

  DEFAULT_POSITION_HANDLERS = {
    [defaultSidesOfDraggablePanel.left]: this.getDefaultPositionByLeftSide,
    [defaultSidesOfDraggablePanel.right]: this.getDefaultPositionByRightSide,
    [defaultSidesOfDraggablePanel.bottomRight]: this.getDefaultPositionByBottomRightSide,
  };

  calcPosition = () => {
    const savedPosition = this.props.draggablePanelPosition;
    if (!isEmpty(savedPosition)) {
      const { width } = this.getDraggablePanelSizes();
      const { workspace, headerSize, onChangePosition } = this.props;
      const pos = {
        left: (savedPosition.left + width) > workspace.width
          ? workspace.width - width - WORKSPACE_VERTICAL_SCROLLBAR_WIDTH
          : savedPosition.left,
        top: (headerSize + WORKSPACE_HEADER_PADDING) > savedPosition.top
          ? headerSize + WORKSPACE_HEADER_PADDING
          : savedPosition.top,
      };
      this.setState(pos);
      onChangePosition(pos);
    }
  }

  storeRef = (ref) => {
    this.ref = ref;
    this.setPosition();
  };

  boundTopLeft = ({ top, left }) => {
    const { workspace } = this.props;
    const { size } = this.state;
    if (size) {
      return {
        top: clamp(top, workspace.top, workspace.height + (workspace.top - size.height)),
        left: clamp(left, workspace.left, (workspace.width + workspace.left) - size.width),
      };
    }

    return { top, left };
  };

  onStart = (event) => {
    const { clientX, clientY } = event;
    this.setState({
      size: this.getDraggablePanelSizes(),
      initialPos: { clientX, clientY },
      finalPosition: { clientX, clientY },
    });
  };

  onDrag = (event) => {
    const { clientX, clientY } = event;
    this.setState({
      finalPosition: { clientX, clientY },
      isDragging: true,
    });
  };

  onStop = () => {
    const { top, left } = this.boundTopLeft(
      this.getTopLeft(),
    );
    this.setState({
      top,
      left,
      initialPos: null,
      finalPosition: null,
      isDragging: false,
    });
    this.props.onChangePosition({
      left,
      top,
    });
  };

  render() {
    const { top, left } = this.boundTopLeft(
      this.getTopLeft(),
    );

    return this.props.children({
      top,
      left,
      isDragging: this.state.isDragging,
      onDragStart: this.onStart,
      onDragMove: this.onDrag,
      onDragStop: this.onStop,
      setDefaultPosition: this.setDefaultPosition,
      storeRef: this.storeRef,
    });
  }
}
