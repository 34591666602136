import PropTypes from 'prop-types';
import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import cls from 'classnames';

import {
  searchTermChangeTimeout,
} from '../../../helpers/const';

import * as Ui from '../../../ui';

export default class ToolbarSearchTextInput extends Component {
  static propTypes = {
    searchTerm: PropTypes.string.isRequired,
    isProcessingSearch: PropTypes.bool.isRequired,
    locale: PropTypes.shape({
      label: PropTypes.string,
    }).isRequired,

    setSearchTerm: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      text: props.searchTerm || '',
      isSpinnerRendered: props.isProcessingSearch,
    };
  }

  componentDidMount() {
    const { text } = this.state;
    if (this.inputNode && text) {
      this.inputNode.setSelectionRange(0, text.length);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.isProcessingSearch && nextProps.isProcessingSearch) {
      if (this.resetSpinnerTimeout) {
        clearTimeout(this.resetSpinnerTimeout);
      }

      this.setState({ isSpinnerRendered: true });
    }
    if (this.props.isProcessingSearch && !nextProps.isProcessingSearch) {
      this.resetSpinnerTimeout = setTimeout(this.resetIsSpinnerRendered, 300);
    }
  }

  setSearchTermDebounce = debounce(
    (text) => {
      this.props.setSearchTerm(text);
    },
    searchTermChangeTimeout,
  );

  resetIsSpinnerRendered = () => {
    this.setState({ isSpinnerRendered: false });
  }

  storeInputRef = (ref) => {
    this.inputNode = ref;
  };

  onChange = (event) => {
    this.setState({ text: event.target.value });
  };

  onResetSearchClick = () => {
    this.setState({ text: '' });
    this.props.setSearchTerm('');

    if (this.inputNode) {
      this.inputNode.focus();

      // ie/edge fix due to clearing with selected search text
      this.inputNode.setSelectionRange(0, 0);
    }
  };

  // Процедуру поиска запускаем только по keyUp,
  // чтобы не проходить когда зажали клавишу (backspace например)
  onKeyUp = (event) => {
    return this.setSearchTermDebounce(event.target.value);
  };

  renderSpinner = () => {
    return (
      <div className="custom-field__cell">
        <div
          className={
            cls(
              'progress',
              'progress--theme--primary',
              'progress--size--small',
              'progress--type--circle',
            )
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 50 50"
            className="progress__circle"
          >
            <circle cx="25" cy="25" r="20" fill="transparent" stroke="#ddd" strokeWidth="2" />
            <circle cx="25" cy="25" r="20" className="progress__circle-figure" />
          </svg>
        </div>
      </div>
    );
  };

  renderResetButton = () => {
    return (
      <div className="custom-field__cell">
        <Ui.Control
          theme={Ui.control.themes.transparent}
          size={Ui.control.sizes.parentHeight}
          offset={Ui.control.offsets.medium}
          icon={
            <Ui.Icon
              type={Ui.icon.types.close}
              size={Ui.icon.sizes.extraSmall}
            />
          }
          onClick={this.onResetSearchClick}
          inheritRadius
        />
      </div>
    );
  };

  render() {
    const { text, isSpinnerRendered } = this.state;

    return (
      <div className="custom-field custom-field--size--parent">

        <div className="custom-field__body">
          <div
            data-flex-grow="1"
            className="custom-field__cell"
          >
            <input
              ref={this.storeInputRef}
              className={cls(
                'input-field',
                'input-field--theme--default',
                'input-field--size--parent',
                'input-field--offset--medium-horizontal',
              )}
              placeholder={this.props.locale.label}
              value={text}
              onChange={this.onChange}
              onKeyUp={this.onKeyUp}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
          </div>

          {isSpinnerRendered && this.renderSpinner()}
          {!isSpinnerRendered && text && this.renderResetButton()}
        </div>
      </div>
    );
  }
}
