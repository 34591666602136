import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { thisDevice } from '@pdffiller/jsf-useragent';
import flatten from 'lodash/flatten';
import get from 'lodash/get';

import PopupWrapper from './Popup/PopupWrapper';
import FontFamilyToolbarSettings from '../../Tools/ToolbarSettings/Font/FontFamily';
import FontSizeToolbarSettings from '../../Tools/ToolbarSettings/FontSize/FontSize';
import {
  BToolbarSettings,
  IToolbarSettings,
  UToolbarSettings,
} from '../../Tools/ToolbarSettings/Style/Style';
import ColorToolbarSettings from '../../Tools/ToolbarSettings/Color/Color';
import LineWidthToolbarSettings from '../../Tools/ToolbarSettings/LineWidth/LineWidth';
import StampToolbarSettings from '../../Tools/ToolbarSettings/Stamp/Stamp';
import HrefToolbarSettings from '../../Tools/ToolbarSettings/Href/Href';
import AlignToolbarSettings from '../../Tools/ToolbarSettings/Alignments/Align';
import VAlignToolbarSettings from '../../Tools/ToolbarSettings/Alignments/VAlign';
import DirectionToolbarSettings from '../../Tools/ToolbarSettings/Direction/Direction';
import { directions } from '../../../helpers/graphicUtils';
import { colorKeys, toolbarAttributesContainerType } from '../../../helpers/const';
import * as toolbarUtils from './toolbarAttributesUtils';

import * as Ui from '../../../ui';
import Dropdown from '../../Dropdown/Dropdown';
import { popoverArrowPositions } from '../../../ui/Popover/Popover';
import { possiblePositions } from '../../Portal/helpers/const';

const POPOVER_WIDTH = 320;
export const INITIAL_BUTTONS_COUNT = -1;
const SMALL_DESKTOP_SCREEN_WIDTH = 1000;

export default class ToolbarAttributesView extends Component {
  static propTypes = {
    locale: PropTypes.shape({
      stamp: PropTypes.string.isRequired,
    }).isRequired,
    sigDateStampChangeable: PropTypes.bool.isRequired,
    handleToggleStampClick: PropTypes.func,

    isConstructor: PropTypes.bool.isRequired,
    hasStamp: PropTypes.bool,
    hasFont: PropTypes.bool,
    hasColor: PropTypes.bool,
    hasFontColor: PropTypes.bool,
    hasFillColor: PropTypes.bool,
    hasBackgroundColor: PropTypes.bool,
    hasBorderColor: PropTypes.bool,
    hasLineWidth: PropTypes.bool,
    hasArrowStyle: PropTypes.bool,
    hasRectDraw: PropTypes.bool,
    hasHref: PropTypes.bool,
    hasAlign: PropTypes.bool,
    hasVAlign: PropTypes.bool,

    toolbarAttributesWidth: PropTypes.number.isRequired,
    setActivePopupMenu: PropTypes.func.isRequired,

    isFillable: PropTypes.bool.isRequired,
    isSignature: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    handleToggleStampClick: () => {},

    hasStamp: false,
    hasFont: false,
    hasColor: false,
    hasFontColor: false,
    hasFillColor: false,
    hasBackgroundColor: false,
    hasBorderColor: false,
    hasLineWidth: false,
    hasArrowStyle: false,
    hasRectDraw: false,
    hasHref: false,
    hasAlign: false,
    hasVAlign: false,
  };

  constructor(props) {
    super(props);
    this.containerRef = null;

    this.state = {
      visibleToolsCount: INITIAL_BUTTONS_COUNT,
      popoverArrowPosition: popoverArrowPositions.topCenter,
      popoverPosition: possiblePositions.bottomCenterOuter,
    };
  }

  componentDidMount() {
    this.updateVisibleToolsCount(this.props.toolbarAttributesWidth);
    this.updatePopoverArrowPosition();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.toolbarAttributesWidth !== nextProps.toolbarAttributesWidth) {
      this.updateVisibleToolsCount(nextProps.toolbarAttributesWidth);
      this.updatePopoverArrowPosition();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.visibleToolsCount > this.state.visibleToolsCount) {
      this.updateVisibleToolsCount(this.props.toolbarAttributesWidth);
      this.updatePopoverArrowPosition();
    }
  }

  getViewportNode = () => {
    if (__CLIENT__) {
      return document.body;
    }
    return null;
  };

  getIsSmallDesktopScreenWidth = () => {
    return (
      thisDevice.isDesktop &&
      window.innerWidth < SMALL_DESKTOP_SCREEN_WIDTH
    );
  };

  getToolbarButtons = () => {
    const {
      hasFont,
      hasLineWidth,
      hasArrowStyle,
      hasStamp,
      hasRectDraw,
      sigDateStampChangeable,
      hasHref,
      hasAlign,
      isConstructor,
      isFillable,
      isSignature,
      hasVAlign,
    } = this.props;
    const isFillableSignature = isFillable && isSignature;

    // Вынесено из родительского рендера,
    // чтобы вычислить видимые элементы и отрендерить только их
    return flatten([
      hasStamp && sigDateStampChangeable && this.renderStampSettings(),
      hasFont && this.renderFontSettings(),
      hasArrowStyle && this.renderArrowStyleSettings(),
      hasRectDraw && this.renderBrushTypeSettings(),
      hasLineWidth && this.renderLineWidthSettings(),
      this.renderColorSettings(),
      hasAlign && this.renderAlignSettings(),
      hasVAlign && this.renderVAlignSettings(),
      (hasHref && !isFillableSignature && !isConstructor) &&
        this.renderHrefSettings(),
    ].filter((item) => {
      return item !== false;
    }));
  };

  storeContainerRef = (ref) => {
    this.containerRef = ref;
  };

  updateVisibleToolsCount = (toolbarAttributesWidth) => {
    if (this.getIsSmallDesktopScreenWidth()) {
      const { hasArrowStyle, hasFont } = this.props;
      const visibleToolsCount = toolbarUtils.getVisibleToolsCount(
        this.containerRef,
        toolbarAttributesWidth,
        hasArrowStyle,
        hasFont,
      );
      this.setState({ visibleToolsCount });
      return;
    }

    if (this.state.visibleToolsCount !== INITIAL_BUTTONS_COUNT) {
      this.setState({ visibleToolsCount: INITIAL_BUTTONS_COUNT });
    }
  };

  updatePopoverArrowPosition = () => {
    const viewportNode = this.getViewportNode();
    const anchorNode = this.containerRef;
    const popoverWidth = POPOVER_WIDTH;

    if (anchorNode === null || viewportNode === null) {
      return;
    }
    const { popoverArrowPosition, popoverPosition } = toolbarUtils.getPopoverArrowPosition(
      viewportNode,
      anchorNode,
      popoverWidth,
    );
    this.setState({
      popoverArrowPosition,
      popoverPosition,
    });
  };

  onMouseDown = () => {
    this.props.setActivePopupMenu();
    this.updatePopoverArrowPosition();
  };

  renderStampSettings = () => {
    return (
      <StampToolbarSettings
        key="stamp"
        toggleStamp={this.props.handleToggleStampClick}
        locale={this.props.locale}
      />
    );
  };

  renderFontSettings = () => {
    return [
      <FontFamilyToolbarSettings key="fontFamily" />,
      <FontSizeToolbarSettings key="fontSize" />,
      <BToolbarSettings key="bold" />,
      <IToolbarSettings key="italic" />,
      <UToolbarSettings key="underline" />,
    ];
  };

  renderArrowStyleSettings = () => {
    return [
      <DirectionToolbarSettings
        direction={directions.forward}
        key={directions.forward}
      />,
      <DirectionToolbarSettings
        direction={directions.backward}
        key={directions.backward}
      />,
      <DirectionToolbarSettings
        direction={directions.double}
        key={directions.double}
      />,
    ];
  };

  // Для активации настойки brushType необходимо:
  // 1. Реализовать метод renderBrushTypeSettings
  // 2. Реализовать компонент BrushToolbarSettings
  // 3. Доработать ghost
  renderBrushTypeSettings = () => {
    // *** example renderBrushTypeSettings implementation ***
    // return <BrushToolbarSettings key="burshType-brush" />;
  };

  renderLineWidthSettings = () => {
    return (
      <LineWidthToolbarSettings key="line-width" />
    );
  };

  renderAlignSettings = () => {
    return (
      <AlignToolbarSettings key="align" />
    );
  }

  renderVAlignSettings = () => {
    return (
      <VAlignToolbarSettings key="vAlign" />
    );
  }

  renderHrefSettings = () => {
    return (
      <HrefToolbarSettings key="href" />
    );
  }

  renderColorSettings = () => {
    const {
      hasFontColor, hasFillColor, hasColor,
      hasBackgroundColor, hasBorderColor,
    } = this.props;
    return [
      hasFontColor ? (
        <ColorToolbarSettings
          key="fontColor"
          colorKey={colorKeys.fontColor}
          hasIcon
          hasThinBottomLine
        />
      ) : false,
      hasFillColor ? (
        <ColorToolbarSettings
          key="fillColor"
          colorKey={colorKeys.fillColor}
        />
      ) : false,
      hasColor ? (
        <ColorToolbarSettings
          key="color"
          colorKey={colorKeys.color}
        />
      ) : false,
      hasBackgroundColor ? (
        <ColorToolbarSettings
          key="backgroundColor"
          colorKey={colorKeys.bgColor}
          hasIcon
          hasThinBottomLine
        />
      ) : false,
      hasBorderColor ? (
        <ColorToolbarSettings
          key="borderColor"
          colorKey={colorKeys.borderColor}
          hasIcon
          hasThinBottomLine
        />
      ) : false,
    ].filter((item) => {
      return item !== false;
    });
  };

  renderMoreIconButton = () => {
    const wrapperProps = {
      width: POPOVER_WIDTH,
      arrowPosition: this.state.popoverArrowPosition,
      locatorArgs: {
        position: this.state.popoverPosition,
      },
    };

    return (
      <Ui.ToolBar.Item
        key="add"
      >
        <Dropdown
          wrapperProps={wrapperProps}
          dropdownComponent={this.renderPopup}
        >
          {({ togglePortal, isPortalOpen, storeRef }) => {
            return (
              <Ui.Control
                icon={
                  <Ui.Icon
                    type={Ui.icon.types.dots}
                    size={Ui.icon.sizes.large}
                  />
                }
                storeRef={storeRef}
                onMouseDown={this.onMouseDown}
                onClick={togglePortal}
                isPressed={isPortalOpen}
                inheritRadius
              >
              </Ui.Control>
            );
          }}
        </Dropdown>
      </Ui.ToolBar.Item>
    );
  };

  renderPopup = ({ closePortal }) => {
    const { hasFont, hasArrowStyle } = this.props;
    const { visibleToolsCount } = this.state;

    const toolbarAttributesLength = get(this.getToolbarButtons(), 'length');
    const popupToolsCount = toolbarUtils.calculatePopupToolsCount(
      toolbarAttributesLength,
      visibleToolsCount,
      hasFont,
      hasArrowStyle,
    );

    return (
      <PopupWrapper
        popupToolsCount={popupToolsCount}
        closePortal={closePortal}
      />
    );
  };

  renderToolbar = () => {
    const { visibleToolsCount } = this.state;

    const toolbar = this.getToolbarButtons();

    const visibleToolbar = toolbarUtils.getVisibleTools(
      toolbar,
      visibleToolsCount,
      toolbarAttributesContainerType.toolbar,
    );

    //  В IE не показываем кнопку More и поповер, а отображаем тулбар полностью
    if (thisDevice.isInternetExplorer11) {
      return toolbar;
    }

    const moreIconButton = this.renderMoreIconButton();
    const needDrawMoreButton = visibleToolsCount > INITIAL_BUTTONS_COUNT;

    if (needDrawMoreButton) {
      visibleToolbar.push(moreIconButton);
    }

    return visibleToolbar;
  };

  render() {
    return (
      <Ui.ToolBar.Group storeRef={this.storeContainerRef}>
        {this.renderToolbar()}
      </Ui.ToolBar.Group>
    );
  }
}
