import PropTypes from 'prop-types';
import React from 'react';
import * as Ui from '../../ui';

const thumbnailsScrollWrapper = ({ storeThumbnailsRef, children }) => {
  return (
    <Ui.SideBar.Cell
      attributes={Ui.attributes.grow}
    >
      <Ui.ScrollBox
        storeRef={storeThumbnailsRef}
      >
        <Ui.FlexibleList.Body
          offset={Ui.flexibleList.offsets.small}
          size={Ui.flexibleList.sizes.medium}
        >
          {children}
        </Ui.FlexibleList.Body>
      </Ui.ScrollBox>
    </Ui.SideBar.Cell>
  );
};

thumbnailsScrollWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  storeThumbnailsRef: PropTypes.func.isRequired,
};

export default thumbnailsScrollWrapper;
