import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import * as Ui from '../../../../ui';
import { toolbar } from '../../../../store/selectors';
import { openHrefModal } from '../../../../store/modules/viewport';

export const hrefMapStateToProps = (state) => {
  const { isActiveElement } = toolbar.getElementPropsForToolbar(state);
  return {
    isDisabled: !isActiveElement,
  };
};

@connect(hrefMapStateToProps, { openHrefModal })
export default class HrefToolbarSettings extends Component {
  static propTypes = {
    isDisabled: PropTypes.bool.isRequired,
    openHrefModal: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Ui.Container.SettingButton
        icon={Ui.icon.types.link}
        size={Ui.icon.sizes.large}
        title={Ui.locales.titles.link}
        onClick={this.props.openHrefModal}
        isDisabled={this.props.isDisabled}
        inheritRadius
      />
    );
  }
}
