import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import * as JsfUi from 'jsfcore/ui';
import Ui from '../../ui';
import Toggler from './Toggler';
import Inputs from './Inputs';
import DatePickerContext from '../DatePickerContext';

const Time = ({
  isPM,
  is24Format,
  inputMinutes,
  inputHours,
  onChangeHours,
  onChangeMinutes,
  onFocusHours,
  onFocusMinutes,
  onBlurHours,
  onBlurMinutes,
  onToggleAMPM,
  onCurrentTimeClick,

  onPlusHours,
  onMinusHours,
  onPlusMinutes,
  onMinusMinutes,
}) => {
  const {
    needShowCurrentTimeButton,
    timeAmPmTheme,
  } = useContext(DatePickerContext);

  return (
    <Ui.Sidebar>
      <Ui.Sidebar.Row data-flex-grow="1">
        <Ui.Sidebar.Cell data-flex-grow="1">
          <JsfUi.Text
            size={14}
            theme={JsfUi.text.themes.darkgray}
            family={JsfUi.text.families.openSans}
            weight={JsfUi.text.weights.bold}
            offset={JsfUi.text.offsets.bottomExtraLarge}
          >
            Time
          </JsfUi.Text>
        </Ui.Sidebar.Cell>
      </Ui.Sidebar.Row>

      <Ui.Sidebar.Row>
        <Ui.Sidebar.Cell data-flex-grow="1">
          <JsfUi.Group.Index offset={JsfUi.group.offsets.small}>
            <JsfUi.Group.Body>
              <JsfUi.Group.Item>
                <JsfUi.Text
                  size={12}
                  theme={JsfUi.text.themes.dovegray}
                  family={JsfUi.text.families.openSans}
                  offset={JsfUi.text.offsets.bottomSmall}
                >
                  Hours
                </JsfUi.Text>
              </JsfUi.Group.Item>
              <JsfUi.Group.Item>
                <JsfUi.Text
                  size={12}
                  theme={JsfUi.text.themes.dovegray}
                  family={JsfUi.text.families.openSans}
                  offset={JsfUi.text.offsets.bottomSmall}
                >
                  Mins
                </JsfUi.Text>
              </JsfUi.Group.Item>
            </JsfUi.Group.Body>
          </JsfUi.Group.Index>
        </Ui.Sidebar.Cell>
      </Ui.Sidebar.Row>

      <Ui.Sidebar.Row>
        <Ui.Sidebar.Cell data-flex-grow="1">
          <JsfUi.Group.Index offset={JsfUi.group.offsets.small}>
            <JsfUi.Group.Body>
              <JsfUi.Group.Item>
                <JsfUi.Control
                  size={JsfUi.control.sizes.smallRectangular}
                  theme={JsfUi.control.themes.transparent}
                  onClick={onPlusHours}
                  icon={
                    <JsfUi.Icon
                      type={JsfUi.icon.types.shortArrowUp}
                    />
                  }
                />
              </JsfUi.Group.Item>
              <JsfUi.Group.Item>
                <JsfUi.Control
                  size={JsfUi.control.sizes.smallRectangular}
                  theme={JsfUi.control.themes.transparent}
                  onClick={onPlusMinutes}
                  icon={
                    <JsfUi.Icon
                      type={JsfUi.icon.types.shortArrowUp}
                    />
                  }
                />
              </JsfUi.Group.Item>
            </JsfUi.Group.Body>
          </JsfUi.Group.Index>
        </Ui.Sidebar.Cell>
      </Ui.Sidebar.Row>

      <Ui.Sidebar.Row>
        <Ui.Sidebar.Cell>
          <Inputs
            onChangeHours={onChangeHours}
            onChangeMinutes={onChangeMinutes}
            onFocusHours={onFocusHours}
            onFocusMinutes={onFocusMinutes}
            onBlurHours={onBlurHours}
            onBlurMinutes={onBlurMinutes}
            minutes={inputMinutes}
            hours={inputHours}
          />
        </Ui.Sidebar.Cell>
      </Ui.Sidebar.Row>

      <Ui.Sidebar.Row>
        <Ui.Sidebar.Cell data-flex-grow="1">
          <JsfUi.Group.Index offset={JsfUi.group.offsets.small}>
            <JsfUi.Group.Body>
              <JsfUi.Group.Item>
                <JsfUi.Control
                  size={JsfUi.control.sizes.smallRectangular}
                  theme={JsfUi.control.themes.transparent}
                  onClick={onMinusHours}
                  icon={
                    <JsfUi.Icon
                      type={JsfUi.icon.types.shortArrowDown}
                    />
                  }
                />
              </JsfUi.Group.Item>
              <JsfUi.Group.Item>
                <JsfUi.Control
                  size={JsfUi.control.sizes.smallRectangular}
                  theme={JsfUi.control.themes.transparent}
                  onClick={onMinusMinutes}
                  icon={
                    <JsfUi.Icon
                      type={JsfUi.icon.types.shortArrowDown}
                    />
                  }
                />
              </JsfUi.Group.Item>
            </JsfUi.Group.Body>
          </JsfUi.Group.Index>
        </Ui.Sidebar.Cell>
      </Ui.Sidebar.Row>

      {!is24Format && (
        <Ui.Sidebar.Row>
          <Ui.Sidebar.Cell>
            <Toggler
              timeAmPmTheme={timeAmPmTheme}
              isPM={isPM}
              onChange={onToggleAMPM}
            />
          </Ui.Sidebar.Cell>
        </Ui.Sidebar.Row>
      )}

      {needShowCurrentTimeButton && (
        <Ui.Sidebar.Row>
          <Ui.Sidebar.Cell attributes={JsfUi.attributes.grow}>
            <JsfUi.Group.Index
              type={JsfUi.group.types.compact}
              offset={JsfUi.group.offsets.largeVertical}
              justify={JsfUi.group.justifies.center}
            >
              <JsfUi.Group.Body>
                <JsfUi.Group.Item attributes={JsfUi.attributes.grow}>
                  <JsfUi.Control
                    size={JsfUi.control.sizes.smallStretched}
                    offset={JsfUi.control.offsets.extraSmall}
                    theme={JsfUi.control.themes.flatLightgray}
                    onClick={onCurrentTimeClick}
                  >
                    <JsfUi.Text
                      size={12}
                      weight={JsfUi.text.weights.bold}
                    >
                      Current time
                    </JsfUi.Text>
                  </JsfUi.Control>
                </JsfUi.Group.Item>
              </JsfUi.Group.Body>
            </JsfUi.Group.Index>
          </Ui.Sidebar.Cell>
        </Ui.Sidebar.Row>
      )}
    </Ui.Sidebar>
  );
};

Time.propTypes = {
  isPM: PropTypes.bool.isRequired,
  is24Format: PropTypes.bool.isRequired,
  inputMinutes: PropTypes.string.isRequired,
  inputHours: PropTypes.string.isRequired,
  onChangeHours: PropTypes.func.isRequired,
  onChangeMinutes: PropTypes.func.isRequired,
  onFocusHours: PropTypes.func.isRequired,
  onFocusMinutes: PropTypes.func.isRequired,
  onBlurHours: PropTypes.func.isRequired,
  onBlurMinutes: PropTypes.func.isRequired,
  onToggleAMPM: PropTypes.func.isRequired,
  onPlusHours: PropTypes.func.isRequired,
  onMinusHours: PropTypes.func.isRequired,
  onPlusMinutes: PropTypes.func.isRequired,
  onMinusMinutes: PropTypes.func.isRequired,
  onCurrentTimeClick: PropTypes.func.isRequired,
};

export default Time;
