import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { alignValues } from '../../../../helpers/const';
import setTextAlign from '../../../../store/thunks/setTextAlign';
import { toolbar } from '../../../../store/selectors';
import Dropdown from '../../../Dropdown/Dropdown';
import * as Ui from '../../../../ui';
import { ClosePortalFunctionsComposer } from '../../../Portal';

const icons = {
  [alignValues.left]: Ui.icon.types.textAlignLeft,
  [alignValues.center]: Ui.icon.types.textAlignCenter,
  [alignValues.right]: Ui.icon.types.textAlignRight,
};

const wrapperProps = {
  offset: Ui.control.offsets.small,
};

export class AlignToolbarSettingsSimple extends Component {
  static propTypes = {
    align: PropTypes.string,
    setAlign: PropTypes.func.isRequired,
  };

  static defaultProps = {
    align: alignValues.left,
  };

  getCurrentIcon() {
    return icons[this.props.align];
  }

  onClickLeft = () => {
    return this.props.setAlign(alignValues.left);
  }

  onClickCenter = () => {
    return this.props.setAlign(alignValues.center);
  }

  onClickRight = () => {
    return this.props.setAlign(alignValues.right);
  }

  renderPopup = ({ closePortal }) => {
    const { align } = this.props;
    return (
      <div className="text-alignment">
        <div className="text-alignment__body">
          <Ui.Group.Index
            size={Ui.group.sizes.parent}
            type={Ui.group.types.compact}
            offset={Ui.group.offsets.extraSmall}
          >
            <Ui.Group.Body>
              <Ui.Group.Item>
                <ClosePortalFunctionsComposer
                  closePortal={closePortal}
                  func={this.onClickLeft}
                >
                  {({ composedFunc }) => {
                    return (
                      <Ui.Control
                        onClick={composedFunc}
                        isChecked={align === alignValues.left}
                        theme={Ui.control.themes.primaryHighlighted}
                        size={Ui.control.sizes.parentHeight}
                        offset={Ui.control.offsets.extraSmall}
                        behavior={Ui.control.behaviors.column}
                        icon={<Ui.Icon
                          type={Ui.icon.types.textAlignLeft}
                          size={Ui.icon.sizes.large}
                        />}
                        inheritRadius
                      />
                    );
                  }}
                </ClosePortalFunctionsComposer>
              </Ui.Group.Item>
              <Ui.Group.Item>
                <ClosePortalFunctionsComposer
                  closePortal={closePortal}
                  func={this.onClickCenter}
                >
                  {({ composedFunc }) => {
                    return (
                      <Ui.Control
                        onClick={composedFunc}
                        isChecked={align === alignValues.center}
                        theme={Ui.control.themes.primaryHighlighted}
                        size={Ui.control.sizes.parentHeight}
                        offset={Ui.control.offsets.extraSmall}
                        behavior={Ui.control.behaviors.column}
                        icon={<Ui.Icon
                          type={Ui.icon.types.textAlignCenter}
                          size={Ui.icon.sizes.large}
                        />}
                        inheritRadius
                      />
                    );
                  }}
                </ClosePortalFunctionsComposer>
              </Ui.Group.Item>
              <Ui.Group.Item>
                <ClosePortalFunctionsComposer
                  closePortal={closePortal}
                  func={this.onClickRight}
                >
                  {({ composedFunc }) => {
                    return (
                      <Ui.Control
                        onClick={composedFunc}
                        isChecked={align === alignValues.right}
                        theme={Ui.control.themes.primaryHighlighted}
                        size={Ui.control.sizes.parentHeight}
                        offset={Ui.control.offsets.extraSmall}
                        behavior={Ui.control.behaviors.column}
                        icon={<Ui.Icon
                          type={Ui.icon.types.textAlignRight}
                          size={Ui.icon.sizes.large}
                        />}
                        inheritRadius
                      />
                    );
                  }}
                </ClosePortalFunctionsComposer>
              </Ui.Group.Item>
            </Ui.Group.Body>
          </Ui.Group.Index>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Dropdown
        dropdownComponent={this.renderPopup}
        wrapperProps={wrapperProps}
        needStopPropagation={false}
      >
        {({ togglePortal, isPortalOpen, storeRef }) => {
          return (
            <Ui.ToolBar.Item>
              <Ui.Container.SettingButton
                icon={this.getCurrentIcon()}
                size={Ui.icon.sizes.large}
                type={Ui.control.types.dropdown}
                title={Ui.locales.titles.hAlign}
                isPressed={isPortalOpen}
                storeRef={storeRef}
                onClick={togglePortal}
                inheritRadius
              />
            </Ui.ToolBar.Item>
          );
        }}
      </Dropdown>
    );
  }
}

export default connect((state) => {
  const { align } = toolbar.getElementPropsForToolbar(state);
  return { align };
}, {
  setAlign: setTextAlign,
})(AlignToolbarSettingsSimple);
