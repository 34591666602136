import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Ui from '../../../../ui';
import { toolbar } from '../../../../store/selectors';
import { toggleStamp } from '../../../../store/thunks';
import { stopEvent } from '../../../../helpers/utils';

@connect(
  (state) => {
    const { dateStamp } = toolbar.getElementPropsForToolbar(state);

    return {
      isStampShown: Boolean(dateStamp),
    };
  },
  {
    toggleStamp,
  },
)
export default class StampToolbarSettings extends Component {
  static propTypes = {
    isStampShown: PropTypes.bool.isRequired,
    locale: PropTypes.shape({
      stamp: PropTypes.string.isRequired,
    }).isRequired,

    // actions
    toggleStamp: PropTypes.func.isRequired,
  };

  onClick = (event) => {
    stopEvent(event);
    this.props.toggleStamp();
  }

  render() {
    return (
      <Ui.Container.SettingButton
        icon={Ui.icon.types.stamp}
        label={this.props.locale.stamp}
        behavior={Ui.control.behaviors.rowRecursive}
        offset={Ui.control.offsets.medium}
        isPressed={this.props.isStampShown}
        size={Ui.icon.sizes.small}
        textSize={14}
        textWeight={Ui.text.weights.null}
        title={Ui.locales.titles.stamp}
        onClick={this.onClick}
        inheritRadius
      />
    );
  }
}
