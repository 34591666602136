import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import GhostMouseListener from './GhostMouseListener';
import ConnectedGhostDataProvider from './GhostDataProvider';
import GhostLimiter from './GhostLimiter';
import GhostVisibilityProvider from './GhostVisibilityProvider';
import GhostElementCreator from './GhostElementCreator';
import GhostKeyboardCreateTrigger from './GhostKeyboardCreateTrigger';
import GhostCreateTrigger from './GhostCreateTrigger';
import GhostRenderer from './GhostRenderer';
import ElementGhost from './ElementGhost';
import { haveDataForRenderGhost } from './ghostUtils';
import MagneticLinesProvider from './MagneticLines/MagneticLinesProvider';
import MagneticLinesLimiter from './MagneticLines/MagneticLinesLimiter';
import MagneticLinesView from '../MagneticLines/MagneticLinesView';
import ImmediateFrameOffsetProvider from '../ScrollContextProvider/ImmediateFrameOffsetProvider';
import ConnectedDateMinWidthProvider from '../DateMinWidth/DateMinWidthProvider';
import config from '../../helpers/clientConfig';

const renderGhost = ({ frameSizes, workspace, scales, pdfPages, getPagesViewport }) => {
  return (
    <GhostMouseListener
      key="ghost"
    >
      {({
        ghostPageId,
        hideGhost,
        isMouseOverPage,
        isMouseOverElement,
        isMouseOverClickBlocker,
        isMouseOverVisibleBox,
        isMouseOverComment,
        isMouseOverFakeEdit,
        mousePosition,
      }) => {
        return (
          <ImmediateFrameOffsetProvider
            pageId={ghostPageId}
            isActiveElement
            provideFrameOffsetWithoutScroll
          >
            {({ frameOffset, frameOffsetWithoutScroll }) => {
              return (
                <ConnectedGhostDataProvider
                  frameSizes={frameSizes}
                  frameOffset={frameOffset}
                  frameOffsetWithoutScroll={frameOffsetWithoutScroll}
                  workspace={workspace}
                  scales={scales}
                  ghostPageId={ghostPageId}
                  mousePosition={mousePosition}
                >
                  {({
                    isConstructorMode,
                    scale,
                    frameSize,
                    ghostElementForRender,
                    getScaledGhostPositionForCreating,
                    getScaledGhostPositionForRender,
                  }) => {
                    if (haveDataForRenderGhost({
                      ghostElementForRender, pdfPages, pageId: ghostPageId,
                    })) {
                      return (
                        <MagneticLinesProvider
                          isMouseOverPage={isMouseOverPage}
                          scale={scale}
                          workspace={workspace}
                          frameOffset={frameOffset}
                          ghost={ghostElementForRender}
                          getScaledGhostPosition={getScaledGhostPositionForCreating}
                          getScaledGhostPositionForRender={getScaledGhostPositionForRender}
                          frameScroll={frameOffset}
                        >
                          {({
                            magneticLines,
                            getMagneticPositionForRender,
                            getMagneticPositionForCreating,
                          }) => {
                            return (
                              <GhostVisibilityProvider
                                isMouseOverElement={isMouseOverElement}
                                isMouseOverClickBlocker={isMouseOverClickBlocker}
                                isMouseOverPage={isMouseOverPage}
                                isMouseOverVisibleBox={isMouseOverVisibleBox}
                                isMouseOverComment={isMouseOverComment}
                                isMouseOverFakeEdit={isMouseOverFakeEdit}
                              >
                                {({ isGhostHidden, isGhostHiddenByActiveElement }) => {
                                  return (
                                    <div>
                                      {
                                        config.app.ghostMagneticLinesOn &&
                                        !isGhostHidden &&
                                        magneticLines.length > 0 &&
                                          <MagneticLinesLimiter
                                            magneticLines={magneticLines}
                                            workspace={workspace}
                                          >
                                            {({ limitedMagneticLines }) => {
                                              return (
                                                <MagneticLinesView
                                                  magneticLines={limitedMagneticLines}
                                                />
                                              );
                                            }}
                                          </MagneticLinesLimiter>
                                      }
                                      <GhostLimiter
                                        frameSize={frameSize}
                                        getScaledGhostPosition={getMagneticPositionForCreating}
                                        ghostElementForRender={ghostElementForRender}
                                        scale={scale}
                                      >
                                        {({ getLimitedScaledGhostPosition }) => {
                                          return (
                                            <div>
                                              <GhostElementCreator
                                                getGhostPosition={getLimitedScaledGhostPosition}
                                                scale={scale}
                                                ghostPageId={ghostPageId}
                                                hideGhost={hideGhost}
                                                isMouseOverElement={isMouseOverElement}
                                                isMouseOverClickBlocker={isMouseOverClickBlocker}
                                                isMouseOverFakeEdit={isMouseOverFakeEdit}
                                                isMouseOverPage={isMouseOverPage}
                                              >
                                                {({ createElement, createElementByKeyboard }) => {
                                                  return (
                                                    <Fragment>
                                                      <GhostKeyboardCreateTrigger
                                                        isGhostHiddenByActiveElement={
                                                          isGhostHiddenByActiveElement
                                                        }
                                                        ghostPageId={ghostPageId}
                                                        createElementByKeyboard={
                                                          createElementByKeyboard
                                                        }
                                                      />
                                                      <GhostCreateTrigger
                                                        createElement={createElement}
                                                      />
                                                    </Fragment>
                                                  );
                                                }}
                                              </GhostElementCreator>
                                              <GhostRenderer
                                                frameOffset={frameOffset}
                                                getPagesViewport={getPagesViewport}
                                                workspace={workspace}
                                                scale={scale}
                                                ghostElementForRender={ghostElementForRender}
                                                ghostPageId={ghostPageId}
                                                getGhostPosition={getMagneticPositionForRender}
                                                isGhostHidden={isGhostHidden}
                                              >
                                                <ElementGhost
                                                  key={
                                                    ghostElementForRender
                                                      ? ghostElementForRender.id
                                                      : 0
                                                  }
                                                  isConstructorMode={isConstructorMode}
                                                  ghost={ghostElementForRender}
                                                  scale={scale}
                                                />
                                              </GhostRenderer>
                                              <ConnectedDateMinWidthProvider
                                                element={ghostElementForRender}
                                                isGhost
                                              />
                                            </div>
                                          );
                                        }}
                                      </GhostLimiter>
                                    </div>
                                  );
                                }}
                              </GhostVisibilityProvider>
                            );
                          }}
                        </MagneticLinesProvider>
                      );
                    }

                    return null;
                  }}
                </ConnectedGhostDataProvider>
              );
            }}
          </ImmediateFrameOffsetProvider>
        );
      }}
    </GhostMouseListener>
  );
};

renderGhost.propTypes = {
  frameSizes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        height: PropTypes.number.isRequired,
      }),
      PropTypes.bool,
    ]).isRequired,
  ).isRequired,
  workspace: PropTypes.shape({
    height: PropTypes.number.isRequired,
    framePadding: PropTypes.shape({
      top: PropTypes.number.isRequired,
      bottom: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
      right: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  scales: PropTypes.arrayOf(
    PropTypes.number,
  ).isRequired,
  pdfPages: PropTypes.arrayOf(
    PropTypes.number,
  ).isRequired,
  getPagesViewport: PropTypes.func.isRequired,
};

export default renderGhost;
