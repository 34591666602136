import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';
import classnames from 'classnames';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { selectors, thunks } from '../..';

import Hammer, { inputType } from '../../helpers/Hammer';
import { toolsDecorator } from '../../decorators';
import PaginationLabel from './PaginationLabel/PaginationLabel';
import {
  changePageAnimationTime, paginationAnimation,
  paginationPanDistance, changePageDirection,
  scrollChangeTimer,
  minimumDeltaY,
  minimumDeltaYFF,
} from '../../helpers/const';
import { hasClass, getEventPath } from '../../helpers/utils';
import {
  setActivePage,
  setCanGoNextPage,
  setCanGoPrevPage,
  getCanGo,
} from '../../store/modules/navigation';
import { setPagePanning, setPageChanging, onOkClicked } from '../../store/modules/events';

@toolsDecorator({ main: true })
@connect(
  (state) => {
    const activePageId = selectors.base.getActivePageId(state);
    const activeElementId = selectors.base.getActiveElementId(state);

    return ({
      activePageId,
      count: state.pdf.count,
      workspace: state.viewport.workspace,
      nextActive: selectors.base.getNextPageId(state),
      canGoNextPage: state.navigation.canGoNextPage[activePageId],
      canGoPrevPage: state.navigation.canGoPrevPage[activePageId],
      pagePanning: state.events.pagePanning,
      pageChanging: state.events.pageChanging,
      pagePinching: state.events.pagePinching,
      pageScaling: state.events.pageScaling,
      dragElementId: state.events.dragElementId,
      isActiveElement: !!activeElementId,
      isDrawingNewGraphicElement: state.events.isDrawingNewGraphicElement,
      stretchingElementId: state.events.stretchingElementId,
      isScrollDisabled: !!(
        state.events.pagePanning || state.events.pagePinching || state.events.pageScaling ||
        state.events.pageChanging || state.events.dragElementId !== false ||
        state.events.isDrawingNewGraphicElement || state.events.stretchingElementId ||
        state.events.isScrollDisabled || false
      ),
      appStarted: state.events.appStarted,
      frameSizes: selectors.getFrameSizes(state),

      // TODO: rename prop to locale
      paginationLabel: selectors.locale.getEditorLocale(state).paginationLabel,
    });
  }, {
    changePage: thunks.changePage,
    setActivePage,
    setPagePanning,
    setPageChanging,
    setCanGoNextPage,
    setCanGoPrevPage,
    onOkClicked,
  },
)
export default class Pagination extends Component {
  static propTypes = {
    children: PropTypes.arrayOf(PropTypes.element).isRequired,

    // Added by toolsDecorator
    triggerActivePageChanged: PropTypes.func.isRequired,

    // from global.state
    workspace: PropTypes.shape({
      height: PropTypes.number.isRequired,
      framePadding: PropTypes.shape({
        top: PropTypes.number.isRequired,
        bottom: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    count: PropTypes.number.isRequired,
    activePageId: PropTypes.number.isRequired,
    nextActive: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
    ]).isRequired,
    canGoNextPage: PropTypes.bool,
    canGoPrevPage: PropTypes.bool,
    pagePanning: PropTypes.bool.isRequired,
    pagePinching: PropTypes.bool.isRequired,
    pageChanging: PropTypes.bool.isRequired,
    pageScaling: PropTypes.bool.isRequired,
    dragElementId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]).isRequired,
    isActiveElement: PropTypes.bool.isRequired,
    isDrawingNewGraphicElement: PropTypes.bool.isRequired,
    stretchingElementId: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]).isRequired,
    paginationLabel: PropTypes.shape({
      template: PropTypes.string.isRequired,
    }).isRequired,
    appStarted: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    frameSizes: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          height: PropTypes.number.isRequired,
        }),
        PropTypes.bool,
      ]).isRequired,
    ).isRequired,

    // actions
    changePage: PropTypes.func.isRequired,
    setActivePage: PropTypes.func.isRequired,
    setPagePanning: PropTypes.func.isRequired,
    setPageChanging: PropTypes.func.isRequired,
    isScrollDisabled: PropTypes.bool.isRequired,
    setCanGoNextPage: PropTypes.func.isRequired,
    setCanGoPrevPage: PropTypes.func.isRequired,
    onOkClicked: PropTypes.func.isRequired,
  };

  static defaultProps = {
    canGoNextPage: false,
    canGoPrevPage: false,
  };

  static contextTypes = {
    getAdjacentPageId: PropTypes.func,
    getIndexByPageId: PropTypes.func,
    getPageIdByIndex: PropTypes.func,
    subscribeToScroll: PropTypes.func,
    unsubscribeToScroll: PropTypes.func,
    onScroll: PropTypes.func,
  };

  static childContextTypes = {
    getWorkspaceRef: PropTypes.func.isRequired,
    paginationHelpers: PropTypes.shape({
      updateCanGoImmediately: PropTypes.func.isRequired,
      updateCanGoWithTimeout: PropTypes.func.isRequired,
      onPageChange: PropTypes.func.isRequired,
      setOkIfNeed: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.wheelTimestamp = 0;
    this.deltaY = 0;
    this.value = 0;
    this.panDirections = false;
    this.pagination = false;
    this.panStarted = false;
    this.scrollerMargin = 0;
    this.scrolls = {};
    this.can = {};
    this.canGoNextPageTimer = null;
    this.canGoPrevPageTimer = null;
    this.scrollListener = null;
  }

  getChildContext = () => {
    return {
      getWorkspaceRef: this.getRoot,
      paginationHelpers: {
        updateCanGoImmediately: this.updateCanGoImmediately,
        updateCanGoWithTimeout: this.updateCanGoWithTimeout,
        onPageChange: this.onPageChange,
        setOkIfNeed: this.setOkIfNeed,
      },
    };
  };

  componentDidMount() {
    const { workspace, activePageId } = this.props;
    if (workspace && workspace.height) {
      this.setPageMargin(workspace.height, activePageId, 0, false);
    }

    this.delegateEvents();
    this.context.subscribeToScroll(this.scrollUpdated);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { activePageId, nextActive, workspace, canGoNextPage, canGoPrevPage } = nextProps;
    const { workspace: oldWorkspace } = this.props;

    if (this.props.canGoNextPage !== canGoNextPage || this.props.canGoPrevPage !== canGoPrevPage) {
      this.can = this.getCanGo(nextProps);
    }

    if (activePageId !== this.props.activePageId) {
      this.onPageChanged(activePageId, nextProps);
    }

    if (nextActive !== this.props.nextActive) {
      this.onNextPageChanged(nextActive, nextProps);
    }

    if (
      workspace && (
        (oldWorkspace && oldWorkspace.height !== workspace.height) ||
        (!oldWorkspace && workspace.height)
      )
    ) {
      const nextActivePageId =
        nextActive === false
          ? activePageId
          : nextActive;

      this.setPageMargin(workspace.height, nextActivePageId, 0, false);
    }
  }

  componentWillUnmount() {
    this.context.unsubscribeToScroll(this.scrollUpdated);
    this.undelegateEvents();
  }

  onNextPageChanged = (activePageId) => {
    const { workspace: { height } } = this.props;
    this.props.setPageChanging(true);
    this.setPageMargin(height, activePageId);

    clearTimeout(this.changePageAnimationTimer);
    this.changePageAnimation = () => {
      this.changePageAnimationTimer = null;
      this.changePageAnimation = null;
      this.changePageAnimationTime = null;
      this.pagination = false;
      this.panDirections = false;
      this.props.setActivePage(activePageId);
      this.props.setPageChanging(false);
    };
    this.changePageAnimationTime = new Date();
    this.changePageAnimationTimer = setTimeout(
      this.changePageAnimation,
      changePageAnimationTime);
  };

  onPageChanged = (activePageId, nextProps) => {
    const { workspace: { height } } = this.props;
    this.props.triggerActivePageChanged(); // TODO: ???
    this.setPageMargin(height, activePageId);
    this.can = { up: nextProps.canGoNextPage, down: nextProps.canGoPrevPage };
  };

  getChangePageDirection = ({ deltaY, deltaX }) => {
    // Если свайпа по X было в 2 раза больше чем по Y, то свайп считаем горизонтальным,
    // страницу не перелистываем. Если вышло так, что deltaY = 0 - то тоже никуда не листаем
    if (
      Math.abs(deltaX) > Math.abs(deltaY * 2) ||
      deltaY === 0
    ) {
      return changePageDirection.none;
    }

    return deltaY > 0
      ? changePageDirection.prev
      : changePageDirection.next;
  };

  getRoot = () => {
    return this.paginationRef;
  };

  getScrollerNode = () => {
    return this.paginationScrollerRef;
  };

  setPageMargin = (height, activePageId, margin = 0, animation = true) => {
    const node = this.getScrollerNode();
    this.scrollerMargin = -this.context.getIndexByPageId(activePageId) * height;
    this.scrollerMargin += margin;
    if (this.setPageMarginRequest && !animation) {
      return;
    }
    const setPageMarginFunction = () => {
      this.setPageMarginRequest = null;
      const animationDuration = animation
        ? changePageAnimationTime
        : 0;

      node.style.transition = `${animationDuration}ms ${paginationAnimation}`;
      node.style.transform = `translate3d(0, ${this.scrollerMargin}px, 0)`;
    };
    if (animation) {
      this.setPageMarginRequest = requestAnimationFrame(setPageMarginFunction);
    } else {
      setPageMarginFunction();
    }
  };

  getScroll = (activePageId = this.props.activePageId) => {
    return this.scrolls[activePageId] || { scrollTop: 0, scrollLeft: 0 };
  };

  getCanGo = (props = this.props) => {
    const { scrollTop } = this.getScroll();
    const { frameSizes, workspace, activePageId } = props;

    const {
      next,
      prev,
    } = getCanGo({
      frameSizes,
      workspace,
      pageId: activePageId,
      scrollTop,
    });

    return {
      up: next,
      down: prev,
    };
  };

  setOkIfNeed = () => {
    const { isActiveElement } = this.props;
    if (isActiveElement) {
      this.props.onOkClicked();
    }
  };

  delegateEvents() {
    if (!Hammer) {
      return false;
    }
    const node = this.getRoot();
    const pan = new Hammer.Pan({
      direction: Hammer.DIRECTION_ALL,
      threshold: 5,
    });
    const hammer = new Hammer.Manager(node, {
      touchAction: 'auto',
      inputClass: inputType(),
    });
    hammer.add(pan);
    if (thisDevice.isMobile) {
      hammer.on('panstart', this.onPanStart);
      hammer.on('panup', this.onPanUp);
      hammer.on('pandown', this.onPanDown);
      hammer.on('panend', this.onPanEnd);

      // on Android - only on real device (for browserStack - it's not happening),
      // on IOS for date standard control,
      // when we're setting element on the bottom on a page, phones
      // scrolling a page and sometime more then need (page is cropping)
      if (thisDevice.isMobile) {
        this.scrollListener = node.addEventListener('scroll', () => {
          if (node.scrollTop !== 0) {
            node.scrollTop = 0;
          }
        });
      }
    } else {
      node.addEventListener('wheel', throttle(this.onWheel, 200));
    }

    if (thisDevice.isSurface) {
      node.addEventListener('pointermove', throttle(this.onWheel, 200));
    }

    if (thisDevice.isDesktop) {
      this.scrollListener = node.addEventListener('scroll', () => {
        // https://pdffiller.atlassian.net/browse/JSF-2959
        // Иногда происходит shit, когда на загрузке происходит фокус на какой-то элемент
        // Браузер, если не видит во viewport нашего элемента, пытается выполнить
        // скролл node, который, несмотря на наличие overflow: hidden, скроллится
        // есть еще какие-то кейсы, когда может съехать, но с этим фиксом больше не репродюсятся
        if (node.scrollTop !== 0) {
          node.scrollTop = 0;
        }
      });
    }

    return true;
  }

  undelegateEvents() {
    const node = this.getRoot();
    if (node && this.scrollListener) {
      node.removeEventListener('scroll', this.scrollListener);
    }
  }

  storePaginationRef = (ref) => {
    this.paginationRef = ref;
  }

  storePaginationScrollerRef = (ref) => {
    this.paginationScrollerRef = ref;
  }

  updateCanGoImmediately = (scroll) => {
    // Here we get information about available directions for pagination
    const can = this.getCanGo();
    const { canGoNextPage, canGoPrevPage, activePageId } = this.props;

    // If directions available for pagination different from the one, that is
    // stored in redux state - we need to update their.
    if (can.up !== canGoNextPage) {
      this.props.setCanGoNextPage(can.up, activePageId);
    }
    if (can.down !== canGoPrevPage) {
      this.props.setCanGoPrevPage(can.down, activePageId);
    }

    // this only for mobile, for correct page changing
    if (thisDevice.isMobile) {
      this.context.onScroll({ scroll, pageId: activePageId });
    }
  };

  updateCanGoWithTimeout = () => {
    // Here we get information about available directions for pagination
    const can = this.getCanGo();
    const { canGoNextPage, canGoPrevPage, activePageId } = this.props;

    // If directions available for pagination different from the one, that is
    // stored in redux state - we need to update their. When pagination direction
    // become available - we send data to redux with delay to prevent pagination
    // by continuous scroll. When pagination direction become unavailable -
    // we send this to redux right now.
    if (can.up !== canGoNextPage) {
      if (can.up) {
        if (!this.canGoNextPageTimer) {
          this.canGoNextPageTimer = setTimeout(() => {
            this.canGoNextPageTimer = null;
            this.props.setCanGoNextPage(can.up, activePageId);
          }, scrollChangeTimer);
        }

        // NOTE: для чего здесь таймаут:
        // Если код выполняется синхронно, то выстреливает вечная цепочка
        // onChangeScroll-updateCanGoWithTimeout-forceScroll-onChangeScroll-...
        // setFrameScroll ни на что не влияет (новый пропсы тупо не успевают прилететь,
        // т.к. код синхронный), и ловим падение приложки. Для того, чтобы такую ситуацию
        // предотвратить, вызываем forceScroll _асинхронно_.

        // ЗАКОМЕНТИРУЕМ ТАК КАК НЕ ПОНИМАЕМ ЗАЧЕМ ЭТО

        // UPD: Некоторые формы при скроле вниз или вверх не досткраливались
        // донизу, поэтому мы скролили вниз принудительно.
        // TODO: найти формы, вернуть фикс, задокументировать

        // setTimeout(() => this.forceScroll({
        //   scrollTop: this.props.frameSizes[activePageId].height +
        //     workspace.framePadding.top + workspace.framePadding.bottom -
        //     workspace.height,
        // }), 0);
      } else {
        this.canGoNextPageTimer = clearTimeout(this.canGoNextPageTimer);
        this.props.setCanGoNextPage(can.up, activePageId);
      }
    }

    if (can.down !== canGoPrevPage) {
      if (can.down) {
        if (!this.canGoPrevPageTimer) {
          this.canGoPrevPageTimer = setTimeout(() => {
            this.canGoPrevPageTimer = null;
            this.props.setCanGoPrevPage(can.down, activePageId);
          }, scrollChangeTimer);
        }
      } else {
        this.canGoPrevPageTimer = clearTimeout(this.canGoPrevPageTimer);
        this.props.setCanGoPrevPage(can.down, activePageId);
      }
    }
  };

  scrollUpdated = (scroll, pageId) => {
    this.scrolls[pageId] = scroll;
    this.can = this.getCanGo();
  };

  isFirstPage = () => {
    return this.context.getIndexByPageId(this.props.activePageId) === 0;
  };

  isLastPage = () => {
    return this.context.getIndexByPageId(this.props.activePageId) === this.props.count - 1;
  };

  changePage = (nextPage, prevPage) => {
    const { dragElementId, isDrawingNewGraphicElement, stretchingElementId } = this.props;

    // don't change page while doing something
    if (dragElementId || isDrawingNewGraphicElement || stretchingElementId) {
      return;
    }

    const activePageId = nextPage < 0
      ? 0
      : nextPage;

    this.pagination = true;
    this.props.changePage(activePageId, prevPage);
  };

  // https://pdffiller.atlassian.net/browse/JSF-5349
  // Removed pan disabling when activeElement exists for page swiping
  isPanAvailable = () => {
    const {
      pagePinching, pageScaling, dragElementId,
      isDrawingNewGraphicElement, stretchingElementId,
    } = this.props;
    if (
      pagePinching || pageScaling ||
      dragElementId !== false ||
      isDrawingNewGraphicElement || stretchingElementId !== false
    ) {
      return false;
    }

    return true;
  };

  isValidDistance = (distance) => {
    return distance > paginationPanDistance;
  };

  onPanStart = () => {
    if (!this.isPanAvailable()) {
      return false;
    }

    this.panStarted = true;
    this.panDirections = this.can;

    return true;
  };

  onPanUp = (event) => {
    if (this.can.up) {
      event.preventDefault();
    }

    if (this.panDirections.up && !this.can.up) {
      this.panStarted = false;
    }

    if (
      this.pagination ||
      !this.panStarted ||
      !this.isPanAvailable() ||
      event.srcEvent.type === 'mouseup'
    ) {
      return false;
    }

    const { deltaY } = event;
    if (this.panDirections.down && !this.panDirections.up && deltaY < 0) {
      return false;
    }

    if (this.panDirections.up || this.panDirections.down) {
      this.onPanPage({ deltaY });
    }

    return true;
  };

  onPanDown = (event) => {
    if (this.can.down) {
      event.preventDefault();
    }

    if (this.panDirections.down && !this.can.down) {
      this.panStarted = false;
    }

    if (
      this.pagination ||
      !this.panStarted ||
      !this.isPanAvailable() ||
      event.srcEvent.type === 'mouseup'
    ) {
      return false;
    }

    const { deltaY } = event;
    if (this.panDirections.up && !this.panDirections.down && deltaY > 0) {
      return false;
    }

    if (this.panDirections.up || this.panDirections.down) {
      this.onPanPage({ deltaY });
    }

    return true;
  };

  onPanEnd = (event) => {
    if (
      !this.panStarted ||
      !this.isPanAvailable() ||
      event.srcEvent.type === 'mouseup'
    ) {
      return false;
    }

    this.onPanPageEnd({
      distance: event.distance,
      direction: this.getChangePageDirection(event),
    });

    return true;
  };

  onPanPage = ({ deltaY }) => {
    const { workspace: { height }, activePageId, pagePanning } = this.props;
    this.setPageMargin(height, activePageId, deltaY, false);
    if (!pagePanning) {
      this.props.setPagePanning(true);
    }
  };

  onPanPageEnd = ({ distance, direction }) => {
    const { workspace: { height }, pagePanning, nextActive, activePageId } = this.props;
    const currentPageId = this.pagination
      ? nextActive
      : activePageId;

    if (this.panDirections.up || this.panDirections.down) {
      if (this.isValidDistance(distance) && direction !== changePageDirection.none) {
        if (
          direction === changePageDirection.next &&
          this.panDirections.up && !this.isLastPage()
        ) {
          this.changePage(this.context.getAdjacentPageId(1, currentPageId), currentPageId);
        } else if (
          direction === changePageDirection.prev &&
          this.panDirections.down && !this.isFirstPage()
        ) {
          this.changePage(this.context.getAdjacentPageId(-1, currentPageId), currentPageId);
        } else {
          this.setPageMargin(height, currentPageId);
        }
      } else {
        this.setPageMargin(height, currentPageId);
      }
    }

    this.panStarted = false;
    if (pagePanning) {
      this.props.setPagePanning(false);
    }
  };

  onWheel = (event) => {
    // Если eventPath содержит node с классом .Select-menu - завершаем
    if (
      getEventPath(event).findIndex((node) => {
        return hasClass(node, 'Select-menu');
      }) > -1
    ) {
      return false;
    }

    event.preventDefault();
    event.stopPropagation();

    const { canGoNextPage, canGoPrevPage } = this.props;
    const { timeStamp } = event;
    const deltaY = thisDevice.isSurface && event.pressure > 0
      ? event.movementY * -1
      : event.deltaY || event.detail;

    const absDeltaY = Math.abs(deltaY);
    if (this.changePageAnimation && this.changePageAnimation &&
      new Date() - this.changePageAnimationTime > changePageAnimationTime) {
      this.deltaY = absDeltaY;
      this.wheelTimestamp = timeStamp;
      this.changePageAnimation();
      return false;
    }

    if (timeStamp - this.wheelTimestamp < 300 &&
      this.deltaY - absDeltaY >= 0) {
      this.deltaY = absDeltaY;
      this.wheelTimestamp = timeStamp;
      return false;
    }

    this.deltaY = absDeltaY;
    this.wheelTimestamp = timeStamp;

    const minDeltaY = thisDevice.isFirefoxDesktop
      ? minimumDeltaYFF
      : minimumDeltaY;

    if (deltaY > minDeltaY) {
      if (
        !this.pagination && canGoNextPage &&
        !this.isLastPage()
      ) {
        this.changePage(this.context.getAdjacentPageId(1), this.props.activePageId);
      }
    }

    if (deltaY < -minDeltaY) {
      if (
        !this.pagination && canGoPrevPage &&
        !this.isFirstPage()
      ) {
        this.changePage(this.context.getAdjacentPageId(-1), this.props.activePageId);
      }
    }

    return true;
  };

  onPageChange = () => {
    this.canGoNextPageTimer = clearTimeout(this.canGoNextPageTimer);
    this.canGoPrevPageTimer = clearTimeout(this.canGoPrevPageTimer);

    // update can go after page activation
    this.updateCanGoImmediately({ scrollTop: 0, scrollLeft: 0 });
  };

  onLockerMouseDown = (event) => {
    if (this.props.isScrollDisabled) {
      event.preventDefault();
    }
  };

  render() {
    const { activePageId, nextActive, count,
      pagePanning, pageChanging, pagePinching, pageScaling,
      isScrollDisabled, isDrawingNewGraphicElement, appStarted } = this.props;
    return (
      <div
        className={classnames('jsf-pagination-local', 'pagination-Pagination', {
          'pageChanging-Pagination': pagePanning || pageChanging,
          'pageScaling-Pagination': pagePinching || pageScaling,
          'pageAnimation-Pagination': pagePanning || pageChanging || pagePinching || pageScaling,
        })}
        ref={this.storePaginationRef}
      >
        <div
          className="scroller-Pagination"
          ref={this.storePaginationScrollerRef}
        >
          {this.props.children}
        </div>
        {isScrollDisabled && !isDrawingNewGraphicElement &&
          <div className="locker-Pagination" onMouseDown={this.onLockerMouseDown} />
        }
        <PaginationLabel
          template={this.props.paginationLabel.template}
          current={this.context.getIndexByPageId(
            nextActive === false
              ? activePageId
              : nextActive,
          )}
          count={count}
          appStarted={appStarted}
          pageChanging={pageChanging}
        />
      </div>
    );
  }
}
