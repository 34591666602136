import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ArrowToolView from './ArrowToolView';
import { pathFromPoints, getViewbox, drawArrow } from '../../../helpers/graphicUtils';
import { defaultControlPoints } from '../../../helpers/const';

export default class ArrowTool extends Component {
  static propTypes = {
    controlPoints: PropTypes.arrayOf(
      PropTypes.number,
    ), // not required because it's not passed in ElementGhost
    fillColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    lineWidth: PropTypes.number.isRequired,
    direction: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    isGhost: PropTypes.bool,

    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    storeRef: PropTypes.func.isRequired,
    interceptFocusDataset: PropTypes.shape({}),
  };

  static defaultProps = {
    controlPoints: defaultControlPoints,
    isDisabled: false,
    isGhost: false,
    interceptFocusDataset: undefined,
  };

  render() {
    const {
      controlPoints,
      direction,
      fillColor,
      isDisabled,
      lineWidth,
      isGhost,

      onFocus,
      onBlur,
      storeRef,
      interceptFocusDataset,
    } = this.props;

    const arrow = drawArrow(controlPoints, lineWidth, direction);
    const viewBox = getViewbox(arrow.points);
    const path = pathFromPoints(arrow.points, true);

    return (
      <ArrowToolView
        fillColor={fillColor}
        path={path}
        viewBox={viewBox}
        isDisabled={isDisabled}
        isGhost={isGhost}

        onFocus={onFocus}
        onBlur={onBlur}
        storeRef={storeRef}
        {...interceptFocusDataset}
      />
    );
  }
}
