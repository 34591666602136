class EventEmitter {
  constructor() {
    this.listeners = [];
  }

  subscribe = (fn) => {
    this.listeners.push(fn);
    return () => {
      this.unsubscribe(fn);
    };
  };

  unsubscribe = (fn) => {
    this.listeners = this.listeners.filter((listener) => {
      return listener !== fn;
    });
  };

  emit = (event) => {
    for (let i = 0; i < this.listeners.length; i++) {
      this.listeners[i](event);
    }
  };
}

const eventEmitter = new EventEmitter();

export default eventEmitter;
