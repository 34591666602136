import get from 'lodash/get';
import { cancellableOpts } from '../../../store/modules/undoRedo';
import {
  isFillableCheckmarkElement,
  isRadioElement,
} from '../../../store/helpers/functions';
import { selectors } from '../../..';

/* eslint arrow-body-style: 0 */
const toggleCheckmarkValue =
  ({ element, updater }) => (dispatch) => {
    if (
      isFillableCheckmarkElement(element) ||
      // radio can be only fillable
      isRadioElement(element)
    ) {
      const newCheckedValue = !get(element, 'content.checked', false);
      return dispatch(
        updater(
          element.id, { checked: newCheckedValue },
          cancellableOpts,
        ),
      );
    }

    return false;
  };

toggleCheckmarkValue.onlyInFillingMode =
  ({ element, updater }) => (dispatch, getState) => {
    if (selectors.mode.isFilling(getState())) {
      return toggleCheckmarkValue({ element, updater })(dispatch);
    }

    return false;
  };


export default toggleCheckmarkValue;
/* eslint arrow-body-style: 0 */
