import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { selectors } from '../..';

@connect(
  (_, { pageId }) => {
    const getSplittedElementsIdsByPageId =
      selectors.elements.getSplittedElementsIdsByPageIdFactory(pageId);

    return (state) => {
      const {
        eraseElementsIds,
        otherElementsIds,
      } = getSplittedElementsIdsByPageId(state);
      return {
        eraseElementsIds,
        otherElementsIds,
      };
    };
  },
)
export default class ElementsProvider extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    eraseElementsIds: PropTypes.arrayOf(
      PropTypes.string.isRequired,
    ).isRequired,
    otherElementsIds: PropTypes.arrayOf(
      PropTypes.string.isRequired,
    ).isRequired,
  };

  render() {
    const { otherElementsIds, eraseElementsIds } = this.props;

    return this.props.children({
      otherElementsIds,
      eraseElementsIds,
    });
  }
}
