import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import JSFillerError from 'jsfcore/errors/JSFillerError';
import { thisDevice } from '@pdffiller/jsf-useragent';

import {
  fireFakeEvent,
  isSignNow,
} from '../../../helpers/utils';
import canvasStore from '../../../store/helpers/canvasStore';

class PageCanvasInserter extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    canvas: PropTypes.number,
    pageId: PropTypes.number.isRequired,
    forceCanvas: PropTypes.bool,

    getRef: PropTypes.func.isRequired,
  };

  static defaultProps = {
    canvas: null,
    forceCanvas: false,
  };

  componentDidMount() {
    const { canvas } = this.props;
    if (canvas) {
      this.onCanvasChanged(canvas);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ canvas }) {
    if (canvas !== this.props.canvas) {
      this.onCanvasChanged(canvas);
    }
  }

  onCanvasChanged = (canvasId) => {
    const node = this.props.getRef();

    if (node) {
      if (node.getElementsByTagName('canvas').length === 1) {
        node.innerHTML = '';
      }

      const canvas = canvasStore.getCanvas(canvasId);
      if (canvas) {
        /**
         * Когда я делал zoom-css отказалось, что safari desktop не умеет
         * нормально рисовать текст с трансформацией поверх canvas'a
         *
         * Было перепробовано много вариантов, самым рабочим оказался вариант
         * отказа от canvas в пользу img.
         *
         * Какие варианты были испробованы:
         * - translateZ(0)
         * - backface-visibility: hidden;
         * - -webkit-font-smoothing: subpixel-antialiased;
         * - zIndex: -1;
         * - zoom вместо scale
         *
         * и разные их комбинации.
         */
        if (thisDevice.isSafariDesktop && !this.props.forceCanvas) {
          const dataUrl = canvas.toDataURL('image/jpeg');
          const img = document.createElement('img');
          img.src = dataUrl;
          img.style.width = '100%';
          img.style.height = '100%';
          img.style.display = 'block';
          if (node.getElementsByTagName('img').length === 1) {
            node.innerHTML = '';
          }
          node.appendChild(img);

          // В этом кейсе нет смысла хранить использованные canvas'ы
          canvasStore.deleteCanvas(canvasId);
          return;
        }

        // JSF-3086: 'Argument 1 ('node') to Node.appendChild must be an instance of Node'
        // Этот код работает уже очень давно безо всяких изменений,
        // но ошибки начали падать только в 2.9
        // Чтобы не было эксепшенов - вставим проверку на instanceof Node
        if (!(canvas instanceof window.Node)) {
          throw new JSFillerError('trying to render canvas that is not instanceof Node', {
            pageId: this.props.pageId,
            canvas,
            canvasId,
            oldCanvasId: this.props.canvas,
            store: canvasStore.dump(),
          });
        }

        node.appendChild(canvas);

        if (isSignNow()) {
          // maybe vertical scrollbar will be added after canvas append
          fireFakeEvent('resize');
        }
      }
    }
  };

  render() {
    return this.props.children;
  }
}

export default connect(
  (__, { pageId }) => {
    return (state) => {
      return {
        canvas: state.pdf.canvases[pageId],
        pageId,
      };
    };
  },
  null,
)(PageCanvasInserter);

export const PageThumbnailsInserter = connect(
  (__, { pageId }) => {
    return (state) => {
      return {
        canvas: state.pdf.thumbnails[pageId],
        pageId,
      };
    };
  },
  null,
)(PageCanvasInserter);
