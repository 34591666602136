import updateElement from './updateElement';
import { toolbar } from '../selectors';
import getFontFamilyConfig from '../../helpers/getFontFamilyConfig';

const setFontFamily = (fontFamily) => {
  return (dispatch, getState) => {
    const elementProps = toolbar.getElementPropsForToolbar(getState());
    const fontFamilyConfig = getFontFamilyConfig(elementProps, fontFamily);

    return dispatch(updateElement(elementProps.id, fontFamilyConfig));
  };
};

export default setFontFamily;
