import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cls from 'classnames';
import { connect } from 'react-redux';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { selectors } from '../..';

@connect(
  (state, props) => {
    return {
      isHoverTool: selectors.getIsHoverTool(state),
      originalSize: selectors.base.getOriginalSizes(state)[props.pageId],
      isVersionsMode: selectors.mode.isVersions(state),
    };
  },
)
export default class ContentWrapperView extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    pageId: PropTypes.number.isRequired,
    scale: PropTypes.number.isRequired,
    isHoverTool: PropTypes.bool.isRequired,
    originalSize: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),
    isVersionsMode: PropTypes.bool,
  };

  static defaultProps = {
    isVersionsMode: false,
    originalSize: {},
  };

  static childContextTypes = {
    getContentRef: PropTypes.func,
  };

  getChildContext() {
    return {
      getContentRef: this.getContentRef,
    };
  }

  getContentRef = () => {
    return this.content;
  };

  storeContentRef = (ref) => {
    this.content = ref;
  };

  renderWrapperForEdge = (children) => {
    const {
      scale,
      originalSize,
    } = this.props;
    if (thisDevice.isEdgeDesktop || thisDevice.isInternetExplorer11) {
      return (
        <div
          style={{
            position: 'absolute',
            width: originalSize.width * scale,
            height: originalSize.height * scale,
            top: 0,
            left: 0,
          }}
        >
          {children}
        </div>
      );
    }

    return children;
  };

  render() {
    const {
      pageId,
      children,
      isHoverTool,
      scale,
      originalSize,
      isVersionsMode,
    } = this.props;
    return this.renderWrapperForEdge(
      <div
        className={
          cls('content-Content', {
            'contentCanNotHover-Content': !isHoverTool || isVersionsMode,
            _inaccessible: isVersionsMode,
          })
        }
        style={{
          transform: `scale(${scale})`,
          transformOrigin: '0 0',
          width: originalSize.width,
          height: originalSize.height,
        }}
        data-page-id={pageId}
        data-autotest="contentContent"
        ref={this.storeContentRef}
      >
        {children}
      </div>,
    );
  }
}
