import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { thisDevice } from '@pdffiller/jsf-useragent';
import flatten from 'lodash/flatten';

import {
  popupMenu,
  popupMenuDesktop,
  colorKeys,
  toolbarAttributesContainerType,
} from '../../../../helpers/const/const';
import FontStylePopupSettings from '../../../Tools/ToolbarSettings/Style/Popup/StylePopup';
import FontSizePopupMenuSettings from '../../../Tools/ToolbarSettings/FontSize/Popup/FontSizePopupMenu';
import FontFamilyPopupMenuSettings from '../../../Tools/ToolbarSettings/Font/Popup/FontFamilyPopupMenuSettings';
import DirectionPopupSettings from '../../../Tools/ToolbarSettings/Direction/Popup/DirectionPopup';
import AlignPopupSettings from '../../../Tools/ToolbarSettings/Alignments/Popup/AlignPopup';
import VAlignPopupSettings from '../../../Tools/ToolbarSettings/Alignments/Popup/VAlignPopup';
import HrefPopupSettings from '../../../Tools/ToolbarSettings/Href/Popup/HrefPopup';
import LineWidthPopupSettings from '../../../Tools/ToolbarSettings/LineWidth/Popup/LineWidthPopup';
import ColorPopupEntry from '../../../Tools/ToolbarSettings/Color/Popup/ColorPopupEntry';
import { getVisibleTools } from '../toolbarAttributesUtils';

export default class PopupToolList extends Component {
  static propTypes = {
    // renderingProps from parent
    elementId: PropTypes.string,
    hasFont: PropTypes.bool,
    hasColor: PropTypes.bool,
    hasBackgroundColor: PropTypes.bool,
    hasBorderColor: PropTypes.bool,
    hasArrowStyle: PropTypes.bool,
    hasFillColor: PropTypes.bool,
    hasFontColor: PropTypes.bool,
    hasAlign: PropTypes.bool,
    hasHref: PropTypes.bool,
    hasLineWidth: PropTypes.bool,

    locale: PropTypes.shape({
      settings: PropTypes.string.isRequired,
      OK: PropTypes.string.isRequired,
      done: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      background: PropTypes.string.isRequired,
      border: PropTypes.string.isRequired,
      font: PropTypes.string.isRequired,
      fontSize: PropTypes.string.isRequired,
      fontStyle: PropTypes.string.isRequired,
      arrowStyle: PropTypes.string.isRequired,
      align: PropTypes.string,
      valign: PropTypes.string,
      href: PropTypes.string,
      size: PropTypes.string,
    }).isRequired,

    setActivePopupMenu: PropTypes.func.isRequired,

    resetPopup: PropTypes.func.isRequired,
    popupToolsCount: PropTypes.number,
    hasVAlign: PropTypes.bool,

    //
    forceFocusTextElement: PropTypes.func.isRequired,
  };

  static defaultProps = {
    hasFont: false,
    hasColor: false,
    hasBackgroundColor: false,
    hasBorderColor: false,
    hasArrowStyle: false,
    hasFillColor: false,
    hasFontColor: false,

    hasAlign: false,
    hasHref: false,
    hasLineWidth: false,
    popupToolsCount: -1,
    hasVAlign: false,
    elementId: null,
  };

  onMenuEntryClickFactory = (menuEntry) => {
    return () => {
      this.props.setActivePopupMenu(menuEntry);
    };
  };

  onOkClick = () => {
    this.props.forceFocusTextElement(this.props.elementId);
    this.props.resetPopup();
  };

  renderToolsList = () => {
    const {
      hasFont,
      hasColor,
      hasFontColor,
      hasArrowStyle,
      hasBackgroundColor,
      hasBorderColor,
      hasLineWidth,
      hasFillColor,
      popupToolsCount,
      locale,
    } = this.props;

    const toolsList = [
      hasFont && <FontFamilyPopupMenuSettings
        key={popupMenu.fontFamily}
        locale={this.props.locale.font}
      />,
      hasFont && <FontSizePopupMenuSettings
        key={popupMenu.fontSize}
        locale={this.props.locale.fontSize}
      />,
      hasFont &&
      <FontStylePopupSettings
        key={popupMenuDesktop.fontStyle}
        locale={this.props.locale.fontStyle}
      />,
      hasArrowStyle &&
      <DirectionPopupSettings
        key={popupMenuDesktop.arrow}
        locale={this.props.locale.arrowStyle}
      />,
      hasLineWidth &&
      <LineWidthPopupSettings
        key={popupMenuDesktop.lineWidth}
        locale={this.props.locale.size}
      />,
      hasColor && <ColorPopupEntry
        key={popupMenu.color}
        label={locale.color}
        popupMenuItem={popupMenu.color}
        locale={locale}
        colorKey={colorKeys.color}
      />,
      hasFontColor && <ColorPopupEntry
        key={popupMenu.fontColor}
        label={locale.color}
        popupMenuItem={popupMenu.fontColor}
        locale={locale}
        colorKey={colorKeys.fontColor}
      />,
      hasFillColor && <ColorPopupEntry
        key={popupMenu.fillColor}
        label={locale.color}
        popupMenuItem={popupMenu.fillColor}
        locale={locale}
        colorKey={colorKeys.fillColor}
      />,
      hasBackgroundColor && <ColorPopupEntry
        key={popupMenu.backgroundColor}
        label={locale.background}
        popupMenuItem={popupMenu.backgroundColor}
        locale={locale}
        colorKey={colorKeys.bgColor}
      />,
      hasBorderColor && <ColorPopupEntry
        key={popupMenu.borderColor}
        label={locale.border}
        popupMenuItem={popupMenu.borderColor}
        locale={locale}
        colorKey={colorKeys.borderColor}
      />,
      thisDevice.isDesktop && this.renderDesktopTools(),
    ];

    if (thisDevice.isDesktop) {
      const toolsListArray = flatten(toolsList).filter((tool) => {
        return tool;
      });

      return getVisibleTools(
        toolsListArray,
        popupToolsCount,
        toolbarAttributesContainerType.popover,
      );
    }
    return toolsList;
  };

  renderDesktopTools = () => {
    const { hasAlign, hasVAlign, hasHref } = this.props;
    const { align, valign, href } = this.props.locale;

    return [
      hasAlign ? (
        <AlignPopupSettings key={popupMenuDesktop.align} locale={align} />
      ) : false,
      hasVAlign ? (
        <VAlignPopupSettings key={popupMenuDesktop.valign} locale={valign} />
      ) : false,
      hasHref ? (
        <HrefPopupSettings key={popupMenuDesktop.href} locale={href} />
      ) : false,
    ];
  };

  render() {
    if (!this.props.elementId) {
      return null;
    }
    return this.renderToolsList();
  }
}
