import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  pageCountLabelForceTimeout,
  pageCountLabelTimeout,
} from '../../../helpers/const';
import PaginationLabelInner from './PaginationLabelInner';

// Wrapper class: it decides if PaginationLabelInner is visible or not
export default class PaginationLabel extends Component {
  static propTypes = {
    current: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
    count: PropTypes.number.isRequired,
    template: PropTypes.string.isRequired,
    pageChanging: PropTypes.bool.isRequired,
    appStarted: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.hideTimeout = null;
    this.state = { isVisible: false };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { current, pageChanging, appStarted } = this.props;
    const {
      current: nextCurrent,
      pageChanging: nextPageChanging,
      appStarted: nextAppStarted,
    } = nextProps;

    if (current !== nextCurrent || (!appStarted && nextAppStarted)) {
      this.setState({ isVisible: true });

      // в общем случае мы прячем метку после того как закончится pageChanging,
      // но если этого не дождемся - делаем в таймауте
      if (this.hideTimeout) {
        clearTimeout(this.hideTimeout);
      }
      this.hideTimeout = setTimeout(this.hideLabel, pageCountLabelForceTimeout);
    }

    if (pageChanging && !nextPageChanging && this.hideTimeout) {
      clearTimeout(this.hideTimeout);
      setTimeout(this.hideLabel, pageCountLabelTimeout);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.hideTimeout);
  }

  hideLabel = () => {
    this.setState({ isVisible: false });
  };

  render() {
    return (
      <PaginationLabelInner isVisible={this.state.isVisible} {...this.props} />
    );
  }
}
