import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import fill from 'lodash/fill';

import { setGhostPageId } from '../../../store/modules/navigation';
import { analyzeTarget } from '../ghostUtils';

import {
  screenToPageFreeGhost,
  getGhostPosition,
  isSimplePagination,
} from '../../../helpers/utils';
import { selectors } from '../../..';

const getFrameOffsetsCustom = createSelector(
  [
    (frameSizes) => {
      return frameSizes;
    },
    (_, workspace) => {
      return workspace;
    },
    (_, __, frameScrolls) => {
      return frameScrolls;
    },
  ],
  selectors.getFrameOffsetsWithoutReselect,
);

export const fillFrameScrollsDefault = ({ length = 0 }) => {
  return fill(Array(length), { scrollTop: 0, scrollLeft: 0 });
};

export const mapStateToProps = (state) => {
  return {
    ghostElementForRender: selectors.getGhostElementForRender(state),
    activePageId: selectors.base.getActivePageId(state),
    isConstructorMode: selectors.mode.isConstructor(state),
  };
};

export const mapDispatchToProps = { setGhostPageId };

export class GhostDataProvider extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    ghostElementForRender: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    frameSizes: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          height: PropTypes.number.isRequired,
        }),
        PropTypes.bool,
      ]).isRequired,
    ).isRequired,
    workspace: PropTypes.shape({
      left: PropTypes.number,
      top: PropTypes.number,
      height: PropTypes.number.isRequired,
      framePadding: PropTypes.shape({
        top: PropTypes.number.isRequired,
        bottom: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        right: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    scales: PropTypes.arrayOf(
      PropTypes.number,
    ).isRequired,
    activePageId: PropTypes.number.isRequired,
    isConstructorMode: PropTypes.bool.isRequired,
    setGhostPageId: PropTypes.func.isRequired,
  };

  static defaultProps = {
    ghostElementForRender: null,
  };

  static contextTypes = {
    subscribeToScroll: PropTypes.func.isRequired,
    unsubscribeToScroll: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { frameSizes } = this.props;

    this.scrolls = fillFrameScrollsDefault({ length: frameSizes.length });
    this.state = {
      frameScrolls: fillFrameScrollsDefault({ length: frameSizes.length }),
    };
  }

  componentDidMount() {
    this.context.subscribeToScroll(this.onScroll);
  }

  componentWillUnmount() {
    this.context.unsubscribeToScroll(this.onScroll);
  }

  getFrameOffsets = () => {
    const { frameSizes, workspace } = this.props;
    return getFrameOffsetsCustom(
      frameSizes,
      workspace,
      fillFrameScrollsDefault({ length: frameSizes.length }),
    );
  }

  getFrameOffset = () => {
    return this.getFrameOffsets()[this.getPageId()];
  };

  getFrameOffsetsWithScroll = () => {
    const { frameSizes, workspace } = this.props;
    const { frameScrolls } = this.state;
    return getFrameOffsetsCustom(frameSizes, workspace, frameScrolls);
  };

  getFrameOffsetWithScroll = () => {
    return this.getFrameOffsetsWithScroll()[this.getPageId()];
  };

  getFrameSize = () => {
    return this.props.frameSizes[this.getPageId()];
  };

  getScale = () => {
    return this.props.scales[this.getPageId()];
  };

  // for mobile use active page id
  getPageId = () => {
    return this.props.activePageId;
  };

  getScaledGhostPosition = (posArg) => {
    const { workspace } = this.props;
    const commonPos = this.getCommonScaledGhostPosition({
      posArg, frameOffset: this.getFrameOffsetWithScroll(),
    });

    return {
      x: commonPos.x - workspace.left,
      y: commonPos.y - workspace.top,
    };
  };

  getCommonScaledGhostPosition = ({ posArg, frameOffset }) => {
    if (!posArg) {
      return { x: 0, y: 0 };
    }

    const { ghostElementForRender, workspace } = this.props;
    const scale = this.getScale();

    const pos = { x: posArg.clientX, y: posArg.clientY };

    // Step 1: get event coords relative to content div
    const coords = screenToPageFreeGhost(pos, scale, frameOffset, workspace);

    // Step 2: calculate position of ghost element
    const ghostPosition = getGhostPosition(scale, ghostElementForRender);
    const unlimitedPosition = {
      y: (coords.y * scale) + ghostPosition.top,
      x: (coords.x * scale) + ghostPosition.left,
    };

    return unlimitedPosition;
  }

  setFrameScroll = (scroll, pageId) => {
    this.scrolls[pageId] = scroll;
  };

  getFrameScrolls = (scroll, pageId) => {
    this.setFrameScroll(scroll, pageId);
    return { frameScrolls: this.scrolls };
  };

  getPropsForElementCreating = (target) => {
    const {
      isMouseOverClickBlocker,
      isMouseOverElement,
      pageIdAboveWhichMouse,
    } = analyzeTarget(target);

    const isMouseOverPage = pageIdAboveWhichMouse !== -1;

    if (isMouseOverPage && pageIdAboveWhichMouse !== this.props.activePageId) {
      this.props.setGhostPageId(pageIdAboveWhichMouse);
    }

    return {
      isMouseOverElement,
      isMouseOverClickBlocker,
      isMouseOverPage,
    };
  };

  onScroll = (scroll, pageId) => {
    // for simple pagination set frame Scrolls for every pages
    this.setState(
      isSimplePagination()
        ? this.getFrameScrolls(scroll, pageId)
        : { frameScrolls: fill(Array(this.props.frameSizes.length), scroll) },
    );
  };

  render() {
    return this.props.children({
      isConstructorMode: this.props.isConstructorMode,
      activePageId: this.props.activePageId,
      scale: this.getScale(),
      frameSize: this.getFrameSize(),
      frameOffset: this.getFrameOffset(),
      ghostElementForRender: this.props.ghostElementForRender,
      getScaledGhostPosition: this.getScaledGhostPosition,
      getPropsForElementCreating: this.getPropsForElementCreating,
    });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GhostDataProvider);
