import range from 'lodash/range';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FocusControlledSelect from '../Select';
import { getDaysInMonth } from '../../helpers/utils';
import * as Ui from '../../ui';
import menuRendererYearDropDown from './menuRendererYearDropDown';

const selectWidths = {
  monthSelect: 112,
  daySelect: 66,
  yearSelect: 82,
};

const undefDateObject = {
  day: undefined,
  month: undefined,
  year: undefined,
  days: range(1, 31).map((label) => {
    return { label, value: label };
  }),
};

const isUndefinedOrNull = (val) => {
  return val === undefined || val === null;
};

export const isUnexpectedDateValue = ({ day, month, year }) => {
  return (
    isUndefinedOrNull(day) ||
    isUndefinedOrNull(month) ||
    isUndefinedOrNull(year)
  );
};

export default class MonthDayYearControl extends Component {
  static propTypes = {
    months: PropTypes.arrayOf(PropTypes.string).isRequired,
    date: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    selectOptions: PropTypes.shape({}).isRequired,

    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = this.getStateFromDateString(props.date, props.format);
    this.months = props.months.map((label, value) => {
      return { label, value };
    });
    this.years = range(1900, 2099).map((label) => {
      return { label, value: label };
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ date, format }) {
    if (date !== this.props.date && date !== this.getDateStringFromState()) {
      this.setState(this.getStateFromDateString(date, format));
    }
  }

  /**
   * Возвращает часть state
   * {
   *   day
   *   daysInMonth - количество дней в month-year
   *   days - массив объектов дней для <Select />
   * }
   * если количество дней в month-year отличается от хранимого в state
   * иначе возвращает пустой объект
   */
  getDayAndDaysAndDaysInMonthIfNeedUpdate = ({ month, year, day }) => {
    const { days, daysInMonth } = this.getDaysAndDaysInMonth({ month, year });
    return (
      (!this.state || daysInMonth !== this.state.daysInMonth)
        ? {
          day: (daysInMonth > day || day === undefined)
            ? day
            : daysInMonth,
          daysInMonth,
          days,
        }
        : {}
    );
  };

  /**
   * Для месяца и года возвращает объект
   * {
   *   daysInMonth - количество дней в month-year
   *   days - массив объектов дней для <Select />
   * }
   */
  getDaysAndDaysInMonth = ({ month, year }) => {
    const daysInMonth = getDaysInMonth(month || 0, year || 2016);
    return {
      daysInMonth,
      days: range(1, daysInMonth + 1).map((label) => {
        return { label, value: label };
      }),
    };
  };

  /**
   * Генерирует строку даты из значений { day, month, year }
   * хранящихся в state и формата который в props.format
   */
  getDateStringFromState = () => {
    const { day, month, year } = this.state;
    if (isUnexpectedDateValue({ day, month, year })) {
      return '';
    }
    return moment([year, month, day]).format(this.props.format.toUpperCase());
  };

  /**
   * Возвращает стейт компонента для начальной инициалиции или
   * после обновления props'ов.
   * {
   *   month - номер месяца
   *   year - номер года
   *   day - номер дня
   *   [daysInMonth - количество дней в month-year]
   *   [days - массив объектов дней для <Select />]
   * }
   * Функция возвращает daysInMonth и days опционально:
   * - Если это инициализация
   * - Если daysInMonth отличается от предыдущего
   */
  getStateFromDateString = (date, format) => {
    if (date.length < 1) {
      return undefDateObject;
    }

    const dateObj = moment(date, format);
    const month = dateObj.month();
    const year = dateObj.year();
    const day = dateObj.date();

    if (Number.isNaN(month) || Number.isNaN(year) || Number.isNaN(day)) {
      return undefDateObject;
    }

    const dmy = { month, year, day };
    return {
      ...dmy,
      ...this.getDayAndDaysAndDaysInMonthIfNeedUpdate(dmy),
    };
  };

  getStringValue = () => {
    const { day, month, year } = this.state;
    if (isUnexpectedDateValue({ day, month, year })) {
      return false;
    }
    const value = moment(0).year(year).month(month).date(day);
    return value.format(this.props.format);
  };

  onChangeYear = ({ value: year }) => {
    const { month, day } = this.state;
    this.setState({
      year,
      ...this.getDayAndDaysAndDaysInMonthIfNeedUpdate({ month, year, day }),
    }, () => {
      this.props.onChange(this.getStringValue());
    });
  };

  onChangeMonth = ({ value: month }) => {
    const { year, day } = this.state;
    this.setState({
      month,
      ...this.getDayAndDaysAndDaysInMonthIfNeedUpdate({ month, year, day }),
    }, () => {
      this.props.onChange(this.getStringValue());
    });
  };

  onChangeDay = ({ value: day }) => {
    this.setState({ day }, () => {
      this.props.onChange(this.getStringValue());
    });
  };

  render() {
    const { selectOptions } = this.props;
    const { month, day, year } = this.state;

    return (
      <Ui.Group.Body>
        <Ui.Group.Item>
          <FocusControlledSelect
            options={this.months}
            value={month}
            onChange={this.onChangeMonth}
            placeholder="MM"
            width={selectWidths.monthSelect}
            {...selectOptions}
          />
        </Ui.Group.Item>

        <Ui.Group.Item>
          <FocusControlledSelect
            options={this.state.days}
            value={day}
            onChange={this.onChangeDay}
            placeholder="DD"
            width={selectWidths.daySelect}
            {...selectOptions}
          />
        </Ui.Group.Item>

        <Ui.Group.Item>
          <FocusControlledSelect
            options={this.years}
            value={year}
            onChange={this.onChangeYear}
            placeholder="YYYY"
            width={selectWidths.yearSelect}
            menuRenderer={menuRendererYearDropDown}
            {...selectOptions}
          />
        </Ui.Group.Item>
      </Ui.Group.Body>
    );
  }
}
