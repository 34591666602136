import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import {
  Wrapper,
  VisibilityProvider,
  DetectWindowClickProvider,
  DetectWindowResizeProvider,
  DetectScrollProvider,
} from '../Portal';
import StoreRefProvider from '../StoreRef/StoreRefProvider';

const storeRefKeys = {
  portal: 'portal',
  trigger: 'trigger',
};

const Dropdown = ({
  dropdownComponent: DropdownComponent,
  children,
  wrapperProps,
  clickCloses,
  getAlignRef,
  needStopPropagation,
  openPortalOnMount,
  timeout,
}) => {
  return (
    <StoreRefProvider>
      {({ storeRefs, getRefs }) => {
        return (
          <VisibilityProvider
            timeout={timeout}
            initialVisibility={openPortalOnMount}
          >
            {({ togglePortal, closePortal, openPortal, isVisible }) => {
              const wrapperOnClick = clickCloses
                ? closePortal
                : null;

              return (
                <Fragment>
                  {children({
                    togglePortal,
                    closePortal,
                    openPortal,
                    storeRef: storeRefs(storeRefKeys.trigger),
                    isPortalOpen: isVisible,
                  })}

                  {isVisible && (
                    <Fragment>
                      <Wrapper
                        {...wrapperProps}
                        storeRef={storeRefs(storeRefKeys.portal)}
                        getRef={getAlignRef || getRefs(storeRefKeys.trigger)}
                        onClick={wrapperOnClick}
                      >
                        <DropdownComponent closePortal={closePortal} />
                      </Wrapper>

                      <DetectWindowClickProvider
                        getRef={getRefs(storeRefKeys.portal)}
                        getTriggerRef={getRefs(storeRefKeys.trigger)}
                        onClick={closePortal}
                        needStopPropagation={needStopPropagation && !isVisible}
                      />

                      <DetectWindowResizeProvider onResize={closePortal} />
                      <DetectScrollProvider
                        getRef={getAlignRef || getRefs(storeRefKeys.trigger)}
                        onScroll={closePortal}
                      />

                    </Fragment>
                  )}
                </Fragment>
              );
            }}
          </VisibilityProvider>
        );
      }}
    </StoreRefProvider>
  );
};

Dropdown.propTypes = {
  dropdownComponent: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  wrapperProps: PropTypes.shape(
    omit(Wrapper.propTypes, ['getRef', 'children']),
  ),
  getAlignRef: PropTypes.func,
  clickCloses: PropTypes.bool,
  needStopPropagation: PropTypes.bool,
  openPortalOnMount: PropTypes.bool,
  timeout: PropTypes.number,
};

Dropdown.defaultProps = {
  getAlignRef: null,
  clickCloses: false,
  wrapperProps: {},
  needStopPropagation: true,
  openPortalOnMount: false,
  timeout: null,
};

export default Dropdown;
