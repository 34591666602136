import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { selectors } from '../../..';
import { occurenceTypes } from '../../../helpers/const';
import {
  setSearchTerm,
  toggleSearchNext,
  toggleSearchPrev,
  setNewHighlightType,
} from '../../../store/modules/search';
import { elemTypes, searchHighlightTypes } from '../../../helpers/elemTypes';

import ToolbarSearchTextInput from './ToolbarSearchTextInput';
import * as Ui from '../../../ui';

@connect(
  (state) => {
    return {
      searchTerm: selectors.base.getSearchTerm(state),
      searchIndex: state.search.searchIndex,
      occurences: selectors.base.getOccurences(state),
      currentHighlightType: state.search.currentHighlightType,
      isProcessingSearch: state.search.isProcessingSearch,
      locale: selectors.locale.getEditorLocale(state).search,
      isFConstructorShown: selectors.base.getIsFConstructorShown(state),
      isPageChanging: selectors.base.getIsPageChanging(state),
      isRectsEmpty: isEmpty(selectors.base.getRects(state)),
    };
  }, {
    setSearchTerm,
    toggleSearchNext,
    toggleSearchPrev,
    setNewHighlightType,
  },
)
export default class ToolbarSearch extends Component {
  static propTypes = {
    searchTerm: PropTypes.string.isRequired,
    searchIndex: PropTypes.number.isRequired,
    occurences: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.array,
    ]).isRequired,
    currentHighlightType: PropTypes.oneOfType([
      PropTypes.oneOf(searchHighlightTypes),
      PropTypes.bool,
    ]).isRequired,
    isProcessingSearch: PropTypes.bool.isRequired,
    locale: PropTypes.shape({
      searchOf: PropTypes.string.isRequired,
    }).isRequired,
    isFConstructorShown: PropTypes.bool.isRequired,
    isPageChanging: PropTypes.bool.isRequired,

    setSearchTerm: PropTypes.func.isRequired,
    toggleSearchNext: PropTypes.func.isRequired,
    toggleSearchPrev: PropTypes.func.isRequired,
    setNewHighlightType: PropTypes.func.isRequired,
    isRectsEmpty: PropTypes.bool.isRequired,
  };

  static contextTypes = {
    getEvents: PropTypes.func.isRequired,
  };

  setHighlightType = (elemType) => {
    const newHighlightType = elemType === this.props.currentHighlightType
      ? false
      : elemType;

    this.props.setNewHighlightType(newHighlightType);
  }

  togglePrev = () => {
    const events = this.context.getEvents();
    if (this.props.occurences && this.props.occurences.length && !events.pageChanging) {
      this.props.toggleSearchPrev();
    }
  }

  toggleNext = () => {
    const events = this.context.getEvents();
    if (this.props.occurences && this.props.occurences.length && !events.pageChanging) {
      this.props.toggleSearchNext();
    }
  }

  onEraseClick = () => {
    this.setHighlightType(elemTypes.erase);
  }

  onHighlightClick = () => {
    this.setHighlightType(elemTypes.highlight);
  }

  onBlackoutClick = () => {
    this.setHighlightType(elemTypes.blackout);
  }

  renderCounter = () => {
    const { occurences, searchIndex, locale } = this.props;
    if (!occurences) {
      return null;
    }
    const counterBodyAttributes = {
      ...Ui.attributes.ariaLive,
      ...Ui.attributes.ariaRelevant,
      ...Ui.attributes.ariaLabel,
    };
    return (
      <Ui.ToolBar.Item type={Ui.toolBar.itemTypes.counter}>
        <Ui.Counter.Body
          theme={Ui.counterBody.themes.default}
          offset={Ui.counterBody.offsets.medium}
          attributes={counterBodyAttributes}
        >
          <Ui.Counter.Item>
            {searchIndex + 1}
          </Ui.Counter.Item>
          <Ui.Counter.Separator>
            {locale.searchOf}
          </Ui.Counter.Separator>
          <Ui.Counter.Item>
            {occurences.length}
          </Ui.Counter.Item>
        </Ui.Counter.Body>
      </Ui.ToolBar.Item>
    );
  }

  renderHighlightButtons = () => {
    const {
      isFConstructorShown,
      occurences,
      searchIndex,
      currentHighlightType,
      isPageChanging,
      isRectsEmpty,
    } = this.props;

    if (isFConstructorShown) {
      return null;
    }

    const activeOccurenceType = get(occurences, `[${searchIndex}].type`);

    const isButtonDisabled =
      !activeOccurenceType ||
      activeOccurenceType === occurenceTypes.element ||
      isPageChanging ||
      isRectsEmpty;
    const isHighlightButtonDisabled = !activeOccurenceType || isPageChanging || isRectsEmpty;

    return (
      <Ui.ToolBar.Group>

        <Ui.Container.SettingButton
          icon={Ui.icon.types.erase}
          title={Ui.locales.titles.erase}
          isPressed={currentHighlightType === elemTypes.erase}
          onClick={this.onEraseClick}
          isDisabled={isButtonDisabled}
          inheritRadius
        />

        <Ui.Container.SettingButton
          icon={Ui.icon.types.highlight}
          title={Ui.locales.titles.highlight}
          isPressed={currentHighlightType === elemTypes.highlight}
          isDisabled={isHighlightButtonDisabled}
          onClick={this.onHighlightClick}
          inheritRadius
        />

        <Ui.Container.SettingButton
          icon={Ui.icon.types.blackout}
          title={Ui.locales.titles.blackout}
          isPressed={currentHighlightType === elemTypes.blackout}
          onClick={this.onBlackoutClick}
          isDisabled={isButtonDisabled}
          inheritRadius
        />

      </Ui.ToolBar.Group>
    );
  };

  render() {
    const {
      occurences,
      searchTerm,
      isProcessingSearch,
      locale,
    } = this.props;
    const isToggleButtonsEnabled = occurences && occurences.length > 0;

    return (
      <Ui.ToolBar.Main>
        <Ui.ToolBar.Group>
          <Ui.ToolBar.Item type={Ui.toolBar.itemTypes.customField}>
            <ToolbarSearchTextInput
              searchTerm={searchTerm}
              isProcessingSearch={isProcessingSearch}
              setSearchTerm={this.props.setSearchTerm}
              locale={locale}
            />
          </Ui.ToolBar.Item>
          {this.renderCounter()}

          <Ui.Container.SettingButton
            icon={Ui.icon.types.caretUp}
            title={Ui.locales.titles.prev}
            size={Ui.icon.sizes.extraSmall}
            isPressed={false}
            onClick={this.togglePrev}
            isDisabled={!isToggleButtonsEnabled}
            inheritRadius
          />

          <Ui.Container.SettingButton
            icon={Ui.icon.types.caretDown}
            title={Ui.locales.titles.next}
            size={Ui.icon.sizes.extraSmall}
            isPressed={false}
            onClick={this.toggleNext}
            isDisabled={!isToggleButtonsEnabled}
            inheritRadius
          />

        </Ui.ToolBar.Group>

        {this.renderHighlightButtons()}
      </Ui.ToolBar.Main>
    );
  }
}
