import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  openCoreHelpModal,
  closeModal,
} from 'combine-modals/lib/actions/actionCreator';
import { HELP_MODAL } from 'combine-modals/lib/constants/modal.classnames';
import { getModeIdFromState } from 'ws-editor-lib/store/helpers';
import { DEFAULT_EDITOR_MODE } from 'ws-editor-lib/constants';

import * as Ui from '../../../ui';
import { miscIds } from '../../../helpers/const';
import {
  getMiscFeatureVisible,
  locale as localeSelectors,
} from '../../../store/selectors';

@connect(
  (state) => {
    return {
      label: localeSelectors.getMiscLabel(state, miscIds.help.id),
      helpModalTitle: localeSelectors.getHelpModalLocale(state).title,
      isHelpButtonVisible: getMiscFeatureVisible(state, miscIds.help.id),
      isHelpOpened: state.modal.options.hasOwnProperty(HELP_MODAL),
      editorModeId: getModeIdFromState(state),
    };
  },
  {
    openCoreHelpModal,
    closeModal,
  },
)
export default class HeaderHelp extends Component {
  static propTypes = {
    // from global state
    isHelpOpened: PropTypes.bool.isRequired,
    isHelpButtonVisible: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    helpModalTitle: PropTypes.string.isRequired,
    editorModeId: PropTypes.string.isRequired,

    // actions
    openCoreHelpModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  onClick = () => {
    const { isHelpOpened, helpModalTitle, editorModeId } = this.props;

    if (!isHelpOpened) {
      this.props.openCoreHelpModal({
        welcome: helpModalTitle,
        editorModeId,
        mode: DEFAULT_EDITOR_MODE,
        isResponsive: true,
      });
    } else {
      this.props.closeModal(HELP_MODAL);
    }
  };

  render() {
    if (!this.props.isHelpButtonVisible) {
      return null;
    }

    return (
      <Ui.Container.SettingButton
        isPressed={this.props.isHelpOpened}
        icon={Ui.icon.types.circledQuestion}
        label={this.props.label}
        title={Ui.locales.titles.help}
        size={Ui.icon.sizes.large}
        offset={Ui.control.offsets.extraSmall}
        textOffset={Ui.text.offsets.topExtraSmall}
        onClick={this.onClick}
      />
    );
  }
}
