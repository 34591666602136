import moment from 'moment';
import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import FocusControlledSelect, {
  selectThemes,
  selectSizes,
} from 'jsfcore/components/Select';
import * as JsfUi from 'jsfcore/ui';
import Ui from '../ui';
import { currentYear } from '../datePickerUtils';

const monthStyle = { width: 115 };
const yearStyle = { width: 75 };

const monthOptions = moment.months().map((month, index) => {
  return {
    label: month,
    value: index,
  };
});

const Header = ({ shownMonth, shownYear, onChangeMonth, onChangeYear, yearsOffset }) => {
  const yearsOptions = useMemo(() => {
    return Array.from(Array(yearsOffset * 2 + 1)).map((_, i) => {
      return {
        label: currentYear - yearsOffset + i,
        value: currentYear - yearsOffset + i,
      };
    });
  }, [yearsOffset]);

  const onChangeMonthSelect = useCallback(({ value }) => {
    onChangeMonth(value);
  }, [onChangeMonth]);

  const onChangeYearSelect = useCallback(({ value }) => {
    onChangeYear(value);
  }, [onChangeYear]);

  return (
    <Ui.Body.Header>
      <Ui.Body.HeaderSelect>
        <FocusControlledSelect
          attributes={JsfUi.attributes.redesignTrue}
          theme={selectThemes.white}
          size={selectSizes.small}
          style={monthStyle}
          options={monthOptions}
          searchable={false}
          clearable={false}
          onChange={onChangeMonthSelect}
          value={shownMonth}
          placeholder="Month"
        />
      </Ui.Body.HeaderSelect>

      <Ui.Body.HeaderSelect>
        <FocusControlledSelect
          attributes={JsfUi.attributes.redesignTrue}
          theme={selectThemes.white}
          size={selectSizes.small}
          style={yearStyle}
          options={yearsOptions}
          searchable={false}
          clearable={false}
          onChange={onChangeYearSelect}
          value={shownYear}
          placeholder="Year"
        />
      </Ui.Body.HeaderSelect>
    </Ui.Body.Header>
  );
};

Header.propTypes = {
  shownMonth: PropTypes.number,
  shownYear: PropTypes.number,
  yearsOffset: PropTypes.number,
  onChangeMonth: PropTypes.func.isRequired,
  onChangeYear: PropTypes.func.isRequired,
};

Header.defaultProps = {
  shownMonth: null,
  shownYear: null,
  yearsOffset: 200,
};

export default Header;
