import Body from './Body';
import BodyFooter from './BodyFooter';
import BodyHeader from './BodyHeader';
import BodyHeaderSelect from './BodyHeaderSelect';
import BodyMain from './Main';

Body.Footer = BodyFooter;
Body.Header = BodyHeader;
Body.HeaderSelect = BodyHeaderSelect;
Body.Main = BodyMain;

export default Body;
