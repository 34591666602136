import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { fConstructor, LazyComponent } from '@pdffiller/jsf-lazyload';
import ChoiceByClickingHelpText from '@pdffiller/jsf-conditions/components/ChoiceByClickingHelpText';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { FocusControllerRestoreFocusArea } from '@pdffiller/jsf-focuscontroller';

import ToolbarNavigation from './ToolbarNavigation/ToolbarNavigation';
import ToolbarAttributes from './ToolbarAttributes/ToolbarAttributes';
import ToolbarAdvice from './ToolbarAdvice/ToolbarAdvice';
import ToolbarHelp from './ToolbarHelp/ToolbarHelp';
import ToolbarSearch from './ToolbarSearch/ToolbarSearch';

import { calculateToolbarAttributesWidth } from './ToolbarAttributes/toolbarAttributesUtils';

import * as Ui from '../../ui';

const attributes = { ...Ui.attributes.grow, ...Ui.attributes.shrink };

export default class ToolbarView extends Component {
  static propTypes = {
    activeElementId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]).isRequired,
    visible: PropTypes.bool.isRequired,
    isFConstructorPreviewShown: PropTypes.bool.isRequired,
    isFConstructorOrderShown: PropTypes.bool.isRequired,
    isFConstructorLoadedAndShown: PropTypes.bool.isRequired,
    isPAConstructorShown: PropTypes.bool.isRequired,
    isVersionsShown: PropTypes.bool.isRequired,
    isSearchModeActive: PropTypes.bool.isRequired,
    onClickFindAnotherForm: PropTypes.func.isRequired,
    isChoiseByClickingModeIsActive: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.containerRef = null;
    this.toolbarAttributesRef = null;
    this.state = {
      elementId: props.activeElementId,
      toolbarAttributesWidth: 0,
    };
  }

  componentDidMount() {
    this.updateToolbarAttributesWidth();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const toolbarAttributesWidth = calculateToolbarAttributesWidth(
      this.containerRef,
      this.toolbarAttributesRef,
    );

    // Why do we keep 'this.props.activeElementId' at state (caused by JSF-819):
    // Sometimes click event wants to go into ToolbarAttributes,
    // but ToolbarAttributes has been already unmounted
    // and 'Invariant Violation: findComponentRoot' error thrown
    // So we don't unmount ToolbarAttributes immediately
    if (this.props.activeElementId && !nextProps.activeElementId) {
      setTimeout(() => {
        this.setState({
          elementId: nextProps.activeElementId,
          toolbarAttributesWidth,
        });
      }, 0);
    } else {
      this.setState({
        elementId: nextProps.activeElementId,
        toolbarAttributesWidth,
      });
    }
  }

  updateToolbarAttributesWidth = () => {
    const toolbarAttributesWidth = calculateToolbarAttributesWidth(
      this.containerRef,
      this.toolbarAttributesRef,
    );
    this.setState({
      toolbarAttributesWidth,
    });
  }

  storeContainerRef = (ref) => {
    this.containerRef = ref;
  };

  storeToolbarAttributesRef = (ref) => {
    this.toolbarAttributesRef = ref;
  };

  renderChoiseByClickHelpText = () => {
    if (!this.props.isChoiseByClickingModeIsActive) {
      return null;
    }

    return (
      <ChoiceByClickingHelpText />
    );
  }

  render() {
    const {
      visible,
      isFConstructorPreviewShown,
      isFConstructorOrderShown,
      isFConstructorLoadedAndShown,
      isSearchModeActive,
      isPAConstructorShown,
      isVersionsShown,
      isChoiseByClickingModeIsActive,
    } = this.props;

    const isHelpAndSeparatorShown =
      (!isFConstructorLoadedAndShown || isFConstructorPreviewShown) &&
      !isPAConstructorShown &&
      !isVersionsShown;

    const isToolbarAttributesShown =
      this.props.activeElementId &&
      thisDevice.isDesktop &&
      !isChoiseByClickingModeIsActive;

    return (
      <Ui.Grid.CellGroup
        storeRef={this.storeContainerRef}
        size={Ui.grid.cellGroup.sizes.medium}
        style={
          visible
            ? {}
            : { display: 'none' }
        }
        type={Ui.grid.cellGroup.types.topLayer}
      >

        <ToolbarNavigation />

        <Ui.Grid.Separator />

        {isToolbarAttributesShown &&
          <Ui.Grid.Cell
            attributes={Ui.attributes.grow}
            storeRef={this.storeToolbarAttributesRef}
          >
            <Ui.Grid.Wrapper>
              <Ui.ToolBar.Body
                size={Ui.toolBar.sizes.small}
                theme={Ui.toolBar.themes.bordered}
                type={Ui.toolBar.types.narrow}
              >
                <Ui.ToolBar.Main>
                  <FocusControllerRestoreFocusArea isSafariDesktop={thisDevice.isSafariDesktop}>
                    <ToolbarAttributes
                      key={this.state.elementId}
                      toolbarAttributesWidth={this.state.toolbarAttributesWidth}
                    />
                  </FocusControllerRestoreFocusArea>
                </Ui.ToolBar.Main>
              </Ui.ToolBar.Body>
            </Ui.Grid.Wrapper>
          </Ui.Grid.Cell>
        }

        {isSearchModeActive && !isToolbarAttributesShown &&
          /* mode for search by text in pdf and by fields content */
          <Ui.Grid.Cell attributes={Ui.attributes.grow}>
            <Ui.Grid.Wrapper>
              <Ui.ToolBar.Body
                size={Ui.toolBar.sizes.small}
                theme={Ui.toolBar.themes.bordered}
                type={Ui.toolBar.types.narrow}
              >
                <ToolbarSearch />
              </Ui.ToolBar.Body>
            </Ui.Grid.Wrapper>
          </Ui.Grid.Cell>
        }

        {this.renderChoiseByClickHelpText()}

        <Ui.Grid.Cell
          attributes={{
            ...attributes,
            ...Ui.attributes.overflowHidden,
          }}
        >
          {!isFConstructorPreviewShown && !isFConstructorOrderShown &&
            /* Standart mode */
            <ToolbarAdvice />
          }
          {(isFConstructorPreviewShown || isFConstructorOrderShown) &&
            /* mode for fConstructor */
            <LazyComponent
              literal={fConstructor.components.fcToolbarAdvice}
              props={{ isFConstructorPreviewShown }}
            />
          }
        </Ui.Grid.Cell>

        {isHelpAndSeparatorShown && (
          <Fragment>
            <Ui.Grid.Separator />
            <ToolbarHelp
              onClickFindAnotherForm={this.props.onClickFindAnotherForm}
            />
          </Fragment>
        )}
      </Ui.Grid.CellGroup>
    );
  }
}
