import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateElement as updateElementActionCreator } from 'ws-editor-lib/actions';

import DatePicker from '@pdffiller/datepicker';
import * as Portal from '../../Portal';
import { selectors } from '../../..';

const locatorArgs = {
  position: Portal.wrapperPositions.bottomLeft,
  clampToViewport: [true, false],
  hideIfAnchorOutOfViewport: true,
  preset: Portal.wrapperPresets.datePicker,
};

const ElementDatePickerProvider = (props, context) => {
  const { element, updateElement, getRefPromise } = props;

  const onChange = useCallback(
    (text) => {
      updateElement(element.id, { text });
    },
    [element, updateElement],
  );

  if (props.isWizardFull) {
    return null;
  }

  return (
    <DatePicker
      openOnMount
      getRefPromise={getRefPromise}
      getViewportRef={context.getWorkspaceRef}
      date={element.content
        ? element.content.text
        : undefined}
      validator={element.template.validator}
      locatorArgs={locatorArgs}
      onChange={onChange}
      closeOnClickOutside={false}
      useArrow={false}
    />
  );
};

ElementDatePickerProvider.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }).isRequired,
    template: PropTypes.shape({
      validator: PropTypes.shape({}).isRequired,
    }),
  }).isRequired,
  getRefPromise: PropTypes.func.isRequired,

  isWizardFull: PropTypes.bool.isRequired,
  updateElement: PropTypes.func.isRequired,
};

ElementDatePickerProvider.contextTypes = {
  getWorkspaceRef: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    return {
      isWizardFull: selectors.base.getIsWizardFull(state),
    };
  },
  { updateElement: updateElementActionCreator },
)(ElementDatePickerProvider);
