import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { selectors } from 'jsfcore';
import findKey from 'lodash/findKey';
import reduce from 'lodash/reduce';
import { modeIds } from '../../../../helpers/const';

const getModesBarFromProps = (state, props) => {
  return props.modesBar;
};

const getTabsData = createSelector(
  [
    selectors.mode.isConstructor,
    selectors.mode.isConstructor.includingPreview,
    selectors.base.getIsFConstructorPreviewShown,
    getModesBarFromProps,
  ],
  (
    isConstructorMode,
    isFConstructorLoadedAndShown,
    isFConstructorPreviewShown,
    modesBar,
  ) => {
    return reduce(modesBar, (acc, mode) => {
      const isModeEqualMain = modeIds.main === mode.id;
      const isModeEqualConstructor = modeIds.constructor === mode.id;
      const isModeEqualPreview = modeIds.preview === mode.id;

      // "selected" is based on the state
      const selected =
        (isModeEqualMain && !isFConstructorLoadedAndShown) ||
        (isModeEqualConstructor && isConstructorMode) ||
        (isModeEqualPreview && isFConstructorPreviewShown);

      acc[mode.id] = {
        selected,
        title: mode.label,
      };

      return acc;
    }, {});
  },
);

@connect(
  (_, ownProps) => {
    return (state) => {
      return {
        tabs: getTabsData(state, ownProps),
      };
    };
  },
)
export default class ModesPanelTabsDataProvider extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    tabs: PropTypes.shape({}).isRequired,
  };

  render() {
    const { tabs } = this.props;
    const selectedModeId = findKey(tabs, { selected: true });

    return this.props.children({
      selectedModeId,
      tabs,
    });
  }
}
