import React from 'react';
import PropTypes from 'prop-types';
import * as JsfUi from 'jsfcore/ui';

const Input = ({ size, onIconClick, storeRef, isInvalid, ...inputProps }) => {
  return (
    <JsfUi.CustomField.Index
      theme={JsfUi.customField.themes.default}
      size={size}
      storeRef={storeRef}
      isInvalid={isInvalid}
    >
      <JsfUi.CustomField.Body>
        <JsfUi.CustomField.Cell data-flex-grow="1">
          <JsfUi.InputField
            theme={JsfUi.inputField.themes.default}
            size={JsfUi.inputField.sizes.parent}
            offset={JsfUi.inputField.offsets.mediumHorizontal}
            data-is-date-input="true"
            {...inputProps}
          />
        </JsfUi.CustomField.Cell>
        <JsfUi.CustomField.Cell>
          <JsfUi.Control
            onClick={onIconClick}
            size={JsfUi.control.sizes.parentHeight}
            offset={JsfUi.control.offsets.small}
            theme={JsfUi.control.themes.transparent}
            inheritRadius
            icon={
              <JsfUi.Icon
                type={JsfUi.icon.types.calendar}
                size={JsfUi.icon.sizes.small}
              />
            }
          />
        </JsfUi.CustomField.Cell>
      </JsfUi.CustomField.Body>
    </JsfUi.CustomField.Index>
  );
};

Input.propTypes = {
  size: PropTypes.oneOf(
    Object.values(JsfUi.customField.sizes),
  ),
  onIconClick: PropTypes.func,
  storeRef: PropTypes.func,
  isInvalid: PropTypes.bool,
};

Input.defaultProps = {
  size: JsfUi.customField.sizes.small,
  onIconClick: null,
  storeRef: null,
  isInvalid: false,
};

export default Input;
