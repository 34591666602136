import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as conditionsSelectors from '../selectors';

@connect(
  (state) => {
    return {
      choiseByClickingModeIsActive:
        conditionsSelectors.getChoiseByClickingModeIsActive(state),
    };
  },
)
export default class ChoiceByClickingModeActive extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    choiseByClickingModeIsActive: PropTypes.bool.isRequired,
  };

  render() {
    return this.props.children({
      isChoiseEnabled: this.props.choiseByClickingModeIsActive,
    });
  }
}
