import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import cls from 'classnames';
import { thisDevice } from '@pdffiller/jsf-useragent';

import { setActivePopupMenu, resetPopup } from '../../../../store/modules/viewport';
import forceFocusTextElement from '../../../../store/thunks/forceFocusTextElement';
import { setScrollDisabled } from '../../../../store/modules/events';
import {
  colorKeys,
  popupMenu,
  popupStatuses,
} from '../../../../helpers/const/const';
import PopupMainMenu from './PopupMainMenu';
import ColorPopupSettings from '../../../Tools/ToolbarSettings/Color/Popup/ColorPopup';
import FontFamilyPopupSettings from '../../../Tools/ToolbarSettings/Font/Popup/FontFamilyPopup';
import FontSizePopupSettings from '../../../Tools/ToolbarSettings/FontSize/Popup/FontSizePopup';
import { toolbar, locale } from '../../../../store/selectors';

import * as Ui from '../../../../ui';

export class PopupWrapperSimple extends Component {
  static propTypes = {
    // get from toolbar attributes
    popupToolsCount: PropTypes.number,

    // get toolbar visibility
    hasFont: PropTypes.bool,
    hasColor: PropTypes.bool,
    hasBackgroundColor: PropTypes.bool,
    hasBorderColor: PropTypes.bool,
    hasFillColor: PropTypes.bool,
    hasFontColor: PropTypes.bool,
    hasArrowStyle: PropTypes.bool,
    hasAlign: PropTypes.bool,
    hasVAlign: PropTypes.bool,
    hasHref: PropTypes.bool,
    hasLineWidth: PropTypes.bool,

    // get active element or ghost props
    isFillable: PropTypes.bool.isRequired,
    elementId: PropTypes.string,

    // from global.state
    activeMenu: PropTypes.oneOf(Object.values(popupMenu)),
    popupVisibility: PropTypes.oneOf(Object.values(popupStatuses)),
    locale: PropTypes.shape({
      back: PropTypes.string.isRequired,
    }).isRequired,

    // actions
    setScrollDisabled: PropTypes.func.isRequired,
    setActivePopupMenu: PropTypes.func.isRequired,
    forceFocusTextElement: PropTypes.func.isRequired,
    onHrefModalOpen: PropTypes.func,
    resetPopup: PropTypes.func,

    // from portal
    closePortal: PropTypes.func.isRequired,
  }

  static defaultProps = {
    activeMenu: popupMenu.main,
    hasArrowStyle: false,
    hasBackgroundColor: false,
    hasBorderColor: false,
    hasColor: false,
    hasFont: false,
    hasFillColor: false,
    hasFontColor: false,
    hasAlign: false,
    hasVAlign: false,
    hasHref: false,
    hasLineWidth: false,
    popupVisibility: popupStatuses.hidden,
    onHrefModalOpen: null,
    resetPopup: null,
    elementId: null,
    popupToolsCount: -1,
  }

  disablePageScroll = () => {
    this.props.setScrollDisabled(true);
  }

  enablePageScroll = () => {
    this.props.setScrollDisabled(false);
  }

  stopPropagation = (event) => {
    event.stopPropagation();
  }

  render() {
    const {
      hasFont,
      hasColor,
      hasFontColor,
      hasBackgroundColor,
      hasBorderColor,
      hasFillColor,
      activeMenu,
    } = this.props;

    const popupVisibility = thisDevice.isDesktop
      ? popupStatuses.shown
      : this.props.popupVisibility;

    return (
      <div
        className={cls('slide-content', {
          'is-moved': activeMenu !== popupMenu.main,
        })}
        onClick={this.stopPropagation}
      >
        <div className="slide-content__body">
          {popupVisibility === popupStatuses.shown &&
            <PopupMainMenu
              // from ToolbarAttributesView
              popupToolsCount={this.props.popupToolsCount}

              // props from connect
              locale={this.props.locale}
              hasFont={this.props.hasFont}
              hasColor={this.props.hasColor}
              hasBackgroundColor={this.props.hasBackgroundColor}
              hasBorderColor={this.props.hasBorderColor}
              hasArrowStyle={this.props.hasArrowStyle}
              hasFillColor={this.props.hasFillColor}
              hasFontColor={this.props.hasFontColor}
              hasAlign={this.props.hasAlign}
              hasVAlign={this.props.hasVAlign}
              hasHref={this.props.hasHref}
              hasLineWidth={this.props.hasLineWidth}
              setActivePopupMenu={this.props.setActivePopupMenu}
              onHrefModalOpen={this.props.onHrefModalOpen}
              isFillable={this.props.isFillable}
              elementId={this.props.elementId}
              resetPopup={this.props.resetPopup}
              forceFocusTextElement={this.props.forceFocusTextElement}

              // handlers
              onTouchStart={this.disablePageScroll}
              onTouchEnd={this.enablePageScroll}
            />
          }

          {activeMenu === popupMenu.main &&
            <Ui.Slider.ContentCell />
          }

          {hasFont && activeMenu === popupMenu.fontSize &&
            <FontSizePopupSettings locale={this.props.locale.back} />
          }

          {hasFont && activeMenu === popupMenu.fontFamily &&
            <FontFamilyPopupSettings locale={this.props.locale.back} />
          }

          {hasFontColor && activeMenu === popupMenu.fontColor &&
            <ColorPopupSettings
              popupMenuIndex={popupMenu.fontColor}
              colorKey={colorKeys.fontColor}
              {...this.props}
            />
          }

          {hasBorderColor && activeMenu === popupMenu.borderColor &&
            <ColorPopupSettings
              popupMenuIndex={popupMenu.borderColor}
              colorKey={colorKeys.borderColor}
              {...this.props}
            />
          }

          {hasColor && activeMenu === popupMenu.color &&
            <ColorPopupSettings
              popupMenuIndex={popupMenu.color}
              colorKey={colorKeys.color}
              {...this.props}
            />
          }

          {hasFillColor && activeMenu === popupMenu.fillColor &&
            <ColorPopupSettings
              popupMenuIndex={popupMenu.fillColor}
              colorKey={colorKeys.fillColor}
              locale={this.props.locale}
              closePortal={this.props.closePortal}
            />
          }

          {hasBackgroundColor && activeMenu === popupMenu.backgroundColor &&
            <ColorPopupSettings
              popupMenuIndex={popupMenu.backgroundColor}
              colorKey={colorKeys.bgColor}
              locale={this.props.locale}
              closePortal={this.props.closePortal}
            />
          }
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  const {
    hasFont,
    hasColor,
    hasBackgroundColor,
    hasBorderColor,
    hasFillColor,
    hasFontColor,
    hasArrowStyle,
    hasAlign,
    hasVAlign,
    hasHref,
    hasLineWidth,
  } = toolbar.getToolbarAttributesVisilibity(state);

  const {
    isFillable,
    id: elementId,
  } = toolbar.getElementPropsForToolbar(state);

  return {
    hasFont,
    hasColor,
    hasBackgroundColor,
    hasBorderColor,
    hasFillColor,
    hasFontColor,
    hasArrowStyle,
    hasAlign,
    hasHref,
    hasLineWidth,
    hasVAlign,

    isFillable,
    activeMenu: state.viewport.activeMenu,
    locale: locale.getEditorLocale(state).toolbarAttributes,
    bodyWidth: (state.viewport.body && state.viewport.body.width) || 0,
    popupVisibility: state.viewport.popupVisibility,
    elementId,
  };
};

export default (connect(
  mapStateToProps, {
    setScrollDisabled,
    setActivePopupMenu,
    resetPopup,
    forceFocusTextElement,
  },
)(PopupWrapperSimple));
