import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { thisDevice } from '@pdffiller/jsf-useragent';

import { selectors } from '../..';
import { stopEvent } from '../../helpers/utils';
import { elemTypes } from '../../helpers/elemTypes';
import { allowToCreateTimeoutMs } from '../../helpers/const';

@connect(
  (state) => {
    return {
      activePageId: selectors.base.getActivePageId(state),
      activeElementId: selectors.base.getActiveElementId(state),
      activeTool: selectors.base.getActiveTool(state),
      isCommentsShown: selectors.base.getIsCommentsShown(state),
      isSearchModeActive: selectors.mode.isSearch(state),
      isFConstructorPreviewShown: selectors.base.getIsFConstructorPreviewShown(state),
      isPAConstructorShown: selectors.base.getIsPAConstructorShown(state),
    };
  },
)
export default class GhostKeyboardCreateTrigger extends Component {
  static propTypes = {
    activeElementId: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]).isRequired,
    isGhostHiddenByActiveElement: PropTypes.bool.isRequired,
    activePageId: PropTypes.number.isRequired,
    ghostPageId: PropTypes.number.isRequired,
    activeTool: PropTypes.shape({
      type: PropTypes.oneOf(
        Object.values(elemTypes),
      ).isRequired,
      subType: PropTypes.string.isRequired,
    }).isRequired,
    isSearchModeActive: PropTypes.bool.isRequired,
    isCommentsShown: PropTypes.bool.isRequired,
    isFConstructorPreviewShown: PropTypes.bool.isRequired,
    isPAConstructorShown: PropTypes.bool.isRequired,

    createElementByKeyboard: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.allowToCreateElementsByKeyboard = true;
    this.allowToCreateElementsByKeyboardTimer = null;
  }

  componentDidMount() {
    document.addEventListener('keypress', this.onCharKeyPress);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.activeElementId !== this.props.activeElementId) {
      // Есть 2 fillable поля:
      // ┌─────────┐ ┌─────────┐
      // │    1    │ │    2    │           * курсор мыши здесь
      // └─────────┘ └─────────┘
      // Пользователь вводит в поле 1 очень быстро
      // и когда место в поле 1 закончится произойдет переключение
      // setActiveElement('1', false) - *
      // setActiveElement('2', true)
      //
      // Если в момент когда сюда придет ghostElementId: false
      // пользователь нажмет кнопку (см. onCharKeyPress)
      // в месте где находится курсор будет создан элемент.
      //
      // Код ниже делает задержку чтобы сразу после изменения
      // ghostElementId на false сразу не создавались в течении
      // allowToCreateTimeoutMs
      if (nextProps.activeElementId === false) {
        this.clearAllowToCreateElementsByKeyboardTimer();
        this.allowToCreateElementsByKeyboardTimer = setTimeout(() => {
          this.allowToCreateElementsByKeyboard = true;
        }, allowToCreateTimeoutMs);
      } else {
        this.clearAllowToCreateElementsByKeyboardTimer();
        this.allowToCreateElementsByKeyboard = false;
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.onCharKeyPress);
  }

  clearAllowToCreateElementsByKeyboardTimer = () => {
    if (this.allowToCreateElementsByKeyboardTimer) {
      clearTimeout(this.allowToCreateElementsByKeyboardTimer);
      this.allowToCreateElementsByKeyboardTimer = null;
    }
  };

  onCharKeyPress = (event) => {
    // Add new element if no tools or text tool are selected,
    // no elements are focused and mouse is on the content
    if (
      !this.allowToCreateElementsByKeyboard ||
      this.props.ghostPageId !== this.props.activePageId ||
      this.props.activeElementId ||
      this.props.activeTool.type !== elemTypes.text ||
      this.props.isGhostHiddenByActiveElement ||
      this.props.isSearchModeActive ||
      this.props.isCommentsShown ||
      this.props.isPAConstructorShown ||
      this.props.isFConstructorPreviewShown ||
      // ff fires onkeypress event on non-alphabet buttons, but with charCode === 0
      event.charCode === 0 ||
      // escape button bug in IE
      (thisDevice.isInternetExplorer11 && event.keyCode === 27)
    ) {
      return;
    }

    const text = thisDevice.isFirefoxDesktop
      ? String.fromCharCode(event.charCode)
      : String.fromCharCode(event.keyCode);

    stopEvent(event); // to prevent text insertion on keypress after setActiveElement
    this.props.createElementByKeyboard(event, text);
  };

  render() {
    return null;
  }
}
