import PropTypes from 'prop-types';
import React from 'react';
import * as Ui from 'jsfcore/ui';

const HintIcon = ({ storeRef, onMouseEnter, onMouseLeave }) => {
  return (
    <Ui.Control
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      storeRef={storeRef}

      theme={Ui.control.themes.transparentBlue}
      behavior={Ui.control.behaviors.column}
      inheritRadius
      icon={
        <Ui.Icon
          size={Ui.icon.sizes.extraSmall}
          type={Ui.icon.types.circledQuestion}
        />
      }
    />
  );
};

HintIcon.propTypes = {
  storeRef: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};

export default HintIcon;
