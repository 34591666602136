import uuidv1 from 'uuid/v1';
import getAdditionalDataForRadioBeforeCreate from '../store/thunks/getAdditionalDataForRadioBeforeCreate';
import { elemSubTypes, elemFCTypes } from '../helpers/elemTypes';
import { isSignNow } from '../helpers/utils';
import * as radioAction from './radioActions';

function addNewRadioFillableElements({
  position,
  ghostPageId,
  roleId = undefined,
  ghostElement,
}) {
  return async (dispatch) => {
    const radioAdditionalData = await dispatch(
      getAdditionalDataForRadioBeforeCreate(),
    );

    if (!radioAdditionalData) {
      return;
    }

    dispatch(radioAction.cloneRadioButtonsByValues({
      id: null,
      pageId: ghostPageId,
      type: elemFCTypes.radio,
      subType: isSignNow()
        ? elemSubTypes.none
        : elemSubTypes.radio.x,
      template: {
        ...ghostElement.template,
        ...position,
        radioGroupId: uuidv1(),
        name: radioAdditionalData.groupName,
        required: radioAdditionalData.required,
        alignment: radioAdditionalData.alignment,
        roleId,
      },
    }, radioAdditionalData.groupValues));
  };
}

export default addNewRadioFillableElements;
