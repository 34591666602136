import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getHeaderButtonClasses } from '../../../helpers/elemTypes';
import { getTypeSubType } from '../../../helpers/utils';

import * as Ui from '../../../ui';

const SIGNATURE_SUBTYPE_ALL = '*';

export default class DropDownButton extends Component {
  static propTypes = {
    tool: PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      visible: PropTypes.bool.isRequired,
    }).isRequired,
    useSignNowTheme: PropTypes.bool.isRequired,
    activeTool: PropTypes.shape({
      type: PropTypes.string,
      subType: PropTypes.string,
    }),

    onClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    activeTool: {},
  };

  getIsPressed = () => {
    const { tool, activeTool } = this.props;

    const { type, subType } = getTypeSubType(tool.id);

    return (
      activeTool &&
      type === activeTool.type &&
      (subType === activeTool.subType || subType === SIGNATURE_SUBTYPE_ALL)
    );
  };

  onClick = () => {
    return this.props.onClick(getTypeSubType(this.props.tool.id));
  };

  render() {
    const { tool, useSignNowTheme } = this.props;
    const isPressed = this.getIsPressed();

    return (
      <Ui.Menu.ListItem>
        <Ui.Control
          size={Ui.control.sizes.extraLargeRectangular}
          offset={Ui.control.offsets.medium}
          justify={Ui.control.justifies.center}
          behavior={Ui.control.behaviors.column}
          align={Ui.control.aligns.center}
          icon={
            <Ui.Icon
              type={getHeaderButtonClasses(useSignNowTheme)[tool.id]}
              size={Ui.icon.sizes.medium}
            />
          }
          onClick={this.onClick}
          isPressed={isPressed}
        >
          <Ui.Text
            size={11}
            weight={Ui.text.weights.bold}
            offset={Ui.text.offsets.topExtraSmall}
          >
            {tool.label}
          </Ui.Text>
        </Ui.Control>
      </Ui.Menu.ListItem>
    );
  }
}
