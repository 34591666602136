import React from 'react';

const style = {
  display: 'none',
};

// eslint-disable-next-line import/prefer-default-export
export const nullComponent = () => {
  return <div style={style} />;
};
