import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { wizard, LazyComponent } from '@pdffiller/jsf-lazyload';
import * as lazySelectors from '@pdffiller/jsf-lazyload/store/selectors';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { cancelVersionsMode } from 'ws-editor-lib/actions';

import { wizardFocusSelector } from '../../helpers/selectors';
import { isFillable } from '../../store/helpers/functions';
import {
  triggerDeclineButtonClick,
  triggerDraftButtonClick,
  triggerMessageButtonClick,
} from '../../store/modules/events';
import ToolbarAttributes from '../Toolbar/ToolbarAttributes/ToolbarAttributes';
import HeaderView from './HeaderView';
import HeaderPhone from './HeaderPhone';
import { selectors } from '../..';
import { constructorButtonIds } from '../../helpers/const';

@connect(
  (state) => {
    const activeElement = selectors.elements.getActiveElement(state);

    return {
      haveWizardFocus: !!wizardFocusSelector(state),
      activeElementId: selectors.base.getActiveElementId(state),
      isFillable: isFillable(activeElement),
      isMessageButtonVisible: state.viewport.isMessageButtonVisible,
      fConstructorLoadedAndShown: selectors.mode.isConstructor.includingPreview(state),
      paConstructorLoadedAndShown: selectors.mode.isPageAttributes(state),
      isFConstructorPreviewShown: selectors.mode.isPreview(state),
      isVersionsMode: selectors.mode.isVersions(state),
      isCommentsLoaded: lazySelectors.jsf.getIsCommentsLoaded(state),
      isWizardLoaded: lazySelectors.common.getIsWizardLoaded(state),
      appStarted: selectors.base.getAppStarted(state),
      isModesPanelVisible: selectors.getIsModesPanelVisible(state),
      isBreadcrumbsVisible:
        selectors.getIsButtonVisible(state, constructorButtonIds.breadcrumbs),
      isCancelButtonVisible:
        selectors.getIsButtonVisible(state, constructorButtonIds.cancel),
      isPreviewButtonVisible:
        selectors.getIsButtonVisible(state, constructorButtonIds.preview),
      isSaveButtonVisible:
        selectors.getIsButtonVisible(state, constructorButtonIds.save),
      versionsLocale: selectors.locale.getVersionsLocale(state),
    };
  }, {
    triggerDeclineButtonClick,
    triggerDraftButtonClick,
    triggerMessageButtonClick,
    cancelVersionsMode,
  },
)
export default class Header extends Component {
  static propTypes = {

    // from global.state
    isMessageButtonVisible: PropTypes.bool.isRequired,
    activeElementId: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]).isRequired,
    haveWizardFocus: PropTypes.bool.isRequired,
    isFillable: PropTypes.bool.isRequired,

    triggerMessageButtonClick: PropTypes.func.isRequired,
    triggerDeclineButtonClick: PropTypes.func.isRequired,
    triggerDraftButtonClick: PropTypes.func.isRequired,
    paConstructorLoadedAndShown: PropTypes.bool.isRequired,
    fConstructorLoadedAndShown: PropTypes.bool.isRequired,
    isFConstructorPreviewShown: PropTypes.bool.isRequired,
    isVersionsMode: PropTypes.bool.isRequired,
    isCommentsLoaded: PropTypes.bool.isRequired,
    isWizardLoaded: PropTypes.bool.isRequired,
    appStarted: PropTypes.bool.isRequired,
    isModesPanelVisible: PropTypes.bool.isRequired,
    isBreadcrumbsVisible: PropTypes.bool.isRequired,
    isCancelButtonVisible: PropTypes.bool.isRequired,
    isPreviewButtonVisible: PropTypes.bool.isRequired,
    isSaveButtonVisible: PropTypes.bool.isRequired,
    versionsLocale: PropTypes.shape({}).isRequired,
    cancelVersionsMode: PropTypes.func.isRequired,

    // property for a phone
    isThumbnailsMounted: PropTypes.bool.isRequired,
  };

  onClickDecline = () => {
    this.props.triggerDeclineButtonClick();
  };

  onClickDraft = () => {
    this.props.triggerDraftButtonClick();
  };

  onClickMessage = () => {
    this.props.triggerMessageButtonClick();
  };

  onClickHeaderCloseButton = () => {
    this.props.cancelVersionsMode();
  };

  renderDesktopTablet = () => {
    return (
      <HeaderView
        isMessageButtonVisible={this.props.isMessageButtonVisible}
        onClickMessage={this.onClickMessage}
        onClickDecline={this.onClickDecline}
        onClickDraft={this.onClickDraft}
        paConstructorLoadedAndShown={this.props.paConstructorLoadedAndShown}
        fConstructorLoadedAndShown={this.props.fConstructorLoadedAndShown}
        isFConstructorPreviewShown={this.props.isFConstructorPreviewShown}
        isVersionsMode={this.props.isVersionsMode}
        activeElementId={this.props.activeElementId}
        isCommentsLoaded={this.props.isCommentsLoaded}
        isWizardLoaded={this.props.isWizardLoaded}
        appStarted={this.props.appStarted}
        isModesPanelVisible={this.props.isModesPanelVisible}
        isBreadcrumbsVisible={this.props.isBreadcrumbsVisible}
        isCancelButtonVisible={this.props.isCancelButtonVisible}
        isPreviewButtonVisible={this.props.isPreviewButtonVisible}
        isSaveButtonVisible={this.props.isSaveButtonVisible}
        versionsLocale={this.props.versionsLocale}
        onClickHeaderCloseButton={this.onClickHeaderCloseButton}
      />
    );
  };

  renderPhone = () => {
    return (
      <HeaderPhone
        appStarted={this.props.appStarted}
        isThumbnailsMounted={this.props.isThumbnailsMounted}
      />
    );
  };

  render() {
    // TODO: Компонент рендерит или HeaderView или ToolbarAttributesMobile
    // хотя эти компоненты вообще не связаны логически, необходим рефакторинг
    if (thisDevice.isPhone && this.props.activeElementId) {
      return (
        <ToolbarAttributes key={this.props.activeElementId} />
      );
    }

    if (
      thisDevice.isTablet &&
      this.props.isWizardLoaded &&
      this.props.haveWizardFocus &&
      this.props.isFillable
    ) {
      return (
        <LazyComponent literal={wizard.components.wizardHeaderNavigation} />
      );
    }

    return (
      thisDevice.isPhone
        ? this.renderPhone()
        : this.renderDesktopTablet()
    );
  }
}
