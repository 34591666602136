import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { thisDevice } from '@pdffiller/jsf-useragent';
import HeaderModesPanel from 'jsfcore/components/Header/HeaderModesPanel/HeaderModesPanel';
import { fConstructor, versions, LazyComponent } from '@pdffiller/jsf-lazyload';
import { isTabletWithActiveElement } from '../../helpers/utils';
import {
  messageButtonHeaderId,
  mainHeaderId,
} from '../../helpers/const';
import * as Ui from '../../ui';

import HeaderTools from './HeaderTools/HeaderTools';
import HeaderZoom from './HeaderZoom/HeaderZoom';
import HeaderHelp from './HeaderHelp/HeaderHelp';
import HeaderDone from './HeaderDone/HeaderDone';
import HeaderLogo from './HeaderLogo/HeaderLogo';
import HeaderSearch from './HeaderSearch/HeaderSearch';
import HeaderComments from './HeaderComments/HeaderComments';
import HeaderWizard from './HeaderWizard/HeaderWizard';

import { HeaderFeedback } from '.';
import TabletOkBtn from '../Toolbar/ToolbarAttributes/Tablet/TabletOkBtn';
import ToolbarAttributes from '../Toolbar/ToolbarAttributes/ToolbarAttributes';
import HeaderDoneActionCreator from './HeaderDone/HeaderDoneActionCreator';
import HeaderCloseButton from './HeaderCloseButton/HeaderCloseButton';
import HeaderDoneVisibilityProvider from './HeaderDone/HeaderDoneVisibilityProvider';
import HeaderFakeEdit from './HeaderFakeEdit/HeaderFakeEdit';

export default class HeaderView extends Component {
  static propTypes = {
    onClickMessage: PropTypes.func.isRequired,
    isMessageButtonVisible: PropTypes.bool.isRequired,
    paConstructorLoadedAndShown: PropTypes.bool.isRequired,
    fConstructorLoadedAndShown: PropTypes.bool.isRequired,
    isFConstructorPreviewShown: PropTypes.bool.isRequired,
    isVersionsMode: PropTypes.bool.isRequired,
    activeElementId: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]).isRequired,
    appStarted: PropTypes.bool.isRequired,
    isModesPanelVisible: PropTypes.bool.isRequired,
    isBreadcrumbsVisible: PropTypes.bool.isRequired,
    isCancelButtonVisible: PropTypes.bool.isRequired,
    isPreviewButtonVisible: PropTypes.bool.isRequired,
    isSaveButtonVisible: PropTypes.bool.isRequired,
    isWizardLoaded: PropTypes.bool.isRequired,
    versionsLocale: PropTypes.shape({
      header: PropTypes.shape({
        buttons: PropTypes.shape({
          close: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      breadcrumbs: PropTypes.shape({}).isRequired,
    }).isRequired,
    onClickHeaderCloseButton: PropTypes.func.isRequired,
  };

  get isAnyConstructorShown() {
    const { fConstructorLoadedAndShown, paConstructorLoadedAndShown } = this.props;
    return fConstructorLoadedAndShown || paConstructorLoadedAndShown;
  }

  get isAnyButtonVisible() {
    const { isCancelButtonVisible, isSaveButtonVisible } = this.props;
    return isCancelButtonVisible || isSaveButtonVisible;
  }

  get isNeedToRenderHeaderButtons() {
    const {
      isVersionsMode,
      isCancelButtonVisible,
    } = this.props;
    return (this.isAnyConstructorShown && this.isAnyButtonVisible) ||
    (isVersionsMode && isCancelButtonVisible);
  }

  renderBreadCrumbs = () => {
    const {
      isFConstructorPreviewShown,
      isVersionsMode,
      isBreadcrumbsVisible,
      versionsLocale,
    } = this.props;

    if (!isBreadcrumbsVisible) {
      return null;
    }

    if (this.isAnyConstructorShown) {
      const props = { previewModeActive: isFConstructorPreviewShown };
      return (
        <LazyComponent
          literal={fConstructor.components.headerBreadcrumbs}
          props={props}
        />
      );
    }

    if (isVersionsMode) {
      const props = {
        onClickEditor: this.props.onClickHeaderCloseButton,
        locale: versionsLocale.breadcrumbs,
      };
      return (
        <LazyComponent
          literal={versions.components.headerBreadcrumbs}
          props={props}
        />
      );
    }

    return null;
  };

  renderHeaderButtons = () => {
    const {
      isVersionsMode,
      isCancelButtonVisible,
      isSaveButtonVisible,
      versionsLocale,
    } = this.props;

    if (this.isAnyConstructorShown && this.isAnyButtonVisible) {
      const props = {
        isCancelButtonVisible,
        isSaveButtonVisible,
      };
      return (
        <Ui.Container.GridCellAndWrapper>
          <LazyComponent
            literal={fConstructor.components.headerButtons}
            props={props}
          />
        </Ui.Container.GridCellAndWrapper>
      );
    }

    if (isVersionsMode && isCancelButtonVisible) {
      return (
        <Ui.Container.GridCellAndWrapper>
          <HeaderCloseButton
            locale={versionsLocale.header.buttons.close}
            onClick={this.props.onClickHeaderCloseButton}
          />
        </Ui.Container.GridCellAndWrapper>
      );
    }

    return null;
  };

  render() {
    const {
      isMessageButtonVisible,
      fConstructorLoadedAndShown,
      isFConstructorPreviewShown,
      paConstructorLoadedAndShown,
      isVersionsMode,
      isModesPanelVisible,
      isPreviewButtonVisible,
      isWizardLoaded,
    } = this.props;

    const isAnyConstructorOrVersionsShown = this.isAnyConstructorShown || isVersionsMode;
    const isTabletWithActiveEl = isTabletWithActiveElement(this.props.activeElementId);
    const isHeaderSearchVisible =
      !paConstructorLoadedAndShown && !isFConstructorPreviewShown && !isVersionsMode;
    const showGridSeparator = (fConstructorLoadedAndShown && !isFConstructorPreviewShown) ||
    (!isAnyConstructorOrVersionsShown && !isTabletWithActiveEl);
    // For tablet - zoom, help и done
    return (
      <Ui.Grid.CellGroup
        id={mainHeaderId}
        style={{ pointerEvents: this.props.appStarted
          ? 'auto'
          : 'none',
        }}
        type={Ui.grid.cellGroup.types.topLayer}
      >
        <Ui.Grid.Cell>
          <HeaderLogo />
        </Ui.Grid.Cell>

        <Ui.Grid.Separator />

        {isModesPanelVisible && (
          <HeaderModesPanel />
        )}

        {!isAnyConstructorOrVersionsShown && !isTabletWithActiveEl &&
          <HeaderTools />
        }

        {isTabletWithActiveEl &&
          <ToolbarAttributes useTabletView />
        }

        {this.renderBreadCrumbs()}

        <Ui.Grid.Separator />

        <Ui.Container.GridCellAndWrapper>
          <Ui.ToolBar.Body
            size={Ui.toolBar.sizes.extraLarge}
            type={Ui.toolBar.types.common}
          >
            <Ui.ToolBar.Main>
              {isMessageButtonVisible && thisDevice.isTablet &&
                <Ui.Container.SettingButton
                  icon={Ui.icon.types.smsThin}
                  label="Message"
                  title="Message"
                  size={Ui.icon.sizes.large}
                  textOffset={Ui.text.offsets.topExtraSmall}
                  onClick={this.props.onClickMessage}
                  id={messageButtonHeaderId}
                />
              }

              {(
                !thisDevice.isTablet &&
                isWizardLoaded &&
                !isAnyConstructorOrVersionsShown
              ) &&
                <HeaderWizard />
              }

              {!isAnyConstructorOrVersionsShown && !thisDevice.isTablet &&
                <HeaderFakeEdit />
              }
              {!isAnyConstructorOrVersionsShown && !thisDevice.isTablet &&
                <HeaderComments />
              }
              {isHeaderSearchVisible && !thisDevice.isTablet && <HeaderSearch />}
              {<HeaderZoom />}
              {!fConstructorLoadedAndShown && <HeaderHelp />}
              {!isAnyConstructorOrVersionsShown && <HeaderFeedback />}
            </Ui.ToolBar.Main>
          </Ui.ToolBar.Body>
        </Ui.Container.GridCellAndWrapper>

        {(paConstructorLoadedAndShown || isVersionsMode) && <Ui.Grid.Separator />}

        {fConstructorLoadedAndShown && isPreviewButtonVisible &&
          <LazyComponent
            literal={fConstructor.components.previewButtons}
            props={{ previewModeActive: isFConstructorPreviewShown }}
          />
        }

        {showGridSeparator && <Ui.Grid.Separator />}

        {!isAnyConstructorOrVersionsShown && !isTabletWithActiveEl &&
          <HeaderDoneVisibilityProvider>
            <HeaderDoneActionCreator>
              {({ onDoneClick, onOptionClick }) => {
                return (
                  <HeaderDone
                    onDoneClick={onDoneClick}
                    onOptionClick={onOptionClick}
                  />
                );
              }}
            </HeaderDoneActionCreator>
          </HeaderDoneVisibilityProvider>
        }

        {isTabletWithActiveEl &&
          <TabletOkBtn />
        }

        {this.isNeedToRenderHeaderButtons &&
          this.renderHeaderButtons()
        }
      </Ui.Grid.CellGroup>
    );
  }
}
