import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import clamp from 'lodash/clamp';
import isFinite from 'lodash/isFinite';
import TimeView from './TimeView';
import {
  getHoursFormat,
  getHoursOffset,
  roundHoursTo24,
  roundHoursTo12,
  roundMinutesTo60,
  isPM,
} from '../../datePickerUtils';

// export for tests
export const getClampedInputVal = (value, upperBound) => {
  return String(clamp(Number(value), 0, upperBound));
};

export default class Time extends Component {
  static propTypes = {
    date: PropTypes.instanceOf(moment).isRequired,
    onChange: PropTypes.func.isRequired,
    is24Format: PropTypes.bool,
  };

  static defaultProps = {
    is24Format: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      inputHours: '',
      inputMinutes: '',
      isTyping: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { date, is24Format } = nextProps;
    if (prevState) {
      // when user is typing, we manually control
      // what he will see in inputs
      if (prevState.isTyping) {
        return null;
      }
    }

    return {
      inputHours: date.format(getHoursFormat(is24Format)),
      inputMinutes: date.format('mm'),
    };
  }

  applyTime({
    inputHours = this.props.date.hours(),
    inputMinutes = this.props.date.minutes(),
  }) {
    const { date } = this.props;

    const inputHoursNumber = Number(inputHours);
    const inputMinutesNumber = Number(inputMinutes);

    const propMinutes = date.minutes();
    const propHours = date.hours();

    const applyMinutes = inputMinutes !== '' && isFinite(inputMinutesNumber)
      ? inputMinutes
      : propMinutes;

    const applyHours = inputHours !== '' && isFinite(inputHoursNumber)
      ? inputHours
      : propHours;

    if (applyMinutes !== propMinutes || applyHours !== propHours) {
      this.props.onChange(
        date
          .clone()
          .hours(applyHours)
          .minutes(applyMinutes),
      );
    }
  }

  sumHours(delta) {
    return () => {
      const { date } = this.props;
      const hours = date.hours();
      if (this.props.is24Format) {
        this.applyTime({
          inputHours: roundHoursTo24(hours + delta),
        });
      } else {
        this.applyTime({
          inputHours: roundHoursTo12(hours + delta, getHoursOffset(date)),
        });
      }
    };
  }

  sumMinutes(delta) {
    return () => {
      const minutes = this.props.date.minutes();
      this.applyTime({
        inputMinutes: roundMinutesTo60(minutes + delta),
      });
    };
  }

  onPlusHours = this.sumHours(1);

  onMinusHours = this.sumHours(-1);

  onPlusMinutes = this.sumMinutes(1);

  onMinusMinutes = this.sumMinutes(-1);

  onFocusHours = () => {
    this.setState({
      isTyping: true,
      inputHours: '',
    });
  };

  onFocusMinutes = () => {
    this.setState({
      isTyping: true,
      inputMinutes: '',
    });
  };

  onChangeHours = (event) => {
    const { date } = this.props;
    const { value: strValue } = event.target;
    const value = Number(strValue);

    if (strValue === '') {
      this.setState({
        inputHours: '',
      });
      return;
    }

    if (this.props.is24Format) {
      const inputHours = getClampedInputVal(value, 23);
      const arg = { inputHours };
      this.applyTime(arg);
      this.setState(arg);

      return;
    }

    // show 1 instead of 13 in 12 hours format
    if (value > 12) {
      const inputHours = getClampedInputVal(value, 23);
      this.applyTime({ inputHours });
      this.setState({
        inputHours: String(value - 12),
      });
      return;
    }

    this.applyTime({
      inputHours: roundHoursTo12(value, getHoursOffset(date)),
    });


    // there is no "0 am" or "0 pm"
    if (value === 0) {
      this.setState({
        inputHours: '12',
      });
      return;
    }

    this.setState({
      inputHours: strValue,
    });
  };

  onChangeMinutes = (event) => {
    const { value } = event.target;

    if (value === '') {
      this.setState({
        inputMinutes: '',
      });
      return;
    }

    const inputMinutes = getClampedInputVal(value, 59);
    const arg = { inputMinutes };
    this.applyTime(arg);
    this.setState(arg);
  };

  onBlurAnyInput = () => {
    this.setState({
      isTyping: false,
    });
  }

  onToggleAMPM = () => {
    const hours = this.props.date.hours();
    this.applyTime({
      inputHours: roundHoursTo24(hours + 12),
    });
  };

  onCurrentTimeClick = () => {
    const now = moment().seconds(0);
    this.props.onChange(now);
  };

  render() {
    return (
      <TimeView
        isPM={isPM(this.props.date)}
        is24Format={this.props.is24Format}
        inputMinutes={this.state.inputMinutes}
        inputHours={this.state.inputHours}
        onChangeHours={this.onChangeHours}
        onChangeMinutes={this.onChangeMinutes}
        onFocusHours={this.onFocusHours}
        onFocusMinutes={this.onFocusMinutes}
        onBlurHours={this.onBlurAnyInput}
        onBlurMinutes={this.onBlurAnyInput}
        onToggleAMPM={this.onToggleAMPM}
        onCurrentTimeClick={this.onCurrentTimeClick}

        onPlusHours={this.onPlusHours}
        onMinusHours={this.onMinusHours}
        onPlusMinutes={this.onPlusMinutes}
        onMinusMinutes={this.onMinusMinutes}
      />
    );
  }
}
