import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { getIsDoneButtonVisible } from '../../../store/selectors';

@connect(
  (state) => {
    return {
      isDoneButtonVisible: getIsDoneButtonVisible(state),
    };
  },
)

export default class HeaderDoneVisibilityProvider extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,

    isDoneButtonVisible: PropTypes.bool.isRequired,
  };

  render() {
    const { isDoneButtonVisible, children } = this.props;

    if (isDoneButtonVisible) {
      return children;
    }

    return null;
  }
}
