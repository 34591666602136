import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as selectors from '../../../store/selectors';
import { signingSessionShow, signingSessionHide } from '../../../store/modules/viewport';
import { getIsReadOnlyOverlayEnabled, getIsWizardOverlayEnabled } from '../../../helpers/utils';
import HeaderToolsView from './HeaderToolsView';
import HeaderToolsOverlayView from './HeaderToolsOverlayView';
import ActivateToolProvider from './ActivateToolProvider';
import ClickToolProvider from './ClickToolProvider';

const emptyObject = {};

const getIsToolbarExist = (toolbar) => {
  if (!toolbar) {
    return false;
  }
  const filteredToolbar = toolbar.filter(({ visible }) => {
    return visible;
  });

  return filteredToolbar.length > 0;
};

@connect(
  (state) => {
    return {
      toolbar: state.ws.features.toolbar || false,
      locale: selectors.locale.getEditorLocale(state),
      toolbarOverlays: state.viewport.toolbarOverlays,
      appStarted: state.events.appStarted,
      useSignNowTheme: selectors.getUseSignNowTheme(state),
      isSigningSessionShown: selectors.base.getIsSigningSessionShown(state),
      isSigningSessionMode: selectors.getIsSigningSessionMode(state),
    };
  }, {
    signingSessionShow,
    signingSessionHide,
  },
)
export default class HeaderTools extends Component {
  static propTypes = {
    // from ws state
    appStarted: PropTypes.bool.isRequired,
    toolbarOverlays: PropTypes.arrayOf(PropTypes.string).isRequired,
    toolbar: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        visible: PropTypes.bool.isRequired,
      })),
      PropTypes.bool,
    ]).isRequired,
    locale: PropTypes.shape({
      labels: PropTypes.object.isRequired,
      headerTools: PropTypes.object.isRequired,
      editorInfo: PropTypes.object.isRequired,
    }).isRequired,
    useSignNowTheme: PropTypes.bool.isRequired,
    isSigningSessionShown: PropTypes.bool.isRequired,
    isSigningSessionMode: PropTypes.bool.isRequired,
    signingSessionShow: PropTypes.func.isRequired,
    signingSessionHide: PropTypes.func.isRequired,
  };

  render() {
    const {
      toolbarOverlays, appStarted, toolbar, locale,
      useSignNowTheme, isSigningSessionShown, isSigningSessionMode,
    } = this.props;

    const isToolbarExist = getIsToolbarExist(toolbar);
    const showHeaderOverlay = (
      getIsReadOnlyOverlayEnabled(toolbarOverlays) ||
      getIsWizardOverlayEnabled(toolbarOverlays) ||
      (isSigningSessionMode && isSigningSessionShown) ||
      !isToolbarExist
    );


    if (showHeaderOverlay) {
      return (
        <HeaderToolsOverlayView
          toolbarOverlays={toolbarOverlays}
          locale={locale.editorInfo}
          isToolbarExist={isToolbarExist}
          signingSessionHide={this.props.signingSessionHide}
        />
      );
    }

    return (
      <ActivateToolProvider>
        {({
          activeTool,
          activeElementId,
          wizardActive,
          haveWizardFocus,
          activateTool,
          toolDraggingStarted,
          toolDraggingStopped,
          setActiveElement,
          setIsSearchModeActive,
        }) => {
          return (
            <ClickToolProvider
              activeTool={activeTool}
              activateTool={activateTool}
              wizardActive={wizardActive}
              haveWizardFocus={haveWizardFocus}
              activeElementId={activeElementId}
              setActiveElement={setActiveElement}
              setIsSearchModeActive={setIsSearchModeActive}
              toolDraggingStarted={toolDraggingStarted}
              toolDraggingStopped={toolDraggingStopped}
            >
              {({ onMouseDown, onMoreButtonClick }) => {
                return (
                  <HeaderToolsView
                    appStarted={appStarted}
                    toolbar={toolbar.filter((item) => {
                      return item.visible;
                    })}
                    activeTool={
                      !wizardActive
                        ? activeTool
                        : emptyObject
                    }
                    onMouseDown={onMouseDown}
                    onMoreButtonClick={onMoreButtonClick}
                    locale={locale.headerTools}
                    labels={locale.labels.toolbar}
                    useSignNowTheme={useSignNowTheme}
                    signingSessionShow={this.props.signingSessionShow}
                    isSigningSessionOverlayEnabled={isSigningSessionMode}
                  />
                );
              }}
            </ClickToolProvider>
          );
        }}
      </ActivateToolProvider>
    );
  }
}
