import React from 'react';
import classnames from 'classnames';
import floor from 'lodash/floor';
import clamp from 'lodash/clamp';
import get from 'lodash/get';

import { thisDevice } from '@pdffiller/jsf-useragent';

import isSignNow from '../../../helpers/isSignNow';
import TextToolView from '../TextTool/TextToolView';
import {
  getCaretContentEditable,
  getStyle,
  setCaretContentEditable,
  textFromContentEditable,
  textToContentEditable,
  textToContentEditableChrome,
} from './cellsToolUtils';
import { selectors } from '../../..';
import ElementIconWrapper, { styleForIconWrapper } from '../../Element/ElementIcon/ElementIconWrapper';

class CellsToolView extends TextToolView {
  static displayName = 'CellsToolView';

  static defaultProps = {
    enableSpellCheck: false,
  }

  constructor(props) {
    super(props);

    this.skipIE11Observer = false;
    this.caretContentEditableTimeout = null;

    // В chrome (desktop, android) мы используем inline-block для работы ячеек,
    // в остальных браузерах - inline + padding
    // Если использовать padding, то на IOS каретка выставляется только в начало или в конец поля
    this.cellsUsePaddings =
      !thisDevice.isChromeDesktop &&
      !thisDevice.isChromeAndroid &&
      !thisDevice.isSafariPhone;
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);
    if (this.cellsUsePaddings) {
      this.setCellsPadding();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    super.UNSAFE_componentWillReceiveProps(nextProps);
    this.toggleCaretByTodolist(nextProps);
  }

  componentWillUnmount() {
    // need clearing timeout because sometime if we're type text fast function getInputNode
    // return null inside the timeout => because component was unmounted already
    clearTimeout(this.caretContentEditableTimeout);
    this.undelegateEvents();
  }

  /**
   * При открытии todoList'a на Android над todoList'ом
   * видна каретка. Для предотвращения этого - запомним позицию каретки
   * и заблюрим элемент. После закрытия todoList'a восстановим каретку в
   * запомненную позицию
   */
  toggleCaretByTodolist = ({ isActiveElement, isMobileWizardTodoListActive }) => {
    if (
      !isActiveElement ||
      isMobileWizardTodoListActive === this.props.isMobileWizardTodoListActive
    ) {
      return;
    }

    if (isMobileWizardTodoListActive) {
      // Запомним позицию каретки и уберем ее
      this.caret = this.getCaret();
    } else {
      // Восстановим каретку в запомненную позицию
      if (!this.caret) {
        return;
      }
      setCaretContentEditable(this.getInputNode(), this.caret);
      this.caret = false;
    }
  };

  /**
   * Для ячеек cells используются inline элементы c padding'ом. Это сделано потому,
   * что при использованиии inline-block с width есть баг - кликаем на символ, и каретка
   * встает в неверную позицию (-1 символ от позиции клика)
   *
   * TODO: функция требует оптимизации. Нужно кешировать letterWidth, и сбрасывать
   * при изменениее scale (другие настройки для fillable поля изменится не могут)
   */
  setCellsPadding = () => {
    const cellWidth = this.getCellWidth();
    const { children } = this.getInputNode();
    for (let index = 0; index < children.length; index++) {
      children[index].style.paddingLeft = '0px';
      children[index].style.paddingRight = '0px';

      // for space sign in Safari need set paddings = 0 in other cases,
      // safari will not set caret and will not allow you input text in the field
      if (thisDevice.isSafariDesktop && this.props.text === ' ') {
        children[index].style.paddingLeft = '0px';
        children[index].style.paddingRight = '0px';
        return;
      }

      const letterWidth = children[index].getBoundingClientRect().width / this.getScale();
      const padding = `${(cellWidth - letterWidth) / 2}px`;
      children[index].style.paddingLeft = padding;
      children[index].style.paddingRight = children.length - 1 !== index
        ? padding
        : '0px';

      // В IE11 onChange мы получаем через MutationObserver, и он немного туповатый
      // поэтому при быстром вводе после последней ячейки в поле может вместится
      // еще несколько символов, (в ws-editor-lib они не уходят) - просто сдвинем их
      if (children.length - 1 === index && thisDevice.isInternetExplorer11) {
        children[index].style.marginRight = padding;
      }
    }
  };

  changeProxy = () => {
    if (!this.props.isActiveElement) {
      this.forceRestoreText();
      return;
    }

    const event = { target: { value: textFromContentEditable(this.getInputNode()) } };
    const isChanged =
      this.props.onChange(event, { caretPosition: this.getCaret() });
    if (isChanged === false) {
      this.forceRestoreText();
    }
  };

  getCaret = () => {
    return getCaretContentEditable(this.getInputNode());
  };

  getScale = () => {
    return selectors.getScale(
      this.context.store.getState(),
      this.props.pageId,
    );
  };

  forceRestoreText = () => {
    this.getInputNode().innerHTML = this.getText();

    // В Safari для iOS отключен переключение поля по переполнению, и при попытке ввода
    // сверх размера поля, при каждом последующем вводе каретка будет сдвигатся на
    // одну позицию влево
    // SignNow: в snfiller тоже отключено переключение поля по переполнению
    if (thisDevice.isSafariPhone || isSignNow()) {
      this.forceCaret();
    }
    if (this.cellsUsePaddings) {
      this.setCellsPadding();
    }
  };

  forceCaret = () => {
    const { forceCaretPosition, text, validator } = this.props;
    if (forceCaretPosition) {
      const existingPosition = clamp(forceCaretPosition.caretPosition, 0, text.length);
      const node = this.getInputNode();
      if (get(validator, 'autoFormatPlaceholders')) {
        // SNF-1679:autoformatted field is changed on focus; therefore loses caret.
        // So we need wait a frame, and set caret to content with 'focus'-text
        requestAnimationFrame(() => {
          setCaretContentEditable(node, existingPosition);
        });
      } else {
        setCaretContentEditable(node, existingPosition);
      }
    }
  };

  getCellWidth = () => {
    return floor(this.props.width / this.props.maxChars, 1);
  }

  getText = () => {
    const nbspChar = String.fromCharCode(160);
    const viewText = this.props.text.replace(/ /g, nbspChar);
    return this.cellsUsePaddings
      ? textToContentEditable(viewText)
      : textToContentEditableChrome(viewText, this.getCellWidth());
  }

  getHtml = () => {
    return {
      __html: this.getText(),
    };
  }

  delegateEvents = () => {
    if (!this.isEventDelegated) {
      this.isEventDelegated = true;
      document.addEventListener('keydown', this.keydownProxy);

      // Start mutation observer for Edge
      // При очистке поля
      // * backspace одного символа
      // * ctrl+a + backspace
      // в edge не срабатывает input.
      // Поэтому следим за этим через MutationObserver
      if (thisDevice.isEdgeDesktop) {
        this.observer = new MutationObserver(() => {
          const text = textFromContentEditable(this.getInputNode());
          if (this.props.text !== text && text === '') {
            this.changeProxy();
          }
        });
        const observerOptions = { childList: true, characterData: true, subtree: true };
        this.observer.observe(this.getInputNode(), observerOptions);
      }

      // Start mutation observer for IE11
      if (thisDevice.isInternetExplorer11) {
        this.observer = new MutationObserver(() => {
          if (this.props.text !== textFromContentEditable(this.getInputNode())) {
            this.changeProxy();
          }
        });

        const observerOptions = { childList: true, characterData: true, subtree: true };
        this.observer.observe(this.getInputNode(), observerOptions);
      }
    }
  };

  caretToEnd = () => {
    this.caretContentEditableTimeout = setTimeout(
      () => {
        setCaretContentEditable(this.getInputNode(), this.props.text.length);
      },

      // TODO: плохой код, но с задержкой в 40мс больше шансов, что каретка встанет
      // в нужную позицию
      thisDevice.isInternetExplorer11
        ? 40
        : 0,
    );
  }

  undelegateEvents = () => {
    if (this.isEventDelegated) {
      this.isEventDelegated = false;
      document.removeEventListener('keydown', this.keydownProxy);
      // Stop mutation observer for IE11
      if (thisDevice.isInternetExplorer11 || thisDevice.isEdgeDesktop) {
        document.removeEventListener('keydown', this.skipIE11MutationObserver);
        this.observer.disconnect();
      }
    }
  };

  pasteProxy = (event) => {
    const clipboardText = event.clipboardData.getData(
      thisDevice.isIOS
        ? 'text/plain'
        : 'text',
    );

    const selectionTextLength = window.getSelection().toString().length;
    const caretPos = this.getCaret();

    event.preventDefault();

    if (selectionTextLength === 0) {
      this.props.onPaste({
        selectionStart: caretPos, selectionEnd: caretPos, clipboardText,
      });
    } else {
      this.props.onPaste({
        selectionStart: caretPos - selectionTextLength, selectionEnd: caretPos, clipboardText,
      });
    }
  };

  render() {
    const { isFillable, isActiveElement, isReadonly, isDisabled } = this.props;
    const isForContentEditable = true;
    const classes = [
      'cellsTool-CellsTool',
      (isReadonly || !isActiveElement)
        ? 'selectionDisabled-CellsTool'
        : 'selectionEnabled-CellsTool',
      {
        'edit-CellsTool': !isFillable && isActiveElement && !isDisabled,
        'elementHover-Content': !isDisabled,
      },
    ];

    const divStyle = getStyle(this.props, !isForContentEditable);
    const textareaStyle = getStyle(this.props, isForContentEditable);
    const html = this.getHtml();

    if (this.props.children) {
      return this.props.children({
        isFillable,
        isActiveElement,
        isReadonly,
        isDisabled,
        divStyle,
        textareaStyle,
        storeWrapperRef: this.storeWrapperRef,
        storeInputRef: this.storeInputRef,
        text: html,
        cellsText: this.props.text,
        onPaste: this.pasteProxy,
        onChange: this.changeProxy,
        tabIndex: this.getTabIndex(),
        icon: this.props.icon,

        onFocus: this.props.onFocus,
        onBlur: this.props.onBlur,
        onMouseDown: this.onMouseDown,
        interceptFocusDataset: this.props.interceptFocusDataset,
      });
    }

    return (
      <div
        className={classnames(...classes)}
        style={getStyle(this.props, !isForContentEditable)}
        ref={this.storeWrapperRef}
      >
        <div
          // Content passed to dangerouslySetInnerHTML is sanitized HTML
          dangerouslySetInnerHTML={html} // eslint-disable-line
          className="fillable-field__fake-input"
          contentEditable
          style={getStyle(this.props, isForContentEditable)}
          readOnly={this.props.isReadonly}
          onPaste={this.pasteProxy}
          onInput={this.changeProxy}
          ref={this.storeInputRef}
          autoCapitalize="off"
          autoCorrect="off"
          spellCheck={false}
          tabIndex={this.getTabIndex()}

          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
          onMouseDown={this.onMouseDown}
          {...this.props.interceptFocusDataset}
        />
        {this.props.icon && (
          <ElementIconWrapper style={styleForIconWrapper}>
            {this.props.icon}
          </ElementIconWrapper>
        )}
      </div>
    );
  }
}

export default CellsToolView;
