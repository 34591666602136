import setActiveElement from 'jsfcore/store/thunks/setActiveElement';
import { getActiveElementId } from 'jsfcore/store/selectors/baseSelectors';
import { getLoadingModalLocale } from 'jsfcore/store/selectors/localesSelectors';
import { activateVersionsMode, getVersionsList, flushOperationsQueue } from 'ws-editor-lib/actions';
import { openModal } from 'combine-modals/lib/actions/actionCreator';
import constants from 'combine-modals/lib/constants/dialog.modal';
import { DIALOG_MODAL } from 'combine-modals/lib/constants/modal.classnames';

const showVersions = () => {
  return (dispatch, getState) => {
    const state = getState();
    const activeElementId = getActiveElementId(state);
    const loadingModalLocale = getLoadingModalLocale(state);

    if (activeElementId) {
      dispatch(setActiveElement(activeElementId, false));
    }
    dispatch(openModal(DIALOG_MODAL, {
      size: constants.SIZE_MD,
      modalType: constants.PROCESSING,
      title: loadingModalLocale.title,
    }));
    dispatch(activateVersionsMode());
    dispatch(getVersionsList());
    dispatch(flushOperationsQueue());
  };
};

export default showVersions;
