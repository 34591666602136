import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeModal } from 'combine-modals/lib/actions/actionCreator';
import * as selectors from '../../store/selectors';

import { LockModal } from '../../helpers/const';
import getModalElementByType from './Modals/modalsMap';

const style = {
  top: 0,
  left: 0,
  zIndex: 9999,
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(179, 179, 179, 0.4)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

@connect((state) => {
  return {
    modalProps: state.modal.options[LockModal],
    locale: selectors.locale.getEditorLocale(state).modals,
  };
}, {
  closeModal,
})
export default class InterfaceLockedModalWindow extends Component {
  static propTypes = {
    modalProps: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        handlers: PropTypes.object,
        data: PropTypes.object,
      }),
    ]).isRequired,
    locale: PropTypes.shape({}).isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  static getModalId() {
    return LockModal;
  }

  getLocaleByType = () => {
    return this.props.locale[this.props.modalProps.type];
  };

  onCloseModal = () => {
    this.props.closeModal(LockModal);
  };

  render() {
    const { modalProps } = this.props;
    if (!modalProps) {
      return null;
    }

    const ModalForRender = getModalElementByType(modalProps.type);

    return (
      <div
        style={style}
      >
        <ModalForRender
          data={modalProps.data}
          handlers={modalProps.handlers}
          locale={this.getLocaleByType()}
          onCloseModal={this.onCloseModal}
        />
      </div>
    );
  }
}
