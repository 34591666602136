export const getFontIdentifier = ({ fontFamily, bold, italic }) => {
  let result = fontFamily;
  if (bold) {
    result += ' bold';
  }
  if (italic) {
    result += ' italic';
  }
  return result;
};

export const getFontIdentifierForLoadEvent = ({ family, weight, style }) => {
  return getFontIdentifier({
    fontFamily: family,
    bold: weight === 'bold',
    italic: style === 'italic',
  });
};
