import React, { Component } from 'react';
import ConnectedRightPanelDataProvider from './RightPanelDataProvider';
import RightPanelVisibilityProvider from './RightPanelVisibilityProvider';
import RightPanelElementsRenderer from './RightPanelElementsRenderer';

export default class RightPanelRenderer extends Component {
  render() {
    return (
      <ConnectedRightPanelDataProvider>
        {({
          isExtrasBarVisible,
          isPAConstructorShown,
          isFConstructorShown,
          isFConstructorLoaded,
          isFConstructorPreviewShown,
          isWizardLoaded,
          isTodoListMaximized,
          isVersionsMode,
        }) => {
          return (
            <RightPanelVisibilityProvider
              isExtrasBarVisible={isExtrasBarVisible}
              isPAConstructorShown={isPAConstructorShown}
              isFConstructorShown={isFConstructorShown}
              isFConstructorLoaded={isFConstructorLoaded}
              isFConstructorPreviewShown={isFConstructorPreviewShown}
              isWizardLoaded={isWizardLoaded}
              isTodoListMaximized={isTodoListMaximized}
              isVersionsMode={isVersionsMode}
            >
              {(props) => {
                if (!props.needShowRightPanel) {
                  return null;
                }

                return (
                  <RightPanelElementsRenderer {...props} />
                );
              }}
            </RightPanelVisibilityProvider>
          );
        }}
      </ConnectedRightPanelDataProvider>
    );
  }
}
