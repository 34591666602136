import React, { Component } from 'react';
import LayerSimplePaginationWrapper from './GhostLayerSimplePaginationWrapper';
import * as GhostMobile from './Mobile';

const Ghost = {
  Mobile: GhostMobile,
  LayerSimplePaginationWrapper,
};

export default class FreeGhostMobile extends Component {
  render() {
    return (
      <Ghost.LayerSimplePaginationWrapper>
        {({
          frameSizes,
          workspace,
          scales,
          pdfPages,
          getPagesViewport,
        }) => {
          return Ghost.Mobile.renderGhost({
            frameSizes,
            workspace,
            scales,
            pdfPages,
            getPagesViewport,
          });
        }}
      </Ghost.LayerSimplePaginationWrapper>
    );
  }
}
