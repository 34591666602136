import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FocusControllerRestoreFocusArea } from '@pdffiller/jsf-focuscontroller';
import { thisDevice } from '@pdffiller/jsf-useragent';
import {
  turnWizardFull,
  turnWizardLite,
} from 'ws-editor-lib/actions';
import * as selectors from '../../../store/selectors';
import * as Ui from '../../../ui';
import { stopEvent } from '../../../helpers/utils';

@connect(
  (state) => {
    return {
      isWizardFull: selectors.base.getIsWizardFull(state),
      locale: selectors.locale.getWizardFullLiteButtonLocale(state),
      hasFillableFields: selectors.elements.getHasFillableFields(state),
      hasOnlyFormulaElements: selectors.elements.getHasOnlyFormulaElements(state),
      hasNotFormulasEnabledElements:
        selectors.elements.getFillableNotFormulasEnabledElements(state).length !== 0,
    };
  }, {
    turnWizardFull,
    turnWizardLite,
  },
)
export default class HeaderWizard extends Component {
  static propTypes = {
    isWizardFull: PropTypes.bool.isRequired,
    locale: PropTypes.shape({
      label: PropTypes.string,
      title: PropTypes.string,
    }).isRequired,
    turnWizardFull: PropTypes.func.isRequired,
    turnWizardLite: PropTypes.func.isRequired,
    hasFillableFields: PropTypes.bool.isRequired,
    hasOnlyFormulaElements: PropTypes.bool.isRequired,
    hasNotFormulasEnabledElements: PropTypes.bool.isRequired,
  };

  onMouseDown = (event) => {
    // предотвращаем расфокус поля
    stopEvent(event);

    if (this.props.isWizardFull) {
      this.props.turnWizardLite();
    } else {
      this.props.turnWizardFull();
    }
  }

  render() {
    const {
      locale,
      isWizardFull,
      hasFillableFields,
      hasOnlyFormulaElements,
      hasNotFormulasEnabledElements,
    } = this.props;

    if (
      !hasFillableFields ||
      hasOnlyFormulaElements ||
      !hasNotFormulasEnabledElements
    ) {
      return null;
    }

    return (
      <FocusControllerRestoreFocusArea isSafariDesktop={thisDevice.isSafariDesktop}>
        <Ui.Container.SettingButton
          icon={
            isWizardFull
              ? Ui.icon.types.wizardFull
              : Ui.icon.types.wizardLite
          }
          label={locale.label}
          onMouseDown={this.onMouseDown}
          size={Ui.icon.sizes.large}
          title={locale.title}
          isPressed={isWizardFull}
          textOffset={Ui.text.offsets.topExtraSmall}
          theme={Ui.control.themes.transparentGray}
          offset={Ui.control.offsets.extraSmall}
        />
      </FocusControllerRestoreFocusArea>
    );
  }
}
