import PropTypes from 'prop-types';
import React, { Component } from 'react';
import chunk from 'lodash/chunk';

import { basicColors, smallColors } from '../../../../../helpers/const';
import * as Ui from '../../../../../ui';
import ColorButton from '../ColorButton/ColorButton';

export default class ColorPickerMobile extends Component {
  static propTypes = {
    colorKey: PropTypes.string.isRequired,
    locale: PropTypes.shape({
      basicColors: PropTypes.string.isRequired,
      moreColors: PropTypes.string.isRequired,
      back: PropTypes.string.isRequired,
    }).isRequired,

    //
    color: PropTypes.string.isRequired,
    setColor: PropTypes.func.isRequired,
  };

  onColorChange = (color) => {
    const { colorKey } = this.props;
    this.props.setColor(color, colorKey);
  };

  renderBigColorDiv = () => {
    return (color) => {
      return (
        <ColorButton
          key={color}
          onColorChange={this.onColorChange}
          color={color}
          type={Ui.control.boxIconTypes.parentMedium}
          isActive={this.props.color === color}
        />
      );
    };
  };

  renderSmallColorDiv = () => {
    return (colorsRow) => {
      const { color: currentColor } = this.props;
      return (
        <Ui.ColorPicker.ItemByType
          key={colorsRow}
          type={Ui.colorPicker.itemTypes.set}
        >
          {colorsRow.map((color) => {
            return (
              <ColorButton
                key={color}
                onColorChange={this.onColorChange}
                color={color}
                type={Ui.control.boxIconTypes.parentMedium}
                isActive={currentColor === color}
              />
            );
          })}
        </Ui.ColorPicker.ItemByType>
      );
    };
  };

  render() {
    const { locale } = this.props;

    return (
      <Ui.ColorPicker.Index
        offset={Ui.colorPicker.offsets.medium}
        type={Ui.colorPicker.types.flexible}
      >
        <Ui.ColorPicker.ItemByType type={Ui.colorPicker.itemTypes.group}>
          <Ui.Text
            size={12}
            weight={Ui.text.weights.bold}
            offset={Ui.text.offsets.bottomExtraSmall}
          >
            {locale.basicColors}
          </Ui.Text>
          <Ui.ColorPicker.ItemByType
            type={Ui.colorPicker.itemTypes.list}
            tag={Ui.colorPicker.itemTags.ul}
          >
            {basicColors.map(this.renderBigColorDiv())}
          </Ui.ColorPicker.ItemByType>
        </Ui.ColorPicker.ItemByType>
        <Ui.ColorPicker.ItemByType type={Ui.colorPicker.itemTypes.group}>
          <Ui.Text
            size={12}
            weight={Ui.text.weights.bold}
            offset={Ui.text.offsets.bottomExtraSmall}
          >
            {locale.moreColors}
          </Ui.Text>
          <Ui.ColorPicker.ItemByType
            type={Ui.colorPicker.itemTypes.list}
            tag={Ui.colorPicker.itemTags.ul}
          >
            {chunk(smallColors, 6).map(this.renderSmallColorDiv())}
          </Ui.ColorPicker.ItemByType>
        </Ui.ColorPicker.ItemByType>
      </Ui.ColorPicker.Index>
    );
  }
}
