import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import * as lazySelectors from '@pdffiller/jsf-lazyload/store/selectors';
import { thunks, selectors } from '../..';

@connect(
  (state) => {
    return {
      activePageId:
        selectors.base.getNextPageId(state) === false
          ? selectors.base.getActivePageId(state)
          : selectors.base.getNextPageId(state),
      pagesSettings: selectors.navigation.getPagesSettings(state),
      isThumbnailsOpen: selectors.base.getIsThumbnailsOpen(state),
      pageChanging: state.events.pageChanging,
      isWizardLoaded: lazySelectors.common.getIsWizardLoaded(state),
    };
  }, {
    changePage: thunks.changePage,
  },
)
export default class ThumbnailsProvider extends Component {
  static propTypes = {
    onThumbnailsMount: PropTypes.func.isRequired,
    activePageId: PropTypes.number.isRequired,
    count: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool,
    ]).isRequired,

    pagesSettings: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          rotation: PropTypes.number,
          source: PropTypes.number,
          visible: PropTypes.bool,
        }),
      ),
      PropTypes.bool,
    ]),
    isThumbnailsOpen: PropTypes.bool.isRequired,
    pageChanging: PropTypes.bool.isRequired,
    isWizardLoaded: PropTypes.bool.isRequired,

    changePage: PropTypes.func.isRequired,

    children: PropTypes.func.isRequired,
  };

  static contextTypes = {
    getAdjacentPageId: PropTypes.func,
    getPageIdByIndex: PropTypes.func,
  };

  static defaultProps = {
    pagesSettings: false,
  };

  componentDidMount() {
    this.props.onThumbnailsMount();
  }

  toFirst = () => {
    if (this.props.pageChanging) {
      return false;
    }

    this.props.changePage(this.context.getPageIdByIndex(0));
    return true;
  };

  toLast = () => {
    if (this.props.pageChanging) {
      return false;
    }

    this.props.changePage(this.context.getPageIdByIndex(this.props.count - 1));
    return true;
  };

  toPrev = () => {
    if (this.props.pageChanging) {
      return false;
    }

    this.props.changePage(this.context.getAdjacentPageId(-1));
    return true;
  };

  toNext = () => {
    if (this.props.pageChanging) {
      return false;
    }

    this.props.changePage(this.context.getAdjacentPageId(1));
    return true;
  };

  render() {
    return this.props.children({
      isThumbnailsOpen: this.props.isThumbnailsOpen,
      isWizardLoaded: this.props.isWizardLoaded,
      activePageId: this.props.activePageId,
      pagesSettings: this.props.pagesSettings,
      toFirst: this.toFirst,
      toPrev: this.toPrev,
      toNext: this.toNext,
      toLast: this.toLast,
    });
  }
}
