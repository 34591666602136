import { Component } from 'react';
import PropTypes from 'prop-types';

class FrameOffsetProvider extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
  };

  static contextTypes = {
    getPageViewport: PropTypes.func,
  };

  getFrameOffsetWithScroll = () => {
    return this.context.getPageViewport().frameOffset;
  };

  render() {
    return this.props.children({
      getFrameOffset: this.getFrameOffsetWithScroll,
    });
  }
}

export default FrameOffsetProvider;
