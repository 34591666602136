import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';
import clone from 'lodash/clone';
import clamp from 'lodash/clamp';
import { thisDevice } from '@pdffiller/jsf-useragent';

import { getElementProps } from '../../../store/helpers/functions';
import { stopEvent } from '../../../helpers/utils';
import {
  positions,
  pressCancelTimeout,
  popupStatuses,
  elementIsDraggingTimeout,
} from '../../../helpers/const';
import { getEventPos } from '../../../helpers/dragUtils';
import { scrollInterval, getScrollDirection,
  getScrollObject } from '../../../helpers/scrollOnDragUtils';
import { isTextToolBasedElement } from '../../../helpers/elemTypes';
import { setActiveElement, forceFocusTextElement } from '../../../store/thunks';
import { setDrag } from '../../../store/modules/events';

const initialState = {
  isDragging: false,
  resizeIndex: positions.none,

  dragStartPoint: { x: -1, y: -1 },
  dragEndPoint: { x: -1, y: -1 },

  initialOffset: { scrollTop: 0, scrollLeft: 0 },
};

@connect(
  null, {
    setDrag,
    forceFocusTextElement,
    setActiveElement,
  },
)
export default class ElementDragProvider extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    getRenderMixin: PropTypes.func.isRequired,
    getActualDraggingGeometry: PropTypes.func.isRequired,
    alwaysProvideFunctions: PropTypes.bool,
    getFrameOffset: PropTypes.func.isRequired,

    // from ElementDecorator
    element: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
    isActiveElement: PropTypes.bool.isRequired,
    isDraggingInRedux: PropTypes.bool.isRequired,
    isFillable: PropTypes.bool,
    isComment: PropTypes.bool,
    fConstructorLoadedAndShown: PropTypes.bool,

    // actions from ElementDecorator
    setDrag: PropTypes.func.isRequired,
    forceFocusTextElement: PropTypes.func.isRequired,
  };

  static defaultProps = {
    alwaysProvideFunctions: false,
    fConstructorLoadedAndShown: false,
    isFillable: false,
    isComment: false,
  };

  static contextTypes = {
    changePageScroll: PropTypes.func,
    getPageViewport: PropTypes.func,
    getEvents: PropTypes.func,
  };

  static childContextTypes = {
    updatePos: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const { x, y } = getElementProps(props.element);
    this.state = {
      ...initialState,
      elemPos: { x, y },
    };

    // sometimes there are dragMove events after dragStop (without dragStart, on touch)
    this.isDragEventStarted = false;
  }

  getChildContext() {
    return {
      updatePos: this.updatePos,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !nextProps.fConstructorLoadedAndShown &&
      nextProps.isFillable
    ) {
      return;
    }

    // update { x, y, width, height } of element in internal state, if it was changed outside
    this.processElemDiff(
      getElementProps(this.props.element),
      getElementProps(nextProps.element),
      nextProps,
    );
  }

  getSize = () => {
    return (
      this.getPropsFromComponent() ||
      getElementProps(this.props.element)
    );
  };

  getPropsFromComponent = () => {
    return this.textToolState || false;
  };

  storeValidatedTextToolSize = (state) => {
    this.textToolState = state;
  };

  forceFocusTextElementIfNeed = () => {
    const { element, isActiveElement } = this.props;
    const { popupVisibility } = this.context.getPageViewport();
    if (
      isActiveElement &&
      isTextToolBasedElement(element) &&
      !(thisDevice.isMobile && popupVisibility !== popupStatuses.hidden)
    ) {
      this.props.forceFocusTextElement(element.id);
    }
  };

  maybeScroll = throttle(() => {
    const { resizeIndex, isDragging } = this.state;

    if (!this.context.changePageScroll || (!resizeIndex && !isDragging)) {
      return;
    }

    const scrollDirection = getScrollDirection(this.state, this.context.getPageViewport());
    if (!scrollDirection) {
      return;
    }

    const scrollObject = getScrollObject(scrollDirection, this.context.getPageViewport());
    this.context.changePageScroll(scrollObject);
  }, scrollInterval);

  // Here we update width or height on elem size changed outside
  processElemDiff = (oldElemProps, newElemProps, nextProps = this.props) => {
    if (!nextProps.isActiveElement) {
      return;
    }

    const { x, y } = newElemProps;
    const { x: oldX, y: oldY } = oldElemProps;

    const isPosChanged = x !== oldX || y !== oldY;
    if (isPosChanged) {
      this.setState({
        ...(isPosChanged
          ? { elemPos: { x, y } }
          : {}),
      });
    }
  };

  // public
  updatePos = ({ x, y }) => {
    const { originalSize } = this.context.getPageViewport();

    const { width, height } = this.props.getActualDraggingGeometry({
      elemSizeFromGetSize: this.getSize(),
      state: this.state,
    });
    this.setState({
      elemPos: {
        x: clamp(x, 0, originalSize.width - width),
        y: clamp(y, 0, originalSize.height - height),
      },
    });
  };

  onResizeDragStop = () => {
    return (event) => {
      clearTimeout(this.setIsDraggingTimeout);
      stopEvent(event);

      if (this.mouseMoveRequestAnimationFrame) {
        cancelAnimationFrame(this.mouseMoveRequestAnimationFrame);
      }

      // when dragStop shoot, Content::onClick shoots too.
      // to prevent Content's onClick, we reset drag after a small delay
      setTimeout(() => {
        this.props.setDrag(false);
      }, 200);

      const newGeometry = this.props.getActualDraggingGeometry({
        elemSizeFromGetSize: this.getPropsFromComponent(),
        state: this.state,
      });
      const { x, y } = (
        isTextToolBasedElement(this.props.element) &&
        !this.state.isDragging &&
        !this.props.fConstructorLoadedAndShown
      )
        ? this.state.elemPos
        : newGeometry;

      this.isDragEventStarted = false;
      this.setState({
        ...initialState,
        initialElemProps: undefined,
        elemPos: {
          x,
          y,
        },
      });

      this.forceFocusTextElementIfNeed();
    };
  };

  onResizeDragMove = (resizeIndex) => {
    return (event) => {
      if (this.mouseMoveRequestAnimationFrame) {
        cancelAnimationFrame(this.mouseMoveRequestAnimationFrame);
      }

      this.mouseMoveRequestAnimationFrame = requestAnimationFrame(() => {
        this.mouseMoveRequestAnimationFrame = null;

        if (!this.isDragEventStarted) {
          return;
        }

        // set drag only when drag is already started and some move appeared.
        // other way (dragstart->dragstop without move) - is click, with handler at Content.js
        if (!this.props.isDraggingInRedux) {
          this.props.setDrag(this.props.element.id);
        }

        stopEvent(event);
        const eventPos = getEventPos(event, this.props);
        if (!eventPos) {
          return;
        }

        this.setState({
          dragEndPoint: eventPos,
          resizeIndex,
        }, this.maybeScroll);
      });
    };
  };

  onResizeDragStart = (resizeIndex) => {
    return (event) => {
      const eventPos = getEventPos(event, this.props);
      if (!eventPos) {
        return;
      }
      stopEvent(event);

      this.isDragEventStarted = true;
      this.setState((prevState) => {
        return {
          initialElemProps: { ...prevState.elemPos, ...this.getSize() },
          dragStartPoint: eventPos,
          dragEndPoint: eventPos, // that's ok, we start to process at the very beginning of drag
          initialOffset: clone(this.props.getFrameOffset()),
        };
      });


      // JSF-3567: we need to work with dragging element even if there's no dragMove events
      this.setIsDraggingTimeout = setTimeout(() => {
        if (this.state.resizeIndex === positions.none) {
          this.setState({ resizeIndex }, () => {
            if (!this.props.isDraggingInRedux) {
              this.props.setDrag(this.props.element.id);
            }
          });
        }
      }, elementIsDraggingTimeout);
    };
  };

  onDragStop = () => {
    clearTimeout(this.setIsDraggingTimeout);
    if (this.mouseMoveRequestAnimationFrame) {
      cancelAnimationFrame(this.mouseMoveRequestAnimationFrame);
    }

    if (thisDevice.isIOS) {
      document.removeEventListener('touchmove', stopEvent, { passive: false });
    }

    if (!this.props.isDraggingInRedux) {
      this.isDragEventStarted = false;
      return;
    }

    if (!this.isDragEventStarted) {
      return;
    }

    // when dragStop shoot, Content::onClick shoots too.
    // to prevent Content's onClick, we reset drag after a small delay
    setTimeout(() => {
      this.props.setDrag(false);
    }, 200);

    const { x, y } = this.props.getActualDraggingGeometry({
      elemSizeFromGetSize: this.getPropsFromComponent(),
      state: this.state,
    });

    this.isDragEventStarted = false;
    this.setState({
      ...initialState,
      initialElemProps: undefined,
      elemPos: { x, y },
    });

    this.forceFocusTextElementIfNeed();
  };

  onDragMove = (event) => {
    if (this.mouseMoveRequestAnimationFrame) {
      cancelAnimationFrame(this.mouseMoveRequestAnimationFrame);
    }

    this.mouseMoveRequestAnimationFrame = requestAnimationFrame(() => {
      this.mouseMoveRequestAnimationFrame = null;

      const { pagePinching, pageScaling, pageChanging, pagePanning } = this.context.getEvents();
      if (pagePinching || pageScaling || pageChanging || pagePanning) {
        return;
      }
      if (!this.isDragEventStarted) {
        return;
      }

      // set drag only when drag is already started and some move appeared.
      // other way (dragstart->dragstop without move) - is click, with handler at Content.js
      if (!this.props.isDraggingInRedux) {
        this.props.setDrag(this.props.element.id);
      }

      clearTimeout(this.cancelTimeout);
      stopEvent(event);
      const eventPos = getEventPos(event, this.props);
      if (!eventPos) {
        return;
      }

      this.setState({
        dragEndPoint: eventPos,
        isDragging: true,
      }, this.maybeScroll);
    });
  };

  onDragStart = (event) => {
    const { pagePinching, pageScaling, pageChanging, pagePanning } = this.context.getEvents();
    if (
      pagePinching || pageScaling || pageChanging || pagePanning ||
      this.isDragEventStarted ||
      // in IE11 element is not actvated onMouseDown (ClickController::render),
      // so we sure that it'll not become active after few milliseconds, and not drag this element
      (thisDevice.isInternetExplorer11 && !this.props.isActiveElement)
    ) {
      return;
    }

    const eventPos = getEventPos(event, { pagePinching });
    if (!eventPos) {
      return;
    }

    this.isDragEventStarted = true;

    this.setState((prevState) => {
      return {
        initialElemProps: { ...prevState.elemPos, ...this.getSize() },
        dragStartPoint: eventPos,
        dragEndPoint: eventPos, // that's ok, we start to process at the very beginning of drag
        initialOffset: clone(this.props.getFrameOffset()),
      };
    });

    if (thisDevice.isIOS) {
      // prevent page scrolling during tools dragging
      // by default touch events has { passive: true } for performance
      // scroll works in parallel with touch events and we can't use preventDefault
      // https://dom.spec.whatwg.org/#observing-event-listeners
      document.addEventListener('touchmove', stopEvent, { passive: false });
      this.cancelTimeout = setTimeout(() => {
        // need to full clear drag in case of iOS magnifier showing
        this.props.setDrag(false);
        this.isDragEventStarted = false;
        this.setState(initialState);
      }, pressCancelTimeout);
    }

    // JSF-3567: we need to work with dragging element even if there's no dragMove events
    this.setIsDraggingTimeout = setTimeout(() => {
      if (!this.state.isDragging) {
        this.setState({ isDragging: true }, () => {
          if (!this.props.isDraggingInRedux) {
            this.props.setDrag(this.props.element.id);
          }
        });
      }
    }, elementIsDraggingTimeout);
  };

  renderElement = () => {
    // Если элемент драгируется или ресайзится, то только тогда имеет
    // смысл подмешивать в него свойства
    const { element, resizingGeometry } =
      (this.state.isDragging || this.state.resizeIndex)
        ? this.props.getRenderMixin({
          elemSizeFromGetSize: this.getPropsFromComponent(),
          state: this.state,
        })
        : this.props;

    return this.props.children({
      // methods
      onDrag: {
        start: this.onDragStart,
        move: this.onDragMove,
        stop: this.onDragStop,
      },
      onResize: {
        start: this.onResizeDragStart,
        move: this.onResizeDragMove,
        stop: this.onResizeDragStop,
      },

      draggableProps: {
        isDragging: this.state.isDragging,
        resizeIndex: this.state.resizeIndex,
        ...(!this.props.isFillable
          ? { storeValidatedTextToolSize: this.storeValidatedTextToolSize }
          : {}
        ),
        ...(resizingGeometry
          ? { resizingGeometry }
          : {}),
      },

      ...(element
        ? { element }
        : {}),
    });
  };

  render() {
    const {
      fConstructorLoadedAndShown,
      isActiveElement,
      isFillable,
      isComment,
      alwaysProvideFunctions,
    } = this.props;

    // MS: sometimes, ElementDragProvider should provides "onDrag" and "onResize"
    // regardless availability of active element
    // IS: SNFiller experimental feature
    if (alwaysProvideFunctions) {
      const isFillableElementInConstructor = isFillable && fConstructorLoadedAndShown;
      const isSimpleElement = !isFillable;

      if (isSimpleElement || isFillableElementInConstructor) {
        return this.renderElement();
      }
    }

    if (isComment) {
      return this.renderElement();
    }

    // It's was changed in https://github.com/pdffiller/jsfcore/commit/e8584634f2ad6cd16b811e1bf38c4812ebb06cbe
    // But it's incorrect fix
    // I revert it to prev state
    if (fConstructorLoadedAndShown && isActiveElement && isFillable) {
      return this.renderElement();
    }

    if (isFillable || !isActiveElement) {
      return (
        this.props.children({
          element: this.props.element,

          draggableProps: {
            isDragging: this.props.isDraggingInRedux,
            resizeIndex: positions.none,
          },
        })
      );
    }

    return this.renderElement();
  }
}
