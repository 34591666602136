import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { elemTypes } from 'jsfcore/helpers/elemTypes';
import { isFillable } from 'jsfcore/store/helpers/functions';
import {
  onClickElementInChoiseByClickingMode,
  onMouseDownElementInChoiseByClickingMode,
} from '../store/actions/onClickElementInChoiseByClickingMode';
import * as conditionsSelectors from '../selectors';
import { getDependency, dependenciesMap } from '../di';

const styleDisabled = {
  opacity: 0.5,
};

const styleEnabled = {
  boxShadow: '0 9px 24px rgba(0, 0, 0, 0.3)',
};

const jsfcoreSelectors = getDependency(dependenciesMap.jsfcoreSelectors);

@connect(
  () => {
    const snRoleSelectors = getDependency(dependenciesMap.snRoleSelectors);

    return (state) => {
      return {
        choiseByClickingModeIsActive: conditionsSelectors.getChoiseByClickingModeIsActive(state),
        steps: snRoleSelectors.getSteps(state),
      };
    };
  }, {
    onClickElementInChoiseByClickingMode,
    onMouseDownElementInChoiseByClickingMode,
  },
)
export default class ChoiseByClickingElementWrapper extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    choiseByClickingModeIsActive: PropTypes.bool.isRequired,
    element: PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
      enabled: PropTypes.bool.isRequired,
      type: PropTypes.string.isRequired,
      template: PropTypes.shape({
        roleId: PropTypes.string,
        name: PropTypes.string.isRequired,
      }),
    }).isRequired,
    steps: PropTypes.arrayOf(PropTypes.shape({
      signers: PropTypes.arrayOf(
        PropTypes.shape({
          roleId: PropTypes.string.isRequired,
        }),
      ).isRequired,
    })).isRequired,

    onClickElementInChoiseByClickingMode: PropTypes.func.isRequired,
    onMouseDownElementInChoiseByClickingMode: PropTypes.func.isRequired,
  };

  static contextTypes = {
    store: PropTypes.shape({
      dispatch: PropTypes.func.isRequired,
      getState: PropTypes.func.isRequired,
    }).isRequired,
  };

  getIsChoiseEnabled = () => {
    const state = this.context.store.getState();
    const activeElement = jsfcoreSelectors.elements.getActiveElement(state);

    const { element, steps } = this.props;
    const isChoiseEnabled = (
      activeElement &&
      isFillable(element) &&
      activeElement.id !== element.id &&
      activeElement.template.name !== element.template.name
    );

    return (
      isChoiseEnabled &&
      !conditionsSelectors.isElementOnCurrentRoleOrOnNextStep(
        steps,
        activeElement.template.roleId,
        element.template.roleId,
      )
    );
  };

  getStyleByTypeAndState = () => {
    const { element } = this.props;
    // this code for filled signature/initial and document signed
    // in this case need show signature without any opacity
    if (
      element &&
      element.enabled === false &&
      element.content &&
      element.type === elemTypes.signature &&
      element.content.url
    ) {
      return { opacity: 1 };
    }

    return {};
  };

  onMouseDown = (event) => {
    this.props.onMouseDownElementInChoiseByClickingMode(event);
  };

  onClick = (event) => {
    const { element } = this.props;

    this.props.onClickElementInChoiseByClickingMode(event, element.id);
  }

  render() {
    const { choiseByClickingModeIsActive } = this.props;
    if (choiseByClickingModeIsActive) {
      const isChoiseEnabled = this.getIsChoiseEnabled();
      return (
        this.props.children({
          isChoiseEnabled,
          onMouseDownChoiseByClicking: this.onMouseDown,
          onClickChoiseByClicking: this.onClick,
          style:
            isChoiseEnabled
              ? styleEnabled
              : styleDisabled,
        })
      );
    }

    return this.props.children({
      isChoiseEnabled: true,
      style: this.getStyleByTypeAndState(),
      onMouseDownChoiseByClicking: this.onMouseDown,
      onClickChoiseByClicking: this.onClick,
    });
  }
}
