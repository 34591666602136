const getBoundedDragGeometryProps = (props) => {
  const { resizeIndex } = props.draggableProps;
  const { width, x } = props.element.template;

  // если ширина превышает допустимую, обрезаем значение
  const { elementMinWidth } = props;

  if (elementMinWidth > width) {
    const updatedWidth = elementMinWidth;
    const dx = width - updatedWidth;

    // если мы тянем за точки с правой стороны или изменяем
    // размер шрифта, стиль или формат даты,
    // то ограничиваем только ширину

    // если тянем за точки с левой стороны, то увеличиваем координату "x"
    // на смещение ширины
    const isLeftSide = [4, 5, 6].indexOf(resizeIndex) !== -1;
    const updatedX = isLeftSide
      ? x + dx
      : x;

    return {
      width: updatedWidth,
      x: updatedX,
    };
  }

  return {
    width,
    x,
  };
};

export default getBoundedDragGeometryProps;
