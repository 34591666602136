import PropTypes from 'prop-types';
import React, { Component } from 'react';
import chunk from 'lodash/chunk';

import * as Ui from '../../../../../ui';
import { basicColors, colorKeys, smallColors } from '../../../../../helpers/const';
import ColorButton from '../ColorButton/ColorButton';

export default class ColorPickerDesktop extends Component {
  static propTypes = {
    setColor: PropTypes.func.isRequired,
    forceFocusTextElement: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    colorKey: PropTypes.oneOf(Object.values(colorKeys)).isRequired,
    closePortal: PropTypes.func,
  };

  static defaultProps = {
    closePortal: null,
  };

  onColorChange = (color) => {
    this.props.setColor(color, this.props.colorKey);
    this.props.forceFocusTextElement();
  };

  renderColorButton = ({ type, boxIconSize = null, tag, onClick, color }) => {
    const { color: colorProp } = this.props;
    return (
      <ColorButton
        key={color}
        onColorChange={this.onColorChange}
        size={Ui.control.sizes.parentHeight}
        color={color}
        isActive={colorProp === color}
        type={type}
        boxIconSize={boxIconSize}
        tag={tag}
        onClick={onClick}
      />
    );
  };

  renderBigColorDiv = (onClick) => {
    return (color) => {
      return this.renderColorButton({
        type: Ui.control.boxIconTypes.wide,
        boxIconSize: Ui.control.boxIconSizes.large,
        onClick,
        color,
      });
    };
  };

  renderSmallColorDiv = (onClick) => {
    return (colorsRow) => {
      return (
        <Ui.ColorPicker.ItemByType
          key={colorsRow}
          type={Ui.colorPicker.itemTypes.set}
          tag={Ui.colorPicker.itemTags.li}
        >
          {colorsRow.map((color) => {
            return this.renderColorButton({
              tag: Ui.colorPicker.itemTags.div,
              boxIconSize: Ui.control.boxIconSizes.mediumRectangle,
              onClick,
              color,
            });
          })}
        </Ui.ColorPicker.ItemByType>
      );
    };
  };

  render() {
    const { closePortal } = this.props;

    return (
      <Ui.ColorPicker.Index
        offset={Ui.colorPicker.offsets.extraSmall}
      >
        <Ui.ColorPicker.ItemByType type={Ui.colorPicker.itemTypes.group}>
          <Ui.Text
            size={12}
            weight={Ui.text.weights.bold}
            offset={Ui.text.offsets.bottomExtraSmall}
          >
            Basic colors
          </Ui.Text>
          <Ui.ColorPicker.ItemByType
            type={Ui.colorPicker.itemTypes.list}
            tag={Ui.colorPicker.itemTags.ul}
          >
            {basicColors.map(this.renderBigColorDiv(closePortal))}
          </Ui.ColorPicker.ItemByType>
        </Ui.ColorPicker.ItemByType>
        <Ui.ColorPicker.ItemByType type={Ui.colorPicker.itemTypes.group}>
          <Ui.Text
            size={12}
            weight={Ui.text.weights.bold}
            offset={Ui.text.offsets.bottomExtraSmall}
          >
            More colors
          </Ui.Text>
          <Ui.ColorPicker.ItemByType
            type={Ui.colorPicker.itemTypes.list}
            tag={Ui.colorPicker.itemTags.ul}
          >
            {chunk(smallColors, 3).map(this.renderSmallColorDiv(closePortal))}
          </Ui.ColorPicker.ItemByType>
        </Ui.ColorPicker.ItemByType>
      </Ui.ColorPicker.Index>
    );
  }
}
