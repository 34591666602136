import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { updateElement, updateComment } from 'ws-editor-lib/actions';

import updateFillableElementThunk from '../../../store/thunks/updateFillableElement';

import { cancellableOpts } from '../../../store/modules/undoRedo';
import { isTextToolBasedElement } from '../../../helpers/elemTypes';

export const mapDispatchToProps = {
  updateFillableElementThunk,
  updateElement,
  updateComment,
};

export const propTypes = {
  isDragging: PropTypes.bool.isRequired,
  resizeIndex: PropTypes.number.isRequired,
  resizingGeometry: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
  elementProps: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  element: PropTypes.shape({
    type: PropTypes.string.isRequired,
    subType: PropTypes.string.isRequired,
    brushType: PropTypes.string,
    template: PropTypes.shape({}),
    content: PropTypes.shape({}),
    id: PropTypes.string.isRequired,
    pageId: PropTypes.number.isRequired,
  }).isRequired,
  fConstructorLoadedAndShown: PropTypes.bool,
  isFillableElement: PropTypes.bool,
  isComment: PropTypes.bool,

  children: PropTypes.element.isRequired,

  updateFillableElementThunk: PropTypes.func.isRequired,
  updateElement: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
};

export class GeometryUpdater extends Component {
  static propTypes = propTypes;

  static defaultProps = {
    resizingGeometry: null,
    fConstructorLoadedAndShown: false,
    isFillableElement: false,
    isComment: false,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const dragWasStopped = this.props.isDragging === true && nextProps.isDragging === false;
    const resizeWasStopped = this.props.resizeIndex !== 0 && nextProps.resizeIndex === 0;

    if (dragWasStopped || resizeWasStopped) {
      const { x, y, width, height } = this.props.resizingGeometry || this.props.elementProps;
      this.applyNewElementProps({ x, y, width, height });
    }
  }

  getNewProps = (actualDraggingGeometry) => {
    const { element, fConstructorLoadedAndShown } = this.props;

    return {
      ...(isTextToolBasedElement(element)
        ? {
          x: actualDraggingGeometry.x,
          y: actualDraggingGeometry.y,
          ...(fConstructorLoadedAndShown && {
            width: actualDraggingGeometry.width,
            height: actualDraggingGeometry.height,
          }),
        }
        : { ...actualDraggingGeometry }),
    };
  };

  applyNewElementProps = (actualDraggingGeometry) => {
    const { element } = this.props;
    const opts = cancellableOpts;

    const newProps = this.getNewProps(actualDraggingGeometry);

    if (this.props.isComment) {
      this.props.updateComment({
        ...element,
        content: {
          ...element.content,
          ...newProps,
        },
      });
    } else if (this.props.fConstructorLoadedAndShown && this.props.isFillableElement) {
      this.props.updateFillableElementThunk({ id: element.id, template: newProps }, opts);
    } else {
      this.props.updateElement(element.id, newProps, opts);
    }
  };

  render() {
    return this.props.children;
  }
}

export default connect(null, mapDispatchToProps)(GeometryUpdater);
