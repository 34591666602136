import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { cancellableOpts } from '../../store/modules/undoRedo';
import updateFillableElementThunk from '../../store/thunks/updateFillableElement';
import updateFillableElementWithPreprocessing from '../../store/thunks/updateFillableElementWithPreprocessing';
import { limitByFrameSize } from '../../helpers/utils';
import { selectors } from '../../index';

const mapStateToProps = (state, { element }) => {
  return {
    originalSize: selectors.getOriginalSize(state, element.pageId),
    activeElementId: selectors.base.getActiveElementId(state),
  };
};

const mapDispatchToProps = {
  updateFillableElementWithPreprocessing,
  updateFillableElementThunk,
};

export class DateMinWidthUpdaterSimple extends Component {
  static propTypes = {
    activeElementId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]).isRequired,
    elementMinWidth: PropTypes.number.isRequired,
    element: PropTypes.shape({
      id: PropTypes.string.isRequired,
      template: PropTypes.shape({
        width: PropTypes.number.isRequired,
      }),
    }).isRequired,
    updateFillableElementWithPreprocessing: PropTypes.func.isRequired,
    updateFillableElementThunk: PropTypes.func.isRequired,
    originalSize: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }).isRequired,
  };

  componentDidUpdate(prevProps) {
    const { elementMinWidth, element } = this.props;
    const width = get(element, 'template.width');

    // if the width has been changed - skip min width update (SNF-1633)
    if (width !== get(prevProps.element, 'template.width')) {
      return;
    }

    const height = get(element, 'template.height');
    const x = get(element, 'template.x');
    const y = get(element, 'template.y');

    if (!width || !elementMinWidth || !(width < elementMinWidth)) {
      return;
    }

    const updatedTemplate = {
      width: elementMinWidth,
      ...limitByFrameSize(
        { x, y },
        this.props.originalSize,
        { width: elementMinWidth, height },
      ),
    };

    if (element.id === this.props.activeElementId) {
      this.props.updateFillableElementThunk({
        id: this.props.activeElementId,
        template: updatedTemplate,
      }, cancellableOpts);
      return;
    }

    this.props.updateFillableElementWithPreprocessing({
      id: element.id,
      template: updatedTemplate,
    });
  }

  render() {
    return null;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DateMinWidthUpdaterSimple);
