import PropTypes from 'prop-types';
import { Component } from 'react';
import { positions } from '../../helpers/const';

export default class ElementHrefProvider extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,

    hyperlink: PropTypes.string,
    isEnabledElement: PropTypes.bool,

    isDragging: PropTypes.bool.isRequired,
    resizeIndex: PropTypes.number,
  };

  static defaultProps = {
    isEnabledElement: true,
    resizeIndex: positions.none,
    hyperlink: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const isDraggingStopped = !nextProps.isDragging && this.props.isDragging;
    const isResizingStopped = !nextProps.resizeIndex && this.props.resizeIndex;

    if (isDraggingStopped || isResizingStopped) {
      this.setHover({ val: true, timeout: 1000 });
    }

    const isDraggingStarted = nextProps.isDragging && !this.props.isDragging;
    const isResizingStarted = nextProps.resizeIndex && !this.props.resizeIndex;

    if (isDraggingStarted || isResizingStarted) {
      this.setHover({ val: false, timeout: 0 });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.setHoverTimeout);
    this.isUnmounted = true;
  }

  setHover = ({ val, timeout }) => {
    const setFunc = () => {
      if (this.state.hover !== val) {
        this.setState({ hover: val });
      }
    };

    clearTimeout(this.setHoverTimeout);

    if (timeout !== 0) {
      this.setHoverTimeout = setTimeout(setFunc, timeout);
    } else {
      setFunc();
    }
  };

  onMouseEnter = () => {
    this.setHover({ val: true, timeout: 0 });
  };

  onMouseLeave = () => {
    if (this.isUnmounted) {
      return;
    }

    this.setHover({ val: false, timeout: 600 });
  };

  onClickWrapperElement = (event) => {
    const { hyperlink, isEnabledElement } = this.props;

    // если элемент содержит ссылку и disabled, то,
    // при клике по такому элементу - необходимо перейти по ссылке
    if (hyperlink && isEnabledElement === false) {
      window.open(hyperlink, '_blank');
      event.stopPropagation();
    }
    event.preventDefault();
  };

  render() {
    return this.props.children({
      onClickWrapperElement: this.onClickWrapperElement,
      hover: this.state.hover,
      onMouseEnter: this.onMouseEnter,
      onMouseLeave: this.onMouseLeave,
    });
  }
}
