import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import findIndex from 'lodash/findIndex';
import debounce from 'lodash/debounce';

import { getStyle } from '../Tools/TextTool/utils/textToolUtils';
import { screenToPage } from '../../helpers/utils';
import { getScrollToRect } from '../../helpers/searchUtils';
import { isObjectFitRectangle } from '../../helpers/search/searchExistingHighlightElements';
import {
  textToolArrangements,
  occurenceTypes,
} from '../../helpers/const';
import { appendOccurenceRects } from '../../store/modules/search';
import { selectors } from '../..';

@connect(
  (state) => {
    return {
      searchIndex: state.search.searchIndex,
      activePageId: selectors.base.getActivePageId(state),
      isPageChanging: selectors.base.getIsPageChanging(state),
      occurenceRects: state.search.occurenceRects,
      pdfScale: selectors.base.getPdfScale(state),
    };
  },
  {
    appendOccurenceRects,
  },
)
export default class SearchHighlighter extends Component {
  static propTypes = {
    // from store
    occurenceRects: PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
      }),
    ).isRequired,

    // NOTE: Баг eslint'a, проп используется в getIsActiveOccurence
    // eslint-disable-next-line react/no-unused-prop-types
    searchIndex: PropTypes.number.isRequired,
    activePageId: PropTypes.number.isRequired,
    isPageChanging: PropTypes.bool.isRequired,
    pdfScale: PropTypes.number.isRequired,
    // actions
    appendOccurenceRects: PropTypes.func.isRequired,

    occurence: PropTypes.shape({
      occurPos: PropTypes.number.isRequired,
      occurLength: PropTypes.number.isRequired,
      index: PropTypes.number.isRequired,
      pageId: PropTypes.number.isRequired,
      type: PropTypes.oneOf(Object.values(occurenceTypes)).isRequired,
      content: PropTypes.shape({
        fontFamily: PropTypes.string.isRequired,
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        text: PropTypes.string.isRequired,
        // for pdf text only
        rotate: PropTypes.number,
        // for text element only
        arrangement: PropTypes.oneOf(Object.values(textToolArrangements)),
        align: PropTypes.string,
        bold: PropTypes.bool,
        fontSize: PropTypes.number,
        italic: PropTypes.bool,
        padding: PropTypes.number,
        paddingTop: PropTypes.number,
        underline: PropTypes.bool,
        maxChars: PropTypes.number, // for cells only
      }),
    }).isRequired,
  };

  static contextTypes = {
    getPageViewport: PropTypes.func,
    changePageScroll: PropTypes.func,
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isInited: false,
      scaleX: 1,
    };
    this.innerNode = null;
    this.wrapperNode = null;
  }

  componentDidMount() {
    this.initComponent();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.occurence !== nextProps.occurence) {
      this.setState({ isInited: false, scaleX: 1 });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.isInited) {
      this.initComponent();
      return;
    }

    const isActiveOccurence = this.getIsActiveOccurence(this.props);
    const prevIsActiveOccurence = this.getIsActiveOccurence(prevProps);

    const becameInited = !prevState.isInited && this.state.isInited;
    const becameActive = !prevIsActiveOccurence && isActiveOccurence;

    if (isActiveOccurence && (becameInited || becameActive)) {
      if (!prevProps.isPageChanging) {
        this.updateOccurenceRectsIfNeed();
      } else {
        // зачем нужен debounce:
        // при переключении страницы наш элемент становится активным в пропсах,
        // но анимация перелистывания еще не завершается, и поэтому getClientRects
        // дает немного неправильные цифры. Если самую малость подождать - то ок
        this.debouncedUpdateOccurenceRectsIfNeed();
      }

      const rect = this.innerNode.getBoundingClientRect();
      const { workspace, frameOffset } = this.context.getPageViewport();
      const needScroll = getScrollToRect({ workspace, rect });

      if (needScroll.dTop !== 0 || needScroll.dLeft !== 0) {
        const newScroll = {
          scrollTop: frameOffset.scrollTop + needScroll.dTop,
          scrollLeft: frameOffset.scrollLeft + needScroll.dLeft,
        };
        this.context.changePageScroll(newScroll);
      }
    }
  }

  getIsActiveOccurence = (props) => {
    const { occurence, searchIndex, activePageId, isPageChanging } = props;

    return (
      occurence.index === searchIndex &&
      occurence.pageId === activePageId &&
      !isPageChanging
    );
  };

  getStyle = () => {
    const { type, content } = this.props.occurence;
    if (type === occurenceTypes.element) {
      return {
        ...getStyle({
          paddingTop: content.paddingTop,
          align: content.align,
          fontSize: content.fontSize,
          width: content.width,
          height: content.height,
          bold: content.bold,
          italic: content.italic,
          underline: content.underline,
          fontFamily: content.fontFamily,
          padding: content.padding,
        }, true),
        color: 'transparent',
      };
    }

    const fontSize = this.scaleValue(content.height);
    const transform = `rotate(${content.rotate}deg)scaleX(${this.state.scaleX})`;

    return {
      fontFamily: content.fontFamily,
      fontSize: `${fontSize}px`,
      left: `${this.scaleValue(content.x)}px`,
      top: `${this.scaleValue(content.y)}px`,
      fontWeight: content.bold
        ? 'bold'
        : '',

      fontStyle: content.italic
        ? 'italic'
        : '',

      transform,
      transformOrigin: '0 0',
    };
  };

  getScale = () => {
    const { activePageId } = this.props;
    return selectors.getScale(this.context.store.getState(), activePageId);
  };

  scaleValue = (value) => {
    return value * this.props.pdfScale;
  };

  initComponent = () => {
    if (this.props.occurence.type === occurenceTypes.element) {
      this.setState({ isInited: true });
      return;
    }

    // PDF text:
    // После первого рендера мы смотрим ширину отрендеренного дива
    // и на сколько она не попала в this.props.occurence.content.width
    // выставляем transform: scaleX,
    // Такиим образом наш отрендеренный текст будет лучше попадать в pdf-текст.
    // В PDFJS сделано так же
    const itemWidth = this.scaleValue(this.props.occurence.content.width);
    const nodeWidth = parseInt(getComputedStyle(this.wrapperNode).width, 10);
    this.setState({ scaleX: itemWidth / nodeWidth, isInited: true });
  };

  updateOccurenceRectsIfNeed = () => {
    const { occurenceRects } = this.props;
    const { workspace, frameOffset } = this.context.getPageViewport();
    const realScale = this.getScale();

    const clientRects = Array.prototype.map.call(this.innerNode.getClientRects(), (rect) => {
      return {
        ...screenToPage({ x: rect.left, y: rect.top }, realScale, frameOffset, workspace),
        width: rect.width / realScale,
        height: rect.height / realScale,
      };
    });

    // Выбираем только те rects, которыу еще не лежат в store
    const onlyNewRects = clientRects.filter((newRect) => {
      return findIndex(
        occurenceRects,
        (rect) => {
          return isObjectFitRectangle(newRect, rect) === -1;
        },
      );
    });

    if (onlyNewRects.length > 0) {
      this.props.appendOccurenceRects(onlyNewRects);
    }
  };

  debouncedUpdateOccurenceRectsIfNeed = debounce(() => {
    this.updateOccurenceRectsIfNeed();
  }, 30);

  storeInnerNodeRef = (ref) => {
    this.innerNode = ref;
  };

  storeWrapperNodeRef = (ref) => {
    this.wrapperNode = ref;
  };

  renderCells = () => {
    const { content, occurPos, occurLength } = this.props.occurence;
    const isActiveOccurence = this.getIsActiveOccurence(this.props);

    const letterWidth = content.width / content.maxChars;
    const left = letterWidth * occurPos;
    const width = letterWidth * occurLength;
    return (
      <div
        className={cx('searchHighlighterInner', 'cells', {
          active: isActiveOccurence,
        })}
        ref={this.storeInnerNodeRef}
        style={{ left, width }}
      />
    );
  };

  renderText = () => {
    const { occurPos, occurLength, content: { text } } = this.props.occurence;
    const isActiveOccurence = this.getIsActiveOccurence(this.props);

    return (
      <div>
        {text.slice(0, occurPos)}
        <span
          className={cx('searchHighlighterInner', {
            active: isActiveOccurence,
          })}
          ref={this.storeInnerNodeRef}
        >
          {text.slice(occurPos, occurPos + occurLength)}
        </span>
        {text.slice(occurPos + occurLength)}
      </div>
    );
  };

  render() {
    const { occurence: { type, content } } = this.props;
    const isElement = type === occurenceTypes.element;
    const isCells = content.arrangement === textToolArrangements.cells;
    return (
      <div
        className={cx('searchHighlighter', {
          text: isElement && !isCells,
          cells: isElement && isCells,
        })}
        style={this.getStyle()}
        ref={this.storeWrapperNodeRef}
      >
        {!isCells && this.renderText()}
        {isCells && this.renderCells()}
      </div>
    );
  }
}
