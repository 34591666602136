import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const themes = {
  default: 'default',
  null: null,
};

const DatePicker = ({ children, theme }) => {
  return (
    <div
      className={classnames('datepicker', {
        [`datepicker--theme--${theme}`]: theme,
      })}
    >
      {children}
    </div>
  );
};

DatePicker.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.oneOf(Object.values(themes)),
};

DatePicker.defaultProps = {
  theme: themes.default,
};

DatePicker.themes = themes;

export default DatePicker;
