import memoize from 'lodash/memoize';
import PropTypes from 'prop-types';
import React from 'react';
import { DraggableCore } from 'react-draggable';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { focusControllerDraggableDecorator } from '@pdffiller/jsf-focuscontroller';

import * as Conditions from '@pdffiller/jsf-conditions/components';
import * as thunks from '../../store/thunks';
import ElementTypesMapper from './ElementTypesMapper';
import RemoveButton, { removeButtonTypes } from './RemoveButton/RemoveButton';
import ElementFillableWrapperView from './ElementFillableWrapperView';
import ElementIcon from './ElementIcon/ElementIcon';
import { wrapperPositions, WrapperExperiment } from '../Portal';
import StoreRefProvider from '../StoreRef/StoreRefProvider';

const locatorArgs = {
  position: wrapperPositions.topRightOuter,
  hideIfAnchorOutOfViewport: true,
  clampToViewport: false,
  smartPosition: false,
};

const onDragIfChoiseByClickingEnabled = {
  start: () => {},
  move: () => {},
  stop: () => {},
};

const getDecoratedDraggableCore = memoize(() => {
  return focusControllerDraggableDecorator(thisDevice)(DraggableCore);
});

const renderConstructorElementView = ({
  elementProps,
  element,
  onDrag,
  onResize,
  draggableProps,
  scale,
  isActiveElement,
  fConstructorLoadedAndShown,
}, context) => {
  const DecoratedDraggableCore = getDecoratedDraggableCore();

  return (
    <Conditions.ChoiseByClickingElementWrapperJSF
      isActiveElement={isActiveElement}
      element={element}
    >
      {({
        style,
        isChoiseEnabled,
        onClickChoiseByClicking,
        onMouseDownChoiseByClicking,
      }) => {
        // Если текущий элемент нельзя выбрать запрещаем его дрэгать
        const currentOnDrag = isChoiseEnabled
          ? onDrag
          : onDragIfChoiseByClickingEnabled;

        return (
          <ElementTypesMapper
            type={element.type}
            subType={element.subType}
            isFConstuctorMode
          >
            {({ ComponentElement }) => {
              return (
                <StoreRefProvider>
                  {({ storeRef, getRefPromise }) => {
                    return (
                      <>
                        <DecoratedDraggableCore
                          onStart={currentOnDrag.start}
                          onDrag={currentOnDrag.move}
                          onStop={currentOnDrag.stop}
                        >
                          <ElementFillableWrapperView
                            scale={scale}
                            isActiveElement={isActiveElement}
                            element={element}
                            storeRef={storeRef}
                            isDragging={draggableProps.isDragging}
                          >
                            <ComponentElement
                              {...elementProps}
                              isChoiseEnabled={isChoiseEnabled}
                              onMouseDownChoiseByClicking={onMouseDownChoiseByClicking}
                              onClickChoiseByClicking={onClickChoiseByClicking}
                              style={style}
                              width={elementProps.width || 10}
                              height={elementProps.height || 10}
                              type={element.type}
                              subType={element.subType}
                              id={element.id}
                              scale={scale}
                              pageId={element.pageId}
                              isDragging={draggableProps.isDragging}
                              element={element}
                              // Нет кейсов для изменения ресайз функций ибо мы просто не показываем
                              // точки за которые можно ресайзить элемент
                              onResizeDragStart={onResize.start}
                              onResizeDragMove={onResize.move}
                              onResizeDragStop={onResize.stop}
                              resizingGeometry={draggableProps.resizingGeometry}
                              resizeIndex={draggableProps.resizeIndex}
                              isActiveElement={isActiveElement}
                              elementIcon={
                                <ElementIcon
                                  element={element}
                                  scale={scale}
                                  fConstructorLoadedAndShown={fConstructorLoadedAndShown}
                                />
                              }
                            />

                            {isActiveElement && isChoiseEnabled &&
                              <WrapperExperiment
                                getAnchorRefPromise={getRefPromise}
                                getViewportRef={context.getWorkspaceRef}
                                locatorArgs={locatorArgs}
                                theme={null}
                                useArrow={false}
                                zIndex={1}
                              >
                                <RemoveButton
                                  onMouseDown={thunks.remove}
                                  type={removeButtonTypes.delete}
                                />
                              </WrapperExperiment>
                            }
                          </ElementFillableWrapperView>
                        </DecoratedDraggableCore>
                      </>
                    );
                  }}
                </StoreRefProvider>
              );
            }}
          </ElementTypesMapper>
        );
      }}
    </Conditions.ChoiseByClickingElementWrapperJSF>
  );
};

renderConstructorElementView.propTypes = {
  elementProps: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  element: PropTypes.shape({
    type: PropTypes.string.isRequired,
    subType: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    pageId: PropTypes.number.isRequired,
    template: PropTypes.shape({}),
  }).isRequired,
  onDrag: PropTypes.shape({
    start: PropTypes.func,
    move: PropTypes.func,
    stop: PropTypes.func,
  }).isRequired,
  onResize: PropTypes.shape({
    start: PropTypes.func,
    move: PropTypes.func,
    stop: PropTypes.func,
  }).isRequired,
  draggableProps: PropTypes.shape({
    isDragging: PropTypes.bool.isRequired,
    storeValidatedTextToolSize: PropTypes.func,
    resizeIndex: PropTypes.number,
    resizingGeometry: PropTypes.shape({}),
  }).isRequired,
  scale: PropTypes.number.isRequired,
  isActiveElement: PropTypes.bool.isRequired,
  fConstructorLoadedAndShown: PropTypes.bool.isRequired,
};

renderConstructorElementView.contextTypes = {
  getWorkspaceRef: PropTypes.func.isRequired,
};

export default renderConstructorElementView;
