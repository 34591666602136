import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as Ui from '../../../ui';
import SigningSessionProvider from './SigningSessionProvider';
import {
  getIsSigningSessionOverlayEnabled,
  getIsReadOnlyOverlayEnabled,
  getIsWizardOverlayEnabled,
} from '../../../helpers/utils';

const gridCellAttributes = { ...Ui.attributes.grow, ...Ui.attributes.shrink };
const groupItemAttributes = { ...Ui.attributes.grow };

export default class HeaderToolsOverlayView extends Component {
  static propTypes = {
    toolbarOverlays: PropTypes.arrayOf(PropTypes.string).isRequired,
    locale: PropTypes.shape({
      readOnlyAccess: PropTypes.string.isRequired,
      fillingWizard: PropTypes.string.isRequired,
    }).isRequired,
    signingSessionHide: PropTypes.func.isRequired,
    isToolbarExist: PropTypes.bool.isRequired,
  };

  renderReadOnlyAccessOverlay = () => {
    const { locale } = this.props;

    return (
      <Ui.EditorInfo.Index>
        <Ui.EditorInfo.Body>
          <Ui.EditorInfo.Item>
            <Ui.Group.Index
              align={Ui.group.aligns.center}
              offset={Ui.group.offsets.small}
            >
              <Ui.Group.Body>
                <Ui.Group.Item>
                  <Ui.Icon
                    type={Ui.icon.types.eye}
                    size={Ui.icon.sizes.large}
                  />
                </Ui.Group.Item>
                <Ui.Group.Item>
                  <Ui.Text lineHeight={Ui.text.lineHeights.medium} size={20}>
                    {locale.readOnlyAccess}
                  </Ui.Text>
                </Ui.Group.Item>
              </Ui.Group.Body>
            </Ui.Group.Index>
          </Ui.EditorInfo.Item>
        </Ui.EditorInfo.Body>
      </Ui.EditorInfo.Index>
    );
  };

  renderFillingWizardOverlay = () => {
    const { locale } = this.props;

    return (
      <Ui.EditorInfo.Index>
        <Ui.EditorInfo.Body>
          <Ui.EditorInfo.Item>
            <Ui.Group.Index
              align={Ui.group.aligns.center}
              offset={Ui.group.offsets.small}
            >
              <Ui.Group.Body>
                <Ui.Group.Item>
                  <Ui.Icon
                    type={Ui.icon.types.magicWand}
                    size={Ui.icon.sizes.large}
                  />
                </Ui.Group.Item>
                <Ui.Group.Item>
                  <Ui.Text lineHeight={Ui.text.lineHeights.medium} size={14}>
                    {locale.fillingWizard}
                  </Ui.Text>
                </Ui.Group.Item>
              </Ui.Group.Body>
            </Ui.Group.Index>
          </Ui.EditorInfo.Item>
        </Ui.EditorInfo.Body>
      </Ui.EditorInfo.Index>
    );
  };

  renderSigningSessionOverlay = () => {
    const { isToolbarExist } = this.props;

    return (
      <SigningSessionProvider>
        {({ titleId, textId, interpolationParams, isSmileyIconShown }) => {
          return (
            <Ui.EditorInfo.Index>
              <Ui.EditorInfo.Body>
                <Ui.EditorInfo.Row>
                  <Ui.EditorInfo.Cell>
                    <Ui.Text
                      size={14}
                      tag={Ui.text.tags.p}
                      theme={Ui.text.themes.dark}
                      family={Ui.text.families.openSans}
                      weight={Ui.text.weights.bold}
                      lineHeight={Ui.text.lineHeights.medium}
                      id={titleId}
                      interpolationParams={interpolationParams}
                    />
                  </Ui.EditorInfo.Cell>
                  <Ui.EditorInfo.Cell>
                    {isSmileyIconShown
                      ? (
                        <Ui.Group.Index
                          type={Ui.group.types.compact}
                          offset={Ui.group.offsets.small}
                          align={Ui.group.aligns.center}
                        >
                          <Ui.Group.Body>
                            <Ui.Group.Item>
                              <Ui.Text
                                size={12}
                                tag={Ui.text.tags.p}
                                theme={Ui.text.themes.darkgray}
                                family={Ui.text.families.openSans}
                                lineHeight={Ui.text.lineHeights.medium}
                                id={textId}
                                display={Ui.text.displays.inlineFlex}
                              />
                            </Ui.Group.Item>
                            <Ui.Group.Item attributes={groupItemAttributes}>
                              <Ui.Icon
                                size={Ui.icon.sizes.small}
                                type={Ui.icon.types.coloredSmiling}
                              />
                            </Ui.Group.Item>
                          </Ui.Group.Body>
                        </Ui.Group.Index>
                      )
                      : (
                        <Ui.Text
                          size={12}
                          tag={Ui.text.tags.p}
                          theme={Ui.text.themes.darkgray}
                          family={Ui.text.families.openSans}
                          lineHeight={Ui.text.lineHeights.medium}
                          id={textId}
                          display={Ui.text.displays.inlineFlex}
                        />
                      )
                    }
                  </Ui.EditorInfo.Cell>
                </Ui.EditorInfo.Row>
                {isToolbarExist && (
                  <Ui.EditorInfo.Row>
                    <Ui.EditorInfo.Cell>
                      <Ui.Control
                        behavior={Ui.control.behaviors.column}
                        size={Ui.control.sizes.largeRectangular}
                        offset={Ui.control.offsets.extraSmall}
                        theme={Ui.control.themes.secondaryTransparent}
                        isPressed={false}
                        isBusy={false}
                        icon={
                          <Ui.Icon size={Ui.icon.sizes.large} type={Ui.icon.types.annotate} />
                        }
                        onClick={this.props.signingSessionHide}
                        titleId="SIGNING_SESSION.ANNOTATE_BUTTON.TITLE"
                      >
                        <Ui.Text
                          size={11}
                          weight={Ui.text.weights.bold}
                          id="SIGNING_SESSION.ANNOTATE_BUTTON.TEXT"
                        />
                      </Ui.Control>
                    </Ui.EditorInfo.Cell>
                  </Ui.EditorInfo.Row>
                )}
              </Ui.EditorInfo.Body>
            </Ui.EditorInfo.Index>
          );
        }}
      </SigningSessionProvider>
    );
  };

  render() {
    const { toolbarOverlays } = this.props;

    const isReadOnlyOverlayEnabled = getIsReadOnlyOverlayEnabled(toolbarOverlays);
    const isWizardOverlayEnabled = getIsWizardOverlayEnabled(toolbarOverlays);
    const isSigningSessionOverlayEnabled = getIsSigningSessionOverlayEnabled(toolbarOverlays);

    return (
      <Ui.Grid.Cell attributes={gridCellAttributes}>
        <Ui.Grid.Wrapper>
          {isReadOnlyOverlayEnabled && this.renderReadOnlyAccessOverlay()}
          {isWizardOverlayEnabled && this.renderFillingWizardOverlay()}
          {isSigningSessionOverlayEnabled && this.renderSigningSessionOverlay()}
        </Ui.Grid.Wrapper>
      </Ui.Grid.Cell>
    );
  }
}
