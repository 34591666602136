import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as Ui from '../../../../../ui';
import { openHrefModal } from '../../../../../store/modules/viewport';

export class HrefPopupSettingsSimple extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    openHrefModal: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Ui.Menu.ListItem>
        <Ui.Menu.Item
          type={Ui.menu.itemsTypes.button}
          role={Ui.menu.itemsRoles.button}
          onClick={this.props.openHrefModal}
        >
          <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.body}>

            <Ui.Menu.ItemByType attributes={Ui.attributes.grow}>
              <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.text}>
                <Ui.Text
                  size={16}
                  align={Ui.text.aligns.left}
                >
                  {this.props.locale}
                </Ui.Text>
              </Ui.Menu.ItemByType>
            </Ui.Menu.ItemByType>

            <Ui.Menu.ItemByType attributes={Ui.attributes.shrinkOff}>

              <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.icon}>
                <Ui.Icon type={Ui.icon.types.link} size={Ui.icon.sizes.small} />
              </Ui.Menu.ItemByType>

            </Ui.Menu.ItemByType>

          </Ui.Menu.ItemByType>
        </Ui.Menu.Item>
      </Ui.Menu.ListItem>
    );
  }
}

export default connect(null, {
  openHrefModal,
})(HrefPopupSettingsSimple);
