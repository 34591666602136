import PropTypes from 'prop-types';
import React from 'react';

export const styleForIconWrapper = {
  position: 'absolute',
  top: '50%',
  right: 0,
  transform: 'translateY(-50%)',
};

const ElementIconWrapper = ({ children, style }) => {
  return (
    <div className="fillable-field__icon" style={style}>
      {children}
    </div>
  );
};

ElementIconWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  style: PropTypes.shape({}),
};

ElementIconWrapper.defaultProps = {
  style: {},
};

export default ElementIconWrapper;
