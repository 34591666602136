import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as selectors from '../../../store/selectors';
import { deactivateElement } from '../../../store/thunks';
import HeaderDoneView from './HeaderDoneView';

@connect(
  (state) => {
    return {
      doneButton: selectors.base.getDoneButton(state),
      doneButtonLocale: selectors.locale.getDoneButtonLocale(state),
      isDeclineButtonVisible: state.viewport.isDeclineButtonVisible,
      isDraftButtonVisible: state.viewport.isDraftButtonVisible,
    };
  },
  { deactivateElement },
)
export default class HeaderDone extends Component {
  static propTypes = {
    doneButton: PropTypes.shape({
      main: PropTypes.object.isRequired,
      list: PropTypes.array.isRequired,
    }).isRequired,
    doneButtonLocale: PropTypes.string.isRequired,
    isDraftButtonVisible: PropTypes.bool.isRequired,
    isDeclineButtonVisible: PropTypes.bool.isRequired,

    onDoneClick: PropTypes.func.isRequired,
    onOptionClick: PropTypes.func.isRequired,
    deactivateElement: PropTypes.func.isRequired,
  };

  render() {
    return (
      <HeaderDoneView
        isDraftButtonVisible={this.props.isDraftButtonVisible}
        isDeclineButtonVisible={this.props.isDeclineButtonVisible}
        doneButton={this.props.doneButton}
        doneButtonLocale={this.props.doneButtonLocale}
        onClick={this.props.onDoneClick}
        onOptionClick={this.props.onOptionClick}
        onTogglerMouseDown={this.props.deactivateElement}
      />
    );
  }
}
