import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TODO_LIST_STATES as todoListStates } from 'ws-editor-lib/constants';
import * as Ui from 'jsfcore/ui';
import { FocusControllerRestoreFocusArea } from '@pdffiller/jsf-focuscontroller';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { wizard, dispatchAction } from '@pdffiller/jsf-lazyload';

import { selectors } from '../..';
import { paConstructorShow } from '../../store/modules/viewport';
import showVersions from '../../store/thunks/showVersions';
import toggleFConstructor from '../../store/thunks/toggleFConstructor';

@connect((state) => {
  return {
    panelLocale: state.locale.fConstructor.panel,
    isFConstructorBarVisible: selectors.feature.isFConstructorAvailable(state),
    isWatermarkBarVisible: selectors.feature.isWatermarkBarAvailable(state),
    isVersionsBarVisible: selectors.feature.isVersionsBarAvailable(state),
    isAppStarted: state.events.appStarted,
    todoListState: selectors.base.getTodoListState(state),
  };
}, {
  paConstructorShow,
  showVersions,
  toggleFConstructor,
})
export default class RightPanelTabsButtons extends Component {
  static propTypes = {
    panelLocale: PropTypes.shape({
      addFillableFields: PropTypes.string.isRequired,
      addWatermark: PropTypes.string.isRequired,
      versions: PropTypes.string.isRequired,
    }).isRequired,
    isFConstructorBarVisible: PropTypes.bool.isRequired,
    isWatermarkBarVisible: PropTypes.bool.isRequired,
    isVersionsBarVisible: PropTypes.bool.isRequired,
    isAppStarted: PropTypes.bool.isRequired,
    todoListState: PropTypes.oneOf(Object.values(todoListStates)),

    paConstructorShow: PropTypes.func.isRequired,
    showVersions: PropTypes.func.isRequired,
    toggleFConstructor: PropTypes.func.isRequired,
  };

  static contextTypes = {
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    todoListState: todoListStates.minimized,
  };

  toggleFConstructor = () => {
    this.props.toggleFConstructor(this.context);
  };

  toggleWatermark = () => {
    this.props.paConstructorShow();
    dispatchAction(wizard.actions.setWizardActive, false);
  };

  toggleVersions = () => {
    this.props.showVersions();
    dispatchAction(wizard.actions.setWizardActive, false);
  };

  renderFConstructorBarButton = () => {
    if (!this.props.isFConstructorBarVisible) {
      return null;
    }

    return (
      <FocusControllerRestoreFocusArea isSafariDesktop={thisDevice.isSafariDesktop}>
        <Ui.RightSidebar.ListItem
          data-autotest="itemFillableFields"
          onClick={this.toggleFConstructor}
        >
          <Ui.Control
            inheritRadius
            behavior={Ui.control.behaviors.rotatedText}
            offset={Ui.control.offsets.mediumReversed}
            theme={Ui.control.themes.solidSuccess}
            size={Ui.control.sizes.null}
            icon={
              <Ui.Icon
                size={Ui.icon.sizes.small}
                type={Ui.icon.types.redesignFillableFields24}
              />
            }
          >
            <Ui.Text
              size={14}
              weight={Ui.text.weights.bold}
              writing={Ui.text.writings.vertical}
            >
              {this.props.panelLocale.addFillableFields}
            </Ui.Text>
          </Ui.Control>
        </Ui.RightSidebar.ListItem>
      </FocusControllerRestoreFocusArea>
    );
  };

  renderWatermarkBarButton = () => {
    if (!this.props.isWatermarkBarVisible) {
      return null;
    }

    return (
      <Ui.RightSidebar.ListItem
        onClick={this.toggleWatermark}
      >
        <Ui.Control
          inheritRadius
          behavior={Ui.control.behaviors.rotatedText}
          offset={Ui.control.offsets.mediumReversed}
          theme={Ui.control.themes.solidDanger}
          size={Ui.control.sizes.null}
          icon={
            <Ui.Icon
              size={Ui.icon.sizes.small}
              type={Ui.icon.types.watermark}
            />
          }
        >
          <Ui.Text
            size={14}
            weight={Ui.text.weights.bold}
            writing={Ui.text.writings.vertical}
          >
            {this.props.panelLocale.addWatermark}
          </Ui.Text>
        </Ui.Control>
      </Ui.RightSidebar.ListItem>
    );
  };

  renderVersionsBarButton = () => {
    if (!this.props.isVersionsBarVisible) {
      return null;
    }

    return (
      <Ui.RightSidebar.ListItem
        onClick={this.toggleVersions}
      >
        <Ui.Control
          inheritRadius
          behavior={Ui.control.behaviors.rotatedText}
          offset={Ui.control.offsets.mediumReversed}
          theme={Ui.control.themes.solidBlue}
          size={Ui.control.sizes.null}
          icon={
            <Ui.Icon
              size={Ui.icon.sizes.small}
              type={Ui.icon.types.historyClock}
            />
          }
        >
          <Ui.Text
            size={14}
            weight={Ui.text.weights.bold}
            writing={Ui.text.writings.vertical}
          >
            {this.props.panelLocale.versions}
          </Ui.Text>
        </Ui.Control>
      </Ui.RightSidebar.ListItem>
    );
  };

  renderBarButtons = () => {
    if (!this.props.isAppStarted) {
      return null;
    }

    return (
      <Ui.RightSidebar.List>
        {this.renderFConstructorBarButton()}
        {this.renderWatermarkBarButton()}
        {this.renderVersionsBarButton()}
      </Ui.RightSidebar.List>
    );
  };

  render() {
    const {
      isWatermarkBarVisible,
      isFConstructorBarVisible,
      isVersionsBarVisible,
    } = this.props;
    const isTodoListMinimized = this.props.todoListState === todoListStates.minimized;
    if (
      !isWatermarkBarVisible &&
      !isFConstructorBarVisible &&
      !isVersionsBarVisible &&
      isTodoListMinimized
    ) {
      return null;
    }

    return (
      <Ui.RightSidebar.Body>
        {this.renderBarButtons()}
      </Ui.RightSidebar.Body>
    );
  }
}
