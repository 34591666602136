import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { thisDevice } from '@pdffiller/jsf-useragent';

import { popupMenu, sizes } from '../../../../../helpers/const';
import { stopEventPropagation } from '../../../../../helpers/utils';
import { toolbar } from '../../../../../store/selectors';
import setToolbarAttributeValue from '../../../../../store/thunks/setToolbarAttributeValue';
import { setActivePopupMenu } from '../../../../../store/modules/viewport';

import * as Ui from '../../../../../ui';

const fontSizes = sizes.map((item) => {
  return { value: item, label: item };
});

@connect(
  (state) => {
    const { fontSize } = toolbar.getElementPropsForToolbar(state);
    return {
      fontSize,
    };
  },
  {
    setToolbarAttributeValue,
    setActivePopupMenu,
  },
)
export default class FontSizePopupSettings extends Component {
  static propTypes = {
    fontSize: PropTypes.number.isRequired,
    locale: PropTypes.string.isRequired,
    setActivePopupMenu: PropTypes.func.isRequired,
    setToolbarAttributeValue: PropTypes.func.isRequired,
  };

  backToMain = () => {
    this.props.setActivePopupMenu(popupMenu.main);
  }

  onFontSizeChange = (size) => {
    return () => {
      this.props.setToolbarAttributeValue('fontSize', size);
    };
  }

  renderFontSizeDiv = (item) => {
    return (
      <Ui.Menu.ListItem key={item.value}>
        <Ui.Menu.Item
          onClick={this.onFontSizeChange(item.value)}
        >
          <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.body}>
            <Ui.Menu.ItemByType attributes={Ui.attributes.grow}>
              <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.text}>
                <Ui.Text size={16} align={Ui.text.aligns.left}>
                  {item.label}
                </Ui.Text>
              </Ui.Menu.ItemByType>
            </Ui.Menu.ItemByType>

            {item.value === this.props.fontSize &&
              <Ui.Menu.ItemByType>
                <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.checkMark}>
                  <Ui.Icon
                    type={Ui.icon.types.checkMark}
                    size={Ui.icon.sizes.small}
                  />
                </Ui.Menu.ItemByType>
              </Ui.Menu.ItemByType>
            }
          </Ui.Menu.ItemByType>
        </Ui.Menu.Item>
      </Ui.Menu.ListItem>
    );
  };

  render() {
    const textWeight = thisDevice.isDesktop
      ? Ui.text.weights.regular
      : null;
    const offset = thisDevice.isDesktop
      ? Ui.control.offsets.medium
      : null;

    return (
      <Ui.Slider.ContentCell>

        <Ui.ToolBar.Body
          size={Ui.toolBar.sizes.medium}
          theme={Ui.toolBar.themes.solidLightgray}
          offset={Ui.toolBar.offsets.extraSmallHorizontal}
        >
          <Ui.ToolBar.Main>
            <Ui.ToolBar.Group>
              <Ui.Container.SettingButton
                icon={Ui.icon.types.arrowLeft}
                theme={Ui.control.themes.transparentBlue}
                behavior={Ui.control.behaviors.null}
                size={Ui.icon.sizes.small}
                label={this.props.locale}
                textSize={15}
                onClick={this.backToMain}
                textWeight={textWeight}
                offset={offset}
              />
            </Ui.ToolBar.Group>
          </Ui.ToolBar.Main>
        </Ui.ToolBar.Body>

        <Ui.ScrollBox>
          <Ui.Menu.Body>
            <Ui.Menu.List
              onTouchStart={stopEventPropagation}
            >
              {fontSizes.map(this.renderFontSizeDiv)}
            </Ui.Menu.List>
          </Ui.Menu.Body>
        </Ui.ScrollBox>

      </Ui.Slider.ContentCell>
    );
  }
}
