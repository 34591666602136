import getIsFontAvailable from './getIsFontAvailable';
import loadFont, { isFontLoaded } from './loadFont';
import { recognizeFont, getScaledImage } from './recognizeFont';
import fontsMap from './helpers/fontsMap';

export {
  getIsFontAvailable,
  isFontLoaded,
  loadFont,
  recognizeFont,
  getScaledImage,
  fontsMap,
};
