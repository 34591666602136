import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import get from 'lodash/get';
import { managers, wizard, getLazy, dispatchAction } from '@pdffiller/jsf-lazyload';
import { updateElement, trackPoint } from 'ws-editor-lib/actions';

import { setFillablePictureSelectingId } from '../../../store/modules/events';
import { cancellableOpts } from '../../../store/modules/undoRedo';
import { setIsSearchModeActive } from '../../../store/modules/search';
import toggleCheckmarkValue from './toggleCheckmarkValue';

import { selectors } from '../../..';
import { isModalVisible } from '../../../store/helpers/stateToProps';
import needToCancelElementClickProcessingSelector from './needToCancelElementClickProcessingSelector';

import {
  isFillable,
  isPicture,
  isUnfilledPictureOrSign,
  isFillableCheckmarkElement,
  isSignature,
  getSignToReuseContentToUpdate,
} from '../../../store/helpers/functions';
import { isDrawType } from '../../../helpers/elemTypes';
import { nodeIsElement } from '../../Ghost/ghostUtils';
import { getElementIdByNode } from '../contentUtils';

export const isNeedToggleCheckmark = (lastActiveElement, clickedElement) => {
  return (
    !lastActiveElement ||
    (lastActiveElement.id === clickedElement.id) ||
    (isFillableCheckmarkElement(lastActiveElement) === false) ||
    (get(clickedElement, 'template.name') !== get(lastActiveElement, 'template.name'))
  );
};

@connect((state) => {
  return {
    activeElementId: selectors.base.getActiveElementId(state),
  };
})
export default class ClickController extends Component {
  static propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
        PropTypes.bool,
      ]),
    ).isRequired,
    activeElementId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]).isRequired,

    updateElementActionCreator: PropTypes.func,
    toggleCheckmarkValueActionCreator: PropTypes.func,
  };

  static defaultProps = {
    updateElementActionCreator: updateElement,
    toggleCheckmarkValueActionCreator: toggleCheckmarkValue,
  };

  static contextTypes = {
    getEvents: PropTypes.func.isRequired,
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
      dispatch: PropTypes.func.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);
    this.toggleCheckmark = null;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.activeElementId !== this.props.activeElementId &&
      this.toggleCheckmark &&
      this.toggleCheckmark.id === nextProps.activeElementId
    ) {
      this.toggleCheckmark.fn();
      this.toggleCheckmark = null;
    }
  }

  getActiveElement = () => {
    return selectors.elements.getActiveElement(
      this.context.store.getState(),
    );
  };

  openPictureModal = (element) => {
    if (!this.getActiveElement()) {
      return;
    }

    const { store } = this.context;

    if (isPicture(element)) {
      store.dispatch(setFillablePictureSelectingId(element.id));
      dispatchAction(managers.actions.openImageManager, {});
    } else {
      const state = store.getState();
      const cacheSignature = get(state, 'wizard.cacheSignature', false);
      const getSignToReuse = getLazy(wizard.functions.getSignToReuse);
      const signToReuse = getSignToReuse({ cacheSignature, element }) || false;
      const isWizardLite = selectors.base.getIsWizardLite(state);

      if (isWizardLite) {
        dispatchAction(
          wizard.actions.triggerSelectSignatureClick,
          element,
        );
      } else if (signToReuse) {
        const useSigDateStamp = get(state, 'ws.defaults.useSigDateStamp');
        const content =
          getSignToReuseContentToUpdate(signToReuse.content, element, useSigDateStamp);

        const isActiveElement = this.getActiveElement().id === element.id;
        if (isActiveElement) {
          store.dispatch(
            this.props.updateElementActionCreator(element.id, content, cancellableOpts),
          );
        } else {
          store.dispatch(updateElement(element.id, content));
        }
      } else {
        dispatchAction(
          wizard.actions.triggerSelectSignatureClick,
          element,
        );
      }
    }
  };

  needToCancelElementClickProcessing = () => {
    return needToCancelElementClickProcessingSelector(this.context.store.getState());
  };

  handleMouseEvent = (event) => {
    const { store } = this.context;

    const activeElementId = this.getActiveElement().id;

    const isSearchModeActive = selectors.mode.isSearch(
      store.getState(),
    );
    if (isSearchModeActive) {
      store.dispatch(setIsSearchModeActive(false));
    }

    const isPAConstructorLoadedAndShown = selectors.mode.isPageAttributes(
      store.getState(),
    );
    if (isPAConstructorLoadedAndShown) {
      return;
    }

    if (isDrawType(store.getState().ws.ghostElement.type)) {
      return;
    }

    if (isModalVisible(store.getState())) {
      return;
    }

    if (nodeIsElement(event.target)) {
      this.onClickElement(event, activeElementId);
    }
  };

  handleClick = (event) => {
    /**
     * TODO: уменьшить дублирование кода между handleClick и handleMouseEvent
     * (не сделал сразу так как хотфиксил прод)
     */
    const { store } = this.context;

    const isPAConstructorLoadedAndShown = selectors.mode.isPageAttributes(
      store.getState(),
    );
    if (isPAConstructorLoadedAndShown) {
      return;
    }

    if (isDrawType(store.getState().ws.ghostElement.type)) {
      return;
    }

    if (isModalVisible(store.getState())) {
      return;
    }

    if (nodeIsElement(event.target)) {
      const elementId = getElementIdByNode(event.target);
      const element = selectors.elements.getElementByIdFactory(elementId)(store.getState());

      const isFillingMode = selectors.mode.isFilling(store.getState());
      const isWizardLite = selectors.base.getIsWizardLite(store.getState());

      if (isUnfilledPictureOrSign(element) && isFillingMode) {
        this.openPictureModal(element);
      }
      if (
        isWizardLite &&
        isFillable(element) &&
        (isPicture(element) || isSignature(element)) &&
        isFillingMode
      ) {
        this.openPictureModal(element);
      }
    }
  };

  onClickElement = (event, lastActiveElementId) => {
    const { store } = this.context;
    const elementId = getElementIdByNode(event.target);
    const element = selectors.elements.getElementByIdFactory(elementId)(store.getState());
    const lastActiveElement = lastActiveElementId
      ? selectors.elements.getElementByIdFactory(lastActiveElementId)(store.getState())
      : null;

    if (this.needToCancelElementClickProcessing()) {
      return;
    }

    if (lastActiveElement !== element) {
      const wizardTrackPoints = getLazy(wizard.objects.trackPoints, null);
      if (wizardTrackPoints !== null) {
        store.dispatch(
          trackPoint(wizardTrackPoints.FIELD_ACTIVATED_WITHOUT_WIZARD),
        );
      }
    }

    /*
      Проверка на необходимость смены значение checkmark.
      Меняем значение checkmark, если:
      - нет последнего активного элемента
      - мы кликаем на тотже элемент
      - последний активный элемент не checkmark
      - если мы кликнули на checkmark который не входит в туже группу
        Database Field Name с последним активным элементом

      https://pdffiller.atlassian.net/browse/JSF-5325
    */
    if (isNeedToggleCheckmark(lastActiveElement, element)) {
      // TODO: !TEMPORARY!
      // https://www.notion.so/isachivka/focusController-b1362c8d70e747d28d3db3b463dc377b#c479c6606238461abba512df5ec64296
      const fn = () => {
        store.dispatch(
          this.props.toggleCheckmarkValueActionCreator.onlyInFillingMode({
            element,
            updater: this.props.updateElementActionCreator,
          }),
        );
      };

      if (lastActiveElement && lastActiveElement.id === element.id) {
        fn();
      } else {
        this.toggleCheckmark = {
          id: element.id,
          fn,
        };
      }
    }
  };

  render() {
    return (
      <div
        className={this.props.className}
        onMouseDown={this.handleMouseEvent}
        onClick={this.handleClick}
      >
        {this.props.children}
      </div>
    );
  }
}
