import sum from 'lodash/sum';

import { toolbarAttributesContainerType } from '../../../helpers/const';
import { popoverArrowPositions } from '../../../ui/Popover/Popover';
import { possiblePositions } from '../../Portal/helpers/const';

// Количество элементов стиль стрелочки
const ARROW_STYLE_TOOLS_COUNT = 3;

// Это количество элементов шрифта в тулбаре
const FONT_TOOLS_COUNT = 5;

// Это стиль шрифта, Font Family, и размер шрифта, Font Size
const FONT_AND_SIZE_TOOLS_COUNT = 2;

const MORE_ICON_BUTTON_WIDTH = 35;

// Разница между количеством элементов шрифта в тулбаре и поповере: в поповере их меньше на 2,
// т.к. Bold, Italic и Underline - это один элемент для поповера и 3 элемента для тулбара
export const TOOLBAR_AND_POPUP_TOOLS_DIFF = 2;

// Ширина dropdown в компоненте SimpleElementControl
export const DROPDOWN_WIDTH = 316;

export const INITIAL_BUTTONS_COUNT = -1;
const CONTAINERS_MARGIN = 30;

export const getButtonsWidthArray = (containerRef) => {
  const container = containerRef;

  if (!container || container.children.length === 0) {
    return [];
  }

  return Array.from(container.children).map((button) => {
    return button.getBoundingClientRect().width;
  });
};

export const getVisibleToolsCount = (
  containerRef,
  toolbarAttributesWidth,
  hasArrowStyle,
  hasFont,
) => {
  if (!containerRef || !toolbarAttributesWidth) {
    return INITIAL_BUTTONS_COUNT;
  }
  const buttonsWidthArray = getButtonsWidthArray(containerRef);

  const buttonsWidthArraySum = buttonsWidthArray.reduce((arraySum, buttonWidth) => {
    return arraySum + buttonWidth;
  }, 0);

  const containerWidth = toolbarAttributesWidth;

  if (containerWidth >= buttonsWidthArraySum) {
    return INITIAL_BUTTONS_COUNT;
  }

  const visibleButtonsWidthArray = buttonsWidthArray.reduce((array, buttonWidth, index) => {
    const arraySum = sum([...array]);
    if (array.length < index) {
      return array;
    }
    if (arraySum + buttonWidth + MORE_ICON_BUTTON_WIDTH < containerWidth) {
      return [
        ...array,
        buttonWidth,
      ];
    }
    return array;
  }, []);

  const visibleButtonsCount = visibleButtonsWidthArray.length;

  if (hasArrowStyle &&
    visibleButtonsCount <= ARROW_STYLE_TOOLS_COUNT) {
    return 0;
  }

  if (hasFont &&
    visibleButtonsCount < FONT_TOOLS_COUNT) {
    return FONT_AND_SIZE_TOOLS_COUNT;
  }
  return visibleButtonsCount;
};

export const calculatePopupToolsCount = (
  toolbarAttributesLength,
  visibleToolsCount,
  hasArrowStyle,
  hasFont,
) => {
  if (toolbarAttributesLength === undefined ||
    toolbarAttributesLength === null ||
    visibleToolsCount === undefined ||
    visibleToolsCount === null) {
    return 0;
  }
  const popupToolsCount = toolbarAttributesLength - visibleToolsCount;
  if (visibleToolsCount &&
    visibleToolsCount === TOOLBAR_AND_POPUP_TOOLS_DIFF &&
    (hasArrowStyle || hasFont)) {
    return popupToolsCount - TOOLBAR_AND_POPUP_TOOLS_DIFF;
  }
  return popupToolsCount;
};

export const calculateToolbarAttributesWidth = (containerRef, toolbarAttributesRef) => {
  if (!containerRef || !toolbarAttributesRef) {
    return 0;
  }
  const container = containerRef;
  const containerWidth = container.getBoundingClientRect().width;

  const childrenWidth =
    sum((Array.from(container.children))
      .map((child) => {
        return child.getBoundingClientRect().width;
      }));

  const toolbarAttributesContainerWidth = toolbarAttributesRef.getBoundingClientRect().width;

  if (containerWidth >= childrenWidth) {
    return toolbarAttributesContainerWidth;
  }
  const widthDiff = (childrenWidth - containerWidth) + CONTAINERS_MARGIN;

  return toolbarAttributesContainerWidth - widthDiff;
};

export const getVisibleTools = (
  toolsArray,
  visibleToolsCount,
  containerType,
) => {
  if (!toolsArray ||
    visibleToolsCount === undefined ||
    visibleToolsCount === null ||
    !containerType) {
    return [];
  }
  if (containerType === toolbarAttributesContainerType.toolbar) {
    if (visibleToolsCount <= INITIAL_BUTTONS_COUNT) {
      return toolsArray;
    }
    return toolsArray.slice(0, visibleToolsCount);
  }
  if (containerType === toolbarAttributesContainerType.popover) {
    if (visibleToolsCount <= INITIAL_BUTTONS_COUNT) {
      return [];
    }
    if (visibleToolsCount === toolsArray.length) {
      return toolsArray;
    }
    return toolsArray.slice(-visibleToolsCount);
  }
  return [];
};

export const getPopoverArrowPosition = (
  viewportNode,
  anchorNode,
  popoverWidth,
) => {
  const viewportWidth = viewportNode.clientWidth;
  const toolbarRectRight = anchorNode.getBoundingClientRect().right;

  const toolbarRightMargin =
    (viewportWidth - toolbarRectRight) - (MORE_ICON_BUTTON_WIDTH / 2);

  if (toolbarRightMargin < (popoverWidth / 2)) {
    return {
      popoverArrowPosition: popoverArrowPositions.topRight,
      popoverPosition: possiblePositions.bottomRightOuter,
    };
  }
  return {
    popoverArrowPosition: popoverArrowPositions.topCenter,
    popoverPosition: possiblePositions.bottomCenterOuter,
  };
};
