import clamp from 'lodash/clamp';
import { defaultMargins } from './const';
import { limitByFrameSize } from './utils';
import { getTextToolSettings } from '../components/Tools/TextTool/utils/textToolUtils';
import { getResizeGeometry } from './resizeUtils';

// NOTE: this function has test
/**
 * Check if event is valid and return pos
 *
 * @returns {oneOfType(bool, shape({ x, y })} -
 *   false if event is not valid, otherwise - event pos
 */
export const getEventPos = (event, { pagePinching }) => {
  if (pagePinching || !event) {
    return false;
  }

  if (event.touches) {
    const touch = event.touches[0];
    if (!touch || !touch.clientX || !touch.clientY) {
      return false;
    }

    return {
      x: touch.clientX,
      y: touch.clientY,
    };
  }

  if (!event.clientX || !event.clientY) {
    return false;
  }

  return {
    x: event.clientX,
    y: event.clientY,
  };
};

/**
 * @param {Object} startPos coordinates of the start point
 * @param {number} startPos.x x coordinate of start point
 * @param {number} startPos.y y coordinate of start point
 * @param {Object} endPos coordinates of the end point
 * @param {number} endPos.x x coordinate of end point
 * @param {number} endPos.y y coordinate of end point
 * @return {Object} coordinates of rounded end point
 */
export const getRoundedEventPos = (startPos, endPos) => {
  const roundedPos = { x: endPos.x, y: endPos.y };
  const diffOnX = startPos.x - endPos.x;
  const diffOnY = startPos.y - endPos.y;
  const absDiffOnX = Math.abs(diffOnX);
  const absDiffOnY = Math.abs(diffOnY);

  if (diffOnX > 0) {
    // queter 2 or 3
    if (diffOnY > 0) {
      // queter 2
      if (absDiffOnX - absDiffOnY > 0) {
        if (absDiffOnY - (absDiffOnX / 2) > 0) {
          // 45 degree
          roundedPos.y = startPos.y - diffOnX;
        } else {
          // 90 degree
          roundedPos.y = startPos.y;
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (absDiffOnX - (absDiffOnY / 2) > 0) {
          // 45 degree
          roundedPos.x = startPos.x - diffOnY;
        } else {
          // 90 degree
          roundedPos.x = startPos.x;
        }
      }
    } else {
      // queter 3
      // eslint-disable-next-line no-lonely-if
      if (absDiffOnX - absDiffOnY > 0) {
        if (absDiffOnY - (absDiffOnX / 2) > 0) {
          // 45 degree
          roundedPos.y = startPos.y + diffOnX;
        } else {
          // 90 degree
          roundedPos.y = startPos.y;
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (absDiffOnX - (absDiffOnY / 2) > 0) {
          // 45 degree
          roundedPos.x = startPos.x + diffOnY;
        } else {
          // 90 degree
          roundedPos.x = startPos.x;
        }
      }
    }
  } else {
    // queter 1 or 4
    // eslint-disable-next-line no-lonely-if
    if (diffOnY > 0) {
      // queter 1
      if (absDiffOnX - absDiffOnY > 0) {
        if (absDiffOnY - (absDiffOnX / 2) > 0) {
          // 45 degree
          roundedPos.y = startPos.y + diffOnX;
        } else {
          // 90 degree
          roundedPos.y = startPos.y;
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (absDiffOnX - (absDiffOnY / 2) > 0) {
          // 45 degree
          roundedPos.x = startPos.x + diffOnY;
        } else {
          // 90 degree
          roundedPos.x = startPos.x;
        }
      }
    } else {
      // queter 4
      // eslint-disable-next-line no-lonely-if
      if (absDiffOnX - absDiffOnY > 0) {
        if (absDiffOnY - (absDiffOnX / 2) > 0) {
          // 45 degree
          roundedPos.y = startPos.y - diffOnX;
        } else {
          // 90 degree
          roundedPos.y = startPos.y;
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (absDiffOnX - (absDiffOnY / 2) > 0) {
          // 45 degree
          roundedPos.x = startPos.x - diffOnY;
        } else {
          // 90 degree
          roundedPos.x = startPos.x;
        }
      }
    }
  }

  return roundedPos;
};

// NOTE: this function has test
/**
 * Get diff between dragStart pos and dragEnd pos (considering offset change)
 *
 * @param {object} state - elementDraggableDecorator state
 * @param {} props - elementDraggableDecorator props
 * @returns {object} - pos diff { x, y }
 */
export const getDragPosDiff = ({
  isDragging,
  resizeIndex,
  initialOffset,
  dragStartPoint,
  dragEndPoint,
  scale,
  frameOffset,
}) => {
  if (!isDragging && !resizeIndex) {
    return { x: 0, y: 0 };
  }

  const dPoint = {
    x: dragEndPoint.x - dragStartPoint.x,
    y: dragEndPoint.y - dragStartPoint.y,
  };

  const dOffset = {
    left: frameOffset.scrollLeft - initialOffset.scrollLeft,
    top: frameOffset.scrollTop - initialOffset.scrollTop,
  };

  return {
    x: (dPoint.x + dOffset.left) / scale,
    y: (dPoint.y + dOffset.top) / scale,
  };
};

// NOTE: this function has test
/**
 * Get position of dragging element, limited by frame size, in coordinates of unscaled document
 *
 * @returns {object} position { x, y }
 */
export const getDraggingPos =
  ({
    element,
    originalSize,
    dPos,
    elemSize,
    elemPos,
    boundingEnabled = true,
  }) => {
    const margins = getTextToolSettings(element) || defaultMargins;

    const xy = { x: elemPos.x + dPos.x, y: elemPos.y + dPos.y };

    if (boundingEnabled) {
      return {
        ...limitByFrameSize(
          xy,
          originalSize,
          {
            width: elemSize.width + margins.marginLeft + margins.marginRight,
            height: elemSize.height + margins.marginTop + margins.marginBottom,
          },
        ),
        ...elemSize,
      };
    }

    return {
      ...xy,
      ...elemSize,
    };
  };

// TODO: test
export const getDraggingGeometry = ({
  isDragging,
  resizeIndex,
  elemPos,
  initialElemProps,
  originalSize,
  fConstructorLoadedAndShown,
  element,
  elemSizeFromGetSize,
  boundingEnabled = true,
  dPos,
}) => {
  const elemSize = initialElemProps
    ? {
      width: initialElemProps.width,
      height: initialElemProps.height,
    }
    : elemSizeFromGetSize;

  if (isDragging) {
    return getDraggingPos({
      element,
      originalSize,
      dPos,
      elemPos,
      elemSize,
      boundingEnabled,
    });
  }
  if (resizeIndex) {
    return getResizeGeometry({
      dPos,
      resizeIndex,
      element,
      originalSize,
      isFillable: fConstructorLoadedAndShown && element.template,
      initialGeometry: { ...initialElemProps, ...elemSize },
    });
  }
  return { ...elemPos, ...elemSize };
};

export const fixPosition = (componentPosition, componentSize, bounds) => {
  const { x, y } = componentPosition;
  const { width, height } = componentSize;

  return {
    x: clamp(x, bounds.left, bounds.right - width),
    y: clamp(y, bounds.top, bounds.bottom - height),
  };
};
