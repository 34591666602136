import PropTypes from 'prop-types';
import React from 'react';
import EditorAction from 'jsfcore/ui/EditorAction/EditorAction';
import EditorActionItem from 'jsfcore/ui/EditorAction/EditorActionItem';
import Group, { groupSizes, groupTypes } from 'jsfcore/ui/Group/Group';
import GroupBody from 'jsfcore/ui/Group/GroupBody';
import GroupItem from 'jsfcore/ui/Group/GroupItem';
import attributes from 'jsfcore/ui/attributes';
import Control, {
  controlThemes,
  controlSizes,
  controlOffsets,
} from 'jsfcore/ui/Control';
import Icon, { iconTypes, iconSizes } from 'jsfcore/ui/Icon';
import Text, { textWeights } from 'jsfcore/ui/Text';

function HeaderCloseButton(props) {
  return (
    <EditorAction>
      <EditorActionItem>
        <Group
          size={groupSizes.parent}
          type={groupTypes.compact}
        >
          <GroupBody>
            <GroupItem attributes={attributes.grow}>
              <Control
                theme={controlThemes.darkgray}
                size={controlSizes.mediumStretched}
                offset={controlOffsets.small}
                icon={
                  <Icon
                    type={iconTypes.close}
                    size={iconSizes.small}
                  />
                }
                onClick={props.onClick}
              >
                <Text
                  weight={textWeights.bold}
                  size={14}
                >
                  {props.locale}
                </Text>
              </Control>
            </GroupItem>
          </GroupBody>
        </Group>
      </EditorActionItem>
    </EditorAction>
  );
}

HeaderCloseButton.propTypes = {
  locale: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default HeaderCloseButton;
