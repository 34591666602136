import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class GhostWrapperView extends Component {
  static propTypes = {
    y: PropTypes.number.isRequired,
    x: PropTypes.number.isRequired,

    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,

    isHidden: PropTypes.bool.isRequired,

    children: PropTypes.element.isRequired,
  };

  render() {
    const { isHidden, y, x, width, height } = this.props;
    const wrapperStyle = {
      top: y,
      left: x,
      width,
      height,

      ...(isHidden
        ? { opacity: '0.0001' }
        : {}),

      position: 'absolute',
    };

    return (
      <div className="elementGhost-ElementGhost" style={wrapperStyle}>
        {this.props.children}
      </div>
    );
  }
}
