import PropTypes from 'prop-types';
import { Component } from 'react';
import getBoundedDragGeometryProps from './getBoundedDragGeometryProps';

export default class DateMinWidthBounds extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    element: PropTypes.shape({
      template: PropTypes.shape({
        width: PropTypes.number.isRequired,
        x: PropTypes.number.isRequired,
      }),
    }).isRequired,
    draggableProps: PropTypes.shape({
      resizeIndex: PropTypes.number.isRequired,
    }).isRequired,
  };

  render() {
    const { element } = this.props;
    const boundedProps = getBoundedDragGeometryProps(this.props);

    return this.props.children({
      ...this.props,
      boundedElement: {
        ...element,
        template: {
          ...element.template,
          ...boundedProps,
        },
      },
    });
  }
}
