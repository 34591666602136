import PropTypes from 'prop-types';
import React, { Component } from 'react';
import requiredIf from 'react-required-if';

import { pathFromPoints, getViewbox } from '../../../helpers/graphicUtils';
import {
  defaultZoomScale,
  positions,
  elemTypes,
} from '../../../helpers/const';

import RectToolView from './RectToolView';
import RectToolDisabledProvider from './RectToolDisabledProvider';

export default class RectTool extends Component {
  static propTypes = {
    // PAPA TODO: поправить когда будут готовы данные
    // controlPoints: PropTypes.array, // not required because it's not passed in ElementGhost
    // lineWidth: PropTypes.number.isRequired,
    fillColor: PropTypes.oneOfType([
      PropTypes.string, PropTypes.number,
    ]).isRequired,
    fillAlpha: PropTypes.number, // not required. for 'highlight' type only
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    element: PropTypes.shape({}),
    isDisabled: PropTypes.bool.isRequired,
    scale: PropTypes.number,

    // from elementDraggableDecorator
    // not required for ghost
    onResizeDragStart: PropTypes.func,
    onResizeDragMove: PropTypes.func,
    onResizeDragStop: PropTypes.func,
    isDragging: PropTypes.bool,
    resizeIndex: PropTypes.number,
    type: PropTypes.string,

    onFocus: requiredIf(PropTypes.func, (props) => {
      return props.type !== elemTypes.erase;
    }),
    onBlur: requiredIf(PropTypes.func, (props) => {
      return props.type !== elemTypes.erase;
    }),
    storeRef: requiredIf(PropTypes.func, (props) => {
      return props.type !== elemTypes.erase;
    }),

    interceptFocusDataset: PropTypes.shape({}),
  };

  static defaultProps = {
    element: null,
    fillAlpha: 1,
    // controlPoints: [0, 0, 0, 0],
    onResizeDragStart: () => {
      return false;
    },
    onResizeDragMove: () => {
      return false;
    },
    onResizeDragStop: () => {
      return false;
    },
    isDragging: false,
    resizeIndex: positions.none,
    scale: defaultZoomScale,
    type: null,

    onFocus: () => {},
    onBlur: () => {},
    storeRef: () => {},

    interceptFocusDataset: undefined,
  };

  render() {
    const {
      x,
      y,
      width,
      height,
      type,
      fillColor,
      fillAlpha,

      onFocus,
      onBlur,
      storeRef,
      interceptFocusDataset,
    } = this.props;

    // TODO: placeholder controlPoints
    const controlPoints = [
      x, y,
      x + width, y,
      x + width, y + height,
      x, y + height,
    ];
    const viewBox = getViewbox(controlPoints);
    const path = pathFromPoints(controlPoints);

    return (
      <RectToolDisabledProvider
        isDisabled={this.props.isDisabled}
        element={this.props.element}
      >
        <RectToolView
          fillColor={fillColor}
          fillAlpha={fillAlpha}
          path={path}
          viewBox={viewBox}
          onResizeDragStart={this.props.onResizeDragStart}
          onResizeDragMove={this.props.onResizeDragMove}
          onResizeDragStop={this.props.onResizeDragStop}
          isDragging={this.props.isDragging}
          isEraseElement={type === elemTypes.erase}
          resizeIndex={this.props.resizeIndex}
          scale={this.props.scale}
          element={this.props.element}
          needResizeBox={false}

          onFocus={onFocus}
          onBlur={onBlur}
          storeRef={storeRef}
          {...interceptFocusDataset}
        />
      </RectToolDisabledProvider>
    );
  }
}
