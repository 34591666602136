import get from 'lodash/get';
import memoize from 'lodash/memoize';
import PropTypes from 'prop-types';
import React from 'react';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { DraggableCore } from 'react-draggable';

import { removeElement, updateElement } from 'ws-editor-lib/actions';
import {
  FocusControllerRestoreFocusArea,
  focusControllerDraggableDecorator,
} from '@pdffiller/jsf-focuscontroller';

import ToolTypeValidation from '../../jsf-validations/components/ToolTypeValidation';
import * as Portal from '../Portal';
import RemoveButton, { removeButtonTypes } from './RemoveButton/RemoveButton';
import StoreRefProvider from '../StoreRef/StoreRefProvider';
import SimpleElementViewDragWrapper from './SimpleElementViewDragWrapper';
import ElementSimpleWrapperView from './ElementSimpleWrapperView';
import ElementIcon from './ElementIcon/ElementIcon';
import EraseDeleteBtn from './EraseDeleteBtn/EraseDeleteBtn';
import DatePickerProvider from './DatePickerProvider/DatePickerProvider';
import { cancellableOpts } from '../../store/modules/undoRedo';
import ElementTypesMapper from './ElementTypesMapper';
import {
  isDate,
  isFilledElement,
} from '../../store/helpers/functions';
import { defaultDateFormat } from '../../helpers/const';
import { elemTypes } from '../../helpers/elemTypes';

import IOSDateControl from './Mobile/IOSDateControl';

const getDecoratedDraggableCore = memoize(() => {
  return focusControllerDraggableDecorator(thisDevice)(
    DraggableCore,
    {
      width: '100%',
      height: '100%',
    },
  );
});

const locatorArgsRemoveControl = {
  position: Portal.wrapperPositions.topRightOuter,
  hideIfAnchorOutOfViewport: true,
  clampToViewport: false,
  smartPosition: false,
};

const dropdownCallbackParameters = [
  { text: '' },
  cancellableOpts,
];

const renderSimpleElement = ({ ComponentElement, ...props }, context) => {
  const {
    isDateElement,
    isDropdownElement,
    isPictureElement,
    isSignatureElement,
    isFillableElement,
    elementProps,
    isConstructorMode,
    element,
    draggableProps,
    onResize,
    scale,
    isHighlighted,
    isStretching,
    isActiveElement,
  } = props;

  const { type, subType } = element;

  const iosDateControl = (opts) => {
    const format = get(elementProps, 'validator.momentFormat', defaultDateFormat);

    return (
      <IOSDateControl
        id={element.id}
        format={format}
        initial={element.template.initial}
        {...opts}
      />
    );
  };

  return (
    // NB! не вставлять ничего сверху этого компонента
    // потому что передаются пропсы из DraggableCore
    <SimpleElementViewDragWrapper>
      {(dragWrapperProps) => {
        return (
          <ToolTypeValidation
            value={get(element, 'content.text', '')}
            validatorId={get(element, 'template.validatorId', '')}
          >
            {({ isInvalid, validator }) => {
              const useDatePicker = get(validator, 'useDatePicker', false);
              // for mobile, tablet date  we're should use iphone native controls
              const isFillableIOSMobileDate =
                thisDevice.isIOS && isDate(element) && isFillableElement && useDatePicker;

              const shouldRenderDatePickerProvider =
                isActiveElement &&
                !thisDevice.isMobile &&
                isFillableElement &&
                isDate(element);

              return (
                <StoreRefProvider>
                  {({ storeRef, getRefPromise }) => {
                    return (
                      <ElementSimpleWrapperView
                        element={element}
                        elementProps={elementProps}
                        isFillableElement={isFillableElement}
                        isActiveElement={isActiveElement}
                        isStretching={isStretching}
                        isHighlighted={isHighlighted}
                        isConstructorMode={isConstructorMode}
                        isInvalid={isInvalid}
                        storeRef={storeRef}
                        {...dragWrapperProps}
                      >
                        <ComponentElement
                          {...elementProps}
                          width={elementProps.width || 10}
                          height={elementProps.height || 10}
                          type={type}
                          subType={subType}
                          id={element.id}
                          pageId={element.pageId}
                          scale={scale}
                          isDragging={draggableProps.isDragging}
                          isFillable={isFillableElement}
                          isHighlighted={isHighlighted}
                          element={element}
                          onResizeDragStart={onResize.start}
                          onResizeDragMove={onResize.move}
                          onResizeDragStop={onResize.stop}
                          resizingGeometry={draggableProps.resizingGeometry}
                          resizeIndex={draggableProps.resizeIndex}
                          isActiveElement={isActiveElement}
                          storeValidatedTextToolSize={draggableProps.storeValidatedTextToolSize}
                          elementIcon={
                            <ElementIcon
                              element={element}
                              scale={scale}
                            />
                          }
                          isInvalid={isInvalid}
                          validator={validator}
                        >
                          {isFillableIOSMobileDate && iosDateControl}
                        </ComponentElement>
                        {shouldRenderDatePickerProvider && (
                          <FocusControllerRestoreFocusArea
                            isSafariDesktop={thisDevice.isSafariDesktop}
                          >
                            <DatePickerProvider
                              element={element}
                              getRefPromise={getRefPromise}
                            />
                          </FocusControllerRestoreFocusArea>
                        )}
                        {type === elemTypes.erase && thisDevice.isDesktop && (
                          <EraseDeleteBtn
                            className="eraseDeleteBtn-Element"
                            elementId={element.id}
                            scale={scale}
                            hasHoverer
                          />
                        )}

                        {(
                          isActiveElement &&
                          isFillableElement &&
                          isFilledElement(element) &&
                          !thisDevice.isMobile &&
                          (
                            isDateElement ||
                            isDropdownElement ||
                            isPictureElement ||
                            isSignatureElement
                          )
                        ) &&
                        <Portal.WrapperExperiment
                          getAnchorRefPromise={getRefPromise}
                          getViewportRef={context.getWorkspaceRef}
                          locatorArgs={locatorArgsRemoveControl}
                          theme={null}
                          useArrow={false}
                          zIndex={1}
                        >
                          <RemoveButton
                            onMouseDown={
                              isDropdownElement
                                ? updateElement
                                : removeElement
                            }
                            callbackParameters={
                              isDropdownElement
                                ? dropdownCallbackParameters
                                : undefined
                            }
                            type={removeButtonTypes.clear}
                          />
                        </Portal.WrapperExperiment>
                        }
                      </ElementSimpleWrapperView>
                    );
                  }}
                </StoreRefProvider>
              );
            }}
          </ToolTypeValidation>
        );
      }}
    </SimpleElementViewDragWrapper>
  );
};

renderSimpleElement.propTypes = {
  ComponentElement: PropTypes.element.isRequired,
  isDateElement: PropTypes.bool.isRequired,
  isDropdownElement: PropTypes.bool.isRequired,
  isPictureElement: PropTypes.bool.isRequired,
  isSignatureElement: PropTypes.bool.isRequired,
  isFillableElement: PropTypes.bool.isRequired,
  elementProps: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  isConstructorMode: PropTypes.bool.isRequired,
  element: PropTypes.shape({
    type: PropTypes.string.isRequired,
    subType: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    pageId: PropTypes.number.isRequired,
    template: PropTypes.shape({
      initial: PropTypes.string,
    }),
  }).isRequired,
  draggableProps: PropTypes.shape({
    isDragging: PropTypes.bool.isRequired,
    storeValidatedTextToolSize: PropTypes.func.isRequired,
    resizeIndex: PropTypes.number,
    resizingGeometry: PropTypes.shape({}),
  }).isRequired,
  onResize: PropTypes.shape({
    start: PropTypes.func,
    move: PropTypes.func,
    stop: PropTypes.func,
  }).isRequired,
  scale: PropTypes.number.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  isStretching: PropTypes.bool.isRequired,
  isActiveElement: PropTypes.bool.isRequired,
};

const renderSimpleElementView = (props, context) => {
  const { type, subType } = props.element;
  const { isFillableElement } = props;
  const DecoratedDraggableCore = getDecoratedDraggableCore();

  return (
    <ElementTypesMapper
      type={type}
      subType={subType}
    >
      {({ ComponentElement }) => {
        // mobile (can drag not fillable elements)
        if (thisDevice.isMobile && !isFillableElement) {
          return (
            <DecoratedDraggableCore
              onStart={props.onDrag.start}
              onDrag={props.onDrag.move}
              onStop={props.onDrag.stop}
            >
              {renderSimpleElement({
                ComponentElement,
                ...props,
              }, context)}
            </DecoratedDraggableCore>
          );
        }
        // desktop
        return (
          renderSimpleElement({
            ComponentElement,
            ...props,
          }, context)
        );
      }}
    </ElementTypesMapper>
  );
};

renderSimpleElementView.propTypes = {
  isFillableElement: PropTypes.bool.isRequired,
  element: PropTypes.shape({
    type: PropTypes.string.isRequired,
    subType: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    pageId: PropTypes.number.isRequired,
    template: PropTypes.shape({}),
  }).isRequired,
  onDrag: PropTypes.shape({
    start: PropTypes.func,
    move: PropTypes.func,
    stop: PropTypes.func,
  }).isRequired,
};

renderSimpleElementView.contextTypes = {
  getWorkspaceRef: PropTypes.func.isRequired,
};

export default renderSimpleElementView;
