import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { selectors } from '../..';
import eventEmitter from './eventEmitter';

@connect(
  (state) => {
    return {
      isToolDragging: selectors.base.getIsToolDragging(state),
    };
  },
)
export default class GhostCreateTrigger extends Component {
  static propTypes = {
    createElement: PropTypes.func.isRequired,
    isToolDragging: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    eventEmitter.subscribe(this.onClick);
    document.addEventListener('mouseup', this.onMouseUp);
  }

  componentWillUnmount() {
    eventEmitter.unsubscribe(this.onClick);
    document.removeEventListener('mouseup', this.onMouseUp);
  }

  onMouseUp = (event) => {
    if (this.props.isToolDragging) {
      this.props.createElement(event);
    }
  };

  onClick = (event) => {
    this.props.createElement(event);
  };

  render() {
    return null;
  }
}
