import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cls from 'classnames';

const nullFunc = () => {};

export const controlSizes = {
  parent: 'parent',
  medium: 'medium',
  small: 'small',
  extraSmall: 'extra-small',
  extraSmallRectangular: 'extra-small-rectangular',
  mediumStretched: 'medium-stretched',
  parentHeight: 'parent-height',
  smallStretched: 'small-stretched',
  smallRectangular: 'small-rectangular',
  mediumRectangular: 'medium-rectangular',
  touch: 'touch',
  null: null,
};

export const controlOffsets = {
  large: 'large',
  medium: 'medium',
  small: 'small',
  extraSmall: 'extra-small',
  null: null,
};

export const controlAligns = {
  center: 'center',
  null: null,
};

export const controlThemes = {
  simpleDashed: 'simple-dashed',
  transparent: 'transparent',
  transparentGray: 'transparent-gray',
  transparentGrayUnderlined: 'transparent-gray-underlined',
  transparentBlue: 'transparent-blue',
  secondary: 'secondary',
  primary: 'primary',
  multicolored: 'multicolored',
  gray: 'gray',
  lite: 'lite',
  light: 'light',
  spark: 'spark',
  lackluster: 'lackluster',
  darkDashed: 'dark-dashed',
  silver: 'silver',
  dark: 'dark',
  underline: 'underline',
  simpleSolid: 'simple-solid',
  success: 'success',
  grayDanger: 'gray-danger',
  null: null,
};

export const controlBehaviors = {
  iconOnly: 'icon-only',
  wrapText: 'wrap-text',
  overflowText: 'overflow-text',
  rowRecursive: 'row-recursive',
  stretchedContent: 'stretched-content',
  valignBaseline: 'valign-baseline',
  column: 'column',
  null: null,
};

export const controlJustifyes = {
  flexStart: 'flex-start',
  center: 'center',
  null: null,
};

export const controlRoles = {
  switch: 'switch',
  tab: 'tab',
  button: 'button',
  null: null,
};

export const controlTags = {
  a: 'a',
  button: 'button',
  label: 'label',
};

export const controlTypes = {
  dragHandler: 'drag-handler',
  dropdown: 'dropdown',
  null: null,
};

export const controlVerticalAligns = {
  baseline: 'baseline',
  null: null,
};

export default class Control extends PureComponent {
  static propTypes = {
    input: PropTypes.element,
    icon: PropTypes.element,
    boxIcon: PropTypes.element,
    hint: PropTypes.element,
    children: PropTypes.node,
    size: PropTypes.oneOf(
      Object.values(controlSizes),
    ),
    offset: PropTypes.oneOf(
      Object.values(controlOffsets),
    ),
    theme: PropTypes.oneOf(
      Object.values(controlThemes),
    ),
    behavior: PropTypes.oneOf(
      Object.values(controlBehaviors),
    ),
    justify: PropTypes.oneOf(
      Object.values(controlJustifyes),
    ),
    role: PropTypes.oneOf(
      Object.values(controlRoles),
    ),
    tag: PropTypes.oneOf(
      Object.values(controlTags),
    ),
    type: PropTypes.oneOf(
      Object.values(controlTypes),
    ),
    align: PropTypes.oneOf(
      Object.values(controlAligns),
    ),
    verticalAlign: PropTypes.oneOf(
      Object.values(controlVerticalAligns),
    ),
    className: PropTypes.string,
    isBusy: PropTypes.bool,
    isDisabled: PropTypes.bool,
    disabled: PropTypes.bool,
    ariaLabel: PropTypes.string,
    title: PropTypes.string,
    inheritRadius: PropTypes.bool,
    roundRadius: PropTypes.bool,
    roleGeneral: PropTypes.bool,

    onClick: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseUp: PropTypes.func,
    onMouseOut: PropTypes.func,
    onMouseOver: PropTypes.func,
    isChecked: PropTypes.bool,
    isSelected: PropTypes.bool,
    isPressed: PropTypes.bool,
  };

  static defaultProps = {
    behavior: controlBehaviors.overflowText,
    size: controlSizes.mediumStretched,
    offset: controlOffsets.medium,
    justify: controlJustifyes.flexStart,
    theme: controlThemes.transparentGray,
    role: controlRoles.switch,
    tag: controlTags.button,
    type: controlTypes.null,
    align: controlAligns.null,
    verticalAlign: controlVerticalAligns.null,
    input: null,
    icon: null,
    boxIcon: null,
    hint: null,
    className: null,
    ariaLabel: null,
    title: null,
    inheritRadius: false,
    roundRadius: false,
    roleGeneral: false,
    isBusy: null,
    isChecked: null,
    isSelected: null,
    isPressed: null,

    disabled: false,
    isDisabled: null,

    onMouseDown: nullFunc,
    onMouseUp: nullFunc,
    onMouseOut: nullFunc,
    onMouseOver: nullFunc,
    onClick: nullFunc,
    children: null,
  };

  render() {
    const {
      icon,
      boxIcon,
      hint,
      children,
      input,
      tag,
      type,
      align,
      verticalAlign,
      size,
      offset,
      theme,
      inheritRadius,
      roleGeneral,
      roundRadius,
      justify,
      behavior,
      className,
      role,
      isBusy,
      isDisabled,
      disabled,
      isChecked,
      isSelected,
      isPressed,

      onClick,
      onMouseDown,
      onMouseUp,
      onMouseOut,
      onMouseOver,
    } = this.props;
    const Tag = tag;

    return (
      <Tag
        className={
          cls('control', className, {
            [`control--size--${size}`]: size,
            [`control--offset--${offset}`]: offset,
            [`control--theme--${theme}`]: theme,
            [`control--behavior--${behavior}`]: behavior,
            [`control--justify--${justify}`]: justify,
            [`control--type--${type}`]: type,
            [`control--align--${align}`]: align,
            [`control--vertical-align--${verticalAlign}`]: verticalAlign,
            '_inherit-radius': inheritRadius,
            '_role-general': roleGeneral,
            '_round-radius': roundRadius,
            'is-checked': isChecked,
            'is-disabled': disabled || isDisabled,
            'is-selected': isSelected,
            'is-pressed': isPressed,
          })
        }
        role={role}
        aria-checked={isChecked}
        aria-label={this.props.ariaLabel}
        title={this.props.title}
        aria-selected={isSelected}
        aria-busy={isBusy}
        aria-disabled={disabled || isDisabled}
        aria-pressed={isPressed}
        type="button"
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseOut={onMouseOut}
        onMouseOver={onMouseOver}
        disabled={disabled || isDisabled}
      >
        <span className="control__body">
          {hint}
          {input && input}
          {icon &&
            <span className="control__icon">
              {icon}
            </span>
          }
          {boxIcon &&
            <div className="control__box">
              {boxIcon}
            </div>
          }
          {children &&
            <span className="control__text">
              {children}
            </span>
          }
        </span>
      </Tag>
    );
  }
}
