import * as conditionsSelectors from '../../selectors';
import * as choiseByClicking from './choiseByClicking';
import { getDependency, dependenciesMap } from '../../di';

const onClickElementInChoiseByClickingMode =
  (event, id) => {
    return (dispatch, getState) => {
      // injected dependencies
      const jsfcoreSelectors = getDependency(dependenciesMap.jsfcoreSelectors);
      const isRadioElement = getDependency(dependenciesMap.isRadioElement);

      const state = getState();
      const choiseByClickingModeIsActive =
        conditionsSelectors.getChoiseByClickingModeIsActive(state);

      if (choiseByClickingModeIsActive) {
        event.stopPropagation();
        const element = jsfcoreSelectors.elements.getElementByIdFactory(id)(state);

        const value = isRadioElement(element)
          ? element.template.radioValue
          : null;

        dispatch(
          choiseByClicking.disable(
            element.template && element.template.name,
            value,
          ),
        );
        return true;
      }

      return false;
    };
  };

const onMouseDownElementInChoiseByClickingMode =
  (event) => {
    return (dispatch, getState) => {
      const state = getState();
      const choiseByClickingModeIsActive =
        conditionsSelectors.getChoiseByClickingModeIsActive(state);

      if (choiseByClickingModeIsActive) {
        event.stopPropagation();
      }
    };
  };

export {
  onClickElementInChoiseByClickingMode,
  onMouseDownElementInChoiseByClickingMode,
};
