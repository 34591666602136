import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class FillablePlaceholderText extends Component {
  static propTypes = {
    children: PropTypes.string.isRequired,
    style: PropTypes.shape({}),
  };

  static defaultProps = {
    style: null,
  };

  render() {
    return (
      <div className="fillable-placeholder__text">
        <span style={this.props.style}>
          {this.props.children}
        </span>
      </div>
    );
  }
}
