const getRenderTextForDateFormat = (momentFormat) => {
  const renderText = momentFormat;

  const replacePatterns = [
    // date
    ['YYYY', '0000'],
    ['YY', '00'],
    ['MMM', 'WWW'],
    ['MM', '00'],
    ['M', '00'],
    ['DD', '00'],
    ['D', '00'],

    // time
    ['hh', '00'],
    ['mm', '00'],
    ['ss', '00'],
    ['A', 'AM'],
  ];

  const replacePattern = (result, [patternKey, replaceText]) => {
    return result.replace(patternKey, replaceText);
  };

  return replacePatterns.reduce(replacePattern, renderText);
};

export default getRenderTextForDateFormat;
