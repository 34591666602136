import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DEFAULT_MESSAGES } from 'jsfcore/store/modules/locale';

import * as Ui from '../../../ui';

@connect(
  (state) => {
    return {
      message: state.ws.message,
    };
  },
)
export default class ToolbarAdvice extends Component {
  static propTypes = {
    message: PropTypes.string,
  };

  static defaultProps = {
    message: '',
  };

  render() {
    return (
      <Ui.SettingBar.StatusMessage
        icon={
          this.props.message === DEFAULT_MESSAGES.saved
            ? <Ui.Icon type={Ui.icon.types.save} size={Ui.icon.sizes.small} />
            : null
        }
        text={
          <Ui.Text
            size={13}
            align={Ui.text.aligns.right}
            weight={Ui.text.weights.normal}
          >
            {this.props.message}
          </Ui.Text>
        }
      />
    );
  }
}
