import React from 'react';
import PropTypes from 'prop-types';
import ImagePlaceholder from '../../../ui/ImagePlaceholder/ImagePlaceholder';
import ElementIconWrapper from '../../Element/ElementIcon/ElementIconWrapper';

const UnfilledSignToolRender = ({
  elementIcon,
  getUnfilledInnerText,
}) => {
  const innerText = getUnfilledInnerText();

  return (
    <div className="fillable-field__overlay">
      <ElementIconWrapper>{elementIcon}</ElementIconWrapper>
      <ImagePlaceholder innerText={innerText} />
    </div>
  );
};

UnfilledSignToolRender.propTypes = {
  elementIcon: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.bool,
  ]),
  getUnfilledInnerText: PropTypes.func.isRequired,
};

UnfilledSignToolRender.defaultProps = {
  elementIcon: null,
};

export default UnfilledSignToolRender;
