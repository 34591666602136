import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class FillablePlaceholderIcon extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,

    style: PropTypes.shape({}),
  };

  static defaultProps = {
    style: null,
  };

  render() {
    return (
      <div
        className="fillable-placeholder__icon"
        style={this.props.style}
      >
        {this.props.children}
      </div>
    );
  }
}
