import moment from 'moment';

export const valid = { isValid: true, errors: [] };

export const viewModes = {
  freeform: 'freeform',
  annotation: 'annotation',
  limitedEdit: 'limited-edit',
  viewOnly: 'view-only',
  guided: 'guided',
};

export const inviteModes = {
  field: 'field',
};

export const organizationSettings = {
  addSignatureStampWithName: 'add_signature_stamp_with_name',
  inviteDeclineRedirectURL: 'invite_decline_redirect_url',
  logoutOnSigning: 'logout_on_signing',
};

export const userSettings = {
  noUserSignatureReturn: 'no_user_signature_return',
};

export const signerEmptyObjectTemplate = {
  roleId: '',
  name: '',
  defaultEmail: '',
  inviterRole: false,
  declineBySignature: false,
  errors: {},
};

export const elemTypes = {
  signature: 'signature',
  text: 'text',
  date: 'date',
  initials: 'initials',
  checkbox: 'checkmark',
  dropdown: 'dropdown',
  attachment: 'attachment',
  radio: 'radio',
  formula: 'formula',
  smart: 'smartfield',
  salesforce: 'salesforce',
};

export const elementSettingsTypes = {
  allowCustomText: 'allowCustomText',
  addRadioButton: 'addRadioButton',
  advanced: 'advanced',
  answerChoices: 'list',
  conditional: 'conditional',
  data: 'data',
  default: 'default',
  enterValues: 'enterValues',
  information: 'information',
  label: 'initials',
  payment: 'payment',
  prefilled: 'prefilled',
  radioValue: 'radioValue',
  groupName: 'groupName',
  required: 'required',
  role: 'roleId',
  uniqueFieldName: 'name',
  validation: 'validatorId',
};

export const helpStepSelectors = {
  mainPage: {
    one: 'MAIN_PAGE_HELP_STEP_1',
    two: 'MAIN_PAGE_HELP_STEP_2',
    three: 'MAIN_PAGE_HELP_STEP_3',
    four: 'MAIN_PAGE_HELP_STEP_4',
    five: 'MAIN_PAGE_HELP_STEP_5',
    six: 'MAIN_PAGE_HELP_STEP_6',
  },
  editSigners: {
    one: 'EDIT_SIGNERS_HELP_STEP_1',
    two: 'EDIT_SIGNERS_HELP_STEP_2',
    three: 'EDIT_SIGNERS_HELP_STEP_3',
    four: 'EDIT_SIGNERS_HELP_STEP_4',
    five: 'EDIT_SIGNERS_HELP_STEP_5',
    six: 'EDIT_SIGNERS_HELP_STEP_6',
  },
  paymentRequest: {
    one: 'PAYMENT_REQUEST_HELP_STEP_1',
    two: 'PAYMENT_REQUEST_HELP_STEP_2',
    three: 'PAYMENT_REQUEST_HELP_STEP_3',
    four: 'PAYMENT_REQUEST_HELP_STEP_4',
  },
};

export const SN_HEADER_SIZE = 47;

export const emptyValidatorValue = 'none';

export const isEmptyValidator = (validatorId) => {
  return !validatorId || validatorId === emptyValidatorValue;
};

export const helpIds = {
  paymentHelp: 'paymentHelp',
};

export const getFileSizeStringValue = (size) => {
  const mb = size / 1024 / 1024;
  const roundedMb = Math.round(mb * 100) / 100;
  return `${roundedMb}MB`;
};

export const getCreatedFromTimestamp = (timestamp) => {
  // timestamp in sec
  return (
    // convert to ms
    moment(timestamp * 1000)
      .format('ddd MMM D YYYY')
  );
};

export const downloadFile = (url, name) => {
  const link = document.createElement('a');
  link.download = name;
  link.href = url;
  link.click();
};

export const QAFlags = {
  dataTooltype: {
    fieldSignature: 'field-signature',
    fieldText: 'field-text',
    fieldDatetime: 'field-datetime',
    fieldInitials: 'field-initials',
    fieldCheckbox: 'field-checkbox',
    fieldRadiobutton: 'field-radiobutton',
    fieldAttachment: 'field-attachment',
    fieldEnumeration: 'field-enumeration',
    fieldFormula: 'field-formula',
    fieldSmart: 'smartfield',
    fieldSalesforce: 'salesforce',
    signature: 'signature',
    text: 'text',
    datetime: 'datetime',
    checkbox: 'checkbox',
  },
};

export const defaultSimpleSignatureSize = { width: 200, height: 70 };

export const disableClickOutsideAttributeName = 'data-disable-click-outside';

export const nullFunc = () => {
  return undefined;
};

export const mixBase64Header = (base64WithoutHeader) => {
  return `data:image/png;base64,${base64WithoutHeader}`;
};

export const snGridBodyId = 'snGridBodyId';

export const possibleZoomRangeSignNow = [
  { value: 0.50, label: '50%', visible: true },
  { value: 0.60, label: '60%' },
  { value: 0.70, label: '70%' },
  { value: 0.80, label: '80%' },
  { value: 0.90, label: '90%' },
  { value: 1.00, label: '100%', visible: true },
  { value: 1.10, label: '110%' },
  { value: 1.20, label: '120%' },
  { value: 1.30, label: '130%' },
  { value: 1.40, label: '140%' },
  { value: 1.50, label: '150%', visible: true },
  { value: 1.60, label: '160%' },
  { value: 1.70, label: '170%' },
  { value: 1.80, label: '180%' },
  { value: 1.90, label: '190%' },
  { value: 2.00, label: '200%', visible: true },
  { value: 2.10, label: '210%' },
  { value: 2.20, label: '220%' },
  { value: 2.30, label: '230%' },
  { value: 2.40, label: '240%' },
  { value: 2.50, label: '250%', visible: true },
  { value: 2.60, label: '260%' },
  { value: 2.70, label: '270%' },
  { value: 2.80, label: '280%' },
  { value: 2.90, label: '290%' },
  { value: 3.00, label: '300%', visible: true },
];

export const zoomTick = 0.10;
