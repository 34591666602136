import updateElement from './updateElement';
import { toolbar, getOriginalSize } from '../selectors';
import { prepareNewPropsOnLineWidthChange } from '../../helpers/graphicUtils';
import { cancellableOpts } from '../modules/undoRedo';

const setLineWidth = (lineWidth) => {
  return (dispatch, getState) => {
    const state = getState();
    const elementProps = toolbar.getElementPropsForToolbar(state);
    const { id, isGhost, type, pageId } = elementProps;
    const originalSize = getOriginalSize(state, pageId);

    const newProps = prepareNewPropsOnLineWidthChange(
      type,
      elementProps,
      lineWidth,
      originalSize,
      isGhost,
    );

    if (newProps) {
      return dispatch(updateElement(
        id, newProps, cancellableOpts,
      ));
    }

    return false;
  };
};

export default setLineWidth;
