import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { onOkClicked } from '../../../store/modules/events';
import ElementTooltipView from './ElementTooltipView';
import { stopEvent } from '../../../helpers/utils';
import { sizeChangeInterval } from '../../../helpers/const';
import { thunks } from '../../..';

import { typesHasSize, getSizeIcons } from '../../../helpers/elemTypes';

export const getButtonsCount = (haveSizeButtons) => {
  return haveSizeButtons
    ? 5
    : 3;
};

@connect(
  null, {
    onOkClicked,
    sizeUp: thunks.sizeUp,
    sizeDown: thunks.sizeDown,
    remove: thunks.remove,
  },
)
export default class ElementTooltip extends Component {
  static propTypes = {
    element: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
    elementProps: PropTypes.shape({
      y: PropTypes.number.isRequired,
    }).isRequired,
    remove: PropTypes.func.isRequired,
    sizeUp: PropTypes.func.isRequired,
    sizeDown: PropTypes.func.isRequired,
    isActiveElement: PropTypes.bool.isRequired,
    isDragging: PropTypes.bool.isRequired,

    onOkClicked: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,

    // next methods are not required for fillables
    onDragStart: PropTypes.func,
    onDragMove: PropTypes.func,
    onDragStop: PropTypes.func,
  };

  static defaultProps = {
    onDragStart: () => {
      return false;
    },
    onDragMove: () => {
      return false;
    },
    onDragStop: () => {
      return false;
    },
  };

  constructor(props) {
    super(props);
    this.sizeChangeInterval = null;
    this.storedRemoveSizeChangeInterval = null;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ isActiveElement }) {
    if (!isActiveElement && this.props.isActiveElement) {
      this.clearSizeChangeInterval();
    }
  }

  shouldComponentUpdate({ isDragging }) {
    // prevent toooltip updating during drag
    // also this helps to rerender tooltip position after drag(if it needed)
    return !isDragging;
  }

  componentWillUnmount() {
    this.clearSizeChangeInterval();
    this.props.onMouseLeave();
  }

  getTooltipStyle = () => {
    return {
      pointerEvents: 'auto',
    };
  };

  addSizeChangeInterval = (sizeUp) => {
    return (event) => {
      if (event.button !== 0) {
        return;
      } // do it only for left mouse button
      stopEvent(event);
      this.storedRemoveSizeChangeInterval = this.removeSizeChangeInterval(sizeUp);

      document.addEventListener('mouseup', this.storedRemoveSizeChangeInterval);
      this.sizeChangeInterval = setInterval(this.changeSize(sizeUp), sizeChangeInterval);
    };
  }

  removeSizeChangeInterval = (sizeUp) => {
    return (event) => {
      stopEvent(event);
      this.changeSize(sizeUp)();
      this.clearSizeChangeInterval();
    };
  }

  clearSizeChangeInterval = () => {
    clearInterval(this.sizeChangeInterval);
    document.removeEventListener('mouseup', this.storedRemoveSizeChangeInterval);
  };

  changeSize = (sizeUp) => {
    return () => {
      if (sizeUp) {
        this.props.sizeUp();
      } else {
        this.props.sizeDown();
      }
    };
  }

  haveSizeButtons = () => {
    return typesHasSize.includes(this.props.element.type);
  }

  onClickRemove = (event) => {
    stopEvent(event);
    this.props.remove(this.props.element.id);
  }

  render() {
    const { element } = this.props;
    return (
      <ElementTooltipView
        haveSizeButtons={this.haveSizeButtons()}
        sizeIcons={getSizeIcons(element)}
        style={this.getTooltipStyle()}
        onClickMove={this.onClickMove}
        onClickOk={this.props.onOkClicked}
        onMouseDownSizeUp={this.addSizeChangeInterval(true)}
        onMouseDownSizeDown={this.addSizeChangeInterval(false)}
        onClickRemove={this.onClickRemove}
        onDragStart={this.props.onDragStart}
        onDragMove={this.props.onDragMove}
        onDragStop={this.props.onDragStop}
        isDragging={this.props.isDragging}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
      />
    );
  }
}
