const Hammer = __CLIENT__
  ? require('hammerjs')
  : undefined;

export default Hammer;

const MOBILE_REGEX = /mobile|tablet|ip(ad|hone|od)|android/i;

const SUPPORT_TOUCH = __CLIENT__ && window.ontouchstart !== undefined;
const SUPPORT_ONLY_TOUCH = SUPPORT_TOUCH && MOBILE_REGEX.test(navigator.userAgent);

// const SUPPORT_POINTER_EVENTS = prefixed(window, 'PointerEvent') !== undefined;
// const VENDOR_PREFIXES = ['', 'webkit', 'Moz', 'MS', 'ms', 'o'];
// function prefixed(obj, property) {
//   var prefix, prop;
//   var camelProp = property[0].toUpperCase() + property.slice(1);
//
//   var i = 0;
//   while (i < VENDOR_PREFIXES.length) {
//     prefix = VENDOR_PREFIXES[i];
//     prop = (prefix) ? prefix + camelProp : property;
//
//     if (prop in obj) {
//       return prop;
//     }
//     i++;
//   }
//   return undefined;
// }

export const inputType = () => {
  if (SUPPORT_ONLY_TOUCH) {
    return Hammer.TouchInput;
  }

  if (!SUPPORT_TOUCH) {
    return Hammer.MouseInput;
  }

  return Hammer.TouchMouseInput;
};
