import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  getStyle,
} from './utils/textToolUtils';
import { defaultLineHeight } from '../../../helpers/const';

export default class PlaceholderView extends Component {
  static propTypes = {
    placeholder: PropTypes.string.isRequired,

    // for style
    paddingTop: PropTypes.number,
    align: PropTypes.string,
    fontSize: PropTypes.number,
    lineHeight: PropTypes.number,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    bold: PropTypes.bool,
    italic: PropTypes.bool,
    underline: PropTypes.bool,
    fontFamily: PropTypes.string,
    fontColor: PropTypes.string,
    padding: PropTypes.number.isRequired,
  };

  static defaultProps = {
    paddingTop: null,
    align: 'bottom',
    fontSize: 10,
    lineHeight: defaultLineHeight,
    bold: false,
    italic: false,
    underline: false,
    fontFamily: 'Arial',
    fontColor: '000',
  }

  styles = () => {
    const argForStyle = {
      paddingTop: this.props.paddingTop,
      align: this.props.align,
      fontSize: this.props.fontSize,
      lineHeight: this.props.lineHeight,
      width: this.props.width,
      height: this.props.height,
      bold: this.props.bold,
      italic: this.props.italic,
      underline: this.props.underline,
      fontFamily: this.props.fontFamily,
      fontColor: this.props.fontColor,
      padding: this.props.padding,
    };
    const isForTextarea = true;

    return {
      divStyle: getStyle(argForStyle, !isForTextarea),
      textareaStyle: getStyle(argForStyle, isForTextarea),
    };
  };

  render() {
    const { divStyle, textareaStyle } = this.styles();

    const { fontSize, lineHeight, height, padding, paddingTop } = textareaStyle;
    const { pFontSize, pHeight, pPadding, pPaddingTop } = {
      pFontSize: parseFloat(fontSize, 10),
      pHeight: parseFloat(height, 10),
      pPadding: parseFloat(padding, 10),
      pPaddingTop: parseFloat(paddingTop, 10),
    };

    /**
     * Здесь для отображения placeholder'a хотелось использовать textarea для
     * того, чтобы рендеринг был одинаковым с textToolView. Но из-за
     * textOverflow: 'ellipsis' нужен div. Поэтому эмулируем то, как textarea
     * выбирает lineHeight для непомещающегося текста. Сравниваем высоту области
     * для текста с дефолтным lineHeight, и если итоговый lineHeight в пикселях
     * больше области - берем среднее арифметическое от максимально-возможного
     * в этом поле lineHeight и дефолтным lineHeight
     */
    const maxHeight = pHeight - pPadding - pPaddingTop;
    const heightOfLineWithStdLineHeight = pFontSize * lineHeight;
    const lh = heightOfLineWithStdLineHeight > maxHeight
      ? ((maxHeight / pFontSize) + lineHeight) / 2
      : lineHeight;

    return (
      <div
        className="textTool-TextTool"
        style={{
          ...divStyle,
          position: 'absolute',
          top: 0,
          left: 0,
          opacity: 0.5,
        }}
      >
        <div
          style={{
            ...textareaStyle,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            lineHeight: lh,
          }}
        >
          {this.props.placeholder}
        </div>
      </div>
    );
  }
}
