import React from 'react';
import PropTypes from 'prop-types';
import { optionHighlighter } from './dropdownToolUtils';

const HighlightedText = ({
  searchedValue,
  label,
}) => {
  if (searchedValue) {
    const highlightedContent = optionHighlighter(searchedValue.trim(), label);
    return (
      <>
        {highlightedContent.start}
        <b>{highlightedContent.highlightedText}</b>
        {highlightedContent.end}
      </>
    );
  }
  return label;
};

HighlightedText.propTypes = {
  label: PropTypes.string.isRequired,
  searchedValue: PropTypes.string.isRequired,
};

export default HighlightedText;
