import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { defaultMemoize } from 'reselect';
import fill from 'lodash/fill';

import { selectors } from '../..';
import { ImmediateFrameOffsetProviderDefault } from './ImmediateFrameOffsetProvider';

export const getFrameOffsetsCustomContinious =
  defaultMemoize(selectors.getFrameOffsetsWithoutReselect);

const fillFrameScrollsDefault = ({ length = 0 }) => {
  return fill(Array(length), { scrollTop: 0, scrollLeft: 0 });
};

@connect(
  (state) => {
    return {
      frameSizes: selectors.getFrameSizes(state),
      workspace: selectors.base.getWorkspace(state),
      frameScrolls: selectors.base.getFrameScrolls(state),
      pagesSettings: selectors.navigation.getPagesSettings(state),
      scales: selectors.base.getScales(state),
    };
  },
)
class ImmediateFrameOffsetProviderSNF extends ImmediateFrameOffsetProviderDefault {
  static propTypes = {
    ...ImmediateFrameOffsetProviderDefault.propTypes,
    pagesSettings: PropTypes.arrayOf(
      PropTypes.shape({
        originalSize: PropTypes.shape({
          width: PropTypes.number.isRequired,
          height: PropTypes.number.isRequired,
        }).isRequired,
      }).isRequired,
    ).isRequired,
    scales: PropTypes.arrayOf(
      PropTypes.number,
    ).isRequired,
  };

  getFrameOffsetsWithoutScroll = () => {
    const { frameSizes, workspace } = this.props;
    return getFrameOffsetsCustomContinious(
      frameSizes,
      workspace,
      fillFrameScrollsDefault({ length: frameSizes.length }),
      this.props.pagesSettings,
      this.props.scales,
    );
  }

  getFrameOffsetsWithScroll = () => {
    const { frameSizes, workspace } = this.props;
    const { frameScrolls } = this.state;
    return getFrameOffsetsCustomContinious(
      frameSizes,
      workspace,
      frameScrolls,
      this.props.pagesSettings,
      this.props.scales,
    );
  };
}

export default ImmediateFrameOffsetProviderSNF;
