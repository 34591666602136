import omit from 'lodash/omit';
import some from 'lodash/some';
import clamp from 'lodash/clamp';
import { defaultLineHeight } from 'jsfcore/helpers/const';
import { additionalToolIdPrefix } from 'jsfcore/helpers/elemTypes';

import {
  fCTypes,
  elemFCTypes,
  hasIconCenter,
  cells,
  hintValuesByType,
  minLineHeight,
  maxLineHeight,
} from './const';
import toolsMock from './toolsMock.json';

export const fConstructorHasCorrectType = (type) => {
  return Object.values(elemFCTypes).some((t) => {
    return t === type;
  });
};

export const fConstructorHasCorrectProps = (el) => {
  return el && el.type && el.template;
};

export const hasGhostIconCenter = (type) => {
  return hasIconCenter.some((el) => {
    return el === type;
  });
};

export const getFContructorActiveElType = (activeElement) => {
  return (
    (
      fConstructorHasCorrectProps(activeElement) &&
      fConstructorHasCorrectType(activeElement.type)
    )
      ? fCTypes[activeElement.type][activeElement.subType || 'none']
      : undefined
  );
};

export const getActiveElementToolId = (activeElement) => {
  if (activeElement && activeElement.template && activeElement.template.toolId) {
    return activeElement.template.toolId;
  }

  return false;
};

export const getCellQuantity = (width) => {
  return Math.round(width / 20);
};

export const getCheckMarkValue = (arrangement) => {
  return arrangement === cells;
};

export const getIsAdditionalToolId = (toolId) => {
  const [toolType] = toolId.split('_');
  return toolType === additionalToolIdPrefix;
};

export const getFieldTypesByToolId = (toolId) => {
  const [toolType] = toolId.split('_');
  const { type, subType } = toolsMock[toolType].find((tool) => {
    return tool.id === toolId;
  });

  return { type, subType };
};

export const getLegacyActiveElementTypeByToolId = (toolId) => {
  const { type, subType } = getFieldTypesByToolId(toolId);
  return fCTypes[type][subType || 'none'];
};

export const getAdvancedHintValueByToolId = ({ toolId, locale }) => {
  const type = getLegacyActiveElementTypeByToolId(toolId);
  return hintValuesByType[type].reduce(
    (acc, prop) => {
      return acc.concat(locale[prop], '<br />');
    }, '',
  );
};

export const getTransformStyle = (position) => {
  return {
    transform: `translate(${position.x}px, ${position.y}px)`,
  };
};

export const getDefaultLabel = ({ tools, toolId }) => {
  return tools[toolId].defaultLabel;
};

export const getToolUiName = ({ tools, toolId }) => {
  return tools[toolId].uiName;
};

export const formatDBFieldName = (value) => {
  return value.trim().toUpperCase();
};

export const getHasFieldNameAlreadyExistInOtherFCTypes = (
  splittedDBFieldNames,
  activeElementToolId,
  fieldName,
) => {
  const formattedFieldName = formatDBFieldName(fieldName);
  const activeElementType = getLegacyActiveElementTypeByToolId(activeElementToolId);
  const unavailableFieldNames = omit(splittedDBFieldNames, [activeElementType]);

  const existedFieldName = some(
    unavailableFieldNames,
    (fieldNamesByFCType) => {
      return some(fieldNamesByFCType, (fieldNameByFCType) => {
        return formatDBFieldName(fieldNameByFCType) === formattedFieldName;
      });
    },
  );

  return existedFieldName;
};

export const getInitialLineHeight = (element) => {
  const { lineHeight } = element.template;
  return lineHeight !== undefined
    ? lineHeight
    : defaultLineHeight;
};

export const getIsLineHeightValid = (lineHeight) => {
  if (lineHeight === '' || lineHeight === 0) {
    return true;
  }
  return lineHeight >= minLineHeight && lineHeight <= maxLineHeight;
};

export const getValidLineHeightValue = (value) => {
  const lineHeight = value.toFixed(2);
  return clamp(lineHeight, minLineHeight, maxLineHeight);
};
