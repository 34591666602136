import PropTypes from 'prop-types';
import { Component } from 'react';
import { isTextToolBasedElement } from '../../../helpers/elemTypes';
import { getDragPosDiff, getDraggingGeometry } from '../../../helpers/dragUtils';
import { selectors } from '../../..';

export default class ElementDragFuncProvider extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    isFillable: PropTypes.bool,
    element: PropTypes.shape({
      pageId: PropTypes.number.isRequired,
      content: PropTypes.object,
      template: PropTypes.object,
    }).isRequired,
    fConstructorLoadedAndShown: PropTypes.bool.isRequired,
    boundingEnabled: PropTypes.bool,
    getFrameOffset: PropTypes.func.isRequired,
  };

  static defaultProps = {
    boundingEnabled: true,
    isFillable: false,
  };

  static contextTypes = {
    getPageViewport: PropTypes.func,
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
    }).isRequired,
  };

  getScale = () => {
    return selectors.getScale(
      this.context.store.getState(),
      this.props.element.pageId,
    );
  };

  getDPos = (state) => {
    return getDragPosDiff({
      isDragging: state.isDragging,
      resizeIndex: state.resizeIndex,
      initialOffset: state.initialOffset,
      dragStartPoint: state.dragStartPoint,
      dragEndPoint: state.dragEndPoint,
      scale: this.getScale(),
      frameOffset: this.props.getFrameOffset(),
    });
  };

  getActualDraggingGeometry = ({ elemSizeFromGetSize, state, dPos = this.getDPos(state) }) => {
    const viewport = this.context.getPageViewport();

    return getDraggingGeometry({
      isDragging: state.isDragging,
      resizeIndex: state.resizeIndex,
      elemPos: state.elemPos,
      initialElemProps: state.initialElemProps,
      fConstructorLoadedAndShown: this.props.fConstructorLoadedAndShown,
      element: this.props.element,
      originalSize: viewport.originalSize,
      frameOffset: this.props.getFrameOffset(),
      elemSizeFromGetSize,
      boundingEnabled: state.boundingEnabled || this.props.boundingEnabled,
      dPos,
    });
  };

  getRenderMixin = ({ elemSizeFromGetSize, state }) => {
    const { element, fConstructorLoadedAndShown } = this.props;
    const newGeometry = this.getActualDraggingGeometry({ elemSizeFromGetSize, state });
    const objToMerge =
      isTextToolBasedElement(element) && !state.isDragging
        ? state.elemPos
        : newGeometry;

    if (fConstructorLoadedAndShown && this.props.isFillable) {
      return ({
        element: {
          ...element,
          template: {
            ...element.template,
            ...objToMerge,
            ...(state.resizeIndex
              ? newGeometry
              : {}),
          },
        },
      });
    }

    return ({
      resizingGeometry: state.resizeIndex
        ? newGeometry
        : false,

      element: {
        ...element,
        content: {
          ...element.content,
          ...objToMerge,
        },
      },
    });
  };

  render() {
    return this.props.children({
      getRenderMixin: this.getRenderMixin,
      getActualDraggingGeometry: this.getActualDraggingGeometry,
    });
  }
}
