import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import isNumber from 'lodash/isNumber';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { excludeFromRestoreFocus } from '@pdffiller/jsf-focuscontroller';

import Hint from '../../Hint/Hint';
import OverflowIcon from './OverflowIcon';
import {
  getStyle,
  getCaretPosition,
  setCaretPosition,
  isForceCaret,
} from './utils/textToolUtils';
import {
  popupStatuses,
  defaultLineHeight,
} from '../../../helpers/const';
import { locales } from '../../../ui';
import ElementIconWrapper, { styleForIconWrapper } from '../../Element/ElementIcon/ElementIconWrapper';

const activeElementTabIndex = 0;
const activeFillableElementTabIndex = 2;

class TextToolView extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.bool,
    ]),

    // TextTool props
    id: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    maxWidth: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    maxHeight: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    fontSize: PropTypes.number.isRequired,
    lineHeight: PropTypes.number.isRequired,
    fontFamily: PropTypes.string.isRequired,
    bold: PropTypes.bool.isRequired,
    pageId: PropTypes.number.isRequired,
    italic: PropTypes.bool.isRequired,
    underline: PropTypes.bool.isRequired,
    fontColor: PropTypes.string.isRequired,
    align: PropTypes.string.isRequired,
    isHighlighted: PropTypes.bool, // not required for ghost

    // for valign
    paddingTop: PropTypes.number,

    // for gutter
    padding: PropTypes.number.isRequired,

    // Service
    // isCells: PropTypes.bool, // TODO: never used
    forceCaretPosition: PropTypes.shape({
      caretPosition: PropTypes.number,
    }),
    isActiveElement: PropTypes.bool.isRequired,
    isReadonly: PropTypes.bool.isRequired,
    isFillable: PropTypes.bool.isRequired,
    isGhost: PropTypes.bool,
    isSignature: PropTypes.bool,
    isDate: PropTypes.bool,
    isFillableIOSDate: PropTypes.bool.isRequired,
    // TODO: remove eslint-disable-next-line after remove UNSAFE_componentWillReceiveProps
    // eslint-disable-next-line react/no-unused-prop-types
    isDropdown: PropTypes.bool,
    isPopupVisible: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    isSticky: PropTypes.bool,
    isTextBox: PropTypes.bool,

    // Events
    onPaste: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onKeydown: PropTypes.func.isRequired,
    // onBlurTouch: PropTypes.func.isRequired,

    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    storeRef: PropTypes.func.isRequired,
    onMouseDown: PropTypes.func.isRequired,
    interceptFocusDataset: PropTypes.shape({}),
    // Triggers
    // afterFocus: PropTypes.func.isRequired,
    // afterBlur: PropTypes.func.isRequired,

    // Icon for input
    icon: PropTypes.element,

    // Need update caret position for IOS devices
    needFixIOSCaret: PropTypes.shape({}),

    isOverflowedView: PropTypes.bool.isRequired,
    // isOneLineOverflowedText: PropTypes.bool.isRequired,
    // isFFMultiLineOverflowedText: PropTypes.bool.isRequired,
    isShownPhoneButtonSheetMenu: PropTypes.bool.isRequired,
    enableSpellCheck: PropTypes.bool.isRequired,
    whiteSpace: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    isHighlighted: false,
    paddingTop: undefined,
    isGhost: false,
    isSignature: false,
    icon: null,
    needFixIOSCaret: undefined,
    forceCaretPosition: undefined,
    isDate: false,
    isSticky: false,
    isTextBox: false,
    whiteSpace: 'normal',
    isDropdown: false,

    interceptFocusDataset: undefined,
  };

  static contextTypes = {
    changePageScroll: PropTypes.func,
    getPageViewport: PropTypes.func,
    getScroll: PropTypes.func,
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
    }),
  };

  constructor(props) {
    super(props);
    this.isEventDelegated = false;
  }

  componentDidMount() {
    const {
      isActiveElement,
    } = this.props;

    if (isActiveElement) {
      this.delegateEvents();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.isGhost) {
      /**
       * https://pdffiller.atlassian.net/browse/SNF-272
       *
       * Если каретка уставновлена в текстовое поле в IE11
       * и мы открываем модалку - каретка в IE видна над модалкой,
       * поэтому очищаем таймаут восстановления каретки и снимаем ее
       */

      const {
        isActiveElement,
        needFixIOSCaret,
        isShownPhoneButtonSheetMenu,
      } = nextProps;

      if (isActiveElement !== this.props.isActiveElement && isActiveElement) {
        this.delegateEvents();
      }

      if (
        (isActiveElement !== this.props.isActiveElement && !isActiveElement) ||
        (
          isShownPhoneButtonSheetMenu !== this.props.isShownPhoneButtonSheetMenu &&
          isShownPhoneButtonSheetMenu
        )
      ) {
        this.undelegateEvents();
      }

      if (this.props.needFixIOSCaret !== needFixIOSCaret) {
        this.fixIOSCaret();
      }
    }
  }

  componentDidUpdate({ forceCaretPosition }) {
    const { popupVisibility } = this.context.getPageViewport();

    // Restore caret position if forced
    if (
      isForceCaret({
        forceCaretPosition,
      }, {
        forceCaretPosition: this.props.forceCaretPosition,
        isActiveElement: this.props.isActiveElement,
      }) && popupVisibility === popupStatuses.hidden
    ) {
      this.forceCaret();
    }
  }

  componentWillUnmount() {
    this.undelegateEvents();
    if (this.caretToEndTimeout) {
      clearTimeout(this.caretToEndTimeout);
    }
  }

  getInputNode = () => {
    const node = this.inputRef;
    if (node === undefined) {
      const message =
        `getInputNode, can't find DOM node with
         id - ${this.props.id}
         pageId - ${this.props.pageId}`;
      throw new Error(message);
    }
    return node;
  };

  getWrapperNode = () => {
    return this.wrapperRef;
  };

  getCaret = () => {
    return getCaretPosition(this.getInputNode());
  };

  getWidthHeightFromStyles = () => {
    const { width: strWidth, height: strHeight } = this.styles().textareaStyle;
    return {
      width: parseFloat(strWidth, 10),
      height: parseFloat(strHeight, 10),
    };
  };

  // https://pdffiller.atlassian.net/browse/JSF-3560
  // need to change tabIndex earlier then keyboard opened
  setTabIndex = () => {
    this.inputRef.tabIndex = this.props.isFillable
      ? activeFillableElementTabIndex
      : activeElementTabIndex;
  };

  // tabIndex = -1 - iphone сделает кнопки экранной клавиатуры next prev не активными
  getTabIndex = () => {
    if (this.props.isActiveElement) {
      if (this.props.isFillable) {
        return activeFillableElementTabIndex;
      }
      return activeElementTabIndex;
    }

    return -1;
  };

  styles = () => {
    const argForStyle = {
      paddingTop: this.props.paddingTop,
      align: this.props.align,
      fontSize: this.props.fontSize,
      width: this.props.width,
      maxWidth: this.props.maxWidth,
      maxHeight: this.props.maxHeight,
      height: this.props.height,
      bold: this.props.bold,
      italic: this.props.italic,
      underline: this.props.underline,
      fontFamily: this.props.fontFamily,
      fontColor: this.props.fontColor,
      padding: this.props.padding,
      isSticky: this.props.isSticky,
      isTextBox: this.props.isTextBox,
      isSignature: this.props.isSignature,
      isFillable: this.props.isFillable,
      isActiveElement: this.props.isActiveElement,
      lineHeight: this.props.lineHeight,
      whiteSpace: this.props.whiteSpace,
    };

    return {
      divStyle: getStyle(argForStyle, false),
      textareaStyle: getStyle(argForStyle, true),
    };
  };

  forceCaret = () => {
    const { forceCaretPosition = {} } = this.props;

    if (!isNumber(forceCaretPosition.caretPosition)) {
      return;
    }

    const node = this.getInputNode();

    setCaretPosition(
      node,
      forceCaretPosition.caretPosition,
    );

    // Что с фиксом, что без него 👇 разницы поведения не заметил. Зато он
    // создавал в body нескрытый блок, который ломал страницу

    // const { isFFMultiLineOverflowedText } = this.props;

    // https://bugzilla.mozilla.org/show_bug.cgi?id=876693#c0
    // setSelectionRange doesn't scroll textarea with overflow: hidden in FF
    // used only for overflowed field by text and FF
    // if (isFFMultiLineOverflowedText) {
    //   const { left, top } = getFFCaretCoordinatesForOverflowedTool(
    //     node,
    //     forceCaretPosition.caretPosition,
    //   );
    //   node.scroll(left, top);
    // }
  };

  pasteProxy = (event) => {
    if (this.props.isReadonly) {
      return;
    }
    const clipboardText = event.clipboardData.getData(
      thisDevice.isIOS
        ? 'text/plain'
        : 'text',
    );
    event.preventDefault();
    const { selectionStart, selectionEnd } = this.getInputNode();
    this.props.onPaste({
      selectionStart, selectionEnd, clipboardText,
    });
  };

  changeProxy = (event) => {
    if (!this.props.isActiveElement) {
      return;
    }
    const {
      isDate,
      isReadonly,
      isFillableIOSDate,
    } = this.props;
    // for simple date need have caret position
    const isReadonlyDate = isDate && isReadonly;

    this.props.onChange(
      event,
      isReadonlyDate || isFillableIOSDate
        ? {}
        : { caretPosition: this.getCaret() },
    );
  };

  keydownProxy = (event) => {
    if (!this.props.isActiveElement) {
      return;
    }
    this.fixIEConvulsions(event);
    this.props.onKeydown(event);
  };

  delegateEvents = () => {
    if (!this.isEventDelegated) {
      this.isEventDelegated = true;
      document.addEventListener('keydown', this.keydownProxy);
    }
  };

  undelegateEvents = () => {
    if (this.isEventDelegated) {
      this.isEventDelegated = false;
      document.removeEventListener('keydown', this.keydownProxy);
    }
  };

  insertTabIndent = () => {
    const tabSymbol = '     ';
    const node = this.getInputNode();
    const { text } = this.props;
    const textBeforeCaret = text.substring(0, node.selectionStart);
    const textAfterCaret = text.substring(node.selectionEnd);
    const event = { target: { value: `${textBeforeCaret}${tabSymbol}${textAfterCaret}` } };
    this.props.onChange(event, { caretPosition: node.selectionStart + 5 });
  };

  insertNextLineSymbol = () => {
    const nextLineSymbol = '\n';
    const node = this.getInputNode();
    const { text } = this.props;
    const textBeforeCaret = text.substring(0, node.selectionStart);
    const textAfterCaret = text.substring(node.selectionEnd);
    const event = { target: { value: `${textBeforeCaret}${nextLineSymbol}${textAfterCaret}` } };
    this.props.onChange(event, { caretPosition: node.selectionStart + 1 });
  };

  caretToEnd = () => {
    if (this.caretToEndTimeout) {
      clearTimeout(this.caretToEndTimeout);
    }
    this.caretToEndTimeout = setTimeout(() => {
      return setCaretPosition(this.getInputNode(), this.props.text.length);
    }, 0);
  };

  fixIOSCaret = () => {
    if (!thisDevice.isIOS) {
      return;
    }
    const node = this.getInputNode();
    const { value } = node;
    node.value = `${value} `;
    node.value = value;
  };

  /**
   * При нажатии Space, Enter в IE11 текст в не fillable поле дергается.
   * Чтобы исправить это, мы по keydown увеличиваем размер поля
   */
  fixIEConvulsions = (keydownEvent) => {
    // Фикс нужен только для Simple полей в IE11
    if (!thisDevice.isInternetExplorer11) {
      return;
    }

    // Только для Space (32) и Enter (13)
    if (keydownEvent.keyCode !== 13 && keydownEvent.keyCode !== 32) {
      return;
    }

    const { width, height } = this.getWidthHeightFromStyles();
    const node = this.getInputNode();
    node.style.width = `${width + 4}px`;
    node.style.height = `${height + (this.props.fontSize * defaultLineHeight)}px`;

    this.ieConvulsionsWidthTimeout = setTimeout(() => {
      node.style.width = `${this.props.width}px`;
    }, 100);

    this.ieConvulsionsHeightTimeout = setTimeout(() => {
      node.style.height = `${this.props.height}px`;
    }, 100);
  };

  storeWrapperRef = (ref) => {
    this.wrapperRef = ref;
  };

  storeInputRef = (ref) => {
    if (this.props.storeRef && !this.props.isGhost) {
      this.props.storeRef(ref);
    }
    this.inputRef = ref;
  };

  render() {
    const {
      isFillable,
      isActiveElement,
      isHighlighted,
      isReadonly,
      isPopupVisible,
      isDisabled,
      isOverflowedView,
      enableSpellCheck,
      icon,
    } = this.props;
    const { divStyle, textareaStyle } = this.styles();
    // дока по классам https://github.com/pdffiller/jsfcore/blob/develop/docs/classes.md
    const classes = [
      (isReadonly || !isActiveElement || isPopupVisible)
        ? 'selectionDisabled-TextTool'
        : 'selectionEnabled-TextTool',
      {
        'textTool-TextTool': !isFillable,
        'elementHover-Content': !isDisabled,
        'edit-TextTool': !isFillable && isActiveElement && !isDisabled,
      },
    ];

    if (this.props.children) {
      return this.props.children({
        isFillable,
        isActiveElement,
        isHighlighted,
        isReadonly,
        isPopupVisible,
        isDisabled,
        divStyle,
        textareaStyle,
        storeWrapperRef: this.storeWrapperRef,
        storeInputRef: this.storeInputRef,
        text: this.props.text,
        onPaste: this.pasteProxy,
        onChange: this.changeProxy,
        tabIndex: this.getTabIndex(),
        icon,

        onFocus: this.props.onFocus,
        onBlur: this.props.onBlur,
        onMouseDown: this.props.onMouseDown,
        interceptFocusDataset: this.props.interceptFocusDataset,
      });
    }

    return (
      <div
        className={classnames(...classes)}
        ref={this.storeWrapperRef}
        style={divStyle}

        /**
         * Здесь это нужно только для isMobile
         * На isMobile элементы завернуты в DraggableCore
         * который использует RestoreFocusArea.
         */
        {...excludeFromRestoreFocus}
      >
        <textarea
          className="fillable-field__input-control"
          value={this.props.text}
          style={textareaStyle}
          readOnly={this.props.isReadonly}
          onPaste={this.pasteProxy}
          onChange={this.changeProxy}
          ref={this.storeInputRef}
          // https://pdffiller.atlassian.net/browse/JSF-4258
          // https://pdffiller.atlassian.net/browse/JSF-4812
          autoComplete="new-password"
          autoCapitalize="off"
          autoCorrect="off"
          spellCheck={enableSpellCheck}
          tabIndex={this.getTabIndex()}

          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
          onMouseDown={this.props.onMouseDown}
          {...this.props.interceptFocusDataset}
        />
        {icon && (
          <ElementIconWrapper style={styleForIconWrapper}>
            {icon}
          </ElementIconWrapper>
        )}
        {isOverflowedView && (
          <Hint html={locales.titles.textField}>
            {({ onMouseEnter, onMouseLeave, storeRef }) => {
              return (
                <OverflowIcon
                  storeRef={storeRef}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                />
              );
            }}
          </Hint>
        )}
      </div>
    );
  }
}


export default TextToolView;
