import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { toolbar } from '../../../../store/selectors';
import setLineWidth from '../../../../store/thunks/setLineWidth';
import Slider from '../../../Slider/Slider';
import { thicknessValues } from '../../../../helpers/const';
import * as Ui from '../../../../ui';

@connect((state) => {
  const { lineWidth } = toolbar.getElementPropsForToolbar(state);
  return { lineWidth };
}, {
  setLineWidth,
})
export default class LineWidthToolbarSettings extends Component {
  static propTypes = {
    lineWidth: PropTypes.number.isRequired,
    setLineWidth: PropTypes.func.isRequired,
  };

  onLineWidthChange = (lineWidth) => {
    this.props.setLineWidth(lineWidth);

    // we need to do forceUpdate(), because ReactSlider changes handler's position
    // even if new position is not valid
    this.forceUpdate();
  };

  render() {
    return (
      <Ui.ToolBar.Item
        type={Ui.toolBar.itemTypes.rangeSlider}
      >
        <Slider
          value={this.props.lineWidth}
          min={thicknessValues.min}
          max={thicknessValues.max}
          onChange={this.onLineWidthChange}
        />
      </Ui.ToolBar.Item>
    );
  }
}
