import PropTypes from 'prop-types';
import React, { Component } from 'react';
import clamp from 'lodash/clamp';
import throttle from 'lodash/throttle';
import { thisDevice } from '@pdffiller/jsf-useragent';

import * as Ui from '../../ui';

import { getEventPos } from '../../helpers/dragUtils';

export default class Slider extends Component {
  static propTypes = {
    value: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onMouseUp: PropTypes.func,
  };

  static defaultProps = {
    value: 70,
    min: 0,
    max: 100,
    onMouseUp: () => {
      return false;
    },
  };

  static contextTypes = {
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
    }).isRequired,
  };

  getValue = (event) => {
    const state = this.context.store.getState();
    const pos = getEventPos(event, state.events);
    if (!pos || !this.wrapperNode) {
      return false;
    }

    const { max, min } = this.props;
    const { left, right } = this.wrapperNode.getBoundingClientRect();
    const relative = (pos.x - left) / (right - left);
    const value = ((max - min) * relative) + min;
    return clamp(value, min, max);
  }

  getPercent = (value) => {
    const { max, min } = this.props;
    const relative = (value - min) / (max - min);
    return Math.round(relative * 100);
  };

  delegateEvents = () => {
    if (thisDevice.isDesktop) {
      window.addEventListener('mousemove', this.onMouseMove);
      window.addEventListener('mouseup', this.onMouseUp);
    }

    if (thisDevice.isMobile || thisDevice.isSurface) {
      window.addEventListener('touchmove', this.onMouseMove);
      window.addEventListener('touchend', this.onMouseUp);
      window.addEventListener('touchcancel', this.onMouseUp);
    }
  };

  undelegateEvents = () => {
    if (thisDevice.isDesktop) {
      window.removeEventListener('mousemove', this.onMouseMove);
      window.removeEventListener('mouseup', this.onMouseUp);
    }

    if (thisDevice.isMobile || thisDevice.isSurface) {
      window.removeEventListener('touchmove', this.onMouseMove);
      window.removeEventListener('touchend', this.onMouseUp);
      window.removeEventListener('touchcancel', this.onMouseUp);
    }
  };

  storeWrapperRef = (ref) => {
    this.wrapperNode = ref;
  };

  onMouseDown = (event) => {
    event.preventDefault();
    this.delegateEvents();
    const newValue = this.getValue(event);
    if (newValue) {
      this.props.onChange(newValue);
    }
  };

  onMouseMove = throttle((event) => {
    const newValue = this.getValue(event);
    if (newValue) {
      this.props.onChange(newValue);
    }
  }, 50);

  onMouseUp = (event) => {
    const newValue = this.getValue(event);
    if (newValue) {
      this.props.onMouseUp(newValue);
    }
    this.undelegateEvents();
  };

  render() {
    const percents = this.getPercent(this.props.value);

    // Могут быть одновременно и мышь, и тач (surface)
    const eventHandlers = {
      ...(thisDevice.isDesktop
        ? {
          onMouseDown: this.onMouseDown,
        }
        : {}),

      ...(thisDevice.isMobile || thisDevice.isSurface
        ? {
          onTouchStart: this.onMouseDown,
        }
        : {}),
    };

    return (
      <Ui.Slider.RangeSlider
        theme={Ui.slider.themes.lightgray}
        percents={percents}
        storeRef={this.storeWrapperRef}
        handlers={eventHandlers}
      />
    );
  }
}
