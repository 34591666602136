import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import map from 'lodash/map';
import { selectors, thunks } from 'jsfcore';
import * as Ui from 'jsfcore/ui';
import { fConstructor, dispatchAction } from '@pdffiller/jsf-lazyload';
import { FocusControllerRestoreFocusArea } from '@pdffiller/jsf-focuscontroller';
import * as ModesPanel from './components';
import { modeIds } from '../../../helpers/const';

@connect(
  (state) => {
    return {
      isConstructorIncludingPreview: selectors.mode.isConstructor.includingPreview(state),
      isPreview: selectors.mode.isPreview(state),
      modesBar: selectors.base.getModeBarFeature(state),
    };
  }, {
    toggleFConstructor: thunks.toggleFConstructor,
  },
)
export default class HeaderModesPanel extends Component {
  static propTypes = {
    isConstructorIncludingPreview: PropTypes.bool.isRequired,
    isPreview: PropTypes.bool.isRequired,
    modesBar: PropTypes.arrayOf(
      PropTypes.shape({
        selected: PropTypes.bool,
      }),
    ),
    toggleFConstructor: PropTypes.func.isRequired,
  };

  static defaultProps = {
    modesBar: {},
  };

  static contextTypes = {
    getDefaultTool: PropTypes.func,
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
    }),
  };

  getWrapperStyles = () => {
    return this.props.isConstructorIncludingPreview
      ? { flexGrow: 1 }
      : {};
  }

  toggleFConstructor = () => {
    this.props.toggleFConstructor();
  };

  activatePreviewMode = () => {
    dispatchAction(
      fConstructor.actions.activatePreviewMode, { context: this.context },
    );
  }

  deactivatePreviewMode = () => {
    dispatchAction(
      fConstructor.actions.deactivatePreviewMode, { context: this.context },
    );
  }

  hideConstructor = () => {
    dispatchAction(fConstructor.actions.hideConstructor);
  };

  activateMainModeHandler = () => {
    const { isConstructorIncludingPreview, isPreview } = this.props;
    if (isPreview) {
      this.deactivatePreviewMode();
    }
    if (isConstructorIncludingPreview) {
      this.hideConstructor();
    }
  }

  activateConstructorModeHandler = () => {
    if (this.props.isPreview) {
      this.deactivatePreviewMode();
    } else {
      this.toggleFConstructor();
    }
  }

  activatePreviewModeHandler = () => {
    if (!this.props.isConstructorIncludingPreview) {
      this.toggleFConstructor();
    }
    this.activatePreviewMode();
  }

  render = () => {
    const wrapperStyles = this.getWrapperStyles();

    return (
      <FocusControllerRestoreFocusArea style={wrapperStyles}>
        <ModesPanel.TabsDataProvider
          modesBar={this.props.modesBar}
        >
          {({
            selectedModeId,
            tabs,
          }) => {
            const isMainModeEnabled = selectedModeId === modeIds.main;
            const gridCellAttributes = isMainModeEnabled
              ? {}
              : Ui.attributes.grow;

            return (
              <ModesPanel.TabsHandlersProvider
                activateMainMode={this.activateMainModeHandler}
                activateConstructorMode={this.activateConstructorModeHandler}
                activatePreviewMode={this.activatePreviewModeHandler}
                selectedModeId={selectedModeId}
                tabs={tabs}
              >
                {({ tabsHandlers }) => {
                  return (
                    <Fragment>
                      <Ui.Grid.Cell
                        attributes={gridCellAttributes}
                      >
                        <Ui.ToolBar.Body
                          size={Ui.toolBar.sizes.extraLarge}
                        >
                          <Ui.ToolBar.Main>
                            <Ui.ToolBar.Group>
                              {map(tabs, ((tab, key) => {
                                return (
                                  <ModesPanel.TabRenderer
                                    key={tab.title}
                                    tabData={tab}
                                    onClick={tabsHandlers[key]}
                                  />
                                );
                              }))}
                            </Ui.ToolBar.Group>
                          </Ui.ToolBar.Main>
                        </Ui.ToolBar.Body>
                      </Ui.Grid.Cell>
                      {isMainModeEnabled && (
                        <Ui.Grid.Separator />
                      )}
                    </Fragment>
                  );
                }}
              </ModesPanel.TabsHandlersProvider>
            );
          }}
        </ModesPanel.TabsDataProvider>
      </FocusControllerRestoreFocusArea>
    );
  };
}
