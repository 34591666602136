import React from 'react';
import PropTypes from 'prop-types';

const MainCalendarCell = ({ children, onClick }) => {
  return (
    <div className="datepicker__cell" onClick={onClick}>
      {children}
    </div>
  );
};

MainCalendarCell.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

MainCalendarCell.defaultProps = {
  onClick: null,
};

export default MainCalendarCell;
