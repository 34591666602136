import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { selectors } from '../../../..';
import * as Ui from '../../../../ui';

@connect(
  (state) => {
    return {
      locale: selectors.locale.getToolbarAttributesLocale(state).OK,
    };
  },
)
export default class OkBtn extends Component {
  static propTypes = {
    handleOkClick: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
  };

  render() {
    return (
      <Ui.Container.SettingButton
        textSize={15}
        theme={Ui.control.themes.solidPrimary}
        label={this.props.locale}
        offset={Ui.control.offsets.medium}
        onClick={this.props.handleOkClick}
        inheritRadius
      />
    );
  }
}
