import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { fConstructor, wizard, versions, LazyComponent } from '@pdffiller/jsf-lazyload';

import RightPanelTabsButtons from './RightPanelTabsButtons';
import * as Ui from '../../ui';
import { ghostVisibleOverClassName } from '../../helpers/const/const';

const rightPanelCustomClassnames = [
  ghostVisibleOverClassName,
];

export default class RightPanelElementsRenderer extends Component {
  static propTypes = {
    needRenderConstructorsButtons: PropTypes.bool.isRequired,
    needRenderPAConstructor: PropTypes.bool.isRequired,
    needRenderFConstructor: PropTypes.bool.isRequired,
    needRenderWizardTodoListMaximized: PropTypes.bool.isRequired,
    needRenderVersionsPanel: PropTypes.bool.isRequired,
  };

  render() {
    const {
      needRenderConstructorsButtons,
      needRenderPAConstructor,
      needRenderFConstructor,
      needRenderWizardTodoListMaximized,
      needRenderVersionsPanel,
    } = this.props;

    return (
      <Fragment>
        {needRenderPAConstructor && (
          <Ui.SideBar.Body
            theme={Ui.sideBar.themes.lightgray}
            size={Ui.sideBar.sizes.large}
          >
            <Ui.SideBar.Content>
              <Ui.SideBar.Cell data-flex-grow="1">
                <LazyComponent
                  literal={fConstructor.components.paConstructor}
                />
              </Ui.SideBar.Cell>
            </Ui.SideBar.Content>
          </Ui.SideBar.Body>
        )}

        {needRenderFConstructor && (
          <Ui.SideBar.Body
            customClassnames={rightPanelCustomClassnames}
            theme={Ui.sideBar.themes.lightgray}
            size={Ui.sideBar.sizes.extraLarge}
          >
            <Ui.SideBar.Content>
              <Ui.SideBar.Cell data-flex-grow="1">
                <LazyComponent
                  literal={fConstructor.components.fConstructor}
                />
              </Ui.SideBar.Cell>
            </Ui.SideBar.Content>
          </Ui.SideBar.Body>
        )}

        {needRenderWizardTodoListMaximized &&
          <LazyComponent
            literal={wizard.components.wizardTodoListMaximized}
          />
        }

        {needRenderVersionsPanel &&
          <Ui.SideBar.Body
            theme={Ui.sideBar.themes.lightgray}
            size={Ui.sideBar.sizes.large}
          >
            <Ui.SideBar.Content>
              <Ui.SideBar.Cell data-flex-grow="1">
                <LazyComponent
                  literal={versions.components.versionsPanel}
                />
              </Ui.SideBar.Cell>
            </Ui.SideBar.Content>
          </Ui.SideBar.Body>
        }

        {needRenderConstructorsButtons && <RightPanelTabsButtons />}
      </Fragment>
    );
  }
}
