import React, { Component } from 'react';
import DefaultModalView from '../DefaultModalView';

export const dropDownModalTypes = {
  text_dropdown: 'text_dropdown',
  invalid_initial_value: 'invalid_initial_value',
  dropdown_duplicate_value: 'dropdown_duplicate_value',
};

export default class DropDownModalLock extends Component {
  render() {
    return (
      <DefaultModalView
        {...this.props}
      />
    );
  }
}
