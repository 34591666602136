import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { goToFlashSite } from '../../../store/modules/navigation';
import * as selectors from '../../../store/selectors';
import * as Ui from '../../../ui';

const pageNavigationCellAttributes = {
  ...Ui.attributes.grow,
  ...Ui.attributes.shrink,
};
const counterBodyAttributes = {
  ...Ui.attributes.ariaLive,
  ...Ui.attributes.ariaRelevant,
  ...Ui.attributes.ariaLabel,
};

@connect(
  (state) => {
    return {
      locale: selectors.locale.getEditorLocale(state).thumbnails,
    };
  }, {
    goToFlashSite,
  },
)
export default class ThumbnailsNavigationLabel extends Component {
  static propTypes = {
    count: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool,
    ]).isRequired,
    locale: PropTypes.shape({
      label: PropTypes.string.isRequired,
      labelInit: PropTypes.string.isRequired,
    }).isRequired,
    index: PropTypes.number.isRequired,

    // Переходим по тройному клику во флэш редактор
    goToFlashSite: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.clickCounter = 0;
  }

  getLabel = () => {
    const { count, locale } = this.props;

    return (
      count === false
        ? locale.labelInit
        : this.parseLabelFromLocale()
    );
  };

  parseLabelFromLocale = () => {
    const { count, locale, index } = this.props;

    const parsedLabel = locale.label
      .replace('%current%', `<div class="counter__item">${index + 1}</div>`)
      .replace('%total%', `<div class="counter__item">${count}</div>`);
    // [JSF-7229] need to cut page label to avoid text overflow
    const isNeedToRemovePageLabel = (index + 1) >= 10 || count >= 100;

    return isNeedToRemovePageLabel
      ? parsedLabel.replace('<div class=\'counter__label\'>Page</div>', '')
      : parsedLabel;
  };

  goToFlashEditor = () => {
    this.clickCounter = this.clickCounter + 1;
    if (this.clickCounter === 1) {
      setTimeout(() => {
        this.resetClickCounter();
      }, 1000);
    }
    if (this.clickCounter >= 3) {
      this.resetClickCounter();
      this.props.goToFlashSite();
    }
  };

  resetClickCounter = () => {
    this.clickCounter = 0;
  };

  render() {
    return (
      <Ui.PageNavigation.Cell attributes={pageNavigationCellAttributes}>
        <Ui.Counter.Body
          theme={Ui.counterBody.themes.default}
          behavior={Ui.counterBody.behaviors.overflowText}
          attributes={counterBodyAttributes}
          innerHTML={{ __html: this.getLabel() }}
          onClick={this.goToFlashEditor}
        />
      </Ui.PageNavigation.Cell>
    );
  }
}
