import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { FocusControllerRestoreFocusArea } from '@pdffiller/jsf-focuscontroller';

import SimpleElementControls from '../SimpleElementControls';
import OkBtn from './OkBtn';
import MobileTools from '../../MobileTools';
import * as Ui from '../../../../ui';

const mobileToolsWrapperStyles = { flexGrow: 1 };

export default class ToolbarAttributesMobileView extends Component {
  static propTypes = {
    handleOkClick: PropTypes.func.isRequired,

    // props for SimpleElementControls
    hasSize: PropTypes.bool.isRequired,
    hasLineWidth: PropTypes.bool.isRequired,
    hasStamp: PropTypes.bool.isRequired,
    sigDateStampChangeable: PropTypes.bool.isRequired,
    hasPopup: PropTypes.bool.isRequired,
    handleSizeUp: PropTypes.func.isRequired,
    handleSizeDown: PropTypes.func.isRequired,
    onSizeButtonFocus: PropTypes.func,
    handleLineWidthUp: PropTypes.func.isRequired,
    handleLineWidthDown: PropTypes.func.isRequired,
    handleToggleStampClick: PropTypes.func.isRequired,
    onRemovePressed: PropTypes.func.isRequired,
    onTogglePopupPressed: PropTypes.func.isRequired,
    isTextToolBasedElement: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    onSizeButtonFocus: null,
  };

  render() {
    const {
      hasSize,
      hasLineWidth,
      hasStamp,
      sigDateStampChangeable,
      hasPopup,
      handleSizeUp,
      handleSizeDown,
      onSizeButtonFocus,
      handleLineWidthUp,
      handleLineWidthDown,
      handleToggleStampClick,
      onRemovePressed,
      onTogglePopupPressed,
      isTextToolBasedElement,
    } = this.props;

    return (
      <Ui.Grid.CellGroup>
        <Ui.Grid.Cell attributes={Ui.attributes.grow}>
          <Ui.ToolBar.Body
            size={Ui.toolBar.sizes.medium}
            theme={Ui.toolBar.themes.solidLightgray}
          >
            <Ui.ToolBar.Main>

              <Ui.ToolBar.Group attributes={Ui.attributes.grow}>
                <FocusControllerRestoreFocusArea style={mobileToolsWrapperStyles}>
                  <MobileTools />
                </FocusControllerRestoreFocusArea>
                <SimpleElementControls
                  hasSize={hasSize}
                  hasLineWidth={hasLineWidth}
                  hasStamp={hasStamp}
                  sigDateStampChangeable={sigDateStampChangeable}
                  hasPopup={hasPopup}
                  handleSizeUp={handleSizeUp}
                  handleSizeDown={handleSizeDown}
                  onSizeButtonFocus={onSizeButtonFocus}
                  handleLineWidthUp={handleLineWidthUp}
                  handleLineWidthDown={handleLineWidthDown}
                  handleToggleStampClick={handleToggleStampClick}
                  onRemovePressed={onRemovePressed}
                  onTogglePopupPressed={onTogglePopupPressed}
                  isTextToolBasedElement={isTextToolBasedElement}
                />
              </Ui.ToolBar.Group>

              <Ui.ToolBar.Group>
                <OkBtn handleOkClick={this.props.handleOkClick} />
              </Ui.ToolBar.Group>

            </Ui.ToolBar.Main>
          </Ui.ToolBar.Body>
        </Ui.Grid.Cell>
      </Ui.Grid.CellGroup>
    );
  }
}
