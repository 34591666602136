import { createSelector } from 'reselect';
import { utils as wsUtils } from 'ws-editor-lib';
import { getFocusElement } from '../store/helpers/stateToProps';

import { isFillable } from '../store/helpers/functions';
import { selectors } from '..';


export const getExpectedWizardFocus = (focusElement, elementsMap, groups) => {
  if (!focusElement) {
    return false;
  }

  if (!isFillable(focusElement)) {
    return false;
  }

  const { radio } = groups;
  const { radio: radioName } = wsUtils.getElementGroupNames(focusElement);

  if (radioName === false || radio[radioName] === undefined) {
    return focusElement;
  }

  const wizardFocus = radio[radioName]
    .map((id) => {
      return (
        focusElement && focusElement.id === id
          ? focusElement
          : elementsMap[id]
      );
    });

  return wizardFocus;
};

export const wizardFocusSelector = createSelector(
  [getFocusElement, selectors.base.getElementsMap, selectors.base.getGroups],
  (focusElement, elementsMap, groups) => {
    return getExpectedWizardFocus(focusElement, elementsMap, groups);
  },
);
