import inRange from 'lodash/inRange';
import minBy from 'lodash/minBy';

import { isFillable, getElementProps } from '../store/helpers/functions';
import { elemTypes } from './elemTypes';
import { magneticLineDirections, magneticDistance, defaultMargins } from './const';
import { getTextToolSettings } from '../components/Tools/TextTool/utils/textToolUtils';

export const getFillableElementsByPage = ({ elements, activePageId }) => {
  return elements.filter((el) => {
    return el.pageId === activePageId && isFillable(el);
  });
};

// all not fillable elements except erase type
export const getSimpleElementsByPageExceptSome = ({ elements, activePageId }) => {
  return elements.filter((el) => {
    return (
      el.pageId === activePageId &&
      !isFillable(el) &&
      el.type !== elemTypes.erase
    );
  });
};

export const getElementsExceptCurrentOne = ({ elements, id }) => {
  if (!id) {
    return elements;
  }
  return elements.filter((el) => {
    return el.id !== id;
  });
};

export const getTopByY =
  ({
    isTopY,
    isBottomY,
    isCenterY,
    elemProps: { y, height },
    scale,
    topShift = 0,
  }) => {
    if (isCenterY) {
      const halfHeight = height / 2;
      return ((y + halfHeight) * scale) + topShift;
    }
    if (isTopY || isBottomY) {
      return (y * scale) + topShift;
    }
    return ((y + height) * scale) + topShift;
  };

export const getLeftByX =
  ({
    isLeftX,
    isRightX,
    isCenterX,
    elemProps: { x, width },
    scale,
    leftShift = 0,
  }) => {
    if (isCenterX) {
      const halfWidth = width / 2;
      return ((x + halfWidth) * scale) + leftShift;
    }
    if (isLeftX || isRightX) {
      return (x * scale) + leftShift;
    }
    return ((x + width) * scale) + leftShift;
  };

const fixPositionByPaddings = ({ x, y }, { left, top }) => {
  return {
    ...x && { x: x + left },
    ...y && { y: y + top },
  };
};

const getElementPropsConsideringFrameScroll =
  ({ element, frameScroll, scale }) => {
    const elementProps = getElementProps(element);

    return {
      ...elementProps,
      x: elementProps.x - (frameScroll.scrollLeft / scale),
      y: elementProps.y - (frameScroll.scrollTop / scale),
    };
  };

/*
 Our coordinate system look like:
0 -------------------> X
  |
  |
  Y
 */

// Snap to fields for Ghost element
export const ghostSnapToFieldHelper = ({
  elements,
  setMagneticLines,
  scale,
  position,
  ghost,
  workspace = { left: 0, top: 0, framePadding: { left: 0, top: 0 } },
  frameOffset = { left: 0, top: 0 },
  frameScroll = { scrollTop: 0, scrollLeft: 0 },
  isMouseOverPage,
}) => {
  const lines = [];
  let magneticPosition;

  if (!isMouseOverPage) {
    setMagneticLines(lines);
    return position;
  }

  if (!Number.isNaN(position.x) && !Number.isNaN(position.y)) {
    // leftTop X leftTop Y
    const ltX = (position.x - workspace.left) / scale;
    const ltY = (position.y - workspace.top) / scale;
    const ghostProps = getElementProps(ghost);

    elements.forEach((el) => {
      const elemProps = getElementPropsConsideringFrameScroll({
        element: el, frameScroll, scale,
      });

      /* CALCULATING HORIZONTAL LINES */
      const isTopY = inRange(elemProps.y, ltY - magneticDistance, ltY + magneticDistance);
      const isBottomY = inRange(
        elemProps.y,
        (ltY + ghostProps.height) - magneticDistance,
        (ltY + ghostProps.height) + magneticDistance,
      );
      const isTopYBoth = inRange(
        elemProps.y + elemProps.height,
        ltY - magneticDistance, ltY + magneticDistance,
      );
      const isBottomYBoth = inRange(
        elemProps.y + elemProps.height,
        (ltY + ghostProps.height) - magneticDistance,
        (ltY + ghostProps.height) + magneticDistance,
      );
      const isCenterY = inRange(
        elemProps.y + (elemProps.height / 2),
        (ltY + (ghostProps.height / 2)) - magneticDistance,
        (ltY + (ghostProps.height / 2)) + magneticDistance,
      );

      if (isTopY || isBottomY || isTopYBoth || isBottomYBoth || isCenterY) {
        const width = elemProps.x > ltX
          ? (elemProps.x - ltX - ghostProps.width) * scale
          : (ltX - elemProps.x - elemProps.width) * scale;
        // Prevented creating lines with negative width
        if (width <= 0) {
          return;
        }

        const leftPadding = workspace.left + workspace.framePadding.left + frameOffset.left;
        lines.push({
          key: magneticLineDirections.horizontal,
          shape: {
            left: ltX > elemProps.x
              ? ((elemProps.x + elemProps.width) * scale) + leftPadding
              : ((ltX + ghostProps.width) * scale) + leftPadding,
            top: getTopByY({
              isTopY,
              isBottomY,
              isCenterY,
              elemProps,
              scale,
              topShift: workspace.top + workspace.framePadding.top + frameOffset.top,
            }),
            width,
          },
        });

        if (isTopY) {
          magneticPosition = { ...magneticPosition, y: elemProps.y * scale };
        }
        if (isBottomY) {
          magneticPosition = {
            ...magneticPosition, y: (elemProps.y - ghostProps.height) * scale,
          };
        }
        if (isTopYBoth) {
          magneticPosition = {
            ...magneticPosition, y: (elemProps.y + elemProps.height) * scale,
          };
        }
        if (isBottomYBoth) {
          magneticPosition = {
            ...magneticPosition,
            y: ((elemProps.y + elemProps.height) - ghostProps.height) * scale,
          };
        }
        if (isCenterY) {
          const halfElemHeight = elemProps.height / 2;
          const halfGhostHeight = ghostProps.height / 2;

          magneticPosition = {
            ...magneticPosition,
            y: ((elemProps.y + halfElemHeight) - halfGhostHeight) * scale,
          };
        }
      }

      /* CALCULATING VERTICAL LINES */
      const isLeftX = inRange(elemProps.x, ltX - magneticDistance, ltX + magneticDistance);
      const isRightX = inRange(
        elemProps.x,
        (ltX + ghostProps.width) - magneticDistance,
        ltX + ghostProps.width + magneticDistance,
      );
      const isLeftXBoth = inRange(
        elemProps.x + elemProps.width,
        ltX - magneticDistance, ltX + magneticDistance,
      );
      const isRightYBoth = inRange(
        elemProps.x + elemProps.width,
        (ltX + ghostProps.width) - magneticDistance,
        ltX + ghostProps.width + magneticDistance,
      );
      const isCenterX = inRange(
        elemProps.x + (elemProps.width / 2),
        (ltX + (ghostProps.width / 2)) - magneticDistance,
        (ltX + (ghostProps.width / 2)) + magneticDistance,
      );
      if (isLeftX || isRightX || isLeftXBoth || isRightYBoth || isCenterX) {
        const height = ltY > elemProps.y
          ? ((ltY - elemProps.y - elemProps.height) * scale) + frameScroll.scrollTop
          : (elemProps.y - ltY - ghostProps.height) * scale;
        // Prevented creating lines with negative height
        if (height <= 0) {
          return;
        }

        const topPadding = workspace.top + workspace.framePadding.top + frameOffset.top;

        lines.push({
          key: magneticLineDirections.vertical,
          shape: {
            left: getLeftByX({
              isLeftX,
              isRightX,
              isCenterX,
              elemProps,
              scale,
              leftShift: workspace.left + workspace.framePadding.left + frameOffset.left,
            }),
            top:
              ltY > elemProps.y
                ? ((elemProps.y + elemProps.height) * scale) + topPadding
                : ((ltY + ghostProps.height) * scale) + topPadding,
            height:
              ltY > elemProps.y
                ? height - frameScroll.scrollTop
                : height,
          },
        });
        if (isLeftX) {
          magneticPosition = { ...magneticPosition, x: elemProps.x * scale };
        }
        if (isRightX) {
          magneticPosition = {
            ...magneticPosition,
            x: (elemProps.x - ghostProps.width) * scale,
          };
        }
        if (isLeftXBoth) {
          magneticPosition = {
            ...magneticPosition,
            x: (elemProps.x + elemProps.width) * scale,
          };
        }
        if (isRightYBoth) {
          magneticPosition = {
            ...magneticPosition,
            x: ((elemProps.x + elemProps.width) - ghostProps.width) * scale,
          };
        }
        if (isCenterX) {
          const halfElemWidth = elemProps.width / 2;
          const halfGhostWidth = ghostProps.width / 2;
          magneticPosition = {
            ...magneticPosition,
            x: ((elemProps.x + halfElemWidth) - halfGhostWidth) * scale,
          };
        }
      }
    });
    // set values for magneticLines array
    setMagneticLines(lines);
  }

  if (!magneticPosition) {
    return position;
  }

  return {
    ...position,
    ...fixPositionByPaddings(magneticPosition, workspace),
  };
};

const resizeDots = {
  bottom: [6, 2, 10],
  top: [5, 1, 9],
  right: [9, 8, 10],
  left: [5, 4, 6],
};

const hasResizeIndexByType = (type, resizeIndex) => {
  // if not resizing process but drag and drop
  // always shown all types of magnetic line
  if (!resizeIndex) {
    return true;
  }

  return resizeDots[type].some((dot) => {
    return dot === resizeIndex;
  });
};

// Snap to fields for Drag / Resize element
export const dragSnapToFieldHelper = ({
  elements,
  setMagneticLines,
  scale,
  activeElement: { x, y, width, height },
  resizeIndex,
}) => {
  const isResizing = resizeIndex !== 0;
  const lines = [];
  let magneticProps;

  if (!Number.isNaN(x) && !Number.isNaN(y)) {
    elements.forEach((el) => {
      const elemProps = getElementProps(el);

      const activeElemBottonY = y + height;
      const activeElemBottonYPlusMD = activeElemBottonY + magneticDistance;
      const activeElemBottonYMinusMD = activeElemBottonY - magneticDistance;

      const elemBottomY = elemProps.y + elemProps.height;
      const elemRightX = elemProps.x + elemProps.width;

      /* CALCULATING HORIZONTAL LINES */
      const isTopY = hasResizeIndexByType('top', resizeIndex) && inRange(
        elemProps.y,
        y - magneticDistance,
        y + magneticDistance,
      );
      const isBottomY = hasResizeIndexByType('bottom', resizeIndex) && inRange(
        elemProps.y,
        activeElemBottonYMinusMD,
        activeElemBottonYPlusMD,
      );
      const isTopYBoth = hasResizeIndexByType('top', resizeIndex) && inRange(
        elemBottomY,
        y - magneticDistance,
        y + magneticDistance,
      );
      const isBottomYBoth = hasResizeIndexByType('bottom', resizeIndex) && inRange(
        elemBottomY,
        activeElemBottonYMinusMD,
        activeElemBottonYPlusMD,
      );
      // for resizing event not calculate center lines
      const isCenterY = isResizing
        ? false
        : inRange(
          elemProps.y + (elemProps.height / 2),
          (y + (height / 2)) - magneticDistance,
          y + (height / 2) + magneticDistance,
        );

      if (isTopY || isBottomY || isTopYBoth || isBottomYBoth || isCenterY) {
        // Prevented creating lines with negative width
        if (width > 0) {
          const isElemXMoreThanActiveElemX = elemProps.x > x;
          const lineWidth = isElemXMoreThanActiveElemX
            ? (elemProps.x - x - width) * scale
            : (x - elemProps.x - elemProps.width) * scale;

          lines.push({
            key: magneticLineDirections.horizontal,
            shape: {
              left: isElemXMoreThanActiveElemX
                ? (x + width) * scale
                : (elemRightX) * scale,
              top: getTopByY({
                isTopY,
                isBottomY,
                isCenterY,
                elemProps,
                scale,
              }),
              width: lineWidth,
            },
          });
        }

        if (isTopY) {
          magneticProps = {
            ...magneticProps,
            ...(isResizing
              ? {
                height: height - (elemProps.y - y),
                y: elemProps.y,
              }
              : { y: elemProps.y }
            ),
          };
        }
        if (isBottomY) {
          magneticProps = {
            ...magneticProps,
            ...(isResizing
              ? { height: elemProps.y - y }
              : { y: elemProps.y - height }
            ),
          };
        }
        if (isTopYBoth) {
          magneticProps = {
            ...magneticProps,
            ...(isResizing
              ? {
                height: height - (elemBottomY - y),
                y: elemBottomY,
              }
              : { y: elemBottomY }
            ),
          };
        }
        if (isBottomYBoth) {
          magneticProps = {
            ...magneticProps,
            ...(isResizing
              ? { height: elemBottomY - y }
              : { y: elemBottomY - height }
            ),
          };
        }
        if (isCenterY) {
          const halfElemHeight = elemProps.height / 2;
          magneticProps = {
            ...magneticProps,
            y: (elemProps.y + halfElemHeight) - (height / 2),
          };
        }
      }

      const activeElemRightX = x + width;
      const activeElemRightXPlusMD = activeElemRightX + magneticDistance;
      const activeElemRightXMinusMD = activeElemRightX - magneticDistance;

      /* CALCULATING VERTICAL LINES */
      const isLeftX = hasResizeIndexByType('left', resizeIndex) && inRange(
        elemProps.x,
        x - magneticDistance,
        x + magneticDistance,
      );
      const isRightX = hasResizeIndexByType('right', resizeIndex) && inRange(
        elemProps.x,
        activeElemRightXMinusMD,
        activeElemRightXPlusMD,
      );
      const isLeftXBoth = hasResizeIndexByType('left', resizeIndex) && inRange(
        elemRightX,
        x - magneticDistance,
        x + magneticDistance,
      );
      const isRightXBoth = hasResizeIndexByType('right', resizeIndex) && inRange(
        elemRightX,
        activeElemRightXMinusMD,
        activeElemRightXPlusMD,
      );
      // for resizing event not calculate center lines
      const isCenterX = isResizing
        ? false
        : inRange(
          elemProps.x + (elemProps.width / 2),
          (x + (width / 2)) - magneticDistance,
          x + (width / 2) + magneticDistance,
        );

      if (isLeftX || isRightX || isLeftXBoth || isRightXBoth || isCenterX) {
        const isElemYMoreThanActiveElemY = y > elemProps.y;
        const lineHeight = isElemYMoreThanActiveElemY
          ? (y - elemProps.y - elemProps.height) * scale
          : (elemProps.y - y - height) * scale;

        // Prevented creating lines with negative height
        if (height > 0) {
          lines.push({
            key: magneticLineDirections.vertical,
            shape: {
              left: getLeftByX({
                isLeftX,
                isRightX,
                isCenterX,
                elemProps,
                scale,
              }),
              top: isElemYMoreThanActiveElemY
                ? (elemBottomY) * scale
                : (y + height) * scale,
              height: lineHeight,
            },
          });
        }

        if (isLeftX) {
          magneticProps = {
            ...magneticProps,
            ...(isResizing
              ? {
                width: width - (elemProps.x - x),
                x: elemProps.x,
              }
              : { x: elemProps.x }
            ),
          };
        }
        if (isRightX) {
          magneticProps = {
            ...magneticProps,
            ...(isResizing
              ? { width: elemProps.x - x }
              : { x: elemProps.x - width }
            ),
          };
        }
        if (isLeftXBoth) {
          magneticProps = {
            ...magneticProps,
            ...(isResizing
              ? {
                width: width - (elemRightX - x),
                x: elemRightX,
              }
              : { x: elemRightX }
            ),
          };
        }
        if (isRightXBoth) {
          magneticProps = {
            ...magneticProps,
            ...(isResizing
              ? { width: elemRightX - x }
              : { x: elemRightX - width }
            ),
          };
        }
        if (isCenterX) {
          const halfElemWidth = elemProps.width / 2;
          magneticProps = {
            ...magneticProps,
            x: (elemProps.x + halfElemWidth) - (width / 2),
          };
        }
      }
    });
    // set values for magneticLines array
    setMagneticLines(lines);
  }

  if (!magneticProps) {
    return { x, y };
  }
  return magneticProps;
};

const getNearestLineBySizeProps = (lines, part) => {
  if (lines.length === 0 || lines.length === 1) {
    return lines;
  }

  return [
    minBy(lines, (line) => {
      return line.shape[part];
    }),
  ];
};

const getMarginsByElementType = ({ type, subType }) => {
  return getTextToolSettings({ type, subType }) || defaultMargins;
};

export const modifySizeByBorder =
  ({ x, y, width, height, type, subType }) => {
    const {
      marginLeft,
      marginRight,
      marginTop,
      marginBottom,
    } = getMarginsByElementType({ type, subType });

    return (
      opts = { increaseSize: true },
    ) => {
      return {
        x,
        y,
        ...(
          opts.increaseSize
            ? {
              width: width + marginLeft + marginRight,
              height: height + marginTop + marginBottom,
            }
            : {
              width: width - (marginLeft + marginRight),
              height: height - (marginTop + marginBottom),
            }
        ),
      };
    };
  };

export const showMagneticLinesOnlyForNearestElements =
  ({
    lines,
    activeElementShape: { top, right, bottom, left },
  }) => {
    const verticalLines = lines.filter((line) => {
      return line.key === magneticLineDirections.vertical;
    });
    const horizontalLines = lines.filter((line) => {
      return line.key === magneticLineDirections.horizontal;
    });

    const linesAbove = verticalLines.filter((vL) => {
      return vL.shape.top <= top;
    });
    const linesUnder = verticalLines.filter((vL) => {
      return vL.shape.top >= bottom;
    });

    const linesRight = horizontalLines.filter((hL) => {
      return hL.shape.left >= right;
    });
    const linesLeft = horizontalLines.filter((hL) => {
      return hL.shape.left <= left;
    });

    return [
      ...getNearestLineBySizeProps(linesAbove, 'height'),
      ...getNearestLineBySizeProps(linesUnder, 'height'),
      ...getNearestLineBySizeProps(linesRight, 'width'),
      ...getNearestLineBySizeProps(linesLeft, 'width'),
    ];
  };
