import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { toolbar } from '../../../../../store/selectors';
import setLineWidth from '../../../../../store/thunks/setLineWidth';

import Slider from '../../../../Slider/Slider';
import { thicknessValues } from '../../../../../helpers/const';

import * as Ui from '../../../../../ui';

export class LineWidthPopupSettingsSimple extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    lineWidth: PropTypes.number.isRequired,
    setLineWidth: PropTypes.func.isRequired,
  };

  onLineWidthChange = (lineWidth) => {
    this.props.setLineWidth(lineWidth);

    // we need to do forceUpdate(), because ReactSlider changes handler's position
    // even if new position is not valid
    this.forceUpdate();
  };

  render() {
    return (
      <Ui.Menu.ListItem>
        <Ui.Menu.Item>
          <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.body}>

            <Ui.Menu.ItemByType attributes={Ui.attributes.grow}>
              <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.text}>
                <Ui.Text
                  size={16}
                  align={Ui.text.aligns.left}
                >
                  {this.props.locale}
                </Ui.Text>
              </Ui.Menu.ItemByType>
            </Ui.Menu.ItemByType>

            <Ui.Menu.ItemByType attributes={Ui.attributes.grow}>

              <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.rangeSlider}>
                <Slider
                  value={this.props.lineWidth}
                  onChange={this.onLineWidthChange}
                  min={thicknessValues.min}
                  max={thicknessValues.max}
                />
              </Ui.Menu.ItemByType>

            </Ui.Menu.ItemByType>

          </Ui.Menu.ItemByType>
        </Ui.Menu.Item>
      </Ui.Menu.ListItem>
    );
  }
}

export const mapStateToProps = (state) => {
  const { lineWidth } = toolbar.getElementPropsForToolbar(state);
  return { lineWidth };
};

export default connect(mapStateToProps, {
  setLineWidth,
})(LineWidthPopupSettingsSimple);
