import memoize from 'lodash/memoize';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { DraggableCore } from 'react-draggable';
import { focusControllerDraggableDecorator } from '@pdffiller/jsf-focuscontroller';
import { thisDevice } from '@pdffiller/jsf-useragent';

import { getEndPointSize } from '../../../../helpers/graphicUtils';
import { pageClickBlockerClassname } from '../../../../helpers/const';

const greenEndpointColor = '#6FB71B';

const getDecoratedDraggableCore = memoize(() => {
  return focusControllerDraggableDecorator(thisDevice)(DraggableCore);
});

// TODO: correctly render on scale changes
/**
 * Make an EndPoint to drag line.
 * we have array of begin and end, draw a rect only at needed point
 */
export default class SimpleToolEndPoint extends Component {
  static propTypes = {
    onDragStart: PropTypes.func.isRequired,
    onDragMove: PropTypes.func.isRequired,
    onDragStop: PropTypes.func.isRequired,
    top: PropTypes.oneOfType([
      PropTypes.number, PropTypes.string,
    ]).isRequired,
    left: PropTypes.oneOfType([
      PropTypes.number, PropTypes.string,
    ]).isRequired,
    zIndex: PropTypes.number,
    scale: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
    cursor: PropTypes.string,
    resizeIndex: PropTypes.number.isRequired,
    // for constructor style
    isFConstructorShown: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    zIndex: 'auto',
    cursor: 'move',
    isFConstructorShown: false,
  };

  getSize = () => {
    return getEndPointSize() * this.props.scale;
  };

  onDragStart = (event) => {
    this.props.onDragStart(this.props.resizeIndex)(event);
  };

  onDragMove = (event) => {
    this.props.onDragMove(this.props.resizeIndex)(event);
  };

  onDragStop = (event) => {
    this.props.onDragStop(this.props.resizeIndex)(event);
  };

  render() {
    const { cursor, top, left, zIndex } = this.props;
    const size = this.getSize();

    const endPointStyles = {
      cursor,
      width: size,
      height: size,
      ...(this.props.isFConstructorShown
        ? { backgroundColor: greenEndpointColor }
        : {}),
    };

    const DecoratedDraggableCore = getDecoratedDraggableCore();

    return (
      <DecoratedDraggableCore
        onStart={this.onDragStart}
        onDrag={this.onDragMove}
        onStop={this.onDragStop}
        disabled={this.props.disabled}
      >
        <div className="endPointWrapper-EndPoint" style={{ top, left, zIndex }}>
          <div
            className={classnames(pageClickBlockerClassname, 'endPoint-EndPoint')}
            style={endPointStyles}
          />
        </div>
      </DecoratedDraggableCore>
    );
  }
}
