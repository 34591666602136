import PropTypes from 'prop-types';
import React, { Component } from 'react';

import * as Ui from '../../../../../ui';
import { getBoxIcon } from '../../../../../ui/Control';

export default class ColorButton extends Component {
  static propTypes = {
    size: PropTypes.string,
    theme: PropTypes.string,
    behavior: PropTypes.string,
    color: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    type: PropTypes.string,
    boxIconSize: PropTypes.string,
    tag: PropTypes.string,

    onColorChange: PropTypes.func.isRequired,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    theme: Ui.control.themes.spatial,
    size: Ui.control.sizes.parent,
    behavior: Ui.control.behaviors.stretchedContent,
    type: Ui.control.boxIconTypes.null,
    boxIconSize: Ui.control.boxIconSizes.null,
    tag: Ui.colorPicker.itemTags.li,
    onClick: null,
  };

  onClick = (event) => {
    this.props.onColorChange(this.props.color);

    if (this.props.onClick) {
      this.props.onClick(event);
    }
  }

  render() {
    const { color, isActive } = this.props;

    return (
      <Ui.ColorPicker.ItemByType
        type={Ui.colorPicker.itemTypes.item}
        tag={this.props.tag}
      >
        <Ui.Control
          theme={this.props.theme}
          size={this.props.size}
          behavior={this.props.behavior}
          isChecked={isActive}
          boxIcon={
            getBoxIcon({
              boxColor: color,
              type: this.props.type,
              size: this.props.boxIconSize,
            })
          }
          onClick={this.onClick}
          inheritRadius
        />
      </Ui.ColorPicker.ItemByType>
    );
  }
}
