import PropTypes from 'prop-types';
import { Component, createElement } from 'react';
import { connect } from 'react-redux';
import { activateTool } from 'ws-editor-lib/actions';
import get from 'lodash/get';

import { wizard, managers, comments, dispatchAction } from '@pdffiller/jsf-lazyload';
import { setIsSearchModeActive } from '../../store/modules/search';
import { wizardFocusSelector } from '../../helpers/selectors';
import {
  elemTypes,
  elemSubTypes,
  isDisabledType,
  isCommentTool,
} from '../../helpers/elemTypes';
import { wizardTraverseModes } from '../../helpers/const';
import isSignNow from '../../helpers/const/isSignNow';
import { selectors, thunks } from '../..';

export default function toolsDecorator(opts = { main: false }) {
  const { main } = opts;

  return function wrapWithComponent(WrappedComponent) {
    class ToolsDecorator extends Component {
      static propTypes = {

        // from global.state
        activeTool: PropTypes.shape({
          subType: PropTypes.string,
          type: PropTypes.string,
        }),
        activeElement: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.bool,
        ]).isRequired,
        haveWizardFocus: PropTypes.bool.isRequired,
        wizardActive: PropTypes.bool.isRequired,
        isFConstructorShown: PropTypes.bool.isRequired,
        isFConstructorPreviewShown: PropTypes.bool.isRequired,

        // actions
        activateTool: PropTypes.func.isRequired,
        setActiveElement: PropTypes.func.isRequired,
        setIsSearchModeActive: PropTypes.func.isRequired,
      };

      static contextTypes = {
        store: PropTypes.shape({
          getState: PropTypes.func.isRequired,
        }).isRequired,
        getDefaultTool: PropTypes.func,
      };

      static defaultProps = {
        activeTool: null,
      }

      componentDidMount() {
        if (main) {
          const { type, subType } = this.context.getDefaultTool();
          this.props.activateTool(type, subType);
        }
      }

      activateTool = (type, subType) => {
        this.props.activateTool(type, subType);
      };

      // TODO: call auto
      triggerActivePageChanged = () => {
        const { isFConstructorShown, isFConstructorPreviewShown } = this.props;
        if (isFConstructorShown && !isFConstructorPreviewShown && isSignNow()) {
          // activate default fConstructor tool on page changing
          this.props.activateTool(elemTypes.fctool, elemSubTypes.none);
          return;
        }
        if (isCommentTool(this.props.activeTool.type)) {
          const { type, subType } = this.context.getDefaultTool();
          this.props.activateTool(type, subType);
        }
      }

      /**
       * Returns a click callback function for the tool buttons in the header
       * @param {string} tool - tool title const
       */
      onClickHeaderToolButtonFactory = (type, subType = elemSubTypes.none) => {
        if (isDisabledType(type)) {
          return () => {};
        }

        if (type === elemTypes.image) {
          return () => {
            this.onClickCommonActions();
            const { activeTool } = this.props;
            if (activeTool && activeTool.type === type && activeTool.subType === subType) {
              return;
            }
            dispatchAction(managers.actions.openImageManager, {});
          };
        }

        if (type === elemTypes.signature) {
          return () => {
            this.onClickCommonActions();
            const { activeTool } = this.props;
            if (activeTool && activeTool.type === type && activeTool.subType === subType) {
              return;
            }
            dispatchAction(managers.actions.openSignatureManager, {});
          };
        }

        // returns function that handles some event
        return () => {
          this.onClickCommonActions();
          const { activeTool, activeElement, haveWizardFocus, wizardActive } = this.props;

          if (wizardActive) {
            if (haveWizardFocus && activeElement) {
              this.props.setActiveElement(activeElement, false);
            }
            dispatchAction(wizard.actions.setWizardActive, false);
          }

          // When user activate a tool that is already active
          if (activeTool && activeTool.type === type && activeTool.subType === subType) {
            const defaultTool = this.context.getDefaultTool();
            this.props.activateTool(defaultTool.type, defaultTool.subType);
            return;
          }

          this.activateTool(type, subType);
        };
      }

      /**
       * По клику на любой тул в хидере мы сбрасываем wizardTraverseMode и searchMode
       */
      onClickCommonActions = () => {
        const globalState = this.context.store.getState();

        const traverseMode = selectors.base.getWizardTraverseMode(globalState);
        if (traverseMode !== wizardTraverseModes.allUnfilled) {
          dispatchAction(
            wizard.actions.setWizardTraverseMode, wizardTraverseModes.allUnfilled,
          );
        }

        const isCommentActive = selectors.comments.getActiveCommentId(globalState);
        if (isCommentActive) {
          dispatchAction(comments.actions.setActiveComment, false);
        }

        const isSearchActive = selectors.mode.isSearch(globalState);
        if (isSearchActive) {
          this.props.setIsSearchModeActive(false);
        }
      }

      render() {
        const options = {
          // methods
          onClickHeaderToolButtonFactory: this.onClickHeaderToolButtonFactory,
          triggerActivePageChanged: this.triggerActivePageChanged,
          activateTool: this.activateTool,

          // props
          ...this.props,

          // other
          elemTypes,
        };

        return createElement(WrappedComponent, options);
      }
    }
    return connect(
      (state) => {
        return {
          activeTool: state.ws.activeTool,
          originalSize: state.navigation.originalSizes[state.navigation.activePageId],
          activeElement: selectors.base.getActiveElementId(state),
          haveWizardFocus: !!wizardFocusSelector(state),
          wizardActive: get(state, 'wizard.wizardActive', false),
          isFConstructorShown: state.viewport.isFConstructorShown,
          isFConstructorPreviewShown: get(state, 'fConstructor.isFConstructorPreviewShown', false),
        };
      }, {
        activateTool,
        setActiveElement: thunks.setActiveElement,
        setIsSearchModeActive,
      },
    )(ToolsDecorator);
  };
}
