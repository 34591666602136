import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import scroll from 'scroll';
import { selectors as coreSelectors } from 'jsfcore';

import { getScrollTopToPageId, getFrameSizesOrDefault } from '../../../helpers/utils';
import { selectors } from '../../..';
import { scrollAnimationDurationMS } from '../../../helpers/const';

// if we scroll from first to 2000 (two thousand) page,
// we call 'needRender...' for too many unused content
// so if we have long distance one-click scroll - make it without animation
const noAnimationDistance = 5000;

@connect(
  (state) => {
    return {
      // так как все скейлы оданаковае пишем так
      scale: coreSelectors.getScale(state, 0),
    };
  },
)
export default class PaginationContinious extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,

    frameSizes: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          width: PropTypes.number.isRequired,
          height: PropTypes.number.isRequired,
        }).isRequired,
        PropTypes.bool.isRequired,
      ]).isRequired,
    ).isRequired,
    workspace: PropTypes.shape({
      framePadding: PropTypes.shape({
        top: PropTypes.number.isRequired,
        bottom: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        right: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    activePageId: PropTypes.number.isRequired,
    nextPageId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool,
    ]).isRequired,
    scrollToTopSn: PropTypes.shape({}).isRequired,
    pagesSettings: PropTypes.arrayOf(
      PropTypes.shape({
        source: PropTypes.number.isRequired,
      }).isRequired,
    ).isRequired,
    scale: PropTypes.number,

    getRef: PropTypes.func.isRequired,
    setActivePage: PropTypes.func.isRequired,
    setContiniousFrameScroll: PropTypes.func.isRequired,
    setIsContiniousScrollAnimated: PropTypes.func.isRequired,
  };

  static contextTypes = {
    onScroll: PropTypes.func,
    subscribeToScroll: PropTypes.func.isRequired,
    unsubscribeToScroll: PropTypes.func.isRequired,
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
    }).isRequired,
  };

  static childContextTypes = {
    getScroll: PropTypes.func,
    setScroll: PropTypes.func,
  };

  static defaultProps = {
    scale: 1,
  }

  constructor(props) {
    super(props);

    this.domScroll = {
      scrollTop: 0,
      scrollLeft: 0,
    };
  }

  getChildContext = () => {
    return {
      getScroll: this.getScroll,
      setScroll: this.setScroll,
    };
  };

  componentDidMount() {
    this.context.subscribeToScroll(this.calculateAndSetActivePageIdIfNeed);
    this.context.subscribeToScroll(this.dispatchNewScroll);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    // тут что бы не было странного подскрола при листании страниц
    // нам надо сравнивать не только nextPageId но и активную страницу
    // которая в случае листания меняется, но не меняется nextPageId,
    // а в случае CHANGE_PAGE меняется nextPageId
    if (
      this.props.activePageId === nextProps.activePageId &&
      nextProps.nextPageId !== this.props.nextPageId
    ) {
      this.scrollToPageId(nextProps.nextPageId);
    }

    if (nextProps.scrollToTopSn !== this.props.scrollToTopSn) {
      this.scrollToPageId(0);
    }
  }

  componentWillUnmount() {
    this.context.unsubscribeToScroll(this.calculateAndSetActivePageIdIfNeed);
    this.context.unsubscribeToScroll(this.dispatchNewScroll);
  }

  getScroll = () => {
    return {
      scrollTop: this.props.getRef().scrollTop,
      scrollLeft: this.props.getRef().scrollLeft,
    };
  };

  setScroll = ({ scrollTop, scrollLeft, animation = false }) => {
    const scrollDomElement = this.props.getRef();
    const preventAnimationOnLongScrollTop = (
      scrollTop !== undefined &&
      Math.abs(scrollTop - scrollDomElement.scrollTop) > noAnimationDistance
    );

    if (animation && !preventAnimationOnLongScrollTop && scrollTop !== undefined) {
      this.props.setIsContiniousScrollAnimated(true);
      scroll.top(scrollDomElement, scrollTop, { duration: scrollAnimationDurationMS }, () => {
        this.props.setIsContiniousScrollAnimated(false);
      });
      return;
    }

    if (animation && scrollLeft !== undefined) {
      scroll.left(scrollDomElement, scrollLeft, { duration: scrollAnimationDurationMS });
      return;
    }

    if (scrollTop !== undefined) {
      this.props.getRef().scrollTop = scrollTop;
    }
    if (scrollLeft !== undefined) {
      this.props.getRef().scrollLeft = scrollLeft;
    }
  };

  scrollToPageId = (pageId) => {
    const scrollTop = getScrollTopToPageId({
      workspace: this.props.workspace,
      frameSizes: this.props.frameSizes,
      pagesSettings: this.props.pagesSettings,
      pageId,
      scale: this.props.scale,
    });
    this.props.getRef().scrollTop = scrollTop;
  };

  scrollToPageIdWithAnimation = (pageId) => {
    const scrollTop = getScrollTopToPageId({
      workspace: this.props.workspace,
      frameSizes: this.props.frameSizes,
      pagesSettings: this.props.pagesSettings,
      pageId,
      scale: this.props.scale,
    });
    this.setScroll({ scrollTop, animation: true });
  }

  calculateAndSetActivePageIdIfNeed = throttle(({ scrollTop }) => {
    const { pagesSettings } = this.props;

    // берем актуальный scale из стейта, потому что иногда scale из пропсов
    // равняется defaultProps.scale (не берется из селектора) из-за throttle
    const state = this.context.store.getState();
    const activePageId = coreSelectors.base.getActivePageId(state);
    const frameSizes = coreSelectors.getFrameSizes(state);
    const workspace = coreSelectors.base.getWorkspace(state);
    const scale = coreSelectors.getScale(state, activePageId);

    const frameSizesOrDefault = getFrameSizesOrDefault({
      frameSizes,
      workspace,
      pagesSettings,
      scale,
    });
    const newActivePageId =
      selectors.navigation.calulateActivePageId(
        frameSizesOrDefault,
        workspace,
        scrollTop,
      );
    if (newActivePageId !== activePageId) {
      this.props.setActivePage(newActivePageId);
    }
  }, 300);

  dispatchNewScroll = debounce(({ scrollTop, scrollLeft }) => {
    this.props.setContiniousFrameScroll({ scrollTop, scrollLeft });
  }, 300);

  saveDomScroll = ({ scrollTop, scrollLeft }) => {
    this.domScroll = { scrollTop, scrollLeft };
  };

  domScrollHasBeenChanged = ({ scrollTop, scrollLeft }) => {
    return (
      scrollTop !== this.domScroll.scrollTop ||
      scrollLeft !== this.domScroll.scrollLeft
    );
  }

  onDOMScroll = () => {
    const { scrollTop, scrollLeft } = this.props.getRef();
    if (this.domScrollHasBeenChanged({ scrollTop, scrollLeft })) {
      this.context.onScroll({ scrollTop, scrollLeft });
      this.saveDomScroll({ scrollTop, scrollLeft });
    }
  };

  render() {
    return this.props.children({
      onDOMScroll: this.onDOMScroll,
      scrollToPageId: this.scrollToPageIdWithAnimation,
    });
  }
}
