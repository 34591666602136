import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';

import { svgStyle, wrapperDivStyle, getValidColor } from '../../../helpers/graphicUtils';
import { mouseFocusClassName } from '../../../helpers/const';
import { getElementCursor } from '../../../helpers/utils';

export default class LineToolView extends Component {
  static propTypes = {
    fillColor: PropTypes.string.isRequired,
    lineWidth: PropTypes.number.isRequired,
    viewBox: PropTypes.string.isRequired, // SVG-viewbox
    path: PropTypes.string.isRequired, // SVG-path
    isDisabled: PropTypes.bool,

    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    storeRef: PropTypes.func,
  };

  static defaultProps = {
    isDisabled: false,
    onFocus: null,
    onBlur: null,
    storeRef: null,
  };

  getWrapperStyles = () => {
    const cursor = getElementCursor(this.props.isDisabled);

    return { cursor, ...wrapperDivStyle };
  }

  render() {
    const {
      fillColor,
      lineWidth,
      viewBox,
      path,
      isDisabled,
      onFocus,
      onBlur,
      storeRef,
    } = this.props;

    const wrapperStyles = this.getWrapperStyles();

    return (
      <div
        style={wrapperStyles}
        className="LineToolView-tabindex"
        onFocus={onFocus}
        onBlur={onBlur}
        ref={storeRef}
        tabIndex={0}
      >
        <svg
          preserveAspectRatio="none"
          viewBox={viewBox}
          style={svgStyle}
        >
          <path
            className={classnames({
              [mouseFocusClassName]: !isDisabled,
              'elementHover-Content': !isDisabled,
            })}
            d={path}
            style={{
              stroke: getValidColor(fillColor),
              strokeWidth: lineWidth,
            }}
            strokeLinecap="round"
          />
        </svg>
      </div>
    );
  }
}
