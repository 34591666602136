import PropTypes from 'prop-types';
import React from 'react';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { FocusControllerRestoreFocusArea } from '@pdffiller/jsf-focuscontroller';

import { DROPDOWN_WIDTH } from './toolbarAttributesUtils';
import * as Ui from '../../../ui';
import ToolbarMobileButton from './Mobile/ToolbarMobileButton/ToolbarMobileButton';
import Dropdown from '../../Dropdown/Dropdown';
import PopupWrapper from './Popup/PopupWrapper';

const wrapperProps = {
  offset: Ui.control.offsets.small,
  width: DROPDOWN_WIDTH,
};

const SimpleElementControls = (props) => {
  const {
    hasSize,
    hasLineWidth,
    hasStamp,
    sigDateStampChangeable,
    hasPopup,
    isTextToolBasedElement,

    // actions
    onSizeButtonFocus,
    handleSizeUp,
    handleSizeDown,
    handleLineWidthUp,
    handleLineWidthDown,
    onTogglePopupPressed,
    handleToggleStampClick,
    onRemovePressed,
  } = props;

  const showTextSizeUpDown = isTextToolBasedElement;

  return [
    hasSize && <ToolbarMobileButton
      key={0}
      onTouchStart={handleSizeUp}
      onFocus={onSizeButtonFocus}
      icon={showTextSizeUpDown
        ? Ui.icon.types.textGrow
        : Ui.icon.types.graphicGrow
      }
    />,
    hasSize && <ToolbarMobileButton
      key={1}
      onTouchStart={handleSizeDown}
      onFocus={onSizeButtonFocus}
      icon={showTextSizeUpDown
        ? Ui.icon.types.textReduce
        : Ui.icon.types.graphicReduce
      }
    />,

    hasLineWidth && <ToolbarMobileButton
      key={2}
      onTouchStart={handleLineWidthUp}
      icon={Ui.icon.types.graphicGrow}

    />,
    hasLineWidth && <ToolbarMobileButton
      key={3}
      onTouchStart={handleLineWidthDown}
      icon={Ui.icon.types.graphicReduce}
    />,

    // Stamp
    hasStamp && sigDateStampChangeable && (
      <FocusControllerRestoreFocusArea key={4}>
        <ToolbarMobileButton
          icon={Ui.icon.types.stamp}
          onClick={handleToggleStampClick}
        />
      </FocusControllerRestoreFocusArea>
    ),

    // Remove button
    <ToolbarMobileButton
      key={5}
      icon={Ui.icon.types.trashBin}
      onClick={onRemovePressed}
    />,

    // Popup phone
    hasPopup && thisDevice.isPhone && <ToolbarMobileButton
      key={6}
      icon={Ui.icon.types.cogwheel}
      onClick={onTogglePopupPressed}
    />,

    // Popup tablet
    hasPopup && thisDevice.isTablet &&
    <span
      key={7}
      onClick={onTogglePopupPressed}
    >
      <Dropdown
        wrapperProps={wrapperProps}
        dropdownComponent={PopupWrapper}
      >
        {({ togglePortal, isPortalOpen, storeRef }) => {
          return (
            <Ui.ToolBar.Item>
              <ToolbarMobileButton
                key={8}
                icon={Ui.icon.types.cogwheel}
                onClick={togglePortal}
                isPressed={isPortalOpen}
                storeRef={storeRef}
              />
            </Ui.ToolBar.Item>
          );
        }}
      </Dropdown>
    </span>,
  ];
};

SimpleElementControls.propTypes = {
  hasSize: PropTypes.bool.isRequired,
  hasLineWidth: PropTypes.bool.isRequired,
  hasStamp: PropTypes.bool.isRequired,
  sigDateStampChangeable: PropTypes.bool.isRequired,
  hasPopup: PropTypes.bool.isRequired,
  handleSizeUp: PropTypes.func.isRequired,
  handleSizeDown: PropTypes.func.isRequired,
  onSizeButtonFocus: PropTypes.func,
  handleLineWidthUp: PropTypes.func.isRequired,
  handleLineWidthDown: PropTypes.func.isRequired,
  handleToggleStampClick: PropTypes.func.isRequired,
  onRemovePressed: PropTypes.func.isRequired,
  onTogglePopupPressed: PropTypes.func.isRequired,
  isTextToolBasedElement: PropTypes.bool.isRequired,
};

SimpleElementControls.defaultProps = {
  onSizeButtonFocus: null,
};

export default SimpleElementControls;
