import get from 'lodash/get';
import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ReactReduxContext } from 'react-redux';
import * as Ui from '../../../ui';
import { isDate } from '../../../store/helpers/functions';
import { defaultLineHeight } from '../../../helpers/const';
import FillablePlaceholderCells from './FillablePlaceholderCells';

const valignDirections = {
  bottom: 'column-reverse',
  top: 'column',
  center: null,
  undefined: null,
};

const letterCases = {
  sentence: 'capitalize',
  upper: 'uppercase',
  lower: 'lowercase',
  none: 'none',
  undefined: 'none',
};

const usePlaceholderStyle = (template) => {
  return useMemo(() => {
    const {
      fontFamily,
      bold,
      underline,
      italic,
      fontColor,
      fontSize,
      lineHeight = defaultLineHeight,
      valign,
      letterCase,
      align: textAlign,
    } = template;

    const fontStyle = italic
      ? 'italic'
      : null;

    const fontWeight = bold
      ? 'bold'
      : null;

    const textDecoration = underline
      ? 'underline'
      : null;

    const color = fontColor
      ? `#${fontColor}`
      : null;

    return {
      fontFamily,
      fontSize,
      color,
      textAlign,
      fontStyle,
      fontWeight,
      textDecoration,
      lineHeight,
      textTransform: letterCases[letterCase],
      flexDirection: valignDirections[valign],
    };
  }, [template]);
};

const usePlaceholderText = (element) => {
  const { validator, toolId, placeholder } = element.template;
  const { store } = useContext(ReactReduxContext);
  const { locale } = store.getState();

  if (placeholder) {
    return placeholder;
  }

  if (isDate(element) && validator !== undefined) {
    return validator.momentFormat;
  }

  if (validator) {
    return get(locale, `${validator.webShortName}`, '');
  }

  return get(locale, `editor.assistant.tools.${toolId}.uiName`, '');
};

const ElementFillablePlaceholder = ({ element, isCells }) => {
  const { template } = element;
  const style = usePlaceholderStyle(template);
  const text = usePlaceholderText(element);

  if (isCells) {
    return (
      <Ui.FillableField.Label isCells isCellsLinesHidden style={style}>
        <FillablePlaceholderCells text={text} element={element} />
      </Ui.FillableField.Label>
    );
  }

  return (
    <Ui.FillableField.Label style={style}>
      {text}
    </Ui.FillableField.Label>
  );
};

ElementFillablePlaceholder.propTypes = {
  element: PropTypes.shape({
    template: PropTypes.shape({
      toolId: PropTypes.string.isRequired,
      validator: PropTypes.shape({
        webShortName: PropTypes.string.isRequired,
      }),
      placeholder: PropTypes.string,
      arrangement: PropTypes.string,
    }).isRequired,
  }).isRequired,
  isCells: PropTypes.bool.isRequired,
};

export default ElementFillablePlaceholder;
