import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import PageScroll from './PageScroll/PageScroll';

const pageView =
  ({
    pageId,
    page,
    workspace,
    isActivePage,
  }) => {
    return (
      <div
        className={classnames('page-Page', {
          'pageHidden-Pagination': !isActivePage,
          'pageActive-Pagination': isActivePage,
        })}
      >
        {page &&
          <PageScroll
            pageId={pageId}
          />
        }
        {!page &&
          <div
            className="fixer-Page"
            style={{
              width: workspace && workspace.width
                ? workspace.width
                : 0,

              height: workspace && workspace.height
                ? workspace.height
                : 0,
            }}
          />
        }
      </div>
    );
  };

pageView.propTypes = {
  pageId: PropTypes.number.isRequired,
  page: PropTypes.shape({}),
  workspace: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
  isActivePage: PropTypes.bool,
};

pageView.defaultProps = {
  page: null,
  workspace: null,
  isActivePage: false,
};

export default pageView;
