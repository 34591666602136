import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectors } from 'jsfcore';
import config from '../../../helpers/clientConfig';
import * as Ui from '../../../ui';

@connect(
  (state) => {
    return {
      locale: selectors.locale.getPrintLocale(state),
    };
  },
)
export default class PagePrint extends Component {
  static propTypes = {
    locale: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    return (
      <div className="print-blocker">
        <div className="print-blocker__body">
          <div className="print-blocker__main">
            <Ui.Grid.Flex justify={Ui.grid.justifies.center} offset={Ui.grid.offsets.small}>
              <Ui.Grid.FlexRow>
                <Ui.Grid.FlexCell attributes={Ui.attributes.grow}>
                  <img
                    alt="print-logo"
                    src={`${config.mainImgUrl}/img/js-filler/print-logo.png`}
                    className="media media--type--image"
                  />
                </Ui.Grid.FlexCell>
              </Ui.Grid.FlexRow>
              <Ui.Grid.FlexRow>
                <Ui.Grid.FlexCell attributes={Ui.attributes.grow}>
                  <Ui.TextBox.Body>
                    <Ui.Text
                      size={Ui.text.sizes[26]}
                      lineHeight={Ui.text.lineHeights.medium}
                      topOffset={Ui.text.topOffsets.medium}
                      bottomOffset={Ui.text.bottomOffsets.medium}
                      center
                      tag={Ui.text.tags.p}
                      // Это ок, т.к. локаль приходит с сервера и пользователь
                      // не может ее сетить
                      //
                      // eslint-disable-next-line react/no-danger
                      html={this.props.locale.text}
                    />
                  </Ui.TextBox.Body>
                </Ui.Grid.FlexCell>
              </Ui.Grid.FlexRow>
              <Ui.Grid.FlexRow>
                <Ui.Grid.FlexCell attributes={Ui.attributes.grow}>
                  <Ui.Group.Index offset={Ui.group.offsets.extraLarge}>
                    <Ui.Group.Body>
                      <Ui.Group.Item attributes={Ui.attributes.grow}>
                        <img
                          alt="pring-screen"
                          src={`${config.mainImgUrl}/img/js-filler/print-screen.jpg`}
                          className="media media-type--image"
                        />
                      </Ui.Group.Item>
                    </Ui.Group.Body>
                  </Ui.Group.Index>
                </Ui.Grid.FlexCell>
              </Ui.Grid.FlexRow>
            </Ui.Grid.Flex>
          </div>
        </div>
      </div>
    );
  }
}
