import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import FillablePlaceholderBody from './FillablePlaceholderBody';
import FillablePlaceholderText from './FillablePlaceholderText';
import FillablePlaceholderUniqueIndicator from './FillablePlaceholderUniqueIndicator';
import FillablePlaceholderControl from './FillablePlaceholderControl';
import FillablePlaceholderIcon from './FillablePlaceholderIcon';

export const fillablePlaceholderThemes = {
  primary: 'primary',
  primaryGradient: 'primary-gradient',
  null: null,
};

export const fillablePlaceholderOffsets = {
  small: 'small',
  null: null,
};

export const fillablePlaceholderTypes = {
  textarea: 'textarea',
  checkbox: 'checkbox',
  radio: 'radio',
  null: null,
};

const overOtherElementStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  pointerEvents: 'none',
};

export const fillablePlaceholderSizes = {
  parent: 'parent',
  touch: 'touch',
};

export const FillablePlaceholderRadio = () => {
  return (
    <div className="fillable-placeholder__radio" />
  );
};

const getScaledStyle = (scale) => {
  return {
    transform: `scale(${scale})`,
  };
};

export default class FillablePlaceholder extends Component {
  static propTypes = {
    text: PropTypes.string,
    icon: PropTypes.element,
    hint: PropTypes.element,
    control: PropTypes.element,
    offset: PropTypes.oneOf(
      Object.values(fillablePlaceholderOffsets),
    ),
    type: PropTypes.oneOf(
      Object.values(fillablePlaceholderTypes),
    ),
    size: PropTypes.oneOf(
      Object.values(fillablePlaceholderSizes),
    ),
    theme: PropTypes.oneOf(
      Object.values(fillablePlaceholderThemes),
    ),
    isRequired: PropTypes.bool,
    isConditional: PropTypes.bool,
    hasValidation: PropTypes.bool,
    isHoverNeeded: PropTypes.bool,
    isInvalid: PropTypes.bool,
    isTargeted: PropTypes.bool,
    isChanging: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.element,
      PropTypes.shape({}),
    ]),
    className: PropTypes.string,
    scale: PropTypes.number,

    onMouseOut: PropTypes.func,
    onMouseOver: PropTypes.func,

    /*
     * isOverOtherElement - for filling mode (fillable field) we render
     * FillablePlaceholder over main element component (ex. TextTool)
     */
    isOverOtherElement: PropTypes.bool,
  };

  static defaultProps = {
    className: null,
    text: null,
    icon: null,
    hint: null,
    control: null,
    offset: fillablePlaceholderOffsets.null,
    type: fillablePlaceholderTypes.null,
    isRequired: false,
    isConditional: false,
    hasValidation: false,
    isHoverNeeded: true,
    isOverOtherElement: false,
    size: fillablePlaceholderSizes.parent,
    isInvalid: false,
    theme: fillablePlaceholderThemes.primary,
    onMouseOut: null,
    onMouseOver: null,
    isTargeted: false,
    isChanging: false,
    scale: 1,
  };

  render() {
    const {
      text,
      icon,
      hasValidation,
      hint,
      control,
      offset,
      type,
      isRequired,
      isConditional,
      isOverOtherElement,
      size,
      className,
      isInvalid,
      isHoverNeeded,
      theme,
      isTargeted,
      isChanging,
      scale,
    } = this.props;

    const scaledStyle = getScaledStyle(scale);

    return (
      <div
        className={cls(
          'fillable-placeholder',
          className,
          {
            [`fillable-placeholder--size--${size}`]: size,
            [`fillable-placeholder--theme--${theme}`]: theme,
            [`fillable-placeholder--offset--${offset}`]: offset,
            'fillable-placeholder--type--textarea':
              type === fillablePlaceholderTypes.textarea,
            'fillable-placeholder--type--checkbox':
              type === fillablePlaceholderTypes.checkbox,
            'fillable-placeholder--type--radio':
              type === fillablePlaceholderTypes.radio,
            'is-required': isRequired,
            'is-conditional': isConditional,
            'is-invalid': isInvalid,
            'is-non-interactive': !isHoverNeeded,
            'is-targeted': isTargeted,
            'is-changing': isChanging,
          },
        )}
        style={
          isOverOtherElement
            ? overOtherElementStyle
            : null
        }
        tabIndex={0}
        onMouseOver={this.props.onMouseOver}
        onMouseOut={this.props.onMouseOut}
      >
        <FillablePlaceholderBody>
          {
            text
              ? (
                <FillablePlaceholderText style={scaledStyle}>
                  {text}
                </FillablePlaceholderText>
              )
              : null
          }

          {
            hasValidation
              ? <FillablePlaceholderUniqueIndicator />
              : null
          }

          {
            control
              ? (
                <FillablePlaceholderControl>
                  {control}
                </FillablePlaceholderControl>
              )
              : null
          }

          {
            icon
              ? (
                <FillablePlaceholderIcon style={scaledStyle}>
                  {icon}
                </FillablePlaceholderIcon>
              )
              : null
          }
        </FillablePlaceholderBody>

        {hint}
      </div>
    );
  }
}
