import pullAll from 'lodash/pullAll';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import isSignNow from 'ws-editor-lib/isSignNow';

import { selectors } from 'jsfcore';

import { run } from '../../../helpers/const';
import { nullComponent } from '../../../helpers/utilsForReact15';
import { getPage, renderCanvas, renderThumbnail } from '../../../store/modules/pdf';

const { abs } = Math;

export const prioritizeIds = (array, activePageId, getIndexByPageId) => {
  return (
    array
      .reverse()
      .sort((elA, elB) => {
        return (
          abs(getIndexByPageId(elA) - getIndexByPageId(activePageId)) -
          abs(getIndexByPageId(elB) - getIndexByPageId(activePageId))
        );
      })
  );
};

@connect(
  (state) => {
    return {
      hasPdfDocument: state.pdf.hasPdfDocument,
      activePageId: selectors.base.getActivePageId(state),
      needGet: state.pdf.needGet,
      pdfScale: selectors.base.getPdfScale(state),
      pagesSettings: selectors.navigation.getPagesSettings(state),
      pdfPages: state.pdf.pdfPages,
      needRender: state.pdf.needRender,
      needRenderThumbnails: state.pdf.needRenderThumbnails,
      pdfPageLoading: state.pdf.pdfPageLoading,
      canvasLoading: state.pdf.canvasLoading,
      thumbnailLoading: state.pdf.thumbnailLoading,
    };
  }, {
    getPage,
    renderCanvas,
    renderThumbnail,
  },
)
export default class PdfRunner extends Component {
  static propTypes = {

    // from global.state
    // TODO: remove eslint-disable-next-line after remove UNSAFE_componentWillReceiveProps
    // eslint-disable-next-line react/no-unused-prop-types
    hasPdfDocument: PropTypes.bool.isRequired,
    pdfScale: PropTypes.number.isRequired,
    activePageId: PropTypes.number.isRequired,
    pagesSettings: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          rotation: PropTypes.number,
          source: PropTypes.number,
          visible: PropTypes.bool,
        }),
      ),
      PropTypes.bool,
    ]),
    pdfPages: PropTypes.arrayOf(
      PropTypes.number,
    ).isRequired,
    needGet: PropTypes.arrayOf(PropTypes.bool).isRequired,
    needRender: PropTypes.arrayOf(PropTypes.bool).isRequired,
    needRenderThumbnails: PropTypes.arrayOf(PropTypes.bool).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    pdfPageLoading: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
    ]).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    canvasLoading: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
    ]).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    thumbnailLoading: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
    ]).isRequired,

    // actions
    getPage: PropTypes.func.isRequired,
    renderCanvas: PropTypes.func.isRequired,
    renderThumbnail: PropTypes.func.isRequired,
  };

  static contextTypes = {
    getAdjacentPageId: PropTypes.func,
    getIndexByPageId: PropTypes.func,
  };

  static defaultProps = {
    pagesSettings: false,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { activePageId } = nextProps;
    const {
      canvasLoading,
      pdfPageLoading,
      thumbnailLoading,
      hasPdfDocument,
      needGet,
      needRender,
      needRenderThumbnails,
    } = nextProps;
    const {
      needGet: oldNG,
      needRender: oldNR,
      needRenderThumbnails: oldNRT,
      activePageId: oldActive,
    } = this.props;
    if (
      hasPdfDocument !== false &&
      pdfPageLoading === false &&
      canvasLoading === false &&
      thumbnailLoading === false &&
      (
        oldNG !== needGet ||
        oldNR !== needRender ||
        oldNRT !== needRenderThumbnails ||
        oldActive !== activePageId
      )
    ) {
      const { getNeedIds, getLoadedIds, dispatchAction, filterArrays } = this;
      const activeRules = {
        render: this.getActiveRules(run.render, activePageId),

        // get: this.getActiveRules(run.get, activePageId),
        // renderThumbnail: this.getActiveRules(run.renderThumbnail, activePageId),
      };
      const getIds = getNeedIds(needGet);
      const renderIds = getLoadedIds(getNeedIds(needRender), getIds);
      const thumbnailsIds = getLoadedIds(getNeedIds(needRenderThumbnails), getIds);
      dispatchAction(filterArrays(activeRules, {
        get: prioritizeIds(
          getIds, activePageId, this.context.getIndexByPageId,
        ),
        render: prioritizeIds(
          renderIds, activePageId, this.context.getIndexByPageId,
        ),
        thumbnail: prioritizeIds(
          thumbnailsIds, activePageId, this.context.getIndexByPageId,
        ),
      }));
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  getLoadedIds = (array, getIds) => {
    if (!this.props.pdfPages) {
      return [];
    }

    return pullAll(array, getIds);
  };

  getNeedIds = (array) => {
    return (
      array
        .map((el, id) => {
          return el !== null
            ? id
            : el;
        })
        .filter((item) => {
          return item !== null;
        })
    );
  };

  getActiveRules = (rules, activePageId) => {
    return rules.map((item) => {
      return this.context.getAdjacentPageId(item, activePageId);
    });
  };

  filterArrays = (activeRules, { get, render, thumbnail }) => {
    return {
      // for signnow we should render only visible pages + 200px from top and bottom
      // we don't use any activeRules.render rules pages for signnow
      render: isSignNow()
        ? render[0]
        : this.filterArray(render, activeRules.render),
      thumbnail: thumbnail[0],
      get: get[0],

      // get: this.filterArray(get, activeRules.get),
      // thumbnail: this.filterArray(thumbnail, activeRules.renderThumbnail),
    };
  };

  filterArray = (array, rules) => {
    return array.filter((item) => {
      return this.isValidItem(item, rules);
    })[0];
  };

  isValidItem = (item, rules) => {
    return rules.indexOf(item) !== -1;
  };

  dispatchAction = ({ get, render, thumbnail }) => {
    if (render !== undefined) {
      this.props.renderCanvas(render);
      return false;
    }

    if (thumbnail !== undefined) {
      this.props.renderThumbnail(thumbnail);
      return false;
    }

    if (get !== undefined) {
      this.props.getPage(get, this.props.pagesSettings, this.props.pdfScale);
      return false;
    }

    return true;
  };

  render = () => {
    return nullComponent();
  };
}
