import PropTypes from 'prop-types';
import React from 'react';
import * as Ui from '../../../ui';

const thumbnailsNavigationWrapper = ({ children }) => {
  return (
    <Ui.SideBar.Cell>
      <Ui.PageNavigation.Body
        offset={Ui.pageNavigation.offsets.medium}
      >
        {children}
      </Ui.PageNavigation.Body>
    </Ui.SideBar.Cell>
  );
};

thumbnailsNavigationWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

export default thumbnailsNavigationWrapper;
