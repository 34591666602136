import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { needGet } from '../../../helpers/const';
import { nullComponent } from '../../../helpers/utilsForReact15';
import { setNeedGet } from '../../../store/modules/pdf';
import * as baseSelectors from '../../../store/selectors/baseSelectors';

@connect(
  (state) => {
    return {
      count: baseSelectors.getPdfCount(state),
      pdfPages: baseSelectors.getPdfPages(state),
      activePageId: baseSelectors.getActivePageId(state),
    };
  },
  { setNeedGet },
)
export default class PdfGetController extends Component {
  static propTypes = {

    // from global.state
    activePageId: PropTypes.number.isRequired,
    pdfPages: PropTypes.arrayOf(
      PropTypes.number,
    ).isRequired,

    // actions
    setNeedGet: PropTypes.func.isRequired,
  };

  static contextTypes = {
    getAdjacentPageId: PropTypes.func,
  };

  componentDidMount() {
    this.props.setNeedGet(this.getNeedPages());
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.activePageId !== this.props.activePageId ||
      prevProps.pdfPages !== this.props.pdfPages
    ) {
      this.props.setNeedGet(this.getNeedPages());
    }
  }

  getNeedPages = () => {
    return needGet.map((page) => {
      return this.context.getAdjacentPageId(page);
    });
  };

  render = () => {
    return nullComponent();
  }
}
