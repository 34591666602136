import PropTypes from 'prop-types';
import React from 'react';

import * as Ui from '../../../../../ui';
import {
  BToolbarSettings,
  IToolbarSettings,
  UToolbarSettings,
} from '../Style';

const FontStylePopupSettings = ({ locale }) => {
  return (
    <Ui.Menu.ListItem>
      <Ui.Menu.Item>
        <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.body}>

          <Ui.Menu.ItemByType attributes={Ui.attributes.grow}>
            <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.text}>
              <Ui.Text
                size={16}
                align={Ui.text.aligns.left}
              >
                {locale}
              </Ui.Text>
            </Ui.Menu.ItemByType>
          </Ui.Menu.ItemByType>

          <Ui.Menu.ItemByType attributes={Ui.attributes.shrinkOff}>
            <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.toolbar}>
              <Ui.ToolBar.Body
                size={Ui.toolBar.sizes.small}
                theme={Ui.toolBar.themes.bordered}
                type={Ui.toolBar.types.narrow}
              >
                <Ui.ToolBar.Main>
                  <Ui.ToolBar.Group>
                    <BToolbarSettings />
                    <IToolbarSettings />
                    <UToolbarSettings />
                  </Ui.ToolBar.Group>
                </Ui.ToolBar.Main>
              </Ui.ToolBar.Body>
            </Ui.Menu.ItemByType>
          </Ui.Menu.ItemByType>

        </Ui.Menu.ItemByType>
      </Ui.Menu.Item>
    </Ui.Menu.ListItem>
  );
};

FontStylePopupSettings.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default FontStylePopupSettings;
