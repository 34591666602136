import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DraggableCore } from 'react-draggable';

import CurveToolView from '../../../Tools/CurveTool/CurveToolView';
import RectToolView from '../../../Tools/RectTool/RectToolView';
import { getViewboxFromFrameSize } from '../DrawingLayerPoints/DrawingLayerPointsView';

import { pathFromPoints } from '../../../../helpers/graphicUtils';
import { defaultControlPoints } from '../../../../helpers/const';

export default class DrawingLayerCurveView extends Component {
  static propTypes = {
    onDragStart: PropTypes.func.isRequired,
    onDragMove: PropTypes.func.isRequired,
    onDragStop: PropTypes.func.isRequired,
    frameSize: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }).isRequired,
    fillColor: PropTypes.string.isRequired,
    fillAlpha: PropTypes.number,
    lineWidth: PropTypes.number.isRequired,
    controlPoints: PropTypes.oneOfType([
      PropTypes.array.isRequired,
      PropTypes.bool.isRequired,
    ]),
    isRect: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    fillAlpha: 1,
    controlPoints: false,
  };

  getRectPoints = () => {
    const controlPoints = this.props.controlPoints || defaultControlPoints;
    const halfLineWidth = this.props.lineWidth / 2;
    return [
      controlPoints[0] - halfLineWidth,
      controlPoints[1] - halfLineWidth,
      controlPoints[2] + halfLineWidth,
      controlPoints[3] - halfLineWidth,
      controlPoints[2] + halfLineWidth,
      controlPoints[3] + halfLineWidth,
      controlPoints[0] - halfLineWidth,
      controlPoints[1] + halfLineWidth,
    ];
  };

  render() {
    const {
      fillColor,
      lineWidth,
      frameSize,
      fillAlpha,
      controlPoints,
      isRect,
    } = this.props;

    return (
      <div className="drawingLayerCurveView-DrawingLayerCurve">
        {controlPoints && controlPoints.length > 2 &&
          <div className="drawingElement-DrawingLayerCurve">
            {isRect ? (
              <RectToolView
                fillColor={fillColor}
                fillAlpha={fillAlpha}
                viewBox={getViewboxFromFrameSize(frameSize)}
                path={pathFromPoints(this.getRectPoints())}
                needResizeBox={false}
                isDisabled={false}
              />
            ) : (
              <CurveToolView
                fillColor={fillColor}
                lineWidth={lineWidth}
                viewBox={getViewboxFromFrameSize(frameSize)}
                paths={[pathFromPoints(controlPoints)]}
                isDisabled={false}
              />
            )}
          </div>
        }
        <DraggableCore
          onStart={this.props.onDragStart}
          onDrag={this.props.onDragMove}
          onStop={this.props.onDragStop}
          enableUserSelectHack={false}
        >
          <div
            className="dragHandler-DrawingLayerCurve"
            data-autotest="drawingLayerCurve"
          />
        </DraggableCore>
      </div>
    );
  }
}
