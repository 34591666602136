import PropTypes from 'prop-types';
import { Component } from 'react';

export default class PageContextProvider extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    pageId: PropTypes.number.isRequired,
    getPagesViewport: PropTypes.func.isRequired,
  };

  static childContextTypes = {
    getPageViewport: PropTypes.func,
  };

  getChildContext = () => {
    return {
      getPageViewport: this.getPageViewport,
    };
  };

  getPageViewport = () => {
    const { pageId } = this.props;
    const { frameSizes, frameOffsets, originalSizes,
      ...other } = this.props.getPagesViewport();
    return {
      frameSize: frameSizes[pageId],
      frameOffset: frameOffsets[pageId],
      originalSize: originalSizes[pageId],
      ...other,
    };
  };

  render() {
    return this.props.children;
  }
}
