import { selectors } from '../../..';

/**
 * obj -> bool
 * Не обрабатываем клики по элементам если функция возвращает true
 */
const needToCancelElementClickProcessing = ({
  isPagePinching,
  isPageScaling,
  isPageChanging,
  isPagePanning,
}) => {
  return isPagePinching || isPageScaling || isPageChanging || isPagePanning;
};

// Selector для функции 👆🏻 выше, принимает state
const needToCancelElementClickProcessingSelector = (state) => {
  return needToCancelElementClickProcessing({
    isPagePinching: selectors.base.isPagePinching(state),
    isPageScaling: selectors.base.isPageScaling(state),
    isPageChanging: selectors.base.isPageChanging(state),
    isPagePanning: selectors.base.isPagePanning(state),
  });
};

export default needToCancelElementClickProcessingSelector;
