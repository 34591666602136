import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import get from 'lodash/get';
import { DONE_BUTTON_DESTROY_TYPE } from 'ws-editor-lib/actions/constants';
import { elemPrevUserActedTrackPoint } from '../../../helpers/const';

import * as Ui from '../../../ui';
import HeaderDeclineButton from '../HeaderDeclineButton/HeaderDeclineButton';
import HeaderDraftButton from '../HeaderDraftButton/HeaderDraftButton';
import Dropdown from '../../Dropdown/Dropdown';
import StoreRefProvider from '../../StoreRef/StoreRefProvider';
import { possiblePositions as wrapperPositions } from '../../Portal/helpers/const';

const separatorKey = 'separator';
const icons = {
  'done.print': Ui.icon.types.print,
  'done.email': Ui.icon.types.email,
  'done.fax': Ui.icon.types.fax,
  'done.sms': Ui.icon.types.sms,
  'done.saveas': Ui.icon.types.save,
  'done.share': Ui.icon.types.share,
  'done.s2s': Ui.icon.types.s2s,
  'done.l2f': Ui.icon.types.l2f,
  'done.signnow': Ui.icon.types.signnow,
  'done.myforms': Ui.icon.types.return,
  'done.notarize': Ui.icon.types.notarize,
  'done.irs': Ui.icon.types.irs,
  'done.resend': Ui.icon.types.redesignResend,
};

export default class HeaderDoneView extends Component {
  static propTypes = {
    doneButton: PropTypes.shape({
      main: PropTypes.object.isRequired,
      list: PropTypes.array.isRequired,
    }).isRequired,
    doneButtonLocale: PropTypes.string.isRequired,
    isDraftButtonVisible: PropTypes.bool.isRequired,
    isDeclineButtonVisible: PropTypes.bool.isRequired,

    onClick: PropTypes.func.isRequired,
    onOptionClick: PropTypes.func.isRequired,
    onTogglerMouseDown: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    // NOTE: Это воркэраунт вокруг циклических зависимострей
    this.wrapperProps = {
      arrowPosition: 'top-right',
      locatorArgs: {
        position: wrapperPositions.bottomRight,
      },
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.generateHandlers();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.doneButton !== this.props.doneButton) {
      this.generateHandlers(nextProps);
    }
  }

  generateHandlers(props = this.props) {
    const list = get(props, 'doneButton.list', false);
    // TODO REFACTORING
    // sometime after cancel, list came from ws as {}
    if (list && Array.isArray(list)) {
      this.onOptionClickHandlers = list.map(({
        blankLocation,
        id,
        location,
      }) => {
        return props.onOptionClick({
          inputLocation: location,
          id,
          blankLocation,
          destroyType: DONE_BUTTON_DESTROY_TYPE,
        });
      });
    }
  }

  renderListEntry = ({ id, label }, index) => {
    if (id === separatorKey) {
      return <Ui.Menu.Separator key={index} />;
    }

    return (
      <Ui.Menu.ListItem key={index}>
        <Ui.Control
          size={Ui.control.sizes.smallStretched}
          offset={Ui.control.offsets.medium}
          justify={Ui.control.justifies.flexStart}
          icon={
            <Ui.Icon
              type={icons[id]}
              size={Ui.icon.sizes.small}
            />
          }
          onClick={this.onOptionClickHandlers[index]}
          inheritRadius
        >
          <Ui.Text size={14} weight={Ui.text.weights.regular}>
            {label || id}
          </Ui.Text>
        </Ui.Control>
      </Ui.Menu.ListItem>
    );
  };

  render() {
    const {
      doneButton,
      doneButtonLocale,
      isDraftButtonVisible,
      isDeclineButtonVisible,
    } = this.props;
    const haveList = doneButton.list && doneButton.list.length > 0;
    return (
      <Ui.Container.GridCellAndWrapper>
        <StoreRefProvider>
          {({ storeRef, getRef }) => {
            return (
              <Ui.EditorAction.Index
                {...(
                  (isDraftButtonVisible || isDeclineButtonVisible)
                    ? {
                      type: Ui.editorAction.types.compact,
                      size: Ui.editorAction.sizes.null,
                      offset: Ui.editorAction.offsets.medium,
                    }
                    : {
                      size: Ui.editorAction.sizes.small,
                    }
                )}
              >
                {isDraftButtonVisible && <HeaderDraftButton />}

                {isDeclineButtonVisible && <HeaderDeclineButton />}

                <Ui.EditorAction.Item
                  storeRef={storeRef}
                >
                  <Ui.Group.Index
                    size={Ui.group.sizes.parent}
                    type={Ui.group.types.compact}
                    behavior={Ui.group.behaviors.flattenRadius}
                  >
                    <Ui.Group.Body>
                      <Ui.Group.Item
                        attributes={Ui.attributes.grow}
                        data-autotest-name="done_button"
                        id={elemPrevUserActedTrackPoint.done}
                      >
                        <Ui.Control
                          theme={Ui.control.themes.primary}
                          size={Ui.control.sizes.mediumStretched}
                          offset={Ui.control.offsets.medium}
                          icon={<Ui.Icon
                            type={Ui.icon.types.checkMarkThin}
                            size={Ui.icon.sizes.small}
                          />}
                          onClick={
                            doneButton
                              ? this.props.onClick
                              : () => {
                                return null;
                              }
                          }
                          inheritRadius
                          dataRedesign
                        >
                          <Ui.Text weight={Ui.text.weights.normal} size={14}>
                            {doneButtonLocale}
                          </Ui.Text>
                        </Ui.Control>
                      </Ui.Group.Item>
                      {haveList &&
                        <Fragment>
                          <Ui.Separator
                            theme={Ui.separator.themes.default}
                            type={Ui.separator.types.vertical}
                          />
                          <Ui.Group.Item>
                            <Dropdown
                              wrapperProps={this.wrapperProps}
                              getAlignRef={getRef}
                              dropdownComponent={() => {
                                return (
                                  <Ui.Menu.Body>
                                    <Ui.Menu.List>
                                      {doneButton.list.map(this.renderListEntry)}
                                    </Ui.Menu.List>
                                  </Ui.Menu.Body>
                                );
                              }}
                              clickCloses
                            >
                              {({ togglePortal, isPortalOpen, storeRef: storeTriggerRef }) => {
                                return (
                                  <Ui.Control
                                    theme={Ui.control.themes.primary}
                                    size={Ui.control.sizes.medium}
                                    offset={Ui.control.offsets.medium}
                                    icon={<Ui.Icon
                                      type={Ui.icon.types.arrowDown}
                                      size={Ui.icon.sizes.small}
                                    />}
                                    storeRef={storeTriggerRef}
                                    isPressed={isPortalOpen}
                                    // https://pdffiller.atlassian.net/browse/JSF-7495
                                    // Added element deactivation on mouse down
                                    // for earlier all set popover calculation earlier
                                    onMouseDown={this.props.onTogglerMouseDown}
                                    onClick={togglePortal}
                                    inheritRadius
                                    dataRedesign
                                  />
                                );
                              }}
                            </Dropdown>
                          </Ui.Group.Item>
                        </Fragment>
                      }
                    </Ui.Group.Body>
                  </Ui.Group.Index>
                </Ui.EditorAction.Item>
              </Ui.EditorAction.Index>
            );
          }}
        </StoreRefProvider>
      </Ui.Container.GridCellAndWrapper>
    );
  }
}
