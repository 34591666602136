import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import {
  activateTool,
} from 'ws-editor-lib/actions';
import { selectors, thunks } from '../../..';
import {
  toolDraggingStarted,
  toolDraggingStopped,
} from '../../../store/modules/events';

import { wizardFocusSelector } from '../../../helpers/selectors';
import { setIsSearchModeActive } from '../../../store/modules/search';

@connect(
  (state) => {
    return {
      activeTool: selectors.base.getActiveTool(state),
      activeElementId: selectors.base.getActiveElementId(state),
      haveWizardFocus: !!wizardFocusSelector(state),
      wizardActive: get(state, 'wizard.wizardActive', false),
    };
  },
  {
    activateTool,
    setActiveElement: thunks.setActiveElement,
    toolDraggingStarted,
    toolDraggingStopped,
    setIsSearchModeActive,
  },
)
export default class ActivateToolProvider extends Component {
  static propTypes = {
    activeElementId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]).isRequired,
    activeTool: PropTypes.shape({
      subType: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
    haveWizardFocus: PropTypes.bool.isRequired,
    wizardActive: PropTypes.bool.isRequired,
    setIsSearchModeActive: PropTypes.func.isRequired,

    children: PropTypes.func.isRequired,

    activateTool: PropTypes.func.isRequired,
    setActiveElement: PropTypes.func.isRequired,
    toolDraggingStarted: PropTypes.func.isRequired,
    toolDraggingStopped: PropTypes.func.isRequired,
  };

  static defaultProps = {
    activeTool: null,
  };

  render() {
    return this.props.children({
      activeTool: this.props.activeTool,
      activeElementId: this.props.activeElementId,
      wizardActive: this.props.wizardActive,
      setActiveElement: this.props.setActiveElement,
      activateTool: this.props.activateTool,
      haveWizardFocus: this.props.haveWizardFocus,
      toolDraggingStarted: this.props.toolDraggingStarted,
      toolDraggingStopped: this.props.toolDraggingStopped,
      setIsSearchModeActive: this.props.setIsSearchModeActive,
    });
  }
}
