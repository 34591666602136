import PropTypes from 'prop-types';
import React from 'react';

const getSvg = (path) => {
  const svg = ({ color }) => {
    return (
      <svg
        x={0}
        y={0}
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="icon_pic"
      >
        <g>
          <path d={path} fill={color} />
        </g>
      </svg>
    );
  };

  svg.propTypes = {
    color: PropTypes.string.isRequired,
  };

  return svg;
};

export default getSvg;
