import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { thisDevice } from '@pdffiller/jsf-useragent';

import { comments, LazyComponent } from '@pdffiller/jsf-lazyload';
import StoreRefProvider from 'jsfcore/components/StoreRef/StoreRefProvider';

import { selectors } from '../../..';
import Hammer, { inputType } from '../../../helpers/Hammer';
import {
  screenToPage,
  pageToScreen,
  getNewFrameOffset,
  getDefaultScales,
} from '../../../helpers/utils';
import {
  defaultTransformOrigin,
  scaleAnimaion,
  scalePageAnimationTime,
  maxScale,
  minScale,
  getMaxResolution,
  popupStatuses,
  defaultZoomScale,
  ghostVisibleOverClassName,
} from '../../../helpers/const';
import {
  setScale,
  setAllScales,
  changeScale,
} from '../../../store/modules/navigation';
import {
  SCALE_FIT_PAGE,
  SCALE_FIT_WIDTH,
} from '../../../store/modules/navigationActionTypes';

import { setPagePinching, setPageScaling } from '../../../store/modules/events';

import PageCanvas from '../PageCanvas/PageCanvas';
import ConnectedPageCanvasInserter from '../PageCanvas/PageCanvasInserter';

import WheelConverter from '../../../helpers/WheelConverter';
import WheelZoom from './WheelZoom';

@connect(
  (__, { pageId }) => {
    return (state) => {
      const isActivePage = selectors.getIsActivePage(state, pageId);
      const hasActiveElement = selectors.base.getActiveElementId(state) !== false;

      return {
        isActivePage,
        isCommentsModeActive: selectors.mode.isComments(state),
        workspace: state.viewport.workspace,
        scale: state.navigation.scales[pageId],
        nextScale: isActivePage && state.navigation.nextScale,
        defaultScale: selectors.getDefaultScale(state, pageId),
        headerScale: state.navigation.headerScales[pageId],
        frameSize: selectors.getFrameSize(state, pageId),
        frameOffset: selectors.getFrameOffset(state, pageId),
        originalSize: state.navigation.originalSizes[pageId],
        pagePanning: isActivePage && state.events.pagePanning,
        pageChanging: isActivePage && state.events.pageChanging,
        popupVisibility: state.viewport.popupVisibility,
        isKeyboardShown: state.viewport.isKeyboardShown,
        wizardFooterHeight: state.viewport.wizardFooterHeight,
        dragElementId: isActivePage && state.events.dragElementId,
        hasActiveElement,
      };
    };
  }, {
    setPagePinching,
    setPageScaling,
    setScale,
    setAllScales,
    changeScale,
  },
)
export default class PagePinch extends Component {
  static propTypes = {
    pageId: PropTypes.number.isRequired,

    // from global.state
    workspace: PropTypes.shape({
      top: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      framePadding: PropTypes.shape({
        top: PropTypes.number.isRequired,
        bottom: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        right: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    scale: PropTypes.number,
    nextScale: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    headerScale: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    defaultScale: PropTypes.shape({}),
    frameSize: PropTypes.oneOfType([
      PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
      }),
      PropTypes.bool,
    ]).isRequired,
    frameOffset: PropTypes.shape({
      top: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
      scrollTop: PropTypes.number.isRequired,
      scrollLeft: PropTypes.number.isRequired,
    }).isRequired,
    originalSize: PropTypes.oneOfType([
      PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
      }),
      PropTypes.bool,
    ]),
    pagePanning: PropTypes.bool.isRequired,
    pageChanging: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    wizardFooterHeight: PropTypes.number.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    popupVisibility: PropTypes.oneOf(Object.values(popupStatuses)).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    isKeyboardShown: PropTypes.bool.isRequired,
    dragElementId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]).isRequired,
    isActivePage: PropTypes.bool.isRequired,
    isCommentsModeActive: PropTypes.bool.isRequired,
    // TODO: remove eslint-disable-next-line after remove UNSAFE_componentWillReceiveProps
    // eslint-disable-next-line react/no-unused-prop-types
    hasActiveElement: PropTypes.bool.isRequired,

    // actions
    setPagePinching: PropTypes.func.isRequired,
    setPageScaling: PropTypes.func.isRequired,
    setAllScales: PropTypes.func.isRequired,
    changeScale: PropTypes.func.isRequired,

    // parent event
    updateScale: PropTypes.func.isRequired,
  };

  static defaultProps = {
    originalSize: false,
    defaultScale: {},
    scale: defaultZoomScale,
    headerScale: defaultZoomScale,
  };

  constructor(props) {
    super(props);
    this.pinchEvents = [];
    this.pinching = false;
    this.origin = undefined;
    this.scale = undefined;
    this.validateScaleTimeout = null;
    this.wheelZoom = undefined;
  }

  componentDidMount() {
    this.delegateEvents();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      isActivePage,
      pageId,
      pagePanning,
      pageChanging,
      nextScale,
      scale,
      frameSize,
      popupVisibility,
      isKeyboardShown,
      wizardFooterHeight,
      headerScale,
      defaultScale,
      workspace,
      frameOffset,
      hasActiveElement,
    } = nextProps;
    const { pagePanning: oldPagePanning } = this.props;
    const { pageChanging: oldPageChanging } = this.props;

    if (pagePanning !== oldPagePanning) {
      this.enableHammerPinch(!pagePanning);
    }

    if (
      thisDevice.isMobile &&
      !pageChanging &&
      pageChanging !== oldPageChanging &&
      !isKeyboardShown
    ) {
      this.props.setAllScales(SCALE_FIT_PAGE, pageId);
    }

    if (
      nextScale && nextScale !== this.props.nextScale && isActivePage
    ) {
      this.onNextScaleChanged(nextScale);
    }

    if (scale !== this.props.scale) {
      this.onScaleChanged(scale);
    }

    const isDefaultScaleShouldBeCanceled = thisDevice.isMobile && (
      hasActiveElement ||
      popupVisibility !== popupStatuses.hidden ||
      wizardFooterHeight
    );

    if (!isDefaultScaleShouldBeCanceled && nextProps.defaultScale !== this.props.defaultScale) {
      this.onDefaultScaleChanged({
        headerScale,
        defaultScale,
        workspace,
        frameOffset,
        pageId,
      });
    }

    if (frameSize && this.props.frameSize !== frameSize) {
      this.restoreDefaultTransform();
    }
  }

  componentWillUnmount() {
    if (this.pinchStartAminationFrameReqId) {
      cancelAnimationFrame(this.pinchStartAminationFrameReqId);
    }
    if (this.pinchEndAminationFrameReqId) {
      cancelAnimationFrame(this.pinchEndAminationFrameReqId);
    }
    if (this.validateScaleTimeout) {
      clearTimeout(this.validateScaleTimeout);
    }
  }

  onDefaultScaleChanged = ({
    headerScale,
    defaultScale,
    workspace,
    frameOffset,
    pageId,
    isActivePage,
  }) => {
    const FP = SCALE_FIT_PAGE;
    const FW = SCALE_FIT_WIDTH;
    const prevScale = this.props.scale;
    if (
      (headerScale === FP && defaultScale[FP] !== this.props.defaultScale[FP]) ||
      (headerScale === FW && defaultScale[FW] !== this.props.defaultScale[FW])
    ) {
      // check from big_files
      if (thisDevice.isMobile) {
        const newScale = defaultScale[headerScale];
        const screenCenter = {
          x: workspace.left + (workspace.width / 2),
          y: workspace.top + (workspace.height / 2),
        };
        const { x, y } = screenToPage(screenCenter, prevScale, frameOffset, workspace);
        const offset = getNewFrameOffset({ x, y }, newScale, screenCenter, workspace);
        this.setScaleImmediately(pageId, headerScale, offset);
      } else if (isActivePage) {
        this.props.setAllScales(headerScale, pageId);
      }
    }
  };

  onNextScaleChanged = (nextScale) => {
    const {
      isActivePage,
      pageId,
      scale: currentScale,
      frameOffset,
      workspace,
      originalSize,
    } = this.props;
    if (!nextScale || !isActivePage) {
      return;
    }
    if (this.validateScaleTimeout) {
      clearTimeout(this.validateScaleTimeout);
      this.validateScaleTimeout = null;
    }

    const scale = getDefaultScales(nextScale, workspace, originalSize);
    const screenCenter = this.origin
      ? pageToScreen(this.origin, this.props.scale, frameOffset, workspace)
      : {
        x: workspace.left + (workspace.width / 2),
        y: workspace.top + (workspace.height / 2),
      };

    const { x, y } = screenToPage(screenCenter, this.props.scale, frameOffset, workspace);
    const time = Math.abs(this.scale - (scale / this.props.scale)) < 0.1
      ? 0
      : (scalePageAnimationTime);
    if (currentScale === scale) {
      this.setPageTransform(1, x * this.props.scale, y * this.props.scale, null, null, true);
      this.setScaleImmediately(pageId, nextScale, 0, time);
      this.origin = null;
      return;
    }

    const offset = getNewFrameOffset({ x, y }, scale, screenCenter, workspace, originalSize);
    this.props.setPageScaling(true);
    this.setPageTransform(scale / this.props.scale, x * this.props.scale, y * this.props.scale,
      offset, frameOffset, true);
    this.validateScaleTimeout = setTimeout(() => {
      const validScale = this.validateScale(scale);
      if (validScale !== scale) {
        this.props.changeScale(validScale, pageId);
        return;
      }

      const newOffset = getNewFrameOffset({ x, y }, scale, screenCenter, workspace);
      this.setScaleImmediately(pageId, nextScale, newOffset, 0);
    }, time);
  };

  onScaleChanged = () => {
    this.origin = null;
  };

  setScaleImmediately = (pageId, scale, offset, time) => {
    if (this.validateScaleTimeout) {
      clearTimeout(this.validateScaleTimeout);
    }

    this.validateScaleTimeout = setTimeout(() => {
      // NOTE: Изначально restoreDefaultTransform вызывался после setScale
      // из-за этого система получает уведомление о новом scale до того
      // как transform снят и 2 раза накладывается масштаб на рулер
      this.restoreDefaultTransform();
      this.props.setAllScales(scale, pageId);
      this.props.updateScale(offset);
      setTimeout(() => {
        this.props.setPageScaling(false);
      }, 250);
    }, time || 0);
  };

  // cX - centerX
  // cY - centerY
  setPageTransform = (scale, cX, cY, offset, frameOffset, anim) => {
    setTimeout(() => {
      const node = this.getElNode();
      this.scale = scale;

      let left = 0;
      let top = 0;
      if (offset) {
        left = cX - (((cX - offset.left - frameOffset.scrollLeft) + frameOffset.left) / scale);
        top = cY - (((cY - offset.top - frameOffset.scrollTop) + frameOffset.top) / scale);
      }

      node.style.transformOrigin = (!cX && !cY)
        ? defaultTransformOrigin
        : `${cX}px ${cY}px 0`;

      const animDuration = anim
        ? scalePageAnimationTime
        : 0;

      node.style.transition =
        `${animDuration}ms ${scaleAnimaion}`;

      node.style.transform =
        `scale(${scale}) translate3d(${left}px,${top}px,0)`;
    }, 0);
  };

  getHammerNode = () => {
    return this.pinchHammerRef;
  };

  getElNode = () => {
    return this.pinchElRef;
  };

  storePinchHammerRef = (ref) => {
    this.pinchHammerRef = ref;
  };

  storePinchElRef = (ref) => {
    this.pinchElRef = ref;
  };

  validateScale = (scale) => {
    const { originalSize } = this.props;
    const fitPage = this.props.defaultScale[SCALE_FIT_PAGE];
    if (thisDevice.isMobile) {
      if (scale < fitPage) {
        return fitPage;
      }
    } else if (scale < minScale) {
      return minScale;
    }

    if (scale > maxScale) {
      return maxScale;
    }

    const resolution = {
      width: originalSize.width * scale,
      height: originalSize.height * scale,
    };
    const maxResolution = getMaxResolution();
    if (resolution.width * resolution.height > maxResolution) {
      const maxResolutionScale =
        Math.sqrt(maxResolution / originalSize.width / originalSize.height);
      return Math.floor(maxResolutionScale * 10) / 10.0;
    }

    return scale;
  };

  restoreDefaultTransform() {
    const node = this.getElNode();
    node.style.transition = null;
    node.style.transformOrigin = defaultTransformOrigin;
    node.style.transform = '';
    this.scale = 1;
  }

  enableHammerPinch = (enable) => {
    this.hammer.get('pinch').set({ enable });
  };

  delegateEvents() {
    if (!Hammer) {
      return false;
    }
    const node = this.getHammerNode();
    const pinch = new Hammer.Pinch();
    this.hammer = new Hammer.Manager(node, {
      touchAction: 'auto',
      inputClass: inputType(),
    });
    this.hammer.add(pinch);
    this.hammer.on('pinchstart', this.onPinchStart);
    this.hammer.on('pinchmove', this.onPinchMove);
    this.hammer.on('pinchend', this.onPinchEnd);
    this.hammer.on('pinchcancel', this.onPinchCancel);

    if (thisDevice.isDesktop) {
      const wheelConverter = new WheelConverter();

      this.wheelZoom = new WheelZoom({
        onStart: () => {
          this.onPinchStart(
            wheelConverter.onStartToOnPinchStart(this.props),
          );
        },
        onMove: (event) => {
          this.onPinchMove(
            wheelConverter.onMoveToOnPinchMove(event, this.scale),
          );
        },
        onEnd: () => {
          this.onPinchEnd(
            wheelConverter.onEndToOnPinchEnd(this.scale),
          );
        },
        node,
      });
    }

    if (thisDevice.isIOS) {
      // JSF-1676: в iOS10 стало можно зумить страницу,
      // даже если установлен user-scalable=no (типа заботятся об accessibility)
      // пока помогоает только такой хак
      document.addEventListener('touchmove', (event) => {
        // выдает warning, т.к. при имитации в хроме "event.scale" === undefined
        // (на реальном айфоне все нормально)
        if (event.scale !== undefined && event.scale !== 1) {
          event.preventDefault();
        }
      });
    }

    return true;
  }

  onPinchStart = (event) => {
    if (!event) {
      return;
    }
    event.preventDefault();

    this.pinchStartAminationFrameReqId = requestAnimationFrame(() => {
      if (this.props.dragElementId || this.pinching) {
        return;
      }

      const { scale, frameOffset, workspace, originalSize } = this.props;

      this.pinchEvents = [];
      this.pinching = true;
      const node = this.getElNode();

      this.origin = screenToPage(event.center, scale, frameOffset, workspace);
      if (this.origin.x < 0) {
        this.origin.x = 0;
      }
      if (this.origin.y < 0) {
        this.origin.y = 0;
      }
      if (this.origin.x > originalSize.width) {
        this.origin.x = originalSize.width;
      }
      if (this.origin.y > originalSize.height) {
        this.origin.y = originalSize.height;
      }

      node.style.transition = null;
      node.style.transform = 'translateZ(0)';
      node.style.transformOrigin = `${this.origin.x * scale}px ${this.origin.y * scale}px 0`;

      if (!event.notSetPagePinching) {
        this.props.setPagePinching(true);
      }
    });
  };

  onPinchMove = (event) => {
    if (!event) {
      return;
    }
    event.preventDefault();
    if (this.props.dragElementId || !this.pinching) {
      return;
    }

    this.pinchEvents.push(event);
    const { scale } = event;
    const node = this.getElNode();
    this.scale = scale;
    if (this.setTransformRequest) {
      return;
    }
    this.setTransformRequest = requestAnimationFrame(() => {
      if (this.setTransformRequest) {
        cancelAnimationFrame(this.setTransformRequest);
      }
      this.setTransformRequest = null;
      if (this.props.dragElementId || !this.pinching) {
        return;
      }
      node.style.transform = `scale(${scale}) translate3d(0,0,0)`;
    });
  };

  onPinchEnd = (event) => {
    if (!event) {
      return;
    }
    event.preventDefault();

    if (!this.pinching || this.props.dragElementId) {
      return;
    }

    this.pinchEvents = [];
    const { scale } = event;

    this.pinchEndAminationFrameReqId = requestAnimationFrame(() => {
      this.pinching = false;
      this.props.changeScale(scale * this.props.scale, this.props.pageId);
      if (!event.notSetPagePinching) {
        this.props.setPagePinching(false);
      }
    });
  };

  onPinchCancel = (event) => {
    if (!event) {
      return;
    }
    event.preventDefault();
    if (!this.pinching) {
      return;
    }
    // TODO: Массив pinchEvents нам не нужен, нам нужен последний
    // event pinchMove
    this.onPinchEnd(this.pinchEvents[this.pinchEvents.length - 1]);
  };

  render() {
    const { pageId, scale, isActivePage, isCommentsModeActive } = this.props;
    return (
      <div ref={this.storePinchHammerRef} className="pagePinch-PagePinch">
        <div
          ref={this.storePinchElRef}
          className={classnames('pagePinchBlank-PagePinch', ghostVisibleOverClassName)}
        >
          {scale &&
            <StoreRefProvider>
              {({ storeRef, getRef }) => {
                return (
                  <ConnectedPageCanvasInserter getRef={getRef} pageId={pageId}>
                    <PageCanvas pageId={pageId} storeRef={storeRef} />
                  </ConnectedPageCanvasInserter>
                );
              }}
            </StoreRefProvider>
          }
        </div>
        {isCommentsModeActive && scale && (
          <LazyComponent
            literal={comments.components.comments}
            props={{ pageId, scale, isActivePage }}
          />
        )}
      </div>
    );
  }
}
