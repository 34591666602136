import PropTypes from 'prop-types';
import { Component } from 'react';
import pick from 'lodash/pick';

import {
  dragSnapToFieldHelper,
  getFillableElementsByPage,
  getSimpleElementsByPageExceptSome,
  getElementsExceptCurrentOne,
  showMagneticLinesOnlyForNearestElements,
  modifySizeByBorder,
} from '../../../helpers/magneticLinesHelpers';
import { selectors } from '../../..';
import { getElementProps } from '../../../store/helpers/functions';
import config from '../../../helpers/clientConfig';
import {
  hasMagneticLines,
  getIsFakeEditTextElement,
  getIsAspectRatioPreserved,
} from '../../../helpers/elemTypes';
import { positions } from '../../../helpers/const';
import {
  applyRatio,
  getRoundedGeometry,
} from '../../../helpers/resizeUtils';

export default class MagneticLinesProvider extends Component {
  static propTypes = {
    element: PropTypes.shape({
      type: PropTypes.string.isRequired,
      subType: PropTypes.string.isRequired,
      template: PropTypes.shape({}),
      content: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
      }),
      id: PropTypes.string.isRequired,
      pageId: PropTypes.number.isRequired,
    }).isRequired,
    isFillableElement: PropTypes.bool.isRequired,
    scale: PropTypes.number.isRequired,
    resizeIndex: PropTypes.number,
    isActiveElement: PropTypes.bool.isRequired,
    isDragging: PropTypes.bool.isRequired,

    children: PropTypes.func.isRequired,
  };

  static contextTypes = {
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
    }),
  };

  static defaultProps = {
    resizeIndex: positions.none,
  };

  constructor(props) {
    super(props);

    this.lines = [];
    this.state = {
      x: null,
      y: null,
      height: null,
      width: null,
    };
    this.elemX = null;
    this.elemY = null;
    this.width = null;
    this.height = null;
    this.scaleHasBeenChanged = false;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!config.app.dragMagneticLinesOn) {
      return;
    }
    if (this.props.isActiveElement === true && nextProps.isActiveElement === false) {
      this.resetMagneticLines();
      return;
    }
    if (!nextProps.isActiveElement) {
      return;
    }
    const { isDragging, isFillableElement, resizeIndex } = nextProps;
    // turn off magnetic effect for resize in case not fillable element
    if (!isFillableElement && resizeIndex !== 0) {
      return;
    }
    if (
      !isDragging &&
      resizeIndex === 0 &&
      !this.scaleHasBeenChanged
    ) {
      return;
    }

    this.scaleHasBeenChanged = this.props.scale !== nextProps.scale;

    if (
      !hasMagneticLines(this.props.element.type) ||
      getIsFakeEditTextElement(this.props.element)
    ) {
      return;
    }

    const {
      x: elemX,
      y: elemY,
      width: elemWidth,
      height: elemHeight,
    } = modifySizeByBorder({
      ...getElementProps(nextProps.element),
      type: nextProps.element.type,
      subType: nextProps.element.subType,
    })();

    if (
      elemX === this.elemX && elemY === this.elemY &&
      elemWidth === this.width && elemHeight === this.height
    ) {
      return;
    }

    const state = this.context.store.getState();
    const elementsExceptCurrentOne = getElementsExceptCurrentOne({
      elements: selectors.elements.getElements(state),
      id: nextProps.element.id,
    });
    const elements = this.props.isFillableElement
      ? getFillableElementsByPage(
        {
          elements: elementsExceptCurrentOne,
          activePageId: this.props.element.pageId,
        },
      )
      : getSimpleElementsByPageExceptSome(
        {
          elements: elementsExceptCurrentOne,
          activePageId: this.props.element.pageId,
        },
      );

    const { x, y, height, width } = dragSnapToFieldHelper({
      elements,
      setMagneticLines: this.setMagneticLines,
      scale: nextProps.scale,
      activeElement: {
        x: elemX,
        y: elemY,
        width: elemWidth,
        height: elemHeight,
      },
      resizeIndex: nextProps.resizeIndex,
    });

    this.elemX = elemX;
    this.elemY = elemY;
    this.width = elemWidth;
    this.height = elemHeight;

    this.setState(
      // for correct magnetic effect we should have this one
      // only for coordinate without border (margins)
      modifySizeByBorder({
        x,
        y,
        height,
        width,
        type: nextProps.element.type,
        subType: nextProps.element.subType,
      })({ increaseSize: false }),
    );
  }

  getRatioPreservedGeometry = () => {
    const initialGeometry = pick(getElementProps(this.props.element), ['x', 'y', 'width', 'height']);
    const newGeometry = {
      x: this.state.x,
      y: this.state.y,
      width: this.state.width,
      height: this.state.height,
    };
    const geometry = applyRatio({
      initialGeometry,
      geometry: newGeometry,
      resizeIndex: this.props.resizeIndex,
    });
    return getRoundedGeometry(geometry);
  };

  setMagneticLines = (lines) => {
    const { scale } = this.props;

    const left = this.elemX * scale;
    const top = this.elemY * scale;
    const { content = this.props.element.template } = this.props.element;
    const { width, height } = content;

    this.lines = showMagneticLinesOnlyForNearestElements({
      lines,
      activeElementShape: {
        left,
        top,
        right: left + (width * scale),
        bottom: top + (height * scale),
      },
    });
  };

  resetMagneticLines = () => {
    this.lines = [];
  };

  patchPosition = (part) => {
    if (!this.props.isDragging && this.props.resizeIndex === 0) {
      return null;
    }

    const isRatioPreserved =
      getIsAspectRatioPreserved(this.props.element.type, this.props.isFillableElement);
    if (isRatioPreserved) {
      const { x, y, width, height } = this.getRatioPreservedGeometry();

      return {
        [part]: {
          ...this.props.element[part],
          ...(x && { x }),
          ...(y && { y }),
          ...(height && { height }),
          ...(width && { width }),
        },
      };
    }

    return {
      [part]: {
        ...this.props.element[part],
        ...(this.state.x && { x: this.state.x }),
        ...(this.state.y && { y: this.state.y }),
        ...(this.state.height && { height: this.state.height }),
        ...(this.state.width && { width: this.state.width }),
      },
    };
  };

  render() {
    // turn off magnetic effect for resize in case not fillable element
    const isResizingSimpleElement = (
      !this.props.isFillableElement && this.props.resizeIndex !== 0
    );
    if (
      !config.app.dragMagneticLinesOn ||
      this.lines.length === 0 || this.scaleHasBeenChanged ||
      isResizingSimpleElement
    ) {
      return (
        this.props.children({
          magneticLines: [],
          magneticElementFromProvider: this.props.element,
        })
      );
    }

    return (
      this.props.children({
        magneticLines: this.lines,
        magneticElementFromProvider: {
          ...this.props.element,
          ...(this.props.isFillableElement && this.patchPosition('template')),
          ...(!this.props.isFillableElement && this.patchPosition('content')),
        },
      })
    );
  }
}
