import { thisDevice } from '@pdffiller/jsf-useragent';
import { pxProps } from '../TextTool/utils/textToolUtils';
import { getValidColor } from '../../../helpers/graphicUtils';
import { getElementCursor } from '../../../helpers/utils';

export const textToContentEditable = (text) => {
  return text.split('').map(
    (char) => {
      return `<i>${char}</i>`;
    },
  ).join('');
};

export const textToContentEditableChrome = (text, width) => {
  return text.split('').map(
    (char) => {
      return `<span style="width: ${width}px">${char}</span>`;
    },
  ).join('');
};

export const textFromContentEditable = (node) => {
  return (node.textContent || node.innerText || '').replace(/\n/g, '');
};

export const getCaretContentEditable = (element) => {
  let caretOffset = 0;
  if (!element) {
    return caretOffset;
  }
  const doc = element.ownerDocument || element.document;
  const win = doc.defaultView || doc.parentWindow;
  let sel;
  if (typeof win.getSelection !== 'undefined') {
    sel = win.getSelection();
    if (sel.rangeCount > 0) {
      const range = win.getSelection().getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
    }
  } else {
    sel = doc.selection;
    if (sel.type !== 'Control') {
      const textRange = sel.createRange();
      const preCaretTextRange = doc.body.createTextRange();
      preCaretTextRange.moveToElementText(element);
      preCaretTextRange.setEndPoint('EndToEnd', textRange);
      caretOffset = preCaretTextRange.text.length;
    }
  }

  return caretOffset;
};

export const setCaretContentEditable = (node, pos) => {
  const range = document.createRange();
  const sel = window.getSelection();
  range.setStart(node, pos);
  range.collapse(true);
  sel.removeAllRanges();
  sel.addRange(range);
};

export const getStyle = (props, isForContentEditable) => {
  const {
    height,
    width,
    paddingTop,
    align,
    fontSize,
    bold,
    italic,
    underline,
    fontFamily,
    fontColor,
    padding,
    isActiveElement,
  } = props;

  return pxProps({
    fontFamily,
    fontSize,
    height,
    width,
    fontWeight: bold
      ? 'bold'
      : 'normal',

    fontStyle: italic
      ? 'italic'
      : 'normal',

    textDecoration: underline
      ? 'underline'
      : 'none',

    color: getValidColor(fontColor),
    cursor: getElementCursor(isActiveElement),
    textAlign: align,
    paddingBottom: isForContentEditable && padding
      ? padding
      : '0px',

    paddingTop: isForContentEditable && paddingTop
      ? paddingTop
      : '0px',

    ...(thisDevice.isInternetExplorer11
      ? { whiteSpace: 'nowrap' }
      : {}),
  });
};
