import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { toolIds } from '../../../helpers/elemTypes';
import ImagePlaceholder from '../../../ui/ImagePlaceholder/ImagePlaceholder';
import * as FillableField from '../../../ui/FillableField';
import ElementIcon from '../../Element/ElementIcon/ElementIcon';
import ElementIconWrapper, { styleForIconWrapper } from '../../Element/ElementIcon/ElementIconWrapper';

const placeholders = {
  [toolIds.image]: 'Image',
  [toolIds.signature]: 'Signature',
  [toolIds.initials]: 'Initials',
};

const classes = {
  [toolIds.initials]: 'fillable-field--type--image fillable-field--type--initials',
  [toolIds.signature]: 'fillable-field--type--image fillable-field--type--signature',
  [toolIds.image]: 'fillable-field--type--image fillable-field--type--picture',
  [toolIds.text]: 'fillable-field--type--text-field fillable-field--type--text',
  [toolIds.date]: 'fillable-field--type--text-field fillable-field--type--date',
  [toolIds.dropdown]: 'fillable-field--type--text-field fillable-field--type--select',
  [toolIds.formula]: 'fillable-field--type--text-field fillable-field--type--formula',
  [toolIds.number]: 'fillable-field--type--text-field fillable-field--type--number',
  [toolIds.checkbox]: 'fillable-field--type--checkbox',
  [toolIds.radio]: 'fillable-field--type--checkbox',
};

const placeholderMinWidth = {
  [toolIds.image]: 82,
  [toolIds.signature]: 115,
  [toolIds.initials]: 115,
};

@connect((state) => {
  return {
    scale: state.navigation.scales[state.navigation.activePageId],
  };
})
export default class FieldTool extends Component {
  static propTypes = {
    element: PropTypes.shape({}).isRequired,
    scale: PropTypes.number.isRequired,
    toolId: PropTypes.string,
    width: PropTypes.number,
  };

  static defaultProps = {
    toolId: null,
    width: 0,
  };

  constructor(props) {
    super(props);
    const {
      element,
      scale,
      toolId,
    } = this.props;

    this.icon =
      <ElementIcon
        fConstructorLoadedAndShown // рендерится только в режиме конструктора
        element={element}
        scale={scale}
      />;

    this.text = placeholders[toolId];
    this.isNoneOfSignatureOrInitialsOrImage = !(
      toolId === toolIds.signature ||
      toolId === toolIds.initials ||
      toolId === toolIds.image
    );
  }

  getInnerText = () => {
    const {
      toolId,
      width,
    } = this.props;
    const text = placeholders[toolId];

    if (width <= placeholderMinWidth[toolId]) {
      return null;
    }

    if (text) {
      return text;
    }

    return null;
  };

  renderIcon = () => {
    if (this.icon) {
      const { scale } = this.props;
      return this.isNoneOfSignatureOrInitialsOrImage
        ? (
          <ElementIconWrapper style={{
            ...styleForIconWrapper,
            padding: `0 ${4 * scale}px`,
          }}
          >
            {this.icon}
          </ElementIconWrapper>
        )
        : (
          <div className="fillable-field__overlay">
            <ElementIconWrapper>
              {this.icon}
            </ElementIconWrapper>
            <ImagePlaceholder
              innerText={this.getInnerText()}
              fontSize={14 * scale}
              paddingLeft={7 * scale}
            />
          </div>
        );
    }

    return null;
  };

  render() {
    const { toolId } = this.props;

    return (
      <FillableField.Body
        className={classes[toolId]}
        isDraggable
        isActive
      >
        <FillableField.Container>
          {this.isNoneOfSignatureOrInitialsOrImage &&
            <input className="fillable-field__input-control" readOnly />}
          {this.renderIcon()}
        </FillableField.Container>
      </FillableField.Body>
    );
  }
}
