import PropTypes from 'prop-types';
import React, { Component } from 'react';
import get from 'lodash/get';
import { comments, getLazy } from '@pdffiller/jsf-lazyload';
import { mayBePatched } from '../../decorators';

import { elemTypes } from '../../helpers/elemTypes';
import { types } from '../../helpers/tools';
import FieldTool from '../Tools/FieldTool/FieldTool';

class UnwrappedElementGhost extends Component {
  static propTypes = {
    isConstructorMode: PropTypes.bool.isRequired,
    scale: PropTypes.number.isRequired,
    ghost: PropTypes.shape({
      id: PropTypes.string.isRequired,
      pageId: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      subType: PropTypes.string.isRequired,
      content: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        brushType: PropTypes.string,
      }).isRequired,
      template: PropTypes.shape({}),
    }),
  };

  static defaultProps = {
    ghost: null,
  };

  getComponentElement() {
    const { isConstructorMode, ghost } = this.props;
    const { type, subType = 'none' } = ghost;

    if (isConstructorMode && ghost.template) {
      return FieldTool;
    }

    switch (type) {
      case elemTypes.comment:
        return getLazy(comments.components.comment);

      default:
        return types[type][subType];
    }
  }

  render() {
    const { scale, ghost } = this.props;

    if (!ghost) {
      return null;
    }

    const { id, pageId, type, subType, content: { width, height, x, y } } = ghost;
    const toolId = get(ghost, 'template.toolId');

    const ComponentElement = this.getComponentElement();

    return (
      <ComponentElement
        scale={scale}
        element={ghost}
        isFillable={false}
        isDisabled={false}
        isDragging={false}
        isGhost
        isActiveElement={false}
        {...ghost.content}
        id={id}
        toolId={toolId}
        type={type}
        subType={subType}
        pageId={pageId}
        y={y}
        x={x}
        width={width}
        height={height}
        checked
      />
    );
  }
}


const WrappedComponent = mayBePatched(
  (context) => {
    return (
      context && context.getPatchedComponent &&
      context.getPatchedComponent('ElementGhost')
    ) || null;
  },
)(UnwrappedElementGhost);

export { UnwrappedElementGhost };
export default WrappedComponent;
