/**
 * Components for render
*/
import DropDownModalLock from './DropDownModalLock/DropDownModalLock';
import { dropDownModalTypes } from '.';

const modalTypesMap = {
  [dropDownModalTypes.text_dropdown]: DropDownModalLock,
  [dropDownModalTypes.invalid_initial_value]: DropDownModalLock,
  [dropDownModalTypes.dropdown_duplicate_value]: DropDownModalLock,
};

const getModalElementByType = (type) => {
  return modalTypesMap[type];
};

export default getModalElementByType;
