import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { thisDevice } from '@pdffiller/jsf-useragent';
import get from 'lodash/get';

import { wizardFocusSelector } from '../../helpers/selectors';
import {
  isPreConfigurableType,
  isTextToolBasedElement as isTextToolBasedElementFn,
} from '../../helpers/elemTypes';
import { zeroSpace, popupStatuses } from '../../helpers/const';
import { isTabletWithActiveElement } from '../../helpers/utils';
import ToolbarView from './ToolbarView';
import { thunks, selectors } from '../..';

@connect(
  (state) => {
    const activeElement = selectors.elements.getActiveElement(state);

    return ({
      haveWizardFocus: !!wizardFocusSelector(state),
      popupVisibility: state.viewport.popupVisibility,
      popupSize: state.viewport.popupSize,
      activeElementId: selectors.base.getActiveElementId(state),
      isTextToolBasedElement: isTextToolBasedElementFn(activeElement),
      ghostElement: selectors.base.getGhostElement(state),
      isFConstructorPreviewShown: get(state, 'fConstructor.isFConstructorPreviewShown', false),
      isFConstructorOrderShown: get(state, 'fConstructor.isFConstructorOrderShown', false),
      isFConstructorLoadedAndShown: selectors.mode.isConstructor.includingPreview(state),
      isPAConstructorShown: state.viewport.isPAConstructorShown,
      isVersionsShown: selectors.base.getIsVersionsShown(state),
      isSearchModeActive: state.search.isSearchModeActive,
      isChoiseByClickingModeIsActive: state.conditions.isActiveChoiseByClickingMode,

      /**
       * Это свойство не используется в компоненте, но необходимо
       * чтобы тулбар перерисовывался при изменении размеров браузера
       * JSF-6127
       */
      workspace: selectors.base.getWorkspace(state),
    });
  }, {
    setActiveElement: thunks.setActiveElement,
  },
)
export default class Toolbar extends Component {
  static propTypes = {
    haveWizardFocus: PropTypes.bool.isRequired,
    popupVisibility: PropTypes.oneOf(Object.values(popupStatuses)).isRequired,
    popupSize: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.object,
    ]).isRequired,
    activeElementId: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]).isRequired,
    isTextToolBasedElement: PropTypes.bool.isRequired,
    ghostElement: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
    isFConstructorPreviewShown: PropTypes.bool.isRequired,
    isFConstructorOrderShown: PropTypes.bool.isRequired,
    isFConstructorLoadedAndShown: PropTypes.bool.isRequired,
    isPAConstructorShown: PropTypes.bool.isRequired,
    isSearchModeActive: PropTypes.bool.isRequired,
    isVersionsShown: PropTypes.bool.isRequired,
    isChoiseByClickingModeIsActive: PropTypes.bool.isRequired,

    setActiveElement: PropTypes.func.isRequired,
  };

  static defaultProps = {
    ghostElement: null,
  };

  isVisible = () => {
    if (thisDevice.isDesktop) {
      return true;
    }

    const {
      popupVisibility,
      popupSize,
      haveWizardFocus,
      isTextToolBasedElement,
      activeElementId,
    } = this.props;
    const isPopupAtBottom =
      popupVisibility !== popupStatuses.hidden && popupSize !== zeroSpace;

    return !((
      isTextToolBasedElement ||
      isTabletWithActiveElement(activeElementId) ||
      haveWizardFocus ||
      isPopupAtBottom
    ));
  };

  onClickFindAnotherForm = () => {
    if (this.props.activeElementId) {
      this.props.setActiveElement(this.props.activeElementId, false);
    }
  };

  render() {
    const {
      activeElementId,
      ghostElement,
      isFConstructorPreviewShown,
      isFConstructorOrderShown,
      isFConstructorLoadedAndShown,
      isPAConstructorShown,
      isVersionsShown,
      isChoiseByClickingModeIsActive,
    } = this.props;

    const configurableElementId =
      activeElementId ||
      (!!ghostElement && isPreConfigurableType(ghostElement.type) && ghostElement.id) ||
      false;

    return (
      <ToolbarView
        isChoiseByClickingModeIsActive={isChoiseByClickingModeIsActive}
        onClickFindAnotherForm={this.onClickFindAnotherForm}
        visible={this.isVisible()}
        activeElementId={configurableElementId}
        isFConstructorPreviewShown={isFConstructorPreviewShown}
        isFConstructorOrderShown={isFConstructorOrderShown}
        isFConstructorLoadedAndShown={isFConstructorLoadedAndShown}
        isPAConstructorShown={isPAConstructorShown}
        isVersionsShown={isVersionsShown}
        isSearchModeActive={this.props.isSearchModeActive}
      />
    );
  }
}
