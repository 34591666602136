import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import {
  trackPoint,
} from 'ws-editor-lib/actions';

import {
  initializeNavigation,
} from '../../../store/modules/navigation';

@connect(
  (state) => {
    return {
      sourceFilename:
        (
          state.ws.source &&
          state.ws.source.pdf &&
          state.ws.source.pdf.url
        ) || false,
      pdfPages: state.pdf.pdfPages,
      pdfDocumentError: state.pdf.pdfDocumentError,
    };
  }, {
    initializeNavigation,
    trackPoint,
  },
)
export default class PdfDocumentController extends Component {
  static propTypes = {

    pdfDocumentError: PropTypes.shape({}),
    // pdfPages: PropTypes.oneOfType([
    //   PropTypes.array, PropTypes.bool,
    // ]),

    // actions
    // initializeNavigation: PropTypes.func.isRequired,
    trackPoint: PropTypes.func.isRequired,
  };

  static defaultProps = {
    // pdfPages: false,
    pdfDocumentError: null,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.listenStatus(nextProps);
    // this.initializeNavigation(nextProps);
  }

  shouldComponentUpdate() {
    return false;
  }

  listenStatus({ sourceFilename, pdfDocumentError }) {
    const occurredPdfDocumentError =
      pdfDocumentError && pdfDocumentError !== this.props.pdfDocumentError;

    // Track error
    if (occurredPdfDocumentError) {
      this.props.trackPoint('DOCUMENT_FAILED', { url: sourceFilename });
    }
  }

  /**
   * NOTE:
   * Раньше здесь была цепочка
   * getDocumentSuccess устанавливал pdfPages
   * этот компонент ловил изменение pdfPages
   * и бросал другой action initializeNavigation
   *
   * Все какое-то время шло хорошо, но появился баг, что между
   * getDocumentSuccess и initializeNavigation успевают
   * проскочить какие-то экшны
   *
   * Причина не была выяснена, но если здесь поставить setTimeout то
   * мы поймаем такое-же падение которое я расследовал.
   *
   * А значит getDocumentSuccess и initializeNavigation должны просходить строго
   * вместе в одном цикле рендера
   *
   * Падение выглядит так
   *
   * getDocumentSuccess -> render дерева целиком -> fatal
   *
   * getDocumentSuccess -> render до PdfDocumentController ->
   *   initializeNavigation -> success
   *
   * Но все это выглядит как костыль, и намного проще переделать этот экшн в
   * один, это и было сделано, поэтому код ниже закомменчен.
   *
   * Не удален он из-за того, что это хотфикс, этот файл выглядит иначе
   * в develop
   */
  // initializeNavigation({ pdfPages }) {
  //   if (pdfPages && !this.props.pdfPages) {
  //     this.props.initializeNavigation(pdfPages.length);
  //   }
  // }

  render = () => {
    return null;
  };
}
