import PropTypes from 'prop-types';
import { Component } from 'react';

import { magneticLineDirections, rightReserveForScroll } from '../../../helpers/const';

export default class MagneticLinesLimiter extends Component {
  static propTypes = {
    magneticLines: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        shape: PropTypes.shape({
          height: PropTypes.number,
          width: PropTypes.number,
          top: PropTypes.number,
          left: PropTypes.number,
        }),
      }),
    ).isRequired,
    workspace: PropTypes.shape({
      top: PropTypes.number.isRequired,
      right: PropTypes.number.isRequired,
    }).isRequired,

    children: PropTypes.func.isRequired,
  };

  getLimitedMagneticLines = () => {
    const { workspace, magneticLines } = this.props;

    return magneticLines.map((el) => {
      if (
        el.key === magneticLineDirections.vertical &&
        el.shape.top &&
        el.shape.top <= workspace.top
      ) {
        return {
          ...el,
          shape: {
            ...el.shape,
            height: (el.shape.height - workspace.top) + el.shape.top,
            top: workspace.top,
          },
        };
      }

      if (
        el.key === magneticLineDirections.horizontal &&
        (el.shape.left + el.shape.width) - workspace.right > 0
      ) {
        const rightPadding =
            (el.shape.left + el.shape.width + rightReserveForScroll) - workspace.right;
        return {
          ...el,
          shape: {
            ...el.shape,
            // where rightReserveForScroll - PS scroll section width in px
            width: el.shape.width - rightPadding,
          },
        };
      }

      return el;
    });
  };

  render() {
    return (
      this.props.children({
        limitedMagneticLines: this.getLimitedMagneticLines(),
      })
    );
  }
}
