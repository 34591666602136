import PropTypes from 'prop-types';
import { Component } from 'react';
import { getScrollToElement } from '../../decorators/element/elementDecorator';
import { getScale } from '../../store/selectors';

export default class ElementScrollToProvider extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    element: PropTypes.shape({
      pageId: PropTypes.number.isRequired,
    }).isRequired,
  };

  static contextTypes = {
    changePageScroll: PropTypes.func,
    getPageViewport: PropTypes.func.isRequired,
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
    }).isRequired,
  };

  getScale = () => {
    const { pageId } = this.props.element;
    return getScale(this.context.store.getState(), pageId);
  };

  scrollToElement = () => {
    if (!this.context.changePageScroll) {
      return;
    }

    const scale = this.getScale();
    const { element } = this.props;
    const {
      frameOffset,
      frameSize,
      workspace,
    } = this.context.getPageViewport();

    const scrollTopToElement = getScrollToElement({
      scale,
      element,
      frameOffset,
      frameSize,
      workspace,
    });

    if (scrollTopToElement) {
      this.context.changePageScroll(scrollTopToElement);
    }
  };

  render() {
    return this.props.children({
      scrollToElement: this.scrollToElement,
    });
  }
}
