import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as JsfUi from 'jsfcore/ui';

const Toggler = ({ timeAmPmTheme, isPM, onChange }) => {
  const onAMClick = useCallback(() => {
    onChange(false);
  }, [onChange]);

  const onPMClick = useCallback(() => {
    onChange(true);
  }, [onChange]);

  return (
    <JsfUi.Toggler
      theme={JsfUi.toggler.themes.primary}
      size={JsfUi.toggler.sizes.parentWidth}
      offset={JsfUi.toggler.offsets.extraSmall}
    >
      <JsfUi.Group.Index>
        <JsfUi.Group.Body>
          <JsfUi.Group.Item data-flex-grow="1" data-flex-basis="0" data-text-overflow="true">
            <JsfUi.Control
              roundedCorners
              size={JsfUi.control.sizes.parent}
              offset={JsfUi.control.offsets.medium}
              theme={timeAmPmTheme}
              isPressed={!isPM}
              onClick={isPM
                ? onAMClick
                : null}
            >
              <JsfUi.Text
                size={12}
                weight={JsfUi.text.weights.bold}
                family={JsfUi.text.families.openSans}
              >
                AM
              </JsfUi.Text>
            </JsfUi.Control>
          </JsfUi.Group.Item>
          <JsfUi.Group.Item data-flex-grow="1" data-flex-basis="0" data-text-overflow="true">
            <JsfUi.Control
              roundedCorners
              size={JsfUi.control.sizes.parent}
              offset={JsfUi.control.offsets.medium}
              theme={timeAmPmTheme}
              isPressed={isPM}
              onClick={!isPM
                ? onPMClick
                : null}
            >
              <JsfUi.Text
                size={12}
                weight={JsfUi.text.weights.bold}
                family={JsfUi.text.families.openSans}
              >
                PM
              </JsfUi.Text>
            </JsfUi.Control>
          </JsfUi.Group.Item>
        </JsfUi.Group.Body>
      </JsfUi.Group.Index>
    </JsfUi.Toggler>
  );
};

Toggler.propTypes = {
  timeAmPmTheme: PropTypes.oneOf(Object.values(JsfUi.control.themes)),
  isPM: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

Toggler.defaultProps = {
  timeAmPmTheme: JsfUi.control.themes.toggleSecondary,
};

export default Toggler;
