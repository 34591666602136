import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isSignNow from 'ws-editor-lib/isSignNow';
import * as conditionsSelectors from '../selectors';

import * as styles from '../helpers/constants';

const style = isSignNow()
  ? styles.snf
  : styles.jsf;

@connect(
  (state) => {
    return {
      choiseByClickingModeIsActive: conditionsSelectors.getChoiseByClickingModeIsActive(state),
    };
  },
)
export default class ChoiseByClickingOpacityCanvas extends Component {
  static propTypes = {
    choiseByClickingModeIsActive: PropTypes.bool.isRequired,
  };

  render() {
    if (this.props.choiseByClickingModeIsActive) {
      return (
        <div style={style} />
      );
    }

    return null;
  }
}
