import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import findKey from 'lodash/findKey';
import isObject from 'lodash/isObject';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import { isSignNow } from 'ws-editor-lib';
import updateFillableElementThunk from 'jsfcore/store/thunks/updateFillableElement';

import {
  removeDependencyByPath,
  getDependenciesByPath,
  makeLevelInPath,
  addDepInPath,
} from '../helpers/conditionsUtils';
import { Ui, consts } from '..';
import isCircularDependency from '../store/actions/isCircularDependency';
import * as choiseByClicking from '../store/actions/choiseByClicking';
import * as conditionsSelectors from '../selectors';
import { getDependency, dependenciesMap } from '../di';

const fillablePlaceholderStyle = { width: '100%', height: 16 };

// Above other portal elements
const paletteStyle = isSignNow()
  ? null
  : { zIndex: 100 };
const selectStyle = isSignNow()
  ? null
  : { zIndex: 101 };

const CONDITIONAL_FULFILLED_DESCRIPTIONS_LOCALE = 'CONDITIONAL_FIELDS.FULFILLED_DESCRIPTIONS';

const simpleOptions = {
  text: [
    { label: `${CONDITIONAL_FULFILLED_DESCRIPTIONS_LOCALE}.field-text`, value: false },
    { label: `${CONDITIONAL_FULFILLED_DESCRIPTIONS_LOCALE}.field-text`, value: true },
  ],
  date: [
    { label: `${CONDITIONAL_FULFILLED_DESCRIPTIONS_LOCALE}.field-datetime`, value: false },
    { label: `${CONDITIONAL_FULFILLED_DESCRIPTIONS_LOCALE}.field-datetime`, value: true },
  ],
  initials: [
    { label: `${CONDITIONAL_FULFILLED_DESCRIPTIONS_LOCALE}.field-initials`, value: false },
    { label: `${CONDITIONAL_FULFILLED_DESCRIPTIONS_LOCALE}.field-initials`, value: true },
  ],
  checkmark: [
    { label: `${CONDITIONAL_FULFILLED_DESCRIPTIONS_LOCALE}.field-checkbox`, value: false },
    { label: `${CONDITIONAL_FULFILLED_DESCRIPTIONS_LOCALE}.field-checkbox`, value: true },
  ],
  signature: [
    { label: `${CONDITIONAL_FULFILLED_DESCRIPTIONS_LOCALE}.field-signature`, value: false },
    { label: `${CONDITIONAL_FULFILLED_DESCRIPTIONS_LOCALE}.field-signature`, value: true },
  ],
  attachment: [
    { label: `${CONDITIONAL_FULFILLED_DESCRIPTIONS_LOCALE}.field-attachment`, value: false },
    { label: `${CONDITIONAL_FULFILLED_DESCRIPTIONS_LOCALE}.field-attachment`, value: true },
  ],
};

const operatorToValueMap = {
  or: {
    operator: 'or',
    negated: false,
  },
  and: {
    operator: 'and',
    negated: false,
  },
  'not and': {
    operator: 'and',
    negated: true,
  },
  'not or': {
    operator: 'or',
    negated: true,
  },
};

const makeConditionalLocale = 'CONDITIONAL_FIELDS.DEPENDENCY_TOGGLE_LABEL';
const choiseByClickingValue = 'choiseByClicking#pwergirgiewrg';

@connect(
  (state) => {
    const jsfcoreSelectors = getDependency(dependenciesMap.jsfcoreSelectors);
    const {
      id,
      conditional,
      dependency,
      roleId,
      type,
      subType,
    } = conditionsSelectors.getActiveElementConditions(state);
    return {
      id,
      conditional,
      dependency,
      roleId,
      type,
      subType,
      elements: jsfcoreSelectors.elements.getFillableEnabledElements(state),
      isConditionalFieldsShown: jsfcoreSelectors.base.getIsConditionalFieldsShown(state),
      selectFields: conditionsSelectors.getSelectFields(state),
    };
  }, {
    updateFillableElementThunk,
    isCircularDependency,
    enableChoiseByClicking: choiseByClicking.enable,
    openModal: getDependency(dependenciesMap.jsfcoreModalsActions).openModal,
  },
)
class Conditions extends Component {
  static propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    subType: PropTypes.string,
    conditional: PropTypes.bool,
    dependency: PropTypes.shape({
      name: PropTypes.string,
      clauses: PropTypes.shape({}),
    }),
    renderWithoutPalette: PropTypes.bool,
    roleId: PropTypes.string,
    isConditionalFieldsShown: PropTypes.bool.isRequired,
    elements: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        template: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    ).isRequired,
    selectFields: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string,
      }).isRequired,
    ).isRequired,
    updateFillableElementThunk: PropTypes.func.isRequired,
    isCircularDependency: PropTypes.func.isRequired,
    enableChoiseByClicking: PropTypes.func.isRequired,
    storeRef: PropTypes.func,
    className: PropTypes.string,

    top: PropTypes.number,
    left: PropTypes.number,
    onDragStart: PropTypes.func,
    onDragMove: PropTypes.func,
    onDragStop: PropTypes.func,
    openModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    id: null,
    conditional: null,
    type: null,
    subType: null,
    className: '',
    renderWithoutPalette: false,
    storeRef: consts.nullFunc,
    top: null,
    left: null,
    roleId: null,
    onDragStart: consts.nullFunc,
    onDragMove: consts.nullFunc,
    onDragStop: consts.nullFunc,
    dependency: {},
  };

  static contextTypes = {
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
    }).isRequired,
  };

  getElementsByName = (name) => {
    if (!name) {
      return null;
    }
    return this.props.elements.filter((el) => {
      return el.template.name === name;
    });
  };

  getSimpleOptionsByElement = (element) => {
    if (!element) {
      return simpleOptions.text;
    }

    const elemSubTypes = getDependency(dependenciesMap.elemSubTypes);
    const elemFCTypes = getDependency(dependenciesMap.elemFCTypes);
    const { type, subType } = element;

    switch (type) {
      case elemFCTypes.text:
        switch (subType) {
          case elemSubTypes.text.date:
            return simpleOptions.date;

          default:
            return simpleOptions.text;
        }

      case elemFCTypes.signature:
        switch (subType) {
          case elemSubTypes.signature.initials:
            return simpleOptions.initials;

          default:
            return simpleOptions.signature;
        }

      case elemFCTypes.checkmark:
        return simpleOptions.checkmark;

      case elemFCTypes.attachment:
        return simpleOptions.attachment;

      default:
        return isSignNow()
          ? null
          : simpleOptions.text;
    }
  };

  getPlaceholderText = () => {
    const { type, subType } = this.props;
    const elemSubTypes = getDependency(dependenciesMap.elemSubTypes);
    const elemFCTypes = getDependency(dependenciesMap.elemFCTypes);

    switch (type) {
      case elemFCTypes.text:
        switch (subType) {
          case elemSubTypes.text.date:
            // TODO: Warning! hardcoded text, get it from locale
            return 'Date field';

          case elemSubTypes.text.dropdown:
            // TODO: Warning! hardcoded text, get it from locale
            return 'Dropdown';

          case elemSubTypes.text.number:
            // TODO: Warning! hardcoded text, get it from locale
            return 'Number field';

          case elemSubTypes.text.formula:
            // TODO: Warning! hardcoded text, get it from locale
            return 'Formula field';

          default:
            // TODO: Warning! hardcoded text, get it from locale
            return 'Text field';
        }

      case elemFCTypes.signature:
        switch (subType) {
          case elemSubTypes.signature.initials:
            // TODO: Warning! hardcoded text, get it from locale
            return 'Initials';

          default:
            // TODO: Warning! hardcoded text, get it from locale
            return 'Signature field';
        }

      case elemFCTypes.checkmark:
        // TODO: Warning! hardcoded text, get it from locale
        return 'Checkbox field';

      case elemFCTypes.image:
        // TODO: Warning! hardcoded text, get it from locale
        return 'Image';

      case elemFCTypes.radio:
        // TODO: Warning! hardcoded text, get it from locale
        return 'Radio';

      case elemFCTypes.attachment:
        // TODO: Warning! hardcoded text, get it from locale
        return 'Attachment field';

      default:
        return null;
    }
  };

  getOptionsFromComplexValues = (values) => {
    return values.map((val) => {
      return {
        label: val,
        value: val,
      };
    });
  };

  updateDependencies = (dependency) => {
    if (isSignNow()) {
      const snRoleSelectors = getDependency(dependenciesMap.snRoleSelectors);
      const snfillerModalsTypes = getDependency(dependenciesMap.snfillerModalsTypes);
      const snfillerModalsMap = getDependency(dependenciesMap.snfillerModalsMap);

      const { elements, roleId, dependency: oldDependency } = this.props;
      const depElementRoleId = get(
        elements.find((elem) => {
          return elem.template.name === dependency.name || (
            dependency.clauses &&
            conditionsSelectors.checkDependencyClauses(dependency.clauses, elem.template.name)
          );
        }),
        'template.roleId',
        null,
      );
      // if we're try to set value like prev value
      if (isEqual(dependency, oldDependency)) {
        return;
      }
      const hasCrossRoleConditional = (
        depElementRoleId && roleId && depElementRoleId !== roleId
      );
      if (hasCrossRoleConditional) {
        const state = this.context.store.getState();
        // if one time CrossRole modal shown prevent shown it's again
        if (!snRoleSelectors.hasCrossRoles(state)) {
          this.props.openModal(
            snfillerModalsMap[snfillerModalsTypes.crossRoleConditionalFields],
            dependency,
          );
          return;
        }
      }
    }

    const cancellableOpts = getDependency(dependenciesMap.cancellableOpts);
    const blurNativeActiveElement = getDependency(dependenciesMap.blurNativeActiveElement);
    this.props.updateFillableElementThunk({
      id: this.props.id,
      template: { dependency },
    }, cancellableOpts);

    // need to remove browser focus from choosed conditional element
    // otherwise we'll have two visually focused elements
    blurNativeActiveElement();
  };

  valueRenderer = (op) => {
    return (
      <div className="Select-placeholder">
        <Ui.Text size={null}>
          {op.label}
        </Ui.Text>
      </div>
    );
  };

  onChangeMakeConditional = (conditional) => {
    const cancellableOpts = getDependency(dependenciesMap.cancellableOpts);
    this.props.updateFillableElementThunk({
      id: this.props.id,
      template: { conditional },
    }, cancellableOpts);
  };

  onChangeField = (path) => {
    return async (val) => {
      let value;
      let radioValue;
      if (choiseByClickingValue === val.value) {
        const obj = await this.props.enableChoiseByClicking();
        value = obj.name;
        radioValue = obj.value;
      } else {
        // eslint-disable-next-line
        value = val.value;
      }

      if (
        value &&
        !this.props.isCircularDependency({
          id: this.props.id,
          name: value,
        })
      ) {
        const clonnedDependencies = cloneDeep(this.props.dependency);
        const dependenciesByPath = getDependenciesByPath(clonnedDependencies, path);
        dependenciesByPath.name = value;
        dependenciesByPath.value = radioValue || true;
        this.updateDependencies(clonnedDependencies);
      }
    };
  };

  onChangeFieldNegated = (path) => {
    return (val) => {
      const { value } = val;
      const clonnedDependencies = cloneDeep(this.props.dependency);
      const dependenciesByPath = getDependenciesByPath(clonnedDependencies, path);
      dependenciesByPath.negated = value;
      this.updateDependencies(clonnedDependencies);
    };
  };

  onChangeFieldValue = (path) => {
    return (val) => {
      const { value, name } = val;
      const clonnedDependencies = cloneDeep(this.props.dependency);
      const dependenciesByPath = getDependenciesByPath(clonnedDependencies, path);
      dependenciesByPath.value = value;
      // name should be equal to "" (empty string) by default
      name
        ? this.updateDependencies({ ...clonnedDependencies, name })
        : this.updateDependencies(clonnedDependencies);
    };
  }

  onChangeOperator = (path) => {
    return (val) => {
      const value = operatorToValueMap[val.value];
      const clonnedDependencies = cloneDeep(this.props.dependency);
      const dependenciesByPath = getDependenciesByPath(clonnedDependencies, path);
      dependenciesByPath.operator = value.operator;
      dependenciesByPath.negated = value.negated;
      this.updateDependencies(clonnedDependencies);
    };
  };

  onClickRemove = (path) => {
    return () => {
      const clonnedDependencies = cloneDeep(this.props.dependency);
      this.updateDependencies(
        removeDependencyByPath(clonnedDependencies, path),
      );
    };
  };

  onClickAdd = (path) => {
    return () => {
      const clonnedDependencies = cloneDeep(this.props.dependency);
      this.updateDependencies(
        addDepInPath(clonnedDependencies, path),
      );
    };
  };

  onClickMakeLevel = (path) => {
    return () => {
      const clonnedDependencies = cloneDeep(this.props.dependency);
      this.updateDependencies(
        makeLevelInPath(clonnedDependencies, path),
      );
    };
  };

  renderComplexValues = (values, dependency, path) => {
    const FormattedMessage = getDependency(dependenciesMap.FormattedMessage);
    const Select = getDependency(dependenciesMap.Select);
    const SelectElement = isSignNow()
      ? Ui.Select
      : Select;

    return (
      <FormattedMessage
        id={[
          'CONDITIONAL_FIELDS.ANY_VALUE',
          'CONDITIONAL_FIELDS.IS',
          'CONDITIONAL_FIELDS.IS_NOT',
        ]}
      >
        {(anyValueLocale, ...complexLocales) => {
          const formattedComplexOptions = [{
            label: complexLocales[0],
            value: false,
          }, {
            label: complexLocales[1],
            value: true,
          }];

          return (
            <Ui.Group
              size={Ui.groupSizes.parentWidth}
              offset={null}
            >
              <Ui.GroupBody>
                <Ui.GroupItem data-flex-grow="1">
                  <SelectElement
                    className="Select Select--single Select--theme--white-bordered Select--size--extra-small"
                    value={dependency.negated}
                    onChange={this.onChangeFieldNegated(path)}
                    options={formattedComplexOptions}
                    valueRend={this.valueRenderer}
                    clearable={false}
                    backspaceRemoves={false}
                    wrapperProps={selectStyle}
                  />
                </Ui.GroupItem>
                <Ui.GroupItem data-flex-grow="2">
                  <SelectElement
                    className="Select Select--single Select--theme--white-bordered Select--size--extra-small"
                    value={dependency.value}
                    onChange={this.onChangeFieldValue(path)}
                    options={[
                      {
                        label: anyValueLocale,
                        value: true,
                      },
                      ...this.getOptionsFromComplexValues(values),
                    ]}
                    valueRend={this.valueRenderer}
                    clearable={false}
                    backspaceRemoves={false}
                    wrapperProps={selectStyle}
                  />
                </Ui.GroupItem>
              </Ui.GroupBody>
            </Ui.Group>
          );
        }}
      </FormattedMessage>
    );
  };

  renderRadioValues = (elements, dependency, path) => {
    const values = uniq(elements.map((el) => {
      return el.template.radioValue;
    }));

    return this.renderComplexValues(values, dependency, path);
  };

  renderDropdownValues = (element, dependency, path) => {
    // List may be ['1', '2', '3'] or
    // [{ data: '1' }, { data: '2' }, { data: '1' }]
    const values = element.template.list.map((el) => {
      if (isObject(el)) {
        return el.data;
      }

      return el;
    });

    return this.renderComplexValues(values, dependency, path);
  };

  renderSimpleValues = (element, dependency, path) => {
    const FormattedMessage = getDependency(dependenciesMap.FormattedMessage);
    const Select = getDependency(dependenciesMap.Select);
    const SelectElement = isSignNow()
      ? Ui.Select
      : Select;
    const values = this.getSimpleOptionsByElement(element);
    const valuesLabelsForTranslate = values.map(({ label }) => {
      return label;
    });

    return (
      <FormattedMessage
        id={valuesLabelsForTranslate}
        interpolationParam={[
          { name: 'false' },
          { name: 'other' },
        ]}
      >
        {(...translatedLabels) => {
          const translatedValues = translatedLabels.map((translatedLabel, i) => {
            return {
              label: translatedLabel,
              value: values[i].value,
            };
          });

          return (
            <Ui.Group
              size={Ui.groupSizes.parentWidth}
              offset={null}
            >
              <Ui.GroupBody>
                <Ui.GroupItem data-flex-grow="1">
                  <SelectElement
                    className="Select Select--single Select--theme--white-bordered Select--size--extra-small"
                    value={dependency.negated}
                    onChange={this.onChangeFieldNegated(path)}
                    options={translatedValues}
                    valueRend={this.valueRenderer}
                    clearable={false}
                    backspaceRemoves={false}
                    wrapperProps={selectStyle}
                  />
                </Ui.GroupItem>
              </Ui.GroupBody>
            </Ui.Group>
          );
        }}
      </FormattedMessage>
    );
  }

  renderValues = (dependency, path) => {
    const elements = this.getElementsByName(dependency.name);
    const isDropdownElement = getDependency(dependenciesMap.isDropdownElement);
    const isRadioElement = getDependency(dependenciesMap.isRadioElement);
    const isRadioElements = elements && elements.every(isRadioElement);

    if (!isRadioElements) {
      const element = get(elements, '[0]', null);

      if (element && isDropdownElement(element)) {
        return this.renderDropdownValues(element, dependency, path);
      }

      return this.renderSimpleValues(element, dependency, path);
    }

    return this.renderRadioValues(elements, dependency, path);
  };

  renderCondition = (dependency, path, operatorTitle, className) => {
    const FormattedMessage = getDependency(dependenciesMap.FormattedMessage);
    const Select = getDependency(dependenciesMap.Select);
    const SelectElement = isSignNow()
      ? Ui.Select
      : Select;

    if (dependency.operator) {
      return this.renderForm(dependency, path, operatorTitle, className);
    }

    const canRemove = path !== '0';
    const splittedPath = path.split('.');
    const canMakeLevel = splittedPath.length === 2;
    const isSubDependencies = splittedPath.length === 3;

    return (
      <Ui.DependencyClause
        key={path}
        inside={isSubDependencies}
        operator={
          operatorTitle
            ? (
              <Ui.Text size={Ui.textSizes[10]} center bold uppercase block>
                {operatorTitle}
              </Ui.Text>
            )
            : null
        }
        controls={
          <Ui.List.Wrapper
            offset={Ui.List.listOffsets.extraSmall}
            type={null}
            behavior={null}
            justify={null}
            theme={null}
          >
            {canRemove
              ? (
                <Ui.List.Item>
                  <Ui.Group
                    size={Ui.groupSizes.parentWidth}
                    offset={null}
                  >
                    <Ui.GroupBody>
                      <Ui.GroupItem>
                        <Ui.Control
                          size={Ui.controlSizes.extraSmallRectangular}
                          theme={Ui.controlThemes.simpleSolid}
                          behavior={Ui.controlBehaviors.iconOnly}
                          offset={Ui.controlOffsets.extraSmall}
                          justify={Ui.controlJustifyes.null}
                          onClick={this.onClickRemove(path)}
                          icon={
                            <Ui.Icon
                              type={Ui.iconTypes.trashBin}
                              size={Ui.iconSizes.parent}
                            />
                          }
                        />
                      </Ui.GroupItem>
                    </Ui.GroupBody>
                  </Ui.Group>
                </Ui.List.Item>
              )
              : null
            }
            {canMakeLevel
              ? (
                <Ui.Group
                  size={Ui.groupSizes.parentWidth}
                  offset={null}
                >
                  <Ui.GroupBody>
                    <Ui.GroupItem>
                      <Ui.List.Item>
                        <Ui.Control
                          size={Ui.controlSizes.extraSmallRectangular}
                          theme={Ui.controlThemes.simpleSolid}
                          behavior={Ui.controlBehaviors.null}
                          offset={Ui.controlOffsets.null}
                          justify={Ui.controlJustifyes.null}
                          onClick={this.onClickMakeLevel(path)}
                          icon={
                            <Ui.Icon
                              type={Ui.iconTypes.arrowDownright}
                              size={Ui.iconSizes.small}
                            />
                          }
                        />
                      </Ui.List.Item>
                    </Ui.GroupItem>
                  </Ui.GroupBody>
                </Ui.Group>
              )
              : null
            }
          </Ui.List.Wrapper>
        }
      >
        <Ui.List.Wrapper
          offset={Ui.List.listOffsets.extraSmall}
          type={null}
          behavior={null}
          justify={null}
          theme={null}
        >
          <Ui.List.Item>
            <Ui.Group
              size={Ui.groupSizes.parentWidth}
              offset={null}
            >
              <Ui.GroupBody>
                <Ui.GroupItem data-flex-grow="1">
                  <FormattedMessage id="CONDITIONAL_FIELDS.CHOOSE_BY_CLICKING">
                    {(formattedMessage) => {
                      // TODO: show choiseByClicking in JSF
                      const formattedOptions = [
                        ...this.props.selectFields,
                        {
                          value: choiseByClickingValue,
                          label: formattedMessage,
                        },
                      ];

                      return (
                        <SelectElement
                          className="Select Select--single Select--theme--white-bordered Select--size--extra-small"
                          roleClass={className}
                          value={dependency.name}
                          onChange={this.onChangeField(path)}
                          options={formattedOptions}
                          valueRend={this.valueRenderer}
                          clearable={false}
                          backspaceRemoves={false}
                          wrapperProps={selectStyle}
                        />
                      );
                    }}
                  </FormattedMessage>
                </Ui.GroupItem>
              </Ui.GroupBody>
            </Ui.Group>
          </Ui.List.Item>
          <Ui.List.Item>
            {this.renderValues(dependency, path)}
          </Ui.List.Item>
        </Ui.List.Wrapper>
      </Ui.DependencyClause>
    );
  };

  renderOperatorSelect = (path, dependency) => {
    const selectedValue = findKey(operatorToValueMap, {
      operator: dependency.operator,
      negated: dependency.negated,
    }) || 'or';

    const FormattedMessage = getDependency(dependenciesMap.FormattedMessage);
    const Select = getDependency(dependenciesMap.Select);
    const SelectElement = isSignNow()
      ? Ui.Select
      : Select;

    return (
      <FormattedMessage
        id={[
          'CONDITIONAL_FIELDS.COMBINED_OPERATORS.OR.DESCRIPTION',
          'CONDITIONAL_FIELDS.COMBINED_OPERATORS.AND.DESCRIPTION',
          'CONDITIONAL_FIELDS.COMBINED_OPERATORS.NOT_OR.DESCRIPTION',
          'CONDITIONAL_FIELDS.COMBINED_OPERATORS.NOT_AND.DESCRIPTION',
        ]}
      >
        {(...formattedLocales) => {
          const formattedOperators = [
            {
              label: formattedLocales[0],
              value: 'or',
            },
            {
              label: formattedLocales[1],
              value: 'and',
            },
            {
              label: formattedLocales[2],
              value: 'not and',
            },
            {
              label: formattedLocales[3],
              value: 'not or',
            },
          ];

          return (
            <Ui.Group
              offset={Ui.groupOffsets.extraSmall}
              type={Ui.groupTypes.compact}
            >
              <Ui.GroupBody>
                <Ui.GroupItem data-flex-grow="1">
                  <SelectElement
                    className="Select Select--single Select--theme--white-bordered Select--size--small"
                    onChange={this.onChangeOperator(path)}
                    disabled={!dependency.operator}
                    value={selectedValue}
                    options={formattedOperators}
                    valueRend={this.valueRenderer}
                    clearable={false}
                    backspaceRemoves={false}
                    wrapperProps={selectStyle}
                  />
                </Ui.GroupItem>
              </Ui.GroupBody>
            </Ui.Group>
          );
        }}
      </FormattedMessage>
    );
  };

  renderForm = (dependency, path, operatorTitleTop, className) => {
    const operatorTitle = findKey(operatorToValueMap, {
      operator: dependency.operator,
      negated: dependency.negated,
    });
    const inner = (
      <Ui.GroupClause
        key={path}
        isHighlighted={path !== '0'}
        operator={
          this.renderOperatorSelect(path, dependency)
        }
        bar={
          <Ui.Control
            size={Ui.controlSizes.extraSmallRectangular}
            theme={Ui.controlThemes.simpleSolid}
            behavior={Ui.controlBehaviors.null}
            offset={Ui.controlOffsets.null}
            justify={Ui.controlJustifyes.null}
            onClick={this.onClickAdd(path)}
            icon={
              <Ui.Icon
                type={Ui.iconTypes.plus}
                size={Ui.iconSizes.extraSmall}
              />
            }
          />
        }
      >
        {dependency.clauses
          ? dependency.clauses.map((deps, i) => {
            return this.renderCondition(
              deps, `${path}.${i}`, operatorTitle, className,
            );
          })
          : this.renderCondition(dependency, path, null, className)}
      </Ui.GroupClause>
    );

    if (path === '0') {
      return inner;
    }

    return (
      <Ui.DependencyClause
        key={path}
        operator={
          <Ui.Text size={Ui.textSizes[10]} center bold uppercase block>
            {operatorTitleTop}
          </Ui.Text>
        }
        box={inner}
      />
    );
  };

  renderText = ({ conditional, haveElements }) => {
    if (conditional) {
      return haveElements ? (
        <Ui.Text
          id="CONDITIONAL_FIELDS.HIDE_THIS_FIELD_UNTIL"
          size={Ui.textSizes[14]}
          verticalOffset={Ui.textVerticalOffsets.extraSmall}
        />
      ) : (
        <Ui.Text
          id="CONDITIONAL_FIELDS.NOT_ENOUGH_FIELDS_ERROR"
          size={Ui.textSizes[14]}
        />
      );
    }

    return null;
  };

  renderPlaceholder = ({ className }) => {
    return (
      <Ui.FillablePlaceholder
        text={this.getPlaceholderText()}
        className={className}
        size={null}
        type={Ui.fillablePlaceholderTypes.textarea}
        isActiveElement
        style={fillablePlaceholderStyle}
      />
    );
  };

  renderConditionalContent = () => {
    const {
      id,
      conditional,
      dependency,
      isConditionalFieldsShown,
      className,
      selectFields,
    } = this.props;

    if (isSignNow() && !isConditionalFieldsShown) {
      return null;
    }

    const haveElements = selectFields.length === 1
      ? get(selectFields, '0.id') !== id
      : selectFields.length > 1;

    if (id) {
      return (
        <Ui.ConditionConfigurator
          placeholder={
            this.props.renderWithoutPalette
              ? null
              : this.renderPlaceholder({ className })
          }
          checkbox={
            this.props.renderWithoutPalette
              ? null
              : (
                <Ui.Checkbox
                  title={makeConditionalLocale}
                  onChange={this.onChangeMakeConditional}
                  value={conditional}
                />
              )
          }
          info={this.renderText({ conditional, haveElements })}
          offset={isSignNow()
            ? null
            : Ui.conditionConfiguratorOffsets.small
          }
        >
          {
            haveElements && conditional
              ? this.renderForm(dependency, '0', null, className)
              : null
          }
        </Ui.ConditionConfigurator>
      );
    }

    return (
      <Ui.Text
        id="CONDITIONAL_FIELDS.PALETTE_HELP_TEXT"
        block
        hasHTML
      />
    );
  };

  render() {
    if (isSignNow() && !this.props.isConditionalFieldsShown) {
      return null;
    }

    if (this.props.renderWithoutPalette) {
      return this.renderConditionalContent();
    }

    return (
      <Ui.Palette
        storeRef={this.props.storeRef}
        top={this.props.top}
        left={this.props.left}
        style={paletteStyle}
        onDragStart={this.props.onDragStart}
        onDragMove={this.props.onDragMove}
        onDragStop={this.props.onDragStop}
        useFocusControllerDecorator
      >
        {this.renderConditionalContent()}
      </Ui.Palette>
    );
  }
}

export default Conditions;
