import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import FocusControlledSelect from '../../../Select';
import { toolbar } from '../../../../store/selectors';
import * as Ui from '../../../../ui';
import setFontFamily from '../../../../store/thunks/setFontFamily';
import { fontsMap, fontsList } from '../../../../helpers/const';
import forceFocusTextElement from '../../../../store/thunks/forceFocusTextElement';

const fonts = fontsList.map((item) => {
  return fontsMap[item];
});

@connect(
  (state) => {
    const { fontFamily } = toolbar.getElementPropsForToolbar(state);
    return {
      fontFamily,
    };
  },
  {
    forceFocusTextElement,
    setFontFamily,
  },
)
export default class FontFamilyToolbarSettings extends Component {
  static propTypes = {
    fontFamily: PropTypes.string.isRequired,

    // actions
    setFontFamily: PropTypes.func.isRequired,
    forceFocusTextElement: PropTypes.func.isRequired,
  };

  storeSelectRef = (ref) => {
    this.selectRef = ref;
  };

  onChange = ({ value }) => {
    this.props.setFontFamily(value);
  };

  onDropDownClose = () => {
    this.selectRef.input.blur();
    this.props.forceFocusTextElement();
  };

  render() {
    const { fontFamily } = this.props;

    return (
      <Ui.ToolBar.Item type={Ui.toolBar.itemTypes.select}>
        <FocusControlledSelect
          storeRef={this.storeSelectRef}
          onClose={this.onDropDownClose}
          onChange={this.onChange}
          width={157}
          value={fontFamily}
          options={fonts}
          searchable={false}
          clearable={false}

          /* need if we have fontFamily not from this.props.fonts, e.g. from fakeEdit */
          placeholder={fontFamily}

          backspaceRemoves={false}
        />
      </Ui.ToolBar.Item>
    );
  }
}
