import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

export const listTypes = {
  signature: 'signature',
  null: null,
};

export const listOffsets = {
  medium: 'medium',
  extraSmall: 'extra-small',
  null: null,
};

export const listBehaviors = {
  flexboxColumn: 'flexbox-column',
  null: null,
};

export const listJustifyes = {
  center: 'center',
  null: null,
};

export const listThemes = {
  thin: 'thin',
  null: null,
};

export default class List extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    type: PropTypes.oneOf(
      Object.values(listTypes),
    ),
    offset: PropTypes.oneOf(
      Object.values(listOffsets),
    ),
    behavior: PropTypes.oneOf(
      Object.values(listBehaviors),
    ),
    justify: PropTypes.oneOf(
      Object.values(listJustifyes),
    ),
    theme: PropTypes.oneOf(
      Object.values(listThemes),
    ),
  };

  static defaultProps = {
    type: listTypes.null,
    offset: listOffsets.null,
    behavior: listBehaviors.flexboxColumn,
    justify: listJustifyes.center,
    theme: listThemes.null,
  };

  render() {
    const { type, offset, behavior, justify, theme, children } = this.props;

    return (
      <ul
        className={
          cls('list', {
            [`list--type--${type}`]: type,
            [`list--offset--${offset}`]: offset,
            [`list--behavior--${behavior}`]: behavior,
            [`list--justify--${justify}`]: justify,
            [`list--theme--${theme}`]: theme,
          })
        }
      >
        {children}
      </ul>
    );
  }
}
