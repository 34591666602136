import PropTypes from 'prop-types';
import React from 'react';
import StoreRefProvider from 'jsfcore/components/StoreRef/StoreRefProvider';

import SignToolElementProvider from './SignToolElementProvider';
import SignToolWrapper from './SignToolWrapper';
import SignToolRender from './SignToolRender';
import SignStampProvider from './SignStampProvider';
import OriginalSizeProvider from '../../OriginalSizeProvider';
import { isFilledElement } from '../../../store/helpers/functions';

const renderSignTool = ({
  id,
  element,
  isDisabled,
  isFillable,
  isGhost,
  scale,
  pageId,
  elementIcon,
  width,
  height,
  isActiveElement,
  type,
  storeValidatedTextToolSize,

  onFocus,
  onBlur,
  storeRef: storeRefProp,
  interceptFocusDataset,
}) => {
  if (!element || !(element.content || element.template)) {
    return null;
  }

  return (
    <StoreRefProvider proxy={storeRefProp}>
      {({ getRefPromise, storeRef }) => {
        const isUnfilledSignTool = isFillable && !isFilledElement(element);

        return (
          <SignToolWrapper
            isDisabled={isDisabled}
            isUnfilledSignTool={isUnfilledSignTool}

            onFocus={onFocus}
            onBlur={onBlur}
            storeRef={storeRef}
            interceptFocusDataset={interceptFocusDataset}
          >
            <OriginalSizeProvider pageId={pageId}>
              {({ originalSize }) => {
                return (
                  <SignStampProvider
                    id={id}
                    isFillable={isFillable}
                    isGhost={isGhost}
                    element={element}
                    originalSize={originalSize}
                    width={width}
                    height={height}
                    getRefPromise={getRefPromise}
                  >
                    <SignToolElementProvider
                      scale={scale}
                      isGhost={isGhost}
                      isDisabled={isDisabled}
                      element={element}
                      originalSize={originalSize}
                      // children props
                      id={id}
                      isActiveElement={isActiveElement}
                      isFillable={isFillable}
                      pageId={pageId}
                      type={type}
                      storeValidatedTextToolSize={storeValidatedTextToolSize}
                      elementIcon={elementIcon}
                    >
                      {({ childType, childProps }) => {
                        return (
                          <SignToolRender
                            childType={childType}
                            childProps={childProps}
                          />
                        );
                      }}
                    </SignToolElementProvider>
                  </SignStampProvider>
                );
              }}
            </OriginalSizeProvider>
          </SignToolWrapper>
        );
      }}
    </StoreRefProvider>
  );
};

renderSignTool.propTypes = {
  id: PropTypes.string.isRequired,
  isFillable: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  isGhost: PropTypes.bool,
  scale: PropTypes.number.isRequired,
  element: PropTypes.shape({
    content: PropTypes.shape({
      contentY: PropTypes.number,
      contentX: PropTypes.number,
      height: PropTypes.number,
      width: PropTypes.number,
      required: PropTypes.bool,
    }),
    template: PropTypes.shape({
      dateStamp: PropTypes.string,
    }),
  }).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  elementIcon: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.bool,
  ]),
  pageId: PropTypes.number.isRequired,
  isActiveElement: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  storeValidatedTextToolSize: PropTypes.func,

  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  storeRef: PropTypes.func.isRequired,
  interceptFocusDataset: PropTypes.shape({}),
};

renderSignTool.defaultProps = {
  isDisabled: false,
  isGhost: false,
  elementIcon: null,
  storeValidatedTextToolSize: null,

  interceptFocusDataset: undefined,
};

export default renderSignTool;
