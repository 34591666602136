import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { openCoreHelpModal } from 'combine-modals/lib/actions/actionCreator';
import { updateTodoList } from 'ws-editor-lib/actions';
import { TODO_LIST_STATES as todoListStates } from 'ws-editor-lib/constants';

import {
  triggerFindAnotherFormClick,
  triggerMessageButtonClick,
} from '../../../store/modules/events';
import * as selectors from '../../../store/selectors';
import ToolbarHelpView from './ToolbarHelpView';

@connect(
  (state) => {
    return {
      locale: selectors.locale.getEditorLocale(state).toolbarHelpView,
      localeTodo: selectors.locale.getEditorLocale(state).wizardTodo,
      isFindAnotherFormButtonVisible: state.viewport.isFindAnotherFormButtonVisible,
      isFindAnotherFormBubbleVisible: state.viewport.isFindAnotherFormBubbleVisible,
      isMessageButtonVisible: state.viewport.isMessageButtonVisible,
      todoListStatus: selectors.base.getTodoListState(state),
      isWizardActive: selectors.base.getIsWizardActive(state),
    };
  },
  {
    updateTodoList,
    openCoreHelpModal,
    triggerFindAnotherFormClick,
    triggerMessageButtonClick,
  },
)
export default class ToolbarHelp extends Component {
  static propTypes = {
    onClickFindAnotherForm: PropTypes.func.isRequired,
    todoListStatus: PropTypes.string.isRequired,

    locale: PropTypes.objectOf(PropTypes.string).isRequired,
    localeTodo: PropTypes.objectOf(PropTypes.string).isRequired,
    isFindAnotherFormButtonVisible: PropTypes.bool.isRequired,
    isFindAnotherFormBubbleVisible: PropTypes.bool.isRequired,
    isMessageButtonVisible: PropTypes.bool.isRequired,
    isWizardActive: PropTypes.bool.isRequired,

    // actions
    triggerMessageButtonClick: PropTypes.func.isRequired,
    triggerFindAnotherFormClick: PropTypes.func.isRequired,
    updateTodoList: PropTypes.func.isRequired,
  };

  openTodoList = () => {
    const { todoListStatus } = this.props;
    this.props.updateTodoList(
      todoListStatus === todoListStates.maximized
        ? todoListStates.minimized
        : todoListStates.maximized);
  }

  onClickFindAnotherForm = () => {
    this.props.triggerFindAnotherFormClick();
    this.props.onClickFindAnotherForm();
  };

  onClickMessageButton = () => {
    this.props.triggerMessageButtonClick();
  };

  render() {
    return (
      <ToolbarHelpView
        locale={this.props.locale}
        isFindAnotherFormButtonVisible={this.props.isFindAnotherFormButtonVisible}
        isFindAnotherFormBubbleVisible={this.props.isFindAnotherFormBubbleVisible}
        isMessageButtonVisible={this.props.isMessageButtonVisible}
        onClickFindAnotherForm={this.onClickFindAnotherForm}
        onClickMessageButton={this.onClickMessageButton}
        openTodoList={this.openTodoList}
        todoListStatus={this.props.todoListStatus}
        isWizardActive={this.props.isWizardActive}
        localeTodo={this.props.localeTodo}
      />
    );
  }
}
