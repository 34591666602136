import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { popupMenu } from '../../../../../helpers/const';
import { toolbar } from '../../../../../store/selectors';
import setToolbarAttributeValue from '../../../../../store/thunks/setToolbarAttributeValue';
import { setActivePopupMenu } from '../../../../../store/modules/viewport';
import PopupMenuItem from '../../../../Toolbar/ToolbarAttributes/Popup/PopupMenuItem';

@connect(
  (state) => {
    const { fontSize } = toolbar.getElementPropsForToolbar(state);
    return {
      fontSize,
    };
  },
  {
    setToolbarAttributeValue,
    setActivePopupMenu,
  },
)
export default class FontSizePopupMenuSettings extends Component {
  static propTypes = {
    fontSize: PropTypes.oneOfType([
      PropTypes.string, PropTypes.number,
    ]).isRequired,
    locale: PropTypes.string.isRequired,
    setActivePopupMenu: PropTypes.func.isRequired,
  };

  handleShowFontSizeMenu = () => {
    this.props.setActivePopupMenu(popupMenu.fontSize);
  };

  render() {
    return (<PopupMenuItem
      label={this.props.locale}
      showPopupMenuItem={this.handleShowFontSizeMenu}
      value={this.props.fontSize}
    />);
  }
}
