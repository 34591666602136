import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectors } from '../..';
import pdfPagesStore from '../../store/helpers/pdfPagesStore';
import { popupStatuses } from '../../helpers/const';
import PageView from './PageView';

@connect(
  (__, { pageId }) => {
    return (state) => {
      return {
        isWizardActive: selectors.base.getIsWizardActive(state),
        isActivePage: selectors.getIsActivePage(state, pageId),
        pdfPage: pdfPagesStore.getPdfPage(state.pdf.pdfPages[pageId]),
        frameSize: selectors.getFrameSize(state, pageId || 0),
        frameOffset: selectors.getFrameOffset(state, pageId || 0),
        originalSize: state.navigation.originalSizes[pageId || 0],
        popupVisibility: state.viewport.popupVisibility,
      };
    };
  },
)
export default class Page extends Component {
  static propTypes = {
    pageId: PropTypes.number.isRequired,

    // from global.state
    isActivePage: PropTypes.bool.isRequired,
    pdfPage: PropTypes.shape({}),
    frameSize: PropTypes.oneOfType([
      PropTypes.shape({
        height: PropTypes.number.isRequired,
      }),
      PropTypes.bool,
    ]).isRequired,
    frameOffset: PropTypes.shape({
      scrollTop: PropTypes.number.isRequired,
    }).isRequired,
    workspace: PropTypes.shape({
      height: PropTypes.number.isRequired,
    }).isRequired,
    originalSize: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),
    popupVisibility: PropTypes.oneOf(Object.values(popupStatuses)).isRequired,
  };

  static childContextTypes = {
    getPageViewport: PropTypes.func,
  };

  static contextTypes = {
    paginationHelpers: PropTypes.shape({
      setOkIfNeed: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    pdfPage: null,
    originalSize: null,
  }

  getChildContext = () => {
    return {
      getPageViewport: this.getPageViewport,
    };
  };

  getPageViewport = () => {
    return {
      workspace: this.props.workspace,
      frameSize: this.props.frameSize,
      frameOffset: this.props.frameOffset,
      originalSize: this.props.originalSize,
      popupVisibility: this.props.popupVisibility,
    };
  };

  render() {
    const {
      pageId,
      pdfPage,
      isActivePage,
      workspace,
    } = this.props;

    return (
      <PageView
        pageId={pageId}
        workspace={workspace}
        page={pdfPage}
        isActivePage={isActivePage}
      />
    );
  }
}
