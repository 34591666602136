import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { selectors } from '../..';
import { getForceFocusElementObject } from '../../store/helpers/stateToProps';
import { setForceFocusElement } from '../../store/modules/events';

@connect(
  (_, { element }) => {
    return (state) => {
      return {
        forceFocusElementObject: getForceFocusElementObject(state, element),
        workspace: selectors.base.getWorkspace(state),
        isActivePage: selectors.getIsActivePage(state, element.pageId),
      };
    };
  }, {
    setForceFocusElement,
  },
)
export default class ElementScrollToExecutor extends Component {
  static propTypes = {
    scrollToElement: PropTypes.func.isRequired,
    isActiveElement: PropTypes.bool.isRequired,
    // TODO: remove eslint-disable-next-line after remove UNSAFE_componentWillReceiveProps
    // eslint-disable-next-line react/no-unused-prop-types
    isFillable: PropTypes.bool.isRequired,
    element: PropTypes.shape({
      pageId: PropTypes.number.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,

    forceFocusElementObject: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.object,
    ]).isRequired,
    workspace: PropTypes.shape({}).isRequired,
    isActivePage: PropTypes.bool.isRequired,
    setForceFocusElement: PropTypes.func.isRequired,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      forceFocusElementObject,
      isActiveElement,
      workspace,
      isActivePage,
      isFillable,
      element,
    } = nextProps;

    if (
      forceFocusElementObject !== this.props.forceFocusElementObject &&
      forceFocusElementObject
    ) {
      this.props.scrollToElement(nextProps);
    }

    if (
      isActiveElement === true &&
      (
        this.props.isActiveElement === false ||
        workspace !== this.props.workspace
      )
    ) {
      this.props.scrollToElement(nextProps);
    }

    if (isActivePage !== this.props.isActivePage && isActiveElement === true) {
      if (!isActivePage && !isFillable) {
        return;
      }

      this.props.scrollToElement(nextProps);
      this.props.setForceFocusElement(element.id);
    }
  }

  render() {
    return null;
  }
}
