import PropTypes from 'prop-types';
import React, { Component } from 'react';
import get from 'lodash/get';
import { isFilledElement } from '../../../store/helpers/functions';
import { toolIds } from '../../../helpers/elemTypes';
import Icon, { iconTypes, iconSizes } from '../../../ui/Icon';

export default class ElementIcon extends Component {
  static propTypes = {
    element: PropTypes.shape({
      template: PropTypes.shape({
        toolId: PropTypes.string,
      }),
    }).isRequired,
    fConstructorLoadedAndShown: PropTypes.bool,
    scale: PropTypes.number,
  };

  static defaultProps = {
    fConstructorLoadedAndShown: false,
    scale: 1,
  };

  getStyle = () => {
    const { element, fConstructorLoadedAndShown, scale } = this.props;
    const toolId = get(element, 'template.toolId');

    if (!fConstructorLoadedAndShown) {
      return null;
    }

    switch (toolId) {
      case toolIds.image:
      case toolIds.initials:
      case toolIds.signature:
        return {
          width: 16 * scale,
          height: 16 * scale,
        };

      case toolIds.dropdown:
      case toolIds.states:
      case toolIds.gender:
      case toolIds.date:
      case toolIds.number:
      case toolIds.phone:
      case toolIds.usZip:
      case toolIds.usCurrency:
      case toolIds.euCurrency:
      case toolIds.age:
      case toolIds.ssn:
      case toolIds.ein:
      case toolIds.ccn:
      case toolIds.formula:
        return {
          width: 12 * scale,
          height: 12 * scale,
        };

      default:
        return null;
    }
  };

  render() {
    const { element, fConstructorLoadedAndShown } = this.props;
    const toolId = get(element, 'template.toolId');

    // in constructor mode need shown icon in case when element filled
    const isFilledElementInSimpleMode = isFilledElement(element) && !fConstructorLoadedAndShown;
    if (isFilledElementInSimpleMode) {
      return null;
    }

    const style = this.getStyle();

    switch (toolId) {
      case toolIds.dropdown:
      case toolIds.states:
      case toolIds.gender:
        return (
          <Icon
            style={style}
            type={iconTypes.arrowDown}
            size={iconSizes.xsmall}
          />
        );

      case toolIds.date:
        return (
          <Icon
            style={style}
            type={iconTypes.calendar}
            size={iconSizes.xsmall}
          />
        );

      case toolIds.number:
      case toolIds.phone:
      case toolIds.usZip:
      case toolIds.usCurrency:
      case toolIds.euCurrency:
      case toolIds.age:
      case toolIds.ssn:
      case toolIds.ein:
      case toolIds.ccn:
        return (
          <Icon
            style={style}
            type={iconTypes.hash}
            size={iconSizes.xsmall}
          />
        );

      case toolIds.formula:
        return fConstructorLoadedAndShown
          ? (
            <Icon
              style={style}
              type={iconTypes.formula}
              size={iconSizes.xsmall}
            />
          )
          : null;

      case toolIds.initials:
        return (
          <Icon
            style={style}
            type={iconTypes.initials}
            size={iconSizes.small}
          />
        );

      case toolIds.signature:
        return (
          <Icon
            style={style}
            type={iconTypes.sendToSign}
            size={iconSizes.small}
          />
        );

      case toolIds.image:
        return (
          <Icon
            style={style}
            type={iconTypes.imageNew}
            size={iconSizes.small}
          />
        );

      default:
        return null;
    }
  }
}
