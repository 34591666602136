import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { alignValues } from '../../../../../helpers/const';
import { stopEvent } from '../../../../../helpers/utils';
import setTextAlign from '../../../../../store/thunks/setTextAlign';
import { toolbar } from '../../../../../store/selectors';
import * as Ui from '../../../../../ui';

export class AlignPopupSettingsSimple extends Component {
  static propTypes = {
    align: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    setAlign: PropTypes.func.isRequired,
  };

  onClickLeft = (event) => {
    stopEvent(event);
    return this.props.setAlign(alignValues.left);
  };

  onClickCenter = (event) => {
    stopEvent(event);
    return this.props.setAlign(alignValues.center);
  };

  onClickRight = (event) => {
    stopEvent(event);
    return this.props.setAlign(alignValues.right);
  };

  render() {
    const { align } = this.props;
    return (
      <Ui.Menu.ListItem>
        <Ui.Menu.Item>
          <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.body}>

            <Ui.Menu.ItemByType attributes={Ui.attributes.grow}>
              <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.text}>
                <Ui.Text
                  size={16}
                  align={Ui.text.aligns.left}
                >
                  {this.props.locale}
                </Ui.Text>
              </Ui.Menu.ItemByType>
            </Ui.Menu.ItemByType>

            <Ui.Menu.ItemByType attributes={Ui.attributes.shrinkOff}>
              <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.toolbar}>
                <Ui.ToolBar.Body
                  size={Ui.toolBar.sizes.small}
                  theme={Ui.toolBar.themes.bordered}
                  type={Ui.toolBar.types.narrow}
                >
                  <Ui.ToolBar.Main>
                    <Ui.ToolBar.Group>

                      <Ui.Container.SettingButton
                        icon={Ui.icon.types.textAlignLeft}
                        isPressed={align === alignValues.left}
                        onClick={this.onClickLeft}
                        inheritRadius
                      />

                      <Ui.Container.SettingButton
                        icon={Ui.icon.types.textAlignCenter}
                        isPressed={align === alignValues.center}
                        onClick={this.onClickCenter}
                        inheritRadius
                      />

                      <Ui.Container.SettingButton
                        icon={Ui.icon.types.textAlignRight}
                        isPressed={align === alignValues.right}
                        onClick={this.onClickRight}
                        inheritRadius
                      />

                    </Ui.ToolBar.Group>
                  </Ui.ToolBar.Main>
                </Ui.ToolBar.Body>
              </Ui.Menu.ItemByType>
            </Ui.Menu.ItemByType>

          </Ui.Menu.ItemByType>
        </Ui.Menu.Item>
      </Ui.Menu.ListItem>
    );
  }
}

export default connect((state) => {
  const { align } = toolbar.getElementPropsForToolbar(state);
  return { align };
}, {
  setAlign: setTextAlign,
})(AlignPopupSettingsSimple);
