import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setActivePopupMenu } from '../../../../../store/modules/viewport';
import { toolbar } from '../../../../../store/selectors';

import * as Ui from '../../../../../ui';
import { getBoxIcon } from '../../../../../ui/Control';
import { getColorIcon } from '../../../../../helpers/graphicUtils';

export const colorEntryMapStateToProps = (state, ownProps) => {
  const { color, colorKey } = toolbar.getValidColorPropsFactory(ownProps.colorKey)(state);

  return {
    color,
    colorKey,
  };
};

@connect(colorEntryMapStateToProps,
  {
    setActivePopupMenu,
  })
class ColorPopupEntry extends Component {
  static propTypes = {
    color: PropTypes.string.isRequired,
    colorKey: PropTypes.string.isRequired,
    setActivePopupMenu: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    popupMenuItem: PropTypes.string.isRequired,
  };

  handleSetActivePopupMenu = () => {
    this.props.setActivePopupMenu(this.props.popupMenuItem);
  }

  render() {
    const icon = getColorIcon(this.props.colorKey);
    return (
      <Ui.Menu.ListItem>
        <Ui.Menu.Item
          type={Ui.menu.itemsTypes.dropdown}
          dataPointer={Ui.control.dataPointers.arrowRight}
          onClick={this.handleSetActivePopupMenu}
        >
          <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.body}>

            <Ui.Menu.ItemByType attributes={Ui.attributes.grow}>
              <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.text}>
                <Ui.Text size={16} align={Ui.text.aligns.left}>
                  {this.props.label}
                </Ui.Text>
              </Ui.Menu.ItemByType>
            </Ui.Menu.ItemByType>

            <Ui.Menu.ItemByType attributes={Ui.attributes.shrinkOff}>
              <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.control}>
                <Ui.Control
                  behavior={Ui.control.behaviors.column}
                  icon={
                    icon
                      ? <Ui.Icon type={icon} size={Ui.icon.sizes.small} />
                      : null
                  }
                  boxIcon={
                    getBoxIcon({
                      boxColor: this.props.color,
                      type: Ui.control.boxIconTypes.thin,
                    })
                  }
                  inheritRadius
                />
              </Ui.Menu.ItemByType>
            </Ui.Menu.ItemByType>

          </Ui.Menu.ItemByType>
        </Ui.Menu.Item>
      </Ui.Menu.ListItem>
    );
  }
}

export default ColorPopupEntry;
