import React from 'react';
import PropTypes from 'prop-types';
import * as Ui from '../../../ui';

const nextButton = ({ toNext, toLast, can }) => {
  return (
    <Ui.PageNavigation.Cell>
      <Ui.Group.Index
        type={Ui.group.types.compact}
      >
        <Ui.Group.Body>
          <Ui.Group.Item>
            <Ui.Control
              behavior={Ui.control.behaviors.wrapText}
              size={Ui.control.sizes.extraSmall}
              theme={Ui.control.themes.transparentGray}
              offset={Ui.control.offsets.extraSmall}
              onClick={toNext}
              attributes={Ui.attributes.redesignTrue}
              disabled={!can}
              icon={
                <Ui.Icon
                  size={Ui.icon.sizes.small}
                  type={Ui.icon.types.arrowRight}
                />
              }
            />

          </Ui.Group.Item>
          <Ui.Group.Item>
            <Ui.Control
              behavior={Ui.control.behaviors.wrapText}
              size={Ui.control.sizes.extraSmall}
              theme={Ui.control.themes.transparentGray}
              offset={Ui.control.offsets.extraSmall}
              onClick={toLast}
              attributes={Ui.attributes.redesignTrue}
              disabled={!can}
              icon={
                <Ui.Icon
                  size={Ui.icon.sizes.small}
                  type={Ui.icon.types.doubleArrowRight}
                />
              }
            />
          </Ui.Group.Item>
        </Ui.Group.Body>
      </Ui.Group.Index>
    </Ui.PageNavigation.Cell>
  );
};

nextButton.propTypes = {
  toNext: PropTypes.func.isRequired,
  toLast: PropTypes.func.isRequired,
  can: PropTypes.bool.isRequired,
};

export default nextButton;
