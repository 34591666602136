import { sendFontRecognitionImage } from 'ws-editor-lib/actions';
import { findPageSettingsByPageId } from 'jsfcore/helpers/utils';
import { selectors } from 'jsfcore';
import pdfPagesStore from 'jsfcore/store/helpers/pdfPagesStore';
import pdfTextStore from 'jsfcore/store/helpers/pdfTextStore';
import pdfDocumentStore from 'jsfcore/store/helpers/pdfDocumentStore';
import pdfFontsStore from './store/pdfFontsStore';
import { desiredImageHeight, maxFontRecognizeWaitTime } from './helpers/const';

// regex for get MIME type from base64 string
const mimeRegex = /data:[a-zA-Z0-9]+\/([a-zA-Z0-9-.+]+).*,.*/;

/*
 * 'sendCanvas'
 */
const getImageFormat = (base64) => {
  if (typeof base64 !== 'string') {
    return null;
  }

  const mime = base64.match(mimeRegex);
  if (mime && mime.length) {
    return mime[1];
  }

  return null;
};

/**
 * Возвращаем канвас, на котором отрендерен кусок pdf-страницы с текстом
 * отскейленный таким образом, чтобы его высота была desiredHeight, если надо.
 */
export const getScaledImage =
  async ({ state, pageId, highlightItem, desiredHeight = false, maxWidth = Infinity }) => {
    const pdfScale = selectors.base.getPdfScale(state);
    const pdf = pdfDocumentStore.getPdfDocument();
    const pdfPage = await pdf.getPage(pageId + 1);

    const pagesSettings = selectors.navigation.getPagesSettings(state);
    const pageSetting = findPageSettingsByPageId(pagesSettings, pageId);

    // scale that needed to render item with desiredImageHeight
    const scale = desiredHeight
      ? desiredHeight / highlightItem.height
      : pdfScale;

    const viewport = pdfPage.getViewport(
      scale,
      pageSetting.rotation + pdfPage.rotate,
    );

    const canvasEl = document.createElement('canvas');
    const canvasContext = canvasEl.getContext('2d');

    canvasContext.globalCompositeOperation = 'source-in';
    canvasEl.width = Math.min(highlightItem.width * scale, maxWidth);
    canvasEl.height = highlightItem.height * scale;
    viewport.transform[4] = -highlightItem.x * scale;
    viewport.transform[5] -= highlightItem.y * scale;

    const { promise } = pdfPage.render({
      canvasContext,
      viewport,
      intent: 'display', // maybe 'print'
    });
    await promise;
    return canvasEl;
  };

/**
 * recognize font at given area of pdf document
 *
 * @param {object} store - redux store
 * @param {number} pageId - pdf document's page
 * @param {shape({ x, y, width, heght })} item - area of page to crop and send
 * @returns {promise} promise, that is resolved on font recognize finish
 */
export const recognizeFont = ({ store, item }) => {
  return new Promise(async (resolve, reject) => {
    const knownFont = pdfFontsStore.getFontByPdfName(item.pdfFontName);
    if (knownFont) {
      resolve(knownFont);
      return;
    }

    // Need sample of same pdfFontName with longest text
    const textSample = pdfTextStore.getPdfTextSample(item.pdfFontName);

    const state = store.getState();
    const canvas = await getScaledImage({
      state,
      pageId: textSample.pageId,
      highlightItem: textSample,
      desiredHeight: desiredImageHeight,

      // Требование aberkovsky: для распознавания шрифта достаточно, чтобы
      // в ширину было 10 высоты (если полсимвола обрежется - не страшно).
      maxWidth: desiredImageHeight * 10,
    });

    const base64image = canvas.toDataURL();
    const base64dataOnly = base64image.slice(base64image.indexOf(',') + 1);
    const format = getImageFormat(base64image);

    if (format) {
      const oldRecognizedFont = selectors.base.getRecognizedFont(state);

      let unsubscribe = null;

      const timeout = setTimeout(() => {
        if (unsubscribe) {
          unsubscribe();
        }
        reject();
      }, maxFontRecognizeWaitTime);

      unsubscribe = store.subscribe(() => {
        const newRecognizedFont = selectors.base.getRecognizedFont(store.getState());
        if (oldRecognizedFont !== newRecognizedFont) {
          unsubscribe();
          unsubscribe = null;
          clearTimeout(timeout);
          pdfFontsStore.putFontByPdfName(item.pdfFontName, newRecognizedFont);
          resolve(newRecognizedFont);
        }
      });
      store.dispatch(sendFontRecognitionImage(base64dataOnly, format, item.text));
    } else {
      reject();
    }
  });
};
