import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import fill from 'lodash/fill';
import { selectors } from '../..';
import { isSimplePagination } from '../../helpers/utils';

const getFrameOffsetsCustom = createSelector(
  [
    (frameSizes) => {
      return frameSizes;
    },
    (_, workspace) => {
      return workspace;
    },
    (_, __, frameScrolls) => {
      return frameScrolls;
    },
  ],
  selectors.getFrameOffsetsWithoutReselect,
);

const fillFrameScrollsDefault = ({ length = 0 }) => {
  return fill(Array(length), { scrollTop: 0, scrollLeft: 0 });
};

export class ImmediateFrameOffsetProviderDefault extends Component {
  static propTypes = {
    pageId: PropTypes.number.isRequired,
    children: PropTypes.func.isRequired,
    frameSizes: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          height: PropTypes.number.isRequired,
        }),
        PropTypes.bool,
      ]).isRequired,
    ).isRequired,
    workspace: PropTypes.shape({
      height: PropTypes.number.isRequired,
      framePadding: PropTypes.shape({
        top: PropTypes.number.isRequired,
        bottom: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        right: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    frameScrolls: PropTypes.arrayOf(
      PropTypes.shape({
        scrollTop: PropTypes.number.isRequired,
        scrollLeft: PropTypes.number.isRequired,
      }),
    ).isRequired,
    provideFrameOffsetWithoutScroll: PropTypes.bool,
  };

  static defaultProps = {
    provideFrameOffsetWithoutScroll: false,
  };

  static contextTypes = {
    subscribeToScroll: PropTypes.func.isRequired,
    unsubscribeToScroll: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      frameScrolls: props.frameScrolls,
    };
  }

  componentDidMount() {
    this.context.subscribeToScroll(this.onScroll);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ frameScrolls }) {
    if (frameScrolls.length !== this.state.frameScrolls.length) {
      this.setState({ frameScrolls });
    }
  }

  componentWillUnmount() {
    this.context.unsubscribeToScroll(this.onScroll);
  }

  getPageId = () => {
    return this.props.pageId;
  };

  getFrameOffsetWithScroll = () => {
    return this.getFrameOffsetsWithScroll()[this.getPageId()];
  };

  getFrameOffsetWithoutScroll = () => {
    return this.getFrameOffsetsWithoutScroll()[this.getPageId()];
  };

  getFrameOffsetsWithoutScroll = () => {
    const { frameSizes, workspace } = this.props;
    return getFrameOffsetsCustom(
      frameSizes,
      workspace,
      fillFrameScrollsDefault({ length: frameSizes.length }),
    );
  }

  getFrameOffsetsWithScroll = () => {
    const { frameSizes, workspace } = this.props;
    const { frameScrolls } = this.state;
    return getFrameOffsetsCustom(frameSizes, workspace, frameScrolls);
  };

  onScroll = (scroll, pageId) => {
    const { frameScrolls } = this.state;
    const newFrameScrolls = isSimplePagination()
      ? [
        ...frameScrolls.slice(0, pageId),
        scroll,
        ...frameScrolls.slice(pageId + 1),
      ]
      : fill(Array(this.props.frameSizes.length), scroll);

    this.setState({ frameScrolls: newFrameScrolls });
  };

  render() {
    const { provideFrameOffsetWithoutScroll } = this.props;
    return this.props.children({
      frameOffset: this.getFrameOffsetWithScroll(),
      ...(
        provideFrameOffsetWithoutScroll
          ? { frameOffsetWithoutScroll: this.getFrameOffsetWithoutScroll() }
          : {}
      ),
    });
  }
}

const mapStateToProps = (state) => {
  return {
    frameSizes: selectors.getFrameSizes(state),
    workspace: selectors.base.getWorkspace(state),
    frameScrolls: selectors.base.getFrameScrolls(state),
  };
};

export default connect(mapStateToProps)(ImmediateFrameOffsetProviderDefault);
