import { thisDevice } from '@pdffiller/jsf-useragent';
import { getIsPressedMetaKey } from '../../../helpers/const';

export default class WheelZoom {
  constructor({ onStart, onMove, onEnd, node }) {
    this.wheeling = false;
    this.wheelTimeout = undefined;
    this.callbacks = {
      onWheelStart: onStart,
      onWheelMove: onMove,
      onWheelEnd: onEnd,
    };

    /**
     * Handlers for mouse wheel or touchpad
     */
    // https://developer.mozilla.org/en-US/docs/Web/Events/wheel
    window.addEventListener('wheel', this.preventZoom);
    node.addEventListener('wheel', this.setZoom, true);

    // Prevent zoom for Opera/Safari/Chrome
    window.addEventListener('mousewheel', this.preventZoom);
    node.addEventListener('mousewheel', this.setZoom, true);

    // Prevent zoom for Firefox
    window.addEventListener('MozMousePixelScroll', this.preventZoom);
    node.addEventListener('MozMousePixelScroll', this.setZoom, true);

    // Prevent zoom for macOs and safari from 9.1
    if (thisDevice.isSafariDesktop) {
      document.addEventListener('gesturestart', this.preventZoom);
      node.addEventListener('gesturestart', this.setZoom, true);
    }
  }

  preventZoom = (event) => {
    const isPressedMetaKey = getIsPressedMetaKey(event);

    if (isPressedMetaKey) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  };

  setZoom = (event) => {
    const isPressedMetaKey = getIsPressedMetaKey(event);

    if (isPressedMetaKey) {
      if (!event) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();

      if (!this.wheeling) {
        this.callbacks.onWheelStart();
        this.wheeling = true;
      } else {
        this.callbacks.onWheelMove(event);

        clearTimeout(this.wheelTimeout);
        this.wheelTimeout = setTimeout(() => {
          this.callbacks.onWheelEnd(event);
          this.wheelTimeout = undefined;
          this.wheeling = false;
        }, 250);
      }
    }
  };
}
