import renderGhost from './renderGhost';
import MobileGhostDataProvider from './GhostDataProvider';
import GhostElementCreator from './GhostElementCreator';

const GhostDataProvider = MobileGhostDataProvider;
export {
  renderGhost,
  GhostDataProvider,
  GhostElementCreator,
};
