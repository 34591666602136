import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';

import { SvgCheck, SvgCircle, SvgCross } from './Svgs';
import { getValidColor } from '../../../helpers/graphicUtils';
import { getElementCursor } from '../../../helpers/utils';

export default class SVGTool extends Component {
  static propTypes = {
    isDisabled: PropTypes.bool,
    subType: PropTypes.string.isRequired,
    color: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    checked: PropTypes.bool,
    isGhost: PropTypes.bool,

    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    storeRef: PropTypes.func.isRequired,
    interceptFocusDataset: PropTypes.shape({}),
  };

  static defaultProps = {
    isDisabled: false,
    checked: false,
    isGhost: false,

    interceptFocusDataset: undefined,
  };

  getWrapperStyles = () => {
    const cursor = getElementCursor(this.props.isDisabled);

    return this.props.checked
      ? { width: '100%', height: '100%', cursor }
      : { width: '100%', height: '100%', cursor, opacity: 0 };
  }

  renderSVG() {
    const { subType, color } = this.props;
    switch (subType) {
      case 'x':
        return <SvgCross color={getValidColor(color)} />;
      case 'o':
        return <SvgCircle color={getValidColor(color)} />;
      case 'v':
        return <SvgCheck color={getValidColor(color)} />;
      default:
        return null;
    }
  }

  render() {
    const { onFocus, onBlur, storeRef, isGhost, interceptFocusDataset } = this.props;
    const wrapperStyles = this.getWrapperStyles();

    return (
      <div
        className={classnames('svgTool-SVGTool', {
          'elementHover-Content': !this.props.isDisabled,
        })}
        tabIndex={0}
        onFocus={onFocus}
        onBlur={onBlur}
        // TODO: research
        ref={
          isGhost
            ? null
            : storeRef
        }
        {...interceptFocusDataset}
      >
        <div
          style={wrapperStyles}
        >
          {this.renderSVG()}
        </div>
      </div>
    );
  }
}
