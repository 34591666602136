import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { thisDevice } from '@pdffiller/jsf-useragent';

import * as Ui from '../../ui';
import { isEnabledElement } from '../../helpers/utils';
import { graphicToolPaddingPx } from '../../helpers/const';
import {
  isCheckmarkElement,
  isFilledElement,
  isFormulaElement,
  isRadioElement,
  isErase,
} from '../../store/helpers/functions';
import {
  elemTypes,
  isGraphicToolType,
  isTextToolBasedElement,
} from '../../helpers/elemTypes';
import getStyleWithPointerEvent from './getStyleWithPointerEvent';

const nullF = () => {};

export default class ElementSimpleWrapperView extends Component {
  static propTypes = {
    children: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.element.isRequired,
        PropTypes.oneOf([false]).isRequired,
      ]).isRequired,
    ).isRequired,
    element: PropTypes.shape({
      type: PropTypes.string.isRequired,
      subType: PropTypes.string.isRequired,
      isChecked: PropTypes.bool,
      template: PropTypes.shape({
        required: PropTypes.bool,
      }),
    }).isRequired,
    elementProps: PropTypes.shape({}).isRequired,
    isFillableElement: PropTypes.bool.isRequired,
    isActiveElement: PropTypes.bool.isRequired,
    isStretching: PropTypes.bool.isRequired,
    isHighlighted: PropTypes.bool.isRequired,
    isConstructorMode: PropTypes.bool.isRequired,
    isInvalid: PropTypes.bool,
    storeRef: PropTypes.func.isRequired,

    // From DraggableCore
    onMouseDown: PropTypes.func,
    onTouchStart: PropTypes.func,
    onMouseUp: PropTypes.func,
    onTouchEnd: PropTypes.func,
  };

  static defaultProps = {
    onMouseDown: nullF,
    onTouchStart: nullF,
    onMouseUp: nullF,
    onTouchEnd: nullF,
    isInvalid: false,
  };

  getViewStyleAndEnabledProp() {
    const { elementProps, element, isConstructorMode } = this.props;
    const isEnabled = isEnabledElement(element);
    const isEraseElement = isErase(element);

    const style = getStyleWithPointerEvent({
      style: isGraphicToolType(element.type)
        ? {
          margin: `${-graphicToolPaddingPx}px`,
          padding: `${graphicToolPaddingPx}px`,
          width: '100%',
          height: '100%',
          boxSizing: 'content-box',
        }
        : {
          width: '100%',
          height: '100%',
        },

      elementProps,
      isOuter: false,
      isActiveLinks: !isConstructorMode,
      isEraseElement,
    });

    return { style, isEnabled };
  }

  renderFillableView() {
    const { isActiveElement, isFillableElement, element, isInvalid, isHighlighted } = this.props;
    const { style, isEnabled } = this.getViewStyleAndEnabledProp();

    const canBeChecked = isRadioElement(element) || isCheckmarkElement(element);
    const isFilled = !canBeChecked && isFillableElement && isFilledElement(element);
    const isChecked = canBeChecked && element.isChecked;
    const isRequired = (isEnabled && isHighlighted) ||
      (element.template && element.template.required && isEnabled);

    return (
      <Ui.FillableField.Body
        style={style}
        onMouseDown={this.props.onMouseDown}
        onTouchStart={this.props.onTouchStart}
        onMouseUp={this.props.onMouseUp}
        onTouchEnd={this.props.onTouchEnd}
        isFocused={isEnabled && isActiveElement}
        isFilled={isFilled}
        isRequired={isRequired}
        isInvalid={isInvalid}
        isDisabled={!isEnabled}
        isChecked={isChecked}
        type={element.type}
        subType={element.subType}
        isEnabled={isEnabled}
      >
        <Ui.FillableField.Container>
          {this.props.children}
        </Ui.FillableField.Container>
      </Ui.FillableField.Body>
    );
  }

  renderSimpleView() {
    const {
      isActiveElement,
      element,
      isFillableElement,
      isStretching,
      isConstructorMode,
    } = this.props;
    const { style, isEnabled } = this.getViewStyleAndEnabledProp();
    const isTextToolBased = isTextToolBasedElement(element);

    return (
      <Ui.SimpleTool
        style={style}
        onMouseDown={this.props.onMouseDown}
        onTouchStart={this.props.onTouchStart}
        onMouseUp={this.props.onMouseUp}
        onTouchEnd={this.props.onTouchEnd}
        isActiveElement={isActiveElement}
        isFillableElement={isFillableElement}
        isDisabled={!isEnabled && !isConstructorMode}
        type={element.type}
        isEnabled={isEnabled}
        isStretching={isStretching}
        isHoverable={(
          thisDevice.isDesktop &&
          !isStretching &&
          !isFillableElement &&
          !isConstructorMode
        )}
        isTextToolBased={isTextToolBased}
        isHoverableErase={(
          (element.type === elemTypes.erase) &&
          thisDevice.isDesktop &&
          !isStretching &&
          !isActiveElement &&
          !isConstructorMode
        )}
      >
        {this.props.children}
      </Ui.SimpleTool>
    );
  }

  render() {
    const {
      element,
      isFillableElement,
      isActiveElement,
      storeRef,
    } = this.props;
    const isTextToolBased = isTextToolBasedElement(element);
    const isFormula = isTextToolBased && isFormulaElement(element);

    const boxStyle = {
      width: '100%',
      height: '100%',
    };

    return (
      <Ui.Box.Index
        theme={Ui.box.themes.primary}
        isFocused={isActiveElement}
        storeRef={storeRef}
        style={boxStyle}
      >
        <Ui.Box.Body>
          <Ui.Box.Main>
            {isFillableElement && !isFormula
              ? this.renderFillableView()
              : this.renderSimpleView()}
          </Ui.Box.Main>
        </Ui.Box.Body>
      </Ui.Box.Index>
    );
  }
}
