import PropTypes from 'prop-types';
import { Component } from 'react';
import { setMoveCursor, deleteMoveCursor } from '../../helpers/utils';

export default class ElementFillableMoveCursor extends Component {
  static propTypes = {
    isDragging: PropTypes.bool.isRequired,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isDragging && !this.props.isDragging) {
      setMoveCursor();
    }
    if (!nextProps.isDragging && this.props.isDragging) {
      deleteMoveCursor();
    }
  }

  render() {
    return null;
  }
}
