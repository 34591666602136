import PropTypes from 'prop-types';
import { Component } from 'react';
import { selectors } from '..';
import {
  getAdjacentPageId,
  getPageIdByIndex,
  getIndexByPageId,
} from '../helpers/utils';

export default class PageIdIndexContextProvider extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
  };

  static contextTypes = {
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
    }).isRequired,
  };

  static childContextTypes = {
    getAdjacentPageId: PropTypes.func,
    getPageIdByIndex: PropTypes.func,
    getIndexByPageId: PropTypes.func,
  };

  getChildContext = () => {
    return {
      getAdjacentPageId: this.getAdjacentPageId,
      getIndexByPageId: this.getIndexByPageId,
      getPageIdByIndex: this.getPageIdByIndex,
    };
  };

  getAdjacentPageId = (adjacent, activePageId = this.getActivePageId()) => {
    return getAdjacentPageId(activePageId, this.getPagesSettings(), adjacent);
  };

  getPageIdByIndex = (index) => {
    return getPageIdByIndex(index, this.getPagesSettings());
  };

  getIndexByPageId = (pageId) => {
    return getIndexByPageId(pageId, this.getPagesSettings());
  };

  getActivePageId = () => {
    return selectors.base.getActivePageId(this.context.store.getState());
  };

  getPagesSettings = () => {
    return selectors.navigation.getPagesSettings(this.context.store.getState());
  };

  render() {
    return this.props.children;
  }
}
