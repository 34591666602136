import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import once from 'lodash/once';
import { inY } from 'verge';

import { thumbnailsMargin } from '../../helpers/const';
import { smoothScrollToElement } from '../../helpers/utils';
import { setVisibleThumbnails } from '../../store/modules/pdf';
import { selectors } from '../..';

/**
 * return needed scroll (amount of pixels relative to current scroll position)
 */
export const getScrollDelta = (thumbnailsNodeRect, activeNodeRect) => {
  const { top: nodeTop, bottom: nodeBottom } = thumbnailsNodeRect;
  const { top: thumbnailTop, bottom: thumbnailBottom } = activeNodeRect;

  if (nodeTop + thumbnailsMargin > thumbnailTop) {
    return thumbnailTop - thumbnailsMargin - nodeTop;
  }

  if (nodeBottom - thumbnailsMargin < thumbnailBottom) {
    return (thumbnailBottom + thumbnailsMargin) - nodeBottom;
  }

  return 0;
};

@connect(
  (state) => {
    return {
      activePageId:
        selectors.base.getNextPageId(state) === false
          ? selectors.base.getActivePageId(state)
          : selectors.base.getNextPageId(state),
      count: state.pdf.count,
      hasPdfDocument: state.pdf.hasPdfDocument,
      pagesSettings: selectors.navigation.getPagesSettings(state),
    };
  }, { setVisibleThumbnails },
)
export default class ThumbnailsScrollController extends Component {
  static propTypes = {
    activePageId: PropTypes.number.isRequired,
    // TODO: remove eslint-disable-next-line after remove UNSAFE_componentWillReceiveProps
    // eslint-disable-next-line react/no-unused-prop-types
    hasPdfDocument: PropTypes.bool.isRequired,
    pagesSettings: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          rotation: PropTypes.number,
          source: PropTypes.number,
          visible: PropTypes.bool,
        }),
      ),
      PropTypes.bool,
    ]),
    count: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool,
    ]).isRequired,
    getRefs: PropTypes.func.isRequired,
    storeRefs: PropTypes.func.isRequired,

    setVisibleThumbnails: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
  };

  static defaultProps = {
    pagesSettings: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      savedActivePageId: props.activePageId,
    };
  }

  componentDidMount() {
    this.delegateEvents();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ activePageId, hasPdfDocument }) {
    if (activePageId !== this.state.savedActivePageId && hasPdfDocument) {
      this.setState({
        savedActivePageId: activePageId,
      }, () => {
        this.scrollToThumbnail(this.state.savedActivePageId);
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.count === false && this.props.count !== false) {
      this.updateVisibleThumbnails();
    }
  }

  getNode = () => {
    return this.props.getRefs('index')();
  };

  getThumbnailNode = (id) => {
    return this.props.getRefs(id)();
  };

  getVisibleThumbnails = () => {
    return (
      this.props.pagesSettings
        .map(({ source }) => {
          return (
            this.isVisibleThumbnail(source)
              ? source
              : null
          );
        })
        .filter((item) => {
          return item !== null;
        })
    );
  };

  updateVisibleThumbnails = () => {
    if (!this.props.pagesSettings) {
      return;
    }

    const visibleThumbnails = this.getVisibleThumbnails();
    if (visibleThumbnails.length > 0) {
      this.props.setVisibleThumbnails(visibleThumbnails);
    }
  };

  updateVisibleThumbnailsDebounce = once(
    debounce(this.updateVisibleThumbnails, 200),
  );

  scrollToThumbnail = (activePageId) => {
    const activeNode = this.getThumbnailNode(activePageId);

    if (activeNode) {
      const scrollNode = this.getNode();
      smoothScrollToElement(activeNode, scrollNode, { duration: 150 });
    }
  };

  storeRefs = (source) => {
    return (ref) => {
      this.props.storeRefs(source)(ref);
      if (source !== 'index') {
        this.updateVisibleThumbnailsDebounce();
      }
    };
  };

  isVisibleThumbnail = (pageId) => {
    return inY(this.getThumbnailNode(pageId), 200);
  };

  delegateEvents() {
    if (__CLIENT__) {
      const node = this.getNode();
      node.addEventListener('scroll', debounce(this.updateVisibleThumbnails, 100));
    }
  }

  render() {
    return this.props.children({
      storeRefs: this.storeRefs,
      count: this.props.count,
    });
  }
}
