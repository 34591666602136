import PropTypes from 'prop-types';
import React from 'react';
import { thisDevice } from '@pdffiller/jsf-useragent';

import { FocusControllerRestoreFocusArea } from '@pdffiller/jsf-focuscontroller';
import ElementWrapperPositionView from './ElementWrapperPositionView';
import ElementScrollToProvider from './ElementScrollToProvider';
import ElementScrollToExecutor from './ElementScrollToExecutor';
import ElementFillableMoveCursor from './ElementFillableMoveCursor';
import ElementBoundOnChangeSize from './ElementBoundOnChangeSize';
import ElementTooltip from './ElementTooltip/ElementTooltip';
import ElementHref from './ElementHref/ElementHref';
import * as Portal from '../Portal';

const locatorArgsTooltip = {
  position: Portal.wrapperPositions.topLeftOuter,
  preset: Portal.wrapperPresets.tooltip,
  hideIfAnchorOutOfViewport: true,
};

const locatorArgsHref = {
  position: Portal.wrapperPositions.leftCenterOuter,
  preset: Portal.wrapperPresets.href,
  hideIfAnchorOutOfViewport: true,
};

const renderElementWrapperView = (props, context) => {
  const {
    children,

    onDrag,
    draggableProps,
    element,
    scale,

    elementProps,
    isFillableElement,
    isConstructorMode,
    isStretching,
    isActiveElement,

    onClickWrapperElement,

    hover,
    onMouseEnter,
    onMouseLeave,
  } = props;

  return (
    <ElementWrapperPositionView
      element={element}
      elementProps={elementProps}
      scale={scale}
      isConstructorMode={isConstructorMode}
      isActiveElement={isActiveElement}
      isFillableElement={isFillableElement}

      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClickWrapperElement}
    >
      {({ getWrapperPositionRefPromise }) => {
        return (
          <>
            {!draggableProps.isDragging &&
              <ElementScrollToProvider
                element={element}
                scale={scale}
              >
                {({ scrollToElement }) => {
                  return (
                    <ElementScrollToExecutor
                      scrollToElement={scrollToElement}
                      isFillable={isFillableElement}
                      isActiveElement={isActiveElement}
                      element={element}
                    />
                  );
                }}
              </ElementScrollToProvider>
            }

            {isFillableElement &&
              <ElementFillableMoveCursor
                isDragging={draggableProps.isDragging}
              />
            }

            <ElementBoundOnChangeSize
              isActiveElement={isActiveElement}
              elementId={element.id}
            />
            {(
              thisDevice.isDesktop &&
              !isStretching &&
              !isFillableElement &&
              isActiveElement
            ) && (
              <FocusControllerRestoreFocusArea>
                <Portal.WrapperExperiment
                  getAnchorRefPromise={getWrapperPositionRefPromise}
                  getViewportRef={context.getWorkspaceRef}
                  locatorArgs={locatorArgsTooltip}
                  theme={null}
                  useArrow={false}
                >
                  <ElementTooltip
                    element={element}
                    isActiveElement={isActiveElement}
                    onDragStart={onDrag.start}
                    onDragMove={onDrag.move}
                    onDragStop={onDrag.stop}
                    isDragging={draggableProps.isDragging}
                    elementProps={elementProps}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                  />
                </Portal.WrapperExperiment>
              </FocusControllerRestoreFocusArea>
            )
            }
            {thisDevice.isDesktop && children}
            {!draggableProps.isDragging && hover && (
              <Portal.WrapperExperiment
                getAnchorRefPromise={getWrapperPositionRefPromise}
                getViewportRef={context.getWorkspaceRef}
                locatorArgs={locatorArgsHref}
                theme={null}
                useArrow={false}
              >
                <ElementHref
                  elementProps={elementProps}
                  visible
                />
              </Portal.WrapperExperiment>
            )}

            {thisDevice.isMobile && children}
          </>
        );
      }}
    </ElementWrapperPositionView>
  );
};

renderElementWrapperView.propTypes = {
  children: PropTypes.element.isRequired,

  element: PropTypes.shape({
    type: PropTypes.string.isRequired,
    subType: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    pageId: PropTypes.number.isRequired,
    template: PropTypes.shape({}),
  }).isRequired,
  onDrag: PropTypes.shape({
    start: PropTypes.func,
    move: PropTypes.func,
    stop: PropTypes.func,
  }).isRequired,
  draggableProps: PropTypes.shape({
    isDragging: PropTypes.bool.isRequired,
    storeValidatedTextToolSize: PropTypes.func,
    resizeIndex: PropTypes.number,
    resizingGeometry: PropTypes.shape({}),
  }).isRequired,
  scale: PropTypes.number.isRequired,

  elementProps: PropTypes.shape({}).isRequired,
  isFillableElement: PropTypes.bool.isRequired,
  isConstructorMode: PropTypes.bool.isRequired,
  isStretching: PropTypes.bool.isRequired,
  isActiveElement: PropTypes.bool.isRequired,

  onClickWrapperElement: PropTypes.func.isRequired,

  hover: PropTypes.bool.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};

renderElementWrapperView.contextTypes = {
  getWorkspaceRef: PropTypes.func,
};

export default renderElementWrapperView;
