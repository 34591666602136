import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

import { thunks } from '../../..';
import {
  graphicToolPaddingPx,
  mouseFocusClassName,
  defaultZoomScale,
} from '../../../helpers/const';
import {
  stopEvent,
  getMouseEventHandler,
} from '../../../helpers/utils';

const DELETE_BTN_DECREASE_SIZE_COEFFICIENT = 19.5;
const DELETE_BTN_INITIAL_PX_WIDTH = 15;
const DELETE_BTN_INITIAL_PX_HEIGHT = 15;


@connect(
  null, {
    remove: thunks.remove,
  },
)
export default class EraseDeleteBtn extends Component {
  static propTypes = {
    elementId: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    scale: PropTypes.number.isRequired,
    hasHoverer: PropTypes.bool,
    remove: PropTypes.func.isRequired,
  };

  static defaultProps = {
    hasHoverer: false,
  };

  handleMouseEvent = (event) => {
    stopEvent(event);
    this.props.remove(this.props.elementId);
  };

  render() {
    const isScaleLowerThanDefault = this.props.scale < defaultZoomScale;

    const decreaseSize = isScaleLowerThanDefault
      ? Math.floor((defaultZoomScale - this.props.scale) * DELETE_BTN_DECREASE_SIZE_COEFFICIENT)
      : 0;

    const deleteBtnStyle = isScaleLowerThanDefault
      ? {
        width: `${DELETE_BTN_INITIAL_PX_WIDTH - decreaseSize}px`,
        height: `${DELETE_BTN_INITIAL_PX_HEIGHT - decreaseSize}px`,
      }
      : null;

    const svgIconStyleScale = isScaleLowerThanDefault
      ? this.props.scale
      : 1;

    const svgIconStyle = {
      zIndex: 10,
      transform: `scale(${svgIconStyleScale})`,
    };

    const wrapperStyle = this.props.hasHoverer
      ? {
        left: `calc(100% + ${graphicToolPaddingPx}px)`,
        top: `-${graphicToolPaddingPx}px`,
      }
      : null;

    // We need 'Hoverer' to make it possible
    // to continiously move mouse from element to button without hover loose
    return (
      <div
        className={classnames(
          'eraseDeleteBtnWrapper-EraseDeleteBtn',
          this.props.className,
          mouseFocusClassName,
        )}
        style={wrapperStyle}
      >
        {this.props.hasHoverer && <div className="eraseDeleteBtnHoverer-EraseDeleteBtn" />}
        <div
          className={classnames('jsf-doc-btn', 'eraseDeleteBtn-EraseDeleteBtn')}
          style={deleteBtnStyle}
          {...getMouseEventHandler(this.handleMouseEvent)}
        >
          <div
            className="jsf-doc-btn__icon icon-svg-trash-white"
            style={svgIconStyle}
          />
        </div>
      </div>
    );
  }
}
