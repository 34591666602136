import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { triggerDeclineButtonClick } from '../../../store/modules/events';
import * as selectors from '../../../store/selectors';
import * as Ui from '../../../ui';

@connect(
  (state) => {
    return {
      locale: selectors.locale.getEditorLocale(state).headerDeclineButton,
    };
  },
  {
    triggerDeclineButtonClick,
  },
)
export default class HeaderDeclineButton extends Component {
  static propTypes = {
    locale: PropTypes.shape({
      label: PropTypes.string.isRequired,
    }).isRequired,
    triggerDeclineButtonClick: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Ui.EditorAction.Item>
        <Ui.Control
          theme={Ui.control.themes.silver}
          size={Ui.control.sizes.medium}
          offset={Ui.control.offsets.medium}
          icon={
            <Ui.Icon
              type={Ui.icon.types.documentDecline}
              size={Ui.icon.sizes.small}
            />
          }
          onClick={this.props.triggerDeclineButtonClick}
        >
          <Ui.Text
            weight={Ui.text.weights.bold}
            size={13}
          >
            {this.props.locale.label}
          </Ui.Text>
        </Ui.Control>
      </Ui.EditorAction.Item>
    );
  }
}
