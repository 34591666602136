import React from 'react';

import { FormattedMessage } from 'jsfcore/jsf-localization';
import * as Ui from 'jsfcore/ui';

const ChoiceByClickingHelpText = () => {
  return (
    <FormattedMessage id="COMPACT_HEADER.FIELD_SELECTION_STARTED">
      {(formattedNotificationText) => {
        return (
          <Ui.Grid.Cell>
            <Ui.Grid.Wrapper>
              <Ui.ToolBar.Body>
                {formattedNotificationText}
              </Ui.ToolBar.Body>
            </Ui.Grid.Wrapper>
          </Ui.Grid.Cell>
        );
      }}
    </FormattedMessage>
  );
};

export default ChoiceByClickingHelpText;
