import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { toolbar } from '../../../../store/selectors';
import setColor from '../../../../store/thunks/setColor';
import forceFocusTextElement from '../../../../store/thunks/forceFocusTextElement';
import { colorKeys } from '../../../../helpers/const';
import Dropdown from '../../../Dropdown/Dropdown';
import ColorPickerDesktop from './Popup/ColorPickerDesktop';

import * as Ui from '../../../../ui';
import { getBoxIcon } from '../../../../ui/Control';

const colorKeyMapper = {
  borderColor: 'square',
  bgColor: 'paint',
  fontColor: 'textColor',
};

const wrapperProps = {
  offset: Ui.control.offsets.small,
};

@connect((state, ownProps) => {
  const { colorKey } = ownProps;

  // get color and valid color key
  const { colorKey: validColorKey, color } = toolbar.getValidColorPropsFactory(colorKey)(state);
  return {
    colorKey: validColorKey,
    color,
  };
}, {
  setColor,
  forceFocusTextElement,
})
export default class ColorToolbarSettings extends Component {
  static propTypes = {
    setColor: PropTypes.func.isRequired,
    forceFocusTextElement: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    colorKey: PropTypes.oneOf(Object.values(colorKeys)).isRequired,
    hasIcon: PropTypes.bool,
    hasThinBottomLine: PropTypes.bool,
  };

  static defaultProps = {
    hasIcon: false,
    hasThinBottomLine: false,
  };

  renderColorPickerDesktop = ({ closePortal }) => {
    const { colorKey } = this.props;
    return (
      <ColorPickerDesktop
        color={this.props.color}
        forceFocusTextElement={this.props.forceFocusTextElement}
        setColor={this.props.setColor}
        colorKey={colorKey}
        closePortal={closePortal}
      />
    );
  };

  render() {
    const {
      colorKey,
      color,
      hasIcon,
      hasThinBottomLine,
    } = this.props;
    const icon = hasIcon
      ? (
        <Ui.Icon
          type={Ui.icon.types[colorKeyMapper[colorKey]]}
          size={Ui.icon.sizes.large}
        />
      )
      : null;

    return (
      <Dropdown
        wrapperProps={wrapperProps}
        needStopPropagation={false}
        dropdownComponent={this.renderColorPickerDesktop}
      >
        {({ togglePortal, isPortalOpen, storeRef }) => {
          return (
            <Ui.ToolBar.Item>
              <Ui.Control
                icon={icon}
                boxIcon={
                  getBoxIcon({
                    boxColor: color,
                    type: hasThinBottomLine
                      ? Ui.control.boxIconTypes.thin
                      : null,
                  })
                }
                type={Ui.control.types.dropdown}
                title={Ui.locales.titles[colorKey]}
                behavior={Ui.control.behaviors.column}
                inheritRadius
                onClick={togglePortal}
                isPressed={isPortalOpen}
                storeRef={storeRef}
              />
            </Ui.ToolBar.Item>
          );
        }}
      </Dropdown>
    );
  }
}
