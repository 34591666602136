import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { showComments, hideComments } from 'jsfcore/store/modules/viewport';
import { activateTool } from 'ws-editor-lib/actions';
import { miscIds } from '../../../helpers/const';
import { elemTypes, elemSubTypes } from '../../../helpers/elemTypes';
import * as selectors from '../../../store/selectors';

import * as Ui from '../../../ui';

@connect(
  (state) => {
    return {
      label: selectors.locale.getMiscLabel(state, miscIds.comments.id),
      isCommentsButtonVisible: selectors.getMiscFeatureVisible(state, miscIds.comments.id),
      isCommentsShown: selectors.base.getIsCommentsShown(state),
    };
  },
  {
    activateTool,
    showComments,
    hideComments,
  },
)
export default class HeaderComments extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    isCommentsButtonVisible: PropTypes.bool.isRequired,
    isCommentsShown: PropTypes.bool.isRequired,
    showComments: PropTypes.func.isRequired,
    hideComments: PropTypes.func.isRequired,
    activateTool: PropTypes.func.isRequired,
  };

  onClick = () => {
    if (this.props.isCommentsShown) {
      this.props.activateTool(elemTypes.text, elemSubTypes.none);
      this.props.hideComments();
    } else {
      this.props.showComments();
    }
  };

  render = () => {
    if (!this.props.isCommentsButtonVisible) {
      return null;
    }

    return (
      <Ui.Container.SettingButton
        icon={
          this.props.isCommentsShown
            ? Ui.icon.types.commentSwitchActive
            : Ui.icon.types.commentSwitch
        }
        label={this.props.label}
        title={Ui.locales.titles.comments}
        isPressed={this.props.isCommentsShown}
        size={Ui.icon.sizes.large}
        offset={Ui.control.offsets.extraSmall}
        textOffset={Ui.text.offsets.topExtraSmall}
        onClick={this.onClick}
      />
    );
  }
}
