import PropTypes from 'prop-types';
import { Component } from 'react';

export default class SimpleElementViewDragWrapper extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
    ]).isRequired,

    // From DraggableCore
    style: PropTypes.shape({}),
    onMouseDown: PropTypes.func,
    onTouchStart: PropTypes.func,
    onMouseUp: PropTypes.func,
    onTouchEnd: PropTypes.func,
  };

  static defaultProps = {
    onMouseDown: null,
    onTouchStart: null,
    onMouseUp: null,
    onTouchEnd: null,
    style: null,
  };

  render() {
    const dragWrapperProps = {
      style: this.props.style,
      onMouseDown: this.props.onMouseDown,
      onTouchStart: this.props.onTouchStart,
      onMouseUp: this.props.onMouseUp,
      onTouchEnd: this.props.onTouchEnd,
    };

    return (
      this.props.children(dragWrapperProps)
    );
  }
}
