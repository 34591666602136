import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

export const groupOffsets = {
  small: 'small',
  smallHorizontal: 'small-horizontal',
  extraSmallHorizontal: 'extra-small-horizontal',
  extraSmall: 'extra-small',
  null: null,
};

export const groupSizes = {
  null: null,
  parentHeight: 'parent-height',
  parentWidth: 'parent-width',
  parent: 'parent',
};

export const groupBehaviors = {
  flexboxWrap: 'flexbox-wrap',
  flattenRadius: 'flatten-radius',
  null: null,
};

export const groupAligns = {
  center: 'center',
  null: null,
};

export const groupJustifies = {
  spaceBetween: 'space-between',
  flexEnd: 'flex-end',
  null: null,
};

export const groupTypes = {
  compact: 'compact',
  null: null,
};

export const GroupItem = ({ children, ...props }) => {
  return (
    <div className="group__item" {...props}>
      {children}
    </div>
  );
};

GroupItem.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.shape({}),
};

GroupItem.defaultProps = {
  style: {},
};

export const GroupBody = ({ children, ...props }) => {
  return (
    <div className="group__body" {...props}>
      {children}
    </div>
  );
};

GroupBody.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.shape({}),
};

GroupBody.defaultProps = {
  style: {},
};

export default class Group extends Component {
  static propTypes = {
    /* eslint-disable react/forbid-prop-types */
    children: PropTypes.any.isRequired,

    offset: PropTypes.oneOf(
      Object.values(groupOffsets),
    ),
    behavior: PropTypes.oneOf(
      Object.values(groupBehaviors),
    ),
    align: PropTypes.oneOf(
      Object.values(groupAligns),
    ),
    justify: PropTypes.oneOf(
      Object.values(groupJustifies),
    ),
    size: PropTypes.oneOf(
      Object.values(groupSizes),
    ),
    type: PropTypes.oneOf(
      Object.values(groupTypes),
    ),
  };

  static defaultProps = {
    offset: groupOffsets.small,
    behavior: groupBehaviors.null,
    align: groupAligns.null,
    justify: groupJustifies.null,
    size: groupSizes.null,
    type: groupTypes.null,
  };

  render() {
    const {
      offset,
      behavior,
      align,
      justify,
      size,
      type,
    } = this.props;
    return (
      <div
        className={cls('group', {
          [`group--offset--${offset}`]: offset,
          [`group--behavior--${behavior}`]: behavior,
          [`group--align--${align}`]: align,
          [`group--justify--${justify}`]: justify,
          [`group--size--${size}`]: size,
          [`group--type--${type}`]: type,
        })}
      >
        {this.props.children}
      </div>
    );
  }
}
