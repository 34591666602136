import {
  getObjectForAppendToNewElement,
  getFocusElements,
  getEraseSeparately,
  getDrawingLayerType,
  applyDateStampIfNeed,
} from './contentUtils';
import DrawingLayerView from './DrawingLayer/DrawingLayerView';
import LockLayerView from './LockLayer/LockLayerView';
import SearchLayer from './SearchLayer/SearchLayer';
import ClickController from './ClickController/ClickController';

import ElementsProvider from './ContentElementsProvider';
import WrapperView from './ContentWrapperView';
import ElementsSplitter from './ContentElementsSplitter';

const utils = {
  getObjectForAppendToNewElement,
  getFocusElements,
  getEraseSeparately,
  getDrawingLayerType,
  applyDateStampIfNeed,
};

export {
  // Components
  DrawingLayerView,
  LockLayerView,
  SearchLayer,
  ClickController,

  ElementsProvider,
  WrapperView,
  ElementsSplitter,

  // Utils
  utils,
};
