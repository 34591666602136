import moment from 'moment';

export const currentDate = moment().startOf('day');
export const currentYear = currentDate.year();
export const weekDays = moment.weekdaysMin();

export function isDateInputActive() {
  return (
    document &&
    document.activeElement &&
    document.activeElement.dataset.isDateInput
  );
}

export function getHoursFormat(is24) {
  if (is24) {
    return 'HH';
  }
  return 'hh';
}

export function isPM(date) {
  return (date instanceof moment) && (date.hours() > 11);
}

export function getHoursOffset(date) {
  if (isPM(date)) {
    return 12;
  }

  return 0;
}

// we need this function to ensure negative hours work correctly
export function roundHoursTo12(hours, offset = 0) {
  return ((hours + 12) % 12) + offset;
}

export function roundHoursTo24(hours) {
  return (hours + 24) % 24;
}

export function roundMinutesTo60(minutes) {
  return (minutes + 60) % 60;
}
