import updateElement from './updateElement';
import { toolbar } from '../selectors';

const setTextBoxVAlign = (valign) => {
  return (dispatch, getState) => {
    const { id } = toolbar.getElementPropsForToolbar(getState());
    return dispatch(updateElement(id, { valign }));
  };
};

export default setTextBoxVAlign;
