import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Page from '../Page/Page';
import Pagination from '../Pagination/Pagination';
import { selectors } from '../..';
import { getAdjacentPageId } from '../../helpers/utils';

const Workspace = ({ activePageId, pagesSettings, workspace }) => {
  const prevPageId = getAdjacentPageId(activePageId, pagesSettings, -1);
  const nextPageId = getAdjacentPageId(activePageId, pagesSettings, 1);

  const { top, right, bottom, left } = workspace.framePadding;

  const framePaddingStyles = {
    padding: `${top}px ${right}px ${bottom}px ${left}px`,
  };

  const fixerStyles = {
    width: workspace && workspace.width
      ? workspace.width - (left + right)
      : 0,

    height: workspace && workspace.height
      ? workspace.height - (top + bottom)
      : 0,

    background: '#fff',
  };

  return (
    <Pagination>
      {pagesSettings.map(({ source: pageId }, pageIndex) => {
        const isActivePage = activePageId === pageId;
        const key = `${pageId}-${pageIndex}`;

        if (isActivePage || pageId === prevPageId || pageId === nextPageId) {
          return (
            <Page
              pageId={pageId}
              workspace={workspace}
              isActivePage={isActivePage}
              key={key}
            />
          );
        }

        return (
          <div key={key} style={framePaddingStyles} className="page-Page pageHidden-Pagination">
            <div className="fixer-Page" style={fixerStyles} />
          </div>
        );
      })}
    </Pagination>
  );
};

Workspace.propTypes = {
  pagesSettings: PropTypes.arrayOf(
    PropTypes.shape({
      rotation: PropTypes.number,
      source: PropTypes.number,
      visible: PropTypes.bool,
    }),
  ).isRequired,
  workspace: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    framePadding: PropTypes.shape({
      top: PropTypes.number.isRequired,
      right: PropTypes.number.isRequired,
      bottom: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  activePageId: PropTypes.number.isRequired,
};

export default connect(
  (state) => {
    return {
      pagesSettings: selectors.navigation.getPagesSettings(state),
      activePageId: selectors.base.getActivePageId(state),
      workspace: state.viewport.workspace,
    };
  },
)(Workspace);
