import PropTypes from 'prop-types';
import React from 'react';
import { isSignNow } from '../../helpers/utils';

const style = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  pointerEvents: 'none',
  zIndex: 10,
  ...(isSignNow()
    ? { zIndex: 11 }
    : {}),
};

const GhostLayerView = ({ children }) => {
  if (!children) {
    return null;
  }

  return (
    <div
      className="GhostLayer"
      style={style}
    >
      {children}
    </div>
  );
};

GhostLayerView.propTypes = {
  children: PropTypes.element,
};

GhostLayerView.defaultProps = {
  children: null,
};

export default GhostLayerView;
