import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class DefaultModalView extends Component {
  static propTypes = {
    additionalText: PropTypes.element,
    cancelButton: PropTypes.bool,
    handlers: PropTypes.shape({
      onOk: PropTypes.func,
      onCloseModal: PropTypes.func,
      onCancel: PropTypes.func,
    }),
    locale: PropTypes.shape({
      text: PropTypes.string,
      cancelButtonText: PropTypes.string,
      okButtonText: PropTypes.string,
    }).isRequired,
    onCloseModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    additionalText: null,
    cancelButton: false,
    handlers: {},
  };

  closeModal = () => {
    const { handlers } = this.props;

    if (handlers.onCloseModal) {
      handlers.onCloseModal();
    }

    this.props.onCloseModal();
  };

  handleOkClick = () => {
    const { handlers } = this.props;

    if (handlers.onOk) {
      handlers.onOk();
    }

    this.closeModal();
  };

  handleCancelClick = () => {
    const { handlers } = this.props;

    if (handlers.onCancel) {
      handlers.onCancel();
    }

    this.closeModal();
  };

  render() {
    const {
      additionalText,
      cancelButton,
      locale,
    } = this.props;

    return (
      <div className="jsf-default-modal">
        <div>{locale.text}</div>

        {additionalText}

        <div>
          {cancelButton ? (
            <div
              className="g-btn g-btn-secondary jsf-default-modal__btn-style"
              onClick={this.handleCancelClick}
            >
              {locale.cancelButtonText}
            </div>
          ) : null}

          <div
            className="g-btn g-btn-primary jsf-default-modal__btn-style"
            onClick={this.handleOkClick}
          >
            {locale.okButtonText}
          </div>
        </div>
      </div>
    );
  }
}
