import React from 'react';
import PropTypes from 'prop-types';

const OverflowIcon = ({ storeRef, onMouseEnter, onMouseLeave }) => {
  return (
    <div
      className="fillable-field__overflow-indicator"
      ref={storeRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
};

OverflowIcon.propTypes = {
  storeRef: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};

export default OverflowIcon;
