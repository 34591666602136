import PropTypes from 'prop-types';
import React from 'react';

const getWidth = (frameSize, framePadding) => {
  return Math.floor(frameSize.width + (framePadding * 2));
};

const getHeight = (frameSize, framePadding) => {
  return Math.floor(frameSize.height + framePadding);
};

const PageFrameContinious = (props) => {
  const {
    frameSize,
    framePadding,
    frameOffset,
    children,
  } = props;
  return (
    <div
      className="pageFrame-PageFrame"
      style={{
        padding: framePadding,
        paddingBottom: 0,
        width: getWidth(frameSize, framePadding),
        height: getHeight(frameSize, framePadding),
        marginLeft: frameOffset.left,
      }}
    >
      {children}
    </div>
  );
};

PageFrameContinious.propTypes = {
  children: PropTypes.element.isRequired,
  frameOffset: PropTypes.shape({
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
  }).isRequired,
  frameSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  framePadding: PropTypes.number.isRequired,
};

export default PageFrameContinious;
