import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { thisDevice } from '@pdffiller/jsf-useragent';

import { toolbar } from '../../../../../store/selectors';
import { setActivePopupMenu } from '../../../../../store/modules/viewport';

import setColor from '../../../../../store/thunks/setColor';
import forceFocusTextElement from '../../../../../store/thunks/forceFocusTextElement';
import { popupMenu } from '../../../../../helpers/const';
import { stopEventPropagation } from '../../../../../helpers/utils';
import ColorPickerMobile from './ColorPickerMobile';
import ColorPickerDesktop from './ColorPickerDesktop';

import * as Ui from '../../../../../ui';

export class ColorPopupSettingsSimple extends Component {
  static propTypes = {
    setActivePopupMenu: PropTypes.func.isRequired,
    colorKey: PropTypes.string.isRequired,
    locale: PropTypes.shape({
      basicColors: PropTypes.string.isRequired,
      moreColors: PropTypes.string.isRequired,
      back: PropTypes.string.isRequired,
    }).isRequired,

    //
    setColor: PropTypes.func.isRequired,
    forceFocusTextElement: PropTypes.func,
    closePortal: PropTypes.func,
    color: PropTypes.string.isRequired,
  };

  static defaultProps = {
    forceFocusTextElement: null,
    closePortal: null,
  };

  backToMain = () => {
    this.props.setActivePopupMenu(popupMenu.main);
  };

  onDesktopPopupClose = () => {
    this.backToMain();
    this.props.closePortal();
  };

  render() {
    const { locale } = this.props;
    const textWeight = thisDevice.isDesktop
      ? Ui.text.weights.regular
      : null;
    const offset = thisDevice.isDesktop
      ? Ui.control.offsets.medium
      : null;

    return (
      <Ui.Slider.ContentCell
        onTouchStart={stopEventPropagation}
      >

        <Ui.ToolBar.Body
          size={Ui.toolBar.sizes.medium}
          theme={Ui.toolBar.themes.solidLightgray}
          offset={Ui.toolBar.offsets.extraSmallHorizontal}
        >
          <Ui.ToolBar.Main>
            <Ui.ToolBar.Group>
              <Ui.Container.SettingButton
                icon={Ui.icon.types.arrowLeft}
                theme={Ui.control.themes.transparentBlue}
                behavior={Ui.control.behaviors.null}
                size={Ui.icon.sizes.small}
                label={locale.back}
                textSize={15}
                onClick={this.backToMain}
                textWeight={textWeight}
                offset={offset}
              />
            </Ui.ToolBar.Group>
          </Ui.ToolBar.Main>
        </Ui.ToolBar.Body>

        <Ui.ScrollBox>
          {thisDevice.isMobile && (<ColorPickerMobile
            colorKey={this.props.colorKey}
            color={this.props.color}
            locale={this.props.locale}
            setColor={this.props.setColor}
          />)}
          {thisDevice.isDesktop && (<ColorPickerDesktop
            color={this.props.color}
            colorKey={this.props.colorKey}
            setColor={this.props.setColor}
            forceFocusTextElement={this.props.forceFocusTextElement}
            closePortal={this.onDesktopPopupClose}
          />)}
        </Ui.ScrollBox>

      </Ui.Slider.ContentCell>
    );
  }
}

export default connect((state, ownProps) => {
  const { colorKey } = ownProps;

  // get color and valid color key
  const { colorKey: validColorKey, color } = toolbar.getValidColorPropsFactory(colorKey)(state);
  return {
    colorKey: validColorKey,
    color,
  };
}, {
  setColor,
  forceFocusTextElement,
  setActivePopupMenu,
})(ColorPopupSettingsSimple);
