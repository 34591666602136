import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'jsfcore/jsf-localization';
import * as Ui from 'jsfcore/ui';

const ConstructorValidation = ({ errorMessage }) => {
  return (
    <FormattedMessage id={errorMessage}>
      {(formattedMessage) => {
        return (
          <Ui.PopoverItem.Index offset={Ui.popoverItem.offsets.large}>
            <Ui.PopoverItem.Body className="item__body">
              <Ui.Group.Index
                type={Ui.group.types.compact}
                offset={Ui.group.offsets.small}
                align={Ui.group.aligns.flexStart}
              >
                <Ui.Group.Body>
                  <Ui.Group.Item>
                    <Ui.Icon
                      size={Ui.icon.sizes.extraLarge}
                      type={Ui.icon.types.circledExclamationThin}
                      theme={Ui.icon.themes.dovegray}
                    />
                  </Ui.Group.Item>
                  <Ui.Group.Item>
                    <Ui.Text
                      size={13}
                      theme={Ui.text.themes.dovegray}
                      lineHeight={Ui.text.lineHeights.medium}
                    >
                      {formattedMessage}
                    </Ui.Text>
                  </Ui.Group.Item>
                </Ui.Group.Body>
              </Ui.Group.Index>
            </Ui.PopoverItem.Body>
          </Ui.PopoverItem.Index>
        );
      }}
    </FormattedMessage>
  );
};

ConstructorValidation.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};


export default ConstructorValidation;
