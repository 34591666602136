import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import clone from 'lodash/clone';
import { addElement, trackPoint } from 'ws-editor-lib/actions';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { selectors, thunks } from '../../../..';

import { cancellableOpts } from '../../../../store/modules/undoRedo';
import { setIsDrawingNewGraphicElement } from '../../../../store/modules/events';

import { isHorizontalGraphicType, isDrawRectType } from '../../../../helpers/elemTypes';
import { stopEvent } from '../../../../helpers/utils';
import { toolsTrackPoints } from '../../../../helpers/const';
import { getPureViewbox, getEdgePoints } from '../../../../helpers/graphicUtils';

import { getEventPos } from '../../../../helpers/dragUtils';
import { getScrollDirection, scrollInterval,
  getScrollObject } from '../../../../helpers/scrollOnDragUtils';
import { getRenderPoint } from '../drawingLayerUtils';

import DrawingLayerCurveView from './DrawingLayerCurveView';

// for tests only
export const initialState = {
  isDrawing: false,
  drawPoints: false,

  // need these two for maybeScroll()
  dragStartPoint: { x: 0, y: 0 },
  dragEndPoint: { x: 0, y: 0 },
};

@connect(
  (__, { pageId }) => {
    return (state) => {
      return {
        scale: selectors.getScale(state, pageId),
        frameSize: selectors.getFrameSize(state, pageId),
        ghost: selectors.base.getGhostElement(state),
        isDrawingNewGraphicElement: selectors.base.getIsDrawingNewGraphicElement(state),
        pagePinching: state.events.pagePinching,
      };
    };
  }, {
    addElement,
    setActiveElement: thunks.setActiveElement,
    setIsDrawingNewGraphicElement,
    trackPoint,
  },
)
export default class DrawingLayerCurve extends Component {
  static propTypes = {
    // from global state
    pageId: PropTypes.number.isRequired,
    scale: PropTypes.number.isRequired,
    frameSize: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }).isRequired,
    ghost: PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.shape({
        lineWidth: PropTypes.number.isRequired,
        fillColor: PropTypes.string.isRequired,
        fillAlpha: PropTypes.number,
      }),
      type: PropTypes.string.isRequired,
    }).isRequired,

    isDrawingNewGraphicElement: PropTypes.bool.isRequired,
    pagePinching: PropTypes.bool.isRequired,

    // actions
    addElement: PropTypes.func.isRequired,
    setActiveElement: PropTypes.func.isRequired,
    setIsDrawingNewGraphicElement: PropTypes.func.isRequired,
    trackPoint: PropTypes.func.isRequired,
  };

  static contextTypes = {
    changePageScroll: PropTypes.func,
    getPageViewport: PropTypes.func,
    getEvents: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      drawPoints: false,
    };
    // DraggableCore на таблетах дважды вызывает onDragStop,
    // поэтому решили объединять вызовы, чтобы не появлялись дубликаты элементов
    if (thisDevice.isTablet) {
      this.onDragStop = debounce(this.onDragStop, 100);
    }
  }

  componentDidUpdate() {
    if (this.state.isDrawing) {
      this.maybeScroll();
    }
  }

  addElement = () => {
    const { ghost, pageId, scale } = this.props;

    const { lineWidth } = ghost.content;

    const controlPoints = this.state.drawPoints.map((val) => {
      return val / scale;
    });

    // fix for zero-length curve: draw it anyway (but we need at least 2 points for drawing)
    if (controlPoints.length === 2) {
      controlPoints.push(...controlPoints);
    }

    const viewbox = getPureViewbox(controlPoints, lineWidth);

    const elem = {
      ...ghost,
      pageId,
      content: {
        ...ghost.content,
        ...viewbox,
        controlPoints,
      },
    };

    this.props.trackPoint(toolsTrackPoints.ADDED_SIMPLE_TOOL);
    this.props.addElement(elem, cancellableOpts);
    this.props.setActiveElement(ghost.id, true);
  };

  maybeScroll = throttle(() => {
    if (!this.context.changePageScroll || !this.state.isDrawing) {
      return;
    }

    const viewport = this.context.getPageViewport();
    const scrollDirection = getScrollDirection(this.state, viewport);
    if (!scrollDirection) {
      return;
    }

    const scrollObject = getScrollObject(scrollDirection, viewport);
    this.context.changePageScroll(scrollObject);
  }, scrollInterval);

  onDragStart = (event) => {
    const eventPos = getEventPos(event, this.context.getEvents());
    if (!eventPos) {
      return;
    }

    // нужно для корректной работы Highlight/Erase/Blackout tools в FireFox
    // если установить компонент просто кликом, то после этого с него слетал фокус
    // https://pdffiller.atlassian.net/browse/JSF-5630
    // поэтому устанавливается isDrawingNewGraphicElement в true
    // как в onDragMove
    if (thisDevice.isFirefoxDesktop && !this.props.isDrawingNewGraphicElement) {
      this.props.setIsDrawingNewGraphicElement(true);
    }

    const { frameOffset } = this.context.getPageViewport();

    const pointToRender = getRenderPoint(eventPos, this.context.getPageViewport(), this.props);
    this.setState({
      drawPoints: [...pointToRender, ...pointToRender],
      dragStartPoint: eventPos,
      dragEndPoint: eventPos, // that's ok, we start to process at the very beginning of drag
      initialOffset: clone(frameOffset),
      isDrawing: true,
    });
  };

  onDragMove = (event) => {
    if (!this.state.isDrawing) {
      return;
    }
    // To prevent draw during pinching
    if (this.props.pagePinching) {
      this.setState(initialState);
      return;
    }

    stopEvent(event);
    if (!this.props.isDrawingNewGraphicElement) {
      this.props.setIsDrawingNewGraphicElement(true);
    }

    const eventPos = getEventPos(event, this.context.getEvents());
    if (!eventPos) {
      return;
    }

    const eventRenderPoint = getRenderPoint(eventPos, this.context.getPageViewport(), this.props);
    const newPoints = [...this.state.drawPoints, ...eventRenderPoint];
    const isHorizontal = isHorizontalGraphicType(this.props.ghost.type);
    this.setState({
      drawPoints: isHorizontal
        ? getEdgePoints(newPoints)
        : newPoints,

      dragEndPoint: eventPos,
    });
  };

  onDragStop = () => {
    // when dragStop shoot, Content::onClick shoots too.
    // to prevent Content's onClick, we reset drag after a small delay
    setTimeout(() => {
      return this.props.setIsDrawingNewGraphicElement(false);
    }, 100);

    if (this.state.isDrawing) {
      this.addElement();
      // we don't need to set initial state in case isDrawing === false
      // it's already set as initial in onDragMove event
      this.setState(initialState);
    }
  };

  render() {
    const { scale, ghost } = this.props;
    const { fillColor, lineWidth, fillAlpha } = ghost.content;
    return (
      <DrawingLayerCurveView
        onDragStart={this.onDragStart}
        onDragMove={this.onDragMove}
        onDragStop={this.onDragStop}
        frameSize={this.props.frameSize}
        fillColor={fillColor}
        fillAlpha={fillAlpha}
        lineWidth={lineWidth * scale}
        controlPoints={this.state.drawPoints}
        isRect={isDrawRectType(ghost.type)}
      />
    );
  }
}
