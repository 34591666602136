import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { editType, logoUrls } from '../../../helpers/const';
import * as Ui from '../../../ui';
import onDoneButtonOptionClickFactory, { LOGO_ID } from '../../../store/thunks/onDoneButtonOptionClickFactory';

const getLogoUiPropsByUrls = (logo) => {
  const logoUiPropsByDevice = thisDevice.isTablet
    ? {
      size: Ui.logo.sizes.extraSmall,
      offset: Ui.logo.offsets.extraSmallHorizontal,
      behavior: Ui.logo.behaviors.centeredContent,
    }
    : {
      behavior: Ui.logo.behaviors.centeredContent,
      size: Ui.logo.sizes.large,
    };

  if (logo && !logo.urls.large) {
    return {
      ...logoUiPropsByDevice,
      offset: Ui.logo.offsets.smallHorizontal,
      size: Ui.logo.sizes.extraSmall,
    };
  }

  return logoUiPropsByDevice;
};

const getLogoUrl = (logo) => {
  if (!logo) {
    return null;
  }

  if (thisDevice.isTablet) {
    return (
      logo.urls.small
        ? logo.urls.small
        : logo.urls.large
    );
  }
  return logo.urls.large
    ? logo.urls.large
    : logo.urls.small;
};

const LOGO_ALT = 'PDFfiller logo';

@connect((state) => {
  const mode = state.query.editType;

  return {
    logo: get(
      state,
      'ws.features.logo',
      (mode === editType.l2f || mode === editType.s2s || mode === editType.as)
        ? null
        : logoUrls,
    ),
    logoVisible: get(state, 'ws.features.logo.visible', true),
  };
},
{
  onDoneButtonOptionClickFactory,
})
export default class HeaderLogo extends Component {
  static propTypes = {
    logo: PropTypes.shape({
      urls: PropTypes.shape({
        large: PropTypes.string,
        small: PropTypes.string,
      }),
    }),

    logoVisible: PropTypes.bool.isRequired,
    onDoneButtonOptionClickFactory: PropTypes.func.isRequired,
  };

  static defaultProps = {
    logo: null,
  };

  handleOnLogoClick = this.props.onDoneButtonOptionClickFactory({ id: LOGO_ID });

  render() {
    if (!this.props.logoVisible) {
      return null;
    }

    return (
      <Ui.Logo.Index
        onClick={this.handleOnLogoClick}
        {...getLogoUiPropsByUrls(this.props.logo)}
      >
        <Ui.Logo.Media
          logoUrl={getLogoUrl(this.props.logo)}
          alt={LOGO_ALT}
          size={thisDevice.isDesktop
            ? Ui.media.sizes.parent
            : Ui.media.sizes.parentHeight
          }
          type={Ui.media.types.image}
        />
      </Ui.Logo.Index>
    );
  }
}
