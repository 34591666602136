import memoize from 'lodash/memoize';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { DraggableCore } from 'react-draggable';
import classnames from 'classnames';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { focusControllerDraggableDecorator } from '@pdffiller/jsf-focuscontroller';
import { mouseFocusClassName } from '../../../helpers/const';
import * as Ui from '../../../ui';

const getDecoratedDraggableCore = memoize(() => {
  return focusControllerDraggableDecorator(thisDevice)(DraggableCore);
});

const stopEvent = (event) => {
  event.stopPropagation();
};

export default class ElementTooltipView extends Component {
  static propTypes = {
    isDragging: PropTypes.bool.isRequired,
    haveSizeButtons: PropTypes.bool.isRequired,
    sizeIcons: PropTypes.shape({
      more: PropTypes.string.isRequired,
      less: PropTypes.string.isRequired,
    }),
    style: PropTypes.shape({}),
    onClickOk: PropTypes.func,
    onMouseDownSizeUp: PropTypes.func,
    onMouseDownSizeDown: PropTypes.func,
    onClickRemove: PropTypes.func,
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,

    onDragStart: PropTypes.func.isRequired,
    onDragMove: PropTypes.func.isRequired,
    onDragStop: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onClickOk: () => {
      return 0;
    },
    onMouseDownSizeUp: () => {
      return 0;
    },
    onMouseDownSizeDown: () => {
      return 0;
    },
    onClickRemove: () => {
      return 0;
    },
    sizeIcons: {},
    style: {},
  };

  render() {
    const DecoratedDraggableCore = getDecoratedDraggableCore();

    return (
      <div
        className={classnames(
          mouseFocusClassName, {
            active: this.props.isDragging,
          },
        )}
        style={this.props.style}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        onMouseDown={
          /**
           * Проблема появилась после переноса Tooltip'a в
           * Portal в Safari из-за того, что событие долетает в
           * ClickController и деактивирует элемент при попытке
           * перемещения
           * Safari почти везде использует mouseDown
           */
          thisDevice.isSafariDesktop
            ? stopEvent
            : null
        }
      >
        <Ui.ToolBar.Body
          size={Ui.toolBar.sizes.extraSmall}
          theme={Ui.toolBar.themes.lite}
          type={Ui.toolBar.types.narrow}
        >
          <Ui.ToolBar.Main>
            <Ui.ToolBar.Group>
              <Ui.ToolBar.Item>
                <DecoratedDraggableCore
                  enableUserSelectHack={false}
                  onStart={this.props.onDragStart}
                  onDrag={this.props.onDragMove}
                  onStop={this.props.onDragStop}
                >
                  <Ui.Control
                    inheritRadius
                    behavior={Ui.control.behaviors.column}
                    size={Ui.control.sizes.parent}
                    theme={Ui.control.themes.transparentGray}
                    icon={
                      <Ui.Icon
                        type={Ui.icon.types.move}
                        size={Ui.icon.sizes.small}
                      />
                    }
                  />
                </DecoratedDraggableCore>
              </Ui.ToolBar.Item>

              <Ui.ToolBar.Item>
                <Ui.Control
                  onClick={this.props.onClickOk}
                  inheritRadius
                  behavior={Ui.control.behaviors.column}
                  size={Ui.control.sizes.parent}
                  theme={Ui.control.themes.transparentGray}
                  icon={
                    <Ui.Icon
                      type={Ui.icon.types.ok}
                      size={Ui.icon.sizes.small}
                    />
                  }
                />
              </Ui.ToolBar.Item>
              {this.props.haveSizeButtons &&
                <Fragment>
                  <Ui.ToolBar.Item>
                    <Ui.Control
                      onMouseDown={this.props.onMouseDownSizeUp}
                      inheritRadius
                      behavior={Ui.control.behaviors.column}
                      size={Ui.control.sizes.parent}
                      theme={Ui.control.themes.transparentGray}
                      icon={
                        <Ui.Icon
                          type={Ui.icon.types.textGrow}
                          size={Ui.icon.sizes.small}
                        />
                      }
                    />
                  </Ui.ToolBar.Item>
                  <Ui.ToolBar.Item>
                    <Ui.Control
                      onMouseDown={this.props.onMouseDownSizeDown}
                      inheritRadius
                      behavior={Ui.control.behaviors.column}
                      size={Ui.control.sizes.parent}
                      theme={Ui.control.themes.transparentGray}
                      icon={
                        <Ui.Icon
                          type={Ui.icon.types.textReduce}
                          size={Ui.icon.sizes.small}
                        />
                      }
                    />
                  </Ui.ToolBar.Item>
                </Fragment>
              }
              <Ui.ToolBar.Item>
                <Ui.Control
                  onClick={this.props.onClickRemove}
                  inheritRadius
                  behavior={Ui.control.behaviors.column}
                  size={Ui.control.sizes.parent}
                  theme={Ui.control.themes.transparentGray}
                  icon={
                    <Ui.Icon
                      type={Ui.icon.types.trash}
                      size={Ui.icon.sizes.small}
                    />
                  }
                />
              </Ui.ToolBar.Item>
            </Ui.ToolBar.Group>
          </Ui.ToolBar.Main>
        </Ui.ToolBar.Body>
      </div>
    );
  }
}
