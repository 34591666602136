import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { directions } from '../../../../helpers/graphicUtils';

import * as Ui from '../../../../ui';
import { toolbar } from '../../../../store/selectors';
import forceFocusTextElement from '../../../../store/thunks/forceFocusTextElement';
import setToolbarAttributeValue from '../../../../store/thunks/setToolbarAttributeValue';

const iconMap = {
  [directions.forward]: Ui.icon.types.arrowUpright,
  [directions.backward]: Ui.icon.types.arrowDownleft,
  [directions.double]: Ui.icon.types.arrowUprightDownleft,
};

export const directionMapStateToProps = (state) => {
  const { direction: value } = toolbar.getElementPropsForToolbar(state);
  return {
    value,
  };
};

@connect(directionMapStateToProps, {
  forceFocusTextElement,
  setToolbarAttributeValue,
})
export default class DirectionToolbarSettings extends Component {
  static propTypes = {
    direction: PropTypes.string.isRequired,
    setToolbarAttributeValue: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    forceFocusTextElement: PropTypes.func,
  };

  static defaultProps = {
    forceFocusTextElement: () => {},
  };

  onClick = () => {
    this.props.setToolbarAttributeValue('direction', this.props.direction);
    this.props.forceFocusTextElement();
  };

  render() {
    const { value, direction } = this.props;
    const icon = iconMap[direction];

    return (
      <Ui.Container.SettingButton
        icon={icon}
        isPressed={direction === value}
        onClick={this.onClick}
        inheritRadius
      />
    );
  }
}
