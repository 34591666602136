let wrapper = null;

const initializeWrapper = () => {
  wrapper = document.createElement('div');
  Object.assign(wrapper.style, {
    opacity: 0,
    width: 0,
    height: 0,
    lineHeight: 0,
    overflow: 'hidden',
    position: 'absolute',
  });
  document.body.appendChild(wrapper);
};

export default ({
  text = '',
  fontFamily = null,
  bold = null,
  italic = null,
  weight = null,
}) => {
  if (!__CLIENT__) {
    return null;
  }

  const span = document.createElement('span');
  Object.assign(span.style, {
    position: 'absolute',
    top: '-500px',
    fontSize: '72px',
    ...(fontFamily && { fontFamily }),
    ...(bold && { fontWeight: 'bold' }),
    ...(italic && { fontStyle: 'italic' }),
    ...(weight && { fontWeight: weight }),
  });
  span.innerText = text;

  if (!wrapper) {
    initializeWrapper();
  }
  wrapper.appendChild(span);
  return span;
};
