import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as conditionsSelectors from '../selectors';

const style = {
  width: '100%',
  height: '100%',
  background: 'rgba(255, 255, 255, 0.5)',
  position: 'fixed',
  top: 50,
  left: 0,
  zIndex: 10,
};

@connect(
  (state) => {
    return {
      choiseByClickingModeIsActive: conditionsSelectors.getChoiseByClickingModeIsActive(state),
    };
  },
)
export default class ChoiseByClickingOpacity extends Component {
  static propTypes = {
    choiseByClickingModeIsActive: PropTypes.bool.isRequired,
  };

  render() {
    if (this.props.choiseByClickingModeIsActive) {
      return (
        <div style={style} />
      );
    }

    return null;
  }
}
