import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { locale as localeSelectors } from '../../../../store/selectors';
import { onOkClicked } from '../../../../store/modules/events';

import * as Ui from '../../../../ui';

@connect(
  (state) => {
    return {
      locale: localeSelectors.getEditorLocale(state).toolbarAttributes.OK,
    };
  }, { onOkClicked },
)
export default class TabletOkBtn extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    onOkClicked: PropTypes.func.isRequired,
  };

  render() {
    const { locale } = this.props;

    return (
      <Ui.Container.GridCellAndWrapper>
        <Ui.EditorAction.Index
          size={Ui.editorAction.sizes.null}
        >
          <Ui.EditorAction.Item>
            <Ui.Group.Index
              size={Ui.group.sizes.parent}
              type={Ui.group.types.compact}
              offset={Ui.group.offsets.small}
            >
              <Ui.Group.Body>
                <Ui.Group.Item
                  attributes={Ui.attributes.grow}
                >
                  <Ui.Control
                    theme={Ui.control.themes.primary}
                    size={Ui.control.sizes.medium}
                    offset={Ui.control.offsets.extraLarge}
                    onClick={this.props.onOkClicked}
                  >
                    <Ui.Text
                      weight={Ui.text.weights.bold}
                      size={13}
                      transform={Ui.text.transforms.uppercase}
                    >
                      {locale}
                    </Ui.Text>
                  </Ui.Control>
                </Ui.Group.Item>
              </Ui.Group.Body>
            </Ui.Group.Index>
          </Ui.EditorAction.Item>
        </Ui.EditorAction.Index>
      </Ui.Container.GridCellAndWrapper>
    );
  }
}
