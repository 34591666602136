import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class ContentEditable extends Component {
  static propTypes = {
    tagName: PropTypes.string,
    html: PropTypes.string.isRequired,
    disabled: PropTypes.bool,

    onChange: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
  }

  static defaultProps = {
    tagName: 'div',
    disabled: false,
    onBlur: null,
  };

  constructor(props) {
    super(props);

    this.htmlEl;
    // bind this for correct get a ref for inner html
    this.emitChange = this.emitChange.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    const { props, htmlEl } = this;

    // We need not rerender if the change of props simply reflects the user's edits.
    // Rerendering in this case would make the cursor/caret jump

    // Rerender if there is no element yet... (somehow?)
    if (!htmlEl) {
      return true;
    }

    // ...or if html really changed... (programmatically, not by user edit)
    if (nextProps.html !== htmlEl.textContent) {
      return true;
    }

    const optional = ['style', 'className', 'disabled', 'tagName'];

    // Handle additional properties
    return optional.some((name) => {
      return props[name] !== nextProps[name];
    });
  }

  componentDidUpdate() {
    // You gotta use only "textContent" (not "innerHTML") in this file because there is a
    // trouble in EDGE 16.16299: it puts <br> into an empty tag (JSF-2888)
    if (this.htmlEl && this.props.html !== this.htmlEl.textContent) {
      // Perhaps React (whose VDOM gets outdated because we often prevent
      // rerendering) did not update the DOM. So we update it manually now.
      this.htmlEl.textContent = this.props.html;
    }
  }

  emitChange(event) {
    if (!this.htmlEl) {
      return;
    }
    const html = this.htmlEl.textContent;
    if (this.props.onChange && html !== this.lastHtml) {
      event.target = { value: html }; // eslint-disable-line no-param-reassign
      this.props.onChange(event);
    }
    this.lastHtml = html;
  }

  storeRef = (ref) => {
    this.htmlEl = ref;
  }

  onBlur = (event) => {
    this.emitChange(event);
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  render() {
    const { tagName: Tag, html, ...props } = this.props;

    return (
      <Tag
        {...props}
        ref={this.storeRef}
        onInput={this.emitChange}
        onBlur={this.onBlur}
        onKeyDown={props.onKeyDown}
        contentEditable={!this.props.disabled}

        // Родительский компонент содержит escape()
        //
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  }
}
