import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  isSignature,
  isPicture,
} from '../../../store/helpers/functions';
import { elemSubTypes, elemTypes } from '../../../helpers/elemTypes';
import { getValidColor } from '../../../helpers/graphicUtils';
import { SvgCheck, SvgCircle, SvgCross } from '../../Tools/SVGTool/Svgs';
import ConstructorToolView from './ConstructorToolView';

import { checkMarkSubTypes, positions } from '../../../helpers/const';
import ImagePlaceholder from '../../../ui/ImagePlaceholder/ImagePlaceholder';

const placeholderMinWidth = {
  [elemTypes.image]: 82,
  [elemTypes.signature]: 115,
};

const placeHolderText = {
  signature: 'Signature',
  initials: 'Initials',
  image: 'Image',
};

export default class ConstructorTool extends Component {
  static propTypes = {
    elementIcon: PropTypes.element,
    element: PropTypes.shape({
      type: PropTypes.string.isRequired,
      subType: PropTypes.string.isRequired,
      template: PropTypes.shape({
        width: PropTypes.number,
        initial: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.bool,
        ]),
        color: PropTypes.string,
      }),
      content: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
        height: PropTypes.number,
        width: PropTypes.number,
        required: PropTypes.bool,
      }),
    }).isRequired,
    scale: PropTypes.number.isRequired,
    isDragging: PropTypes.bool.isRequired,
    resizeIndex: PropTypes.number,
    isActiveElement: PropTypes.bool.isRequired,
    isChoiseEnabled: PropTypes.bool.isRequired,

    style: PropTypes.shape({}),
    onResizeDragStart: PropTypes.func,
    onResizeDragMove: PropTypes.func,
    onResizeDragStop: PropTypes.func,

    onClickChoiseByClicking: PropTypes.func,
    onMouseDownChoiseByClicking: PropTypes.func,

    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    storeRef: PropTypes.func.isRequired,
    interceptFocusDataset: PropTypes.shape({}),
  };

  static defaultProps = {
    elementIcon: null,
    resizeIndex: positions.none,

    style: null,

    interceptFocusDataset: undefined,

    onResizeDragStart: () => {},
    onResizeDragMove: () => {},
    onResizeDragStop: () => {},

    onClickChoiseByClicking: () => {},
    onMouseDownChoiseByClicking: () => {},
  };

  getCheckMarkIcon = () => {
    const { subType, template, content } = this.props.element;
    const { color } = template;

    if (!content || !Number(template.initial)) {
      return false;
    }

    switch (subType) {
      case checkMarkSubTypes.x:
        return <SvgCross color={getValidColor(color)} />;
      case checkMarkSubTypes.o:
        return <SvgCircle color={getValidColor(color)} />;
      case checkMarkSubTypes.v:
        return <SvgCheck color={getValidColor(color)} />;
      default:
        return false;
    }
  };

  getInnerText = () => {
    const { type, subType, template: { width } } = this.props.element;
    if (width <= placeholderMinWidth[type]) {
      return null;
    }

    if (type === elemTypes.signature) {
      return subType === elemSubTypes.signature.initials
        ? placeHolderText.initials
        : placeHolderText.signature;
    }

    if (type === elemTypes.image) {
      return placeHolderText.image;
    }

    return null;
  };

  canBeChecked = (type) => {
    return type === elemTypes.checkmark;
  };

  renderScaledIcon = () => {
    const { elementIcon, element } = this.props;

    if (isSignature(element) || isPicture(element)) {
      return (
        <div
          className="fillable-field__overlay"
        >
          {elementIcon}
          <ImagePlaceholder innerText={this.getInnerText()} />
        </div>
      );
    }

    return elementIcon;
  };

  render() {
    const {
      isDragging,
      resizeIndex,
      scale,
      style,
      isChoiseEnabled,
      isActiveElement,
      element,
    } = this.props;

    return (
      <ConstructorToolView
        isPicture={isPicture(element)}
        isSignature={isSignature(element)}
        onClick={this.props.onClickChoiseByClicking}
        onMouseDown={this.props.onMouseDownChoiseByClicking}
        style={style}
        isChoiseEnabled={isChoiseEnabled}
        icon={this.renderScaledIcon()}
        isDragging={isDragging}
        resizeIndex={resizeIndex}
        scale={scale}
        isActiveElement={isActiveElement}
        onResizeDragStart={this.props.onResizeDragStart}
        onResizeDragMove={this.props.onResizeDragMove}
        onResizeDragStop={this.props.onResizeDragStop}
        checkMarkIcon={this.canBeChecked(element.type) && this.getCheckMarkIcon()}

        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        storeRef={this.props.storeRef}
        interceptFocusDataset={this.props.interceptFocusDataset}
      />
    );
  }
}
