import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { defaultMemoize } from 'reselect';
import { connect } from 'react-redux';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { withFocusControllerContext } from '@pdffiller/jsf-focuscontroller';

import { changeScale } from '../../../store/modules/navigation';
import { SCALE_FIT_WIDTH } from '../../../store/modules/navigationActionTypes';

import * as selectors from '../../../store/selectors';
import { defaultZoomScale, zeroSpace } from '../../../helpers/const';
import HeaderZoomView from './HeaderZoomView';
import { setKeyboardShown, setKeyboardSize } from '../../../store/modules/viewport';

@connect(
  (state) => {
    const activePageId = selectors.base.getActivePageId(state);

    return {
      activePageId,
      scale: state.navigation.headerScales[activePageId],
      scaleValue: state.navigation.scales[activePageId],
      locale: selectors.locale.getEditorLocale(state).headerZoom,
    };
  }, {
    changeScale,
    setKeyboardSize,
    setKeyboardShown,
  },
)
class HeaderZoom extends Component {
  static propTypes = {
    // actions
    changeScale: PropTypes.func.isRequired,
    locale: PropTypes.shape({
      fitPage: PropTypes.string.isRequired,
      fitWidth: PropTypes.string.isRequired,
      zoom: PropTypes.string.isRequired,
    }).isRequired,

    // from global.state
    activePageId: PropTypes.number.isRequired,
    scale: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    scaleValue: PropTypes.number,
    setKeyboardShown: PropTypes.func.isRequired,
    setKeyboardSize: PropTypes.func.isRequired,
    restoreFocus: PropTypes.func.isRequired,
  };

  static defaultProps = {
    scale: defaultZoomScale,
    scaleValue: defaultZoomScale,
  };

  handleChange = (option) => {
    if (this.props.scale !== option.value) {
      this.props.changeScale(option.value, this.props.activePageId);
    }
  };

  iPadKeyboardHandler = (isPortalOpen) => {
    if (thisDevice.isIOS && thisDevice.isTablet) {
      if (!isPortalOpen) {
        this.props.setKeyboardShown(false);
        this.props.setKeyboardSize(zeroSpace);
      } else {
        this.props.restoreFocus();
      }
    }
  };

  onClickHandlerCreator = defaultMemoize((isPortalOpen, togglePortal) => {
    return () => {
      togglePortal();
      this.iPadKeyboardHandler(isPortalOpen);
    };
  });

  render() {
    return (
      <HeaderZoomView
        scaleValue={this.props.scaleValue}
        scale={this.props.scale || SCALE_FIT_WIDTH}
        isDisabled={!this.props.scale}
        onChange={this.handleChange}
        locale={this.props.locale}
        onClickHandlerCreator={this.onClickHandlerCreator}
      />
    );
  }
}

export default withFocusControllerContext(HeaderZoom);
