import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { selectors } from '../..';
import { textFieldViewModes } from '../../helpers/const';
import { isFillable } from '../../store/helpers/functions';

@connect(
  (_, { elementId }) => {
    const getElement =
      selectors.elements.getElementByIdFactory(elementId);

    return (state) => {
      return {
        element: getElement(state),
        defaultTextFieldViewMode: selectors.base.getDefaultViewMode(state),
      };
    };
  },
)
export default class ElementProvider extends Component {
  static propTypes = {
    element: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool,
    ]).isRequired,
    children: PropTypes.func.isRequired,
    defaultTextFieldViewMode: PropTypes.string.isRequired,
  };

  render() {
    const { element, defaultTextFieldViewMode } = this.props;
    return this.props.children({
      element,
      textFieldViewMode: isFillable(element)
        ? defaultTextFieldViewMode
        : textFieldViewModes.classic,
    });
  }
}
