import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { stopEvent } from '../../../helpers/utils';
import * as selectors from '../../../store/selectors';
import { cancellableOpts } from '../../../store/modules/undoRedo';
import * as Ui from '../../../ui';

export const removeButtonTypes = {
  clear: 'clear',
  delete: 'delete',
};

const settings = {
  [removeButtonTypes.clear]: {
    iconType: Ui.icon.types.clear,
    controlTheme: Ui.control.themes.primary,
  },
  [removeButtonTypes.delete]: {
    iconType: Ui.icon.types.trash,
    controlTheme: Ui.control.themes.success,
  },
};

export default class RemoveButton extends Component {
  static propTypes = {
    onMouseDown: PropTypes.func.isRequired,
    callbackParameters: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.oneOf(
      Object.values(removeButtonTypes),
    ).isRequired,
  };

  static defaultProps = {
    callbackParameters: [cancellableOpts],
  };

  static contextTypes = {
    store: PropTypes.shape({
      dispatch: PropTypes.func.isRequired,
      getState: PropTypes.func.isRequired,
    }).isRequired,
  }

  getActiveElementId = () => {
    const state = this.context.store.getState();
    const activeElementId = selectors.base.getActiveElementId(state);

    return activeElementId;
  };

  handleOnMouseDown = (event) => {
    const activeElementId = this.getActiveElementId();
    stopEvent(event);

    this.context.store.dispatch(
      this.props.onMouseDown(activeElementId, ...this.props.callbackParameters),
    );
  };

  render() {
    const { type } = this.props;

    return (
      <Ui.Box.Index
        isFocused
        theme={Ui.box.themes.primary}
      >
        <Ui.Box.Body>
          <Ui.Box.RemoveControl>
            <Ui.Control
              ariaLabel="Remove Field"
              inheritRadius
              attributes={Ui.attributes.redesignTrue}
              isBysu={false}
              icon={
                <Ui.Icon
                  size={Ui.icon.sizes.extraSmall}
                  type={settings[type].iconType}
                />
              }
              onMouseDown={this.handleOnMouseDown}
              size={Ui.control.sizes.parent}
              theme={settings[type].controlTheme}
            />
          </Ui.Box.RemoveControl>
        </Ui.Box.Body>
      </Ui.Box.Index>
    );
  }
}
