import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { excludeFromRestoreFocus } from '@pdffiller/jsf-focuscontroller';
import { presetsNames as wrapperPresets } from 'jsfcore/components/Portal/helpers/presets';
import { possiblePositions as wrapperPositions } from 'jsfcore/components/Portal/helpers/const';
import { selectors } from 'jsfcore';
import Hint from '../../../Hint/Hint';
import { tooltipTimeout } from '../../../../helpers/const';
import * as Ui from '../../../../ui';

const attributes = { ...excludeFromRestoreFocus, ...Ui.attributes.grow };

export const mapStateToProps = (state) => {
  return {
    locale: selectors.locale.getRearrangeButtonTooltip(state),
    disabled: selectors.getIsPdfBig(state),
  };
};

@connect(mapStateToProps)
export default class ToolbarRearrangeButton extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,

    onClickRearrange: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    // NOTE: Это воркэраунт вокруг циклических зависимострей
    this.hintWrapperProps = {
      locatorArgs: {
        position: wrapperPositions.bottomRightEdge,
        preset: wrapperPresets.hint,
      },
    };
  }

  render() {
    return (
      <Hint
        html={this.props.locale}
        timeout={tooltipTimeout}
        wrapperProps={this.hintWrapperProps}
      >
        {({ onMouseEnter, onMouseLeave, storeRef, children }) => {
          return (
            <Ui.Container.SettingButton
              inheritRadius
              isDisabled={this.props.disabled}
              icon={Ui.icon.types.pageRearrange}
              size={Ui.icon.sizes.large}
              textOffset={Ui.text.offsets.topExtraSmall}
              onClick={this.props.onClickRearrange}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              storeRef={storeRef}
              attributes={attributes}
            >
              {children}
            </Ui.Container.SettingButton>
          );
        }}
      </Hint>
    );
  }
}
