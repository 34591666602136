import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { selectors } from '../../..';
import PagePinch from '../PagePinch';

@connect(
  (__, { pageId }) => {
    return (state) => {
      return {
        frameOffset: selectors.getFrameOffset(state, pageId),
        frameSize: selectors.getFrameSize(state, pageId) || { width: 0, height: 0 },
        framePadding: state.viewport.workspace.framePadding,
      };
    };
  },
)
export default class PageFrame extends Component {
  static propTypes = {
    pageId: PropTypes.number.isRequired,
    updateScale: PropTypes.func.isRequired,

    // from global.state
    frameOffset: PropTypes.shape({
      top: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
    }).isRequired,
    frameSize: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }).isRequired,
    framePadding: PropTypes.shape({
      top: PropTypes.number.isRequired,
      right: PropTypes.number.isRequired,
      bottom: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
    }),
  };

  static defaultProps = {
    framePadding: {},
  }

  render() {
    const { frameSize, framePadding, pageId, frameOffset } = this.props;

    return (
      <div
        className="pageFrame-PageFrame"
        style={{
          boxSizing: 'content-box',
          width: frameSize.width,
          height: frameSize.height,
          paddingTop: framePadding.top,
          paddingRight: framePadding.right,
          paddingBottom: framePadding.bottom,
          paddingLeft: framePadding.left,
          marginTop: frameOffset.top,
          marginLeft: frameOffset.left,
        }}
      >
        <PagePinch pageId={pageId} updateScale={this.props.updateScale} />
      </div>
    );
  }
}
