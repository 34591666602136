import updateElement from './updateElement';
import { toolbar } from '../selectors';
import { colorKeys } from '../../helpers/const';

const setColor = (color, key = colorKeys.color) => {
  return (dispatch, getState) => {
    const { id } = toolbar.getElementPropsForToolbar(getState());
    return dispatch(updateElement(id, { [key]: color }));
  };
};

export default setColor;
