import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const DependencyClause = ({ operator, children, controls, box, inside }) => {
  return (
    <div
      className={cls(
        'dependency-clause',
        'dependency-clause--theme--primary',
        'dependency-clause--offset--small', {
          'dependency-clause--type--inside': inside,
        },
      )}
    >
      <div className="dependency-clause__body">
        {operator &&
          <div className="dependency-clause__operator">
            {operator}
          </div>
        }
        {(children || controls) &&
          <div className="dependency-clause__main">
            <div className="dependency-clause__content">
              {children}
            </div>
            <div className="dependency-clause__control-bar">
              {controls}
            </div>
          </div>
        }
        <div className="dependency-clause__box">
          {box}
        </div>
      </div>
    </div>
  );
};

DependencyClause.propTypes = {
  operator: PropTypes.element,
  children: PropTypes.element,
  controls: PropTypes.element,
  box: PropTypes.element,
  inside: PropTypes.bool,
};

DependencyClause.defaultProps = {
  operator: null,
  box: null,
  inside: false,
  children: null,
  controls: null,
};

export default DependencyClause;
