import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { updateElement } from 'ws-editor-lib/actions';
import { wizard, dispatchAction } from '@pdffiller/jsf-lazyload';
import { base } from '../../../store/selectors';
import { onDropdownToolOpen, onDropdownToolClose } from '../../../store/modules/events';

import { getStyle } from '../TextTool/utils/textToolUtils';
import { getDropdownAlign } from './dropdownToolUtils';
import SelectDropdown from './SelectDropdown';
import OptionOverriden from './OptionOverridden';
import valueRendererOverridden from './valueRendererOverridden';
import OptionRenderer from './OptionRenderer';

const mockFunc = () => {
  return null;
};

const mapStateToProps = (state) => {
  const isWizardLite = base.getIsWizardLite(state);
  return {
    isWizardLite,
  };
};

class DropdownTool extends Component {
  static propTypes = {
    element: PropTypes.shape({
      content: PropTypes.shape({}),
    }).isRequired,

    required: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isWizardLite: PropTypes.bool.isRequired,
    elementIcon: PropTypes.element,
    isActiveElement: PropTypes.bool.isRequired,
    onDropdownToolOpen: PropTypes.func.isRequired,
    onDropdownToolClose: PropTypes.func.isRequired,
    valign: PropTypes.string.isRequired,
    align: PropTypes.string.isRequired,

    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    storeRef: PropTypes.func.isRequired,
    interceptFocusDataset: PropTypes.shape({}),
  };

  static defaultProps = {
    isDisabled: false,
    required: false,
    elementIcon: null,

    interceptFocusDataset: undefined,
  };

  constructor(props) {
    super(props);
    // ref на комопонент
    this.reactSelectRef = null;
  }

  // eslint-disable-next-line camelcase
  componentDidUpdate(prevProps) {
    if (this.props.isActiveElement && !prevProps.isActiveElement) {
      this.openSelect();
    }
  }

  getSelectRef = () => {
    // two cases: Select and CreatableSelect
    return get(this.reactSelectRef, 'select', this.reactSelectRef);
  };

  getDropdownHandlersByMode = (isWizardLite) => {
    const { onFocus, onBlur, storeRef, interceptFocusDataset } = this.props;

    const selectHandlers = {
      onFocusSelectControl: onFocus,
      onBlurSelectControl: onBlur,
      // this crutch fixes bug in IE, something with FocusController could not find
      // лайт визард - при возврате фокуса на дд (табом или мышкой) - список скрывается (IE) (c)
      // look at comment in JSF-7857
      ...!thisDevice.isInternetExplorer11 && { storeRefSelectControl: storeRef },
    };
    const toolHandlers = { onFocus, onBlur, ref: storeRef, ...interceptFocusDataset };

    return isWizardLite
      ? { selectHandlers }
      : { toolHandlers };
  };

  openSelect = () => {
    if (!this.props.isWizardLite) {
      return;
    }

    const select = this.getSelectRef();
    if (select) {
      select.focus();
      select.openMenu();
    }
  };

  storeRef = (ref) => {
    this.reactSelectRef = ref;
  };

  onChange = () => {
    const { isWizardLite } = this.props;

    if (isWizardLite) {
      dispatchAction(wizard.actions.toggleWizardNext);
    }
  }

  render() {
    const {
      elementIcon,
      valign,
      align,
      isWizardLite,
    } = this.props;

    const alignStyles = getDropdownAlign({ align, valign });
    const { toolHandlers, selectHandlers } = this.getDropdownHandlersByMode(isWizardLite);
    const style = {
      ...getStyle({
        ...this.props,
        width: 'inherit',
        padding: 2,
      }, true),
    };

    const styleWithAlign = {
      ...style,
      ...alignStyles,
    };

    const selectDropdownOptions = {
      optionComponent: OptionOverriden,
      optionRenderer: (option, _, inputValue) => {
        return (
          <OptionRenderer label={option.label} inputValue={inputValue} />
        );
      },
      selectMenuOuterClassName: 'Select-menu-outer Select-menu-outer--theme--lightnull',
      inputProps: {
        className: 'fillable-field__input-control',
        autoComplete: 'new-password',
      },
      clearable: false,
      className: 'Select--type--fillable-field',
      ...thisDevice.isMobile && { menuRenderer: mockFunc },
      ...selectHandlers,
      // default name prop (valueRenderer) patched in jsfcore/components/Select/Select.js
      valueRend: valueRendererOverridden(styleWithAlign),
    };

    return (
      <div
        className={classnames('dropdownTool-DropdownTool', {
          'elementHover-Content': !this.props.isDisabled,
        })}
        style={{
          width: getStyle(this.props).width,
        }}
        tabIndex={0}
        {...toolHandlers}
      >
        <div className="fillable-field__body">
          <div className="fillable-field__container">
            <SelectDropdown
              {...this.props}
              onChange={this.onChange}
              storeRef={this.storeRef}
              disabled={!this.props.isWizardLite}
              placeholderStyle={{
                ...styleWithAlign,
                opacity: 0.5,
              }}
              selectOptions={{
                onOpen: this.props.onDropdownToolOpen,
                onClose: this.props.onDropdownToolClose,
                ...selectDropdownOptions,
                arrowRenderer: () => {
                  return elementIcon;
                },
                inputRenderer: (inputProps) => {
                  return (
                    <div
                      className={
                        classnames('Select-input fillable-field__input-control', {
                          active: this.props.isWizardLite,
                        })
                      }
                      // alignment for input child
                      style={{
                        height: style.height,
                        ...alignStyles,
                      }}
                    >
                      <input
                        style={{
                          ...style,
                          // is needed for IE
                          flexBasis: '100%',
                        }}
                        {...inputProps}
                      />
                    </div>
                  );
                },
              }}
            />
            {this.props.required && <div className="fillable-field__required-indicator" />}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, {
  onDropdownToolOpen,
  onDropdownToolClose,
  updateElement,
})(DropdownTool);
