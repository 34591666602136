import clamp from 'lodash/clamp';
import { getPureViewbox, drawArrow } from '../../../helpers/graphicUtils';
import { elemTypes } from '../../../helpers/elemTypes';
import { screenToFrame } from '../../../helpers/utils';

// NOTE: this function has test
/**
 * bound point according to framesize
 *
 * @param {number} x - x coord of point
 * @param {number} y - y coord of point
 * @param {object} frameSize - current frameSize
 * @param {number} lineWidth
 * @param {number} scale
 * @returns {object} - bounded point
 */
export const fixXY = ({ x, y }, frameSize, lineWidth, scale) => {
  const lw2 = lineWidth * (scale / 2);
  return {
    x: clamp(x, lw2, frameSize.width - lw2),
    y: clamp(y, lw2, frameSize.height - lw2),
  };
};

// NOTE: this function has test
/**
 * Check if given viewbox fits frameSize (whole viewbox is placed inside frameSize)
 *
 * @param {object} viewbox - { x, y, width, height } - incoming viewbox
 * @param {object} frameSize - { width, height } - framesize
 * @returns {bool} - true if viewbox is valid
 */
export const isViewboxValid = (viewbox, frameSize) => {
  return viewbox.x >= 0 &&
  viewbox.y >= 0 &&
  viewbox.x + viewbox.width <= frameSize.width &&
  viewbox.y + viewbox.height <= frameSize.height;
};

// NOTE: this function has test
/**
 * map controlPoints coords from any random to document coords
 * need for correct work of elements, that were created at any other filler clients
 *
 * @param {object} element - element as given in state
 * @returns {object} element with modified controlPoints
 */
export const normalizeControlPoints = (element) => {
  if (!element || !element.content) {
    return element;
  }

  const { controlPoints, x, y, width, height, lineWidth } = element.content;

  // these two will be modified if type === arrow
  let viewBox = getPureViewbox(controlPoints, lineWidth);
  let pointsBeforeMap = controlPoints;

  if (element.type === elemTypes.arrow) {
    const arrow = drawArrow(controlPoints, lineWidth, element.content.direction);
    viewBox = getPureViewbox(arrow.points, 0);
    pointsBeforeMap = arrow.endPoints;
  }

  const mappedPoints = pointsBeforeMap.map((value, index) => {
    return index % 2
      ? (((value - viewBox.y) / viewBox.height) * height) + y
      : (((value - viewBox.x) / viewBox.width) * width) + x;
  });

  // if mapped points didn't change - return given element
  const isPointsEqual = mappedPoints.reduce((prev, value, index) => {
    return prev && Math.abs(value - controlPoints[index]) < 0.1;
  }, true);

  if (isPointsEqual) {
    return element;
  }

  return {
    ...element,
    content: {
      ...element.content,
      controlPoints: mappedPoints,
    },
  };
};

/**
 * Map coords from eventPos to bounded document coords
 *
 * @param {object {x, y}} eventPos - eventPos from dragMove event
 * @param {object} viewport - context.getPageViewport() result
 * @param {object} props - we need { ghost: { content: { lineWidth } }, scale } from this
 * @returns {array} - [x, y] point
 */
export const getRenderPoint = (eventPos, viewport, props) => {
  const { workspace, frameOffset, frameSize } = viewport;
  const { ghost, scale } = props;

  const pointInFrameCoords = screenToFrame(eventPos, frameOffset, workspace);
  const boundedPoint = fixXY(pointInFrameCoords, frameSize, ghost.content.lineWidth, scale);
  return [boundedPoint.x, boundedPoint.y];
};
