import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import memoize from 'lodash/memoize';

import { wizard, dispatchAction } from '@pdffiller/jsf-lazyload';
import * as Portal from 'jsfcore/components/Portal';
import StoreRefProvider from 'jsfcore/components/StoreRef/StoreRefProvider';
import * as JsfUi from 'jsfcore/ui';
import { getMouseEventHandler, stopEventPropagation } from 'jsfcore/helpers/utils';
import ToolTypeValidation from 'jsfcore/jsf-validations/components/ToolTypeValidation';

import DatePicker from '../DatePicker';
import Input from './Input';
import ConstructorValidation from './ConstructorValidation';

const getMomentObject = (date, format, isUTC = false) => {
  if (isUTC) {
    return moment.utc(date);
  }

  return moment(date, format);
};

const defaultLocatorArgs = {
  position: Portal.wrapperPositions.bottomLeft,
};

export default class JsfDatePickerWrapper extends Component {
  static propTypes = {
    validator: PropTypes.shape({
      momentFormat: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
    date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    onChange: PropTypes.func.isRequired,

    locatorArgs: Portal.WrapperExperiment.propTypes.locatorArgs,
    size: PropTypes.oneOf(Object.values(JsfUi.customField.sizes)),
    arrowPosition: PropTypes.string,
    useArrow: PropTypes.bool,
    closeOnClickOutside: PropTypes.bool,
    openOnMount: PropTypes.bool,
    isUTC: PropTypes.bool,
    getRefPromise: PropTypes.func,
    isReadOnly: PropTypes.bool,
    openOnInputClick: PropTypes.bool,
    showValidation: PropTypes.bool,
    getViewportRef: PropTypes.func,
    clearIfInvalidOnOpenCalendar: PropTypes.bool,
  };

  static defaultProps = {
    getRefPromise: null,
    openOnMount: false,
    closeOnClickOutside: true,
    size: undefined,
    date: '',
    arrowPosition: undefined,
    useArrow: true,
    isUTC: false,
    isReadOnly: false,
    openOnInputClick: false,
    showValidation: false,
    locatorArgs: defaultLocatorArgs,
    getViewportRef: undefined,
    clearIfInvalidOnOpenCalendar: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isVisible: props.openOnMount,
    };
    this.momentValue = null;
  }

  getInputValue() {
    const { date, validator: { momentFormat } } = this.props;

    // no date
    if (!date || date === momentFormat) {
      return '';
    }

    if (this.props.isUTC) {
      return this.getCachedMoment().format(momentFormat);
    }

    return date;
  }

  getCachedMoment() {
    const { date, validator, isUTC } = this.props;
    const momentObject = getMomentObject(
      date,
      validator.momentFormat,
      isUTC,
    );

    if (this.momentValue === null || !this.momentValue.isSame(momentObject)) {
      this.momentValue = momentObject;
    }

    return this.momentValue;
  }

  toggleCalendar = () => {
    this.setState((prevState) => {
      return {
        isVisible: !prevState.isVisible,
      };
    });
  };

  toggleWizardNext = () => {
    dispatchAction(wizard.actions.toggleWizardNext);
  };

  openCalendar = () => {
    this.setState({
      isVisible: true,
    });
  };

  onIconClick = memoize((isInvalid) => {
    return () => {
      if (isInvalid && this.props.clearIfInvalidOnOpenCalendar) {
        this.props.onChange('');
      } else {
        this.toggleCalendar();
      }
    };
  });

  onChangeCalendar = (date) => {
    if (this.props.isUTC) {
      this.props.onChange(
        date.utc().valueOf(),
      );
    } else {
      this.props.onChange(
        date.format(this.props.validator.momentFormat),
      );
    }
  };

  onChangeInput = (event) => {
    const { value } = event.target;
    this.props.onChange(value);
  };

  render() {
    const {
      date,
      validator,
      useArrow,
      arrowPosition,
      isReadOnly,
      openOnInputClick,
      showValidation,
    } = this.props;

    const { momentFormat } = validator;
    const lcFormat = momentFormat.toLowerCase();
    const inputValue = this.getInputValue();
    const needRenderInput = this.props.getRefPromise === null;

    return (
      <StoreRefProvider>
        {({ storeRefs, getRefsPromise }) => {
          const getAnchorRefPromise = needRenderInput
            ? getRefsPromise('input')
            : this.props.getRefPromise;

          return (
            <ToolTypeValidation
              value={inputValue}
              validatorId={validator.id}
            >
              {({ isInvalid, validator: validation }) => {
                const showSelectedDate = (
                  !isInvalid &&
                  Boolean(date) !== false &&
                  date !== validator.momentFormat
                );

                const selectedDate = showSelectedDate
                  ? this.getCachedMoment()
                  : null;

                const showErrorMessage = isInvalid && showValidation;

                return (
                  <Fragment>
                    {(showErrorMessage || this.state.isVisible) && (
                      <Portal.WrapperExperiment
                        getAnchorRefPromise={getAnchorRefPromise}
                        storeRef={storeRefs('portal')}
                        theme={
                          showErrorMessage
                            ? JsfUi.popover.themes.warmInvalid
                            : JsfUi.popover.themes.primaryUnbordered
                        }
                        getViewportRef={this.props.getViewportRef}
                        locatorArgs={this.props.locatorArgs}
                        useArrow={useArrow}
                        arrowPosition={arrowPosition}
                        {...getMouseEventHandler(stopEventPropagation)}
                      >
                        <JsfUi.Popover.Body>
                          {showErrorMessage && validation
                            ? <ConstructorValidation errorMessage={validation.errorMessage} />
                            : <DatePicker
                              selectedDate={selectedDate}
                              onChange={this.onChangeCalendar}
                              isMonthYearSelectEnabled={momentFormat.includes('M') || lcFormat.includes('y')}
                              isCalendarEnabled={lcFormat.includes('d')}
                              isTimeEnabled={lcFormat.includes('h')}
                              is24Format={momentFormat.includes('H')}
                              toggleWizardNext={this.toggleWizardNext}
                            />}
                        </JsfUi.Popover.Body>
                      </Portal.WrapperExperiment>
                    )}
                    {this.props.closeOnClickOutside && this.state.isVisible && (
                      <Portal.DetectWindowClickProvider
                        onClick={this.toggleCalendar}
                        getRefPromise={getRefsPromise('portal')}
                        getTriggerRefPromise={getRefsPromise('input')}
                        needStopPropagation={false}
                      />
                    )}
                    {needRenderInput && (
                      <Input
                        onIconClick={this.onIconClick(isInvalid)}
                        onChange={this.onChangeInput}
                        storeRef={storeRefs('input')}
                        size={this.props.size}
                        value={inputValue}
                        isReadOnly={isReadOnly}
                        isInvalid={isInvalid}
                        onClick={
                          isReadOnly || openOnInputClick
                            ? this.openCalendar
                            : null
                        }
                      />
                    )}
                  </Fragment>
                );
              }}
            </ToolTypeValidation>
          );
        }}
      </StoreRefProvider>
    );
  }
}
