import PropTypes from 'prop-types';
import React, { Component, cloneElement } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import {
  trackPoint,
  updateElement,
} from 'ws-editor-lib/actions';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { wizard, dispatchAction, LazyComponentSubscribed } from '@pdffiller/jsf-lazyload';

import { selectors, thunks } from '../../..';
import { selectors as jsfValidationsSelectors } from '../../../jsf-validations';
import {
  setKeyboardShown,
  setKeyboardSize,
  resetPopup,
} from '../../../store/modules/viewport';
import {
  cancellableKey,
  cancellableOpts,
  getOptsForUndoRedo,
} from '../../../store/modules/undoRedo';

import { clearCaretPosition } from '../../../store/modules/navigation';

import { isTextToolBasedElement } from '../../../helpers/elemTypes';
import {
  isFillable,
  isText,
  isFilledTextElement,
  isSignature,
  isSignatureText,
  isDropdownElement,
  isDate,
  isSticky,
  isTextBox,
  getElementProps,
  momentConverter,
} from '../../../store/helpers/functions';
import { getForceFocusElementObject,
  isModalVisible, isPopupVisible } from '../../../store/helpers/stateToProps';
import {
  defaultLineHeight, zeroSpace, popupStatuses, textToolArrangements,
  occurenceTypes, valignValues, amendmentToTheWind, mobileDefaultDateFormat,
  keyCodesMap, positions, sizes, defaultDateFormat, textFieldViewModes, textFieldMaxLengths,
} from '../../../helpers/const';
import isSignNow from '../../../helpers/isSignNow';
import { stopEvent, limitByFrameSize } from '../../../helpers/utils';
import fontSizeBinarySearch from './utils/fontSizeBinarySearch';
import { scaleProps, unscaleProps, getStateByNewRuler, getKeyboardSize,
  getTextLength, getMiddleText, getAdjustmentTopToSaveBaseline, isForceFocus,
  modificatePropsByBorders, directions,
  // getFormattedNumberText,
  getTextToolSettings, isReadonly, getCaseAssignedText, getVisibleLinesCount,
  getIsElementValidatorWithDatePicker,
  getIsOnlyHeightUpdatedInStretchOrComboViewMode,
} from './utils/textToolUtils';

import TextToolView from './TextToolView';
import TextLetterCaseTransformer from './TextLetterCaseTransformer';
import RulerView from './RulerView';
import SearchHighlighter from '../../SearchHighlighter/SearchHighlighter';
import CellsToolView from '../CellsTool/CellsToolView';
import PlaceholderView from './PlaceholderView';
import FontProvider from './FontProvider';
import { unformat, format } from '../../../jsf-validations/utils/formatUnformat';
import Portal from '../../Portal/Portal';

const emptyFunc = () => {};
const maxSimpleSignatureHeight = 35;

// Исправляет проблему, с fillable полями которые имеют колличиство lines > 1.
// При различных масштабах страницы случается проблема - курсор не встаёт на
// 2-ю строку TextTool из за неправильного height для данного scale, по этой
// причине maxSize.height + 2px
const fillableHeightInaccuracy = 2;

/**
 * Список параметров, влияющих на высоту полей (Sticky и TextBox).
 * Если при изменении параметра, высота поля становится не валидной
 * (поле вылезает за документ), то возвращаемся к предыдущему валидному значению
 * параметра
 */
const staticStickyAndTextBoxProps = [
  'fontSize',
  'fontFamily',
];

const NEXT_LINE_REGEX = /[\n]/g;

const getTextLinesLength = (text) => {
  return text.split(NEXT_LINE_REGEX).length;
};

const getForcedCaretPositionObject = (caretPosition) => {
  return {
    caretPosition,
    forceCaretPosition: {
      caretPosition,
    },
  };
};

function getStringsIntersection(string1, string2) {
  const result = Array.prototype.findIndex.call(string1, (val, index) => {
    return val !== string2[index];
  });

  return result === -1
    ? string1.length
    : result;
}

@connect(
  (__, { element, pageId }) => {
    const getSearchOccurences = selectors.makeGetSearchOccurences(element.id);
    return (state) => {
      const activeElementId = selectors.base.getActiveElementId(state);
      return {
        isMobileWizardTodoListActive:
          get(state, 'wizard.isMobileWizardTodoListActive', false),
        isShownPhoneButtonSheetMenu: get(state, 'wizard.isShownPhoneButtonSheetMenu', false),
        forceFocusElementObject: getForceFocusElementObject(state, element),
        isModalVisible: isModalVisible(state),
        isDropdownMenuModalVisible: selectors.getIsSignnowModalVisible(state, 'isDropdownMenuModalVisible'),
        isPopupVisible: isPopupVisible(state) && activeElementId === element.id,
        frameOffset: selectors.getFrameOffset(state, pageId),
        originalPageSize: selectors.getOriginalSize(state, pageId),
        searchOccurences: getSearchOccurences(state),
        caretPosition: selectors.getCaretPositionById(state, element.id),
        dateValidators: jsfValidationsSelectors.validators.getDateValidators(state),
        enableSpellCheck: selectors.base.getIsSpellCheckEnabled(state),
      };
    };
  }, {
    trackPoint,
    setActiveElement: thunks.setActiveElement,
    updateElement,
    setKeyboardShown,
    setKeyboardSize,
    resetPopup,
    clearCaretPosition,
  },
  (stateProps, dispatchProps, ownProps) => {
    return { ...stateProps, ...dispatchProps, ...ownProps };
  },
)
export default class TextTool extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.bool,
    ]),
    scale: PropTypes.number.isRequired,
    happeningNowResize: PropTypes.bool,
    isGhost: PropTypes.bool,
    isDragging: PropTypes.bool,
    resizeIndex: PropTypes.number,
    isActiveElement: PropTypes.bool,
    isMobileWizardTodoListActive: PropTypes.bool.isRequired,
    isShownPhoneButtonSheetMenu: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    isHighlighted: PropTypes.bool,
    searchOccurences: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.arrayOf(
        PropTypes.shape({
          occurPos: PropTypes.number.isRequired,
          occurLength: PropTypes.number.isRequired,
        }),
      ),
    ]).isRequired,
    isFillable: PropTypes.bool.isRequired,
    viewMode: PropTypes.oneOf(
      Object.values(textFieldViewModes),
    ),
    caretPosition: PropTypes.number,
    enableSpellCheck: PropTypes.bool.isRequired,

    // @connect actions
    setKeyboardShown: PropTypes.func.isRequired,
    setKeyboardSize: PropTypes.func.isRequired,
    updateElement: PropTypes.func.isRequired,
    setActiveElement: PropTypes.func.isRequired,
    clearCaretPosition: PropTypes.func.isRequired,
    resetPopup: PropTypes.func.isRequired,
    trackPoint: PropTypes.func.isRequired,

    // Service TextTool props
    isModalVisible: PropTypes.bool.isRequired,
    isDropdownMenuModalVisible: PropTypes.bool,
    isPopupVisible: PropTypes.bool.isRequired,
    arrangement: PropTypes.oneOf(Object.values(textToolArrangements)),
    pageId: PropTypes.number.isRequired,
    pattern: PropTypes.object, // eslint-disable-line
    maxLines: PropTypes.number,
    maxChars: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    element: PropTypes.shape({
      id: PropTypes.string,
      pageId: PropTypes.number.isRequired,
      content: PropTypes.shape({
        valign: PropTypes.string,
        autoFilled: PropTypes.bool,
      }),
      template: PropTypes.shape({
        fontSize: PropTypes.number,
        lineHeight: PropTypes.number,
        placeholder: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        viewMode: PropTypes.oneOf(
          Object.values(textFieldViewModes),
        ),
        validatorId: PropTypes.string,
      }),
    }).isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    forceFocusElementObject: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.object,
    ]).isRequired,
    subType: PropTypes.string.isRequired,
    customDropdownValueSelected: PropTypes.bool,
    isCopyByClicking: PropTypes.bool,

    // for resizeable components
    storeValidatedTextToolSize: PropTypes.func,
    onResizeDragStart: PropTypes.func,
    onResizeDragMove: PropTypes.func,
    onResizeDragStop: PropTypes.func,
    resizingGeometry: PropTypes.oneOfType([
      PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
      }),
      PropTypes.bool,
    ]),

    // Cached view TextTool props
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    text: PropTypes.string,

    // View TextTool props
    fontSize: PropTypes.number,
    lineHeight: PropTypes.number,
    fontFamily: PropTypes.string,
    bold: PropTypes.bool,
    italic: PropTypes.bool,
    underline: PropTypes.bool,
    fontColor: PropTypes.string,
    align: PropTypes.string,
    valign: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
    elementIcon: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.bool,
    ]),

    frameOffset: PropTypes.shape({
      scrollLeft: PropTypes.number.isRequired,
      scrollTop: PropTypes.number.isRequired,
    }).isRequired,

    undoRedoOpts: PropTypes.shape({
      [cancellableKey]: PropTypes.bool,
    }),
    validator: PropTypes.shape({
      useDatePicker: PropTypes.bool,
      restriction: PropTypes.string,
    }),
    originalPageSize: PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
    }).isRequired,

    dateValidators: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        useDatePicker: PropTypes.bool.isRequired,
      }),
    ).isRequired,

    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    storeRef: PropTypes.func,
    onMouseDown: PropTypes.func.isRequired,
    interceptFocusDataset: PropTypes.shape({}),
  };

  static contextTypes = {
    getViewport: PropTypes.func,
    getEvents: PropTypes.func,
    getActiveElement: PropTypes.func,
    getPageViewport: PropTypes.func,
    updatePos: PropTypes.func,
    updateSizeForStamp: PropTypes.func,
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    children: null,
    text: '',
    bold: false,
    italic: false,
    underline: false,
    fontFamily: 'Arial',
    fontSize: 10,
    lineHeight: defaultLineHeight,
    fontColor: '000',
    happeningNowResize: false,
    isDragging: false,
    isDisabled: false,
    resizeIndex: positions.none,
    isHighlighted: false,
    isGhost: false,
    isActiveElement: false,
    arrangement: textToolArrangements.none,
    pattern: null,
    maxLines: null,
    maxChars: null,
    customDropdownValueSelected: false,
    isCopyByClicking: false,
    align: 'left',
    valign: valignValues.top,
    storeValidatedTextToolSize: null,
    viewMode: textFieldViewModes.classic,
    onResizeDragStart: emptyFunc,
    onResizeDragMove: emptyFunc,
    onResizeDragStop: emptyFunc,
    resizingGeometry: false,
    bgColor: 'FFFFFF',
    borderColor: '000000',
    elementIcon: false,
    undoRedoOpts: null,
    caretPosition: null,
    validator: null,
    isDropdownMenuModalVisible: false,

    interceptFocusDataset: undefined,

    onFocus: emptyFunc,
    onBlur: emptyFunc,
    storeRef: emptyFunc,
  };

  constructor(props) {
    super(props);

    // Can contain bool "false" or object with paste props
    this.paste = false;

    this.touchBlurTimeout = false;

    const {
      x,
      y,
      width,
      height,
      fontSize,
      fontFamily,
      text,
      element,
      type,
      subType,
      caretPosition,
      viewMode,
      lineHeight,
    } = props;

    this.settings = getTextToolSettings({ type, subType });

    const isStickyElement = isSticky(element);
    const isTextBoxElement = isTextBox(element);
    // в snf при старте мобильной версии, происходит патчинг валидаторов, таким образом,
    // что валидаторы даты содержащие время (useDatePicker === false) не считаются датой,
    // так как в мобильной версии для них надо показывать клавиатуру а не datePicker
    const isDateElement = isSignNow()
      ? isDate(element) && props.validator && props.validator.useDatePicker
      : isDate(element);

    const isStickyOrTextbox = isStickyElement || isTextBoxElement;
    const isFillableIOSDate = isDateElement && thisDevice.isIOS && this.props.isFillable;

    const modificatedProps = modificatePropsByBorders({
      x,
      y,
      width,
      height,
      paddingTop: 0,
      text,
      rulerText: text,
      ruler: false,
      isDate: isDateElement,
      isSticky: isStickyElement,
      isTextBox: isTextBoxElement,
      isFillableIOSDate,
      isSignature: isSignature(element),
      isDropdown: isDropdownElement(element),
      fontSize: false,
      isOverflowed: false,
    }, this.settings, directions.toTextTool);

    // Cache element props to local state.
    // After lost focus we send they to redux
    this.state = {
      ...modificatedProps,
      canAddNewLine: (
        (this.hasNextLineSymbol(text) && (
          viewMode !== textFieldViewModes.classic &&
          viewMode !== textFieldViewModes.warning
        )) ||
        getVisibleLinesCount({ height, fontSize, lineHeight }) > 1 ||
        !isFillable(element)
      ),
      padding: this.settings.padding,
      ...(caretPosition && getForcedCaretPositionObject(caretPosition)),
      ...(isStickyOrTextbox && {
        isOnlyIncrease: true,
        isWidthFixed: true,
        minWidth: modificatedProps.width,
        minHeight: modificatedProps.height,
      }),
      positionFixed: false,
    };

    if (isStickyOrTextbox) {
      this.fontSize = fontSize;
      this.fontFamily = fontFamily;
    }
  }

  componentDidMount() {
    // Text tool and Text Signature hasn't initial height and width on mobile
    // because ghost element doesn't renders on mobile, size comes from Ruler,
    // accordingly, until it come from the ruler, then there is no initial size
    // this leads to element sticking to the left side after drag
    // on desktop it's ok because ghost element renders
    // https://pdffiller.atlassian.net/browse/JSF-4613
    // https://pdffiller.atlassian.net/browse/JSF-4268
    const { element } = this.props;
    const isSimpleEmptyText =
      !isFillable(element) && !isFilledTextElement(element) && isText(element);

    if (
      thisDevice.isMobile &&
      (isSignatureText(element) || isSimpleEmptyText)
    ) {
      this.debouncedStateToRedux();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const yToSaveBaseLine = getAdjustmentTopToSaveBaseline(this.props, nextProps);
    const { x, y, element, text, caretPosition, viewMode, isActiveElement } = nextProps;
    const { scrollTop, scrollLeft } = nextProps.frameOffset;
    const { content } = element;

    if (!this.props.storeValidatedTextToolSize && nextProps.storeValidatedTextToolSize) {
      nextProps.storeValidatedTextToolSize({
        width: this.state.width,
        height: this.state.height,
      });
    }

    if (yToSaveBaseLine !== 0 && this.context.updatePos) {
      this.props.updateElement(this.props.id, { x, y: y + yToSaveBaseLine });
    }

    if (
      isForceFocus(nextProps, this.props) && this.touchBlurTimeout
    ) {
      clearTimeout(this.touchBlurTimeout);
    }

    if (
      this.props.isFillable && (text !== this.props.text || viewMode !== this.props.viewMode)
    ) {
      this.setState({
        canAddNewLine: (
          (this.hasNextLineSymbol(text) && (
            viewMode !== textFieldViewModes.classic &&
            viewMode !== textFieldViewModes.warning
          )) ||
          getVisibleLinesCount({
            height: element.template.height,
            fontSize: element.template.fontSize,
            lineHeight: element.template.lineHeight,
          }) > 1
        ),
      });
    }

    if (text !== this.props.text && text !== this.state.text) {
      // NOTE: https://pdffiller.atlassian.net/browse/JSF-1711
      // Возможна ситуация, когда селектнутый пользователем пункт Dropdown'a
      // не влезет в поле, при этом нужно обрезать текст. Обрезание текста у нас
      // реализовано в paste методе
      if (
        (text && this.state.isDropdown && !nextProps.customDropdownValueSelected) ||
        // Также нужно обрезать текст, если элемент имеет способность "CopyByClicking"
        (text && nextProps.isCopyByClicking && nextProps.isActiveElement)
      ) {
        this.onPaste({
          selectionStart: 0,
          selectionEnd: this.state.text.length,
          clipboardText: nextProps.text,
        });
      } else {
        this.setState({ rulerText: nextProps.text });
      }
    }

    if (
      thisDevice.isIOS && nextProps.isActiveElement &&
      (
        this.props.frameOffset.scrollTop !== scrollTop ||
        this.props.frameOffset.scrollLeft !== scrollLeft
      )
    ) {
      this.setState({ needFixIOSCaret: {} });
    }

    // when changing the vertical alignment in element, need update ruler
    // for text content, add paddingTop.
    if (
      content && content.valign && this.props.element.content &&
      this.props.element.content.valign !== content.valign
    ) {
      this.applyRuler(this.state.ruler, undefined, nextProps);
    }

    // меняется только при двойном клике по fakeEdit-элементу
    if (this.props.caretPosition !== caretPosition) {
      this.setState(getForcedCaretPositionObject(caretPosition));
    }

    const originalPageSizeHeight = this.props.originalPageSize &&
      this.props.originalPageSize.height;

    // isStickyOrTextbox мы не можем уменьшать высоту через уменьшение fontSize
    if (this.state.isSticky || this.state.isTextBox) {
      return;
    }

    // Если новый фонт больше, чем текущий
    // Мы проверяем, что высота контента элемента не больше высоты страницы.
    // Если высота с новым шрифтом больше, то мы оставляем прошлый размер шрифта
    if (originalPageSizeHeight && nextProps.height > originalPageSizeHeight) {
      const currentElementFontSize = get(
        this.props.element,
        'content.fontSize',
        this.state.fontSize,
      );

      const contentToUpdate = {
        fontSize: sizes[sizes.indexOf(currentElementFontSize) - 1],
      };

      if (!contentToUpdate.fontSize) {
        return;
      }

      this.props.updateElement(this.props.element.id, contentToUpdate);
    }

    // По фокус ауту, если состояние isOverFLowed=true отправляем трэкпоинт
    if (
      isActiveElement === false &&
      this.props.isActiveElement === true &&
      this.state.isOverflowed === true
    ) {
      this.props.trackPoint('FIELD_OVERFLOWED', {});
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const rerenderProps = [
      'scale', 'happeningNowResize', 'borderColor', 'bold', 'bgColor',
      'isActiveElement', 'fontSize', 'fontFamily', 'forceFocusElementObject',
      'italic', 'underline', 'fontColor', 'align', 'valign', 'resizeIndex',
      'resizingGeometry', 'isDragging', 'isMobileWizardTodoListActive',
      'isPopupVisible', 'isModalVisible', 'elementIcon', 'element', 'isHighlighted',
      'searchOccurences', 'isTargeted', 'isShownPhoneButtonSheetMenu',
    ];
    if (rerenderProps.filter((key) => {
      return this.props[key] !== nextProps[key];
    }).length > 0) {
      return true;
    }
    if (this.state !== nextState) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    if (this.props.caretPosition) {
      this.props.clearCaretPosition();
    }
    this.debouncedStateToRedux.cancel();
  }

  get canBeOverflowed() {
    const { viewMode, element } = this.props;

    if (element.content && element.content.autoFilled) {
      return true;
    }

    if (viewMode === textFieldViewModes.classic) {
      return false;
    }

    return true;
  }

  // Public function
  getState = () => {
    return this.state;
  };

  // Use for get props from TextTool
  getModificatedPropsByBorders = (state = this.state) => {
    return modificatePropsByBorders(
      state, this.settings, directions.fromTextTool,
    );
  }

  getMaxSize = (isScaleApplied = true) => {
    const { originalSize } = this.context.getPageViewport();
    const {
      width,
      fontSize,
      lineHeight,
      height,
      resizingGeometry,
      isGhost,
      x,
      y,
      scale,
      viewMode,
      isActiveElement,
      element,
    } = this.props;

    const getSize = () => {
      if (this.props.isFillable) {
        if (this.state.isSignature) {
          /** ширина и высота signature полей всегда одинаковая */
          return {
            width,
            height: height + fillableHeightInaccuracy,
          };
        }

        const canNotExpand = (
          viewMode === textFieldViewModes.classic ||
          viewMode === textFieldViewModes.warning ||
          (viewMode === textFieldViewModes.combo && !isActiveElement)
        );

        const maxWidth = (() => {
          // Однострочные stretch поля имеют нефиксированную ширину
          // и ограничены размером страницы
          if (viewMode === textFieldViewModes.stretch) {
            const linesCountFromTemplate = getVisibleLinesCount({
              height: element.template.height,
              fontSize: element.template.fontSize,
              lineHeight: element.template.lineHeight,
            });

            return linesCountFromTemplate > 1
              ? element.template.width
              : originalSize.width - x;
          }

          // Ширина остальных полей может быть ограничена только режимом
          return canNotExpand
            ? width
            : originalSize.width - x;
        })();

        const maxHeight = (() => {
          // Поле может быть с фиксированный высотой
          // или с нефиксированной (ограниченной размером страницы)
          const heightWithoutBuggyCase = (canNotExpand && !this.getIsOverflowedByViewMode())
            ? height + fillableHeightInaccuracy
            : originalSize.height - y;

          // Если высота поля багованная (в нее никогда не может влезть текст)
          // Подменяем ее на корректную для всех кейсов
          return this.isBuggyFillable(heightWithoutBuggyCase)
            ? (fontSize * lineHeight) + (this.state.padding * 2) + amendmentToTheWind
            : heightWithoutBuggyCase;
        })();

        return {
          height: maxHeight,
          width: maxWidth,
        };
      }

      // NOTE: we need to add '0.5', because sometimes (FF, Safari) there are situations
      // when e.g. unscaled = 100.55432, and resizing = 100.50543
      //
      // TODO: Fix comment
      if (resizingGeometry) {
        return {
          width: resizingGeometry.width + 0.5,
          height: resizingGeometry.height + 0.5,
        };
      }

      if (isGhost && this.state.isSignature) {
        return {
          width: 10000,
          height: maxSimpleSignatureHeight,
        };
      }

      return {
        width:
          this.state.isWidthFixed
            ? this.state.width
            : originalSize.width - x,
        height: originalSize.height - y,
      };
    };

    if (isScaleApplied) {
      return scaleProps(getSize(), scale);
    }
    return getSize();
  };

  getRoundedSize = (width, height) => {
    return {
      width: Math.ceil(width * 10) / 10,
      height: Math.ceil(height * 10) / 10,
    };
  };

  getRoundedMaxSize = () => {
    const { width, height } = this.getMaxSize();

    return this.getRoundedSize(width, height);
  };

  /**
   * Get pattern for this element
   * @returns {regex or false} - false if no pattern
   */
  getPattern = () => {
    const { validator } = this.props;
    return validator && validator.restriction && new RegExp(validator.restriction);
  };

  /**
   * Get visible lines number of tool
   * @returns {number}
   */
  getMaxVisibleLinesCount = () => {
    // get tool height
    const { height } = this.getMaxSize();
    // get scaled font size
    const scaledFontSize = scaleProps(
      { fontSize: this.state.fontSize || this.props.fontSize },
      this.props.scale,
    );
    // get scaled lineHeight
    const scaledLineHeight = scaleProps(
      { lineHeight: this.props.lineHeight },
      this.props.scale,
    );

    // return number of lines by height, font size and padding
    return getVisibleLinesCount({
      ...scaledFontSize,
      height,
      ...scaledLineHeight,
      padding: this.state.padding,
    });
  };

  getContentForStateToRedux = (text) => {
    if (this.props.isFillable && !(
      this.props.viewMode !== textFieldViewModes.classic &&
      this.props.viewMode !== textFieldViewModes.warning
    )) {
      return { text };
    }

    const modificatedProps = this.getModificatedPropsByBorders({
      width: this.state.width,
      height: this.state.height,
    });

    return {
      ...modificatedProps,
      text,
      ...(
        // При ресайзе после пересчета размера в TextTool, из-за того что уже наступил onDragStop
        // ElementDragProvider не может пробросить забаунденые координаты вниз для обновления
        // элемента. Поэтому обновляем координаты тут.
        // https://pdffiller.atlassian.net/browse/SNF-1307
        this.state.positionFixed
          ? {
            x: this.state.x,
            y: this.state.y,
          }
          : {}
      ),
    };
  };

  getWhiteSpace = () => {
    const { viewMode, fontSize, lineHeight, isActiveElement, height } = this.props;

    if (
      viewMode === textFieldViewModes.warning ||
      (viewMode === textFieldViewModes.combo && !isActiveElement)
    ) {
      if (
        this.state.text.indexOf('\n') !== -1 ||
        getVisibleLinesCount({
          height,
          fontSize: this.state.fontSize || fontSize,
          lineHeight,
          padding: this.state.padding,
        }) > 1
      ) {
        return 'pre-wrap';
      }

      return 'nowrap';
    }

    return 'pre-wrap';
  };

  // eslint-disable-next-line react/sort-comp
  storeViewRef = (ref) => {
    this.view = ref;
  };

  hasNextLineSymbol = (text) => {
    return text.match(NEXT_LINE_REGEX);
  };

  // Some times fillable fields can not be filled.
  // This occurs when the height of element less than the lineHeight.
  isBuggyFillable = (fillableHeight = this.props.height) => {
    return this.props.isFillable &&
      !this.state.isSignature &&
      fillableHeight < (this.props.fontSize * this.props.lineHeight) + (this.state.padding * 2);
  };

  pasteBinarySearch = (isValid, rulerSize) => {
    const { bad, good, test } = this.paste;
    // Intercept the insertion of the same text
    // shelimov:
    //  for some reason, binary search sometimes leads to recursive calls
    //  with that args:
    //    isValid = -1, bad.length = 17, good.length = 15, test.length = 16
    //    bad !== this.state.text
    //  when called with the same text
    if (
      isValid === -1 &&
      (bad === this.state.text || test === this.state.rulerText)
    ) {
      this.paste = false;
      this.applyRuler();
      return;
    }

    // Binary search completed. Apply, abort
    if (
      bad === good ||
      (good.length && test.length - 1 === good.length)
    ) {
      const newSize = isValid === false
        ? this.state
        : rulerSize;

      this.paste = false;
      this.applyRuler(newSize, good);
      return;
    }

    // Keep right
    if (isValid === true) {
      this.paste = { ...this.paste, good: test, test: getMiddleText(test, bad) };
    }

    // Keep left
    if (isValid === false) {
      this.paste = { ...this.paste, bad: test, test: getMiddleText(good, test) };
    }

    // The text is sent to the ruler. The result will come to 'onRulerChange'
    // and be intercepted back to the function
    this.setState({ rulerText: this.paste.test });
  };

  // This function return object
  // {
  //   valid: true/false
  //   next: true/false
  // }
  validateText = (text) => {
    const { maxChars, maxLines, element } = this.props;
    if (this.props.isFillable) {
      // Check compliance by pattern
      const pattern = this.getPattern();

      if (pattern && !pattern.test(text)) {
        return { valid: false, next: false };
      }

      // At first we should check maxLines, because
      // text may be valid (and return valid) by maxChars
      if (maxLines && maxLines < getTextLinesLength(text)) {
        return { valid: false, next: true };
      }

      // Check compliance by maxChars
      if (maxChars) {
        const length = getTextLength(text);
        const lengthFormat = getTextLength(
          format(
            unformat(text, element),
            element,
            text,
          ),
        );

        if (length > maxChars || lengthFormat > maxChars) {
          return { valid: false, next: true };
        }

        if (length === maxChars || lengthFormat === maxChars) {
          return { valid: true, next: true };
        }
      }

      // if maxChars === 0 -> no any chars limits
      return { valid: true, next: false };
    }

    return { valid: true, next: false };
  };

  /**
   * Validate ruler params
   * @param width {number}
   * @param height {number}
   * @param linesCount {number}
   * @returns {{valid: (number|boolean), next: boolean}}
   */
  // possible values for valid
  // Return true - valid
  // Return false - invalid
  // Return -1 after trash calls
  // next - specifies that can we go to the next field by wizard
  validateRuler = ({ width, height, linesCount }) => {
    // Prevent infinite re-render
    const { ruler } = this.state;
    const {
      resizingGeometry,
      viewMode,
      arrangement,
      maxLines,
      element,
      fontSize,
      lineHeight,
    } = this.props;
    if (
      ruler && this.state.text === this.state.rulerText &&
      width === ruler.width && height === ruler.height && !resizingGeometry
    ) {
      return { valid: -1, next: false };
    }

    if (width === 0 && height === 0) {
      return { valid: -1, next: false };
    }

    if (this.props.isFillable) {
      const textLength = this.state.text.length;
      const rulerTextLength = this.state.rulerText.length;

      // sometimes values differ by 0.01-0.02
      const roundedMaxSize = this.getRoundedMaxSize();
      const roundedRulerSize = this.getRoundedSize(
        width,
        isSignNow()
          ? height + fillableHeightInaccuracy
          : height,
      );

      const isFiledWithWarningIcon = (
        viewMode === textFieldViewModes.warning ||
        viewMode === textFieldViewModes.combo
      );

      const isMaxWidthOverflowed = roundedRulerSize.width > roundedMaxSize.width;
      const isMaxHeightOverflowed = roundedRulerSize.height > roundedMaxSize.height;

      if (isFiledWithWarningIcon) {
        const templateHeight = this.isBuggyFillable(element.template.height)
          ? (fontSize * lineHeight) + (this.state.padding * 2) + amendmentToTheWind
          : element.template.height + fillableHeightInaccuracy;

        const roundedTemplateSize = this.getRoundedSize(
          element.template.width,
          templateHeight,
        );

        const isWarningFieldOverflowed = (
          (roundedRulerSize.width > roundedTemplateSize.width) ||
          (roundedRulerSize.height > roundedTemplateSize.height)
        );

        if (this.state.isOverflowed !== isWarningFieldOverflowed) {
          this.setState(() => {
            return {
              isOverflowed: isWarningFieldOverflowed,
            };
          });
        }
      }

      if (rulerTextLength < textLength) {
        return { valid: true, next: false };
      }

      // Check compliance for fillable by size
      const isMaxLinesCountOverflowed = (
        // we dont need to check height for cells: ruler is rendered like for simple non-cells,
        // so ruler size is wrong
        arrangement !== textToolArrangements.cells &&
        maxLines &&
        linesCount > maxLines
      );


      // Check compliance by maxLines
      // during overflowing one line tool, ruler returns linesCount > 1
      // when maxLines is 1, we can validate ruler by width and height
      if (isMaxLinesCountOverflowed) {
        // lines count more than maxLines
        // it means that should go to next field
        return { valid: false, next: true };
      }

      if (
        (isMaxHeightOverflowed || isMaxWidthOverflowed) &&
        viewMode !== textFieldViewModes.warning
      ) {
        return { valid: false, next: true };
      }

      const fieldMaxLength = textFieldMaxLengths[viewMode];

      // JSF-7969
      // add field max length only for jsf
      if (
        !isSignNow() &&
        fieldMaxLength &&
        rulerTextLength > fieldMaxLength
      ) {
        return { valid: false, next: true };
      }
    } else {
      // Check by bounding
      const maxHeight = this.getRoundedMaxSize().height;

      const modificatedHeight = this.getModificatedPropsByBorders({
        height,
      }).height;

      if (modificatedHeight > maxHeight) {
        return { valid: false, next: !this.canBeOverflowed };
      }
    }

    return { valid: true, next: false };
  };

  stateToRedux = () => {
    this.debouncedStateToRedux.cancel();
    const { text } = this.state;

    const contentForUpdate = this.getContentForStateToRedux(text);

    if (this.props.isGhost) {
      this.props.updateElement(this.props.id, {
        width: contentForUpdate.width,
        height: contentForUpdate.height,
      }, { isGhost: true });
      return;
    }

    const opts = {
      ...this.props.text !== text && cancellableOpts,
      // to prevent excess conditionsRemoveContentSaga running
      ...this.props.text === text && getOptsForUndoRedo(),
    };

    // https://pdffiller.atlassian.net/browse/JSF-6470
    // Ранее isUselessUpdate работал только для неактивного элемента
    // Перенес для всех элементов, чтобы не было лишнего апдейта для случая:
    // Элемент уже активный, впервые рендерится, у template нет text: ''
    // и делаем элемент неактивным
    const isUselessUpdate = (() => {
      const textForUpdate = get(contentForUpdate, 'text', '');
      // https://pdffiller.atlassian.net/browse/JSF-6468
      // Во время рендера филдов без контента, отправлялся ненужный апдейт в сокет
      // https://pdffiller.atlassian.net/browse/JSF-6470
      // После рендера активного поля без ключа text в контенте,
      // отправлялся ненужный апдейт в сокет на focus out
      const isContentOrTextMissed = get(this.props.element, 'content.text') === undefined;
      if (textForUpdate.length === 0 && isContentOrTextMissed) {
        return true;
      }

      const keys = Object.keys(contentForUpdate);
      const currentContentToCompare = pick(this.props.element.content || {}, keys);

      return isEqual(currentContentToCompare, contentForUpdate);
    })();

    if (isUselessUpdate) {
      return;
    }

    const { viewMode, element: { content = {} } } = this.props;
    // https://pdffiller.atlassian.net/browse/JSF-6502
    // Во время рендера stretch/combo филдов, отправлялся ненужный апдейт в сокет
    const isOnlyHeightUpdatedInStretchOrComboViewMode =
      getIsOnlyHeightUpdatedInStretchOrComboViewMode(content, contentForUpdate, viewMode);

    this.props.updateElement(this.props.id, contentForUpdate, {
      ...opts,
      ...this.props.undoRedoOpts,
      // Опция для обновления данных только в сторе, без отправки в сокет
      isLocalUpdate: isOnlyHeightUpdatedInStretchOrComboViewMode,
    });
  };

  debouncedStateToRedux = debounce(this.stateToRedux, 500);

  getBoundedXY = (width, height) => {
    const { originalPageSize, scale, x, y } = this.props;
    const { frameSize } = this.context.getPageViewport();

    const boundedXY = limitByFrameSize(
      { x, y },
      isSignNow()
        ? originalPageSize
        : frameSize,
      unscaleProps({ width, height }, scale),
    );

    return boundedXY;
  };

  fixPosition = ({ width, height }) => {
    if (!this.context.updatePos) {
      return false;
    }

    const boundedXY = this.getBoundedXY(width, height);
    this.context.updatePos(boundedXY);

    this.setState({
      ...(
        boundedXY
          ? {
            ...boundedXY,
            positionFixed: true,
          }
          : {}
      ),
    });

    return true;
  };

  applyRuler = (
    rulerSize = this.state, text = this.state.rulerText, props = this.props,
  ) => {
    const newState = getStateByNewRuler(rulerSize, props, this.state);
    if (this.props.storeValidatedTextToolSize) {
      this.props.storeValidatedTextToolSize({
        width: newState.width,
        height: newState.height,
      });
    }

    const setForceCaretPosition = (
      // Restore caret position need only if text changed
      text !== this.state.text &&
      // Dropdown can be overflowed, we don't need to set caret for it because we need to see a text
      // beginning but not when a custom value is selected (JSF-5127)
      (!this.state.isDropdown || this.props.customDropdownValueSelected)
    );

    /**
     * Experimental optimization
     */
    const nextState = {
      ...newState,
      text,
      ...setForceCaretPosition && getForcedCaretPositionObject(this.state.caretPosition),
    };
    const prevStatePart = pick(this.state, Object.keys(nextState));
    if (isEqual(prevStatePart, nextState)) {
      return;
    }
    // ---

    this.setState((state) => {
      return {
        ...newState,
        text,
        ...setForceCaretPosition && getForcedCaretPositionObject(state.caretPosition),
      };
    }, () => {
      // After update state, send real size to signTool for stamp
      // if we got context.updateSizeForStamp
      if (this.context.updateSizeForStamp) {
        if (this.props.isFillable) {
          this.context.updateSizeForStamp(
            unscaleProps(this.state.ruler, props.scale),
          );
        } else {
          this.context.updateSizeForStamp(this.state);
        }
      }

      if (props.isActiveElement || props.isGhost ||
        (
          this.props.viewMode !== textFieldViewModes.classic &&
          this.props.viewMode !== textFieldViewModes.warning
        )
      ) {
        if (
          props.isDragging ||
          props.resizingGeometry
        ) {
          this.debouncedStateToRedux();
        } else {
          // Оставил коммент чтоб был конфликт при мерже с 2.19
          // чтобы не тащить код с комментом N-ый раз сюда
          this.stateToRedux();
        }
      }
    });
  };

  afterFocus = () => {
    // AB: TODO: Жесткий фикс от автоматического подсткола к фокусированным элементам
    // который вызывал смешение стараниц
    // if (__CLIENT__) {
    //   if (document.querySelector('div.jsf-pagination-local')) {
    //     document.querySelector('div.jsf-pagination-local').scrollTop = 0;
    //   }
    // }

    // Потенциально плохой код, возможен баг при переключении с текстового поля на
    // поле cells (место под клавиатуру не увеличится)
    if (this.context.getViewport().isKeyboardShown) {
      return;
    }

    // Код ниже нужен для того, чтобы уменьшить размер страницы на iOS
    // на предполагаемый размер клавиатуры. Для обычный полей мы выключаем
    // предиктивный набор, но для cells полей (contenteditable) это сделать
    // невозможно, https://bugs.webkit.org/show_bug.cgi?id=148503, поэтому к обычному
    // размеру клавиатуры мы добавляем высоту поля предиктивного набора
    // UPD: дл/ не-iOS- просто выставляем флаг isKeyboardShown
    if (thisDevice.isMobile) {
      this.props.setKeyboardShown(true);
    }

    const { isFillableIOSDate } = this.state;
    const isFillableIOSTabletDate = thisDevice.isTablet && isFillableIOSDate;

    if (thisDevice.isIOS && !isFillableIOSTabletDate) {
      this.props.setKeyboardSize(
        getKeyboardSize({
          isCells: this.props.arrangement === textToolArrangements.cells,
          isDate: isFillableIOSDate,
        }),
      );
    }
  };

  afterBlur = (focusNode = false) => {
    const focusElement = this.context.getActiveElement();
    const { isKeyboardShown } = this.context.getViewport();

    const isDropDownWithCustomChoose = (
      thisDevice.isMobile &&
      isDropdownElement(focusElement) &&
      !get(focusElement, 'content.customDropdownValueSelected', false)
    );

    const useDatePicker =
      getIsElementValidatorWithDatePicker(focusElement, this.props.dateValidators);
    const isIOSTablet = thisDevice.isIOS && thisDevice.isTablet;
    const isMobileDate = isDate(focusElement) &&
      useDatePicker &&
      (thisDevice.isAndroid || isIOSTablet);

    if (
      isKeyboardShown && thisDevice.isMobile && (
        focusElement === false ||
        !isTextToolBasedElement(focusElement) ||
        isSignatureText(focusElement) ||
        (
          isTextToolBasedElement(focusElement) &&
          isFillable(focusElement) &&
          (
            isMobileDate ||
            isDropDownWithCustomChoose
          )
        )
      )
    ) {
      this.props.setKeyboardShown(false);
      if (thisDevice.isIOS) {
        // fixed for iphone screen keyboard, need call blur event on focused field
        // for correct closing screen keyboard
        if (focusNode) {
          focusNode.blur();
        }
        this.props.setKeyboardSize(zeroSpace);
      }
    }
    // this.stateToRedux();
  };

  updateResizingGeometry = (isValid, linesCount) => {
    const { resizingGeometry } = this.props;
    const isSizeChanged =
      Math.abs(this.state.width - resizingGeometry.width) > 1 ||
      Math.abs(this.state.height - resizingGeometry.height) > 1;
    if (!isValid || !isSizeChanged) {
      return;
    }

    const scaledGeometry = scaleProps(resizingGeometry, this.props.scale);
    this.applyRuler({
      width: scaledGeometry.width,
      height: scaledGeometry.height,
      linesCount,
      realSize: this.state.ruler.realSize,
    });
    if (this.context.updatePos) {
      this.context.updatePos({
        x: resizingGeometry.x,
        y: resizingGeometry.y,
      });
    }
  };

  toggleWizardNext = (next) => {
    // Focus to next fillable element
    if (next && this.props.isActiveElement) {
      // Disabled 'next' on overflow for iOS
      // https://pdffiller.atlassian.net/browse/JSF-952
      if (!thisDevice.isSafariPhone && !thisDevice.isChromeIOS) {
        dispatchAction(wizard.actions.toggleWizardNext);
      }
    }
  };

  getIsOverflowedByViewMode = () => {
    const isOverflowedByViewMode = (
      this.props.viewMode === textFieldViewModes.warning ||
      (this.props.viewMode === textFieldViewModes.combo && !this.props.isActiveElement)
    );

    return isOverflowedByViewMode;
  };

  // This function is called from the parent component
  onDrop = () => {
    if (this.props.isActiveElement) {
      this.view.focus();
    }
  };

  onPaste = ({ selectionStart, selectionEnd, clipboardText }) => {
    if (this.state.isDate) {
      return;
    }
    const { text } = this.state;

    const caseAssignedText = getCaseAssignedText({
      text: clipboardText,
      letterCase: get(this.props.element, 'template.letterCase', 'none'),
    });

    const pattern = this.getPattern();
    const filteredClipboardText = pattern
      ? caseAssignedText.split('').filter((char) => {
        return pattern.test(char);
      }).join('')
      : caseAssignedText;

    // After end binary search we restore caret to this
    // position
    const caretPosition =
      text.slice(0, selectionStart).length + filteredClipboardText.length;
    this.setState({
      caretPosition:
        (this.props.maxChars && caretPosition > this.props.maxChars)
          ? this.props.maxChars
          : caretPosition,
    });

    // Calc result text
    let bad =
      text.slice(0, selectionStart) + filteredClipboardText + text.slice(selectionEnd);

    if (this.props.maxChars && bad.length > this.props.maxChars) {
      bad = bad.slice(0, this.props.maxChars);
    }

    bad = bad.replace('\r', '');

    // Binary search store
    this.paste = {
      good: text.slice(0, selectionStart),
      bad,
      test: bad,
    };

    // Run
    this.pasteBinarySearch();
  };

  onChangeApplyCorrectDateFormat = (oldDate) => {
    const { validator, text } = getElementProps(this.props.element);
    const newFormat = get(validator, 'momentFormat', defaultDateFormat);

    if (!oldDate) {
      return text;
    }

    return momentConverter({
      oldDate,
      oldFormat: mobileDefaultDateFormat, // ios date format
      newFormat,
    });
  };

  onChange = (event, { caretPosition }) => {
    if (this.props.isModalVisible) {
      return true;
    }
    const { value } = event.target;
    const newText = this.state.isFillableIOSDate
      ? this.onChangeApplyCorrectDateFormat(value)
      : getCaseAssignedText(
        {
          text: value,
          letterCase: get(this.props.element, 'template.letterCase', 'none'),
        });

    // Pre-validation fillable elements by pattern and maxChars
    // (it is possible to do without the ruler)
    const isValid = this.validateText(newText);
    // isValid contain object { valid: true/false, next: true/false }

    // Send the text to the ruler and save caret position
    // to restore it later
    if (isValid.valid) {
      this.setState({
        rulerText: newText,
        caretPosition,
      }, () => {
        this.toggleWizardNext(isValid.next);
      });

      return true;
    }

    // on iOS caret goes to left when field overfilled
    // so we return it to position where attempt to fill occured
    if (isValid.next && (thisDevice.isIOS)) {
      this.setState((prevState) => {
        return getForcedCaretPositionObject(
          getStringsIntersection(value, prevState.rulerText),
        );
      });
    }

    this.toggleWizardNext(isValid.next);
    // For restore text in cells (contentEditable) return false
    return false;
  };

  onFocus = (event) => {
    if (this.props.isPopupVisible) {
      this.props.resetPopup();
    }

    this.props.onFocus(event);
  };

  onKeydown = (event) => {
    if (this.props.isModalVisible) {
      return;
    }
    const isTabKey = event.keyCode === keyCodesMap.tab;
    const isBackspaceKey = event.keyCode === keyCodesMap.backspace;
    const isEnterKey = event.keyCode === keyCodesMap.enter;

    // for tool which can be overflowed
    // when pressed Enter and it haven't empty visible lines
    // then focus to next fillable element
    if (isEnterKey && !event.ctrlKey && !this.state.canAddNewLine) {
      stopEvent(event);
      if (isSignNow()) {
        // Пока в snfiller нет аналога 'toggleWizardNext' - просто блюрим элемент
        this.props.setActiveElement(this.props.id, false);
      } else {
        dispatchAction(wizard.actions.toggleWizardNext);
      }
    }

    if (isEnterKey && event.ctrlKey) {
      stopEvent(event);
      this.view.insertNextLineSymbol();
    }

    if (!this.props.isFillable && isTabKey) {
      // Tab for simple element
      stopEvent(event);
      this.view.insertTabIndent();
    }

    if (
      thisDevice.isMobile &&
      isBackspaceKey &&
      this.props.customDropdownValueSelected &&
      this.props.text.length === 0
    ) {
      this.props.updateElement(
        this.props.id,
        { customDropdownValueSelected: false },
      );
    }
  };

  onRulerChange = ({ width, height, linesCount, realSize }) => {
    // #1
    // Эта проверка была введена в коммите 696f7a.
    // Сейчас багов на андроиде с ходу обнаржуить не удалось
    // TODO: отключив проверку найти баг (и выписать его сюда) или удалить проверку.
    //
    // #2
    // Если при загрузке выполнялется изменение активной страницы сценарием - этот
    // код мешает нормальному поведению valign'a. Было принято решение его закомментировать.
    // Возможно раньше код был нужен, т.к. не был написан shouldComponentUpdate.
    // И компонент перерендеривался по каждому чиху.
    //
    // const { pagePinching, pageScaling, pageChanging, pagePanning } = this.context.getEvents();
    // const isPageSomething = pagePinching || pageScaling || pageChanging || pagePanning;
    // if (!this.props.isGhost && isPageSomething) return false;

    const { valid, next } = this.validateRuler({ width, height, linesCount, realSize });

    // Start binary search to fix fontSize for fillable-text-signature
    if (this.state.isSignature && !valid && !this.fontSizeBinarySearch) {
      this.fontSizeBinarySearch = fontSizeBinarySearch(this.props.fontSize);
      this.setState({ fontSize: this.fontSizeBinarySearch.test });
      return false;
    }

    // Continue or finish binary search to fix fontSize
    if (this.fontSizeBinarySearch) {
      // Continue binary search to fix fontSize
      if (this.fontSizeBinarySearch.test) {
        this.fontSizeBinarySearch = this.fontSizeBinarySearch.step(valid);
        this.setState({ fontSize: this.fontSizeBinarySearch.test });
      }

      // Finish binary search to fix fontSize
      if (this.fontSizeBinarySearch.good) {
        const fontSizeBinarySearchGood = this.fontSizeBinarySearch.good;

        // Так как передается ссылка на объект привязанный к this
        // внутри колбэка мы ссылаемся уже на другой объект, который false
        this.setState({ fontSize: false }, () => {
          this.props.updateElement(this.props.id, {
            fontSize: fontSizeBinarySearchGood,
          });
        });

        this.fontSizeBinarySearch = false;
      }

      return false;
    }

    // turn off ruler conditional to canBeOverflowed
    const isValid = valid;

    // Interception of the binary search process
    if (this.paste) {
      return this.pasteBinarySearch(isValid, { width, height, linesCount, realSize });
    }

    // Update geometry at elementDraggableDecorator
    if (this.props.resizingGeometry) {
      return this.updateResizingGeometry(isValid, linesCount);
    }

    // Apply props of the ruler to local state
    if (isValid === true && !this.props.resizingGeometry) {
      this.applyRuler({ width, height, linesCount, realSize });
    }

    // Update static props of Sticky and TextBox
    if (
      this.state.text === this.state.rulerText &&
      (this.state.isSticky || this.state.isTextBox)
    ) {
      for (let propIndex = 0; propIndex < staticStickyAndTextBoxProps.length; propIndex++) {
        const propName = staticStickyAndTextBoxProps[propIndex];
        const prevProp = get(this, propName);
        const prop = get(this.props, `element.content.${propName}`, prevProp);

        // update element with previous value
        if (isValid === false && prevProp !== prop) {
          this.props.updateElement(this.props.element.id, { [propName]: prevProp });
          return false;
        }

        // save new value
        if (isValid !== false && prevProp !== prop) {
          this[propName] = prop;
        }
      }
    }

    // If ruler realSize changed, but text don't changed.
    // For example, if changed the font family
    // Apply new ruler
    if (
      this.state.text === this.state.rulerText &&
      this.state.ruler.realSize !== realSize
    ) {
      if (this.state.positionFixed === true) {
        this.setState({ positionFixed: false });
      }

      this.applyRuler({ width, height, linesCount, realSize });
    }

    // If element size now is invalid and changed by text
    // Reset text and caretPosition in ruler
    if (next) {
      if (this.props.isFillable && this.props.isActiveElement) {
        // Disabled 'next' on overflow for iOS
        // https://pdffiller.atlassian.net/browse/JSF-952
        if (!thisDevice.isSafariPhone) {
          // Вызов stateToRedux необходим для кейса, когда text activeElement'a был изменен
          // в WizardAssistantDateView (при вводе даты из Wizard'a), но не прошел валидацию
          // в этом компоненте (validateRuler). Таким образом в стейте этого компонента
          // сохраняется валидный текст, а в redux - невалидный. После рефреша страницы
          // стейт этого компонента обновляется значением из redux.
          if (this.state.isDate) {
            this.stateToRedux();
          }
          dispatchAction(wizard.actions.toggleWizardNext);
        }
      }

      this.setState((prevState) => {
        return {
          rulerText: prevState.text,
          ...getForcedCaretPositionObject(prevState.caretPosition - 1),
        };
      });
    }

    return true;
  };

  // На touch девайсах у клавиатуры есть кнопка 'done'
  // нажатие на нее вызывает сброс фокуса из поля и закрытие клавиатуры
  // в случае если через 300 мс после
  // потери dom-фокуса это поле по прежднему активно -
  // снимаем с поля активность
  onBlurTouch = () => {
    /**
     * Обнаружил баг в iOS, когда срабатывает сам собой onBlur, но
     * клавиатура и каретка остается на месте. Перед сбросом фокуса лучше проверить,
     * что getSelection() и вправду не равен ничему
     */
    if (window.getSelection().type === 'None') {
      this.touchBlurTimeout = setTimeout(() => {
        const viewport = this.context.getViewport();

        if (this.state.isSignature) {
          return;
        }
        if (this.state.isDate && thisDevice.isAndroid) {
          return;
        }
        const isSignNowTablet = isSignNow && thisDevice.isTablet;
        // TODO это временное решение когда придет новый дата пикер выпилим
        // Поле вызывает blur когда в дата пикере есть время и если мы встанем на
        // input редактирования времени происходит блур не снимаем фокус
        if (isSignNowTablet && this.state.isFillableIOSDate) {
          return;
        }
        if (
          (thisDevice.isMobile || isSignNowTablet) &&
          this.state.isDropdown &&
          !this.props.customDropdownValueSelected
        ) {
          return;
        }
        if (this.props.happeningNowResize) {
          return;
        }
        if (this.props.isDragging) {
          return;
        }
        if (viewport.popupVisibility !== popupStatuses.hidden) {
          return;
        }

        // Раньше мы читали это свойство из context.getEvents, но оно переехало в state.wizard
        // Смысла особо нет из контекста читать: оно используется в рендере
        if (this.props.isMobileWizardTodoListActive) {
          return;
        }
        if (this.props.isDropdownMenuModalVisible) {
          return;
        }

        const focusElement = this.context.getActiveElement();
        if (focusElement.id === this.props.id) {
          this.props.setActiveElement(this.props.id, false);
        }
      }, 300);
    }

    // https://pdffiller.atlassian.net/browse/JSF-3571 IOS Table input="date"
    // When date dropdown opened and happened blur event, it prevents all other event
    if (this.state.isFillableIOSDate && thisDevice.isTablet) {
      dispatchAction(wizard.actions.toggleWizardNext);
    }
  };


  renderView = () => {
    const { isActiveElement } = this.props;
    const handlers = {
      onPaste: this.onPaste,
      onChange: this.onChange,
      onKeydown: this.onKeydown,
      // onFocus: this.onFocus,
      // onBlurTouch: this.onBlurTouch,
      afterFocus: this.afterFocus,
      afterBlur: this.afterBlur,

      onFocus: this.onFocus,
      onBlur: this.props.onBlur,
      storeRef: this.props.storeRef,
      onMouseDown: this.props.onMouseDown,
    };
    // const visibleLinesCount = this.getVisibleLinesCount();
    // const isOneLineOverflowedText = this.state.isOverflowed && visibleLinesCount === 1;
    // const isFFMultiLineOverflowedText =
    //   this.state.isOverflowed && visibleLinesCount > 1 && thisDevice.isFirefoxDesktop;

    const isOverflowedByViewMode = this.getIsOverflowedByViewMode();

    const maxSize = this.getMaxSize(false);

    const props = {
      whiteSpace: this.getWhiteSpace(),

      y: this.state.y,
      width: this.state.width,
      height: this.state.height,

      // we need crop not-active expandable field
      maxWidth: !isActiveElement && this.props.isFillable
        ? this.props.width
        : maxSize.width,
      maxHeight: !isActiveElement && this.props.isFillable
        ? this.props.height
        : maxSize.height,

      paddingTop: this.state.paddingTop,
      text: this.state.text,
      isFillable: this.props.isFillable,
      isSignature: this.state.isSignature,
      isSticky: this.state.isSticky,
      isTextBox: this.state.isTextBox,
      isDate: this.state.isDate,
      isFillableIOSDate: this.state.isFillableIOSDate,
      isDropdown: this.state.isDropdown,
      padding: this.state.padding,
      forceCaretPosition: this.state.forceCaretPosition,

      fontSize: this.state.fontSize || this.props.fontSize,
      lineHeight: this.props.lineHeight,
      fontFamily: this.props.fontFamily,
      bold: this.props.bold,
      italic: this.props.italic,
      underline: this.props.underline,
      fontColor: this.props.fontColor,
      align: this.props.align,
      isActiveElement,
      id: this.props.id,
      maxChars: this.props.maxChars,
      isHighlighted: this.props.isHighlighted,

      forceFocusElementObject: this.props.forceFocusElementObject,
      isReadonly: isReadonly({
        happeningNowResize: this.props.happeningNowResize,
        isFillable: this.props.isFillable,
        isDate: this.state.isDate,
        isSignature: this.state.isSignature,
        isDragging: this.props.isDragging,
        customDropdownValueSelected: this.props.customDropdownValueSelected,
        isDropdown: this.state.isDropdown,
      }),
      trackPoint: this.props.trackPoint,
      pageId: this.props.pageId,
      isDisabled: this.props.isDisabled,
      isGhost: this.props.isGhost,
      isMobileWizardTodoListActive: this.props.isMobileWizardTodoListActive,
      isShownPhoneButtonSheetMenu: this.props.isShownPhoneButtonSheetMenu,
      isPopupVisible: this.props.isPopupVisible,
      isModalVisible: this.props.isModalVisible,
      needFixIOSCaret: this.state.needFixIOSCaret,

      isOverflowedView: this.state.isOverflowed && isOverflowedByViewMode,
      // isOneLineOverflowedText,
      // isFFMultiLineOverflowedText,

      interceptFocusDataset: this.props.interceptFocusDataset,
    };

    // TODO: divide properties
    const scaledProps = scaleProps(props, this.props.scale);

    const cloneElementIcon = () => {
      if (this.props.elementIcon) {
        return cloneElement(
          this.props.elementIcon,
          {
            element: {
              ...this.props.element,
              content: {
                ...this.props.element.content,
                text: this.state.text,
              },
            },
          },
        );
      }

      return undefined;
    };

    const { element, enableSpellCheck } = this.props;

    return (
      <TextLetterCaseTransformer
        propName="text"
        letterCase={get(this.props.element, 'template.letterCase', 'none')}
        key={0}
      >
        {this.props.arrangement === textToolArrangements.cells
          ? (
            <CellsToolView
              ref={this.storeViewRef}
              icon={cloneElementIcon()}
              pageId={element.pageId}
              validator={this.props.validator}
              isCells
              {...scaledProps}
              {...handlers}
            >
              {this.props.children}
            </CellsToolView>
          )
          : (
            <TextToolView
              ref={this.storeViewRef}
              icon={cloneElementIcon()}
              pageId={element.pageId}
              enableSpellCheck={enableSpellCheck}
              {...scaledProps}
              {...handlers}
            >
              {this.props.children}
            </TextToolView>
          )
        }
      </TextLetterCaseTransformer>
    );
  };

  renderRuler = () => {
    if (isSignNow() && this.props.resizingGeometry) {
      return null;
    }

    const { element, fontSize, lineHeight, isFillable: isFillableProp } = this.props;

    const isSingleLineFillableField = isFillableProp && getVisibleLinesCount({
      height: element.template.height,
      fontSize,
      lineHeight,
    }) === 1;

    const isSingleLineWarningField = (this.props.viewMode === textFieldViewModes.warning) &&
      isSingleLineFillableField;

    const props = {
      rulerText: this.state.rulerText,
      padding: this.state.padding,
      minHeight: this.state.minHeight,
      minWidth: this.state.minWidth,
      resizingGeometry: this.props.resizingGeometry,
      ...(
        /**
         * Однострочное текстовое поле c Warning Mode
         * не дожно быть ограничено для переполнения по ширине
         */
        isSingleLineWarningField
          ? {}
          : { maxWidth: this.getMaxSize(false).width }
      ),
      fontFamily: this.props.fontFamily,
      fontSize: this.state.fontSize || this.props.fontSize,
      bold: this.props.bold,
      italic: this.props.italic,
      underline: this.props.underline,
      valign: this.props.valign,
      onRulerChange: this.onRulerChange,
      element: this.props.element,
      lineHeight: this.props.lineHeight,
    };

    return (
      <Portal key={1}>
        <TextLetterCaseTransformer
          propName="rulerText"
          letterCase={get(this.props.element, 'template.letterCase', 'none')}
        >
          <RulerView {...scaleProps(props, this.props.scale)} />
        </TextLetterCaseTransformer>
      </Portal>
    );
  };

  renderSearchHighlighters = () => {
    const { searchOccurences } = this.props;
    if (!searchOccurences) {
      return [];
    }

    const props = {
      padding: this.state.padding,
      minHeight: this.state.minHeight,
      minWidth: this.state.minWidth,
      resizingGeometry: this.props.resizingGeometry,
      maxWidth: this.getMaxSize().width,
      fontFamily: this.props.fontFamily,
      fontSize: this.state.fontSize || this.props.fontSize,
      bold: this.props.bold,
      italic: this.props.italic,
      underline: this.props.underline,
      width: this.state.width,
      height: this.state.height,
      paddingTop: this.state.paddingTop,
      align: this.props.align,
      maxChars: this.props.maxChars,
    };

    const componentProps = {
      ...scaleProps(props, this.props.scale),
      x: this.props.x,
      y: this.props.y,
    };

    return searchOccurences.map((occurence) => {
      return (
        <SearchHighlighter
          occurence={{
            ...occurence,
            type: occurenceTypes.element,
            content: {
              ...occurence.content,
              ...componentProps,
              text: this.state.text,
              arrangement: this.props.arrangement,
            },
          }}
          key={`searchHighlighter-${occurence.elementId}-${occurence.index}`}
        />
      );
    });
  };

  renderWrapper = (child) => {
    const { WrapperComponent: wrapper } = this.settings;
    const WrapperComponent = wrapper.isLazy
      ? LazyComponentSubscribed
      : wrapper;
    const lazyProps = wrapper.isLazy
      ? { literal: wrapper.component }
      : null;

    if (WrapperComponent) {
      const wrapperComponentProps = modificatePropsByBorders({
        width: this.state.width,
        height: this.state.height,
        bgColor: this.props.bgColor,
        borderColor: this.props.borderColor,
        scale: this.props.scale,
        isActiveElement: this.props.isActiveElement,
        innerStyle: scaleProps(this.settings, this.props.scale),
        isGhost: this.props.isGhost,
        onResizeDragStart: this.props.onResizeDragStart,
        onResizeDragMove: this.props.onResizeDragMove,
        onResizeDragStop: this.props.onResizeDragStop,
        isDragging: this.props.isDragging,
        isDisabled: this.props.isDisabled,
        resizeIndex: this.props.resizeIndex,
        id: this.props.id,
      }, this.settings, directions.fromTextTool);

      return (
        <WrapperComponent
          {...lazyProps}
          {...scaleProps(wrapperComponentProps, this.props.scale)}
        >
          {child}
        </WrapperComponent>
      );
    }

    return (
      <>
        {child}
      </>
    );
  };

  renderPlaceholder = () => {
    const { text } = this.state;
    const { element } = this.props;

    if (
      element.template &&
      element.template.placeholder && (
        !text || text.length < 1
      )
    ) {
      const props = {
        fontSize: this.state.fontSize || this.props.fontSize,
        lineHeight: this.props.lineHeight,
        fontFamily: this.props.fontFamily,
        bold: this.props.bold,
        italic: this.props.italic,
        underline: this.props.underline,
        fontColor: this.props.fontColor,
        align: this.props.align,
        paddingTop: this.state.paddingTop,
        width: this.state.width,
        height: this.state.height,
        padding: this.state.padding,
      };
      const scaledProps = scaleProps(props, this.props.scale);
      return ([
        <PlaceholderView
          // text
          placeholder={element.template.placeholder}
          key={2}
          // for style
          {...scaledProps}
        />,
      ]);
    }

    return [];
  };

  renderTool = () => {
    return this.renderWrapper([
      this.renderView(),
      this.renderRuler(),
      ...this.renderPlaceholder(),
      ...this.renderSearchHighlighters(),
    ]);
  };

  render() {
    // 1. На IOS есть FontFaceSet, но loading event не триггерится
    // 2. mobile/tablet -> возникает ошибка, когда показывается PhoneButtonSheetMenu
    // вызывается FontProvider.onFontLoaded, который триггерит applyRuler и обновление компонента,
    // но данные по text tool удалены со стейта, а componentWillUnmount еще не вызвался
    // также мы не поддерживаем fake edit на mobile/tablet
    if (thisDevice.isMobile) {
      return this.renderTool();
    }

    return (
      <FontProvider
        fontFamily={this.props.fontFamily}
        bold={this.props.bold}
        italic={this.props.italic}
      >
        {this.renderTool()}
      </FontProvider>
    );
  }
}
