/* eslint-disable quote-props */

// key: fontRecognized name
// value: font style, as it is at document.fonts.addEventListener('loadingdone') event
const fontsMap = {
  'ArialUnicodeMS': {
    fontFamily: 'Arial',
    bold: false,
    italic: false,
  },
  'ArialUnicodeMSBold': {
    fontFamily: 'Arial',
    bold: true,
    italic: false,
  },
  'ArialUnicodeMSItalic': {
    fontFamily: 'Arial',
    bold: false,
    italic: true,
  },
  'ArialUnicodeMSBoldItalic': {
    fontFamily: 'Arial',
    bold: true,
    italic: true,
  },
  'Arial': {
    fontFamily: 'Arial',
    bold: false,
    italic: false,
  },
  'AmaticSC-Regular': {
    fontFamily: 'Amatic SC',
    bold: false,
    italic: false,
  },
  'Amatic-Bold': {
    fontFamily: 'Amatic',
    bold: true,
    italic: false,
  },
  'Calibri': {
    fontFamily: 'Calibri',
    bold: false,
    italic: false,
  },
  'Cambria-Bold': {
    fontFamily: 'Cambria',
    bold: true,
    italic: false,
  },
  'Cambria-BoldItalic': {
    fontFamily: 'Cambria',
    bold: true,
    italic: true,
  },
  'Cambria-Italic': {
    fontFamily: 'Cambria',
    bold: false,
    italic: true,
  },
  'Cambria': {
    fontFamily: 'Cambria',
    bold: false,
    italic: false,
  },
  'CaveatBrush-Regular': {
    fontFamily: 'Caveat Brush',
    bold: false,
    italic: false,
  },
  'Caveat-Bold': {
    fontFamily: 'Caveat',
    bold: true,
    italic: false,
  },
  'Caveat-Regular': {
    fontFamily: 'Caveat',
    bold: false,
    italic: false,
  },
  'Comfortaa-Bold': {
    fontFamily: 'Comfortaa',
    bold: true,
    italic: false,
  },
  'Comfortaa-Light': {
    fontFamily: 'Comfortaa-Light',
    bold: false,
    italic: false,
  },
  'Comfortaa': {
    fontFamily: 'Comfortaa',
    bold: false,
    italic: false,
  },
  'ComicSansMS': {
    fontFamily: 'Comic Sans MS',
    bold: false,
    italic: false,
  },
  'Consolas': {
    fontFamily: 'Consolas',
    bold: false,
    italic: false,
  },
  'CourierNewPSMT': {
    fontFamily: 'Courier New',
    bold: false,
    italic: false,
  },
  'DroidSans-Bold': {
    fontFamily: 'Droid Sans',
    bold: true,
    italic: false,
  },
  'DroidSans': {
    fontFamily: 'Droid Sans',
    bold: false,
    italic: false,
  },
  'DroidSerif': {
    fontFamily: 'Droid Serif',
    bold: false,
    italic: false,
  },
  'EBGaramond12-Italic': {
    fontFamily: 'EB Garamond 12',
    bold: false,
    italic: true,
  },
  'EBGaramond12-Regular': {
    fontFamily: 'EB Garamond 12',
    bold: false,
    italic: false,
  },
  'FreeSans': {
    fontFamily: 'FreeSans',
    bold: false,
    italic: false,
  },
  'FreeSansBold': {
    fontFamily: 'FreeSans',
    bold: true,
    italic: false,
  },
  'FreeSansBoldOblique': {
    fontFamily: 'FreeSans',
    bold: true,
    italic: true,
  },
  'FreeSansOblique': {
    fontFamily: 'FreeSans',
    bold: false,
    italic: true,
  },
  'Impact': {
    fontFamily: 'Impact',
    bold: false,
    italic: false,
  },
  'Lora-Bold': {
    fontFamily: 'Lora',
    bold: true,
    italic: false,
  },
  'Lora-BoldItalic': {
    fontFamily: 'Lora',
    bold: true,
    italic: true,
  },
  'Lora-Italic': {
    fontFamily: 'Lora',
    bold: false,
    italic: true,
  },
  'Lora-Regular': {
    fontFamily: 'Lora',
    bold: false,
    italic: false,
  },
  'Merriweather-Bold': {
    fontFamily: 'Merriweather',
    bold: true,
    italic: false,
  },
  'Merriweather-BoldItalic': {
    fontFamily: 'Merriweather',
    bold: true,
    italic: true,
  },
  'Merriweather-Italic': {
    fontFamily: 'Merriweather',
    bold: false,
    italic: true,
  },
  'Merriweather-LightItalic': {
    fontFamily: 'Merriweather-Light',
    bold: false,
    italic: true,
  },
  'Merriweather-Light': {
    fontFamily: 'Merriweather-Light',
    bold: false,
    italic: false,
  },
  'Merriweather-Regular': {
    fontFamily: 'Merriweather',
    bold: false,
    italic: false,
  },
  'MonotypeCorsiva': {
    fontFamily: 'Monotype Corsiva',
    bold: false,
    italic: true,
  },
  'Montserrat-Bold': {
    fontFamily: 'Montserrat',
    bold: true,
    italic: false,
  },
  'Montserrat-BoldItalic': {
    fontFamily: 'Montserrat',
    bold: true,
    italic: true,
  },
  'Montserrat-Italic': {
    fontFamily: 'Montserrat',
    bold: false,
    italic: true,
  },
  'Montserrat-Light': {
    fontFamily: 'Montserrat-Light',
    bold: false,
    italic: false,
  },
  'Montserrat-Medium': {
    fontFamily: 'Montserrat',
    bold: false,
    italic: false,
  },
  'Montserrat-Regular': {
    fontFamily: 'Montserrat',
    bold: false,
    italic: false,
  },
  'Nunito-Light': {
    fontFamily: 'Nunito-Light',
    bold: false,
    italic: false,
  },
  'Nunito-Medium': {
    fontFamily: 'Nunito',
    bold: false,
    italic: false,
  },
  'Nunito-Bold': {
    fontFamily: 'Nunito',
    bold: true,
    italic: false,
  },
  'Nunito-Regular': {
    fontFamily: 'Nunito',
    bold: false,
    italic: false,
  },
  'Roboto-ThinItalic': {
    fontFamily: 'Roboto-Thin',
    bold: false,
    italic: true,
  },
  'Roboto-Thin': {
    fontFamily: 'Roboto-Thin',
    bold: false,
    italic: false,
  },
  'Roboto-Light': {
    fontFamily: 'Roboto-Light',
    bold: false,
    italic: false,
  },
  'Roboto-Medium': {
    fontFamily: 'Roboto',
    bold: false,
    italic: false,
  },
  'Roboto-BoldItalic': {
    fontFamily: 'Roboto',
    bold: true,
    italic: true,
  },
  'Roboto-Bold': {
    fontFamily: 'Roboto',
    bold: true,
    italic: false,
  },
  'Roboto-Italic': {
    fontFamily: 'Roboto',
    bold: false,
    italic: true,
  },
  'Roboto-Regular': {
    fontFamily: 'Roboto',
    bold: false,
    italic: false,
  },
  'TimesNRCyrMT-Bold': {
    fontFamily: 'Times New Roman Cyr',
    bold: true,
    italic: false,
  },
  'TimesNRCyrMT-Inclined': {
    fontFamily: 'Times New Roman Cyr',
    bold: false,
    italic: true,
  },
  'TimesNewRomanPS-BoldItalicMT': {
    fontFamily: 'Times New Roman',
    bold: true,
    italic: true,
  },
  'TimesNewRomanPS-BoldMT': {
    fontFamily: 'Times New Roman',
    bold: true,
    italic: false,
  },
  'TimesNewRomanPS-ItalicMT': {
    fontFamily: 'Times New Roman',
    bold: false,
    italic: true,
  },
  'TimesNewRomanPSMT': {
    fontFamily: 'Times New Roman',
    bold: false,
    italic: false,
  },
  'Ubuntu-Light': {
    fontFamily: 'Ubuntu-Light',
    bold: false,
    italic: false,
  },
  'Ubuntu-BoldItalic': {
    fontFamily: 'Ubuntu',
    bold: true,
    italic: true,
  },
  'Ubuntu-Bold': {
    fontFamily: 'Ubuntu',
    bold: true,
    italic: false,
  },
  'Ubuntu-Italic': {
    fontFamily: 'Ubuntu',
    bold: false,
    italic: true,
  },
  'Ubuntu': {
    fontFamily: 'Ubuntu',
    bold: false,
    italic: false,
  },
  'UbuntuCondensed-Regular': {
    fontFamily: 'Ubuntu Condensed',
    bold: false,
    italic: false,
  },
  'UbuntuMono-BoldItalic': {
    fontFamily: 'Ubuntu Mono',
    bold: true,
    italic: true,
  },
  'UbuntuMono-Bold': {
    fontFamily: 'Ubuntu Mono',
    bold: true,
    italic: false,
  },
  'UbuntuMono-Italic': {
    fontFamily: 'Ubuntu Mono',
    bold: false,
    italic: true,
  },

  // style?
  // 'ArialUnicodeMS'
};

export default fontsMap;

/* eslint-enable quote-props */
