import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import clamp from 'lodash/clamp';
import { clearElement, removeElement, updateElement } from 'ws-editor-lib/actions';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { withFocusControllerContext } from '@pdffiller/jsf-focuscontroller';

import { stopEvent } from '../../../helpers/utils';
import {
  thicknessValues,
  sizeChangeInterval,
  zeroSpace,
  popupStatuses,
} from '../../../helpers/const';

// import from store
import { cancellableOpts } from '../../../store/modules/undoRedo';
import { onOkClicked } from '../../../store/modules/events';
import {
  resetPopup,
  setKeyboardShown,
  setKeyboardSize,
  togglePopup,
  openHrefModal,
  setActivePopupMenu,
} from '../../../store/modules/viewport';
import {
  toolbar,
  base,
  locale as localesSelectors,
  mode,
} from '../../../store/selectors';
import setLineWidth from '../../../store/thunks/setLineWidth';
import { sizeUp, sizeDown } from '../../../store/thunks/sizeUpDown';
import { toggleStamp, forceFocusTextElement } from '../../../store/thunks';
import setActiveElement from '../../../store/thunks/setActiveElement';

// import components
import ToolbarAttributesMobileView from './Mobile/ToolbarAttributesMobileView';
import ToolbarAttributesView from './ToolbarAttributesView';
import ToolbarAttributesTabletView from './Tablet/ToolbarAttributesTabletView';

@connect(
  (state) => {
    return {
      // base selectors
      logo: base.getFeaturesLogo(state),
      sigDateStampChangeable: base.getSigDateStampChangeable(state),
      activeMenu: base.getActiveMenu(state),

      // mode selectors
      isConstructor: mode.isConstructor(state),
      isPreview: mode.isPreview(state),
      isFieldsOrder: mode.isFieldsOrder(state),

      // root selectors
      popupVisibility: base.getSettingsPopupVisibility(state),
      editType: base.getEditType(state),

      // toolbar selectors
      toolbarAttributesProps: toolbar.getToolbarAttributesProps(state),

      // locale selectors
      locale: localesSelectors.getToolbarAttributesLocale(state),
    };
  }, {
    resetPopup,
    updateElement,
    clearElement,
    removeElement,
    togglePopup,
    openHrefModal,
    setKeyboardShown,
    setKeyboardSize,
    onOkClicked,
    forceFocusTextElement,
    toggleStamp,
    setActivePopupMenu,
    setLineWidth,
    sizeDown,
    sizeUp,
    setActiveElement,
  },
)

class ToolbarAttributes extends Component {
  static propTypes = {
    toolbarAttributesProps: PropTypes.shape({
      id: PropTypes.string,
      isFillable: PropTypes.bool,
      isTextToolBasedElement: PropTypes.bool,
      isFConstructorElementHaveToolbar: PropTypes.bool,
      isElementHaveToolbar: PropTypes.bool,
      isSignature: PropTypes.bool,
      lineWidth: PropTypes.number,

      // visibility props
      hasPopup: PropTypes.bool,
      hasSize: PropTypes.bool,
      hasStamp: PropTypes.bool,
      hasFont: PropTypes.bool,
      hasColor: PropTypes.bool,
      hasFontColor: PropTypes.bool,
      hasFillColor: PropTypes.bool,
      hasBackgroundColor: PropTypes.bool,
      hasBorderColor: PropTypes.bool,
      hasLineWidth: PropTypes.bool,
      hasArrowStyle: PropTypes.bool,
      hasRectDraw: PropTypes.bool,
      hasHref: PropTypes.bool,
      hasAlign: PropTypes.bool,
      hasVAlign: PropTypes.bool,

    }).isRequired,
    locale: PropTypes.shape({}).isRequired,
    togglePopup: PropTypes.func.isRequired,
    popupVisibility: PropTypes.oneOf(
      Object.values(popupStatuses),
    ).isRequired,
    resetPopup: PropTypes.func.isRequired,
    setKeyboardShown: PropTypes.func.isRequired,
    setKeyboardSize: PropTypes.func.isRequired,
    onOkClicked: PropTypes.func.isRequired,
    forceFocusTextElement: PropTypes.func.isRequired,
    toggleStamp: PropTypes.func.isRequired,
    sigDateStampChangeable: PropTypes.bool,

    // element decorator
    sizeUp: PropTypes.func.isRequired,
    sizeDown: PropTypes.func.isRequired,
    setLineWidth: PropTypes.func.isRequired,
    isConstructor: PropTypes.bool.isRequired,
    isPreview: PropTypes.bool.isRequired,
    isFieldsOrder: PropTypes.bool.isRequired,

    disableBlur: PropTypes.func.isRequired,
    enableBlur: PropTypes.func.isRequired,
    restoreFocus: PropTypes.func.isRequired,

    clearElement: PropTypes.func.isRequired,
    removeElement: PropTypes.func.isRequired,

    toolbarAttributesWidth: PropTypes.number,
    useTabletView: PropTypes.bool,

    // connect props
    setActiveElement: PropTypes.func.isRequired,
  };

  static defaultProps = {
    sigDateStampChangeable: true,
    toolbarAttributesWidth: null,
    useTabletView: false,
  };

  constructor(props) {
    super(props);
    this.sizeChangeInterval = null;
    this.storedRemoveSizeChangeInterval = null;
  }

  getWrapperNode = () => {
    return this.wrapperRef;
  };

  storeWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleSizeButtonFocus = (event) => {
    stopEvent(event);
    // https://pdffiller.atlassian.net/browse/JSF-7706
    // На мобильных устройствах на touch start элементы забирают фокус, из-за чего происходил
    // onBlur в TextTool и каретка исчезала
    this.props.restoreFocus();
  };

  blur = () => {
    const { id } = this.props.toolbarAttributesProps;
    this.props.setActiveElement(id, false);
  }

  remove = (event) => {
    const { id, isFillable } = this.props.toolbarAttributesProps;
    if (event) {
      stopEvent(event);
    }

    // commit: a5254f
    // [JSF-7136, JSF-7137, JSF-7138] Added reseting active element before removing on toolbar
    // delete button press for tablet (#1527)

    // order setActiveElement(false) -> removeElement - is necessary
    this.blur();

    if (!isFillable) {
      this.props.removeElement(id, cancellableOpts);
    } else {
      this.props.clearElement(id);
    }
  };

  handleToggleStampClick = (event) => {
    stopEvent(event);
    this.props.toggleStamp();
  };

  handleSizeChange = ({ more, changeLineWidth }) => {
    return (event) => {
      stopEvent(event);

      if (changeLineWidth) {
        const dStep = more
          ? 1
          : -1;

        const { lineWidth } = this.props.toolbarAttributesProps;
        const step = thicknessValues.step * dStep;
        const newLineWidth = clamp(
          lineWidth + step,
          thicknessValues.min,
          thicknessValues.max,
        );
        this.props.setLineWidth(newLineWidth);
      } else if (more) {
        this.props.sizeUp();
      } else {
        this.props.sizeDown();
      }
    };
  };

  addSizeChangeInterval = ({ more, changeLineWidth = false }) => {
    return (event) => {
      stopEvent(event);
      this.props.disableBlur();

      if (this.sizeChangeInterval) {
        this.storedRemoveSizeChangeInterval();
      }

      this.storedRemoveSizeChangeInterval =
        this.removeSizeChangeInterval({ more, changeLineWidth });
      document.addEventListener('touchend', this.storedRemoveSizeChangeInterval);
      document.addEventListener('touchcancel', this.storedRemoveSizeChangeInterval);

      this.sizeChangeInterval =
        setInterval(this.handleSizeChange({ more, changeLineWidth }), sizeChangeInterval);
    };
  };

  addSizeChangeIntervalSizeUp = this.addSizeChangeInterval({ more: true });

  addSizeChangeIntervalSizeDown = this.addSizeChangeInterval({ more: false });

  addSizeChangeIntervalWidthDown = this.addSizeChangeInterval({
    more: false, changeLineWidth: true,
  });

  addSizeChangeIntervalWidthUp = this.addSizeChangeInterval({
    more: true, changeLineWidth: true,
  });

  removeSizeChangeInterval = ({ more, changeLineWidth = false }) => {
    return (event) => {
      stopEvent(event);

      clearInterval(this.sizeChangeInterval);
      this.sizeChangeInterval = null;
      document.removeEventListener('touchend', this.storedRemoveSizeChangeInterval);
      document.removeEventListener('touchcancel', this.storedRemoveSizeChangeInterval);

      this.handleSizeChange({ more, changeLineWidth })();

      this.props.enableBlur();
    };
  };

  onRemovePressed = (event) => {
    stopEvent(event);
    this.props.resetPopup();

    if (thisDevice.isMobile) {
      this.props.setKeyboardShown(false);
    }

    if (thisDevice.isIOS) {
      this.props.setKeyboardSize(zeroSpace);
    }

    this.remove();
  };

  onTogglePopupPressed = (event) => {
    stopEvent(event);
    if (this.props.popupVisibility !== popupStatuses.hidden) {
      // then we hide popup, and need to show keyboard
      this.props.forceFocusTextElement();
    } else {
      // we show popup, and we need to hide keyboard space
      if (thisDevice.isMobile) {
        this.props.setKeyboardShown(false);
      }

      if (thisDevice.isIOS) {
        this.props.setKeyboardSize(zeroSpace);
      }
    }

    this.props.togglePopup();
  };

  renderTablet() {
    const { sigDateStampChangeable } = this.props;

    const {
      id,
      isTextToolBasedElement,

      // visibility props
      hasPopup,
      hasSize,
      hasStamp,
      hasLineWidth,
    } = this.props.toolbarAttributesProps;

    if (!id) {
      return null;
    }

    return (
      <ToolbarAttributesTabletView
        // actions
        handleSizeUp={this.addSizeChangeIntervalSizeUp}
        handleSizeDown={this.addSizeChangeIntervalSizeDown}
        handleLineWidthDown={this.addSizeChangeIntervalWidthDown}
        handleLineWidthUp={this.addSizeChangeIntervalWidthUp}
        onRemovePressed={this.onRemovePressed}
        onTogglePopupPressed={this.onTogglePopupPressed}
        handleToggleStampClick={this.handleToggleStampClick}
        storeRef={this.storeWrapperRef}

        // toolbar element props
        elementId={id}
        hasStamp={hasStamp}
        hasSize={hasSize}
        hasLineWidth={hasLineWidth}
        hasPopup={hasPopup}
        isTextToolBasedElement={isTextToolBasedElement}
        sigDateStampChangeable={sigDateStampChangeable}
      />
    );
  }

  renderMobile() {
    const { sigDateStampChangeable } = this.props;
    const {
      id,
      isFillable,
      isTextToolBasedElement,

      // visibility props
      hasPopup,
      hasSize,
      hasStamp,
      hasLineWidth,
    } = this.props.toolbarAttributesProps;

    if (id === null || isFillable) {
      return null;
    }

    return (
      <ToolbarAttributesMobileView
        // actions
        handleSizeUp={this.addSizeChangeIntervalSizeUp}
        handleSizeDown={this.addSizeChangeIntervalSizeDown}
        onSizeButtonFocus={this.handleSizeButtonFocus}
        handleLineWidthDown={this.addSizeChangeIntervalWidthDown}
        handleLineWidthUp={this.addSizeChangeIntervalWidthUp}
        onRemovePressed={this.onRemovePressed}
        onTogglePopupPressed={this.onTogglePopupPressed}
        handleToggleStampClick={this.handleToggleStampClick}
        storeRef={this.storeWrapperRef}
        handleOkClick={this.props.onOkClicked}

        // toolbar element props
        hasStamp={hasStamp}
        hasSize={hasSize}
        hasLineWidth={hasLineWidth}
        hasPopup={hasPopup}

        elementId={id}
        isTextToolBasedElement={isTextToolBasedElement}
        sigDateStampChangeable={sigDateStampChangeable}
        isFillable={isFillable}
      />
    );
  }

  renderDesktop() {
    const {
      isConstructor,
      toolbarAttributesWidth,
      sigDateStampChangeable,
      locale,
    } = this.props;

    const {
      isFillable,
      isSignature,

      // visibility props
      hasStamp,
      hasFont,
      hasColor,
      hasFontColor,
      hasFillColor,
      hasBackgroundColor,
      hasBorderColor,
      hasLineWidth,
      hasArrowStyle,
      hasRectDraw,
      hasHref,
      hasAlign,
      hasVAlign,
    } = this.props.toolbarAttributesProps;

    // Desktop
    return (
      <ToolbarAttributesView
        // actions
        handleToggleStampClick={this.handleToggleStampClick}
        toolbarAttributesWidth={toolbarAttributesWidth}
        setActivePopupMenu={setActivePopupMenu}

        // from toolbarAttributesProps
        isSignature={isSignature}
        isFillable={isFillable}

        // from connect
        isConstructor={isConstructor}
        sigDateStampChangeable={sigDateStampChangeable}
        locale={locale}

        hasStamp={hasStamp}
        hasFont={hasFont}
        hasColor={hasColor}
        hasFontColor={hasFontColor}
        hasFillColor={hasFillColor}
        hasBackgroundColor={hasBackgroundColor}
        hasBorderColor={hasBorderColor}
        hasLineWidth={hasLineWidth}
        hasArrowStyle={hasArrowStyle}
        hasRectDraw={hasRectDraw}
        hasHref={hasHref}
        hasAlign={hasAlign}
        hasVAlign={hasVAlign}
      />
    );
  }

  render() {
    const {
      isFConstructorElementHaveToolbar,
      isElementHaveToolbar,
    } = this.props.toolbarAttributesProps;

    const {
      useTabletView,
      isConstructor,
      isPreview,
      isFieldsOrder,
    } = this.props;


    // use in HeaderView component, not in Header component
    if (useTabletView) {
      return this.renderTablet();
    }

    // check render conditions
    if (isPreview || isFieldsOrder) {
      return null;
    }

    if (!isConstructor && !isElementHaveToolbar) {
      return null;
    }

    if (isConstructor && !isFConstructorElementHaveToolbar) {
      return null;
    }

    // render mobile
    if (thisDevice.isPhone) {
      return this.renderMobile();
    }

    // render desktop
    return this.renderDesktop();
  }
}

export default withFocusControllerContext(ToolbarAttributes);
