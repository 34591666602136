import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showSimpleVideo } from 'combine-modals/lib/actions/actionCreator';
import { thisDevice } from '@pdffiller/jsf-useragent';

export const isVideoUrl = (url) => {
  const regExp = /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/;
  return regExp.test(url);
};

@connect(
  null, { showSimpleVideo },
)
export default class ElementHref extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    elementProps: PropTypes.shape({
      hyperlink: PropTypes.string,
    }).isRequired,
    showSimpleVideo: PropTypes.func.isRequired,
  };

  onClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { hyperlink } = this.props.elementProps;
    if (isVideoUrl(hyperlink)) {
      this.props.showSimpleVideo({
        params: {
          autostart: true,
          minimizable: false,
          videoId: hyperlink,
        },
        onHide: () => {},
      });
    } else {
      window.open(hyperlink, '_blank');
    }
  };

  renderInner() {
    const { elementProps, visible } = this.props;
    const style = {
      pointerEvents: 'auto',
      display: visible
        ? 'block'
        : 'none',
    };

    return (
      <div
        className="source-link"
        style={style}
      >
        <div className="source-link__body">
          <a
            href="/"
            className="source-link__item"
            onClick={this.onClick}
          >
            <span className="source-link__item-href">{elementProps.hyperlink}</span>
            {!isVideoUrl(elementProps.hyperlink) &&
              <span className="source-link__item-icon-container">
                <span className="i i-goto-destination source-link__item-icon" />
              </span>
            }
          </a>
        </div>
      </div>
    );
  }

  render() {
    const { elementProps } = this.props;
    if (!elementProps.hyperlink) {
      return null;
    }

    if (thisDevice.isSafariDesktop) {
      // Зачем дополнительная обертка у сафари:
      // когда мы убираем курсор - то сам блок с ссылкой пропадает (display: none)
      // но его 'box-shadow' остается. Если сделать доп. враппер - пустой див, то бага не будет
      return (
        <div>
          {this.renderInner()}
        </div>
      );
    }

    return this.renderInner();
  }
}
