import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DirectionToolbarSettings from '../Direction';
import { directions } from '../../../../../helpers/graphicUtils';
import * as Ui from '../../../../../ui';

export default class DirectionPopupSettings extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
  };

  render() {
    return (
      <Ui.Menu.ListItem>
        <Ui.Menu.Item>
          <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.body}>
            <Ui.Menu.ItemByType attributes={Ui.attributes.grow}>
              <Ui.Text size={16} align={Ui.text.aligns.left}>
                {this.props.locale}
              </Ui.Text>
            </Ui.Menu.ItemByType>
            <Ui.Menu.ItemByType attributes={Ui.attributes.shrinkOff}>
              <Ui.Menu.ItemByType type={Ui.menu.itemsTypes.toolbar}>
                <Ui.ToolBar.Body
                  size={Ui.toolBar.sizes.small}
                  theme={Ui.toolBar.themes.bordered}
                  type={Ui.toolBar.types.narrow}
                >
                  <Ui.ToolBar.Main>
                    <Ui.ToolBar.Group>
                      <DirectionToolbarSettings direction={directions.forward} />
                      <DirectionToolbarSettings direction={directions.backward} />
                      <DirectionToolbarSettings direction={directions.double} />
                    </Ui.ToolBar.Group>
                  </Ui.ToolBar.Main>
                </Ui.ToolBar.Body>
              </Ui.Menu.ItemByType>
            </Ui.Menu.ItemByType>
          </Ui.Menu.ItemByType>
        </Ui.Menu.Item>
      </Ui.Menu.ListItem>
    );
  }
}
