import PropTypes from 'prop-types';
import React from 'react';

import * as Ui from '../../../ui';

const rearrangeNavigationView =
  ({ onClickPages, onClickRearrange, isThumbnailsOpen, locale }) => {
    return (
      <Ui.Grid.Cell size={Ui.grid.sizes.small}>
        <Ui.ToolBar.Body
          size={Ui.toolBar.sizes.parent}
          theme={Ui.toolBar.themes.bordered}
          type={Ui.toolBar.types.slideItems}
        >
          <Ui.ToolBar.Main>

            <div className="jsf-main-action-toolbar__cell">
              <div
                className="jsf-toolbars-btn jsf-toolbars-btn--collapsed js_toggle active"
                onClick={onClickPages}
              >
                <div className="jsf-toolbars-btn__icon icon-svg-pages" />
                <div className="jsf-toolbars-btn__title">
                  {
                    isThumbnailsOpen
                      ? locale.hidePages
                      : locale.showPages
                  }
                </div>
              </div>
            </div>

            <div className="jsf-main-action-toolbar__cell">
              <div
                className="jsf-toolbars-btn jsf-toolbars-btn--collapsed"
                onClick={onClickRearrange}
              >
                <div className="jsf-toolbars-btn__icon icon-svg-rearrange" />
                <div className="jsf-toolbars-btn__title">
                  {locale.editPages}
                </div>
              </div>
            </div>

          </Ui.ToolBar.Main>
        </Ui.ToolBar.Body>
      </Ui.Grid.Cell>
    );
  };

rearrangeNavigationView.propTypes = {
  onClickPages: PropTypes.func.isRequired,
  onClickRearrange: PropTypes.func.isRequired,
  isThumbnailsOpen: PropTypes.bool.isRequired,
  locale: PropTypes.shape({
    showPages: PropTypes.string.isRequired,
    hidePages: PropTypes.string.isRequired,
    editPage: PropTypes.string.isRequired,
    editPages: PropTypes.string.isRequired,
  }).isRequired,
};

export default rearrangeNavigationView;
