import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LineToolView from './LineToolView';
import { pathFromPoints, getViewbox } from '../../../helpers/graphicUtils';
import { defaultControlPoints } from '../../../helpers/const';

export default class LineTool extends Component {
  static propTypes = {
    // not required because it's not passed in ElementGhost
    controlPoints: PropTypes.arrayOf(PropTypes.number),
    fillColor: PropTypes.oneOfType([
      PropTypes.string, PropTypes.number,
    ]).isRequired,
    lineWidth: PropTypes.number.isRequired,
    isDisabled: PropTypes.bool,

    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    storeRef: PropTypes.func.isRequired,
    interceptFocusDataset: PropTypes.shape({}),
  };

  static defaultProps = {
    controlPoints: defaultControlPoints,
    isDisabled: false,

    interceptFocusDataset: undefined,
  };

  render() {
    const {
      controlPoints,
      fillColor,
      lineWidth,
      isDisabled,

      onFocus,
      onBlur,
      storeRef,
      interceptFocusDataset,
    } = this.props;

    const viewBox = getViewbox(controlPoints, lineWidth);
    const path = pathFromPoints(controlPoints);

    return (
      <LineToolView
        fillColor={fillColor}
        lineWidth={lineWidth}
        path={path}
        viewBox={viewBox}
        isDisabled={isDisabled}

        onFocus={onFocus}
        onBlur={onBlur}
        storeRef={storeRef}
        {...interceptFocusDataset}
      />
    );
  }
}
