import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import flatten from 'lodash/flatten';
import { currentDate } from '../../datePickerUtils';

import Ui from '../../ui';
import Cell from './Cell';

const maxCalendarRows = 6;
const weekDaysAmount = 7;

function generateCalendar(shownDate) {
  const month = moment(shownDate).startOf('month');
  const monthStartWeekDay = month.day();
  const daysInMonth = month.daysInMonth();

  let counter = 0;
  let nullCounter = -1;
  const initialMap = Array.from(Array(maxCalendarRows)).fill(
    Array.from(Array(weekDaysAmount)).fill(null),
  );

  return flatten(
    initialMap
      .map((week) => {
        return week.map((_, dayOfWeek) => {
          if ((counter === 0 && dayOfWeek !== monthStartWeekDay) || counter === daysInMonth) {
            nullCounter -= 1;
            return nullCounter;
          }

          counter += 1;
          return counter;
        });
      })
      .filter((week) => {
        return week.some((day) => {
          return day > -1;
        });
      }),
  );
}

const Calendar = ({ onChangeDay, shownDate, selectedDate }) => {
  const month = shownDate.month();
  const year = shownDate.year();
  const currentMonthSameAsShown = shownDate.isSame(currentDate, 'month');
  const selectedMonthSameAsShown = shownDate.isSame(selectedDate, 'month');

  return (
    <Ui.Body.Main.Calendar>
      {generateCalendar(shownDate).map((day) => {
        const value = day >= 0
          ? day
          : null;

        const isDisabled = value === null;

        return (
          <Cell
            key={`${day}-${month}-${year}`}
            disabled={isDisabled}
            highlighted={currentMonthSameAsShown && currentDate.date() === day}
            isPressed={selectedMonthSameAsShown && selectedDate.date() === day}
            onClick={isDisabled
              ? undefined
              : onChangeDay}
          >
            {value}
          </Cell>
        );
      })}
    </Ui.Body.Main.Calendar>
  );
};

Calendar.propTypes = {
  shownDate: PropTypes.instanceOf(moment).isRequired,
  onChangeDay: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(moment),
};

Calendar.defaultProps = {
  selectedDate: undefined,
};

export default Calendar;
