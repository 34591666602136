const fieldNamesMap = {
  alignment: 'alignment',
  required: 'required',
  groupName: 'groupName',
  groupValues: 'groupValues',
};

export const radioAlignmentModes = {
  vertical: 'vertical',
  horizontal: 'horizontal',
};

export const defaultDialogSize = 'small';

export const defaultDialogOffset = 'medium';

export default fieldNamesMap;
