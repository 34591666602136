import PropTypes from 'prop-types';
import React, { Component } from 'react';

const style = {
  position: 'absolute',
  borderTopColor: '#FF0000',
  borderTopStyle: 'dashed',
  borderLeftColor: '#FF0000',
  borderLeftStyle: 'dashed',
  borderWidth: 1,
};

const stylePointerEvents = {
  pointerEvents: 'none',
};

export default class MagneticLinesView extends Component {
  static propTypes = {
    magneticLines: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        shape: PropTypes.shape({
          height: PropTypes.number,
          width: PropTypes.number,
          top: PropTypes.number,
          left: PropTypes.number,
        }),
      }),
    ).isRequired,
  }

  render() {
    const { magneticLines } = this.props;
    if (magneticLines.length === 0) {
      return null;
    }

    return (
      <div style={stylePointerEvents}>
        {
          magneticLines.map((line) => {
            return (
              <div
                key={`${line.key}_${line.shape.top}_${line.shape.left}`}
                style={{ ...style, ...line.shape }}
              />
            );
          })
        }
      </div>
    );
  }
}
