import PropTypes from 'prop-types';
import React from 'react';
import {
  pageCountLabelAnimation,
} from '../../../helpers/const';

const visible = {
  opacity: 1,
  transition: 'none',
};
const invisible = {
  opacity: 0,
  transition: pageCountLabelAnimation,
};

function PaginationLabelInner(props) {
  const { current, count, template, isVisible } = props;
  const animationStyle = isVisible
    ? visible
    : invisible;

  return (
    <div className="pageCountLabelWrapper-PaginationLabel" style={animationStyle}>
      <span className="pageCountLabel-PaginationLabel">
        {template.replace('%current%', current + 1).replace('%total%', count)}
      </span>
    </div>
  );
}

PaginationLabelInner.propTypes = {
  current: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  count: PropTypes.number.isRequired,
  template: PropTypes.string.isRequired,

  isVisible: PropTypes.bool.isRequired,
};

export default PaginationLabelInner;
