import React from 'react';
import * as JsfUi from 'jsfcore/ui';
import PropTypes from 'prop-types';
import moment from 'moment';

import Ui from '../ui';
import { weekDays } from '../datePickerUtils';
import Calendar from './Calendar/Calendar';
import Time from './Time/Time';
import Header from './Header';

export default function DatePickerView({
  selectedDate,
  isTimeEnabled,
  isCalendarEnabled,
  isMonthYearSelectEnabled,
  shownDate,
  shownMonth,
  shownYear,
  is24Format,
  onChangeYear,
  onChangeMonth,
  onChangeDay,
  onChange,
  onCurrentDateClick,
  yearsOffset,
}) {
  const showCalendar = shownDate instanceof moment && isCalendarEnabled;

  return (
    <Ui theme={Ui.themes.default}>
      {(isMonthYearSelectEnabled || isCalendarEnabled) && (
        <Ui.Body>
          {isMonthYearSelectEnabled && (
            <Header
              onChangeYear={onChangeYear}
              onChangeMonth={onChangeMonth}
              shownMonth={shownMonth}
              shownYear={shownYear}
              yearsOffset={yearsOffset}
            />
          )}
          {showCalendar && (
            <Ui.Body.Main>
              <Ui.Body.Main.Days>
                {weekDays.map((weekDay) => {
                  return (
                    <Ui.Body.Main.CalendarCell key={weekDay}>
                      <JsfUi.Text
                        size={12}
                        theme={JsfUi.text.themes.lightgray}
                        family={JsfUi.text.families.openSans}
                      >
                        {weekDay}
                      </JsfUi.Text>
                    </Ui.Body.Main.CalendarCell>
                  );
                })}
              </Ui.Body.Main.Days>
              <Calendar
                shownDate={shownDate}
                selectedDate={selectedDate}
                onChangeDay={onChangeDay}
              />
            </Ui.Body.Main>
          )}

          {isCalendarEnabled && (
            <Ui.Body.Footer>
              <JsfUi.Control
                size={JsfUi.control.sizes.smallStretched}
                offset={JsfUi.control.offsets.medium}
                theme={JsfUi.control.themes.flatLightgray}
                onClick={onCurrentDateClick}
              >
                <JsfUi.Text
                  size={12}
                  family={JsfUi.text.families.openSans}
                  weight={JsfUi.text.weights.bold}
                >
                  {isTimeEnabled
                    ? 'Current date and time'
                    : 'Today\'s date'}
                </JsfUi.Text>
              </JsfUi.Control>
            </Ui.Body.Footer>
          )}
        </Ui.Body>
      )}

      {isTimeEnabled && (
        <Time
          date={shownDate}
          onChange={onChange}
          is24Format={is24Format}
        />
      )}
    </Ui>
  );
}

DatePickerView.propTypes = {
  selectedDate: PropTypes.instanceOf(moment),
  shownDate: PropTypes.instanceOf(moment),
  shownMonth: PropTypes.number,
  shownYear: PropTypes.number,
  isTimeEnabled: PropTypes.bool,
  isCalendarEnabled: PropTypes.bool,
  isMonthYearSelectEnabled: PropTypes.bool,
  is24Format: PropTypes.bool,
  yearsOffset: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onChangeYear: PropTypes.func.isRequired,
  onChangeMonth: PropTypes.func.isRequired,
  onChangeDay: PropTypes.func.isRequired,
  onCurrentDateClick: PropTypes.func.isRequired,
};

DatePickerView.defaultProps = {
  shownDate: null,
  shownMonth: null,
  shownYear: null,
  selectedDate: undefined,
  isTimeEnabled: false,
  isCalendarEnabled: true,
  isMonthYearSelectEnabled: true,
  is24Format: false,
  yearsOffset: undefined,
};
