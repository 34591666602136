import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as JsfUi from 'jsfcore/ui';
import Ui from '../../ui';

const Cell = ({ children, disabled, isPressed, highlighted, onClick }) => {
  const onCellClicked = useCallback(
    () => {
      onClick(children);
    },
    [children, onClick],
  );

  return (
    <Ui.Body.Main.CalendarCell onClick={onCellClicked}>
      <JsfUi.Control
        size={JsfUi.control.sizes.parent}
        theme={JsfUi.control.themes.datepickerPrimary}
        disabled={disabled}
        isPressed={isPressed}
        isHighlighted={highlighted}
        inheritRadius
      >
        <JsfUi.Text
          size={14}
          family={JsfUi.text.families.openSans}
        >
          {children}
        </JsfUi.Text>
      </JsfUi.Control>
    </Ui.Body.Main.CalendarCell>
  );
};

Cell.propTypes = {
  children: PropTypes.number,
  disabled: PropTypes.bool,
  isPressed: PropTypes.bool,
  highlighted: PropTypes.bool,
  onClick: PropTypes.func,
};

Cell.defaultProps = {
  children: null,
  disabled: null,
  isPressed: false,
  highlighted: false,
  onClick: () => {},
};

export default Cell;
