import Main from './Main';
import MainCalendar from './MainCalendar';
import MainCalendarCell from './MainCalendarCell';
import MainDays from './MainDays';

Main.Calendar = MainCalendar;
Main.CalendarCell = MainCalendarCell;
Main.Days = MainDays;

export default Main;
