import { Component } from 'react';
import PropTypes from 'prop-types';

export default class RightPanelVisibilityProvider extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,

    isExtrasBarVisible: PropTypes.bool.isRequired,
    isPAConstructorShown: PropTypes.bool.isRequired,
    isFConstructorShown: PropTypes.bool.isRequired,
    isFConstructorPreviewShown: PropTypes.bool.isRequired,
    isFConstructorLoaded: PropTypes.bool.isRequired,
    isWizardLoaded: PropTypes.bool.isRequired,
    isTodoListMaximized: PropTypes.bool.isRequired,
    isVersionsMode: PropTypes.bool.isRequired,
  };

  render() {
    const {
      isExtrasBarVisible,
      isPAConstructorShown,
      isFConstructorShown,
      isFConstructorLoaded,
      isTodoListMaximized,
      isWizardLoaded,
      isFConstructorPreviewShown,
      isVersionsMode,
    } = this.props;

    const isTodoListMinimizedInPreviewMode = (
      isFConstructorPreviewShown &&
      !isTodoListMaximized
    );

    return this.props.children({
      needShowRightPanel: !isTodoListMinimizedInPreviewMode,
      needRenderConstructorsButtons: (
        !isPAConstructorShown &&
        !isFConstructorShown &&
        !isVersionsMode &&
        isExtrasBarVisible
      ),
      needRenderPAConstructor: (
        isPAConstructorShown &&
        isFConstructorLoaded
      ),
      needRenderFConstructor: (
        isFConstructorShown &&
        isFConstructorLoaded &&
        !(isTodoListMaximized && isFConstructorPreviewShown)
      ),
      needRenderWizardTodoListMaximized: (
        !isPAConstructorShown &&
        !isVersionsMode &&
        isWizardLoaded &&
        isTodoListMaximized &&
        (!isFConstructorShown || isFConstructorPreviewShown)
      ),
      needRenderVersionsPanel: isVersionsMode,
    });
  }
}
