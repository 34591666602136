import PropTypes from 'prop-types';
import { Component } from 'react';

import { hasMagneticLines } from '../../../helpers/elemTypes';
import {
  ghostSnapToFieldHelper,
  getFillableElementsByPage,
  getSimpleElementsByPageExceptSome,
  showMagneticLinesOnlyForNearestElements,
} from '../../../helpers/magneticLinesHelpers';
import { isFillable } from '../../../store/helpers/functions';
import { selectors } from '../../..';
import config from '../../../helpers/clientConfig';

const getElementsSimpleOrFillable = (state, isGhostFillable) => {
  return (
    isGhostFillable
      ? getFillableElementsByPage({
        elements: selectors.elements.getElements(state),
        activePageId: selectors.base.getActivePageId(state),
      })
      : getSimpleElementsByPageExceptSome({
        elements: selectors.elements.getElements(state),
        activePageId: selectors.base.getActivePageId(state),
      })
  );
};

export default class MagneticLinesProvider extends Component {
  static propTypes = {
    scale: PropTypes.number.isRequired,
    frameOffset: PropTypes.shape({
      scrollTop: PropTypes.number.isRequired,
      top: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
      scrollLeft: PropTypes.number.isRequired,
    }).isRequired,
    workspace: PropTypes.shape({
      top: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      framePadding: PropTypes.shape({
        top: PropTypes.number.isRequired,
        bottom: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        right: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    ghost: PropTypes.shape({
      type: PropTypes.string,
      content: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    frameScroll: PropTypes.shape({
      scrollTop: PropTypes.number.isRequired,
      scrollLeft: PropTypes.number.isRequired,
    }).isRequired,
    isMouseOverPage: PropTypes.bool.isRequired,

    getScaledGhostPositionForRender: PropTypes.func.isRequired,
    getScaledGhostPosition: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
  };

  static contextTypes = {
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.lines = [];
  }

  setMagneticLines = (lines) => {
    if (lines.length > 1) {
      const { workspace, frameOffset } = this.props;
      const leftPadding = workspace.left + workspace.framePadding.left + frameOffset.left;
      const topPadding = workspace.top + workspace.framePadding.top + frameOffset.top;

      const { x: left, y: top, width, height } = this.props.getScaledGhostPosition();
      this.lines = showMagneticLinesOnlyForNearestElements({
        lines,
        activeElementShape: {
          left: (left + leftPadding) - frameOffset.scrollLeft,
          top: (top + topPadding) - frameOffset.scrollTop,
          right: (left + width + leftPadding) - frameOffset.scrollLeft,
          bottom: (top + height + topPadding) - frameOffset.scrollTop,
        },
      });
    } else {
      this.lines = lines;
    }
  };

  getMagneticPositionForRender = () => {
    const state = this.context.store.getState();
    const {
      ghost,
      workspace,
      scale,
      frameOffset,
      frameScroll,
      isMouseOverPage,
    } = this.props;
    const position = this.props.getScaledGhostPositionForRender();
    const isGhostFillable = isFillable(ghost);

    if (hasMagneticLines(ghost.type) || isGhostFillable) {
      return ghostSnapToFieldHelper({
        elements: getElementsSimpleOrFillable(state, isGhostFillable),
        setMagneticLines: this.setMagneticLines,
        scale,
        position,
        ghost,
        workspace,
        frameOffset,
        frameScroll,
        isMouseOverPage,
      });
    }

    return position;
  };

  getMagneticPositionForCreating = () => {
    const state = this.context.store.getState();
    const {
      ghost,
      scale,
      isMouseOverPage,
    } = this.props;
    const position = this.props.getScaledGhostPosition();
    const isGhostFillable = isFillable(ghost);

    if (hasMagneticLines(ghost.type) || isGhostFillable) {
      return {
        ...position,
        ...ghostSnapToFieldHelper({
          elements: getElementsSimpleOrFillable(state, isGhostFillable),
          setMagneticLines: this.setMagneticLines,
          scale,
          position,
          ghost,
          isMouseOverPage,
        }),
      };
    }

    return position;
  }

  render() {
    if (!config.app.ghostMagneticLinesOn) {
      return (
        this.props.children({
          magneticLines: [],
          getMagneticPositionForRender: this.props.getScaledGhostPositionForRender,
          getMagneticPositionForCreating: this.props.getScaledGhostPosition,
        })
      );
    }

    return (
      this.props.children({
        magneticLines: this.lines,
        getMagneticPositionForRender: this.getMagneticPositionForRender,
        getMagneticPositionForCreating: this.getMagneticPositionForCreating,
      })
    );
  }
}
