import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { TODO_LIST_STATES as todoListStates } from 'ws-editor-lib/constants';
import * as lazySelectors from '@pdffiller/jsf-lazyload/store/selectors';
import { selectors } from '../..';

const mapStateToProps = (state) => {
  return {
    isFConstructorLoaded: state.lazyLoad.fConstructor,
    isPAConstructorShown: state.viewport.isPAConstructorShown,
    isFConstructorShown: state.viewport.isFConstructorShown,
    isFConstructorPreviewShown: get(state, 'fConstructor.isFConstructorPreviewShown', false),
    isWizardLoaded: lazySelectors.common.getIsWizardLoaded(state),
    todoListState: selectors.base.getTodoListState(state),
    isVersionsMode: selectors.mode.isVersions(state),

    isFConstructorBarVisible:
      selectors.feature.isFConstructorAvailable(state),
    isWatermarkBarVisible:
      selectors.feature.isWatermarkBarAvailable(state),
    isVersionsBarVisible:
      selectors.feature.isVersionsBarAvailable(state),
  };
};

class RightPanelDataProvider extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,

    isFConstructorBarVisible: PropTypes.bool.isRequired,
    isWatermarkBarVisible: PropTypes.bool.isRequired,
    isVersionsBarVisible: PropTypes.bool.isRequired,

    isPAConstructorShown: PropTypes.bool.isRequired,
    isFConstructorShown: PropTypes.bool.isRequired,
    isFConstructorLoaded: PropTypes.bool.isRequired,
    isFConstructorPreviewShown: PropTypes.bool.isRequired,
    isWizardLoaded: PropTypes.bool.isRequired,
    todoListState: PropTypes.oneOf(Object.values(todoListStates)),
    isVersionsMode: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    todoListState: todoListStates.minimized,
  };

  getIsExtrasBarVisible = () => {
    const {
      isFConstructorBarVisible,
      isVersionsBarVisible,
      isWatermarkBarVisible,
    } = this.props;

    return (
      isFConstructorBarVisible || isVersionsBarVisible || isWatermarkBarVisible
    );
  }

  render() {
    return this.props.children({
      isExtrasBarVisible: this.getIsExtrasBarVisible(),
      isPAConstructorShown: this.props.isPAConstructorShown,
      isFConstructorShown: this.props.isFConstructorShown,
      isFConstructorLoaded: this.props.isFConstructorLoaded,
      isFConstructorPreviewShown: this.props.isFConstructorPreviewShown,
      isWizardLoaded: this.props.isWizardLoaded,
      isTodoListMaximized: this.props.todoListState === todoListStates.maximized,
      isVersionsMode: this.props.isVersionsMode,
    });
  }
}

export default connect(mapStateToProps, null)(RightPanelDataProvider);
