import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { addComment, trackPoint } from 'ws-editor-lib/actions';

import { getObjectForAppendToNewElement, applyDateStampIfNeed } from './ghostUtils';
import { cancellableOpts } from '../../store/modules/undoRedo';
import { elemSubTypes, elemTypes, isCommentTool, isTextToolBasedElement } from '../../helpers/elemTypes';
import { pageClickBlockerClassname, toolsTrackPoints } from '../../helpers/const';
import { selectors, thunks } from '../..';
import { hasClass } from '../../helpers/utils';
import addNewRadioFillableElements from '../../jsf-radio/addNewRadioFillableElements';
import activateDefaultTool from '../../store/thunks/activateDefaultTool';
import { getIsAdditionalToolId } from '../../../jsfiller3/src/modules/fConstructor/helpers/utils';

@connect(
  (state) => {
    return {
      isElementCreationAvailable: selectors.getIsElementCreationAvailable(state),
      activeTool: selectors.base.getActiveTool(state),
      defaultTool: selectors.getDefaultTool(state),
      ghostElement: selectors.base.getGhostElement(state),
      isConstructorMode: selectors.mode.isConstructor(state),
      useSigDateStamp: get(state, 'ws.defaults.useSigDateStamp', false),
      isDrawingNewGraphicElement: state.events.isDrawingNewGraphicElement,
    };
  }, {
    addNewRadioFillableElements,
    activateDefaultTool,
    addComment,
    trackPoint,
    addElement: thunks.addElement,
    setActiveElement: thunks.setActiveElement,
    addFillableElement: thunks.addFillableElement,
  },
)
export default class GhostElementCreator extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    isElementCreationAvailable: PropTypes.bool.isRequired,
    activeTool: PropTypes.shape({
      subType: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
    defaultTool: PropTypes.shape({
      subType: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
    getGhostPosition: PropTypes.func.isRequired,
    scale: PropTypes.number.isRequired,
    ghostElement: PropTypes.shape({
      subType: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      content: PropTypes.object,
      template: PropTypes.object,
    }).isRequired,
    ghostPageId: PropTypes.number.isRequired,
    hideGhost: PropTypes.func.isRequired,
    addElement: PropTypes.func.isRequired,
    addFillableElement: PropTypes.func.isRequired,
    addComment: PropTypes.func.isRequired,
    setActiveElement: PropTypes.func.isRequired,
    activateDefaultTool: PropTypes.func.isRequired,
    isMouseOverClickBlocker: PropTypes.bool.isRequired,
    isMouseOverElement: PropTypes.bool.isRequired,
    isMouseOverFakeEdit: PropTypes.bool.isRequired,
    isMouseOverPage: PropTypes.bool.isRequired,
    useSigDateStamp: PropTypes.bool.isRequired,
    isConstructorMode: PropTypes.bool.isRequired,
    isDrawingNewGraphicElement: PropTypes.bool.isRequired,
    addNewRadioFillableElements: PropTypes.func.isRequired,
    trackPoint: PropTypes.func.isRequired,
  };

  getGhostPositionForState = (posArg = undefined) => {
    const { x, y } = this.props.getGhostPosition(posArg);
    const { scale } = this.props;
    const scaledPos = {
      x: x / scale,
      y: y / scale,
    };

    return {
      x: +(scaledPos.x.toFixed(2)),
      y: +(scaledPos.y.toFixed(2)),
    };
  };

  addNewElement = (position, text = false) => {
    const { ghostElement, ghostPageId, useSigDateStamp } = this.props;

    if (!ghostElement) {
      return;
    }

    this.props.hideGhost();

    this.props.trackPoint(toolsTrackPoints.ADDED_SIMPLE_TOOL);
    if (isTextToolBasedElement(ghostElement)) {
      this.props.trackPoint(toolsTrackPoints.ADDED_SIMPLE_TEXT);
    }

    this.props.addElement({
      ...ghostElement,
      subType: ghostElement.subType || elemSubTypes.none,
      pageId: ghostPageId,
      content: {
        ...ghostElement.content,
        ...getObjectForAppendToNewElement(ghostElement),
        ...position,
        ...(text && { text }),
        ...applyDateStampIfNeed({ ghost: ghostElement, useSigDateStamp }),
      },
    }, cancellableOpts);

    this.props.setActiveElement(ghostElement.id, true);
  };

  addNewFillableElement = (position) => {
    const { ghostElement, ghostPageId } = this.props;
    const ghostToolId = get(ghostElement, 'template.toolId', '');
    const isAdditionalTool = getIsAdditionalToolId(ghostToolId);

    this.props.hideGhost();

    this.props.trackPoint(toolsTrackPoints.ADDED_FILLABLE_FIELD);

    this.props.addFillableElement({
      ...ghostElement,
      pageId: ghostPageId,
      template: {
        ...ghostElement.template,
        ...position,
      },
    }, { ...cancellableOpts, dontUpdateDefaults: isAdditionalTool });
  };

  addNewComment = (position) => {
    const { ghostElement, ghostPageId } = this.props;

    this.props.addComment({
      pageId: ghostPageId,
      content: {
        ...position,
      },
      id: ghostElement.id,
    });

    this.props.hideGhost();
    // if we're in the constructor mode and added new fillable element on a page -
    // activating default constructor tool
    this.props.activateDefaultTool();
  }

  createSimpleText = (event, text) => {
    const position = this.getGhostPositionForState();
    this.addNewElement(position, text);
  };

  onClick = (event) => {
    const { target } = event;
    const {
      isMouseOverClickBlocker,
      isMouseOverElement,
      isMouseOverFakeEdit,
      isMouseOverPage,
      isDrawingNewGraphicElement,
      ghostElement,
      ghostPageId,
    } = this.props;

    if (
      isMouseOverClickBlocker ||
      isMouseOverElement ||
      isMouseOverFakeEdit ||
      !isMouseOverPage
    ) {
      return;
    }

    if (!this.props.isElementCreationAvailable) {
      return;
    }
    if (hasClass(target, pageClickBlockerClassname)) {
      return;
    }

    const position = this.getGhostPositionForState();

    if (this.props.isConstructorMode) {
      if (this.props.activeTool.type === elemTypes.radio) {
        this.props.hideGhost();
        this.props.addNewRadioFillableElements({
          position,
          ghostPageId,
          ghostElement,
        });
        this.props.activateDefaultTool();
        return;
      }

      this.addNewFillableElement(position);

      // когда event.shiftKey === true: можем добавить сразу несколько филдов в
      // документ без необходимости повторного активирования их в конструкторе
      if (!event.shiftKey) {
        this.props.activateDefaultTool();
        return;
      }

      return;
    }

    if (isCommentTool(this.props.activeTool.type)) {
      this.addNewComment(position);
      return;
    }

    if (!isDrawingNewGraphicElement) {
      this.addNewElement(position);
    }
  };

  onKeyDown = (event, text) => {
    if (this.props.isConstructorMode) {
      const position = this.getGhostPositionForState();
      this.addNewFillableElement(position);
      return;
    }

    this.createSimpleText(event, text);
  }

  render() {
    return this.props.children({
      createElement: this.onClick,
      createElementByKeyboard: this.onKeyDown,
    });
  }
}
