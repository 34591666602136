import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from 'jsfcore/jsf-validations';

@connect(null, {
  setConstructorError: actions.errors.setConstructorError,
})
export default class ValidationErrorSetter extends Component {
  static propTypes = {
    elementId: PropTypes.string.isRequired,
    isInvalid: PropTypes.bool,
    setConstructorError: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isInvalid: false,
  }

  componentDidMount = () => {
    if (this.props.isInvalid) {
      this.props.setConstructorError({
        id: this.props.elementId,
        isValid: !this.props.isInvalid,
      });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isInvalid !== nextProps.isInvalid) {
      this.props.setConstructorError({
        id: nextProps.elementId,
        isValid: !nextProps.isInvalid,
      });
    }
  }


  render() {
    return null;
  }
}
