import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { selectors } from '../..';

@connect(
  (state) => {
    return {
      hasActiveElement: selectors.base.getActiveElementId(state) !== false,
      isGhostHiddenByState: selectors.getIsGhostHidden(state),
    };
  },
)
export default class GhostVisibilityProvider extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,

    hasActiveElement: PropTypes.bool.isRequired,

    isGhostHiddenByState: PropTypes.bool.isRequired,
    // isMouseOverPage: PropTypes.bool.isRequired,
    isMouseOverClickBlocker: PropTypes.bool.isRequired,
    isMouseOverElement: PropTypes.bool.isRequired,
    isMouseOverVisibleBox: PropTypes.bool.isRequired,
    isMouseOverComment: PropTypes.bool.isRequired,
    isMouseOverFakeEdit: PropTypes.bool.isRequired,
  };

  render() {
    const {
      isGhostHiddenByState,
      isMouseOverElement,
      isMouseOverClickBlocker,
      isMouseOverVisibleBox,
      isMouseOverComment,
      isMouseOverFakeEdit,
      hasActiveElement,
    } = this.props;

    const isGhostHiddenWithoutActiveElement =
      isGhostHiddenByState && hasActiveElement;

    return this.props.children({
      isGhostHidden: (
        isMouseOverElement ||
        isMouseOverClickBlocker ||
        isGhostHiddenByState ||
        isMouseOverComment ||
        isMouseOverFakeEdit ||
        !isMouseOverVisibleBox
      ),
      isGhostHiddenByActiveElement: (
        isMouseOverElement ||
        isMouseOverClickBlocker ||
        isGhostHiddenWithoutActiveElement ||
        !isMouseOverVisibleBox
      ),
    });
  }
}
