import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FrameOffsetProvider from '../../ScrollContextProvider/FrameOffsetProvider';
import ConnectedDateMinWidthProvider from '../../DateMinWidth/DateMinWidthProvider';
import ElementDragFuncProvider from './ElementDragFuncProvider';
import ElementDragProvider from './ElementDragProvider';

export default class ElementDrag extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,

    element: PropTypes.shape({
      id: PropTypes.string.isRequired,
      pageId: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
    isActiveElement: PropTypes.bool.isRequired,
    isDraggingInRedux: PropTypes.bool.isRequired,
    fConstructorLoadedAndShown: PropTypes.bool.isRequired,
    isFillableElement: PropTypes.bool.isRequired,
    boundingEnabled: PropTypes.bool,
    alwaysProvideFunctions: PropTypes.bool,
  };

  static defaultProps = {
    alwaysProvideFunctions: false,
    boundingEnabled: true,
  };

  render() {
    const {
      fConstructorLoadedAndShown,
      isActiveElement,
      isDraggingInRedux,
      isFillableElement,
      boundingEnabled,
      alwaysProvideFunctions,
    } = this.props;

    return (
      <FrameOffsetProvider
        pageId={this.props.element.pageId}
      >
        {({ getFrameOffset }) => {
          return (
            <ElementDragFuncProvider
              fConstructorLoadedAndShown={fConstructorLoadedAndShown}
              element={this.props.element}
              isFillable={isFillableElement}
              boundingEnabled={boundingEnabled}
              getFrameOffset={getFrameOffset}
            >
              {({ getRenderMixin, getActualDraggingGeometry }) => {
                return (
                  <ElementDragProvider
                    getRenderMixin={getRenderMixin}
                    getActualDraggingGeometry={getActualDraggingGeometry}
                    getFrameOffset={getFrameOffset}

                    element={this.props.element}
                    isActiveElement={isActiveElement}
                    isDraggingInRedux={isDraggingInRedux}
                    isFillable={isFillableElement}
                    fConstructorLoadedAndShown={fConstructorLoadedAndShown}
                    alwaysProvideFunctions={alwaysProvideFunctions}
                  >
                    {({
                      onDrag = {},
                      onResize = {},
                      draggableProps = {},
                      element,
                    }) => {
                      return (
                        <ConnectedDateMinWidthProvider
                          element={element}
                          draggableProps={draggableProps}
                        >
                          {({ boundedElement }) => {
                            return (
                              this.props.children({
                                onDrag,
                                onResize,
                                draggableProps,
                                element: boundedElement,
                                getActualDraggingGeometry,
                              })
                            );
                          }}
                        </ConnectedDateMinWidthProvider>
                      );
                    }}
                  </ElementDragProvider>
                );
              }}
            </ElementDragFuncProvider>
          );
        }}
      </FrameOffsetProvider>
    );
  }
}
