import find from 'lodash/find';
import filter from 'lodash/filter';
import startsWith from 'lodash/startsWith';
import flow from 'lodash/flow';
import curryRight from 'lodash/curryRight';
import take from 'lodash/take';


export const selectedValueKey = 'id';

export const elementInList = (options, contentText) => {
  return options.some((option) => {
    return option.label === contentText;
  });
};

export const getDropdownToolOptions = (element, contentText) => {
  if (!element.template || !element.template.list) {
    return [];
  }

  const isElementInList = elementInList(element.template.list, contentText);

  const listWithAdditional = [
    ...element.template.list,
    ...(isElementInList || !contentText
      ? []
      : [{ data: contentText, label: contentText }]),
  ];

  const list = listWithAdditional.map(
    ({ data, label }, index) => {
      return { id: index, label, value: data };
    },
  );

  return list;
};

export const getDropdownToolValue = ({ contentText, options }) => {
  if (contentText === false || contentText === '') {
    return false;
  }

  // we try to match strict, othewise use first occurence
  const startWithEls = filter(options, (el) => {
    return startsWith(el.label, contentText);
  });

  if (!startWithEls.length) {
    return false;
  }

  const strictComparsionFind = find(startWithEls, ['label', contentText]);

  return (
    strictComparsionFind !== undefined
      ? strictComparsionFind
      : startWithEls[0]
  )[selectedValueKey];
};

const verticalAlignMap = {
  top: 'flex-start',
  middle: 'center',
  bottom: 'flex-end',
};

const horizontalAlignMap = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};


export const getDropdownAlign = ({
  valign,
  align,
}) => {
  return {
    display: 'flex',
    width: '100%',
    alignItems: verticalAlignMap[valign],
    justifyContent: horizontalAlignMap[align],
  };
};

const setStartValue = (startValue) => {
  return (content) => {
    return {
      ...content,
      start: startValue,
    };
  };
};

const getStartValue = (idx) => {
  return flow([
    curryRight(take)(idx, 0),
    setStartValue,
  ]);
};

const setHighlightedValue = (highlightedValue) => {
  return (content) => {
    return {
      ...content,
      highlightedText: highlightedValue,
    };
  };
};

const setEndValue = (endValue) => {
  return (content) => {
    return {
      ...content,
      end: endValue,
    };
  };
};

export const optionHighlighter = (highlightedText, label) => {
  const idx = label.toUpperCase().indexOf(highlightedText.toUpperCase());

  return flow([
    getStartValue(idx)(label),
    setHighlightedValue(label.slice(idx, idx + highlightedText.length)),
    setEndValue(label.slice(idx + highlightedText.length)),
  ])({});
};
