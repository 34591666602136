import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { thunks } from '../../..';

@connect(
  (__, { pageId }) => {
    return (state) => {
      return ({
        isActivePage:
          (
            typeof state.navigation.nextActive === 'number'
              ? state.navigation.nextActive
              : state.navigation.activePageId
          ) === pageId,
        thumbnail: state.pdf.thumbnails[pageId],
      });
    };
  }, { changePage: thunks.changePage },
)
export default class ThumbnailProvider extends Component {
  static propTypes = {
    pageId: PropTypes.number.isRequired,
    isWizardLoaded: PropTypes.bool.isRequired,

    // from global.state
    isActivePage: PropTypes.bool.isRequired,
    thumbnail: PropTypes.number,

    // actions
    changePage: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,

    getRefs: PropTypes.func.isRequired,
    storeRefs: PropTypes.func.isRequired,
  };

  static defaultProps = {
    thumbnail: null,
  };

  static contextTypes = {
    getIndexByPageId: PropTypes.func,
    getEvents: PropTypes.func,
  };

  onClickPageItemChange = () => {
    if (this.context.getEvents().pageChanging) {
      return;
    }

    this.props.changePage(this.props.pageId);
  };

  render() {
    return (
      this.props.children({
        isActivePage: this.props.isActivePage,
        thumbnail: this.props.thumbnail,
        onClickPageItemChange: this.onClickPageItemChange,
        index: this.context.getIndexByPageId(this.props.pageId),
        getRefs: this.props.getRefs,
        storeRefs: this.props.storeRefs,
        isWizardLoaded: this.props.isWizardLoaded,
        pageId: this.props.pageId,
      })
    );
  }
}
