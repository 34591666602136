import React from 'react';
import PropTypes from 'prop-types';

import UnfilledSignToolLocaleProvider from './UnfilledSignToolLocaleProvider';
import UnfilledSignToolRender from './UnfilledSignToolRender';

function UnfilledSignTool({
  element,
  elementIcon,
  storeRef,
  onFocus,
  onBlur,
}) {
  return (
    <UnfilledSignToolLocaleProvider
      element={element}
    >
      {({ getUnfilledInnerText }) => {
        return (
          <UnfilledSignToolRender
            elementIcon={elementIcon}
            getUnfilledInnerText={getUnfilledInnerText}

            storeRef={storeRef}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        );
      }}
    </UnfilledSignToolLocaleProvider>
  );
}

UnfilledSignTool.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    subType: PropTypes.string.isRequired,
    template: PropTypes.shape({
      width: PropTypes.number,
    }),
    content: PropTypes.shape({
      subType: PropTypes.string,
    }),
  }).isRequired,
  elementIcon: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.bool,
  ]).isRequired,
  storeRef: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export default UnfilledSignTool;
