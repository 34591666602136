import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { TODO_LIST_STATES as todoListStates } from 'ws-editor-lib/constants';

import { possiblePositions as wrapperPositions } from '../../Portal/helpers/const';
import { presetsNames as wrapperPresets } from '../../Portal/helpers/presets';
import Hint from '../../Hint/Hint';
import { tooltipTimeout } from '../../../helpers/const';
import * as Ui from '../../../ui';
import MessageButtonProvider from '../MessageButtonProvider';

const hintWrapperProps = {
  locatorArgs: {
    position: wrapperPositions.bottomRightEdge,
    preset: wrapperPresets.hint,
  },
};

export default class ToolbarHelpView extends Component {
  static propTypes = {
    onClickFindAnotherForm: PropTypes.func.isRequired,
    openTodoList: PropTypes.func.isRequired,
    todoListStatus: PropTypes.string.isRequired,
    isFindAnotherFormButtonVisible: PropTypes.bool.isRequired,
    isFindAnotherFormBubbleVisible: PropTypes.bool.isRequired,
    isMessageButtonVisible: PropTypes.bool.isRequired,
    isWizardActive: PropTypes.bool.isRequired,
    locale: PropTypes.shape({
      findAnotherForm: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      formLookingFor: PropTypes.string.isRequired,
    }).isRequired,
    localeTodo: PropTypes.shape({
      needToFillTitle: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const {
      onClickFindAnotherForm,
      isFindAnotherFormButtonVisible,
      isFindAnotherFormBubbleVisible,
      openTodoList,
      todoListStatus,
      isWizardActive,
    } = this.props;

    if (thisDevice.isTablet && this.props.isMessageButtonVisible) {
      return null;
    }

    const customStyle = {
      width: '200px',
      position: 'absolute',
      top: 'calc(100% + 10px)',
      right: '50px',
    };

    return (
      <>
        {isFindAnotherFormButtonVisible &&
          <>
            <Ui.Grid.Cell>
              <Ui.Grid.Wrapper>
                <Ui.EditorAction.Index size={Ui.control.sizes.medium}>
                  <Ui.EditorAction.Item>
                    <Ui.Control
                      behavior={Ui.control.behaviors.overflowText}
                      theme={Ui.control.themes.secondary}
                      size={Ui.control.sizes.smallStretched}
                      offset={Ui.control.offsets.small}
                      title={this.props.locale.findAnotherForm}
                      isBusy={false}
                      isPressed={false}
                      onClick={onClickFindAnotherForm}
                      icon={<Ui.Icon type={Ui.icon.types.search} size={Ui.icon.sizes.small} />}
                    >
                      <Ui.Text
                        weight={Ui.text.weights.bold}
                        size={12}
                      >
                        {this.props.locale.findAnotherForm}
                      </Ui.Text>
                    </Ui.Control>
                  </Ui.EditorAction.Item>
                </Ui.EditorAction.Index>
              </Ui.Grid.Wrapper>
              {isFindAnotherFormBubbleVisible &&
                <Ui.Popover.Index
                  theme={Ui.popover.themes.primary}
                  arrowPosition={Ui.popover.arrowPositions.topRight}
                  style={customStyle}
                  tIndex="-1"
                >
                  <Ui.Popover.Body>
                    <Ui.PopoverItem.Index
                      offset={Ui.popoverItem.offsets.large}
                      theme={Ui.popoverItem.themes.info}
                      arrowPosition={Ui.popoverItem.arrowPositions}
                    >
                      <Ui.PopoverItem.Body>
                        <Ui.Group.Index
                          type={Ui.group.types.compact}
                          offset={Ui.group.offsets.small}
                          align={Ui.group.aligns.flexStart}
                        >
                          <Ui.Group.Body>
                            <Ui.Group.Item>
                              <Ui.Icon
                                size={Ui.icon.sizes.extraLarge}
                                type={Ui.icon.types.info}
                                theme={Ui.icon.themes.darkgray}
                              />
                            </Ui.Group.Item>
                            <Ui.Group.Item>
                              <Ui.Text size={14}>
                                {this.props.locale.formLookingFor}
                              </Ui.Text>
                            </Ui.Group.Item>
                          </Ui.Group.Body>
                        </Ui.Group.Index>
                      </Ui.PopoverItem.Body>
                    </Ui.PopoverItem.Index>
                  </Ui.Popover.Body>
                </Ui.Popover.Index>
              }
            </Ui.Grid.Cell>
            <Ui.Grid.Separator />
          </>
        }
        <Ui.Grid.Cell>
          <Ui.ToolBar.Body
            theme={Ui.toolBar.themes.bordered}
            size={Ui.toolBar.sizes.parent}
          >
            <Ui.ToolBar.Main>
              <MessageButtonProvider>
                {({ locale, onClickMessageButton, isMessageButtonVisible }) => {
                  if (isMessageButtonVisible && thisDevice.isDesktop) {
                    return (
                      <Ui.ToolBar.Item>
                        <Ui.Control
                          theme={Ui.control.themes.transparentGray}
                          size={Ui.control.sizes.parent}
                          offset={Ui.control.offsets.medium}
                          behavior={Ui.control.behaviors.overflowText}
                          title={locale.message}
                          isBusy={false}
                          isPressed={false}
                          justify={Ui.control.justifies.flexStart}
                          onClick={onClickMessageButton}
                          inheritRadius
                          icon={
                            <Ui.Icon
                              type={Ui.icon.types.sms}
                              size={Ui.icon.sizes.small}
                            />
                            }
                        >
                          <Ui.Text weight={Ui.text.weights.bold} size={12}>
                            {locale.message}
                          </Ui.Text>
                        </Ui.Control>
                      </Ui.ToolBar.Item>
                    );
                  }
                  return null;
                }}
              </MessageButtonProvider>

              {isWizardActive &&
                <Hint
                  timeout={tooltipTimeout}
                  html={this.props.localeTodo.needToFillTitle}
                  wrapperProps={hintWrapperProps}
                >
                  {({ onMouseEnter, onMouseLeave, storeRef }) => {
                    return (
                      <Ui.ToolBar.Item>
                        <Ui.Control
                          theme={Ui.control.themes.transparentGray}
                          size={Ui.control.sizes.parent}
                          offset={Ui.control.offsets.medium}
                          behavior={Ui.control.behaviors.overflowText}
                          justify={Ui.control.justifies.flexStart}
                          isBusy={false}
                          onClick={openTodoList}
                          inheritRadius
                          isPressed={todoListStatus === todoListStates.maximized}
                          icon={<Ui.Icon type={Ui.icon.types.todo} size={Ui.icon.sizes.large} />}
                          onMouseEnter={onMouseEnter}
                          onMouseLeave={onMouseLeave}
                          storeRef={storeRef}
                        >
                        </Ui.Control>
                      </Ui.ToolBar.Item>
                    );
                  }}
                </Hint>
                }
            </Ui.ToolBar.Main>
          </Ui.ToolBar.Body>
        </Ui.Grid.Cell>
      </>
    );
  }
}
