import * as GhostMobile from './Mobile';
import MouseListener from './GhostMouseListener';
import DataProvider from './GhostDataProvider';
import Renderer from './GhostRenderer';
import VisibilityProvider from './GhostVisibilityProvider';
import Limiter from './GhostLimiter';
import ElementCreator from './GhostElementCreator';
import LayerSimplePaginationWrapper from './GhostLayerSimplePaginationWrapper';
import CreateTrigger from './GhostCreateTrigger';
import KeyboardCreateTrigger from './GhostKeyboardCreateTrigger';
import FreeGhost from './FreeGhost';
import FreeGhostMobile from './FreeGhostMobile';
import MagneticLinesProvider from './MagneticLines/MagneticLinesProvider';
import MagneticLinesLimiter from './MagneticLines/MagneticLinesLimiter';
import renderGhost from './renderGhost';
import ElementGhost from './ElementGhost';

// Renderer subcomponents
import GhostLayerView from './GhostLayerView';
import GhostWrapperView from './GhostWrapperView';
// / Renderer subcomponents

import { haveDataForRenderGhost } from './ghostUtils';

const Mobile = {
  renderGhost: GhostMobile.renderGhost,
  DataProvider: GhostMobile.GhostDataProvider,
  ElementCreator: GhostMobile.GhostElementCreator,
};

export {
  Mobile,
  MouseListener,
  DataProvider,
  Renderer,
  VisibilityProvider,
  Limiter,
  ElementCreator,
  LayerSimplePaginationWrapper,
  CreateTrigger,
  KeyboardCreateTrigger,
  FreeGhost,
  FreeGhostMobile,
  MagneticLinesProvider,
  MagneticLinesLimiter,
  renderGhost,
  GhostLayerView,
  GhostWrapperView,
  ElementGhost,
  haveDataForRenderGhost,
};
