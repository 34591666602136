import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

@connect(
  (__, { pageId }) => {
    return (state) => {
      return {
        originalSize: state.navigation.originalSizes[pageId],
      };
    };
  },
)
export default class OriginalSizeProvider extends Component {
  static propTypes = {
    originalSize: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),

    children: PropTypes.func.isRequired,
  };


  static defaultProps = {
    originalSize: null,
  };

  render() {
    return (
      this.props.children({
        originalSize: this.props.originalSize,
      })
    );
  }
}
