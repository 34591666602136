import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { selectors } from '../..';

@connect(
  (__, { pageId }) => {
    return (
      (state) => {
        return {
          isContentShown: selectors.getIsContentShown(state, pageId),
        };
      }
    );
  },
)
export default class IfContentShown extends Component {
  static propTypes = {
    isContentShown: PropTypes.bool.isRequired,
    children: PropTypes.element.isRequired,
  };

  render() {
    if (this.props.isContentShown) {
      return this.props.children;
    }

    return null;
  }
}
