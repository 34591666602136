import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { cancellableOpts } from '../../../store/modules/undoRedo';
import { getElementProps } from '../../../store/helpers/functions';
import { Select, Creatable } from '../../Select';
import { getDropdownToolOptions, getDropdownToolValue, selectedValueKey } from './dropdownToolUtils';

export default class SelectDropdown extends Component {
  static propTypes = {
    element: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    updateElement: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    selectOptions: PropTypes.shape({}),
    placeholder: PropTypes.string,
    placeholderStyle: PropTypes.shape({}),
    isWizardAssistant: PropTypes.bool,
    storeRef: PropTypes.func,
  };

  static defaultProps = {
    selectOptions: {},
    disabled: false,
    placeholder: '',
    placeholderStyle: null,
    isWizardAssistant: null,
    storeRef: null,
    onChange: () => {},
  };

  componentDidMount() {
    const { isWizardAssistant } = this.props;

    if (isWizardAssistant) {
      // focus and openMenu doesn't work without setTimeout
      setTimeout(this.focusSelect, 0);
    }
  }

  getDropdownComponent = (allowCustomText) => {
    return allowCustomText
      ? Creatable
      : Select;
  };

  storeSelectRef = (ref) => {
    const { storeRef } = this.props;

    if (storeRef) {
      storeRef(ref);
    }

    this.selectRef = ref;
  };

  focusSelect = () => {
    // two cases: Select and CreatableSelect
    const select = get(this.selectRef, 'select', this.selectRef);
    if (select && !select.state.isOpen) {
      select.focus();
      select.openMenu();
    }
  };

  onChange = (option) => {
    const { value, label } = option || { value: '', label: '' };

    this.props.onChange(value);

    this.props.updateElement(this.props.element.id, {
      text: (value === -1)
        ? ''
        : label,
    }, cancellableOpts);
  };

  onInputKeyDown = (event) => {
    // CTRL(CMD) + ENTER
    if (event.keyCode === 13 && (event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      event.stopPropagation();

      const select = get(this.selectRef, 'select', this.selectRef);
      if (select) {
        select.selectFocusedOption();
      }
    }
  };

  render = () => {
    const { disabled, selectOptions, placeholderStyle } = this.props;
    const {
      text: contentText,
      allowCustomText,
      placeholder,
    } = getElementProps(this.props.element);

    const options = getDropdownToolOptions(this.props.element, contentText);

    const dropdownPlaceholder = placeholder || '';

    const value = getDropdownToolValue({
      contentText,
      options,
    });

    const SelectDropdownComponent = this.getDropdownComponent(allowCustomText);

    const placeholderWithStyle = placeholderStyle
      ? <div style={placeholderStyle}>{dropdownPlaceholder}</div>
      : dropdownPlaceholder;

    return (
      <SelectDropdownComponent
        placeholder={placeholderWithStyle}
        onChange={this.onChange}
        disabled={disabled}
        value={value}
        valueKey={selectedValueKey}
        options={options}
        matchProp="label"
        {...selectOptions}
        storeRef={this.storeSelectRef}
        onInputKeyDown={this.onInputKeyDown}
      />
    );
  }
}
