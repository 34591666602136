import PropTypes from 'prop-types';
import React from 'react';

import * as Ui from '../../../ui';

const headerSearchView = ({ onClick, isSearchModeActive, label }) => {
  return (
    <Ui.Container.SettingButton
      icon={Ui.icon.types.searchText}
      label={label}
      title={Ui.locales.titles.search}
      size={Ui.icon.sizes.large}
      offset={Ui.control.offsets.extraSmall}
      textOffset={Ui.text.offsets.topExtraSmall}
      isPressed={isSearchModeActive}
      onClick={onClick}
    />
  );
};

headerSearchView.propTypes = {
  isSearchModeActive: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default headerSearchView;
