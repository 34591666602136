import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { thisDevice } from '@pdffiller/jsf-useragent';

import { svgStyle, svgFillableStyle, wrapperDivStyle,
  getValidColor } from '../../../helpers/graphicUtils';
import { mouseFocusClassName, longTapClassName } from '../../../helpers/const';
import { elemTypes } from '../../../helpers/elemTypes';
import { getElementCursor } from '../../../helpers/utils';

export default class CurveToolView extends Component {
  static propTypes = {
    fillColor: PropTypes.string.isRequired,
    lineWidth: PropTypes.number.isRequired,
    viewBox: PropTypes.string.isRequired, // SVG viewBox
    paths: PropTypes.arrayOf(PropTypes.string).isRequired, // SVG paths
    isFillable: PropTypes.bool, // from SignTool only
    type: PropTypes.string,
    isGhost: PropTypes.bool, // from ElementGhost->SignTool only
    isDisabled: PropTypes.bool,

    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    storeRef: PropTypes.func,
  };

  static defaultProps = {
    isDisabled: false,
    isGhost: false,
    isFillable: false,
    type: null,
    onFocus: null,
    onBlur: null,
    storeRef: null,
  };

  getWrapperStyles = () => {
    const cursor = getElementCursor(this.props.isDisabled);

    return { cursor, ...wrapperDivStyle };
  }

  render() {
    const {
      fillColor,
      lineWidth,
      viewBox,
      paths,
      type,
      isGhost,
      isDisabled,
    } = this.props;

    const pathProps = {
      className: classnames({
        'elementHover-Content': !isDisabled,
        [mouseFocusClassName]: !isDisabled && !isGhost && (!type || type !== elemTypes.erase),
        [longTapClassName]: !isDisabled && thisDevice.isMobile && type === elemTypes.erase,
      }),
      fill: 'none',
      stroke: getValidColor(fillColor),
    };

    const wrapperStyles = this.getWrapperStyles();

    const style = {
      ...svgStyle,
      strokeWidth: lineWidth,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      ...(this.props.isFillable
        ? svgFillableStyle
        : {}),
    };

    return (
      <div
        style={wrapperStyles}
        className="CurveToolView-tabindex"
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        ref={this.props.storeRef}
        tabIndex={0}
      >
        <svg
          style={style}
          viewBox={viewBox}
          preserveAspectRatio={
            this.props.isFillable
              ? 'xMinYMin meet'
              : 'none'
          }
        >
          <g>
            {paths.map((path) => {
              return (
                <path
                  key={path}
                  d={path}
                  {...pathProps}
                />
              );
            })}
          </g>
        </svg>
      </div>
    );
  }
}
