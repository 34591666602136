import PropTypes from 'prop-types';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import {
  wizard,
  comments,
  fakeEdit,
  LazyComponent,
} from '@pdffiller/jsf-lazyload';
import * as lazySelectors from '@pdffiller/jsf-lazyload/store/selectors';

import * as Conditions from '@pdffiller/jsf-conditions/components';

import { selectors } from '../..';
import { drawingLayerTypes } from '../../helpers/const';

import PageAttributesLayer from './PageAttributesLayer/PageAttributesLayer';
import ElementProvider from '../Element/ElementProvider';
import renderElement from '../Element/renderElement';
import GhostButton from '../Ghost/GhostButton';

import {
  DrawingLayerView,
  LockLayerView,
  SearchLayer,
  ClickController,
  WrapperView,
  ElementsProvider,
} from '.';

@connect(
  (_, { pageId }) => {
    return (state) => {
      return {
        isActivePage: selectors.getIsActivePage(state, pageId),
        isContentLocked: selectors.getIsContentLocked(state, pageId),
        drawingLayerType: selectors.getDrawingLayerType(state),
        isPAConstructorLoadedAndShown: selectors.mode.isPageAttributes(state),
        isSearchModeActive: selectors.mode.isSearch(state),
        scale: selectors.getScale(state, pageId),
        isWizardContentVisible: selectors.getIsWizardContentVisible(state),
        isAttributesInitialized: selectors.isAttributesInitialized(state),
        isFakeEditActive: (
          lazySelectors.jsf.getIsFakeEditLoaded(state) &&
          selectors.fakeEdit.getIsFakeEditActive(state)
        ),
        isCommentsModeActive: selectors.mode.isComments(state),
      };
    };
  },
)
export default class Content extends Component {
  static propTypes = {
    pageId: PropTypes.number.isRequired,
    isActivePage: PropTypes.bool.isRequired,
    isContentLocked: PropTypes.bool.isRequired,
    drawingLayerType: PropTypes.oneOf(
      Object.values(drawingLayerTypes),
    ).isRequired,
    isSearchModeActive: PropTypes.bool.isRequired,
    scale: PropTypes.number.isRequired,
    isWizardContentVisible: PropTypes.bool.isRequired,
    isPAConstructorLoadedAndShown: PropTypes.bool.isRequired,
    isAttributesInitialized: PropTypes.bool.isRequired,
    isFakeEditActive: PropTypes.bool.isRequired,
    isCommentsModeActive: PropTypes.bool.isRequired,
  };

  render() {
    const {
      pageId,
      isActivePage,
      isContentLocked,
      drawingLayerType,
      isSearchModeActive,
      scale,
      isWizardContentVisible,
      isPAConstructorLoadedAndShown,
      isAttributesInitialized,
      isFakeEditActive,
      isCommentsModeActive,
    } = this.props;

    return (
      <Fragment>
        <WrapperView scale={scale} pageId={pageId}>
          {isActivePage && isSearchModeActive && (
            <SearchLayer pageId={pageId} />
          )}
          <ElementsProvider pageId={pageId}>
            {({ otherElementsIds, eraseElementsIds }) => {
              return (
                <ClickController className="elementsWrapper-Content">
                  <Conditions.ChoiseByClickingOpacityCanvas />

                  <GhostButton />

                  {isActivePage && isFakeEditActive &&
                    <LazyComponent
                      literal={fakeEdit.components.FakeEditLayer}
                      props={{ pageId }}
                    />
                  }
                  {eraseElementsIds.map((elementId) => {
                    return (
                      <ElementProvider
                        elementId={elementId}
                        key={elementId}
                      >
                        {renderElement}
                      </ElementProvider>
                    );
                  })}
                  {isAttributesInitialized &&
                    <PageAttributesLayer pageId={pageId} />
                  }
                  {otherElementsIds.map((elementId) => {
                    return (
                      <ElementProvider
                        elementId={elementId}
                        key={elementId}
                      >
                        {renderElement}
                      </ElementProvider>
                    );
                  })}

                  {isPAConstructorLoadedAndShown &&
                    <LockLayerView backgroundType="white" />
                  }
                </ClickController>
              );
            }}
          </ElementsProvider>
          {isContentLocked && <LockLayerView />}
        </WrapperView>
        {drawingLayerType !== drawingLayerTypes.none &&
          <DrawingLayerView
            drawingLayerType={drawingLayerType}
            pageId={pageId}
          />
        }
        {isWizardContentVisible &&
          <LazyComponent
            literal={wizard.components.wizardContent}
            props={{ pageId }}
          />
        }
        {isCommentsModeActive &&
          <LazyComponent
            literal={comments.components.contentLayer}
            props={{ pageId, scale }}
          />
        }
      </Fragment>
    );
  }
}
