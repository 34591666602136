import PropTypes from 'prop-types';
import React from 'react';
import Hint from 'jsfcore/components/Hint/Hint';
import * as Ui from '../../../../ui';

const ToolbarUndoRedoButton = ({
  hintHtml, isDisabled, onClick, icon,
}) => {
  return (
    <Hint html={hintHtml}>
      {({
        children,
        onMouseEnter,
        onMouseLeave,
        storeRef,
      }) => {
        return (
          <Ui.Container.SettingButton
            icon={icon}
            input={children}
            isDisabled={isDisabled}
            size={Ui.icon.sizes.large}
            storeRef={storeRef}
            textOffset={Ui.text.offsets.topExtraSmall}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            inheritRadius
            attributes={Ui.attributes.grow}
          />
        );
      }}
    </Hint>
  );
};

ToolbarUndoRedoButton.propTypes = {
  hintHtml: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.oneOf(Object.values(Ui.icon.types)).isRequired,
};

export default ToolbarUndoRedoButton;
