import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { thisDevice } from '@pdffiller/jsf-useragent';
import { toggleMobileToolsVisibility } from '../../store/modules/viewport';
import { toolsDropDownButtonId } from '../../helpers/const';
import { elemTypes, elemSubTypes, getHeaderButtonClasses } from '../../helpers/elemTypes';
import { getTypeSubType } from '../../helpers/utils';
import { selectors } from '../../index';

import * as Portal from '../Portal';
import StoreRefProvider from '../StoreRef/StoreRefProvider';
import ToolsDropDown from './MobileToolsDropDown/ToolsDropDown';

import * as Ui from '../../ui';
import ActivateToolProviderPhone from '../Header/HeaderTools/ActivateToolProviderPhone';
import ClickToolProviderPhone from '../Header/HeaderTools/ClickToolProviderPhone';

const iconMapper = {
  'tools.text.none': Ui.icon.types.text,
  'tools.checkmark.x': Ui.icon.types.cross,
  'tools.checkmark.v': Ui.icon.types.check,
  'tools.checkmark.o': Ui.icon.types.circle,
  'tools.signature.none': Ui.icon.types.sign,
  'tools.text.date': Ui.icon.types.date,
  'tools.image.none': Ui.icon.types.redesignPicture,
  'tools.erase.none': Ui.icon.types.erase,
  'tools.highlight.none': Ui.icon.types.highlight,
  'tools.blackout.none': Ui.icon.types.blackout,
  'tools.textbox.none': Ui.icon.types.textbox,
  'tools.arrow.none': Ui.icon.types.arrowUpright,
  'tools.line.none': Ui.icon.types.line,
  'tools.pen.none': Ui.icon.types.draw,
  'tools.sticky.none': Ui.icon.types.selection,
};

const getIconAndLabelByType = (activeTool, locale) => {
  const path = !activeTool
    ? `tools.${elemTypes.text}.${elemSubTypes.none}`
    : `tools.${activeTool.type}.${activeTool.subType}`;

  return {
    icon: iconMapper[path],
    label: locale[path],
  };
};

@connect(
  (state) => {
    return {
      activeTool: selectors.base.getActiveTool(state),
      mobileToolsAreVisible: state.viewport.mobileToolsAreVisible,
      toolbar: state.ws.features.toolbar,
      useSignNowTheme: selectors.getUseSignNowTheme(state),
      locale: selectors.locale.getMobileToolsLabelsLocale(state),
    };
  }, {
    toggleMobileToolsVisibility,
  },
)
export default class MobileTools extends Component {
  static propTypes = {
    activeTool: PropTypes.shape({
      type: PropTypes.oneOf(
        Object.values(elemTypes),
      ),
      subType: PropTypes.string,
    }),
    toolbar: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      visible: PropTypes.bool.isRequired,
    })).isRequired,
    mobileToolsAreVisible: PropTypes.bool.isRequired,
    locale: PropTypes.shape({}).isRequired,
    useSignNowTheme: PropTypes.bool.isRequired,

    toggleMobileToolsVisibility: PropTypes.func.isRequired,
  };

  static defaultProps = {
    activeTool: null,
  };

  handleOnToolClick = (onClick, params = {}) => {
    return () => {
      return onClick(params);
    };
  };

  renderToolsInHeader = () => {
    const {
      toolbar,
      useSignNowTheme,
    } = this.props;

    return (
      <ActivateToolProviderPhone>
        {({
          activeTool,
          activeElement,
          wizardActive,
          activateTool,
          setActiveElement,
          setIsSearchModeActive,
        }) => {
          return (
            <ClickToolProviderPhone
              activeTool={activeTool}
              activateTool={activateTool}
              wizardActive={wizardActive}
              activeElement={activeElement}
              setActiveElement={setActiveElement}
              setIsSearchModeActive={setIsSearchModeActive}
            >
              {({ onClick }) => {
                return (
                  <Ui.ToolBar.Group attributes={Ui.attributes.grow}>
                    {
                      toolbar
                        .filter((el) => {
                          return el.visible;
                        })
                        .map((tool) => {
                          const { type, subType } = getTypeSubType(tool.id);

                          return (
                            <Ui.Container.SettingButton
                              key={tool.id}
                              id={toolsDropDownButtonId}
                              icon={getHeaderButtonClasses(useSignNowTheme)[tool.id]}
                              size={Ui.icon.sizes.medium}
                              behavior={Ui.control.behaviors.null}
                              offset={Ui.control.offsets.medium}
                              label={tool.label}
                              textSize={13}
                              onClick={this.handleOnToolClick(onClick, { type, subType })}
                              inheritRadius
                              isPressed={activeTool && activeTool.type === type && (
                                activeTool.subType === subType || subType === '*'
                              )}
                            />
                          );
                        })
                    }
                  </Ui.ToolBar.Group>
                );
              }}
            </ClickToolProviderPhone>
          );
        }}
      </ActivateToolProviderPhone>
    );
  };

  render() {
    const {
      mobileToolsAreVisible,
      activeTool,
      toolbar,
      locale,
    } = this.props;

    const { icon, label } = getIconAndLabelByType(activeTool, locale);
    const visibleTools = toolbar.filter((tool) => {
      return tool.visible;
    });

    if (visibleTools.length > 2) {
      return (
        <StoreRefProvider>
          {({ storeRef, getRef }) => {
            return (
              <Fragment>
                <Ui.ToolBar.Group attributes={Ui.attributes.grow}>
                  <Ui.Container.SettingButton
                    id={toolsDropDownButtonId}
                    isPressed={mobileToolsAreVisible}
                    icon={icon}
                    type={Ui.control.types.dropdown}
                    behavior={Ui.control.behaviors.null}
                    size={Ui.icon.sizes.small}
                    label={label}
                    textSize={13}
                    dataPointer={Ui.control.dataPointers.arrow}
                    onClick={this.props.toggleMobileToolsVisibility}
                    inheritRadius
                    storeRef={storeRef}
                  />
                </Ui.ToolBar.Group>
                {thisDevice.isMobile &&
                  <Portal.Wrapper
                    getRef={getRef}
                    useArrow={false}
                    width="100%"
                  >
                    <ToolsDropDown />
                  </Portal.Wrapper>
                }
              </Fragment>
            );
          }}
        </StoreRefProvider>

      );
    }

    return this.renderToolsInHeader();
  }
}
