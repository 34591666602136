import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CurveToolView from './CurveToolView';
import { defaultZoomScale, defaultControlPoints } from '../../../helpers/const';
import { pathFromPoints, getViewbox } from '../../../helpers/graphicUtils';

export default class CurveTool extends Component {
  static propTypes = {
    // not required because it's not passed in ElementGhost
    controlPoints: PropTypes.arrayOf(PropTypes.number),
    fillColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    lineWidth: PropTypes.number.isRequired,
    scale: PropTypes.number,
    type: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,

    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    storeRef: PropTypes.func.isRequired,
    interceptFocusDataset: PropTypes.shape({}),
  };

  static defaultProps = {
    controlPoints: defaultControlPoints,
    isDisabled: false,
    scale: defaultZoomScale,

    interceptFocusDataset: undefined,
  };

  render() {
    const {
      controlPoints,
      fillColor,
      isDisabled,
      lineWidth,
      type,

      onFocus,
      onBlur,
      storeRef,
      interceptFocusDataset,
    } = this.props;

    const viewBox = getViewbox(controlPoints, lineWidth);
    const paths = [pathFromPoints(controlPoints)];

    return (
      <CurveToolView
        controlPoints={controlPoints}
        fillColor={fillColor}
        lineWidth={lineWidth}
        viewBox={viewBox}
        paths={paths}
        type={type}
        scale={this.props.scale}
        isDisabled={isDisabled}

        onFocus={onFocus}
        onBlur={onBlur}
        storeRef={storeRef}
        {...interceptFocusDataset}
      />
    );
  }
}
