import PropTypes from 'prop-types';
import { Children, cloneElement } from 'react';
import { getCaseAssignedText } from './utils/textToolUtils';

const TextLetterCaseTransformer = ({ propName, letterCase, children }) => {
  return cloneElement(Children.only(children), {
    [propName]: getCaseAssignedText({
      text: children.props[propName],
      letterCase,
    }),
  });
};

TextLetterCaseTransformer.propTypes = {
  children: PropTypes.element.isRequired,
  propName: PropTypes.string.isRequired,
  letterCase: PropTypes.string,
};

TextLetterCaseTransformer.defaultProps = {
  letterCase: 'none',
};

export default TextLetterCaseTransformer;
