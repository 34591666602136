let store = [];

if (__CLIENT__) {
  window.JSFillerStores = { ...window.JSFillerStores, pdfFontsStore: store };
}

/**
 * 'pdfName' is a string, looks like 'g_d1_f2'
 * stored 'font' is string, one of 'fontsMap' keys
 */

export default {
  getFontByPdfName: (pdfName) => {
    return store[pdfName];
  },

  putFontByPdfName: (pdfName, fontName) => {
    store[pdfName] = fontName;
  },

  clear: () => {
    store = [];
  },
};
