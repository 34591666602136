import React from 'react';

const valueRendererOverridden = (style) => {
  return (option) => {
    return (
      <div style={{ ...style, lineHeight: 1 }}>
        {option.label}
      </div>
    );
  };
};

export default valueRendererOverridden;
