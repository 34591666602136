import createInvisibleSpan from './helpers/createInvisibleSpan';
import { getFontIdentifier } from './helpers/utils';

// Все шрифты, которые уже проверены и известно, что они есть
// (установлены в ОС, уже загружены приложкой etc)
// мы сохраним в этот кеш: если шрифт загружен, то он уже никуда не пропадет.
// Отрицательные значения мы не кешируем: шрифт еще может быть загружен
const cacheStore = {};

// For test - use a string with the widest char
// Используем 'W' т.к. это самая широкая буква,
// и геометрия разных шрифтов будет сильнее отличаться
const ruler = createInvisibleSpan({
  text: 'WWWWWWWWWWWWWWWWWWWWWWWWWWWWWW-FONT-TESTER-STRING-WWWWWWWWWWWWWWWWWWWWWWWWWWWWWW',
});

const getIsFontAvailable = ({ fontFamily, bold, italic }) => {
  if (!__CLIENT__) {
    return false;
  }

  const fontId = getFontIdentifier({ fontFamily, bold, italic });
  if (cacheStore[fontId]) {
    return true;
  }

  if (bold) {
    ruler.style.fontWeight = 'bold';
  }
  if (italic) {
    ruler.style.fontStyle = 'italic';
  }

  // Для определения, доступен ли фонт или нет,
  // мы применим два правила 'style.fontFamily' и сравним получившийся размер.
  // Если размер одинаков - это значит что применился fontFamily, возвращаем true
  // Если нет - значит fontFamily недоступен, и применились соответственно sans-serif и monospace
  ruler.style.fontFamily = `${fontFamily}, sans-serif`;
  const sansRect = ruler.getBoundingClientRect();
  ruler.style.fontFamily = `${fontFamily}, monospace`;
  const monospaceRect = ruler.getBoundingClientRect();

  const isAvailable =
    sansRect.width === monospaceRect.width &&
    sansRect.height === monospaceRect.height;

  if (isAvailable) {
    cacheStore[fontId] = true;
  }

  return isAvailable;
};

export default getIsFontAvailable;
