import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { presetsNames as wrapperPresets } from 'jsfcore/components/Portal/helpers/presets';
import { possiblePositions as wrapperPositions } from 'jsfcore/components/Portal/helpers/const';
import Hint from 'jsfcore/components/Hint/Hint';
import { tooltipTimeout } from '../../../../helpers/const';
import { selectors } from '../../../..';
import * as Ui from '../../../../ui';

const style = {
  display: 'inherit',
  flex: 'inherit',
};

@connect((state) => {
  return {
    locale: selectors.locale.getShowPagesButtonTooltip(state),
    labels: selectors.locale.getEditorLocale(state).toolbarNavigation,
  };
})
export default class ToolbarThumbnailsButtonReadOnly extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    labels: PropTypes.shape({
      showPages: PropTypes.string.isRequired,
      hidePages: PropTypes.string.isRequired,
    }).isRequired,

    isThumbnailsOpen: PropTypes.bool.isRequired,
    onClickPages: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    // NOTE: Это воркэраунт вокруг циклических зависимострей
    this.wrapperProps = {
      locatorArgs: {
        position: wrapperPositions.bottomRightEdge,
        preset: wrapperPresets.hint,
      },
    };
  }

  render() {
    const { isThumbnailsOpen, onClickPages, labels } = this.props;

    return (
      <Hint
        html={this.props.locale}
        wrapperProps={this.wrapperProps}
        style={style}
        timeout={tooltipTimeout}
      >
        {({ onMouseEnter, onMouseLeave, storeRef, children }) => {
          return (
            <Ui.Container.SettingButton
              icon={Ui.icon.types.pages}
              isPressed={isThumbnailsOpen}
              size={Ui.icon.sizes.large}
              textSize={12}
              label={
                isThumbnailsOpen
                  ? labels.hidePages
                  : labels.showPages
              }
              behavior={Ui.control.behaviors.null}
              storeRef={storeRef}
              onClick={onClickPages}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              inheritRadius
              attributes={Ui.attributes.grow}
            >
              {children}
            </Ui.Container.SettingButton>
          );
        }}
      </Hint>
    );
  }
}
