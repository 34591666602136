import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { DraggableCore } from 'react-draggable';

import { setPaSettings } from 'ws-editor-lib/actions';
import { cancellableOpts } from 'jsfcore/store/modules/undoRedo';
import { getIsFontAvailable, loadFont } from '@pdffiller/jsf-fontloader';

import { selectors } from '../../..';
import { paSections } from '../../../helpers/const';
import { getIndexByPageId, formatDate } from '../../../helpers/utils';
import { getEventPos } from '../../../helpers/dragUtils';
import {
  getPageAttributeStyle, getBatesStringValue, getLimiterStyle,
  getDraggingItemPos, getOffsetObject,
} from './pageAttributesUtils';

export const initialState = {
  isDragging: false,
  dragStartPoint: { x: -1, y: -1 },
  dragEndPoint: { x: -1, y: -1 },
  dragLimiterProps: false,
};

@connect(
  (__, { attrName, pageId }) => {
    return (state) => {
      return {
        attrContent: selectors.getAttrContentWithDefaults(state, attrName),
        originalSize: selectors.base.getOriginalSizes(state)[pageId],
        count: state.pdf.count, // total number of pages in document
        pageIndex: getIndexByPageId(pageId, selectors.navigation.getPagesSettings(state)),
      };
    };
  }, { setPaSettings },
)
export default class PageAttributesItem extends Component {
  static propTypes = {
    attrName: PropTypes.oneOf(Object.values(paSections)).isRequired,
    shownLimiterName: PropTypes.oneOf([false, ...Object.values(paSections)]).isRequired,
    setDraggingSection: PropTypes.func.isRequired,
    resetDraggingSection: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    pageId: PropTypes.number.isRequired,

    // from state
    attrContent: PropTypes.shape({
      fontFamily: PropTypes.string,
      bold: PropTypes.bool,
      italic: PropTypes.bool,
      align: PropTypes.string,
      offsetL: PropTypes.number,
      offsetR: PropTypes.number,
      offsetT: PropTypes.number,
      offsetB: PropTypes.number,
      visible: PropTypes.bool,
      timeUTC: PropTypes.number,
      format: PropTypes.string,
      prefix: PropTypes.string,
      text: PropTypes.string,
      suffix: PropTypes.string,
    }).isRequired,
    originalSize: PropTypes.oneOfType([
      PropTypes.shape({
        height: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
      }),
      PropTypes.bool,
    ]).isRequired,
    pageIndex: PropTypes.number.isRequired, // for numbering and bates only
    count: PropTypes.number.isRequired, // for numbering only

    // actions
    setPaSettings: PropTypes.func.isRequired,
  };

  static contextTypes = {
    getEvents: PropTypes.func.isRequired,
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    const { fontFamily, bold, italic } = props.attrContent;
    loadFont({ fontFamily, bold, italic })
      .then(this.updateDragLimiter);

    this.state = {
      ...initialState,
      dragLimiterProps: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.attrContent !== prevProps.attrContent) {
      this.updateDragLimiter();
    }
  }

  getScale = () => {
    return selectors.getScale(
      this.context.store.getState(),
      this.props.pageId,
    );
  };

  getNodeSize = () => {
    const node = this.itemRef;
    if (node) {
      const { width, height } = node.getBoundingClientRect();
      const scale = this.getScale();
      return {
        width: width / scale,
        height: height / scale,
      };
    }

    return { width: 0, height: 0 };
  };

  getDragLimiterProps = () => {
    const { attrContent } = this.props;

    const {
      bold,
      italic,
      align,
      offsetL,
      offsetR,
      offsetT,
      offsetB,
      visible,
      fontFamily,
    } = attrContent;

    if (
      !visible ||
      !getIsFontAvailable({ fontFamily, bold, italic })
    ) {
      return false;
    }

    const { width, height } = this.getNodeSize();
    return {
      width,
      height,
      align,
      offset: {
        leftright: offsetL + offsetR,
        topbottom: offsetT + offsetB,
      },
    };
  };

  getInnerText = () => {
    const { attrName, attrContent } = this.props;

    if (attrName === paSections.numbering) {
      return attrContent.text
        .replace('%current%', this.props.pageIndex + 1)
        .replace('%total%', this.props.count);
    }

    if (attrName === paSections.date) {
      const dateValue = attrContent.timeUTC
        ? new Date(attrContent.timeUTC)
        : new Date();

      return formatDate(dateValue, attrContent.format);
    }

    if (attrName === paSections.bates) {
      return attrContent.prefix +
        getBatesStringValue(attrContent, this.props.pageIndex) +
        attrContent.suffix;
    }

    return attrContent.text;
  };

  getDragLimiterStyle = () => {
    const { dragLimiterProps } = this.state;

    if (!dragLimiterProps) {
      return null;
    }

    // TODO: to static css
    return {
      position: 'absolute',
      width: '50%',
      height: '25%',
      backgroundColor: 'rgba(244, 167, 66, 0.15)',
      border: '2px solid rgb(244, 167, 66)',
      ...(getLimiterStyle(dragLimiterProps)),
    };
  };

  storeAttrRef = (ref) => {
    this.itemRef = ref;
  };

  updateDragLimiter = () => {
    this.setState({
      dragLimiterProps: this.getDragLimiterProps(),
    });
  };

  onDragStart = (event) => {
    const eventPos = getEventPos(event, this.context.getEvents());
    if (!eventPos) {
      return;
    }

    this.setState({
      dragStartPoint: eventPos,
      dragEndPoint: eventPos, // that's ok, we start to process at the very beginning of drag
    });
  };

  onDragMove = (event) => {
    const eventPos = getEventPos(event, this.context.getEvents());
    if (!eventPos) {
      return;
    }

    this.setState({
      dragEndPoint: eventPos,
      isDragging: true,
    }, () => {
      this.props.setDraggingSection(this.props.attrName);
    });
  };

  onDragStop = () => {
    const { originalSize, attrName, attrContent } = this.props;
    const { dragStartPoint, dragEndPoint, dragLimiterProps } = this.state;
    const draggingItemPos =
      getDraggingItemPos({
        attrContent,
        originalSize,
        dragStartPoint,
        dragEndPoint,
        dragLimiterProps,
        scale: this.getScale(),
      });
    const offsetObject = getOffsetObject(draggingItemPos);

    this.setState({
      ...initialState,
    }, () => {
      this.props.resetDraggingSection();
      this.props.setPaSettings({
        [attrName]: offsetObject,
      }, cancellableOpts);
    });
  };

  renderInner = () => {
    const { attrContent, originalSize } = this.props;
    const { dragStartPoint, dragEndPoint, dragLimiterProps } = this.state;
    const style = {
      ...getPageAttributeStyle(attrContent),
      ...(this.state.isDragging &&
        getDraggingItemPos({
          attrContent,
          originalSize,
          scale: this.getScale(),
          dragStartPoint,
          dragEndPoint,
          dragLimiterProps,
        })
      ),
      cursor: 'move',
    };

    return (
      <div
        ref={this.storeAttrRef}
        className="pageAttribute-PageAttributesLayer"
        style={style}
      >
        {this.getInnerText()}
      </div>
    );
  }

  render() {
    const { shownLimiterName, attrName } = this.props;
    return (
      <Fragment>
        {shownLimiterName === attrName && <div style={this.getDragLimiterStyle()} />}
        <DraggableCore
          onStart={this.onDragStart}
          onDrag={this.onDragMove}
          onStop={this.onDragStop}
        >
          {this.renderInner()}
        </DraggableCore>
      </Fragment>
    );
  }
}
