import difference from 'lodash/difference';
import clamp from 'lodash/clamp';
import filter from 'lodash/filter';
import { utils } from 'ws-editor-lib';
import {
  addFillableElements,
  updateFillableElement,
  activateFillableTool,
} from 'ws-editor-lib/actions';
import {
  getNextNameByTypeIterator,
  generateIdsBeforeAddFillableElements,
} from 'ws-editor-lib/store/models/Operations/Utils';
import { toolIds, elemFCTypes, elemSubTypes } from '../helpers/elemTypes';
import { actions as jsfcoreModalsActions } from '../jsf-modals';
import { modalsMap, modalTypes } from '../jsf-modals/types';
import isSignNow from '../helpers/const/isSignNow';
import { thunks, selectors } from '..';
import { removeFillableElements } from '../store/thunks/remove';
import { cancellableOpts } from '../store/modules/undoRedo';
import { radioAlignmentModes } from '../jsf-modals/components/Radio/const';

const RADIO_PADDING_PX = 10;

const warningModalWithRemoving = async (elementIds, dispatch) => {
  const radioModal = await dispatch(jsfcoreModalsActions.openModal(
    modalsMap[modalTypes.radioRemove],
  ));

  if (!radioModal) {
    return;
  }

  dispatch(removeFillableElements(elementIds, cancellableOpts));
};

const calculateAxisPosition = ({ maxPosition, elementAxis, elementSize, elementPadding }) => {
  return clamp(
    elementAxis + elementSize + elementPadding,
    maxPosition,
  );
};

export const cloneRadioButtonsByValues = (radioElement, values) => {
  return (dispatch, getState) => {
    const state = getState();
    const originalSizes = selectors.base.getOriginalSizes(state);
    const elementPageOriginalSize = originalSizes[radioElement.pageId];
    const radioIdIterator = getNextNameByTypeIterator(radioElement, state.ws, 'radioId');

    const newRadioElements = new Array(values.length)
      .fill(radioElement)
      .map((element, i) => {
        const maxXPosition = elementPageOriginalSize.width - element.template.width;
        const maxYPosition = elementPageOriginalSize.height - element.template.height;

        const isVerticalAligned = (
          !element.template.alignment ||
          element.template.alignment === radioAlignmentModes.vertical
        );
        const isHorizontalAligned = element.template.alignment === radioAlignmentModes.horizontal;

        return {
          ...element,
          id: null,
          template: {
            ...element.template,
            radioValue: values[i],
            ...(
              isSignNow()
                ? { radioId: radioIdIterator.next().value }
                : {}
            ),
            ...(isHorizontalAligned && {
              x: calculateAxisPosition({
                maxPosition: maxXPosition,
                elementAxis: element.template.x,
                elementSize: element.template.width * i,
                elementPadding: RADIO_PADDING_PX * i,
              }),
            }),
            ...(isVerticalAligned && {
              y: calculateAxisPosition({
                maxPosition: maxYPosition,
                elementAxis: element.template.y,
                elementSize: element.template.height * i,
                elementPadding: RADIO_PADDING_PX * i,
              }),
            }),
          },
        };
      });

    if (isSignNow()) {
      dispatch(
        activateFillableTool({
          id: toolIds.radio,
          type: elemFCTypes.radio,
          subType: elemSubTypes.none,
        }),
      );
    }

    const elements = generateIdsBeforeAddFillableElements(state.ws, newRadioElements);
    dispatch(addFillableElements(elements, cancellableOpts));
  };
};

export const addRadioButtons = (activeElement, newElementNames) => {
  return (dispatch, getState) => {
    const state = getState();
    const originalSizes = selectors.base.getOriginalSizes(state);
    const elementPageOriginalSize = originalSizes[activeElement.pageId];

    const elementRadioGroupName = utils.getElementGroupNames(activeElement).radio;
    const elementIds = selectors.elements.getElementsIdByRadioGroup(state, elementRadioGroupName);
    const elementsMap = selectors.base.getElementsMap(state);

    const radioGroupElements = elementIds.map((elementId) => {
      return elementsMap[elementId];
    });

    const uniqueElementNames = isSignNow()
      ? newElementNames
      : difference(
        newElementNames,
        radioGroupElements.map(({ template }) => {
          return template.radioValue;
        }),
      );

    // not add anything if all names are already exists
    if (!uniqueElementNames.length) {
      return;
    }

    // we need to add increased Y position for new element
    const lastElementFromGroup = elementsMap[elementIds[elementIds.length - 1]];
    const maxYPosition = elementPageOriginalSize.height - lastElementFromGroup.template.height;

    const elementToAddWithIncreasedPosition = {
      ...lastElementFromGroup,
      template: {
        ...lastElementFromGroup.template,
        y: calculateAxisPosition({
          maxPosition: maxYPosition,
          elementAxis: lastElementFromGroup.template.y,
          elementSize: lastElementFromGroup.template.height,
          elementPadding: RADIO_PADDING_PX,
        }),
      },
    };

    dispatch(cloneRadioButtonsByValues(
      elementToAddWithIncreasedPosition,
      uniqueElementNames,
    ));
  };
};

// NOTE: removeThunk нужен, т.к. в проекте snfiller он переопределяется.
export const removeRadioButton = (element, removeThunk = thunks.remove) => {
  return (dispatch, getState) => {
    const state = getState();
    const elementRadioGroupName = utils.getElementGroupNames(element).radio;
    const elementIds = selectors.elements.getElementsIdByRadioGroup(state, elementRadioGroupName);

    // show confirmation modal if radiobuttons count is < 2
    if (elementIds.length > 2) {
      const { template, id } = element;
      if (template.prefilled === template.radioValue) {
        filter(elementIds, (elId) => {
          return elId !== id;
        }).forEach((elId) => {
          dispatch(updateFillableElement({ id: elId, template: { prefilled: undefined } }));
        });
      }
      dispatch(removeThunk(id, cancellableOpts));
    } else {
      warningModalWithRemoving(elementIds, dispatch);
    }
  };
};

export const removeRadioButtonGroup = (activeElement) => {
  return async (dispatch, getState) => {
    const state = getState();
    const elementRadioGroupName = utils.getElementGroupNames(activeElement).radio;
    const elementIds = selectors.elements.getElementsIdByRadioGroup(state, elementRadioGroupName);

    warningModalWithRemoving(elementIds, dispatch);
  };
};
