import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { loadFont } from '@pdffiller/jsf-fontloader';

import { selectors } from '../../..';
import { paSections } from '../../../helpers/const';
import { getPageAttributeStyle } from './pageAttributesUtils';

@connect(
  (state) => {
    return {
      watermark: selectors.getAttrContentWithDefaults(state, paSections.watermark),
    };
  },
)
export default class PageAttributesWatermark extends Component {
  static propTypes = {
    watermark: PropTypes.shape({
      fontFamily: PropTypes.string,
      bold: PropTypes.bool,
      italic: PropTypes.bool,
      text: PropTypes.string,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    const { fontFamily, bold, italic } = props.watermark;
    loadFont({ fontFamily, bold, italic });
  }

  renderTextLine = (text, index) => {
    return (
      <div key={index}>
        {text}
      </div>
    );
  }

  render() {
    const { watermark } = this.props;

    const style = getPageAttributeStyle(watermark);

    const decodedText = decodeURI(watermark.text).split('\n');

    return (
      <div
        className={classnames(
          'pageAttribute-PageAttributesLayer',
          'pageAttributeWatermark-PageAttributesLayer',
        )}
        style={style}
      >
        {decodedText.map(this.renderTextLine)}
      </div>
    );
  }
}
