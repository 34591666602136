import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import {
  comments,
  LazyComponent,
  wizard,
} from '@pdffiller/jsf-lazyload';
import * as lazySelectors from '@pdffiller/jsf-lazyload/store/selectors';
import { connect } from 'react-redux';
import { StoreRefProvider } from 'jsfcore/components';
import { selectors } from '../../..';
import {
  defaultZoomScale,
  ghostVisibleOverClassName,
} from '../../../helpers/const';
import PageCanvasInserter from '../PageCanvas/PageCanvasInserter';
import PageCanvas from '../PageCanvas/PageCanvas';
import WheelZoom from './WheelZoom';

@connect(
  (__, { pageId }) => {
    return (state) => {
      return {
        scale: state.navigation.scales[pageId],
        isActivePage: selectors.getIsActivePage(state, pageId),
        isCommentsModeActive: selectors.mode.isComments(state),
        isWizardLoaded: lazySelectors.common.getIsWizardLoaded(state),
      };
    };
  },
)
export default class PagePinchCssZoom extends PureComponent {
  static propTypes = {
    pageId: PropTypes.number.isRequired,
    scale: PropTypes.number,
    isActivePage: PropTypes.bool.isRequired,
    isWizardLoaded: PropTypes.bool.isRequired,
    isCommentsModeActive: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    scale: defaultZoomScale,
  };

  componentDidMount() {
    const node = this.getRef();

    // Выключаем зум трекпадом
    // eslint-disable-next-line no-new
    new WheelZoom({
      onStart: () => {

      },
      onMove: () => {

      },
      onEnd: () => {

      },
      node,
    });
  }

  getRef = () => {
    return this.ref;
  };

  storeRef = (ref) => {
    this.ref = ref;
  };

  render() {
    const { pageId, scale, isActivePage, isCommentsModeActive, isWizardLoaded } = this.props;
    return (
      <div ref={this.storeRef} className="pagePinch-PagePinch">
        <div className={classnames('pagePinchBlank-PagePinch', ghostVisibleOverClassName)}>
          {scale &&
            <StoreRefProvider>
              {({ storeRef, getRef }) => {
                return (
                  <PageCanvasInserter getRef={getRef} pageId={pageId}>
                    <PageCanvas pageId={pageId} storeRef={storeRef} />
                  </PageCanvasInserter>
                );
              }}
            </StoreRefProvider>
          }
        </div>
        {isCommentsModeActive && scale &&
          <LazyComponent
            literal={comments.components.comments}
            props={{ pageId, scale, isActivePage }}
          />
        }
        {isWizardLoaded && scale &&
          <LazyComponent
            literal={wizard.components.wizardPointer}
            props={{ pageId }}
          />
        }
      </div>
    );
  }
}
