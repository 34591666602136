import PropTypes from 'prop-types';
import React from 'react';

import TextTool from '../TextTool/TextTool';
import CurveToolView from '../CurveTool/CurveToolView';
import PictureTool from '../PictureTool/PictureTool';
import UnfilledSignTool from '../UnfilledSignTool/UnfilledSignTool';

import { elemSubTypes } from '../../../helpers/elemTypes';

const SignToolRender = ({ childType, childProps }) => {
  switch (childType) {
    case elemSubTypes.signature.curve:
      return <CurveToolView {...childProps} />;
    case elemSubTypes.signature.image:
      return <PictureTool {...childProps} />;
    case elemSubTypes.signature.text:
      return <TextTool {...childProps} />;
    default:
      return <UnfilledSignTool {...childProps} />;
  }
};

SignToolRender.propTypes = {
  childType: PropTypes.oneOf([
    elemSubTypes.signature.curve,
    elemSubTypes.signature.image,
    elemSubTypes.signature.text,
  ]),
  childProps: PropTypes.shape({}).isRequired,
};

SignToolRender.defaultProps = {
  childType: null,
};

export default SignToolRender;
