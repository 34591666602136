import PropTypes from 'prop-types';
import { Component } from 'react';
import get from 'lodash/get';
import omit from 'lodash/omit';

import { elemSubTypes, getSignatureSubtype } from '../../../helpers/elemTypes';
import {
  bezierPathFromPoints,
  getSignCurveViewbox,
} from '../../../helpers/graphicUtils';
import { getElementProps } from '../../../store/helpers/functions';

export default class SignToolElementProvider extends Component {
  static propTypes = {
    element: PropTypes.shape({
      content: PropTypes.shape({
        curves: PropTypes.arrayOf(
          PropTypes.shape({
            lineWidth: PropTypes.number,
            color: PropTypes.string,
          }),
        ),
        contentY: PropTypes.number,
        contentX: PropTypes.number,
        height: PropTypes.number,
        width: PropTypes.number,
        required: PropTypes.bool,
      }),
      template: PropTypes.shape({
        dateStamp: PropTypes.string,
      }),
    }).isRequired,

    originalSize: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),

    children: PropTypes.func.isRequired,
  };

  static defaultProps = {
    originalSize: null,
  };

  constructor(props) {
    super(props);

    const { element } = props;
    this.state = {
      ...(getSignatureSubtype(element) === elemSubTypes.signature.curve
        ? {
          smoothedCurves: this.getSmoothedCurves(element),
        }
        : {}),
    };

    this.wrappedComponentRef = null;
    this.signToolMapper = {
      [elemSubTypes.none]: this.getUnfilledSignTool,
      [elemSubTypes.signature.initials]: this.getUnfilledSignTool,
      [elemSubTypes.signature.image]: this.getImageTool,
      [elemSubTypes.signature.text]: this.getTextTool,
      [elemSubTypes.signature.curve]: this.getCurveTool,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ element }) {
    if (
      getSignatureSubtype(element) === elemSubTypes.signature.curve &&
      get(element, 'content.curves') &&

      get(element, 'content.curves') !== get(this.props.element, 'content.curves')
    ) {
      this.setState({
        smoothedCurves: this.getSmoothedCurves(element),
      });
    }
  }

  getSmoothedCurves = (element) => {
    const { content } = element;
    if (!content || !content.curves || !content.curves.length) {
      return null;
    }
    const { curves } = content;
    return curves.map((curve) => {
      return bezierPathFromPoints(curve.controlPoints, curve.smoothing);
    });
  };

  getWrappedInstance = () => {
    const node = this.wrappedComponentRef;
    if (node.getWrappedInstance) {
      return node.getWrappedInstance();
    }
    return node;
  };

  getTextTool = () => {
    const props = {
      ...getElementProps(this.props.element),
      ...this.props,
      isSignature: true,
      ref: this.storeWrappedComponentRef,
    };

    return {
      childType: elemSubTypes.signature.text,
      props,
    };
  };

  getImageTool = () => {
    const props = {
      ref: this.storeWrappedComponentRef,
      ...getElementProps(this.props.element),
      ...this.props,
      isSignature: true,
    };

    return {
      childType: elemSubTypes.signature.image,
      props,
    };
  };

  getCurveTool = () => {
    const { content } = this.props.element;
    if (!content || !content.curves || !content.curves.length) {
      return null;
    }

    const { smoothedCurves } = this.state;
    const { lineWidth, color } = content.curves[0];

    const viewBox = getSignCurveViewbox(content.curves);

    const props = {
      ...this.props,
      ref: this.storeWrappedComponentRef,
      lineWidth,
      fillColor: color,
      viewBox: `${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`,
      paths: smoothedCurves,
      isFillable: true,
    };

    return {
      childType: elemSubTypes.signature.curve,
      props,
    };
  };

  getUnfilledSignTool = () => {
    return {
      childType: null,
      props: this.props,
    };
  }

  storeWrappedComponentRef = (ref) => {
    this.wrappedComponentRef = ref;
  };

  render() {
    const { element } = this.props;

    const getSignToolProps = this.signToolMapper[getSignatureSubtype(element)];
    if (!getSignToolProps) {
      return null;
    }

    const { childType, props } = getSignToolProps();

    return this.props.children({
      childType,
      childProps: omit(props, 'children'),
    });
  }
}
