import React from 'react';
import PropTypes from 'prop-types';
import * as Ui from '../../../ui';

const HeaderPhoneOverlayView = ({ iconType, text }) => {
  return (
    <Ui.Grid.Cell attributes={Ui.attributes.grow}>
      <Ui.EditorInfo.Index size={Ui.editorInfo.sizes.mediumHorizontal}>
        <Ui.EditorInfo.Body>
          <Ui.EditorInfo.Item>
            <Ui.Group.Index
              align={Ui.group.aligns.center}
              justify={Ui.group.justifies.center}
              type={Ui.group.types.compact}
              offset={Ui.group.offsets.small}
            >
              <Ui.Group.Body>
                <Ui.Group.Item>
                  <Ui.Icon
                    type={iconType}
                    size={Ui.icon.sizes.large}
                  />
                </Ui.Group.Item>
                <Ui.Group.Item>
                  <Ui.Text lineHeight={Ui.text.lineHeights.medium} size={16}>
                    {text}
                  </Ui.Text>
                </Ui.Group.Item>
              </Ui.Group.Body>
            </Ui.Group.Index>
          </Ui.EditorInfo.Item>
        </Ui.EditorInfo.Body>
      </Ui.EditorInfo.Index>
    </Ui.Grid.Cell>
  );
};

HeaderPhoneOverlayView.propTypes = {
  iconType: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default HeaderPhoneOverlayView;
