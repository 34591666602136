import PropTypes from 'prop-types';
import React from 'react';
import { thisDevice } from '@pdffiller/jsf-useragent';

import * as Ui from '../../ui';
import {
  defaultLineHeight,
  graphicToolPaddingPx,
  textToolArrangements,
} from '../../helpers/const';
import { isGraphicToolType, elemTypes } from '../../helpers/elemTypes';
import ElementFillablePlaceholder from './FillablePlaceholder/FillablePlaceholder';
import { getBaselineImage } from '../Tools/TextTool/utils/getBaselineImage';

const nullF = () => {};

const ElementFillableWrapperView = (props) => {
  const {
    isActiveElement,
    isDragging,
    element,
  } = props;

  const {
    type,
    subType,
    template: { required, arrangement, lineHeight },
  } = element;

  const style = {
    width: '100%',
    height: '100%',
    ...(!thisDevice.isFirefoxDesktop && { position: 'absolute' }),
    ...(type === elemTypes.text && { backgroundImage: getBaselineImage(element) }),
    ...(isGraphicToolType(type) && {
      margin: `${-graphicToolPaddingPx}px`,
      padding: `${graphicToolPaddingPx}px`,
      boxSizing: 'content-box',
    }),
    lineHeight: lineHeight || defaultLineHeight,
  };

  const needRenderPlaceholder = type === elemTypes.text;
  const isCells = arrangement === textToolArrangements.cells;

  return (
    <Ui.Box.Index
      theme={Ui.box.themes.primary}
      isActive={isActiveElement}
      // From DraggableCore
      style={{
        ...style,
        ...props.style,
      }}
      storeRef={props.storeRef}
    >
      <Ui.Box.Body>
        <Ui.Box.Main>
          <Ui.FillableField.Body
            isEnabled
            isPlaceholder={needRenderPlaceholder}
            isEllipsis={!isCells}
            style={style}
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            onMouseUp={props.onMouseUp}
            onTouchEnd={props.onTouchEnd}
            isActive={isActiveElement}
            isDraggable={isDragging}
            isRequired={required}
            type={type}
            subType={subType}
            storeRef={props.storeRef}
          >
            {needRenderPlaceholder && (
              <ElementFillablePlaceholder
                element={element}
                isCells={isCells}
              />
            )}
            <Ui.FillableField.Container>
              {props.children}
            </Ui.FillableField.Container>
          </Ui.FillableField.Body>
        </Ui.Box.Main>
      </Ui.Box.Body>
    </Ui.Box.Index>
  );
};

ElementFillableWrapperView.propTypes = {
  children: PropTypes.node.isRequired,

  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    subType: PropTypes.string.isRequired,
    template: PropTypes.shape({
      required: PropTypes.bool.isRequired,
      arrangement: PropTypes.string,
      lineHeight: PropTypes.number,
    }).isRequired,
  }).isRequired,
  isActiveElement: PropTypes.bool.isRequired,
  isDragging: PropTypes.bool.isRequired,
  storeRef: PropTypes.func,

  // From DraggableCore
  style: PropTypes.shape({}),
  onMouseDown: PropTypes.func,
  onTouchStart: PropTypes.func,
  onMouseUp: PropTypes.func,
  onTouchEnd: PropTypes.func,
};

ElementFillableWrapperView.defaultProps = {
  onMouseDown: nullF,
  onTouchStart: nullF,
  onMouseUp: nullF,
  onTouchEnd: nullF,
  style: {},
  storeRef: null,
};

export default ElementFillableWrapperView;
