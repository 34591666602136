import { getFontBaseline } from './textToolUtils';
import { isCells } from '../../../../store/helpers/functions';
import {
  createSvg,
  getCountForBaseline,
  getFirstStepForBaseline,
  getStepForBaseline,
  svgToBase64,
} from './baselineUtils';

// Функция, которая генерирует фоновое изображение
export const getBackgroundImage = (count, step, firstStep) => {
  const svg = createSvg(count, step, firstStep);
  const encodedData = svgToBase64(svg);
  return `url("data:image/svg+xml;base64,${encodedData}")`;
};

export const getBaselineImage = (element) => {
  const { height, fontSize, lineHeight, fontFamily, valign } = element.template;
  const step = getStepForBaseline({ fontSize, lineHeight });
  const isCellsElement = isCells(element);
  const firstStep = getFirstStepForBaseline({
    step,
    baseline: getFontBaseline(fontFamily, lineHeight),
    valign,
    height,
    isCellsElement,
  });
  const count = getCountForBaseline({
    step,
    firstStep,
    height,
    isCellsElement,
  });

  if (Number.isNaN(step) || Number.isNaN(firstStep) || Number.isNaN(count)) {
    return '';
  }
  return getBackgroundImage(count, step, firstStep);
};
