import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { trackPoint } from 'ws-editor-lib/actions';

import { getObjectForAppendToNewElement, applyDateStampIfNeed } from '../ghostUtils';
import { cancellableOpts } from '../../../store/modules/undoRedo';
import { elemSubTypes, isTextToolBasedElement } from '../../../helpers/elemTypes';
import { pageClickBlockerClassname, toolsTrackPoints } from '../../../helpers/const';
import { selectors, thunks } from '../../..';
import { hasClass } from '../../../helpers/utils';

@connect(
  (state) => {
    return {
      isElementCreationAvailable: selectors.getIsElementCreationAvailable(state),
      activeTool: selectors.base.getActiveTool(state),
      ghostElement: selectors.base.getGhostElement(state),
      useSigDateStamp: get(state, 'ws.defaults.useSigDateStamp', false),
      isDrawingNewGraphicElement: state.events.isDrawingNewGraphicElement,
      isFakeEditModeActive: state.viewport.isFakeEditModeActive,
    };
  }, {
    trackPoint,
    addElement: thunks.addElement,
    setActiveElement: thunks.setActiveElement,
  },
)
export default class GhostElementCreator extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    isElementCreationAvailable: PropTypes.bool.isRequired,
    getGhostPosition: PropTypes.func.isRequired,
    scale: PropTypes.number.isRequired,
    ghostElement: PropTypes.shape({
      subType: PropTypes.string.isRequired,
      id: PropTypes.string,
      content: PropTypes.object,
      type: PropTypes.string,
    }).isRequired,
    activePageId: PropTypes.number.isRequired,
    getPropsForElementCreating: PropTypes.func.isRequired,
    addElement: PropTypes.func.isRequired,
    setActiveElement: PropTypes.func.isRequired,
    useSigDateStamp: PropTypes.bool.isRequired,
    isDrawingNewGraphicElement: PropTypes.bool.isRequired,
    isFakeEditModeActive: PropTypes.bool.isRequired,
    trackPoint: PropTypes.func.isRequired,
  };

  getGhostPositionForState = (posArg = undefined) => {
    const { x, y } = this.props.getGhostPosition(posArg);
    const { scale } = this.props;
    const scaledPos = {
      x: x / scale,
      y: y / scale,
    };

    return {
      x: +(scaledPos.x.toFixed(2)),
      y: +(scaledPos.y.toFixed(2)),
    };
  };

  addNewElement = (position, text = false) => {
    const { ghostElement, activePageId, useSigDateStamp } = this.props;

    if (!ghostElement) {
      return;
    }

    this.props.trackPoint(toolsTrackPoints.ADDED_SIMPLE_TOOL);

    if (isTextToolBasedElement(ghostElement)) {
      this.props.trackPoint(toolsTrackPoints.ADDED_SIMPLE_TEXT);
    }

    this.props.addElement({
      ...ghostElement,
      subType: ghostElement.subType || elemSubTypes.none,
      pageId: activePageId,
      content: {
        ...ghostElement.content,
        ...getObjectForAppendToNewElement(ghostElement),
        ...position,
        ...(text && { text }),
        ...applyDateStampIfNeed({ ghost: ghostElement, useSigDateStamp }),
      },
    }, cancellableOpts);

    this.props.setActiveElement(ghostElement.id, true);
  };

  onClick = (event) => {
    const { clientX, clientY, target } = event;
    const {
      isMouseOverElement,
      isMouseOverClickBlocker,
      isMouseOverPage,
    } = this.props.getPropsForElementCreating(target);
    const { isDrawingNewGraphicElement } = this.props;

    if (isMouseOverClickBlocker || isMouseOverElement || !isMouseOverPage) {
      return;
    }

    // but isn't exactly correct, nobody know how it's should work!
    if (this.props.isFakeEditModeActive) {
      return;
    }
    if (!this.props.isElementCreationAvailable) {
      return;
    }
    if (hasClass(target, pageClickBlockerClassname)) {
      return;
    }

    const position = this.getGhostPositionForState({ clientX, clientY });

    if (!isDrawingNewGraphicElement) {
      this.addNewElement(position);
    }
  };

  render() {
    return this.props.children({
      createElement: this.onClick,
    });
  }
}
