import updateElement from './updateElement';
import { toolbar } from '../selectors';

const setTextAlign = (align) => {
  return (dispatch, getState) => {
    const { id } = toolbar.getElementPropsForToolbar(getState());
    return dispatch(updateElement(id, { align }));
  };
};

export default setTextAlign;
