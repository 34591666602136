import PropTypes from 'prop-types';
import React, { Component } from 'react';

import * as Ui from '../../../ui';
import { getHeaderButtonClasses, elemTypes } from '../../../helpers/elemTypes';
import { scuHelper, getTypeSubType } from '../../../helpers/utils';
import { signNowSignatureLabel } from '../../../helpers/const';

export default class HeaderToolView extends Component {
  static propTypes = {

    // TODO: change activeTool to isActiveTool
    activeTool: PropTypes.shape({
      type: PropTypes.string,
      subType: PropTypes.string,
    }),
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    appStarted: PropTypes.bool.isRequired,
    useSignNowTheme: PropTypes.bool.isRequired,

    onMouseDown: PropTypes.func.isRequired,
  };

  static defaultProps = {
    activeTool: null,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return scuHelper(nextProps, nextState, this, [
      'activeTool', 'id', 'label', 'appStarted',
    ]);
  }

  onMouseDown = () => {
    return this.props.onMouseDown(getTypeSubType(this.props.id));
  };

  render() {
    const { activeTool, id, appStarted, useSignNowTheme } = this.props;

    if (!id) {
      return null;
    }

    const { type, subType } = getTypeSubType(id);
    const icon = getHeaderButtonClasses(useSignNowTheme)[id];
    const label = (useSignNowTheme && type === elemTypes.signature)
      ? signNowSignatureLabel
      : this.props.label;

    return (
      <Ui.Container.SettingButton
        key={id}
        isPressed={activeTool && activeTool.type === type && (
          activeTool.subType === subType || subType === '*'
        )}
        icon={icon}
        label={label}
        title={Ui.locales.titles[icon]}
        size={Ui.icon.sizes.large}
        textOffset={Ui.text.offsets.topExtraSmall}
        {...(appStarted
          ? { onMouseDown: this.onMouseDown }
          : {})}
      />
    );
  }
}
