import { isSignNow } from 'ws-editor-lib';

import { isSimplePagination } from '../../helpers/utils';
import SimpleScrollContextProvider from './SimpleScrollContextProvider';
import ContinuousScrollContextProvider from './ContinuousScrollContextProvider';
import ImmediateFrameOffsetProviderJSF from './ImmediateFrameOffsetProvider';
import ImmediateFrameOffsetProviderSNF from './ImmediateFrameOffsetProviderSNF';
import FrameOffsetProvider from './FrameOffsetProvider';

const ScrollContextProvider = isSimplePagination()
  ? SimpleScrollContextProvider
  : ContinuousScrollContextProvider;

const ImmediateFrameOffsetProvider = isSignNow()
  ? ImmediateFrameOffsetProviderSNF
  : ImmediateFrameOffsetProviderJSF;

export default ScrollContextProvider;
export {
  ImmediateFrameOffsetProvider,
  FrameOffsetProvider,
};
