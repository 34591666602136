import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as selectors from '../../../store/selectors';
import * as Ui from '../../../ui';
import Hint from '../../Hint/Hint';
import {
  activateFakeEdit,
  deactivateFakeEdit,
} from '../../../store/modules/viewport';

@connect(
  (state) => {
    return {
      locale: selectors.locale.getEditorLocale(state).fakeEdit,
      isFakeEditButtonVisible: selectors.fakeEdit.getIsFakeEditAvailable(state),
      isFakeEditActive: selectors.base.getIsFakeEditActive(state),
      isFakeEditInitialHintShown: selectors.base.getIsFakeEditInitialHintShown(state),
      isFakeEditRolloverHintShown: selectors.base.getIsFakeEditRolloverHintShown(state),
    };
  },
  {
    activateFakeEdit,
    deactivateFakeEdit,
  },
)
export default class HeaderFakeEdit extends Component {
  static propTypes = {
    locale: PropTypes.shape({
      label: PropTypes.string,
      hints: PropTypes.shape({
        initial: PropTypes.shape({
          active: PropTypes.string,
          inActive: PropTypes.string,
        }),
        rollover: PropTypes.shape({
          active: PropTypes.string,
          inActive: PropTypes.string,
        }),
      }),
    }).isRequired,
    isFakeEditButtonVisible: PropTypes.bool.isRequired,
    isFakeEditActive: PropTypes.bool.isRequired,
    activateFakeEdit: PropTypes.func.isRequired,
    deactivateFakeEdit: PropTypes.func.isRequired,
    isFakeEditInitialHintShown: PropTypes.bool.isRequired,
    isFakeEditRolloverHintShown: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      hint: null,
    };
  }

  componentDidMount() {
    this.updateHintText();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.isFakeEditInitialHintShown !== nextProps.isFakeEditInitialHintShown ||
      this.props.isFakeEditActive !== nextProps.isFakeEditActive ||
      this.props.isFakeEditRolloverHintShown !== nextProps.isFakeEditRolloverHintShown
    ) {
      this.updateHintText(nextProps);
    }
  }

  updateHintText = (props = this.props) => {
    const { hints } = props.locale;
    const {
      isFakeEditActive,
      isFakeEditInitialHintShown,
      isFakeEditRolloverHintShown,
    } = props;

    if (isFakeEditInitialHintShown) {
      const hint = isFakeEditActive
        ? hints.initial.active
        : hints.initial.inActive;
      this.setState({ hint });
      return;
    }

    if (isFakeEditRolloverHintShown) {
      const hint = isFakeEditActive
        ? hints.rollover.active
        : hints.rollover.inActive;
      this.setState({ hint });
      return;
    }

    this.setState({ hint: null });
  };

  onClick = () => {
    if (this.props.isFakeEditActive) {
      this.props.deactivateFakeEdit();
    } else {
      this.props.activateFakeEdit();
    }
  };

  renderFakeEditButton = (
    onMouseEnter = null,
    onMouseLeave = null,
    storeRef = null,
  ) => {
    const { label } = this.props.locale;

    return (
      <Ui.Container.SettingButton
        icon={
          this.props.isFakeEditActive
            ? Ui.icon.types.fakeEditSwitchActive
            : Ui.icon.types.fakeEditSwitch
        }
        label={label}
        title={this.state.hint
          ? null
          : Ui.locales.titles.fakeEdit
        }
        isPressed={this.props.isFakeEditActive}
        size={Ui.icon.sizes.large}
        offset={Ui.control.offsets.extraSmall}
        textOffset={Ui.text.offsets.topExtraSmall}
        onClick={this.onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        storeRef={storeRef}
      />
    );
  };

  render = () => {
    const { hint } = this.state;
    const { isFakeEditInitialHintShown } = this.props;

    if (!this.props.isFakeEditButtonVisible) {
      return null;
    }

    if (hint) {
      return (
        <Hint
          html={hint}
          openPortalOnMount={isFakeEditInitialHintShown}
        >
          {({
            onMouseEnter,
            onMouseLeave,
            storeRef,
          }) => {
            return (
              this.renderFakeEditButton(
                onMouseEnter,
                onMouseLeave,
                storeRef,
              )
            );
          }}
        </Hint>
      );
    }

    return this.renderFakeEditButton();
  }
}
