import classNames from 'classnames';
import React from 'react';
import { Option } from 'react-select-pdffiller';

const blockEvent = (event) => {
  event.preventDefault();
  event.stopPropagation();
  if (event.target.tagName !== 'A' || !('href' in event.target)) {
    return;
  }
  if (event.target.target) {
    window.open(event.target.href, event.target.target);
  } else {
    window.location.href = event.target.href;
  }
};

export default class OptionOverridden extends Option {
  static propTypes = Option.propTypes;

  static defaultProps = Option.defaultProps;

  render() {
    const { option, instancePrefix, optionIndex } = this.props;
    const className = classNames(this.props.className, option.className);

    return option.disabled ? (
      <div className={className} onMouseDown={blockEvent} onClick={blockEvent}>
        {this.props.children}
      </div>
    ) : (
      <div
        className={className}
        style={option.style}
        aria-label={option.label}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onTouchEnd={this.handleTouchEnd}
        id={`${instancePrefix}-option-${optionIndex}`}
        title={option.title}
      >
        <span className="text text--size--14 text--family--open-sans">
          {this.props.children}
        </span>
      </div>
    );
  }
}
