import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import get from 'lodash/get';

import pictureDecorator from '../../../decorators/picture/pictureDecorator';

const unfilledPictureWrapperStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
};

@pictureDecorator()
export default class PictureTool extends Component {
  static propTypes = {
    isFillable: PropTypes.bool.isRequired,
    isSignature: PropTypes.bool,
    element: PropTypes.shape({
      content: PropTypes.shape({
        url: PropTypes.string,
      }),
    }).isRequired,
    isDisabled: PropTypes.bool,

    // from pictureDecorator
    renderUnfilledFillable: PropTypes.func.isRequired,

    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    storeRef: PropTypes.func.isRequired,
    interceptFocusDataset: PropTypes.shape({}),
  };

  static defaultProps = {
    isDisabled: false,
    isSignature: false,
    interceptFocusDataset: undefined,
  };

  render() {
    const {
      element,
      isDisabled,
      onFocus,
      onBlur,
      storeRef,
      interceptFocusDataset,
    } = this.props;
    const url = get(element, 'content.url', false);
    return (
      <>
        {url &&
          <div
            className={classnames('pictureTool-PictureTool', {
              'elementHover-Content': !isDisabled,
            })}
            tabIndex={0}

            onFocus={onFocus}
            onBlur={onBlur}
            ref={storeRef}
            {...interceptFocusDataset}
          >
            <div className="fillable-field__image">
              <img
                alt="pic"
                src={url}
                className={classnames('media', {
                  'cc-PictureTool': !this.props.isSignature, // center-center
                  'lb-PictureTool': this.props.isSignature, // left-bottom
                  'ff-PictureTool': !this.props.isFillable, // force-full width-height
                })}
              />
            </div>
          </div>
        }
        {this.props.isFillable && !url && !isDisabled &&
          <div
            className="elementHover-Content PictureTool-tabindex"
            style={unfilledPictureWrapperStyles}
            tabIndex={0}

            onFocus={onFocus}
            onBlur={onBlur}
            ref={storeRef}
            {...interceptFocusDataset}
          >
            {this.props.renderUnfilledFillable({
              onFocus,
              onBlur,
              storeRef,
            })}
          </div>
        }
      </>
    );
  }
}
