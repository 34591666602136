import { getUnsupportedInscriptionsByFont } from './utils';

const getFontFamilyConfig = (elementProps, fontFamily) => {
  const { bold, italic } = elementProps;

  if (!bold && !italic) {
    return { fontFamily };
  }

  // for inscription fonts which unsupported bold or italic need reset that to default
  const inscriptionsAreNotSupported = getUnsupportedInscriptionsByFont(fontFamily);

  return {
    fontFamily,
    ...(
      inscriptionsAreNotSupported.boldUnsupported && bold
        ? { bold: false }
        : {}
    ),
    ...(
      inscriptionsAreNotSupported.italicUnsupported && italic
        ? { italic: false }
        : {}
    ),
  };
};

export default getFontFamilyConfig;
