import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { wizard, LazyComponent } from '@pdffiller/jsf-lazyload';
import { PageThumbnailsInserter } from '../Page/PageCanvas/PageCanvasInserter';
import StoreRefProvider from '../StoreRef/StoreRefProvider';
import ThumbnailsScrollController from './ThumbnailsScrollController';
import ThumbnailsProvider from './ThumbnailsProvider';
import ThumbnailsWrapper from './ThumbnailsWrapper';
import ThumbnailsNavigationWrapper from './ThumbnailsNavigation/ThumbnailsNavigationWrapper';
import prevButton from './ThumbnailsNavigation/prevButton';
import nextButton from './ThumbnailsNavigation/nextButton';
import ThumbnailsNavigationLabel from './ThumbnailsNavigation/ThumbnailsNavigationLabel';
import ThumbnailsNavigationProvider from './ThumbnailsNavigation/ThumbnailsNavigationProvider';
import ThumbnailsScrollWrapper from './ThumbnailsScrollWrapper';
import ThumbnailProvider from './Thumbnail/ThumbnailProvider';
import ThumbnailRender from './Thumbnail/ThumbnailRender';
import * as Ui from '../../ui';

const renderThumbnail = ({
  isActivePage,
  onClickPageItemChange,
  thumbnail,
  getRefs,
  storeRefs,
  isWizardLoaded,
  pageId,
}) => {
  return (
    <PageThumbnailsInserter
      pageId={pageId}
      getRef={getRefs(pageId)}
      /**
       * После удаления canvas, thumbnails мидлвар
       * почему-то в safari при рендере в картинки - рендерятся черные
       * квадраты
       */
      forceCanvas
    >
      <Ui.FlexibleList.Item>
        <ThumbnailRender
          pageId={pageId}
          isActivePage={isActivePage}
          thumbnail={thumbnail}
          storeRef={storeRefs(pageId)}
          onClick={onClickPageItemChange}
        >
          {isWizardLoaded && (
            <LazyComponent
              literal={wizard.components.wizardThumbnails}
              props={{ pageId }}
            />
          )}
        </ThumbnailRender>
      </Ui.FlexibleList.Item>
    </PageThumbnailsInserter>
  );
};

renderThumbnail.propTypes = {
  isActivePage: PropTypes.bool.isRequired,
  pageId: PropTypes.number.isRequired,
  thumbnail: PropTypes.number,
  isWizardLoaded: PropTypes.bool.isRequired,
  onClickPageItemChange: PropTypes.func.isRequired,
  getRefs: PropTypes.func.isRequired,
  storeRefs: PropTypes.func.isRequired,
};

renderThumbnail.defaultProps = {
  thumbnail: null,
};

const renderThumbnails = ({ onThumbnailsMount }) => {
  return (
    <StoreRefProvider>
      {({ storeRefs: originalStoreRefs, getRefs }) => {
        return (
          <ThumbnailsScrollController
            getRefs={getRefs}
            storeRefs={originalStoreRefs}
          >
            {({ storeRefs, count }) => {
              return (
                <ThumbnailsProvider
                  onThumbnailsMount={onThumbnailsMount}
                  count={count}
                >
                  {({
                    isThumbnailsOpen,
                    isWizardLoaded,
                    activePageId,
                    pagesSettings,
                    toFirst,
                    toPrev,
                    toNext,
                    toLast,
                  }) => {
                    return (
                      <ThumbnailsWrapper
                        isThumbnailsOpen={isThumbnailsOpen}
                        count={count}
                      >
                        <ThumbnailsNavigationWrapper>
                          <ThumbnailsNavigationProvider
                            activePageId={activePageId}
                            count={count}
                          >
                            {({ index, canPrev, canNext }) => {
                              return (
                                <Fragment>
                                  {prevButton({ toPrev, toFirst, can: canPrev })}
                                  <ThumbnailsNavigationLabel count={count} index={index} />
                                  {nextButton({ toNext, toLast, can: canNext })}
                                </Fragment>
                              );
                            }}
                          </ThumbnailsNavigationProvider>
                        </ThumbnailsNavigationWrapper>
                        <ThumbnailsScrollWrapper
                          storeThumbnailsRef={storeRefs('index')}
                        >
                          {count && pagesSettings && pagesSettings.map(({ source }) => {
                            return (
                              <ThumbnailProvider
                                key={source}
                                pageId={source}
                                getRefs={getRefs}
                                storeRefs={storeRefs}
                                isWizardLoaded={isWizardLoaded}
                              >
                                {renderThumbnail}
                              </ThumbnailProvider>
                            );
                          })}
                        </ThumbnailsScrollWrapper>

                      </ThumbnailsWrapper>
                    );
                  }}
                </ThumbnailsProvider>
              );
            }}
          </ThumbnailsScrollController>
        );
      }}
    </StoreRefProvider>
  );
};

renderThumbnails.propTypes = {
  onThumbnailsMount: PropTypes.func.isRequired,
};

export default renderThumbnails;
