import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select, { Option } from 'react-select-pdffiller';
import cx from 'classnames';
import { focusControllerSelectDecorator } from '@pdffiller/jsf-focuscontroller';

import Popover, {
  popoverArrowPositions,
  popoverSizes,
  popoverOffsets,
  popoverThemes,
  popoverZIndexes,
} from './Popover';
import Text, {
  textSizes,
} from './Text';
import Group, {
  groupOffsets,
  groupSizes,
  GroupItem,
} from './Group';
import Icon, {
  iconTypes,
  iconSizes,
} from './Icon';

const DecoratedSelect = focusControllerSelectDecorator(Select);

export const selectThemes = {
  secondary: 'secondary',
  transparentGray: 'transparent-gray',
  multicolored: 'multicolored',
  light: 'light',
  whiteBordered: 'white-bordered',
  default: 'default',
  primary: 'primary',
};

export const selectSizes = {
  extraSmall: 'extra-small',
  small: 'small',
  null: null,
};

export const selectMenuHorizontalAlign = {
  left: 'left',
  null: null,
};

const popoverStyle = {
  position: 'absolute',
  left: 'calc(100% + 20px)',
  top: '50%',
  transform: 'translateY(-50%)',
  maxWidth: '240px',
  minHeight: '23px',
};

const optionContentStyles = {
  position: 'relative',
};

const menuContainerStylesLeft = {
  left: '113%',
};

export default class CustomSelect extends Component {
  static propTypes = {
    theme: PropTypes.oneOf(
      Object.values(selectThemes),
    ),
    size: PropTypes.oneOf(
      Object.values(selectSizes),
    ),
    selectMenuHorizontalAlign: PropTypes.oneOf(
      Object.values(selectMenuHorizontalAlign),
    ),
    inheritRadius: PropTypes.bool,
    tabIndex: PropTypes.string,
    roleClass: PropTypes.string,
    center: PropTypes.bool,
    dropdownOptionsWithHint: PropTypes.bool,
    optionComponent: PropTypes.func,
    isInvalid: PropTypes.bool,
    autoComplete: PropTypes.bool,
    keepMenuOpenAfterChoosing: PropTypes.bool,
  };

  static defaultProps = {
    theme: selectThemes.secondary,
    size: selectSizes.null,
    selectMenuHorizontalAlign: selectMenuHorizontalAlign.null,
    roleClass: '',
    center: true,
    inheritRadius: false,
    tabIndex: null,
    dropdownOptionsWithHint: false,
    optionComponent: null,
    isInvalid: false,
    autoComplete: false,
    keepMenuOpenAfterChoosing: false,
  };

  getOptionComponent = () => {
    return (
      this.props.optionComponent
        ? this.props.optionComponent
        : Option
    );
  };

  getMenuContainerStyle = () => {
    if (this.props.selectMenuHorizontalAlign === selectMenuHorizontalAlign.left) {
      return menuContainerStylesLeft;
    }

    return null;
  };

  hintRenderer = (hint) => {
    if (!hint) {
      return null;
    }

    return (
      <Popover
        theme={popoverThemes.dark}
        arrowPosition={popoverArrowPositions.leftCenter}
        size={popoverSizes.small}
        offset={popoverOffsets.medium}
        style={popoverStyle}
        zIndex={popoverZIndexes.extraLarge}
        bothCenter
      >
        <Text
          center
          size={textSizes[12]}
          bold
          block
        >
          {hint}
        </Text>
      </Popover>
    );
  }

  optionRenderer = (op) => {
    return (
      <Text
        size={textSizes.null}
      >
        {op.label}
      </Text>
    );
  };

  optionWithHint = (props) => {
    const OptionComponent = this.getOptionComponent();

    return (
      <div>
        <OptionComponent {...props}>
          <div style={optionContentStyles}>
            <Group
              size={groupSizes.parentHeight}
              offset={groupOffsets.extraSmallHorizontal}
            >
              <GroupItem>
                {props.option.label}
              </GroupItem>

              {props.option.formulaCalculation ? (
                <GroupItem>
                  <Icon
                    type={iconTypes.calculator}
                    size={iconSizes.small}
                  />
                </GroupItem>
              ) : null}
            </Group>

            {props.isFocused && this.hintRenderer(props.option.hint)}
          </div>
        </OptionComponent>
      </div>
    );
  }

  valueRenderer = (op) => {
    return (
      <div className="Select-placeholder">
        <Text size={textSizes.null}>
          {op.label}
        </Text>
      </div>
    );
  };

  openMenuAfterClosing(selectRef) {
    if (!this.props.keepMenuOpenAfterChoosing) {
      return;
    }

    if (selectRef) {
      selectRef.setState({
        isOpen: true,
      });
    }
  }

  render() {
    const OptionComponent = this.getOptionComponent();

    return (
      <DecoratedSelect
        className={cx(
          'Select--single',
          'Select',
          this.props.roleClass,
          `Select--theme--${this.props.theme}`,
          {
            'is-invalid': this.props.isInvalid,
            'Select--autocomplete': this.props.autoComplete,
            'Select--align--center': this.props.center,
            [`Select--size--${this.props.size}`]: this.props.size,
            'Select--dropdown--menu--inline': this.props.dropdownOptionsWithHint,
            '_inherit-radius': this.props.inheritRadius,
          },
        )}
        searchable={false}
        clearable={false}
        optionRenderer={this.optionRenderer}
        valueRenderer={this.valueRenderer}
        autosize={false}

        {...this.props}

        tabIndex={this.props.tabIndex}
        optionComponent={
          this.props.dropdownOptionsWithHint
            ? this.optionWithHint
            : OptionComponent
        }
        menuContainerStyle={this.getMenuContainerStyle()}
        ref={this.openMenuAfterClosing.bind(this)}
      />
    );
  }
}
