import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cls from 'classnames';

export const textWeights = {
  semiBold: 'semi-bold',
  bold: 'bold',
  regular: 'regular',
  normal: 'normal',
  null: null,
};

export const textSizes = {
  10: 10,
  11: 11,
  12: 12,
  13: 13,
  14: 14,
  16: 16,
  18: 18,
  20: 20,
  21: 21,
  null: null,
};

export const textLineHeights = {
  medium: 'medium',
  null: null,
};

export const textBottomOffsets = {
  small: 'small',
  large: 'large',
  null: null,
};

export const textDisplays = {
  block: 'block',
  null: null,
};

export const textTopOffsets = {
  extraSmall: 'extra-small',
  small: 'small',
  null: null,
};

export const textVerticalOffsets = {
  extraSmall: 'extra-small',
  null: null,
};

export const textStyles = {
  italic: 'italic',
  null: null,
};

export const textBlockTags = {
  div: 'div',
  p: 'p',
};

export const textThemes = {
  white: 'white',
  dovegray: 'dovegray',
  null: null,
};

export default class Text extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    headerText: PropTypes.element,
    italicText: PropTypes.string,
    uppercase: PropTypes.bool,
    bold: PropTypes.bool,
    center: PropTypes.bool,
    block: PropTypes.bool,
    blockTag: PropTypes.oneOf(
      Object.values(textBlockTags),
    ),
    hasHTML: PropTypes.bool,
    weight: PropTypes.oneOf(
      Object.values(textWeights),
    ),
    size: PropTypes.oneOf(
      Object.values(textSizes),
    ),
    lineHeight: PropTypes.oneOf(
      Object.values(textLineHeights),
    ),
    bottomOffset: PropTypes.oneOf(
      Object.values(textBottomOffsets),
    ),
    display: PropTypes.oneOf(
      Object.values(textDisplays),
    ),
    topOffset: PropTypes.oneOf(
      Object.values(textTopOffsets),
    ),
    verticalOffset: PropTypes.oneOf(
      Object.values(textVerticalOffsets),
    ),
    style: PropTypes.oneOf(
      Object.values(textStyles),
    ),
    theme: PropTypes.oneOf(
      Object.values(textThemes),
    ),
    id: PropTypes.string,
    interpolationParams: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({}),
        PropTypes.number,
      ]),
    ),
  };

  static contextTypes = {
    formatMessage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    children: null,
    headerText: null,
    italicText: null,
    uppercase: false,
    bold: false,
    center: false,
    block: false,
    blockTag: textBlockTags.div,
    hasHTML: false,
    weight: textWeights.null,
    size: textSizes[14],
    bottomOffset: textBottomOffsets.null,
    display: textDisplays.null,
    topOffset: textTopOffsets.null,
    verticalOffset: textVerticalOffsets.null,
    style: textStyles.null,
    theme: textThemes.null,
    lineHeight: textLineHeights.null,
    id: null,
    interpolationParams: [],
  };

  render() {
    const {
      uppercase,
      weight,
      size,
      bottomOffset,
      lineHeight,
      display,
      topOffset,
      verticalOffset,
      style,
      theme,
      bold,
      center,
      block,
      blockTag,
      hasHTML,
      headerText,
      italicText,
      children,
      id,
      interpolationParams,
    } = this.props;

    const formattedMessage = this.context.formatMessage(children, id, ...interpolationParams);

    const className = cls('text', {
      [`text--size--${size}`]: size,
      [`text--line-height--${lineHeight}`]: lineHeight,
      [`text--offset-bottom--${bottomOffset}`]: bottomOffset,
      [`text--display--${display}`]: display,
      [`text--offset-top--${topOffset}`]: topOffset,
      [`text--offset-vertical--${verticalOffset}`]: verticalOffset,
      [`text--style--${style}`]: style,
      [`text--weight--${weight}`]: weight,
      [`text--theme--${theme}`]: theme,
      'text--transform--uppercase': uppercase,
      'text--weight--regular': weight === textWeights.regular,
      'text--weight--bold': bold,
      'text--align--center': center,
    });

    if (block) {
      const Tag = blockTag;

      if (hasHTML) {
        return (
          <Tag
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{ __html: formattedMessage }}
            className={className}
          />
        );
      }

      return (
        <Tag className={className}>
          {formattedMessage}
        </Tag>
      );
    }

    if (hasHTML) {
      return (
        <span
          className={className}
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{ __html: formattedMessage }}
        />
      );
    }

    return (
      <span className={className}>
        {headerText}
        {italicText && (
          <em>{italicText}&nbsp;</em>
        )}
        {formattedMessage}
      </span>
    );
  }
}
