import PropTypes from 'prop-types';
import { Component } from 'react';

import { toolsDropDownButtonId } from '../../../helpers/const';
import { getEventPath } from '../../../helpers/utils';

export default class ToolsDropDownClickOutsideListener extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,

    toggleMobileToolsVisibility: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.toolsDropDownRef = null;
  }

  componentDidMount() {
    this.delegateEvents();
  }

  componentWillUnmount() {
    this.undelegateEvents();
  }

  storeRef = (ref) => {
    this.toolsDropDownRef = ref;
  };

  delegateEvents = () => {
    document.addEventListener('mousedown', this.onClick, true);
    document.addEventListener('touchstart', this.onClick, true);
  };

  undelegateEvents = () => {
    document.removeEventListener('mousedown', this.onClick);
    document.removeEventListener('touchstart', this.onClick);
  };

  onClick = (event) => {
    const { target } = event;
    const path = getEventPath(event);
    const clickOnToolsDropDownButton = path.some((el) => {
      return el.id === toolsDropDownButtonId;
    });
    const isClickOutside = this.toolsDropDownRef && !this.toolsDropDownRef.contains(target);

    if (!clickOnToolsDropDownButton && isClickOutside) {
      this.props.toggleMobileToolsVisibility();
    }
  };

  render() {
    return this.props.children({
      storeRef: this.storeRef,
    });
  }
}
