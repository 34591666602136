import { dateTypes, elemFCTypes } from 'jsfcore/helpers/elemTypes';

export const none = 'none';
export const fctool = 'fctool';
export const cells = 'cells';
export const ORDER = 'order';

export { elemFCTypes };

export const fCTypes = {
  [elemFCTypes.text]: {
    [none]: elemFCTypes.text,
    [elemFCTypes.number]: elemFCTypes.number,
    [elemFCTypes.dropdown]: elemFCTypes.dropdown,
    [elemFCTypes.formula]: elemFCTypes.formula,
    ...(dateTypes.reduce((state, type) => {
      return {
        ...state,
        [type]: elemFCTypes.date,
      };
    }, {})),
  },
  [elemFCTypes.checkmark]: {
    x: elemFCTypes.checkmark,
    v: elemFCTypes.checkmark,
    o: elemFCTypes.checkmark,
  },
  [elemFCTypes.image]: {
    [none]: elemFCTypes.image,
  },
  [elemFCTypes.signature]: {
    [none]: elemFCTypes.signature,
    [elemFCTypes.initials]: elemFCTypes.initials,
  },
  [elemFCTypes.radio]: {
    none: elemFCTypes.radio,
    x: elemFCTypes.radio,
    v: elemFCTypes.radio,
    o: elemFCTypes.radio,
  },
};

export const toolsCodes = {
  initial: 'initial',
  text: 'text', // unused?
  hint: 'hint',
  required: 'required',
  format: 'format',
  viewMode: 'viewMode',
  letterCase: 'letterCase',
  name: 'name',
  radioGroup: 'radioGroup',
  validatorId: 'validatorId',
  radioValue: 'radioValue',
  placeholder: 'placeholder',
  prompt: 'prompt',
};

export const hasIconCenter = [
  elemFCTypes.signature,
  elemFCTypes.initials,
  elemFCTypes.image,
];

export const numberRegEx = /^\d+$/;

export const attributePosition = {
  TL: 'TL',
  TC: 'TC',
  TR: 'TR',
  BL: 'BL',
  BC: 'BC',
  BR: 'BR',
};

export const dateFormatValues = [
  { value: 'M/d/yyyy', label: 'm/d/yyyy' },
  { value: 'MM/dd/yyyy', label: 'mm/dd/yyyy' },
  { value: 'MM/dd/yy', label: 'mm/dd/yy' },
  { value: 'yyyy-MM-dd', label: 'yyyy-mm-dd' },
  { value: 'yyyy/MM/dd', label: 'yyyy/mm/dd' },
  { value: 'yyyy-M-d', label: 'yyyy-m-d' },
  { value: 'yyyy/M/d', label: 'yyyy/m/d' },
  { value: 'd.M.yyyy', label: 'd.m.yyyy' },
  { value: 'd-M-yyyy', label: 'd-m-yyyy' },
  { value: 'd/M/yyyy', label: 'd/m/yyyy' },
  { value: 'dd.MM.yyyy', label: 'dd.mm.yyyy' },
  { value: 'dd-MM-yyyy', label: 'dd-mm-yyyy' },
  { value: 'dd/MM/yyyy', label: 'dd/mm/yyyy' },
];

export const numberingFormatValues = [
  'Page %current% of %total%',
  'Page %current%',
  '%current% of %total%',
  '%current%',
];

export const minPAfontSize = 8;
export const maxPAfontSize = 72;
export const maxWatermarkFontSize = 120;

export const nullOffset = {
  offsetT: 0,
  offsetB: 0,
  offsetL: 0,
  offsetR: 0,
};

export const maxBatesStartNumberLength = 16;
export const maxBatesPrefixLength = 256;
export const maxBatesSuffixLength = 256;

export const maxDBFieldNameLength = 190;

export const hintValuesByType = {
  text: ['default', 'note', 'maxChars', 'maxLines', 'dbName'],
  number: ['default', 'note', 'maxChars', 'dbName'],
  date: ['format', 'initial', 'note', 'dbName'],
  dropdown: ['default', 'note', 'dbName'],
  checkmark: ['typeOfCheck', 'defaultCheckmark', 'note', 'dbName', 'groupName'],
  signature: ['note', 'dbName'],
  initials: ['note', 'dbName'],
  image: ['note', 'dbName'],
  radio: ['note', 'dbName'],
  formula: ['note', 'dbName'],
};

export const EMPTY_LIST = [];
export const EMPTY_DROPDOWN_ITEM = {
  isDeleteButtonShown: false,
  isEditButtonShown: false,
  data: ' ',
  label: ' ',
};

export const maxHelpTextChars = 100;

export const tabs = {
  fields: 'fields',
  options: 'options',
  order: 'order',
};

export const minLineHeight = 0;
export const maxLineHeight = 5;
export const lineHeightStep = 0.01;
