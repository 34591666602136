import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { valignValues } from '../../../../helpers/const';
import { toolbar } from '../../../../store/selectors';
import setTextBoxVAlign from '../../../../store/thunks/setTextBoxVAlign';

import Dropdown from '../../../Dropdown/Dropdown';
import * as Ui from '../../../../ui';
import { ClosePortalFunctionsComposer } from '../../../Portal';

const icons = {
  [valignValues.top]: Ui.icon.types.textAlignTop,
  [valignValues.middle]: Ui.icon.types.textAlignMiddle,
  [valignValues.bottom]: Ui.icon.types.textAlignBottom,
};

const wrapperProps = {
  offset: Ui.control.offsets.small,
};

export class VAlignToolbarSettingsSimple extends Component {
  static propTypes = {
    valign: PropTypes.string,
    setVAlign: PropTypes.func.isRequired,
  };

  static defaultProps = {
    valign: valignValues.top,
  };

  getCurrentIcon() {
    return icons[this.props.valign];
  }

  onClickTop = () => {
    this.props.setVAlign(valignValues.top);
  };

  onClickMiddle = () => {
    this.props.setVAlign(valignValues.middle);
  };

  onClickBottom = () => {
    this.props.setVAlign(valignValues.bottom);
  };

  renderPopup = ({ closePortal }) => {
    const { valign } = this.props;

    return (
      <div className="text-alignment">
        <div className="text-alignment__body">
          <Ui.Group.Index
            size={Ui.group.sizes.parent}
            type={Ui.group.types.compact}
            offset={Ui.group.offsets.extraSmall}
          >
            <Ui.Group.Body>
              <Ui.Group.Item>
                <ClosePortalFunctionsComposer
                  closePortal={closePortal}
                  func={this.onClickTop}
                >
                  {({ composedFunc }) => {
                    return (
                      <Ui.Control
                        onClick={composedFunc}
                        isChecked={valign === valignValues.top}
                        theme={Ui.control.themes.primaryHighlighted}
                        size={Ui.control.sizes.parentHeight}
                        offset={Ui.control.offsets.small}
                        behavior={Ui.control.behaviors.column}
                        icon={<Ui.Icon
                          type={Ui.icon.types.textAlignTop}
                          size={Ui.icon.sizes.large}
                        />}
                      />
                    );
                  }}
                </ClosePortalFunctionsComposer>
              </Ui.Group.Item>
              <Ui.Group.Item>
                <ClosePortalFunctionsComposer
                  closePortal={closePortal}
                  func={this.onClickMiddle}
                >
                  {({ composedFunc }) => {
                    return (
                      <Ui.Control
                        onClick={composedFunc}
                        isChecked={valign === valignValues.middle}
                        theme={Ui.control.themes.primaryHighlighted}
                        size={Ui.control.sizes.parentHeight}
                        offset={Ui.control.offsets.small}
                        behavior={Ui.control.behaviors.column}
                        icon={<Ui.Icon
                          type={Ui.icon.types.textAlignMiddle}
                          size={Ui.icon.sizes.large}
                        />}
                      />
                    );
                  }}
                </ClosePortalFunctionsComposer>
              </Ui.Group.Item>
              <Ui.Group.Item>
                <ClosePortalFunctionsComposer
                  closePortal={closePortal}
                  func={this.onClickBottom}
                >
                  {({ composedFunc }) => {
                    return (
                      <Ui.Control
                        onClick={composedFunc}
                        isChecked={valign === valignValues.bottom}
                        theme={Ui.control.themes.primaryHighlighted}
                        size={Ui.control.sizes.parentHeight}
                        offset={Ui.control.offsets.small}
                        behavior={Ui.control.behaviors.column}
                        icon={<Ui.Icon
                          type={Ui.icon.types.textAlignBottom}
                          size={Ui.icon.sizes.large}
                        />}
                      />
                    );
                  }}
                </ClosePortalFunctionsComposer>
              </Ui.Group.Item>
            </Ui.Group.Body>
          </Ui.Group.Index>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Dropdown
        wrapperProps={wrapperProps}
        needStopPropagation={false}
        dropdownComponent={this.renderPopup}
      >
        {({ togglePortal, isPortalOpen, storeRef }) => {
          return (
            <Ui.ToolBar.Item>
              <Ui.Container.SettingButton
                icon={this.getCurrentIcon()}
                type={Ui.control.types.dropdown}
                title={Ui.locales.titles.vAlign}
                size={Ui.icon.sizes.large}
                isPressed={isPortalOpen}
                storeRef={storeRef}
                onClick={togglePortal}
                inheritRadius
              />
            </Ui.ToolBar.Item>
          );
        }}
      </Dropdown>
    );
  }
}

export default connect((state) => {
  const { valign } = toolbar.getElementPropsForToolbar(state);
  return { valign };
}, {
  setVAlign: setTextBoxVAlign,
})(VAlignToolbarSettingsSimple);
