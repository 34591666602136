import React from 'react';
import PropTypes from 'prop-types';
import * as Ui from '../../../ui';

const prevButton = ({ toFirst, toPrev, can }) => {
  return (
    <Ui.PageNavigation.Cell>
      <Ui.Group.Index
        type={Ui.group.types.compact}
      >
        <Ui.Group.Body>
          <Ui.Group.Item>
            <Ui.Control
              behavior={Ui.control.behaviors.wrapText}
              size={Ui.control.sizes.extraSmall}
              theme={Ui.control.themes.transparentGray}
              offset={Ui.control.offsets.extraSmall}
              onClick={toFirst}
              disabled={!can}
              icon={
                <Ui.Icon
                  size={Ui.icon.sizes.small}
                  type={Ui.icon.types.doubleArrowLeft}
                />
              }
            />
          </Ui.Group.Item>
          <Ui.Group.Item>
            <Ui.Control
              behavior={Ui.control.behaviors.wrapText}
              size={Ui.control.sizes.extraSmall}
              theme={Ui.control.themes.transparentGray}
              offset={Ui.control.offsets.extraSmall}
              onClick={toPrev}
              disabled={!can}
              icon={
                <Ui.Icon
                  size={Ui.icon.sizes.small}
                  type={Ui.icon.types.arrowLeft}
                />
              }
            />
          </Ui.Group.Item>
        </Ui.Group.Body>
      </Ui.Group.Index>
    </Ui.PageNavigation.Cell>
  );
};

prevButton.propTypes = {
  toPrev: PropTypes.func.isRequired,
  toFirst: PropTypes.func.isRequired,
  can: PropTypes.bool.isRequired,
};

export default prevButton;
