import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { loadFont, isFontLoaded } from '@pdffiller/jsf-fontloader';

const FontProvider = ({ fontFamily, bold, italic, children }) => {
  const [isFontAvailable, setIsFontAvailable] = useState(
    isFontLoaded({ fontFamily, bold, italic }),
  );

  useEffect(() => {
    let isMounted = true;
    if (!isFontAvailable) {
      loadFont({ fontFamily, bold, italic })
        .then(() => {
          if (isMounted) {
            setIsFontAvailable(true);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [fontFamily, bold, italic, isFontAvailable]);


  if (!isFontAvailable) {
    return null;
  }

  return children;
};

FontProvider.propTypes = {
  fontFamily: PropTypes.string.isRequired,
  bold: PropTypes.bool.isRequired,
  italic: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};

export default FontProvider;
