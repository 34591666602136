import PropTypes from 'prop-types';
import { Component } from 'react';

import { wizard, managers, dispatchAction } from '@pdffiller/jsf-lazyload';
import { selectors } from '../../..';
import { elemTypes, elemSubTypes, isDisabledType } from '../../../helpers/elemTypes';
import { wizardTraverseModes } from '../../../helpers/const';

export default class ClickToolProviderPhone extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    activateTool: PropTypes.func.isRequired,
    activeTool: PropTypes.shape({
      type: PropTypes.oneOf(
        Object.values(elemTypes),
      ).isRequired,
      subType: PropTypes.string.isRequired,
    }),
    activeElement: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool,
    ]).isRequired,
    wizardActive: PropTypes.bool.isRequired,

    setActiveElement: PropTypes.func.isRequired,
    setIsSearchModeActive: PropTypes.func.isRequired,
    toggleMobileToolsVisibility: PropTypes.func,
  };

  static contextTypes = {
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
    }).isRequired,
    getDefaultTool: PropTypes.func,
  };

  static defaultProps = {
    activeTool: null,
    toggleMobileToolsVisibility: () => {},
  };

  onClick = ({ type, subType }) => {
    this.props.toggleMobileToolsVisibility();
    this.onClickHeaderToolButtonFactory({ type, subType })();
  };

  onClickCommonActions = () => {
    const globalState = this.context.store.getState();

    const traverseMode = selectors.base.getWizardTraverseMode(globalState);
    if (traverseMode !== wizardTraverseModes.allUnfilled) {
      dispatchAction(
        wizard.actions.setWizardTraverseMode, wizardTraverseModes.allUnfilled,
      );
    }

    const isSearchActive = selectors.mode.isSearch(globalState);
    if (isSearchActive) {
      this.props.setIsSearchModeActive(false);
    }
  };

  onClickHeaderToolButtonFactory = ({ type, subType = elemSubTypes.none }) => {
    if (isDisabledType(type)) {
      return () => {};
    }

    if (type === elemTypes.image) {
      return () => {
        this.onClickCommonActions();
        const { activeTool } = this.props;
        if (activeTool && activeTool.type === type && activeTool.subType === subType) {
          return;
        }
        dispatchAction(managers.actions.openImageManager, {});
      };
    }

    if (type === elemTypes.signature) {
      return () => {
        this.onClickCommonActions();
        const { activeTool } = this.props;
        if (activeTool && activeTool.type === type && activeTool.subType === subType) {
          return;
        }
        dispatchAction(managers.actions.openSignatureManager, {});
      };
    }

    // returns function that handles some event
    return () => {
      this.onClickCommonActions();
      const { activeTool, activeElement, wizardActive } = this.props;

      if (activeElement) {
        this.props.setActiveElement(activeElement.id, false);
      }

      if (wizardActive) {
        dispatchAction(wizard.actions.setWizardActive, false);
      }

      // When user activate a tool that is already active
      if (activeTool && activeTool.type === type && activeTool.subType === subType) {
        const defaultTool = this.context.getDefaultTool();
        this.props.activateTool(defaultTool.type, defaultTool.subType);
        return;
      }

      this.props.activateTool(type, subType);
    };
  };


  render() {
    return this.props.children({
      onClick: this.onClick,
    });
  }
}
