import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export const conditionConfiguratorOffsets = {
  small: 'small',
};

const propTypes = {
  placeholder: PropTypes.element,
  checkbox: PropTypes.element,
  info: PropTypes.element,
  children: PropTypes.element,
  offset: PropTypes.oneOf(
    Object.values(conditionConfiguratorOffsets),
  ),
};

const defaultProps = {
  placeholder: null,
  checkbox: null,
  info: null,
  children: null,
  offset: null,
};

const ConditionConfigurator = ({ placeholder, checkbox, info, children, offset }) => {
  return (
    <div
      className={cx(
        'condition-configurator', {
          [`condition-configurator--offset--${offset}`]: offset,
        },
      )}
    >
      <div className="condition-configurator__body">
        {placeholder && (
          <div className="condition-configurator__field-placeholder">
            {placeholder}
          </div>
        )}
        {checkbox && (
          <div className="condition-configurator__checkbox">
            {checkbox}
          </div>
        )}
        <div className="condition-configurator__main">
          {info &&
            <div className="condition-configurator__info">
              {info}
            </div>
          }
          {children &&
            <div className="condition-configurator__group-clause">
              {children}
            </div>
          }
        </div>
      </div>
    </div>
  );
};

ConditionConfigurator.propTypes = propTypes;
ConditionConfigurator.defaultProps = defaultProps;
export default ConditionConfigurator;
