import PropTypes from 'prop-types';
import { Component, Children } from 'react';
import getPromise from 'jsfcore/helpers/getPromise';

const defaultKey = '__default';

export default class StoreRefProvider extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    debug: PropTypes.bool,
    proxy: PropTypes.func,
  };

  static defaultProps = {
    debug: false,
    proxy: null,
  };

  constructor(props) {
    super(props);
    this.references = {
      [defaultKey]: null,
    };
    this.referencesPromises = {};
  }

  getRefs = (uid) => {
    return () => {
      if (this.props.debug) {
        // eslint-disable-next-line
        console.log('getRefs:', uid, this.references);
      }
      return this.references[uid];
    };
  };

  getRef = this.getRefs(defaultKey);

  getRefsPromise = (uid) => {
    return () => {
      // Already have ref - return resolved promise with ref
      if (this.references[uid]) {
        return Promise.resolve(this.references[uid]);
      }

      // If promise not exist - create it, and start catch timer
      if (!this.referencesPromises[uid]) {
        const { resolve, reject, promise } = getPromise();

        const rejectTimeout = setTimeout(() => {
          reject(`Ref ${uid} not set in 300ms`);
          delete this.referencesPromises[uid];
        }, 300);

        this.referencesPromises[uid] = { resolve, promise, rejectTimeout };
      }

      // Return an existing or just created promise
      return this.referencesPromises[uid].promise;
    };
  };

  getRefPromise = this.getRefsPromise(defaultKey);

  storeRefs = (uid) => {
    return (ref) => {
      if (this.props.proxy && uid === defaultKey) {
        this.props.proxy(ref);
      }

      if (this.props.debug) {
        // eslint-disable-next-line
        console.log('storeRefs: ', uid, ref, this.references);
      }

      // Resolve promise and remove it
      const promiseObj = this.referencesPromises[uid];
      if (promiseObj !== undefined) {
        clearTimeout(promiseObj.rejectTimeout);
        promiseObj.resolve(ref);
        delete this.referencesPromises[uid];
      }

      this.references[uid] = ref;
    };
  };

  storeRef = this.storeRefs(defaultKey)

  render() {
    return Children.only(
      this.props.children({
        getRef: this.getRef,
        getRefs: this.getRefs,
        getRefPromise: this.getRefPromise,
        getRefsPromise: this.getRefsPromise,
        storeRef: this.storeRef,
        storeRefs: this.storeRefs,
      }),
    );
  }
}
