import React, { Children } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const GroupClause = ({ children, bar, operator, isHighlighted }) => {
  return (
    <div
      className={cls('group-clause ' +
        'group-clause--offset-vertical--extra-small', {
        'is-highlighted': isHighlighted,
      })}
    >
      <div className="group-clause__body">
        <div className="group-clause__select-operator">
          {operator && operator}
        </div>
        <div className="group-clause__main">
          {Children.map(children, (child) => {
            return (
              <div className="group-clause__item">
                {child}
              </div>
            );
          })}
          <div className="group-clause__control-bar">
            {bar && bar}
          </div>
        </div>
      </div>
    </div>
  );
};

GroupClause.propTypes = {
  children: PropTypes.node.isRequired,
  bar: PropTypes.element,
  operator: PropTypes.element,
  isHighlighted: PropTypes.bool.isRequired,
};

GroupClause.defaultProps = {
  bar: null,
  operator: null,
};

export default GroupClause;
