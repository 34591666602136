import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const unfilledSignatureWrapperStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const SignToolWrapper = ({
  isDisabled,
  children,
  isUnfilledSignTool,

  onFocus,
  onBlur,
  storeRef,
  interceptFocusDataset,
}) => {
  const className = classnames('signTool-SignTool', {
    'elementHover-Content': !isDisabled,
  });
  return (
    <div
      className={className}
      style={
        isUnfilledSignTool
          ? unfilledSignatureWrapperStyles
          : null
      }
      tabIndex={0}

      onBlur={onBlur}
      onFocus={onFocus}
      ref={storeRef}
      {...interceptFocusDataset}
    >
      {children}
    </div>
  );
};

SignToolWrapper.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  isUnfilledSignTool: PropTypes.bool.isRequired,
  children: PropTypes.node,

  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  storeRef: PropTypes.func.isRequired,
  interceptFocusDataset: PropTypes.shape({}),
};

SignToolWrapper.defaultProps = {
  children: null,

  interceptFocusDataset: undefined,
};

export default SignToolWrapper;
