import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isFillable } from 'jsfcore/store/helpers/functions';
import {
  onClickElementInChoiseByClickingMode,
  onMouseDownElementInChoiseByClickingMode,
} from '../store/actions/onClickElementInChoiseByClickingMode';
import * as conditionsSelectors from '../selectors';

import { getDependency, dependenciesMap } from '../di';

const styleDisabled = {
  opacity: 0.5,
  pointerEvents: 'none',
};

const styleEnabled = {
  boxShadow: '0 9px 24px rgba(0, 0, 0, 0.3)',
};

const emptyFn = () => {};

@connect(
  (state) => {
    const jsfcoreSelectors = getDependency(dependenciesMap.jsfcoreSelectors);

    return {
      choiseByClickingModeIsActive:
        conditionsSelectors.getChoiseByClickingModeIsActive(state),
      activeElement: jsfcoreSelectors.elements.getActiveElement(state),
    };
  }, {
    onClickElementInChoiseByClickingMode,
    onMouseDownElementInChoiseByClickingMode,
  },
)
export default class ChoiseByClickingElementWrapperJSF extends Component {
  static propTypes = {
    activeElement: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool,
    ]).isRequired,
    children: PropTypes.func.isRequired,
    choiseByClickingModeIsActive: PropTypes.bool.isRequired,
    element: PropTypes.shape({
      id: PropTypes.string.isRequired,
      template: PropTypes.shape({
        roleId: PropTypes.string,
        name: PropTypes.string.isRequired,
      }),
    }).isRequired,

    onClickElementInChoiseByClickingMode: PropTypes.func.isRequired,
    onMouseDownElementInChoiseByClickingMode: PropTypes.func.isRequired,
  };

  getIsChoiseEnabled = () => {
    const { element, activeElement } = this.props;

    const isChoiseEnabled = (
      activeElement &&
      isFillable(element) &&
      activeElement.id !== element.id &&
      activeElement.template.name !== element.template.name
    );

    return isChoiseEnabled;
  };

  onMouseDown = (event) => {
    this.props.onMouseDownElementInChoiseByClickingMode(event);
  };

  onClick = (event) => {
    const { element } = this.props;

    this.props.onClickElementInChoiseByClickingMode(event, element.id);
  };

  render() {
    const { choiseByClickingModeIsActive } = this.props;
    if (choiseByClickingModeIsActive) {
      const isChoiseEnabled = this.getIsChoiseEnabled();
      return (
        this.props.children({
          isChoiseEnabled,
          onMouseDownChoiseByClicking: this.onMouseDown,
          onClickChoiseByClicking: this.onClick,
          style:
            isChoiseEnabled
              ? styleEnabled
              : styleDisabled,
        })
      );
    }

    return this.props.children({
      isChoiseEnabled: true,
      style: { opacity: 1 },
      onMouseDownChoiseByClicking: emptyFn,
      onClickChoiseByClicking: emptyFn,
    });
  }
}
