import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default function mayBePatched(getPatchedComponent) {
  return function wrapWithComponent(WrappedComponent) {
    class MayBePatched extends Component {
      static contextTypes = {
        getPatchedComponent: PropTypes.func,
      };

      getWrappedInstance = () => {
        return this.wrappedInstance;
      }

      setWrappedInstance = (ref) => {
        this.wrappedInstance = ref;
      };

      render() {
        const PatchedComponent = getPatchedComponent(this.context);
        if (PatchedComponent) {
          return (
            <PatchedComponent
              {...this.props}
              ref={this.setWrappedInstance}
            />
          );
        }

        return (
          <WrappedComponent
            {...this.props}
            ref={this.setWrappedInstance}
          />
        );
      }
    }
    return MayBePatched;
  };
}
