import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getUseSignNowTheme } from '../../../store/selectors';
import { toggleMobileToolsVisibility } from '../../../store/modules/viewport';
import DropDownButton from './DropDownButton';
import ActivateToolProviderPhone from '../../Header/HeaderTools/ActivateToolProviderPhone';
import ClickToolProviderPhone from '../../Header/HeaderTools/ClickToolProviderPhone';
import ToolsDropDownClickOutsideListener from './ToolsDropDownClickOutsideListener';

import * as Ui from '../../../ui';

@connect(
  (state) => {
    return {
      mobileToolsAreVisible: state.viewport.mobileToolsAreVisible,
      toolbar: state.ws.features.toolbar || false,
      useSignNowTheme: getUseSignNowTheme(state),
    };
  }, {
    toggleMobileToolsVisibility,
  },
)
export default class ToolsDropDown extends Component {
  static propTypes = {
    mobileToolsAreVisible: PropTypes.bool.isRequired,
    toolbar: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        visible: PropTypes.bool.isRequired,
      })),
      PropTypes.bool,
    ]).isRequired,
    toggleMobileToolsVisibility: PropTypes.func.isRequired,
    useSignNowTheme: PropTypes.bool.isRequired,
  };

  render() {
    const { mobileToolsAreVisible, toolbar, useSignNowTheme } = this.props;
    if (!toolbar || !mobileToolsAreVisible) {
      return null;
    }

    return (
      <ActivateToolProviderPhone>
        {({
          activeTool,
          activeElement,
          wizardActive,
          activateTool,
          setActiveElement,
          setIsSearchModeActive,
        }) => {
          return (
            <ClickToolProviderPhone
              activeTool={activeTool}
              activateTool={activateTool}
              wizardActive={wizardActive}
              activeElement={activeElement}
              setActiveElement={setActiveElement}
              setIsSearchModeActive={setIsSearchModeActive}
              toggleMobileToolsVisibility={this.props.toggleMobileToolsVisibility}
            >
              {({ onClick }) => {
                return (
                  <ToolsDropDownClickOutsideListener
                    toggleMobileToolsVisibility={this.props.toggleMobileToolsVisibility}
                  >
                    {({ storeRef }) => {
                      return (
                        <Ui.Tools.Dropdown.Index
                          size={Ui.tools.dropdown.sizes.stretched}
                          position={Ui.tools.dropdown.positions.top}
                          offset={Ui.tools.dropdown.offsets.medium}
                          storeRef={storeRef}
                        >
                          <Ui.Tools.Dropdown.Body>
                            <Ui.Menu.Body
                              type={Ui.menu.bodyTypes.grid}
                              justify={Ui.menu.bodyJustifies.center}
                            >
                              <Ui.Menu.List>
                                {toolbar.filter((el) => {
                                  return el.visible;
                                }).map((tool) => {
                                  return (
                                    <DropDownButton
                                      key={tool.id}
                                      tool={tool}
                                      onClick={onClick}
                                      useSignNowTheme={useSignNowTheme}
                                      activeTool={activeTool}
                                    />
                                  );
                                })}
                              </Ui.Menu.List>
                            </Ui.Menu.Body>
                          </Ui.Tools.Dropdown.Body>
                        </Ui.Tools.Dropdown.Index>
                      );
                    }}
                  </ToolsDropDownClickOutsideListener>
                );
              }}
            </ClickToolProviderPhone>
          );
        }}
      </ActivateToolProviderPhone>
    );
  }
}
