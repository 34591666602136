import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { thisDevice } from '@pdffiller/jsf-useragent';

import * as Ui from '../../../../../ui';

export default class ToolbarMobileButton extends Component {
  static propTypes = {
    // either onClick or onTouchStart
    onClick: PropTypes.func,
    onTouchStart: PropTypes.func,
    onFocus: PropTypes.func,

    icon: PropTypes.string.isRequired,
    storeRef: PropTypes.func,
  };

  static defaultProps = {
    onClick: null,
    onTouchStart: null,
    onFocus: null,
    storeRef: null,
  };

  render() {
    const { onClick, onTouchStart, onFocus, icon } = this.props;
    const props = {
      ...(onClick
        ? { onClick }
        : {}),

      ...(onTouchStart
        ? { onTouchStart }
        : {}),

      ...(onFocus
        ? { onFocus }
        : {}),
    };

    return (
      <Ui.Container.SettingButton
        storeRef={this.props.storeRef}
        icon={icon}
        size={Ui.icon.sizes.medium}
        offset={
          thisDevice.isTablet
            ? Ui.control.offsets.null
            : Ui.control.offsets.medium
        }
        inheritRadius
        {...props}
      />
    );
  }
}
