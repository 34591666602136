import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';

import { drawingLayerTypes } from '../../../helpers/const';

import { DrawingLayerCurve, DrawingLayerPoints } from '.';

// https://pdffiller.atlassian.net/browse/JSF-5253
// after click to text tool from updated arrow tool
// UPDATE_ELEMENT > SET_ACTIVE_ELEMENT > SET_ACTIVE_ELEMENT
// it's triggers ElementsProvider -> DrawingLayerView -> DrawingLayerPoints updating
// in this case DrawingLayerView receive text tool as active element
// PureComponent prevents redundant updating and error related to incorrect element
export default class DrawingLayerView extends PureComponent {
  static propTypes = {
    drawingLayerType: PropTypes.oneOf(
      Object.values(drawingLayerTypes),
    ).isRequired,
    pageId: PropTypes.number.isRequired,
  };

  render() {
    const { drawingLayerType } = this.props;
    return (
      <Fragment>
        {drawingLayerType === drawingLayerTypes.points &&
          <DrawingLayerPoints
            pageId={this.props.pageId}
          />
        }
        {drawingLayerType === drawingLayerTypes.curve &&
          <DrawingLayerCurve
            pageId={this.props.pageId}
          />
        }
      </Fragment>
    );
  }
}
