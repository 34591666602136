import isArray from 'lodash/isArray';
import forEach from 'lodash/forEach';

import {
  defaultLineHeight,
  valignValues,
} from '../../../../helpers/const';

// Для полей, в которых нет ни одной линии
export const noneLinesCount = -1;
// Минимальное количество линий 1, для корректной работы с индексами массива задан 0
export const minLinesCount = 0;
export const minFirstStep = 0;
export const baselineHeight = 2;

// Объект в котором задаются атрибуты для svg
const config = {
  svgAttributes: {
    width: 100,
    height: 1000,
  },
  lineAttributes: {
    width: 100,
    height: 100,
    x1: 0,
    x2: 100,
    stroke: '#fff',
  },
};

// Функция, которая задает атрибуты для svg
const setAttributes = (svg, attributes) => {
  if (isArray(attributes)) {
    forEach(attributes, (attribute) => {
      forEach(attribute, (value, key) => {
        svg.setAttribute(String(key), String(value));
      });
    });
  } else {
    // eslint-disable-next-line no-console
    console.warn('Wrong attributes type! Should be array.');
  }
};

// Функция, которая генерирует svg
export const createSvg = (count, step, firstStep) => {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  let stepSum = firstStep;
  setAttributes(svg, [config.svgAttributes]);
  for (let i = 0; i <= count; i++) {
    const newLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');
    const dynamicAttributes = {
      id: `line${i}`,
      y1: String(stepSum),
      y2: String(stepSum),
    };
    setAttributes(newLine, [config.lineAttributes, dynamicAttributes]);
    svg.appendChild(newLine);
    stepSum += step;
  }
  return svg;
};

// Функция, которая конвертирует svg в base64, что позволяет использовать ее как background-image
export const svgToBase64 = (svg) => {
  const stringifySvg = new XMLSerializer().serializeToString(svg);
  return window.btoa(stringifySvg);
};

// Шаг для отрисовки линий baseline в px: равен высоте линии lineHeight
export const getStepForBaseline = ({ fontSize, lineHeight }) => {
  return lineHeight
    ? lineHeight * fontSize
    : defaultLineHeight * fontSize;
};

// Дефолтное значение первого шага в px: высота строки до базовой линии текста (baseline),
// плюс высота самой базовой линии
export const getDefaultFirstStep = (baseline, step) => {
  return baseline * step + baselineHeight;
};

// Поле должно вмещать столько линий baseline, сколько линий текста может уместить,
// поэтому увеличиваем первый шаг на величину шага, если он меньше дефолтного значения
export const getIncreasedFirstStep = (firstStep, step, baseline) => {
  const defaultFirstStep = getDefaultFirstStep(baseline, step);
  if (firstStep < defaultFirstStep) {
    return firstStep + step;
  }
  return firstStep;
};

// Первый шаг для отрисовки линий baseline в px для вертикального выравнивания top
export const getFirstStepForBaselineOnTopVA = ({ step, baseline, height }) => {
  const defaultFirstStep = getDefaultFirstStep(baseline, step);
  return height > defaultFirstStep
    ? defaultFirstStep
    : minFirstStep;
};

// Первый шаг для отрисовки линий baseline в px для вертикального выравнивания middle
export const getFirstStepForBaselineOnMiddleVA = ({ step, baseline, height, isCellsElement }) => {
  const defaultFirstStep = getDefaultFirstStep(baseline, step);
  if (height > defaultFirstStep) {
    const middleStep = height / 2 + step / 2 - (step - step * baseline);
    const firstStep = (middleStep % step) + baselineHeight;

    return isCellsElement
      ? middleStep + baselineHeight
      : getIncreasedFirstStep(firstStep, step, baseline);
  }
  return minFirstStep;
};

// Первый шаг для отрисовки линий baseline в px для вертикального выравнивания bottom
export const getFirstStepForBaselineOnBottomVA = ({ step, baseline, height, isCellsElement }) => {
  const lastStep = step - baseline * step - baselineHeight;
  const defaultFirstStep = getDefaultFirstStep(baseline, step);

  if (height > lastStep + defaultFirstStep) {
    const firstStep = (height - lastStep) % step;

    return isCellsElement
      ? height - lastStep
      : getIncreasedFirstStep(firstStep, step, baseline);
  }
  return minFirstStep;
};

// Функция для расчета первого шага для отрисовки линий baseline в px
// в зависимости от вертикального выравнивания
export const getFirstStepForBaselineOnVA = (valign) => {
  if (!valign) {
    return getFirstStepForBaselineOnTopVA;
  }
  return {
    [valignValues.top]: getFirstStepForBaselineOnTopVA,
    [valignValues.middle]: getFirstStepForBaselineOnMiddleVA,
    [valignValues.bottom]: getFirstStepForBaselineOnBottomVA,
  }[valign];
};

// Первый шаг для отрисовки линий baseline в px:
// зависит от вертикального выравнивания
// и от того, является ли поле Cells
export const getFirstStepForBaseline = ({ step, baseline, valign, height, isCellsElement }) => {
  const calculateFirstStep = getFirstStepForBaselineOnVA(valign);
  return calculateFirstStep({
    step,
    baseline,
    height,
    isCellsElement,
  });
};

// Количество линий baseline, которые помещаются в поле
export const getCountForBaseline = ({ step, firstStep, height, isCellsElement }) => {
  if (firstStep !== minFirstStep) {
    return isCellsElement
      ? minLinesCount
      : Math.floor((height - firstStep) / step);
  }
  return noneLinesCount;
};
