const getStyleWithPointerEvent = ({
  style,
  elementProps,
  isOuter,
  isActiveLinks,
  isEraseElement,
}) => {
  const { hyperlink, linkedTextId } = elementProps;
  const hyperlinkStyles = {
    ...(hyperlink && isActiveLinks && {
      pointerEvents: isOuter
        ? 'auto'
        : 'none',
    }),
  };

  return {
    ...style,
    ...hyperlinkStyles,
    ...(isEraseElement && !linkedTextId && { pointerEvents: 'auto' }),
  };
};

export default getStyleWithPointerEvent;
