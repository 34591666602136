import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import * as lazySelectors from '@pdffiller/jsf-lazyload/store/selectors';
import { selectors } from '../..';
import {
  isCheckmarkElement as isCheckmarkElementFunction,
  isDate,
  isDropdownElement as isDropdownElementFunction,
  isFillable,
  isPicture,
  isRadioElement as isRadioElementFunction,
  isSignature,
} from '../../store/helpers/functions';

import {
  isEnabledElement,
  isConditionalElement,
} from '../../helpers/utils';

@connect(
  (__, { element }) => {
    const { id: elementId } = element;
    return (state) => {
      return {
        isActiveElement: selectors.base.getActiveElementId(state) === elementId,
        isStretching: selectors.base.getStretchingElementId(state) === elementId,
        isDraggingInRedux: selectors.base.getDragElementId(state) === elementId,
        fConstructorLoadedAndShown: selectors.mode.isConstructor.includingPreview(state),
        isWizardLoaded: lazySelectors.common.getIsWizardLoaded(state),
        isConstructorMode: selectors.mode.isConstructor(state),
        isHighlighted: selectors.getIsHighlighted(state, elementId),
      };
    };
  },
)
export default class ElementDataProvider extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    element: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      isEnabledByDependencies: PropTypes.bool.isRequired,
    }).isRequired,

    // @connect
    isActiveElement: PropTypes.bool.isRequired,
    isStretching: PropTypes.bool.isRequired,
    fConstructorLoadedAndShown: PropTypes.bool.isRequired,
    isConstructorMode: PropTypes.bool.isRequired,
    isDraggingInRedux: PropTypes.bool.isRequired,
    isHighlighted: PropTypes.bool.isRequired,
    isWizardLoaded: PropTypes.bool.isRequired,
  };

  render() {
    const {
      fConstructorLoadedAndShown,
      isActiveElement,
      isDraggingInRedux,
      isHighlighted,
      isStretching,
      isConstructorMode,
      isWizardLoaded,
      element,
    } = this.props;
    const isCheckmarkElement = isCheckmarkElementFunction(element);
    const isDateElement = isDate(element);
    const isDropdownElement = isDropdownElementFunction(element);
    const isPictureElement = isPicture(element);
    const isRadioElement = isRadioElementFunction(element);
    const isSignatureElement = isSignature(element);
    const isFillableElement = isFillable(element);
    const isEnabled = isEnabledElement(element);
    const isConditional = isConditionalElement(element);
    const isElementDisabledByDependencies = element.isEnabledByDependencies === false;

    return this.props.children({
      scale: 1,
      fConstructorLoadedAndShown,
      isActiveElement,
      isDraggingInRedux,
      isHighlighted,
      isStretching,
      isConstructorMode,
      isCheckmarkElement,
      isDateElement,
      isDropdownElement,
      isPictureElement,
      isRadioElement,
      isSignatureElement,
      isFillableElement,
      isEnabledElement: isEnabled,
      isElementDisabledByDependencies,
      isConditionalElement: isConditional,
      isWizardLoaded,
    });
  }
}
