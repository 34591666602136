import PropTypes from 'prop-types';
import { Component } from 'react';
import {
  fixPosition,
  getEventPos,
} from '../../../helpers/dragUtils';

export default class DragBounds extends Component {
  static propTypes = {
    bounds: PropTypes.shape({
      top: PropTypes.number.isRequired,
      right: PropTypes.number.isRequired,
      bottom: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
    }).isRequired,
    componentSize: PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
    }).isRequired,
    children: PropTypes.func.isRequired,
    startPosition: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  }

  static defaultProps = {
    startPosition: {
      x: 0,
      y: 0,
    },
  }

  constructor(props) {
    super(props);
    this.state = {
      position: props.startPosition,
      // eslint-disable-next-line react/no-unused-state
      dragStartPoint: { x: -1, y: -1 },
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ bounds, componentSize }) {
    if (
      bounds !== this.props.bounds ||
      componentSize !== this.props.componentSize
    ) {
      if (!this.getIsPositionValid(bounds, componentSize)) {
        this.setState((prevState) => {
          const position = fixPosition(prevState.position, componentSize, bounds);
          return { position };
        });
      }
    }
  }

  getIsPositionValid = (bounds, position) => {
    const { top, right, bottom, left } = bounds;
    const { height, width } = position;
    const { x, y } = this.state.position;

    return x <= top && x + width <= right && y + height <= bottom && y <= left;
  };

  onDragStart = (event) => {
    const eventPos = getEventPos(event, this.props);

    if (!eventPos) {
      return;
    }

    this.setState((prevState) => {
      return {
        dragStartPoint: eventPos,
        initialPosition: prevState.position,
      };
    });
  }

  onDragMove = (event) => {
    const eventPos = getEventPos(event, this.props);
    if (!eventPos) {
      return;
    }

    this.setState((prevState) => {
      const dx = eventPos.x - prevState.dragStartPoint.x;
      const dy = eventPos.y - prevState.dragStartPoint.y;
      return {
        position: fixPosition(
          {
            x: prevState.initialPosition.x + dx,
            y: prevState.initialPosition.y + dy,
          },
          this.props.componentSize,
          this.props.bounds,
        ),
      };
    });
  }

  onDragStop = () => {
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      initialPosition: { x: -1, y: -1 },
      // eslint-disable-next-line react/no-unused-state
      dragStartPoint: { x: -1, y: -1 },
    });
  }

  render() {
    return this.props.children({
      position: this.state.position,
      onDragStart: this.onDragStart,
      onDragMove: this.onDragMove,
      onDragStop: this.onDragStop,
    });
  }
}
