import PropTypes from 'prop-types';
import { Component } from 'react';

import { wizard, managers, dispatchAction } from '@pdffiller/jsf-lazyload';
import { selectors } from '../../..';
import { elemTypes, elemSubTypes, isDisabledType } from '../../../helpers/elemTypes';
import { wizardTraverseModes } from '../../../helpers/const';

export default class ClickToolProvider extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    activateTool: PropTypes.func.isRequired,
    activeTool: PropTypes.shape({
      type: PropTypes.string,
      subType: PropTypes.string,
    }),
    activeElementId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]).isRequired,
    haveWizardFocus: PropTypes.bool.isRequired,
    wizardActive: PropTypes.bool.isRequired,

    setActiveElement: PropTypes.func.isRequired,
    toolDraggingStarted: PropTypes.func.isRequired,
    toolDraggingStopped: PropTypes.func.isRequired,
    setIsSearchModeActive: PropTypes.func.isRequired,
  };

  static contextTypes = {
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
    }).isRequired,
    getDefaultTool: PropTypes.func,
  };

  static defaultProps = {
    activeTool: null,
  };

  constructor(props) {
    super(props);
    this.draggingStartedTimeout = null;
  }

  onMouseDown = ({ type, subType }) => {
    this.draggingStartedTimeout = setTimeout(() => {
      this.draggingStartedTimeout = null;
      this.props.toolDraggingStarted();
    }, 100);

    this.onClickHeaderToolButtonFactory({ type, subType })();
    document.addEventListener('mouseup', this.onMouseUp);
  };

  onMouseUp = () => {
    if (this.draggingStartedTimeout) {
      clearTimeout(this.draggingStartedTimeout);
      this.draggingStartedTimeout = null;
    } else {
      setTimeout(this.props.toolDraggingStopped, 10);
    }
    document.removeEventListener('mouseup', this.onMouseUp);
  };

  /**
   * По клику на любой тул в хидере мы сбрасываем wizardTraverseMode и searchMode
   */
  onClickCommonActions = () => {
    const globalState = this.context.store.getState();

    const traverseMode = selectors.base.getWizardTraverseMode(globalState);
    if (traverseMode !== wizardTraverseModes.allUnfilled) {
      dispatchAction(
        wizard.actions.setWizardTraverseMode, wizardTraverseModes.allUnfilled,
      );
    }

    const isSearchActive = selectors.mode.isSearch(globalState);
    if (isSearchActive) {
      this.props.setIsSearchModeActive(false);
    }
  }

  onClickHeaderToolButtonFactory = ({ type, subType = elemSubTypes.none }) => {
    if (isDisabledType(type)) {
      return () => {};
    }

    if (type === elemTypes.image) {
      return () => {
        this.onClickCommonActions();
        const { activeTool, activeElementId } = this.props;
        if (activeTool && activeTool.type === type && activeTool.subType === subType) {
          return;
        }
        dispatchAction(managers.actions.openImageManager, {});

        if (activeElementId) {
          this.props.setActiveElement(activeElementId, false);
        }

        setTimeout(() => {
          dispatchAction(managers.actions.openImageManager, {});
        }, 0);
      };
    }

    if (type === elemTypes.signature) {
      return () => {
        this.onClickCommonActions();
        const { activeTool, activeElementId } = this.props;
        if (activeTool && activeTool.type === type && activeTool.subType === subType) {
          return;
        }

        if (activeElementId) {
          this.props.setActiveElement(activeElementId, false);
        }

        setTimeout(() => {
          dispatchAction(managers.actions.openSignatureManager, {});
        }, 0);
      };
    }

    // returns function that handles some event
    return () => {
      this.onClickCommonActions();
      const { activeTool, activeElementId, haveWizardFocus, wizardActive } = this.props;

      if (wizardActive) {
        if (haveWizardFocus && activeElementId) {
          this.props.setActiveElement(activeElementId, false);
        }
        dispatchAction(wizard.actions.setWizardActive, false);
      }

      // When user activate a tool that is already active
      if (activeTool && activeTool.type === type && activeTool.subType === subType) {
        const defaultTool = this.context.getDefaultTool();
        this.props.activateTool(defaultTool.type, defaultTool.subType);
        return;
      }

      this.props.activateTool(type, subType);
    };
  };

  onMoreButtonClick = (isPortalOpen) => {
    if (!isPortalOpen) {
      this.onClickCommonActions();
    }
  };

  render() {
    return this.props.children({
      onMouseDown: this.onMouseDown,
      onMoreButtonClick: this.onMoreButtonClick,
    });
  }
}
