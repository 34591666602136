import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateElement } from 'ws-editor-lib/actions';
import get from 'lodash/get';
import { wizard, managers, getLazy, dispatchAction } from '@pdffiller/jsf-lazyload';

import { cancellableOpts } from '../../store/modules/undoRedo';
import { setFillablePictureSelectingId } from '../../store/modules/events';
import { defaultDateFormat, elemTypes } from '../../helpers/const';
import { formatDate } from '../../helpers/utils';
import { elemSubTypes } from '../../helpers/elemTypes';

import * as selectors from '../../store/selectors';
import ImagePlaceholder from '../../ui/ImagePlaceholder/ImagePlaceholder';
import ElementIconWrapper from '../../components/Element/ElementIcon/ElementIconWrapper';
// NOTE: this values got from css+text, if element has less width -> don't show label
const placeholderMinWidth = {
  [elemTypes.image]: 82,
  [elemTypes.signature]: 115,
};

export default function pictureDecorator() {
  return function wrapWithComponent(WrappedComponent) {
    class PictureDecorator extends Component {
      static propTypes = {
        id: PropTypes.string.isRequired,
        element: PropTypes.shape({
          id: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          subType: PropTypes.string.isRequired,
          template: PropTypes.shape({
            width: PropTypes.number,
          }),
          content: PropTypes.shape({
            subType: PropTypes.string,
          }),
        }).isRequired,
        cacheSignature: PropTypes.oneOfType([
          PropTypes.object,
          PropTypes.bool,
        ]).isRequired,
        locale: PropTypes.shape({
          initials: PropTypes.string.isRequired,
          signature: PropTypes.string.isRequired,
          image: PropTypes.string.isRequired,
        }).isRequired,

        // from redux
        useSigDateStamp: PropTypes.bool.isRequired,

        // actions
        updateElement: PropTypes.func.isRequired,
        setFillablePictureSelectingId: PropTypes.func.isRequired,

        elementIcon: PropTypes.oneOfType([
          PropTypes.element,
          PropTypes.bool,
        ]), // not required for ghost
      }

      static defaultProps = {
        elementIcon: null,
      }

      getWrappedInstance = () => {
        return this.wrappedComponentRef;
      };

      getUnfilledInnerText = () => {
        const { type, subType, template: { width } } = this.props.element;
        if (width <= placeholderMinWidth[type]) {
          return null;
        }

        if (type === elemTypes.signature) {
          return subType === elemSubTypes.signature.initials
            ? this.props.locale.initials
            : this.props.locale.signature;
        }

        return this.props.locale.image;
      };

      storeWrappedComponentRef = (ref) => {
        this.wrappedComponentRef = ref;
      }

      isFillableFilled = () => {
        return (
          (this.props.element.content &&
          this.props.element.content.subType) ||
          false
        );
      };

      onFillableClick = () => {
        const { id, type } = this.props.element;
        if (type === elemTypes.image) {
          this.props.setFillablePictureSelectingId(id);
          dispatchAction(managers.actions.openImageManager, {});
        } else {
          const getSignToReuse = getLazy(wizard.functions.getSignToReuse);
          const signToReuse = (getSignToReuse && getSignToReuse({
            cacheSignature: this.props.cacheSignature,
            element: this.props.element,
          })) || false;
          if (signToReuse) {
            const content = {
              ...signToReuse.content,
              ...(this.props.useSigDateStamp
                ? { dateStamp: formatDate(new Date(), defaultDateFormat) }
                : {}),
            };
            this.props.updateElement(id, content, cancellableOpts);
          } else {
            dispatchAction(
              wizard.actions.triggerSelectSignatureClick,
              this.props.element,
            );
          }
        }
      };

      renderUnfilledFillable = () => {
        const { elementIcon } = this.props;
        const innerStyle = {
          whiteSpace: 'nowrap',
        };
        const innerText = this.getUnfilledInnerText();

        return (
          <div
            className="fillable-field__overlay"
            style={innerStyle}
          >
            <ElementIconWrapper>{elementIcon}</ElementIconWrapper>
            <ImagePlaceholder innerText={innerText} />
          </div>
        );
      };

      render() {
        const options = {
          ...this.props,
          renderUnfilledFillable: this.renderUnfilledFillable,
          isFillableFilled: this.isFillableFilled,
          ref: this.storeWrappedComponentRef,
          updateElement: this.props.updateElement,
        };

        return <WrappedComponent {...options} />;
      }
    }

    return connect(
      (state) => {
        return ({
          useSigDateStamp: state.ws.defaults.useSigDateStamp,
          cacheSignature: get(state, 'wizard.cacheSignature', false),
          locale: selectors.locale.getEditorLocale(state).placeholders,
        });
      }, {
        updateElement, setFillablePictureSelectingId,
      },
    )(PictureDecorator);
  };
}
