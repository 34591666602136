import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { thisDevice } from '@pdffiller/jsf-useragent';

import WheelConverter from 'jsfcore/helpers/WheelConverter';
import WheelZoom from 'jsfcore/components/Page/PagePinch/WheelZoom';
import { zoomTickSNF } from 'jsfcore/helpers/const';

import Hammer, { inputType } from '../../../helpers/Hammer';
import { selectors } from '../../..';
import {
  SCALE_FIT_PAGE,
  SCALE_FIT_WIDTH,
} from '../../../store/modules/navigationActionTypes';
import { setAllScales, setAllScalesContinious } from '../../../store/modules/navigation';
import { setPagePinching } from '../../../store/modules/events';

const preventAnyPinchEvent = (event) => {
  if (!event) {
    return;
  }
  event.preventDefault();
};

const hammerSetting = __CLIENT__ && {
  touchAction: 'auto',
  inputClass: inputType(),
};

@connect(
  (state, { activePageId }) => {
    return {
      activePageId,
      defaultScales: selectors.getDefaultScales(state),
      headerScales: selectors.base.getHeaderScales(state),
      nextScale: selectors.base.getNextScale(state),
      scale: selectors.getScale(state, activePageId),
    };
  }, {
    setAllScales,
    setPagePinching,
    setAllScalesContinious,
  },
)
export default class PaginationContiniousPinch extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,

    // @connect
    defaultScales: PropTypes.arrayOf(
      PropTypes.shape({
        [SCALE_FIT_PAGE]: PropTypes.number.isRequired,
        [SCALE_FIT_WIDTH]: PropTypes.number.isRequired,
      }),
    ).isRequired,
    // TODO: remove eslint-disable-next-line after remove UNSAFE_componentWillReceiveProps
    // eslint-disable-next-line react/no-unused-prop-types
    activePageId: PropTypes.number.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    headerScales: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]).isRequired,
    ).isRequired,
    setAllScales: PropTypes.func.isRequired,
    setAllScalesContinious: PropTypes.func.isRequired,
    nextScale: PropTypes.oneOfType([
      PropTypes.number, PropTypes.string,
    ]).isRequired,
    workspace: PropTypes.shape({
      top: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      framePadding: PropTypes.shape({
        top: PropTypes.number.isRequired,
        bottom: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        right: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    frameOffset: PropTypes.shape({
      top: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
      scrollTop: PropTypes.number.isRequired,
      scrollLeft: PropTypes.number.isRequired,
    }).isRequired,
    scale: PropTypes.number,
    getRef: PropTypes.func.isRequired,
    setPagePinching: PropTypes.func.isRequired,
  };

  static defaultProps = {
    scale: 1,
  }

  componentDidMount() {
    this.delegateEvents();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      defaultScales,
      activePageId,
      nextScale,
      headerScales,
    } = nextProps;

    if (defaultScales[activePageId] !== this.props.defaultScales[activePageId]) {
      this.onDefaultScaleChanged({ defaultScales, activePageId, headerScales });
    }

    if (nextScale && nextScale !== this.props.nextScale) {
      this.onNextScaleChanged({ defaultScales, activePageId, headerScales });
    }
  }

  componentWillUnmount() {
    if (this.pinchMoveAminationFrameReqId) {
      cancelAnimationFrame(this.pinchMoveAminationFrameReqId);
    }
  }

  onDefaultScaleChanged = ({ defaultScales, activePageId, headerScales }) => {
    const FP = SCALE_FIT_PAGE;
    const FW = SCALE_FIT_WIDTH;
    const headerScale = headerScales[activePageId];
    const defaultScale = defaultScales[activePageId];
    const prevDefaultScale = this.props.defaultScales[activePageId];
    if (!prevDefaultScale) {
      return;
    }
    if (
      (headerScale === FP && defaultScale[FP] !== prevDefaultScale[FP]) ||
      (headerScale === FW && defaultScale[FW] !== prevDefaultScale[FW])
    ) {
      this.props.setAllScales(headerScale, activePageId);
    }
  };

  onNextScaleChanged = (nextProps) => {
    const { nextScale, activePageId } = nextProps;
    this.props.setAllScales(nextScale, activePageId);
  };

  delegateEvents() {
    if (!Hammer || !__CLIENT__) {
      return;
    }

    if (!thisDevice.isDesktop && !thisDevice.isTablet) {
      const node = this.props.getRef();
      const hammer = new Hammer.Manager(node, hammerSetting);
      hammer.add([new Hammer.Pinch()])
        .on('pinchstart', this.onPinchStart)
        .on('pinchmove', this.onPinchMove)
        .on('pinchend', this.onPinchEnd)
        .on('pinchcancel', preventAnyPinchEvent);
    }

    if (!thisDevice.isDesktop) {
      // prevent pinch for root node
      const contentNode = document.getElementById('content');
      const hammerContentNode = new Hammer.Manager(contentNode, hammerSetting);
      hammerContentNode.add([new Hammer.Pinch()])
        .on('pinchstart', preventAnyPinchEvent)
        .on('pinchmove', preventAnyPinchEvent)
        .on('pinchend', preventAnyPinchEvent)
        .on('pinchcancel', preventAnyPinchEvent);
    }

    if (thisDevice.isIOS) {
      // JSF-1676: в iOS10 стало можно зумить страницу,
      // даже если установлен user-scalable=no (типа заботятся об accessibility)
      // пока помогоает только такой хак
      document.addEventListener('touchmove', (event) => {
        // выдает warning, т.к. при имитации в хроме "event.scale" === undefined
        // (на реальном айфоне все нормально)
        if (event.scale !== undefined && event.scale !== 1) {
          event.preventDefault();
        }
      });
    }

    if (thisDevice.isDesktop) {
      const wheelConverter = new WheelConverter({ zoomTick: zoomTickSNF });

      this.wheelZoom = new WheelZoom({
        onStart: () => {
          this.onPinchStart({
            ...wheelConverter.onStartToOnPinchStart(this.props),
            scale: this.props.scale,
          });
        },
        onMove: (event) => {
          this.onPinchMove(
            wheelConverter.onMoveToOnPinchMove(event, this.startScale),
          );
        },
        onEnd: () => {
          this.onPinchEnd(
            wheelConverter.onEndToOnPinchEnd(this.props.scale),
          );
        },
        node: this.props.getRef(),
      });
    }
  }

  onPinchStart = (event) => {
    preventAnyPinchEvent(event);
    this.startScale = +(event.scale.toFixed(2));
    this.props.setPagePinching(true);
  };

  onPinchMove = (event) => {
    preventAnyPinchEvent(event);

    const scale = +(event.scale.toFixed(2));
    const pinchIn = scale > this.startScale;
    if (
      !this.endScale || (
        (pinchIn && scale > this.endScale) ||
        (!pinchIn && scale < this.endScale)
      )
    ) {
      this.pinchMoveAminationFrameReqId = requestAnimationFrame(() => {
        this.props.setAllScalesContinious(+(scale.toFixed(2)));
      });
    }
  };

  onPinchEnd = (event) => {
    preventAnyPinchEvent(event);
    this.endScale = +(event.scale.toFixed(2));
    this.props.setPagePinching(false);
  };

  render() {
    return this.props.children;
  }
}
