import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Ui from '../../../../ui';
import { toolbar } from '../../../../store/selectors';
import { getUnsupportedInscriptionsByFont, stopEvent } from '../../../../helpers/utils';
import toggleElementFontProperty from '../../../../store/thunks/toggleElementFontProperty';


const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const fontProperties = {
  bold: 'bold',
  italic: 'italic',
  underline: 'underline',
};

const fontStyleComponentFactory = (fontProperty) => {
  const capitalizedProperty = capitalizeFirstLetter(fontProperty);
  @connect(
    (state) => {
      const {
        [fontProperty]: fontPropertyValue,
        isActiveElement,
        fontFamily,
      } = toolbar.getElementPropsForToolbar(state);

      const { [`${fontProperty}Unsupported`]: isDisabled } = getUnsupportedInscriptionsByFont(fontFamily);

      return {
        fontPropertyValue,
        isDisabled: !isActiveElement || Boolean(isDisabled),
      };
    }, {
      toggleElementFontProperty,
    },
  )

  class FontStyleToolbarSettings extends Component {
    static propTypes = {
      fontPropertyValue: PropTypes.bool.isRequired,
      isDisabled: PropTypes.bool.isRequired,
      toggleElementFontProperty: PropTypes.func.isRequired,
    };

    handleFontStyleClickFactory = (fontStyleProperty) => {
      return (event) => {
        stopEvent(event);
        this.props.toggleElementFontProperty(fontStyleProperty);
      };
    };

    onClick = this.handleFontStyleClickFactory(fontProperty);

    render() {
      return (
        <Ui.Container.SettingButton
          data-autotest={`itemBtn${capitalizedProperty}}`}
          size={Ui.icon.sizes.large}
          icon={Ui.icon.types[`letter${capitalizedProperty}`]}
          title={Ui.locales.titles[`letter${capitalizedProperty}`]}
          isPressed={this.props.fontPropertyValue}
          isDisabled={this.props.isDisabled}
          onClick={this.onClick}
          inheritRadius
        />
      );
    }
  }

  return FontStyleToolbarSettings;
};

const BToolbarSettings = fontStyleComponentFactory(fontProperties.bold);
const IToolbarSettings = fontStyleComponentFactory(fontProperties.italic);
const UToolbarSettings = fontStyleComponentFactory(fontProperties.underline);

export {
  BToolbarSettings,
  IToolbarSettings,
  UToolbarSettings,
};
