import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cls from 'classnames';
import { connect } from 'react-redux';
import { wizard, getLazy } from '@pdffiller/jsf-lazyload';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { updateElement } from 'ws-editor-lib/actions';
import { momentConverter } from '../../../store/helpers/functions';
import {
  mobileDefaultDateFormat,
  stylePositionAbsolute,
} from '../../../helpers/const';
import { mergeInputStyles, stopEvent } from '../../../helpers/utils';
import { getSelectionNode } from '../../Tools/TextTool/utils/textToolUtils';

const minDate = '1900-01-01';
const maxDate = '2099-12-31';

@connect(null, { updateElement })
export default class IOSDateControl extends Component {
  static propTypes = {
    isDisabled: PropTypes.bool.isRequired,
    isPopupVisible: PropTypes.bool,
    isActiveElement: PropTypes.bool.isRequired,
    text: PropTypes.string,
    divStyle: PropTypes.shape({}),
    textareaStyle: PropTypes.shape({}),
    isReadonly: PropTypes.bool,
    isHighlighted: PropTypes.bool,
    tabIndex: PropTypes.number,
    format: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    initial: PropTypes.string,

    storeInputRef: PropTypes.func.isRequired,
    storeWrapperRef: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,

    updateElement: PropTypes.func.isRequired,
  };

  static defaultProps = {
    text: '',
    initial: '',
    isPopupVisible: false,
    divStyle: {},
    textareaStyle: {},
    isReadonly: false,
    isHighlighted: false,
    tabIndex: -1,
    storeWrapperRef: null,
    onFocus: () => {
      return null;
    },
    onBlur: () => {
      return null;
    },
  };

  constructor(props) {
    super(props);

    this.inputRef = null;
  }

  getConvertedDate = (oldDate) => {
    return momentConverter({
      oldDate,
      oldFormat: this.props.format,
      newFormat: mobileDefaultDateFormat,
    });
  };

  storeInputRef = (ref) => {
    this.inputRef = ref;

    this.props.storeInputRef(ref);
  };

  blur = () => {
    window.getSelection().removeAllRanges();
    const focusNode = getSelectionNode(true);
    focusNode.blur();
  };

  onInput = (event) => {
    const convertedDate = this.getConvertedDate(this.props.text);

    if (convertedDate === event.target.value) {
      // TODO REFACTORING TO NORMAL FLOW
      this.props.updateElement(this.props.id, { text: '' });
      this.inputRef.blur();
      this.props.onChange(event);
    }
  };

  onFocus = (event) => {
    stopEvent(event);
    this.blur();
    this.props.onFocus(event);
  };

  onBlur = () => {
    // No need to call onBlur, since on iPad datepicker opens in modal window. We just need to
    // close datepicker without element deactivation.
    // Task: JSF-7202
    if (thisDevice.isTablet) {
      return;
    }
    this.props.onBlur();
  };

  render() {
    const {
      isReadonly,
      isPopupVisible,
      isActiveElement,
      isDisabled,
      isHighlighted,
      divStyle,
      text,
      textareaStyle,
      tabIndex,
      initial,
      format,
    } = this.props;

    const classes = [
      'textTool-TextTool',
      (isReadonly || !isActiveElement || isPopupVisible)
        ? 'selectionDisabled-TextTool'
        : 'selectionEnabled-TextTool',
      {
        'jsf-fillable': !isDisabled,
        'jsf-fillable--active': isActiveElement && !isDisabled,
        'elementHover-Content': !isDisabled,
        'is-required': isHighlighted,
      },
    ];

    const getDateToSet = getLazy(wizard.functions.getDateToSet);
    const initialText = getDateToSet(initial, format);
    const value = this.getConvertedDate(text || initialText);

    return (
      <div
        ref={this.props.storeWrapperRef}
        style={divStyle}
        className={cls(...classes)}
      >
        <span style={stylePositionAbsolute}>
          {text}
        </span>
        <input
          type="date"
          min={minDate}
          max={maxDate}
          value={value}
          style={mergeInputStyles(textareaStyle)}
          ref={this.storeInputRef}
          // for ios we should use this handler
          onFocus={this.props.onFocus}
          onBlur={this.onBlur}
          onInput={this.onInput}
          onChange={this.props.onChange}
          autoComplete="off"
          autoCapitalize="off"
          autoCorrect="off"
          spellCheck="false"
          tabIndex={tabIndex}
          readOnly={isReadonly}
        />
      </div>
    );
  }
}
