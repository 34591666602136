import { Component } from 'react';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import reduce from 'lodash/reduce';
import { modeIds } from '../../../../helpers/const';

const getActivateMainModeFromProps = (props) => {
  return props.activateMainMode;
};
const getActivateConstructorModeFromProps = (props) => {
  return props.activateConstructorMode;
};
const getActivatePreviewModeFromProps = (props) => {
  return props.activatePreviewMode;
};
const getSelectedModeIdFromProps = (props) => {
  return props.selectedModeId;
};
const getTabsFromProps = (props) => {
  return props.tabs;
};

const createTabsHandlers = createSelector(
  [
    getSelectedModeIdFromProps,
    getTabsFromProps,
    getActivateMainModeFromProps,
    getActivateConstructorModeFromProps,
    getActivatePreviewModeFromProps,
  ],
  (
    selectedModeId,
    tabs,
    activateMainMode,
    activateConstructorMode,
    activatePreviewMode,
  ) => {
    return reduce(tabs, (acc, tab, key) => {
      if (key === selectedModeId) {
        return {
          ...acc,
          [key]: null,
        };
      }

      if (key === modeIds.main) {
        return {
          ...acc,
          [key]: activateMainMode,
        };
      }

      if (key === modeIds.constructor) {
        return {
          ...acc,
          [key]: activateConstructorMode,
        };
      }

      if (key === modeIds.preview) {
        return {
          ...acc,
          [key]: activatePreviewMode,
        };
      }

      return acc;
    }, {});
  },
);

export default class ModesPanelTabsHandlersProvider extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
  };

  static contextTypes = {
    store: PropTypes.shape({
      getState: PropTypes.func.isRequired,
    }).isRequired,
  };

  render() {
    return this.props.children({
      tabsHandlers: createTabsHandlers(this.props),
    });
  }
}
