import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import cx from 'classnames';

import Text, {
  textSizes,
  textThemes,
} from './Text';

export const checkboxSizes = {
  smallStretched: 'small-stretched',
  null: null,
};

export const checkboxOffsets = {
  medium: 'medium',
  null: null,
};

export default class Checkbox extends Component {
  static propTypes = {
    value: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.element,
    size: PropTypes.oneOf(
      Object.values(checkboxSizes),
    ),
    offset: PropTypes.oneOf(
      Object.values(checkboxOffsets),
    ),
    disabled: PropTypes.bool,

    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: null,
    title: '',
    children: null,
    size: checkboxSizes.null,
    offset: checkboxOffsets.null,
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.checkboxId = uuid.v4();
    this.state = {
      isChecked: props.value || false,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.isChecked !== !!nextProps.value) {
      this.setState({
        isChecked: nextProps.value,
      });
    }
  }

  onChange = () => {
    this.props.onChange(!this.state.isChecked);
  };

  render() {
    const {
      title,
      size,
      offset,
    } = this.props;
    return (
      <label
        className={cx(
          'checkbox', {
            [`checkbox--size--${size}`]: size,
            [`checkbox--offset--${offset}`]: offset,
          },
        )}
        htmlFor={this.checkboxId}
      >
        <span className="checkbox__body">
          <span className="checkbox__indicator">
            <input
              disabled={this.props.disabled}
              className="checkbox__object"
              type="checkbox"
              id={this.checkboxId}
              onChange={this.onChange}
              checked={this.state.isChecked}
            />
          </span>
          <span className="checkbox__label">
            {this.props.children
              ? this.props.children
              : <Text
                id={title}
                size={textSizes[14]}
                theme={textThemes.dovegray}
              />
            }
          </span>
        </span>
      </label>
    );
  }
}
