import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import {
  screenToPageFreeGhost,
  getGhostPosition,
} from '../../helpers/utils';
import { selectors } from '../..';

export const mapStateToProps = (state) => {
  const {
    navigation: {
      frameScrolls,
      activePageId,
    },
  } = state;

  return {
    ghostElementForRender: selectors.getGhostElementForRender(state),
    activePageFrameScroll: frameScrolls[activePageId],
    isConstructorMode: selectors.mode.isConstructor(state),
  };
};

export class GhostDataProvider extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    ghostElementForRender: PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
      }),
    }),
    frameSizes: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          height: PropTypes.number.isRequired,
        }),
        PropTypes.bool,
      ]).isRequired,
    ).isRequired,
    workspace: PropTypes.shape({
      height: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
      top: PropTypes.number.isRequired,
      framePadding: PropTypes.shape({
        top: PropTypes.number.isRequired,
        bottom: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        right: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    isConstructorMode: PropTypes.bool.isRequired,
    scales: PropTypes.arrayOf(
      PropTypes.number,
    ).isRequired,
    ghostPageId: PropTypes.number.isRequired,
    mousePosition: PropTypes.shape({
      contentX: PropTypes.number.isRequired,
      contentY: PropTypes.number.isRequired,
    }).isRequired,
    frameOffset: PropTypes.shape({
      scrollTop: PropTypes.number.isRequired,
      scrollLeft: PropTypes.number.isRequired,
    }).isRequired,
    frameOffsetWithoutScroll: PropTypes.shape({
      scrollTop: PropTypes.number.isRequired,
      scrollLeft: PropTypes.number.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    ghostElementForRender: null,
  };

  static contextTypes = {
    subscribeToScroll: PropTypes.func.isRequired,
    unsubscribeToScroll: PropTypes.func.isRequired,
  };

  getFrameSize = () => {
    return this.props.frameSizes[this.getPageId()];
  };

  getScale = () => {
    return this.props.scales[this.getPageId()];
  };

  getPageId = () => {
    return this.props.ghostPageId;
  };

  getScaledGhostPositionForCreating = (posArg = this.props.mousePosition) => {
    const { workspace } = this.props;
    const commonPos = this.getCommonScaledGhostPosition({
      posArg, frameOffset: this.props.frameOffset,
    });
    return {
      ...commonPos,
      x: commonPos.x - workspace.left,
      y: commonPos.y - workspace.top,
    };
  };

  getScaledGhostPositionForRender = (posArg = this.props.mousePosition) => {
    return this.getCommonScaledGhostPosition({
      posArg,
      frameOffset: this.props.frameOffsetWithoutScroll,
    });
  };

  getCommonScaledGhostPosition = ({ posArg, frameOffset }) => {
    const { ghostElementForRender, workspace } = this.props;
    const scale = this.getScale();

    const pos = {
      x: posArg.contentX,
      y: posArg.contentY,
    };

    // Step 1: get event coords relative to content div
    const coords = screenToPageFreeGhost(pos, scale, frameOffset, workspace);
    // Step 2: calculate position of ghost element
    const ghostPosition = getGhostPosition(scale, ghostElementForRender);
    const unlimitedPosition = {
      y: (coords.y * scale) + ghostPosition.top,
      x: (coords.x * scale) + ghostPosition.left,
      width: ghostElementForRender.content.width * scale,
      height: ghostElementForRender.content.height * scale,
    };

    return unlimitedPosition;
  };

  render() {
    return this.props.children({
      isConstructorMode: this.props.isConstructorMode,
      scale: this.getScale(),
      frameSize: this.getFrameSize(),
      ghostElementForRender: this.props.ghostElementForRender,
      getScaledGhostPositionForCreating: this.getScaledGhostPositionForCreating,
      getScaledGhostPositionForRender: this.getScaledGhostPositionForRender,
    });
  }
}

export default connect(mapStateToProps, null)(GhostDataProvider);
