import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import compact from 'lodash/compact';
import { popupStatuses } from '../../helpers/const';
import { toolsDecorator } from '../../decorators';
import { selectors } from '../..';

import {
  setActivePage,
  setContiniousFrameScroll,
  setIsContiniousScrollAnimated,
} from '../../store/modules/navigation';

import PaginationContinious from '../Pagination/PaginationContinious/PaginationContinious';
import PaginationContiniousView from '../Pagination/PaginationContinious/PaginationContiniousView';
import PaginationContiniousPinch from '../Pagination/PaginationContiniousPinch/PaginationContiniousPinch';

import PageContextProvider from '../Page/PageContextProvider/PageContextProvider';
import PageFrameContinious from '../Page/PageFrameContinious/PageFrameContinious';

import PageCanvas from '../Page/PageCanvas/PageCanvas';
// eslint-disable-next-line import/no-named-as-default
import PageCanvasInserter from '../Page/PageCanvas/PageCanvasInserter';

import StoreRefProvider from '../StoreRef/StoreRefProvider';

@toolsDecorator({ main: true })
@connect(
  (state) => {
    return ({
      pagesSettings: selectors.navigation.getPagesSettings(state),
      scrollToTopSn: state.navigation.scrollToTopSn,

      activePageId: selectors.base.getActivePageId(state),
      nextPageId: selectors.base.getNextPageId(state),
      workspace: selectors.base.getWorkspace(state),
      frameSizes: selectors.getFrameSizes(state),
      frameOffsets: selectors.getFrameOffsets(state),
      originalSizes: selectors.base.getOriginalSizes(state),
      popupVisibility: selectors.base.getSettingsPopupVisibility(state),
    });
  }, {
    setActivePage,
    setContiniousFrameScroll,
    setIsContiniousScrollAnimated,
  },
)
export default class WorkspaceContinious extends Component {
  static propTypes = {
    scrollToTopSn: PropTypes.shape({}).isRequired,
    pagesSettings: PropTypes.arrayOf(
      PropTypes.shape({
        source: PropTypes.number.isRequired,
      }).isRequired,
    ).isRequired,
    activePageId: PropTypes.number.isRequired,
    nextPageId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool,
    ]).isRequired,
    frameSizes: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          height: PropTypes.number.isRequired,
        }),
        PropTypes.bool,
      ]).isRequired,
    ).isRequired,
    frameOffsets: PropTypes.arrayOf(
      PropTypes.shape({
        scrollTop: PropTypes.number.isRequired,
      }).isRequired,
    ).isRequired,
    workspace: PropTypes.shape({
      height: PropTypes.number.isRequired,
      framePadding: PropTypes.shape({
        top: PropTypes.number.isRequired,
        bottom: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        right: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    originalSizes: PropTypes.arrayOf(
      PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
      }),
    ).isRequired,
    popupVisibility: PropTypes.oneOf(
      Object.values(popupStatuses),
    ).isRequired,

    setActivePage: PropTypes.func.isRequired,
    setContiniousFrameScroll: PropTypes.func.isRequired,
    setIsContiniousScrollAnimated: PropTypes.func.isRequired,
  };

  getPagesViewport = () => {
    return ({
      workspace: this.props.workspace,
      frameSizes: this.props.frameSizes,
      frameOffsets: this.props.frameOffsets,
      originalSizes: this.props.originalSizes,
      popupVisibility: this.props.popupVisibility,
    });
  };

  render = () => {
    const {
      frameOffsets,
      frameSizes,
      workspace,
    } = this.props;

    return (
      <StoreRefProvider>
        {({ storeRef: storeRefPagination, getRef: getRefPagination }) => {
          return (
            <PaginationContinious
              scrollToTopSn={this.props.scrollToTopSn}
              frameSizes={this.props.frameSizes}
              workspace={this.props.workspace}
              activePageId={this.props.activePageId}
              nextPageId={this.props.nextPageId}

              getRef={getRefPagination}
              setActivePage={this.props.setActivePage}
              setContiniousFrameScroll={this.props.setContiniousFrameScroll}
              setIsContiniousScrollAnimated={this.props.setIsContiniousScrollAnimated}
            >
              {({ onDOMScroll }) => {
                return (
                  <PaginationContiniousPinch>
                    <div>
                      <PaginationContiniousView
                        framePadding={this.props.workspace.framePadding.top}
                        storeRef={storeRefPagination}
                        onDOMScroll={onDOMScroll}
                      >
                        {[
                          ...compact(this.props.pagesSettings.map(({ source }, pageIndex) => {
                            return (
                              <PageContextProvider
                                getPagesViewport={this.getPagesViewport}
                                pageId={source}
                                key={`${source}-${pageIndex}`} // eslint-disable-line
                              >
                                <PageFrameContinious
                                  frameOffset={frameOffsets[source]}
                                  frameSize={frameSizes[source]}
                                  framePadding={workspace.framePadding.top}
                                >
                                  <StoreRefProvider>
                                    {({ storeRef: storeRefPage, getRef: getRefPage }) => {
                                      return (
                                        <PageCanvasInserter getRef={getRefPage} pageId={source}>
                                          <PageCanvas
                                            pageId={source}
                                            storeRef={storeRefPage}
                                          />
                                        </PageCanvasInserter>
                                      );
                                    }}
                                  </StoreRefProvider>
                                </PageFrameContinious>
                              </PageContextProvider>
                            );
                          })),
                        ]}
                      </PaginationContiniousView>
                    </div>
                  </PaginationContiniousPinch>
                );
              }}
            </PaginationContinious>
          );
        }}
      </StoreRefProvider>
    );
  };
}
