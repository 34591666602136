import PropTypes from 'prop-types';
import React from 'react';

import * as Ui from '../../../ui';
import ToolbarThumbnailsButton from './ToolbarThumbnailsButton/ToolbarThumbnailsButton';
import ToolbarUndoRedoButton from './ToolbarUndoRedoButton/ToolbarUndoRedoButton';
import ToolbarThumbnailsButtonReadOnly from './ToolbarThumbnailsButton/ToolbarThumbnailsButtonReadOnly';

const toolbarNavigationView = ({
  onClickPages,
  onClickUndo,
  onClickRedo,
  isUndoAvailable,
  isRedoAvailable,
  isThumbnailsOpen,
  isReadOnly,
  locale,
}) => {
  return (
    <Ui.Grid.Cell size={Ui.grid.sizes.small}>
      <Ui.ToolBar.Body
        size={Ui.toolBar.sizes.mediumStretched}
        theme={Ui.toolBar.themes.bordered}
      >
        <Ui.ToolBar.Main>
          {
            isReadOnly &&
              <ToolbarThumbnailsButtonReadOnly
                isThumbnailsOpen={isThumbnailsOpen}
                onClickPages={onClickPages}
              />
          }
          {
            !isReadOnly &&
              <ToolbarThumbnailsButton
                isThumbnailsOpen={isThumbnailsOpen}
                onClickPages={onClickPages}
              />
          }
          {
            !isReadOnly &&
            <ToolbarUndoRedoButton
              icon={Ui.icon.types.undo}
              isDisabled={!isUndoAvailable}
              onClick={onClickUndo}
              hintHtml={locale.undo}
            />
          }
          {
            !isReadOnly &&
            <ToolbarUndoRedoButton
              icon={Ui.icon.types.redo}
              isDisabled={!isRedoAvailable}
              onClick={onClickRedo}
              hintHtml={locale.redo}
            />
          }
        </Ui.ToolBar.Main>
      </Ui.ToolBar.Body>
    </Ui.Grid.Cell>
  );
};

toolbarNavigationView.defaultProps = {
  isUndoAvailable: false,
  isRedoAvailable: false,
};

toolbarNavigationView.propTypes = {
  onClickPages: PropTypes.func.isRequired,
  onClickUndo: PropTypes.func.isRequired,
  onClickRedo: PropTypes.func.isRequired,
  isUndoAvailable: PropTypes.bool,
  isRedoAvailable: PropTypes.bool,
  isThumbnailsOpen: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  locale: PropTypes.shape({
    undo: PropTypes.string,
    redo: PropTypes.string,
  }).isRequired,
};

export default toolbarNavigationView;
