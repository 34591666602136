import React from 'react';
import { thisDevice } from '@pdffiller/jsf-useragent';
import PagePinch from './PagePinch';
import PagePinchCssZoom from './PagePinchCssZoom';

const PagePinchProxy = (props) => {
  return thisDevice.isDesktop
    ? <PagePinchCssZoom {...props} />
    : <PagePinch {...props} />;
};

export default PagePinchProxy;
