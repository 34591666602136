import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { thisDevice } from '@pdffiller/jsf-useragent';

import * as Ui from '../../../../ui';
import PopupToolsList from './PopupToolsList';

export default class PopupMainMenu extends Component {
  static propTypes = {
    elementId: PropTypes.string,
    locale: PropTypes.shape({
      settings: PropTypes.string.isRequired,
      done: PropTypes.string.isRequired,
    }).isRequired,

    resetPopup: PropTypes.func.isRequired,
    forceFocusTextElement: PropTypes.func.isRequired,
    onTouchStart: PropTypes.func.isRequired,
    onTouchEnd: PropTypes.func.isRequired,
    popupToolsCount: PropTypes.number,
  };

  static defaultProps = {
    elementId: null,
    popupToolsCount: -1,
  };

  onOkClick = () => {
    this.props.forceFocusTextElement(this.props.elementId);
    this.props.resetPopup();
  };

  render() {
    const { locale } = this.props;

    if (!this.props.elementId) {
      return null;
    }

    return (
      <Ui.Slider.ContentCell
        onTouchStart={this.props.onTouchStart}
        onTouchEnd={this.props.onTouchEnd}
      >
        {thisDevice.isPhone &&
          <Ui.ToolBar.Body
            size={Ui.toolBar.sizes.medium}
            theme={Ui.toolBar.themes.solidLightgray}
            offset={Ui.toolBar.offsets.extraSmallHorizontal}
          >
            <Ui.ToolBar.Main>
              <Ui.ToolBar.Group attributes={Ui.attributes.grow}>
                <Ui.ToolBar.Item type={Ui.toolBar.itemTypes.groupModule}>
                  <Ui.Group.Index offset={Ui.group.offsets.small}>
                    <Ui.Group.Body>
                      <Ui.Group.Item>
                        <Ui.Icon
                          type={Ui.icon.types.cogwheel}
                          size={Ui.icon.sizes.medium}
                        />
                      </Ui.Group.Item>
                      <Ui.Group.Item>
                        <Ui.Text size={16}>
                          {locale.settings}
                        </Ui.Text>
                      </Ui.Group.Item>
                    </Ui.Group.Body>
                  </Ui.Group.Index>
                </Ui.ToolBar.Item>
              </Ui.ToolBar.Group>

              <Ui.ToolBar.Group>
                <Ui.Container.SettingButton
                  theme={Ui.control.themes.transparentBlue}
                  behavior={Ui.control.behaviors.null}
                  textSize={15}
                  label={locale.done}
                  onClick={this.onOkClick}
                />
              </Ui.ToolBar.Group>
            </Ui.ToolBar.Main>
          </Ui.ToolBar.Body>
        }

        <Ui.ScrollBox>
          <Ui.Menu.Body>
            <Ui.Menu.List>
              <PopupToolsList {...this.props} />
            </Ui.Menu.List>
          </Ui.Menu.Body>
        </Ui.ScrollBox>

      </Ui.Slider.ContentCell>
    );
  }
}
