import React from 'react';
import PropTypes from 'prop-types';
import * as Ui from 'jsfcore/ui';

const ModesPanelTabRenderer = ({
  onClick,
  tabData,
}) => {
  return (
    <Ui.ToolBar.Item
      key={tabData.title}
      type={Ui.toolBar.itemTypes.control}
    >
      <Ui.Control
        inheritRadius
        behavior={Ui.control.behaviors.wrapText}
        disabled={tabData.isDisabled}
        isSelected={tabData.selected}
        offset={Ui.control.offsets.horizontalLarge}
        onClick={onClick}
        size={Ui.control.sizes.parentHeight}
        theme={Ui.control.themes.primaryUnderlined}
      >
        <Ui.Text
          size={14}
          weight={Ui.text.weights.bold}
        >
          {tabData.title}
        </Ui.Text>
      </Ui.Control>
    </Ui.ToolBar.Item>
  );
};

ModesPanelTabRenderer.propTypes = {
  onClick: PropTypes.func,
  tabData: PropTypes.shape({
    isDisabled: PropTypes.bool,
    selected: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

ModesPanelTabRenderer.defaultProps = {
  onClick: null,
};

export default ModesPanelTabRenderer;
