import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';

const getWorkspaceContiniousStyle = (framePadding) => {
  return {
    overflow: 'scroll',
    paddingBottom: framePadding,
  };
};

export default class PaginationContiniousView extends Component {
  static propTypes = {
    framePadding: PropTypes.number.isRequired,
    children: PropTypes.arrayOf(
      PropTypes.element.isRequired,
    ).isRequired,
    storeRef: PropTypes.func.isRequired,
    onDOMScroll: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div
        style={getWorkspaceContiniousStyle(this.props.framePadding)}
        ref={this.props.storeRef}
        onScroll={this.props.onDOMScroll}
        className={classnames('jsf-pagination-local', 'pagination-Pagination')}
      >
        {this.props.children}
      </div>
    );
  }
}
