export default function fontSizeBinarySearch(badFontSize) {
  let bad = badFontSize;
  let good = 0;

  // Тестовое значение - среднее между bad и good
  const getTest = () => {
    return (good + bad) / 2;
  };

  let test = getTest();

  const step = (isValid) => {
    if (isValid) {
      good = test;
      test = getTest();
    } else {
      bad = test;
      test = getTest();
    }

    if (Math.abs(good - bad) <= 0.5) {
      return { good };
    }

    return { test, step };
  };

  return { test, step };
}
