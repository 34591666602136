import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { updateDefaultSettings } from 'ws-editor-lib/actions';
import get from 'lodash/get';
import { selectors } from 'jsfcore';
import { toggleStamp } from '../../../store/thunks';

import SignStamp from './SignStamp';
import * as Portal from '../../Portal';
import { parentPropsImportant } from '../../../helpers/utils';
import {
  elemSubTypes,
  getSignatureSubtype,
} from '../../../helpers/elemTypes';
import {
  getContentXYSignature,
  getCroppedSize,
} from '../../../helpers/getContentXY';

const anchorOutline = 1;

function getIsStampShown(state, props) {
  const isAnyConstructorShown = selectors.mode.getIsAnyConstructorShown(state);
  const isPageChanging = selectors.base.isPageChanging(state);
  const isPagePanning = selectors.base.isPagePanning(state);
  const isPagePinching = selectors.base.isPagePinching(state);
  const dateStamp = get(props, 'element.content.dateStamp', null);
  const isGhost = get(props, 'isGhost', false);
  const originalSize = get(props, 'originalSize', null);

  return (
    dateStamp &&
    originalSize &&
    !isAnyConstructorShown &&
    !isGhost &&
    !isPageChanging &&
    !isPagePanning &&
    !isPagePinching
  );
}

function getIsStampDisabled(state, element) {
  const isVersionsShown = selectors.mode.isVersions(state);
  const isFConstructorPreviewShown = selectors.base.getIsFConstructorPreviewShown(state);
  const isEnabled = get(element, 'enabled', true);

  return (
    isVersionsShown ||
    isFConstructorPreviewShown ||
    !isEnabled
  );
}

@connect(
  (state, props) => {
    return {
      sigDateStampChangeable: state.ws.access.sigDateStampChangeable,
      scale: selectors.getScale(
        state,
        selectors.base.getActivePageId(state),
      ),
      isStampShown: getIsStampShown(state, props),
      isStampDisabled: getIsStampDisabled(state, props.element),
    };
  }, {
    updateDefaultSettings,
    toggleStamp,
  }, parentPropsImportant,
)
export default class SignStampProvider extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    isFillable: PropTypes.bool.isRequired,
    scale: PropTypes.number.isRequired,
    toggleStamp: PropTypes.func.isRequired,
    element: PropTypes.shape({
      content: PropTypes.shape({
        dateStamp: PropTypes.string,
        width: PropTypes.number,
      }),
      template: PropTypes.shape({
        dateStamp: PropTypes.string,
        y: PropTypes.number,
      }),
    }).isRequired,
    sigDateStampChangeable: PropTypes.bool.isRequired,
    isStampShown: PropTypes.bool.isRequired,
    isStampDisabled: PropTypes.bool.isRequired,

    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    updateDefaultSettings: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,

    getRefPromise: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
  };

  static contextTypes = {
    getWorkspaceRef: PropTypes.func.isRequired,
    getContentRef: PropTypes.func.isRequired,
  };

  static childContextTypes = {
    updateSizeForStamp: PropTypes.func,
    getSignToolSize: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const { width, height } = props;
    this.state = {
      height,
      width,
    };
  }

  getChildContext = () => {
    return {
      updateSizeForStamp: this.updateSizeForStamp,
      getSignToolSize: this.getSignToolSize,
    };
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ width, height }) {
    if (width !== this.props.width || height !== this.props.height) {
      this.setState({ width, height });
    }
  }

  getSignToolSize = () => {
    const { height, width } = this.state;

    return { width, height };
  };

  getSignToolContentWidth = () => {
    const { element, isFillable } = this.props;
    const subType = getSignatureSubtype(element);
    if (isFillable && subType !== elemSubTypes.signature.text && element.content) {
      // TODO: remove this code after removing activeElement
      const { width } = getCroppedSize(
        element.content,
        element.template,
      );
      return width;
    }
    return this.state.width;
  };

  getSignToolContentTopOffset = () => {
    const { element, isFillable } = this.props;
    if (isFillable && element.content) {
      // TODO: remove this code after removing activeElement
      const { contentY } = getContentXYSignature(
        element.content,
        element.template,
      );
      const { y } = element.template;
      return contentY - y;
    }
    return 0;
  };

  updateSizeForStamp = ({ width, height }) => {
    this.setState({ width, height });
  };

  render() {
    const { element, scale, getRefPromise, isStampShown, isStampDisabled, onFocus } = this.props;
    const dateStamp = element.content && element.content.dateStamp;
    const signToolContentWidth = this.getSignToolContentWidth();
    const signToolContentTopOffset = this.getSignToolContentTopOffset();

    const locatorArgs = {
      elementTopOffset: signToolContentTopOffset * scale,
      elementLeftOffset: signToolContentWidth * scale,
      anchorOutline: anchorOutline * scale,
      position: Portal.wrapperPositions.topLeftInner,
      hideIfAnchorOutOfViewport: true,
      preset: Portal.wrapperPresets.signStamp,
      scale,
    };

    return (
      <Fragment>
        {this.props.children}
        {isStampShown &&
        <Portal.WrapperExperiment
          getAnchorRefPromise={getRefPromise}
          getViewportRef={this.context.getContentRef}
          locatorArgs={locatorArgs}
          theme={null}
          useArrow={false}
          zIndex={1}
          isDisabled={isStampDisabled}
          onFocus={onFocus}
        >
          <SignStamp
            key="SignStamp"
            toggleStamp={this.props.toggleStamp}
            dateStamp={dateStamp}
            updateDefaultSettings={this.props.updateDefaultSettings}
            isFillable={this.props.isFillable}
            id={this.props.id}
            sigDateStampChangeable={this.props.sigDateStampChangeable}
          />
        </Portal.WrapperExperiment>
        }
      </Fragment>
    );
  }
}
