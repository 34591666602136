import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import {
  getFillableRequiredEnabledElementsLengthGroupedByRadioGroup,
  getFilledFillableRequiredElementsLengthGroupedByRadioGroup,
  getFillableOptionalEnabledElementsLengthGroupedByRadioGroup,
  getFilledFillableOptionalElementsLengthGroupedByRadioGroup,
} from '../../../store/selectors/selectors';
import { getSigningSessionTextSet } from '../../../store/selectors/baseSelectors';

const availableFieldsModes = {
  requiredAndOptional: 'requiredAndOptional',
  requiredOnly: 'requiredOnly',
  optionalOnly: 'optionalOnly',
};

const fillingModes = {
  before: 'BEFORE',
  during: 'DURING',
  after: 'AFTER',
};

const getInterpolationParamName = (condition) => {
  return condition
    ? 'one'
    : 'other';
};

@connect(
  (state) => {
    return {
      totalRequiredFieldsCount: getFillableRequiredEnabledElementsLengthGroupedByRadioGroup(state),
      totalOptionalFieldsCount: getFillableOptionalEnabledElementsLengthGroupedByRadioGroup(state),
      filledRequiredFieldsCount: getFilledFillableRequiredElementsLengthGroupedByRadioGroup(state),
      filledOptionalFieldsCount: getFilledFillableOptionalElementsLengthGroupedByRadioGroup(state),
      signingSessionTextSet: getSigningSessionTextSet(state),
    };
  },
)
export default class SigningSessionProvider extends Component {
  static propTypes = {
    totalRequiredFieldsCount: PropTypes.number.isRequired,
    totalOptionalFieldsCount: PropTypes.number.isRequired,
    filledRequiredFieldsCount: PropTypes.number.isRequired,
    filledOptionalFieldsCount: PropTypes.number.isRequired,
    signingSessionTextSet: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  };

  getAvailableFieldsMode = () => {
    const { totalRequiredFieldsCount, totalOptionalFieldsCount } = this.props;

    if (totalRequiredFieldsCount > 0 && totalOptionalFieldsCount === 0) {
      return availableFieldsModes.requiredOnly;
    }

    if (totalRequiredFieldsCount === 0 && totalOptionalFieldsCount > 0) {
      return availableFieldsModes.optionalOnly;
    }

    return availableFieldsModes.requiredAndOptional;
  };

  getFillingMode = () => {
    const {
      totalRequiredFieldsCount, totalOptionalFieldsCount,
      filledRequiredFieldsCount, filledOptionalFieldsCount,
    } = this.props;

    const totalFieldsCount = totalRequiredFieldsCount + totalOptionalFieldsCount;
    const filledFieldsCount = filledRequiredFieldsCount + filledOptionalFieldsCount;

    if (filledFieldsCount > 0 && filledFieldsCount < totalFieldsCount) {
      return fillingModes.during;
    }

    if (filledFieldsCount === totalFieldsCount) {
      return fillingModes.after;
    }

    return fillingModes.before;
  };

  getTitleInterpolationParams = () => {
    const {
      totalRequiredFieldsCount, totalOptionalFieldsCount,
      filledRequiredFieldsCount, filledOptionalFieldsCount,
    } = this.props;

    return [
      {
        name: getInterpolationParamName(totalRequiredFieldsCount > 0),
        value: totalRequiredFieldsCount,
        additional: filledRequiredFieldsCount,
      }, {
        name: getInterpolationParamName(
          totalRequiredFieldsCount > 0 && totalOptionalFieldsCount > 0,
        ),
      }, {
        name: getInterpolationParamName(totalOptionalFieldsCount > 0),
        value: totalOptionalFieldsCount,
        additional: filledOptionalFieldsCount,
      }, {
        name: getInterpolationParamName(
          totalRequiredFieldsCount + totalOptionalFieldsCount === 1,
        ),
      },
    ];
  };

  render() {
    const textSet = this.props.signingSessionTextSet.toUpperCase();
    const fillingMode = this.getFillingMode();
    const availableFieldsMode = this.getAvailableFieldsMode();
    const interpolationParams = this.getTitleInterpolationParams();

    const titleId = `SIGNING_SESSION.${fillingMode}.TITLE`;

    const textIdMode = availableFieldsMode === availableFieldsModes.optionalOnly
      ? 'OPTIONAL_ONLY'
      : 'WITH_REQUIRED';

    const textId = `SIGNING_SESSION.${fillingMode}.TEXT.${textSet}.${textIdMode}`;

    return this.props.children({
      isSmileyIconShown: fillingMode === fillingModes.after,
      textId,
      titleId,
      interpolationParams,
    });
  }
}
