import React from 'react';
import eventEmitter from './eventEmitter';

const style = {
  width: '100%',
  height: '100%',
  padding: 0,
  margin: 0,
  border: 'none',
  background: '#435',
  color: '#fff',
  opacity: 0,
  cursor: 'auto',
  display: 'block',
  outline: 'none',
};

const GhostButton = () => {
  return (
    <button
      type="button"
      onClick={eventEmitter.emit}
      style={style}
    />
  );
};

export default GhostButton;
