import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { elemTypes } from '../../../helpers/const/const';
import { elemSubTypes } from '../../../helpers/elemTypes';
import * as selectors from '../../../store/selectors/selectors';


// NOTE: this values got from css+text, if element has less width -> don't show label
const placeholderMinWidth = {
  [elemTypes.image]: 82,
  [elemTypes.signature]: 115,
};

@connect(
  (state) => {
    return {
      locale: selectors.locale.getEditorLocale(state).placeholders,
    };
  },
)
export default class UnfilledSignToolLocaleProvider extends Component {
  static propTypes = {
    element: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      subType: PropTypes.string.isRequired,
      template: PropTypes.shape({
        width: PropTypes.number,
      }),
      content: PropTypes.shape({
        subType: PropTypes.string,
      }),
    }).isRequired,
    locale: PropTypes.shape({
      initials: PropTypes.string.isRequired,
      signature: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    }).isRequired,

    children: PropTypes.func.isRequired,
  };

  getUnfilledInnerText = () => {
    const { type, subType, template = {} } = this.props.element;
    const { width } = template;

    if (width <= placeholderMinWidth[type]) {
      return null;
    }

    if (type === elemTypes.signature) {
      return subType === elemSubTypes.signature.initials
        ? this.props.locale.initials
        : this.props.locale.signature;
    }

    return this.props.locale.image;
  };

  render() {
    return this.props.children({
      getUnfilledInnerText: this.getUnfilledInnerText,
    });
  }
}
