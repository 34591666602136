import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { selectors } from '../..';
import { popupStatuses } from '../../helpers/const';

@connect(
  (state) => {
    return {
      pdfPages: selectors.base.getPdfPages(state),
      frameSizes: selectors.getFrameSizes(state),
      workspace: selectors.base.getWorkspace(state),
      scales: selectors.base.getScales(state),
      frameOffsets: selectors.getFrameOffsets(state),
      originalSizes: selectors.base.getOriginalSizes(state),
      popupVisibility: selectors.base.getSettingsPopupVisibility(state),
    };
  },
)
export default class GhostLayerSimplePaginationWrapper extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,

    pdfPages: PropTypes.arrayOf(
      PropTypes.number,
    ).isRequired,
    frameSizes: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          height: PropTypes.number.isRequired,
        }),
        PropTypes.bool,
      ]).isRequired,
    ).isRequired,
    workspace: PropTypes.shape({
      height: PropTypes.number.isRequired,
      framePadding: PropTypes.shape({
        top: PropTypes.number.isRequired,
        bottom: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        right: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    scales: PropTypes.arrayOf(
      PropTypes.number,
    ).isRequired,
    frameOffsets: PropTypes.arrayOf(
      PropTypes.shape({
        scrollTop: PropTypes.number.isRequired,
      }).isRequired,
    ).isRequired,
    originalSizes: PropTypes.arrayOf(
      PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
      }),
    ).isRequired,
    popupVisibility: PropTypes.oneOf(
      Object.values(popupStatuses),
    ).isRequired,
  };

  getPagesViewport = () => {
    return ({
      workspace: this.props.workspace,
      frameSizes: this.props.frameSizes,
      frameOffsets: this.props.frameOffsets,
      originalSizes: this.props.originalSizes,
      popupVisibility: this.props.popupVisibility,
    });
  };

  render() {
    const { pdfPages, frameSizes, workspace, scales } = this.props;
    return this.props.children({
      getPagesViewport: this.getPagesViewport,
      pdfPages,
      frameSizes,
      workspace,
      scales,
    });
  }
}
