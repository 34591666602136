import PropTypes from 'prop-types';
import React, { Component, useCallback } from 'react';
import cls from 'classnames';
import { thisDevice } from '@pdffiller/jsf-useragent';
import { mouseFocusClassName } from '../../helpers/const';
import {
  isFilledElement,
  isErase,
} from '../../store/helpers/functions';
import {
  isEnabledElement,
} from '../../helpers/utils';
import {
  isLineType,
  getIsFakeEditTextElement,
  elemTypes,
} from '../../helpers/elemTypes';
import getStyleWithPointerEvent from './getStyleWithPointerEvent';
import StoreRefProvider from '../StoreRef/StoreRefProvider';
import { stickyClassName } from '../Tools/StickyTool/StickyToolView';
import { textboxClassName } from '../Tools/TextBoxTool/TextBoxToolView';

/**
 * Размеры рефа элемента для sticky и textbox изменяются при драге и ресайзе
 * поэтому нужен размер вложенного элемента который не изменяет размеров.
 *
 * По другому сделать сложненько, очень высоко нужно поднимать реф
 * (см. откуда импортятся классы)
 */
const StickyTextBoxRefTransformer = ({ getRefPromise, children, type }) => {
  const getWrapperPositionRefPromise = useCallback(async () => {
    const node = await getRefPromise();

    if (type === elemTypes.sticky && node) {
      return node.querySelector(`.${stickyClassName}`) || node;
    }

    if (type === elemTypes.textbox && node) {
      return node.querySelector(`.${textboxClassName}`) || node;
    }

    return node;
  }, [getRefPromise, type]);
  return children({ getWrapperPositionRefPromise });
};

export default class ElementWrapperPositionView extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,

    element: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      subType: PropTypes.string.isRequired,
    }).isRequired,
    elementProps: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      width: PropTypes.number,
      height: PropTypes.number,
    }).isRequired,
    scale: PropTypes.number.isRequired,
    isConstructorMode: PropTypes.bool.isRequired,

    isActiveElement: PropTypes.bool.isRequired,
    isFillableElement: PropTypes.bool.isRequired,

    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  render() {
    const {
      element,
      elementProps,
      scale,
      isConstructorMode,

      isActiveElement,
      isFillableElement,
    } = this.props;

    const isEraseElement = isErase(element);
    const isEnabled = isEnabledElement(element);
    const isLineElement = isLineType(element.type);
    const isFilledFillableElement = isFilledElement
      ? isFilledElement(element)
      : false;
    const isFakeEditElement = getIsFakeEditTextElement(element);

    /**
     * В обычных браузерах нормально работает top-left позиционирование
     * а в IE11 с таким позиционировнаием при драге элемент оставляет за собой
     * шлейф.
     *
     * По хорошему во всех браузерах нужно переезжать на transform,
     * но это требует глубокого тестирования, поэтому пока исправляем только
     * проблему
     */
    const positionCSS = thisDevice.isInternetExplorer11
      ? {
        top: 0,
        left: 0,
        transform: `translate(${elementProps.x}px, ${elementProps.y}px)`,
      }
      : {
        top: elementProps.y * scale,
        left: elementProps.x * scale,
      };

    return (
      <StoreRefProvider>
        {({ getRefPromise, storeRef }) => {
          // todo fix tooltip position when resizing stickybox
          return (
            <div
              className={
                cls('wrapper-Element', 'jsf-document-attr-wrap', {
                  [mouseFocusClassName]:
                    !isLineElement && !isEraseElement &&
                    (isEnabled || isConstructorMode) &&
                    (!isFakeEditElement || isActiveElement),
                  'jsf-document-attr-wrap--active': isActiveElement,
                }, 'pageElement-Pagination')
              }
              style={
                getStyleWithPointerEvent({
                  style: {
                    position: 'absolute',
                    ...positionCSS,
                    width: elementProps.width * scale,
                    height: elementProps.height * scale,
                  },
                  elementProps,
                  isOuter: true,
                  isActiveLinks: !isConstructorMode,
                  isEraseElement,
                })
              }

              data-id={element.id}
              data-autotest-active={isActiveElement}
              data-autotest-fillable={isFillableElement}
              data-autotest-type={element.type}
              data-autotest-subtype={element.subType}
              data-autotest-filled={isFilledFillableElement}

              onMouseEnter={this.props.onMouseEnter}
              onMouseLeave={this.props.onMouseLeave}
              onClick={this.props.onClick}
              ref={storeRef}
            >
              <StickyTextBoxRefTransformer getRefPromise={getRefPromise} type={element.type}>
                {this.props.children}
              </StickyTextBoxRefTransformer>
            </div>
          );
        }}
      </StoreRefProvider>
    );
  }
}
