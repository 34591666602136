import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as Ui from '../../../ui';

export default class ThumbnailsSmallView extends Component {
  static propTypes = {
    activePageId: PropTypes.number.isRequired,
    count: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool,
    ]).isRequired,
    toNext: PropTypes.func.isRequired,
    toPrev: PropTypes.func.isRequired,
    canPrev: PropTypes.bool.isRequired,
    canNext: PropTypes.bool.isRequired,
    locale: PropTypes.shape({
      label: PropTypes.string.isRequired,
      labelInit: PropTypes.string.isRequired,
    }).isRequired,
  };

  static contextTypes = {
    getIndexByPageId: PropTypes.func,
  };

  render() {
    const { activePageId, count, toNext, toPrev, canPrev, canNext, locale } = this.props;
    const attributes = {
      ...Ui.attributes.grow,
      ...Ui.attributes.shrink,
    };
    const label = (
      count === false
        ? locale.labelInit
        : locale.label
    )
      .replace('%current%', `<div class="counter__item">${this.context.getIndexByPageId(activePageId) + 1}</div>`)
      .replace('%total%', `<div class="counter__item">${count}</div>`);

    return (
      <Ui.SideBar.Pagination>
        <Ui.PageNavigation.Body>
          <Ui.PageNavigation.Cell
            onClick={toNext}
          >
            <Ui.Group.Index
              type={Ui.group.types.compact}
            >
              <Ui.Group.Body>
                <Ui.Group.Item>
                  <Ui.Control
                    behavior={Ui.control.behaviors.wrapText}
                    size={Ui.control.sizes.extraSmall}
                    theme={Ui.control.themes.transparentGray}
                    offset={Ui.control.offsets.extraSmall}
                    disabled={!canNext}
                    inheritRadius
                    icon={
                      <Ui.Icon
                        type={Ui.icon.types.arrowLeftOld}
                        size={Ui.icon.sizes.extraSmall}
                      />
                    }
                  />
                </Ui.Group.Item>
              </Ui.Group.Body>
            </Ui.Group.Index>
          </Ui.PageNavigation.Cell>
          <Ui.PageNavigation.Cell attributes={attributes}>
            <Ui.Counter.Body
              theme={Ui.counterBody.themes.default}
              behavior={Ui.counterBody.behaviors.overflowText}
              innerHTML={{ __html: label }}
            />
          </Ui.PageNavigation.Cell>
          <Ui.PageNavigation.Cell
            onClick={toPrev}
          >
            <Ui.Group.Index
              type={Ui.group.types.compact}
            >
              <Ui.Group.Body>
                <Ui.Group.Item>
                  <Ui.Control
                    behavior={Ui.control.behaviors.wrapText}
                    size={Ui.control.sizes.extraSmall}
                    theme={Ui.control.themes.transparentGray}
                    offset={Ui.control.offsets.extraSmall}
                    disabled={!canPrev}
                    inheritRadius
                    icon={
                      <Ui.Icon
                        type={Ui.icon.types.arrowRightOld}
                        size={Ui.icon.sizes.extraSmall}
                      />
                    }
                  />
                </Ui.Group.Item>
              </Ui.Group.Body>
            </Ui.Group.Index>
          </Ui.PageNavigation.Cell>
        </Ui.PageNavigation.Body>
      </Ui.SideBar.Pagination>
    );
  }
}
