import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ResizeDetector from 'element-resize-detector';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { thisDevice } from '@pdffiller/jsf-useragent';

import * as Ui from '../../../ui';
import HeaderToolView from './HeaderToolView';
import Dropdown from '../../Dropdown/Dropdown';
import { moveTo } from '../../../helpers/utils';
import { ClosePortalFunctionsComposer } from '../../Portal';

const attributes = { ...Ui.attributes.grow, ...Ui.attributes.shrink };
const dropdownWrapperProps = {
  offset: Ui.control.offsets.small,
};

export default class HeaderToolsView extends Component {
  static propTypes = {
    activeTool: PropTypes.shape({
      type: PropTypes.string,
      subType: PropTypes.string,
    }),
    onMouseDown: PropTypes.func.isRequired,
    onMoreButtonClick: PropTypes.func.isRequired,
    appStarted: PropTypes.bool.isRequired,

    locale: PropTypes.shape({
      more: PropTypes.string.isRequired,
    }).isRequired,
    toolbar: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        visible: PropTypes.bool.isRequired,
      })),
      PropTypes.bool,
    ]).isRequired,
    labels: PropTypes.shape({}).isRequired,
    useSignNowTheme: PropTypes.bool.isRequired,
    signingSessionShow: PropTypes.func.isRequired,
    isSigningSessionOverlayEnabled: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    activeTool: null,
  };

  constructor(props) {
    super(props);
    this.isToolbarInited = false;
    this.state = {
      localToolbar: props.toolbar,
      visibleToolsCount: -1,
    };
    this.detector = null;
    this.headerContainerRef = null;
    this.headerWrapperRef = null;
    this.headerBodyRef = null;
    this.buttonMinWidth = null;
  }

  componentDidMount() {
    this.subscribeToResizeWorkspace();
    this.setButtonMinWidth();
    this.updateVisibleToolsCount();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.toolbar, nextProps.toolbar)) {
      this.setState({ localToolbar: nextProps.toolbar });
    }

    if (!isEqual(this.props.activeTool, nextProps.activeTool)) {
      this.updateToolsOrder(nextProps.activeTool);
    }
  }

  componentDidUpdate(prevProps) {
    // Initially component doesn't receive toolbar
    // so we update visibleToolsCount when buttons are _already_received_and_rendered_
    // (if we haven't rendered buttons - we cannot update visibleToolsCount)
    if (!this.isToolbarInited && !isEqual(this.props.toolbar, prevProps.toolbar)) {
      this.updateVisibleToolsCount();
      this.isToolbarInited = true;
    }
    this.setButtonMinWidth();
  }

  componentWillUnmount() {
    this.unsubscribeResizeDetector();
  }

  setButtonMinWidth = () => {
    if (this.buttonMinWidth !== null) {
      return;
    }

    const container = this.headerContainerRef;
    if (!container || container.children.length === 0) {
      return;
    }

    // toolbar__control -> container.firstElementChild.firstElementChild has minWidth
    // (earlier toolbar__item -> container.firstElementChild)
    const { minWidth } = window.getComputedStyle(container.firstElementChild.firstElementChild);
    // "[n]px" to number
    this.buttonMinWidth = parseInt(minWidth, 10);
  };

  storeHeaderWrapperRef = (ref) => {
    this.headerWrapperRef = ref;
  };

  storeHeaderContainerRef = (ref) => {
    this.headerContainerRef = ref;
  };

  storeHeaderBodyRef = (ref) => {
    this.headerBodyRef = ref;
  };

  updateToolsOrder = (activeTool) => {
    if (!activeTool) {
      return;
    }

    const { localToolbar, visibleToolsCount } = this.state;
    const activeToolString = `tools.${activeTool.type}.${activeTool.subType}`;
    const activeToolIndex = localToolbar.findIndex((tool) => {
      return tool.id === activeToolString;
    });

    if (activeToolIndex < visibleToolsCount || visibleToolsCount === -1) {
      return;
    }

    this.setState({
      localToolbar: moveTo(localToolbar, activeToolIndex, visibleToolsCount - 1),
    });
  };

  subscribeToResizeWorkspace() {
    this.detector = new ResizeDetector({ strategy: 'scroll' });
    this.detector.listenTo(this.headerWrapperRef, debounce(this.onResizeHeaderTools, 50));
  }

  unsubscribeResizeDetector() {
    this.detector.uninstall(this.headerWrapperRef);
    this.detector = null;
  }

  // TODO: 480 - constant from static css for '.jsf-header-nav__item--next'
  // on small touch devices we have scrollable header tools
  // else we draw 'more' button if need
  isSmallTouchDevice = () => {
    return (
      thisDevice.isMobile &&
      window.innerWidth < 480
    );
  }

  updateVisibleToolsCount = () => {
    if (
      !__CLIENT__ ||
      this.isSmallTouchDevice() ||
      this.headerBodyRef === null ||
      this.buttonMinWidth === null
    ) {
      if (this.state.visibleToolsCount > -1) {
        // Process case when we switched from landscape to portrait on mobile devices
        this.setState({ visibleToolsCount: -1 });
      }

      return;
    }

    // we dont use offsetWidth as offsetWidth has rounded integer value and we need float
    const { width: headerBodyWidth } = this.headerBodyRef.getBoundingClientRect();
    const amountOfItemsCouldBeFitted = Math.floor(headerBodyWidth / this.buttonMinWidth);


    this.setState((prevState) => {
      return {
        // we reserve one item for "More" button
        visibleToolsCount: amountOfItemsCouldBeFitted < prevState.localToolbar.length
          ? amountOfItemsCouldBeFitted - 1
          : -1,
      };
    }, () => {
      this.updateToolsOrder(this.props.activeTool); // keep activeTool visible
    });
  };

  onResizeHeaderTools = () => {
    this.updateVisibleToolsCount();
  };

  renderPopup = ({ closePortal }) => {
    const { activeTool, appStarted, labels, useSignNowTheme } = this.props;
    const { localToolbar, visibleToolsCount } = this.state;
    const popupTools = localToolbar.slice(visibleToolsCount);

    return (
      <ClosePortalFunctionsComposer
        closePortal={closePortal}
        func={this.props.onMouseDown}
      >
        {({ composedFunc }) => {
          return (
            <Ui.ToolBar.Body
              type={Ui.toolBar.types.tile}
              size={Ui.toolBar.sizes.null}
              behavior={Ui.toolBar.behaviors.wrapItems}
            >
              <Ui.ToolBar.Main>
                {popupTools.map(
                  ({ id, visible }) => {
                    const toolLabel = labels[id];

                    return (
                      <HeaderToolView
                        id={id}
                        visible={visible}
                        onMouseDown={composedFunc}
                        activeTool={activeTool}
                        appStarted={appStarted}
                        key={id}
                        label={toolLabel}
                        useSignNowTheme={useSignNowTheme}
                      />
                    );
                  },
                )}
              </Ui.ToolBar.Main>
            </Ui.ToolBar.Body>
          );
        }}
      </ClosePortalFunctionsComposer>
    );
  }

  render() {
    const {
      activeTool, appStarted, labels,
      useSignNowTheme, isSigningSessionOverlayEnabled,
    } = this.props;

    const { localToolbar, visibleToolsCount } = this.state;
    const visibleTools = visibleToolsCount > -1
      ? localToolbar.slice(0, visibleToolsCount)
      : localToolbar;

    const popupTools = localToolbar.slice(visibleToolsCount);
    const needDrawMoreButton = visibleToolsCount > -1;

    return (
      <Ui.Grid.Cell
        storeRef={this.storeHeaderWrapperRef}
        attributes={attributes}
      >
        <Ui.Grid.Wrapper>
          <Ui.ToolBar.Body
            size={Ui.toolBar.sizes.extraLarge}
            type={Ui.toolBar.types.common}
            behavior={Ui.toolBar.behaviors.wrapItems}
            storeRef={this.storeHeaderBodyRef}
          >
            <Ui.ToolBar.Main
              storeRef={this.storeHeaderContainerRef}
            >
              {visibleTools.map(
                ({ id, visible }) => {
                  const toolLabel = labels[id];

                  return (
                    <HeaderToolView
                      id={id}
                      visible={visible}
                      onMouseDown={this.props.onMouseDown}
                      activeTool={activeTool}
                      appStarted={appStarted}
                      key={id}
                      label={toolLabel}
                      useSignNowTheme={useSignNowTheme}
                    />
                  );
                },
              )}
            </Ui.ToolBar.Main>
            {needDrawMoreButton &&
              <Ui.ToolBar.Add isActive>
                <Dropdown
                  wrapperProps={dropdownWrapperProps}
                  dropdownComponent={this.renderPopup}
                >
                  {({ togglePortal, isPortalOpen, storeRef }) => {
                    const onMouseDown = (event) => {
                      togglePortal(event);
                      this.props.onMoreButtonClick(isPortalOpen);
                    };

                    return (
                      <Ui.ToolBar.Item>
                        <Ui.Control
                          icon={
                            <Ui.Icon
                              type={Ui.icon.types.dots}
                              size={Ui.icon.sizes.large}
                            />
                          }
                          storeRef={storeRef}
                          onMouseDown={onMouseDown}
                          isPressed={isPortalOpen}
                          title={Ui.locales.titles.more}
                          behavior={Ui.control.behaviors.column}
                        >
                          <Ui.Text
                            weight={Ui.text.weights.bold}
                            offset={Ui.text.offsets.topExtraSmall}
                          >
                            {`${this.props.locale.more} (${popupTools.length})`}
                          </Ui.Text>
                        </Ui.Control>
                      </Ui.ToolBar.Item>
                    );
                  }}
                </Dropdown>
              </Ui.ToolBar.Add>
            }
            {isSigningSessionOverlayEnabled && (
              <Ui.ToolBar.Close>
                <Ui.Control
                  behavior={Ui.control.behaviors.column}
                  size={Ui.control.sizes.largeRectangular}
                  theme={Ui.control.themes.silverTransparent}
                  isBusy={false}
                  isPressed={false}
                  onClick={this.props.signingSessionShow}
                  icon={
                    <Ui.Icon size={Ui.icon.sizes.large} type={Ui.icon.types.closeNew} />
                  }
                  titleId="SIGNING_SESSION.CLOSE_BUTTON.TITLE"
                >
                  <Ui.Text
                    size={11}
                    weight={Ui.text.weights.bold}
                    topOffset={Ui.text.topOffsets.extraSmall}
                    id="SIGNING_SESSION.CLOSE_BUTTON.TEXT"
                  />
                </Ui.Control>
              </Ui.ToolBar.Close>
            )}
          </Ui.ToolBar.Body>
        </Ui.Grid.Wrapper>
      </Ui.Grid.Cell>
    );
  }
}
